<template>
  <section class="mt-16">
    <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <rp-icon icon="rp-colaboradores-blue-filled" class="mr-2"/> <span>{{$t('menu.workers_management')}}</span>
      </h1>
      <v-row>
        <v-col cols="12" md="auto">
          <rp-icon
            icon="rp-eye-blue-filled"
            class="mb-n2"
          /><v-icon class="ml-2" small color="secondary">mdi-forward</v-icon>
          <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.visualize')}}</span>
        </v-col>
        <v-col cols="12" md="auto">
          <v-icon color="red" class="ml-4">
            mdi-delete
          </v-icon><v-icon class="ml-2" small color="secondary">mdi-forward</v-icon>
          <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.remove')}}</span>
        </v-col>
      </v-row>

      <div style="position:relative">
        <v-switch
          v-if="this.$root.$vuetify.theme.isDark"
          style="position:absolute"
          light
          v-model="filter.deleted_worker"
          color="success"
          @change="searchWorkers"
          :label="$t('globals.show_deleted')"
        ></v-switch>
        <v-switch
          v-else
          style="position:absolute"
          v-model="filter.deleted_worker"
          color="success"
          @change="searchWorkers"
          :label="$t('globals.show_deleted')"
        ></v-switch>
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t('globals.options')}}</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="info"
                  large
                  @click="searching = true"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>{{$t('globals.search')}}</span>
            </v-tooltip>
            <v-tooltip right color="success">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="success"
                  large
                  @click="exporting = true;"
                  v-if="$root.session.hasPermission(['super', 'export.workers'])"
                >
                  <v-icon>mdi-file-excel</v-icon>
                </v-btn>
              </template>
              <span>{{$t('globals.export')}}</span>
            </v-tooltip>
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  :to="'/' + $session.get('domain') + '/admin/workers-settings/workers/create'"
                  v-if="$root.session.hasPermission(['super', 'workers.create'])"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{$t('globals.register')}}</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
          locale="pt-pt"
        >
          <template v-slot:item.init="{item}">
            <Tooltip
                color="primary"
                :text="$t('workers.hasUser')"
                top
            >
              <v-icon @click="opneUserAccount(item)" color="primary" v-if="item.user_id != null">mdi-account</v-icon>
            </Tooltip>
          </template>

          <template v-slot:item.value="{ item }">
            {{item.value.toFixed(2)}} {{$store.state.coin}}
          </template>

          <template v-slot:item.category="{ item }">
            {{item.category.name}}
          </template>

          <template v-slot:item.company.name="{ item }">
            {{item.company == null ? 'Interno' : item.company.name}}
          </template>

          <template v-slot:item.actions="{item}">
            <IconView
              class="mr-2"
              @click="viewWorker(item)"
            />
            <IconRemove
              v-if="$root.session.hasPermission(['super', 'workers.delete'])"
              class="mr-2"
                :reverse="filter.deleted_worker"
              @on-submit="deleteWorker(item)"
            />
          </template>
        </v-data-table>
      </div>
      <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetWorkers">
              {{$t('globals.clean_fields')}}
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchWorkers">

            <v-list-item>
              <v-text-field
                v-model="filter.code"
                prepend-inner-icon="mdi-barcode"
                :label="$t('globals.id')"
                clearable
              ></v-text-field>
            </v-list-item>
            
            <v-list-item>
              <v-text-field
                v-model="filter.name"
                prepend-inner-icon="mdi-form-textbox"
                :label="$t('globals.name')"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
               <v-select
                  v-model="filter.category_id"
                  :items="categories"
                  item-text="name"
                  item-value="id"
                  menu-props="auto"
                  :label="$t('globals.category')"
                  prepend-inner-icon="mdi-shape"
                  clearable
              ></v-select>
            </v-list-item>

            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              {{$t('globals.search')}}
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
      </v-navigation-drawer>
      <DialogSuccess :opened="started" @on-ok="started = false" >
        {{$t('workers.init_export')}}
      </DialogSuccess>
      <Dialog 
        :opened="exporting">
        <template #content>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-select
                    v-model="worker.export.report"
                    :items="reports"
                    item-text="name"
                    item-value="id"
                    menu-props="auto"
                    :label="$t('globals.report')"
                    @change="changedReport"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-menu
                  v-model="export_started_at"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :label="$t('globals.start_date')"
                        v-model="worker.export.started_at"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="worker.export.started_at"
                    :allowed-dates="!all_days_available ? (date) => date <= new Date(new Date()+1).toISOString().substr(0, 10) : null"
                    locale="pt-pt"
                    @input="export_started_at = false;"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6">
                <v-menu
                  v-model="export_finished_at"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :label="$t('globals.end_date')"
                        v-model="worker.export.finished_at"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="worker.export.finished_at"
                    :allowed-dates="!all_days_available ? (date) => date <= new Date(new Date()+1).toISOString().substr(0, 10) : null"
                    locale="pt-pt"
                    @input="export_finished_at = false;"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template #footer>
            <v-btn
              color="error"
              depressed
              @click="exporting = false"
            >
              {{$t('globals.cancel')}}
            </v-btn>
            <v-btn
              color="primary"
              depressed
              @click="exportReport"
            >
              {{$t('globals.export')}}
            </v-btn>
        </template>
      </Dialog>
      <Loader v-if="exportingDocument"></Loader>  
  </section>
</template>
<script>
import Loader from '@/components/ui/Loader.vue'
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import Category from "@/api/Category.js";
import Worker from "@/api/Worker.js";
import dayjs from 'dayjs';
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Dialog from '@/components/ui/Dialog.vue';
import Export from "@/api/Export.js";
import Tooltip from '@/components/ui/Tooltip.vue';

export default {
  components: {
    IconRemove,
    IconView,
    DialogSuccess,
    Dialog,
    Loader,
    Tooltip
  },
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchWorkers()}, 10);
      },
      deep: true,
    },
  },
  beforeCreate(){
    document.title = "Report Lake - " + this.$t('menu.workers_management');

    Category.list().then(({data}) => {
      this.categories = data;
    });
  },
  data: () => ({
    all_days_available: false,
    exportingDocument: false, 
    started: false,
    exporting: false,
    export_finished_at: false,
    export_started_at: false,
    worker: {
      export: {
        started_at: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
        finished_at: dayjs().format("YYYY-MM-DD"),
        report: "extra_hours",
      }
    },
    items: [],
    total: 0,
    loading: false,
    searching: false,
    options: {},
    speedDial:false,
    filter: {
      code:null,
      name: null,
      category_id: null,
      deleted_worker: false
    },
    categories: [],
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "workers.read"])) {
      this.$router.push('/admin');
    }

    this.fillBaseData();
  },
  methods:{
    opneUserAccount(item){
      if(item == null) return;

      if(item.user_id == null) return;

      this.$router.push('/' + this.$session.get('domain') + '/admin/accesses/users/' + item.user_id)
    },
    firstDateChanged(e){
        let dt = new Date(e)
        dt.setDate(dt.getDate() + 30);

        let month = new Date(e).getMonth() + 1
        let currentMonth = new Date().getMonth() + 1

        if(month == currentMonth)
            dt = new Date()

        this.worker.export.finished_at = dt.toISOString().substr(0, 10)
    },
    changedReport(e){
      this.reports.forEach(element => {
        if(element.id == e)
          this.all_days_available = element.all_days_available
      });
    },
    getTableDate(date) {
      return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
    },
    fillBaseData() {

      if(localStorage["workers-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["workers-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }

      // Exportações. Fim é o dia de hoje o Inicio são 30 dias antes, por defeito
      let dt = new Date(new Date().toISOString().substr(0, 10))
      dt.setDate(dt.getDate() - 30);
      this.worker.export.finished_at = new Date().toISOString().substr(0, 10);
      this.worker.export.started_at = dt.toISOString().substr(0, 10);
    },
    searchWorkers(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        page: this.options.page,
        limit: this.options.itemsPerPage,
        deleted_worker: this.filter.deleted_worker
      };
    
      Object.assign(request, filter);

      localStorage["workers-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      Worker.search(request).then(response => {

        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });

        this.loading = false;

      });
    },
    resetWorkers()
    {
      this.filter = {
      };

      this.searchWorkers();
    },
    viewWorker(item){
      let domain = '/' + this.$session.get('domain')
      
      this.$router.push(domain + '/admin/workers-settings/workers/' + item.id);
    },
    deleteWorker(item){
      if(this.filter.deleted_worker)
        item.deleted_worker = false;
      else
        item.deleted_worker = true
      
        item.category_id = item.category.id

      Worker.update(item, item.id)
        .then(() => {
          this.searchWorkers();
        });
    },
    async exportReport(){ 
      this.exportingDocument = true
      await Worker.exportReport(this.worker.export)
        .then((resp) => {

          if(this.worker.export.report == 'performance'){
            window.open(resp.data.url, '_blank')
            this.exporting=false;
            Export.delete(resp.data.path)
              .then(() => {
                
              });
            return
          }else{            
            if(resp.data.success)
              window.open(resp.data.object)
          }

          this.exporting=false;
        });

        this.exportingDocument = false
    }
  },
  computed: {
    headers(){
      let he = [
        {
          text: '',
          sortable: false,
          align: 'center',
          value: 'init'
        },
        {
          text: this.$t('globals.id'),
          sortable: false,
          value: 'code',
        },
        {
          text: this.$t('globals.name'),
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('globals.cost'),
          sortable: false,
          value: 'value',
        },
        {
          text: this.$t('globals.telephone'),
          sortable: false,
          value: 'phone_number',
        },
        {
          text: this.$t('globals.category'),
          sortable: false,
          value: 'category',
        },
        {
          text: this.$t('globals.company'),
          sortable: false,
          value: 'company.name',
        },
        { 
          text: this.$t('globals.options'),
          value: 'actions', 
          sortable: false 
        },
      ]
      return he
    },
    reports(){
      let r = [
        {
          id: "extra_hours",
          name: this.$t('globals.extra_hours'),
          all_days_available: false
        },
        {
          id: "work_entries",
          name: this.$t('globals.work_entry_point'),
          all_days_available: false
        },
        {
          id: "meals",
          name: this.$t('globals.meals'),
          all_days_available: false
        },
        {
          id: "night_work",
          name: this.$t('globals.night_work'),
          all_days_available: false
        },
        {
          id: "performance",
          name: this.$t('globals.performance'),
          all_days_available: false
        },
        {
          id: "holidays",
          name: this.$t('globals.holidays'),
          all_days_available: true
        },
      ]
      return r
    },
    tableFooter(){
      let t = this.$t("globals.lines_datatable")
      let a = [5, 10, 15, 20];
      return {
        'items-per-page-options': a,
        'items-per-page-text': t
      }
    }, 
    crumbs: function() {
      return [
        {
          text: this.$t('menu.dashboard'),
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: this.$t('menu.workers_management'),
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
