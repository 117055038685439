<template>
    <v-card elevation="10" :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75);'" :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
      <v-tabs :class="!client ? 'elevation-10' : 'elevation-10'" v-model="tab" :background-color="$root.$vuetify.theme.isDark ? 'secondary' : 'primary'" dark fixed-tabs>
        <v-tab>{{$t('client.tabs.general')}}</v-tab>
        <v-tab :disabled="method == 'create'">{{$t('client.tabs.works')}}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" touchless :class="($root.$vuetify.theme.isDark ? 'secondary' : '') + ' elevation-10'">
        <v-container class="mb-n12">
          <v-tab-item class="mt-6" :key="1">
            <validation-observer ref="form" v-slot="{ invalid }">
            <v-form @submit.prevent="submit"  class="mb-16">
                <v-progress-linear v-if="loading"
                    indeterminate
                    class="global-loader"
                ></v-progress-linear>
                <fieldset :disabled="loading">                  
                  <v-card class="transparent elevation-0">
                    <v-row class="mt-2">
                        <v-col cols="12" md="3">
                            <validation-provider v-slot="{ errors }" vid="reference" :name="$t('globals.reference')" rules="required">
                                <v-text-field
                                    v-model="fields.reference"
                                    prepend-inner-icon="mdi-barcode"
                                    :label="$t('globals.reference') + ' *'"
                                    clearable
                                    :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="9">
                            <validation-provider v-slot="{ errors }" vid="name" :name="$t('globals.name')" rules="required">
                                <v-text-field
                                    v-model="fields.name"
                                    prepend-inner-icon="mdi-account-details"
                                    :label="$t('globals.name') + ' *'"
                                    clearable
                                    :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" vid="description" :name="$t('globals.nif')" rules="required">
                                <v-text-field
                                    v-model="fields.nif"
                                    prepend-inner-icon="mdi-ticket-confirmation"
                                    :label="$t('globals.nif') + ' *'"
                                    clearable
                                    :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="5">
                            <validation-provider v-slot="{ errors }" vid="unit" :name="$t('globals.email')" rules="email">
                                <v-text-field
                                    v-model="fields.email"
                                    prepend-inner-icon="mdi-email-box"
                                    :label="$t('globals.email')"
                                    clearable
                                    :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                v-model="fields.telephone"
                                prepend-inner-icon="mdi-phone"
                                :label="$t('globals.contact')"
                                clearable
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <small>{{$t('client.defect_window')}}</small>
                        </v-col>                        
                    </v-row>
                  </v-card>
                
                <v-row class="mt-6" align="center" justify="space-around">
                    <v-btn :disabled="invalid" depressed :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary'" type="submit">
                    {{$t('globals.save')}}
                    </v-btn>
                </v-row>
                </fieldset>
            </v-form>
            <DialogSuccess :opened="success" @on-ok="onSuccess" >
                {{$t('client.success_add')}}
            </DialogSuccess>
            </validation-observer>
          </v-tab-item>
          <v-tab-item class="mt-6" :key="2">
            <ClientWorks v-if="method == 'update'"/>
          </v-tab-item>
        </v-container>
      </v-tabs-items>
    </v-card>
  </template>
  <script>
  import ClientAPI from "@/api/Client.js";
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  import ClientWorks from '@/components/clients/ClientWorks.vue'
  
  export default {
    name: 'ClientComponent',
    components: {
      DialogSuccess,
      ClientWorks
    },
    props: {
     client: Object,
     method: String,
     disabled: Boolean,
   },
   watch: { 
    client: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;
  
          this.fields.id = data.id;
        }
      },
    },
    beforeCreate(){
    },
    data(){
        return{
            loading: false,
            success:false,
            tab: 0,
            fields:{
                name: '',
                reference: null,
                nif: null,
                email: null,
                telephone: null,
                deleted_client: false
            }
        }
    },
    mounted(){
      this.fillBaseData();
    },
    methods:{
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        
        let domain = '/' + this.$session.get('domain')
        this.$router.push(domain + '/admin/clients');
        

        
      },
      fillBaseData(){
      },
      submit() {
  
        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }
  
          let data = {...this.fields};
  
         
  
          ClientAPI[this.method](data, this.fields.id).then(() => {
  
            this.success = true;
  
            this.loading = false;

            
          }).catch(err => {
            this.loading = false;
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = this.$t('globals.error') + " " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })
      }
    },
    computed: {    
    }
  };
  </script>
  