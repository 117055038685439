<template>
    <section class="mt-16">
        <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
    <div>
        <v-row>
            <v-col cols="12" md="12">
                <h2 class="primary--text">{{$t('logs.title')}}</h2>
                    <v-row>
                        <v-col>
                            <div style="position:relative">
                                <v-container>
                                    <v-speed-dial
                                    absolute
                                    v-model="speedDial"
                                    top
                                    right
                                    direction="bottom"
                                    :open-on-hover="true"
                                    >
                                    <template v-slot:activator>
                                        <v-tooltip right color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                v-bind="attrs" v-on="on"
                                                v-model="speedDial"
                                                color="primary"
                                                dark
                                                x-large
                                                fab
                                                >
                                                <v-icon v-if="speedDial">
                                                    mdi-close
                                                </v-icon>
                                                <v-icon v-else>
                                                    mdi-dots-vertical
                                                </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{$t('globals.options')}}</span>
                                        </v-tooltip>
                                    </template>
                                    <v-tooltip right color="secondary">
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-bind="attrs" v-on="on"
                                            fab
                                            dark
                                            small
                                            color="secondary"
                                            large
                                            @click="searching = true"
                                        >
                                            <v-icon>mdi-filter</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>{{$t('globals.search')}}</span>
                                    </v-tooltip>
                                    </v-speed-dial>
                                </v-container>
                                <v-data-table
                                    :headers="headers"
                                    :items="items"
                                    :server-items-length="total"
                                    :loading="loading"
                                    :footer-props="tableFooter"
                                    :options.sync="options"
                                    :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
                                    locale="pt-pt"
                                    >
                                    <template v-slot:item.created_at="{ item }">
                                        {{ formatDate(item.created_at)}}
                                    </template>
                                </v-data-table>
                            </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
    <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetFields">
                {{$t('globals.clean_fields')}}
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchLogs">
            
            <v-list-item>
                <v-autocomplete
                    v-model="filter.logActions"
                    :items="speedDialOptions.logActions"
                    prepend-inner-icon="mdi-barcode"
                    :label="$t('globals.type')"
                ></v-autocomplete>
            </v-list-item>     
            
            <v-list-item>
                <v-autocomplete
                    v-model="filter.logUsers"
                    :items="speedDialOptions.users"
                    prepend-inner-icon="mdi-text"
                    :item-text="item => item.username"
                    item-value="id"
                    :label="$t('globals.user')"
                ></v-autocomplete>
            </v-list-item>  

            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              {{$t('globals.search')}}
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
      </v-navigation-drawer>
    </section>
</template>
<script>
import Logger from "@/api/Logger.js";

export default {
    watch: {
        'options': {
        immediate: false,
        handler () {
            setTimeout(() => {this.searchLogs()}, 10);
        },
        deep: true,
        },
    },
    data: () => ({
        speedDialOptions:{
            logActions: null,
            users: null
        },
        speedDial: false,
        loading: false,
        items: [],
        searching: false,
        total: 0,
        options: {},
        filter: {
            log:null,
            logActions: null,
            logUsers: null
        },
    }),
    mounted(){
        this.searchLogs();
        let parent = this
        Logger.getLoggerVars().then(({data}) => {
            parent.speedDialOptions.logActions = data.logActions
            parent.speedDialOptions.users = data.distinctUsers
        });
    },
    computed:{
        headers(){
            let he = [
                {
                    text: this.$t('globals.action'),
                    sortable: true,
                    value: 'log'
                },
                {
                    text: this.$t('globals.user'),
                    sortable: false,
                    value: 'user[0].name'
                },
                {
                    text: this.$t('globals.data'),
                    sortable: true,
                    value: 'created_at'
                },
                {
                    text: this.$t('globals.parameters'),
                    sortable: false,
                    value: 'parameters'
                },
            ]
            return he
        },
        tableFooter(){
          let t = this.$t("globals.lines_datatable")
          let a = [5, 10, 15, 20];
          return {
            'items-per-page-options': a,
            'items-per-page-text': t
          }
        }, 
        crumbs: function() {
            return [
                {
                text: this.$t('globals.definitions'),
                disabled: false,
                to: '/' + this.$session.get('domain') + '/admin/settings/general',
                exact: true,
                },
                {
                text: this.$t('logs.title'),
                disabled: true,
                to: window.location.href,
                exact: true,
                },
            ];
            }
    },
    methods:{
        resetFields(){
            this.filter = {
                log:null,
                logActions: null,
                logUsers: null
            }
            this.searchLogs()
        },
        row_classes(i){
            if(i.readed == true)
                return '';
        },
        formatDate(d){
            return new Date(d).toLocaleString('pt-PT')
        },
        searchLogs(event){
            if(!!event && event.type == 'submit'){
                this.options.page = 1;
            }

            this.loading = true;

            let filter = {...this.filter};
            let request = {
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                page: this.options.page,
                limit: this.options.itemsPerPage,
            };
            
            
            Object.assign(request, filter);

            localStorage["logs-" + this.$root.session.id] = JSON.stringify({
                filter: this.filter,
                options: this.options,
            });
            

            Logger.search(request).then(response => {

                let data = response.data;

                this.total = data.total;

                this.items = data.data.map((item) => {


                    this.searching = false;

                    return item;
                });

                this.loading = false;

            });
        }
    }
}
</script>