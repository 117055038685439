<template>
    <v-container fluid>
        <v-dialog
            v-model="dialog"
            persistent
            transition="dialog-bottom-transition"
            max-width="950"
        >
        <!-- DESKTOP -->
        <v-card class="d-none d-lg-block" style="overflow: hidden;">
            <v-card-title :class="this.$root.$vuetify.theme.isDark ? 'text-h5 grey lighten-2' : 'primary white--text'">
            {{$t('how_to_do.welcome')}}
            </v-card-title>

            <v-card-text>
                <v-container fluid>
                    <iframe :src="'https://app.supademo.com/embed/E0zXHDEFOjQhsMHCZVDjH'" frameborder="0" 
                            class="mt-6"
                            webkitallowfullscreen="true" 
                            mozallowfullscreen="true"
                            allowfullscreen
                            width="100%" height="500"
                        >
                    </iframe>
                </v-container>
            </v-card-text>
            <v-divider class="mt-n8"></v-divider>

            <v-card-actions>
                <v-row justify="center">
                    <v-col cols="12" align="center">
                        <v-btn
                            color="primary"
                            @click="dialog = false"
                        >
                            {{$t('how_to_do.avancar')}}
                            <v-icon class="ml-2">mdi-arrow-right-bold</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <!-- MOBILE -->
        <v-container fluid class="d-md-none ma-0 pa-0">
            <v-card class="elevation-0 ma-0 pa-0">
                <v-card-title :class="this.$root.$vuetify.theme.isDark ? 'text-body-1' : 'primary white--text text-body-1'">
                    {{$t('how_to_do.welcome')}}
                </v-card-title>
                <v-card-text class="mt-2">
                    <v-row>
                        <v-col cols="12" align="center">
                            {{$t('how_to_do.welcome_intro')}}
                        </v-col>
                        <v-col cols="12" align="center">
                            <v-btn class="success" block @click="openVideo">
                                <v-icon class="mr-2">mdi-video-box</v-icon>{{$t('how_to_do.welcome_mobile_btn')}}
                            </v-btn>
                        </v-col>
                        <v-col cols="12" align="center">
                            <v-btn class="primary" block @click="dialog = false">
                                <v-icon class="mr-2">mdi-arrow-right-bold</v-icon>{{$t('how_to_do.avancar')}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>
        </v-dialog>
    </v-container>
</template>
<script>
import Dashboard from '@/api/Dashboard.js'

export default{
    name:"FirstTime",
    data(){
        return{
            dialog: true
        }
    },
    mounted(){
        Dashboard.markTutorial(this.$root.session.id).then(() => {
            
        });
    },
    methods:{
        openVideo(){
            window.open('https://app.supademo.com/embed/E0zXHDEFOjQhsMHCZVDjH', '_blank')
        }
    }
}
</script>