<template>
    <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''" v-if="loading == false">
      <v-card-title class="primary white--text">
          {{$t('diaries.work_division')}}
      </v-card-title>
      <validation-observer ref="form" v-slot="{ invalid }">
          <v-form @submit.prevent="submitWorkers" :disabled="loading || diary_status == 'FINISHED_APPROVAL' && locked_edition">
              <v-card-text>
                  <v-simple-table>
                      <template v-slot:default>
                          <thead>
                              <tr>
                                  <th class="text-left">
                                  {{$t('globals.material')}}
                                  </th>
                                  <th class="text-left">
                                  {{$t('globals.quantity')}}
                                  </th>
                              </tr>
                          </thead>
                          <tbody :class="$root.$vuetify.theme.isDark ? 'secondary' :''">
                              <tr 
                                  v-for="material in divisionFields"
                                  v-bind:key="material.material_id"
                              >
                                  <td>
                                      {{ material.material_name }}
                                  </td>
                                  <td>
                                      <!-- 
                                          No modelo temos de ir buscar as horas trabalhadas, para aquela linha. 
                                          Procurar no array worker_work_units qual a linha que corresponde
                                          ao ID do user e ao ID da unidade
                                      -->
                                      <validation-provider v-slot="{ errors }" vid="quantity" :name="$t('globals.quantity')" rules="required">
                                      <v-text-field
                                            :readonly="locked_edition"
                                            v-model="material.quantity"
                                            :label="$t('globals.quantity')+  ' *'"
                                            :error-messages="errors"
                                      ></v-text-field>
                                      </validation-provider>
                                  </td>
                              </tr>
                          </tbody>
                      </template>
                  </v-simple-table>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions v-if="!locked_edition">
                  <v-btn
                      dark
                      small
                      color="secondary"
                      @click="divideAutomaticHours"
                      >
                      <v-icon small>mdi-refresh-auto</v-icon>{{$t('diaries.divide_hours')}}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn :disabled="invalid" depressed :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary'" type="submit">
                      {{$t('globals.save')}}
                  </v-btn>    
              </v-card-actions>
          </v-form>
      </validation-observer>
      <SuccessSnackBar v-model="modalSuccess.show" :message="modalSuccess.message" />
      <ErrorSnackbar v-model="error.show" :message="error.message"/>
      </v-card>
</template>
<script>
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';

export default{
  name: "WorkHandDivision",
  components:{
      ErrorSnackbar,
      SuccessSnackBar,
  },
  props:{
      diary_status: String,
      locked_edition: Boolean,
      work_unit_id: String,
      fields: Object,
      auto_divider: Boolean
  },
  data(){
      return{
          divisionFields: [],
          dialog: false,
          loading: true,
          modalSuccess:{
              show: false,
              message: ''
          },
          error:{
              show: false,
              message: ''
          }
      }
  },
  mounted(){
      this.loading = true;
      this.fillBaseData();
      
      if(this.auto_divider)
          this.divideAutomaticHours()
      
      this.loading = false
  },
  methods:{
      fillBaseData(){
          this.fields.materials.forEach(element => {
              let material_object = null;

              this.fields.material_work_units.forEach(w => {
                  if(element.id == w.material_id && w.work_unit_id == this.work_unit_id)
                  material_object = w
              })

              if(material_object == null){
                  this.divisionFields.push({
                      id: 'new_' + element.id,
                      material_id: element.id,
                      material_name: element.name,
                      quantity: null,
                      work_unit_id: this.work_unit_id
                  })
              }else{
                  this.divisionFields.push({
                      id: material_object.work_unit_id,
                      material_id: element.id,
                      material_name: element.name,
                      quantity: material_object.quantity,
                      work_unit_id: this.work_unit_id
                  })
              }
          });
      },
      submitWorkers(){
          this.$emit('submit', this.divisionFields)
      },
      divideAutomaticHours(){   
        this.divisionFields.forEach(element => {         
            this.fields.materials.forEach(material => {
                if(element.material_id == material.id){
                    let quantity_divided = material.quantity
                    if(quantity_divided < 0){
                        this.error.message = "Há valores de quantidades inferiores a zero"
                        this.error.show = true
                        return
                    }
                    quantity_divided = (quantity_divided / this.fields.work_units.length).toFixed(2)

                    element.quantity = quantity_divided
                }
            });
        });
      }
  }
}
</script>