<template>
    <validation-observer ref="form" v-slot="{ invalid }">
      <v-form @submit.prevent="submit"  class="mb-16 mt-n10">
         <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
        <fieldset :disabled="loading">
            <v-stepper v-model="stepperModel" v-if="method == 'create'">
                <v-stepper-header>
                    <v-stepper-step
                        :complete="stepperModel > 1"
                        step="1"
                    >
                        {{$t('holidays.select_worker_user')}}
                    </v-stepper-step>
                    <v-divider></v-divider>

                    <v-stepper-step
                        :complete="stepperModel > 2"
                        step="2"
                    >
                    {{$t('holidays.select_year')}}
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        :complete="stepperModel > 3"
                        step="3"
                    >
                    {{$t('holidays.schedule_holiday')}}
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1" :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">                      
                        <v-row>
                            <v-col cols="12">
                                <p class="text-h6 font-weight-light mb-n2">{{$t('holidays.select_worker')}}:</p>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-select
                                    v-model="fields.worker_id"
                                    :items="workers"
                                    item-value="id"
                                    :item-text="item => item.name"
                                    menu-props="auto"
                                    label="Colaboradores"
                                    prepend-inner-icon="mdi-account"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="8">                    
                                <v-btn :disabled="fields.worker_id == null" color="success" class="mt-2" @click="selectedPerson">
                                    <v-icon>mdi-magnify</v-icon>{{$t('globals.select')}}
                                </v-btn>
                            </v-col>
                            <v-col cols="12"><v-divider></v-divider></v-col>
                        </v-row>          
                        <v-col cols="12">
                            <p class="text-h6 font-weight-light mb-n2">{{$t('holidays.select_user')}}:</p>
                        </v-col>              
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-select
                                    v-model="fields.user_id"
                                    :items="users"
                                    item-value="id"
                                    :item-text="item => item.name"
                                    menu-props="auto"
                                    :label="$t('globals.users')"
                                    prepend-inner-icon="mdi-account"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="8">                    
                                <v-btn :disabled="fields.user_id == null" color="success" class="mt-2" @click="selectedPerson">
                                    <v-icon>mdi-magnify</v-icon>{{$t('globals.select')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>
                    <v-stepper-content step="2" :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                        <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                            <p class="text-h6 font-weight-light mb-n2">{{$t('holidays.select_year')}}:</p>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" md="4">                                
                                        <v-select
                                            v-model="fields.year"
                                            :items="getYears"
                                            menu-props="auto"
                                            :label="$t('globals.year')"
                                            prepend-inner-icon="mdi-calendar-blank"
                                        ></v-select>
                                    </v-col>                                    
                                    <v-col cols="12" md="8">                    
                                        <v-btn :disabled="fields.year == null" color="success" class="mt-2" @click="goToLastStep">
                                            <v-icon>mdi-arrow-right-bold</v-icon>{{$t('globals.next')}}
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12">
                                        <p class="caption font-weight-bold mt-n6">({{$t('holidays.defect_year')}})</p>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-stepper-content>
                    <v-stepper-content step="3" :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                        <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                            <p class="text-h6 font-weight-light mb-n2">{{$t('holidays.schedule_holidays_msg1')}} {{ selectedUser }} {{$t('holidays.schedule_holidays_msg2')}} {{ fields.year }}</p>
                            <v-card-text>
                                <v-row>
                                    <v-col
                                    cols="12"
                                    sm="6"
                                    >
                                    <v-date-picker
                                        v-model="fields.days"
                                        multiple
                                        full-width
                                        no-title
                                    ></v-date-picker>
                                    </v-col>
                                    <v-col
                                    cols="12"
                                    sm="6"
                                    >
                                    <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        :return-value.sync="fields.days"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-combobox
                                            v-model="fields.days"
                                            multiple
                                            chips
                                            small-chips
                                            :label="$t('holidays.days_holiday')"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-combobox>
                                        </template>
                                        <v-date-picker
                                        v-model="fields.days"
                                        multiple
                                        no-title
                                        scrollable
                                        >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="menu = false"
                                        >
                                            {{$t('globals.cancel')}}
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.menu.save(fields.days)"
                                        >
                                            {{$t('globals.ok')}}
                                        </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                    </v-col>
                                </v-row>
                                
                                <v-row class="mt-6" align="center" justify="space-around">
                                    <v-col align="center" justify="space-around">
                                    <v-btn :disabled="invalid" depressed :class="($root.$vuetify.theme.isDark ? 'white primary--text' : 'primary') + ' mr-5 ml-5 mb-5'" type="submit">
                                        {{$t('globals.save')}}
                                    </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-stepper-content>
                    </v-stepper-items>
            </v-stepper>
            <v-container v-else>
                <v-row>
                    <v-col cols="12" md="6" v-if="fields.worker_id != null">
                        <v-card>
                            <v-card-title class="font-weight-bold">{{$t('globals.workers')}}</v-card-title>
                            <v-card-text class="mt-n6">
                            <v-select
                                :disabled="true"
                                v-model="fields.worker_id"
                                :items="workers"
                                item-value="id"
                                :item-text="item => item.name"
                                menu-props="auto"
                                :label="$t('globals.workers')"
                                prepend-inner-icon="mdi-account"
                            ></v-select> 
                            </v-card-text>     
                        </v-card>
                    </v-col>                    
                    <v-col cols="12" md="6" v-if="fields.user_id != null">
                        <v-card class="mb-4">
                            <v-card-title class="font-weight-bold">{{$t('globals.users')}}</v-card-title>
                            <v-card-text class="mt-n6">
                                <v-select
                                    :disabled="true"
                                    v-model="fields.user_id"
                                    :items="users"
                                    item-value="id"
                                    :item-text="item => item.name"
                                    menu-props="auto"
                                    prepend-inner-icon="mdi-account"
                                ></v-select>
                            </v-card-text>
                        </v-card>
                </v-col>                        
                    <v-col cols="12" md="6">   
                        <v-card class="mb-4">
                            <v-card-title class="font-weight-bold">{{$t('globals.year')}}</v-card-title>
                            <v-card-text class="mt-n6">                             
                                <v-select
                                    :disabled="true"
                                    v-model="fields.year"
                                    :items="getYears"
                                    menu-props="auto"
                                    prepend-inner-icon="mdi-calendar-blank"
                                ></v-select>
                            </v-card-text>
                        </v-card>
                    </v-col> 
                </v-row>
                <v-row v-if="method == 'update'">
                    <v-col
                    cols="12"
                    sm="6"
                    >
                    <v-date-picker
                        color="warning"
                        v-model="fields.days"
                        multiple
                        full-width
                    ></v-date-picker>
                    </v-col>
                    <v-col
                    cols="12"
                    sm="6"
                    >
                    <v-card>
                        <v-card-text>
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="fields.days"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-combobox
                                    v-model="fields.days"
                                    multiple
                                    chips
                                    small-chips
                                    :label="$t('holidays.days_holiday')"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <template v-slot:selection="{ item, parent, selected }">
                                        <v-chip
                                        color="primary"
                                        :input-value="selected"
                                        small
                                        >
                                        <span class="pr-2">
                                            {{ item }}
                                        </span>
                                        </v-chip>
                                    </template>
                                </v-combobox>
                                </template>
                                <v-date-picker
                                v-model="fields.days"
                                multiple
                                no-title
                                scrollable
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    {{$t('globals.cancel')}}
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(fields.days)"
                                >
                                    {{$t('globals.ok')}}
                                </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-card-text>
                        <v-card-title class="mt-n6">{{$t('globals.obs')}}</v-card-title>
                        <v-card-text>
                            <v-row class="mt-n10">
                                <v-col cols="12">
                                    <v-textarea
                                        name="input-3-1"
                                        auto-grow
                                        rows="2"
                                        v-model="fields.obs"
                                        :label="fields.obs == null ? $t('holidays.no_data') : ''"
                                        :value="fields.obs"
                                        :hint="$t('holidays.obs')"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    </v-col>
                </v-row>
                
                <v-row class="mt-6" align="center" justify="space-around">
                    <v-col align="center" justify="space-around">
                    <v-btn :disabled="invalid" depressed color="warning" class="mr-5 ml-5 mb-5" type="submit">
                        {{$t('globals.save')}}
                    </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </fieldset>
      </v-form>
      <DialogSuccess :opened="success" @on-ok="onSuccess" >
          {{$t('holidays.success_save')}}
      </DialogSuccess>
    <ErrorSnackbar v-model="error.show" :message="error.message"/>
    </validation-observer>
  </template>
  <script>
    import Holiday from "@/api/Holiday.js";
    import DialogSuccess from '@/components/ui/DialogSuccess.vue';
    import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
    import User from "@/api/User.js";
    import Worker from "@/api/Worker.js";
  
  export default {
    components: {
      DialogSuccess,
      ErrorSnackbar
    },
    props: {
     holiday: Object,
     method: String,
     disabled: Boolean,
   },
   watch: { 
    holiday: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;

          this.fields.worker_id = this.fields.id_worker
          this.fields.user_id = this.fields.id_user
        }
      },
    },
    mounted(){
        this.fillBaseData()

    },
    data: () => ({
        selectedUser: '',
        stepperModel:1,
        menu: false,
        workers: [],
        users: [],
        fields: {
            id: null,
            days: [],
            worker_id: null,
            user_id: null,
            year: null,
            obs: null
        },
        loading:false,
        error: {
            title: '',
            message: '',
            show: false,
        },
        success: false,
    }),
    methods:{
        async goToLastStep(){
            await Holiday.exists(this.fields).then((data) => {
                if(data.data.holiday != null)
                    this.fields.days = data.data.holiday.days

            });
            this.stepperModel = 3
        },
        selectedPerson(){
            if(this.fields.worker_id != null){
                this.workers.forEach(element => {
                    if(element.id == this.fields.worker_id)
                        this.selectedUser = element.name
                });
            }else{
                this.users.forEach(element => {
                    if(element.id == this.fields.user_id)
                        this.selectedUser = element.name
                });
            }
            this.stepperModel = 2
        },
        async fillBaseData(){
            let parent = this
            this.loading = true
            await Worker.list().then(({data}) => {
                parent.workers = data;
            });
            await User.list().then(({data}) => {
                parent.users = data;
            });
            this.loading = false
        },
        onSuccess() {
            if(this.fields.id != null) {
            this.success = false
            return
            }
            let domain = '/' + this.$session.get('domain')
            this.$router.push(domain + '/admin/workers-settings/holidays');
        },
        submit() {
    
            this.$refs.form.validate().then((result) => {
            this.loading = true;
            
            if(!result){
                this.loading = false;
                return;
            }

            let first = new Date(this.fields.year + '-01-01')
            let last = new Date(this.fields.year + '-12-31')

            let goOn = true
            this.fields.days.forEach(element => {
                let check = new Date(element)
                
                if(check < first || check > last){
                    goOn = false
                }
                
            });

            if(goOn == false){
                this.error.message = "Data(s) selecionada(s) não correspondem ao ano que selecionou"
                this.error.show = true
                this.loading = false;
                return;
            }

    
            Holiday[this.method](this.fields, this.fields.id).then(() => {
    
                this.success = true;
    
                this.loading = false;
                
            }).catch(err => {
                this.loading = false;
    
                if(err.response.status == 422) {
    
                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }
    
                this.error.title = this.$t('globals.error') + " " + err.response.status;
    
                this.error.message = err.response.data.message;
    
                });
            })
        }
        },
        computed: {
            getYears(){
                let year = 2023;
                let ar = [];
                let currentTime = new Date();
                let currentYear = currentTime.getFullYear();

                for(year; year <= currentYear; year++)
                    ar.push(year)

                return ar
            }
        }
  };
  </script>
  