<template>
    <v-app>
      <div class="bg-email-confirmation">
      </div>
      <v-main>
        <v-container fluid class="mt-16">
          <v-row justify="center" align="center">
            <v-col cols="12" md="12">
              <v-img
                style="margin: auto; display: block;"
                lazy-src="@/assets/icons/persona dreamdeal tech.png"
                src="@/assets/icons/persona dreamdeal tech.png"
                max-width="450"
                contain
                ></v-img>
            </v-col>
          </v-row>
          <v-row v-if="!loading" justify="center" align="center" class="ma-0 pa-0 mt-16">
            <v-col cols="12" md="6">
              <v-container v-if="subscriptionsRegistered.length <= 0" class="ma-0 pa-0">              
              <validation-observer
                ref="observador"
                v-slot="{ invalid }"
              >
                <v-form @submit.prevent="submit">
                    <v-card>
                      <v-card-title class="text-center blue-primary white--text text-h4">
                        <span style="margin: auto; display: block;">{{$t('subscription.inactive')}}</span>
                      </v-card-title>
                      <v-card-title class="mt-n4 text-center blue-primary white--text text-h6">
                        <v-btn @click="logout" outlined style="display: block; margin: auto;" class="blue-primary white--text">
                          <v-icon class="mr-2">mdi-logout</v-icon>Logout
                        </v-btn>
                      </v-card-title>
                        <v-card-text class="mt-4" v-if="$root.session.hasPermission(['super', 'billings.read'])">                          
                            <v-row justify="center" v-if="!loading">
                              <v-col cols="12" md="auto" class="d-flex flex-column align-center justify-center">
                                <span class="font-weight-bold">{{$t('billings.communication')}}</span>
                              </v-col>
                              <v-col cols="12" md="auto">
                                  <v-img 
                                      max-width="100"
                                      lazy-src="@/assets/eupago/eupago.png"
                                      src="@/assets/eupago/eupago.png"></v-img>      
                              </v-col>
                            </v-row>
                            <v-divider class="mt-2 mb-2"></v-divider>
                            <v-row justify="center">
                                <v-col
                                    cols="12"
                                    md="12"
                                >
                                    <validation-provider :name="$t('subscription.type')" :vid="$t('subscription.type')" rules="required" v-slot="{ errors }">
                                        <v-select
                                            v-model="fields.subscriptionType"
                                            :items="subscriptions"
                                            item-value="id"
                                            :item-text="item => item.type + ' - ' + item.price + $store.state.coin"
                                            menu-props="auto"
                                            :label="$t('subscription.type') + ' *'"
                                            prepend-inner-icon="mdi-list-box"
                                            :error-messages="errors"
                                        ></v-select>
                                    </validation-provider>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="12"
                                >
                                    <validation-provider :name="$t('subscription.payment_method')" :vid="$t('subscription.payment_method')" rules="required" v-slot="{ errors }">
                                        <v-select
                                            v-model="fields.paymentMethod"
                                            :items="$t('subscription.payments')"
                                            menu-props="auto"
                                            item-text="name"
                                            item-value="id"
                                            :label="$t('subscription.payment_method') + ' *'"
                                            prepend-inner-icon="mdi-list-box"
                                            :error-messages="errors"
                                        ></v-select>
                                    </validation-provider>
                                </v-col>
                            </v-row>                 
                            <v-row justify="center">
                                <v-col
                                cols="12"
                                md="auto"
                                >
                                <v-btn
                                    depressed
                                    color="#111b2d"
                                    style="color: white;"
                                    type="submit"
                                    :disabled="invalid"
                                    large
                                    rounded
                                >
                                    {{$t('subscription.subscribe')}}
                                </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-text v-else class="text-center" v-html="$t('subscription.no_permission')">
                        </v-card-text>                        
                    </v-card>
                </v-form>                
              </validation-observer>
              
            </v-container>
            <v-container v-else>
              <v-card>
                <v-card-title class="blue-primary text-h6 white--text text-center">
                  <span style="margin: auto; display: block;">{{$t('subscription.pendent_title')}}</span>
                </v-card-title>
                <v-card-title class="mt-n4 text-center blue-primary white--text text-h6">
                  <v-btn @click="logout" outlined style="display: block; margin: auto;" class="blue-primary white--text">
                    <v-icon class="mr-2">mdi-logout</v-icon>Logout
                  </v-btn>
                </v-card-title>
                <v-card-text class="mt-4 text-center" v-html="$t('subscription.pendent')"></v-card-text>
                <v-card-text v-for="(sr, index) in subscriptionsRegistered" :key="index">
                  <v-row v-if="sr.payment_method == 'MB'">
                    <v-simple-table style="width: 100%;">
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td><strong>{{$t('subscription.entity')}}</strong>: {{ sr.metadata.entidade }}</td>
                            <td><strong>{{$t('subscription.reference')}}</strong>: {{ sr.metadata.referencia }}</td>
                            <td><strong>{{$t('subscription.value')}}</strong>: {{ priceParsed(sr.metadata.valor) }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>
            
            </v-col>
          </v-row>
        </v-container>
      </v-main>
      <ErrorSnackbar v-model="error.show" :message="error.message"/>
    </v-app>
  </template>
  <style>
  .text-white input {
      color: white !important;
    }
    .text-white label {
      color: white !important;
    }
    .text-white i {
      color: white !important;
    }
    .text-white v-messages__wrapper{
      color: white !important;
    }
  
  .bg-email-confirmation {
      background: url( '../assets/login/pexels-photo-3369239.jpeg') no-repeat;
      width: 100%;
      height: 100%;
      position: absolute;
      background-size: cover;
    }
    @media (min-width: 600px) {
      .bg-email-confirmation {
      
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        position: absolute;
        background: url( '../assets/login/pexels-photo-3369239.jpeg') no-repeat;
        
        background-size: cover;
        /*-webkit-filter: grayscale(100%); 
        filter: grayscale(100%);
        transform: scale(1.1);*/
      }
  
      .center-button{
        width: 80%;
      }
  }
  </style>
<script>
import Billings from '@/api/Billings.js'
import Configurations from '@/api/Configurations.js'
import User from '@/api/User.js'
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';


export default{
    name: 'TrialOver',
    components:{
      ErrorSnackbar
    },
    data(){
        return{
            loading: true,
            subscriptions: [],
            methods: ['Multibanco'],
            subscriptionsRegistered: [],
            fields:{
              paymentMethod: null,
              subscriptionType: null,
              creditCardInfo: {
                name: '',
                cardNumber: '',
                expiration: '',
                security: '',
                authorize: false
              }
            },
            error:{
              show: false,
              message: ''
            }
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
      logout() {
        User.logout(this.$root.session.id)
          .then(() => {
            localStorage['auth'] = 0;
  
            this.$router.push('/');
          });
      },
      priceParsed(p){
        return parseInt(p).toFixed(2) + '€'
      },
      async fillBaseData(){
        await User.getSession(false).then(async ({data}) =>  {
          this.user = User.getInstance(data);
      
          this.$root.session = this.user;
        })

        Billings.getUnpaid().then((resp) => {
            this.subscriptionsRegistered = resp.data
        })

        Billings.getPlans().then((resp) => {
            this.subscriptions = resp.data
        })

        await Configurations.get(this.$session.get('domain')).then((resp) => {

          if(!resp.data.trial_over)
            this.$router.push('/')



        }).catch((error) => {

          console.error(error);

          localStorage['auth'] = 0;

          this.$router.push('/');
        });

        this.loading = false
      },
      async submit(){          
          await Billings.requestPayment(this.fields).then((resp) => {

            if(this.fields.paymentMethod == 'MBW'){

              let response_object = JSON.parse(resp.data.msg);
              if(response_object.transactionStatus == 'Rejected'){
                this.error.message = response_object.text
                this.error.show = true
                return;
              }
            }

            this.paymentMessage = resp.data
            this.fillBaseData()

            if(this.fields.paymentMethod == 'CC'){
              if(resp.data.success){
                let object = JSON.parse(resp.data.msg)

                window.open(object.redirectUrl, '_blank')
              }
            }
          })
      }
    }
}
</script>
<style>
.blue-primary{
  background: #111b2d;
}
</style>