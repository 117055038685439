var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"mb-16",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card',{class:_vm.$root.$vuetify.theme.isDark ? 'secondary' : ''},[(_vm.loadPage)?_c('v-progress-linear',{staticClass:"global-loader",attrs:{"indeterminate":""}}):_vm._e(),_c('v-card-text',[(!_vm.loadPage)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"Ficheiro","name":_vm.$t('globals.file'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.files,"menu-props":"auto","label":_vm.$t('globals.file'),"item-text":"name","item-value":"id","error-messages":errors,"persistent-hint":"","hint":_vm.$t('globals.select_file_type'),"prepend-icon":"mdi-file-delimited","single-line":""},on:{"change":_vm.changedDocument},model:{value:(_vm.fields.fileType),callback:function ($$v) {_vm.$set(_vm.fields, "fileType", $$v)},expression:"fields.fileType"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.workSelect)?_c('validation-provider',{attrs:{"vid":"Obra","name":_vm.$t('globals.work'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.works,"menu-props":"auto","error-messages":errors,"label":_vm.$t('globals.work'),"item-text":"name","item-value":"id","persistent-hint":"","hint":_vm.$t('globals.select_work'),"prepend-icon":"mdi-hard-hat","single-line":""},model:{value:(_vm.fields.workId),callback:function ($$v) {_vm.$set(_vm.fields, "workId", $$v)},expression:"fields.workId"}})]}}],null,true)}):_vm._e(),(_vm.machineSelect)?_c('validation-provider',{attrs:{"vid":"Máquinas","name":_vm.$t('globals.machine'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.machines,"menu-props":"auto","error-messages":errors,"label":_vm.$t('globals.machine'),"item-text":"name","item-value":"id","persistent-hint":"","hint":_vm.$t('globals.select_machine'),"prepend-icon":"mdi-hard-hat","single-line":""},model:{value:(_vm.fields.machine_id),callback:function ($$v) {_vm.$set(_vm.fields, "machine_id", $$v)},expression:"fields.machine_id"}})]}}],null,true)}):_vm._e(),(_vm.uoSelect)?_c('validation-provider',{attrs:{"vid":"Unidade Obra","name":_vm.$t('globals.work_unit'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.units,"menu-props":"auto","error-messages":errors,"label":_vm.$t('globals.work_unit'),"item-text":"name","item-value":"id","persistent-hint":"","hint":_vm.$t('globals.select_work_unit'),"prepend-icon":"mdi-hard-hat","single-line":""},model:{value:(_vm.fields.unit_id),callback:function ($$v) {_vm.$set(_vm.fields, "unit_id", $$v)},expression:"fields.unit_id"}})]}}],null,true)}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[(_vm.computedChoosed)?_c('validation-provider',{attrs:{"vid":"Formato","name":_vm.$t('globals.format'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.itemTypes,"prepend-icon":"mdi-paperclip","error-messages":errors,"label":_vm.$t('globals.format')},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})]}}],null,true)}):_c('v-text-field',{attrs:{"label":_vm.$t('globals.format'),"prepend-icon":"mdi-paperclip","readonly":"","disabled":true},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})],1),(_vm.hability_month == false)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"vid":"Inicio","name":_vm.$t('globals.start_date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"disabled":_vm.dateDisabled,"label":_vm.$t('globals.start_date'),"error-messages":errors,"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fields.start),callback:function ($$v) {_vm.$set(_vm.fields, "start", $$v)},expression:"fields.start"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.pickerInicio),callback:function ($$v) {_vm.pickerInicio=$$v},expression:"pickerInicio"}},[_c('v-date-picker',{attrs:{"allowed-dates":!_vm.all_days_available ? function (date) { return date <= new Date(new Date()+1).toISOString().substr(0, 10); } : null},on:{"input":function($event){_vm.pickerInicio = false}},model:{value:(_vm.fields.start),callback:function ($$v) {_vm.$set(_vm.fields, "start", $$v)},expression:"fields.start"}})],1)],1):_vm._e(),(_vm.hability_month == false)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"vid":"Fim","name":_vm.$t('globals.end_date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"disabled":_vm.dateDisabled,"label":_vm.$t('globals.end_date'),"error-messages":errors,"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fields.end),callback:function ($$v) {_vm.$set(_vm.fields, "end", $$v)},expression:"fields.end"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.pickerFim),callback:function ($$v) {_vm.pickerFim=$$v},expression:"pickerFim"}},[_c('v-date-picker',{attrs:{"allowed-dates":!_vm.all_days_available ? function (date) { return date <= new Date(new Date()+1).toISOString().substr(0, 10); } : null},on:{"input":function($event){_vm.pickerFim= false}},model:{value:(_vm.fields.end),callback:function ($$v) {_vm.$set(_vm.fields, "end", $$v)},expression:"fields.end"}})],1)],1):_vm._e(),(_vm.hability_month == true)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"Meses","name":_vm.$t('globals.month'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.$t('globals.months_array'),"error-messages":errors,"item-text":"text","item-value":"id","label":_vm.$t('globals.Month_Capital') + ' *'},model:{value:(_vm.month_selected),callback:function ($$v) {_vm.month_selected=$$v},expression:"month_selected"}})]}}],null,true)})],1):_vm._e()],1):_vm._e(),_c('v-row',{staticClass:"mt-6",attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{staticClass:"mr-5 ml-5 mb-5",attrs:{"disabled":invalid,"depressed":"","color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('globals.save'))+" ")])],1)],1)],1)],1)],1)]}}])}),(_vm.loading)?_c('Loader'):_vm._e(),_c('ErrorSnackbar',{attrs:{"message":_vm.error.message},model:{value:(_vm.error.show),callback:function ($$v) {_vm.$set(_vm.error, "show", $$v)},expression:"error.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }