<template>
  <section class="mt-16">
    <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <rp-icon icon="rp-truck-blue-filled" class="mr-2"/> <span>{{$t('menu.machines_management')}}</span>
      </h1>

      <v-row>
        <v-col cols="12" md="auto">
          <rp-icon
              icon="rp-eye-blue-filled"
              class="mb-n2"
            /><v-icon class="ml-2" small color="secondary">mdi-forward</v-icon>
            <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.visualize')}}</span>
        </v-col>
        <v-col cols="12" md="auto">
          <v-icon color="red" class="ml-4">
            mdi-delete
          </v-icon><v-icon class="ml-2" small color="secondary">mdi-forward</v-icon>
          <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.remove')}}</span>
        </v-col>
        <v-col cols="12" md="auto">
          <v-icon color="success" class="ml-4">
            mdi-file-export
          </v-icon><v-icon class="ml-2" small color="secondary">mdi-forward</v-icon>
          <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.export')}}</span>
        </v-col>
      </v-row>

      <div style="position:relative">
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t('globals.options')}}</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="info"
                  large
                  @click="searching = true; "
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>{{$t('globals.search')}}</span>
            </v-tooltip>
            <v-tooltip right color="success">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="success"
                  large
                  @click="exportingEquipment = true; equipmentToExport = null"
                  v-if="$root.session.hasPermission(['super', 'export.equipments'])"
                >
                  <v-icon>mdi-file-excel</v-icon>
                </v-btn>
              </template>
              <span>{{$t('globals.export')}}</span>
            </v-tooltip>
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  :to="'/' + $session.get('domain') + '/admin/works-settings/machines/create'"
                  v-if="$root.session.hasPermission(['super', 'machines.create'])"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{$t('globals.register')}}</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
          locale="pt-pt"
        >
        <template v-slot:item.company="{item}">
          {{ item.company == null ? $t('globals.internal') : item.company.name }}
        </template>
          <template v-slot:item.actions="{item}">
            <v-row>
              <v-col cols="12" md="auto">
                <IconView
                  @click="viewMachine(item)"
                />
                <IconExport
                  text="Relatório por Equipamento ou Viatura"
                  v-if="$root.session.hasPermission(['super', 'export.equipments'])"
                  @click="equipmentToExport = item; exportingEquipment = true"
                />       
                <IconRemove
                  v-if="!item.has_actions && $root.session.hasPermission(['super', 'machines.delete'])"
                  @on-submit="deleteMachine(item)"
                />
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </div>
      <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetMachines">
              {{$t('globals.clean_fields')}}
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchMachines">
            
            <v-list-item>
              <v-text-field
                v-model="filter.code"
                prepend-inner-icon="mdi-barcode"
                :label="$t('globals.code')"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-text-field
                v-model="filter.name"
                prepend-inner-icon="mdi-form-textbox"
                :label="$t('globals.name')"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              {{$t('globals.search')}}
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
      </v-navigation-drawer>
      <DialogSuccess :opened="started" @on-ok="started = false" >
        {{$t('machines.export_initiated')}}
      </DialogSuccess>

      <Dialog 
        :opened="exportingEquipment">
        <template #content>
          <v-container fluid>
            <v-card-title class="mt-n8">{{$t('globals.choose_interval')}}</v-card-title>
            <v-row>
              <v-col cols="12" md="6">
                <v-menu
                  v-model="equipment_export_started_at"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :label="$t('globals.start_date')"
                        v-model="equipment.export.started_at"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="equipment.export.started_at = null"
                      ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="equipment.export.started_at"
                    :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                    locale="pt-pt"
                    @input="equipment_export_started_at = false;"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6">
                <v-menu
                  v-model="equipment_export_finished_at"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :label="$t('globals.end_date')"
                        v-model="equipment.export.finished_at"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="equipment.export.finished_at = null"
                      ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="equipment.export.finished_at"
                    :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                    locale="pt-pt"
                    @input="equipment_export_finished_at = false;"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template #footer>
            <v-btn
              color="error"
              depressed
              @click="exportingEquipment = false"
            >
              {{$t('globals.cancel')}}
            </v-btn>
            <v-btn
              color="primary"
              depressed
              @click="exportEquipment"
            >
              {{$t('globals.export')}}
            </v-btn>
        </template>
      </Dialog>
      <Loader v-if="exportingDocument"></Loader>  
  </section>
</template>
<script>
import Loader from '@/components/ui/Loader.vue'
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import IconExport from '@/components/ui/IconExport.vue';
import Dialog from '@/components/ui/Dialog.vue';
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Machine from "@/api/Machine.js";
import dayjs from 'dayjs';

export default {
  components: {
    IconRemove,
    IconView,
    DialogSuccess,
    IconExport,
    Dialog,
    Loader
  },
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchMachines()}, 10);
      },
      deep: true,
    },
  },
  beforeCreate(){
    document.title = "Report Lake - " + this.$t('menu.machines_management');
  },
  data: () => ({
    exportingDocument: false,
    equipment_export_started_at: false,
    equipment_export_finished_at: false,
    equipment: {
      export: {
        started_at: null,
        finished_at: null,
        language: null
      }
    },
    equipmentToExport: null,
    started: false,
    exportingEquipment: false,
    report: {
      started_at: null,
      finished_at: null,
    },
    items: [],
    total: 0,
    loading: false,
    searching: false,
    options: {},
    speedDial:false,
    filter: {
      code:null,
      name: null,
    },
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "machines.read"])) {
      this.$router.push('/admin');
    }

    this.fillBaseData();
  },
  methods:{
    firstDateChanged(e){
        let dt = new Date(e)
        dt.setDate(dt.getDate() + 30);

        let month = new Date(e).getMonth() + 1
        let currentMonth = new Date().getMonth() + 1

        if(month == currentMonth)
            dt = new Date()

        this.equipment.export.finished_at = dt.toISOString().substr(0, 10)
    },
    getTableDate(date) {
      return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
    },
    fillBaseData() {

      if(localStorage["machines-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["machines-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }

      // Exportações. Fim é o dia de hoje o Inicio são 30 dias antes, por defeito
      let dt = new Date(new Date().toISOString().substr(0, 10))
      dt.setDate(dt.getDate() - 30);
      this.equipment.export.finished_at = new Date().toISOString().substr(0, 10);
      this.equipment.export.started_at = dt.toISOString().substr(0, 10);
      this.equipment.export = this.$store.state.language
    },
    searchMachines(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        page: this.options.page,
        limit: this.options.itemsPerPage,
      };
    
      Object.assign(request, filter);

      localStorage["machines-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      Machine.search(request).then(response => {

        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });

        this.loading = false;

      });
    },
    resetMachines()
    {
      this.filter = {
      };

      this.searchMachines();
    },
    viewMachine(item){
      let domain = '/' + this.$session.get('domain')
      this.$router.push(domain + '/admin/works-settings/machines/' + item.id);
    },
    deleteMachine(item){
      Machine.delete(item.id)
        .then(() => {
          this.searchMachines();
        });
    },
    async exportEquipment() {
      this.exportingDocument = true
      let data = {...this.equipment.export};

      if(this.equipmentToExport != null){
        data.machine_id = this.equipmentToExport.id; 
      }

      await Machine.exportEquipments(data)
        .then((resp) => {
          this.exportingEquipment=false;
          if(resp.data.success)
          this.exportingDocument = false
            window.open(resp.data.object)

        });
    },
  },
  computed: {
    headers(){
      let he = [
        {
          text: this.$t('globals.code'),
          sortable: true,
          value: 'code',
        },
        {
          text: this.$t('globals.name'),
          sortable: true,
          value: 'name',
        },
        {
          text: this.$t('globals.cost') + ' ' + this.$t('globals.hour_day'),
          sortable: true,
          value: 'value',
        },
        {
          text: this.$t('globals.company'),
          sortable: false,
          value: 'company',
        },
        { 
          text: this.$t('globals.options'),
          value: 'actions', 
          width: '200px',
          sortable: false 
        },
      ]

      return he
    },
    tableFooter(){
      let t = this.$t("globals.lines_datatable")
      let a = [5, 10, 15, 20];
      return {
        'items-per-page-options': a,
        'items-per-page-text': t
      }
    }, 
    crumbs: function() {
      return [
        {
          text: this.$t('menu.dashboard'),
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: this.$t('menu.machines_management'),
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
