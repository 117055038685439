function getIconDocument(path) {
    let extension = path.split('.').pop();

    if(extension == 'pdf')
    return 'mdi-file-pdf-box'

    if(extension == 'jpg')
    return 'mdi-file-jpg-box'

    if(extension == 'jpeg')
    return 'mdi-file-jpg-box'

    if(extension == 'png')
    return 'mdi-file-png-box'

    if(extension == 'docx')
    return 'mdi-file-word'

    if(extension == 'xlsx')
    return 'mdi-file-excel'

    if(extension == 'csv')
    return 'mdi-file-excel'

    return 'mdi-image-album'
  }
  
  export default getIconDocument