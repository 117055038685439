<template>
    <section class="mt-16">
        <v-progress-linear v-if="loading"
        indeterminate
        class="global-loader"
        ></v-progress-linear>
        <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>

        <h1 class="primary--text">
            <rp-icon icon="rp-cog-blue-filled" class="mr-2"/> <span>{{$t('syncs.title')}}</span>
        </h1>
        <div class="mt-12" style="position:relative" v-if="!loading">
            <v-container fluid>
                <v-row justify="center">
                    <v-col cols="12" md="auto">
                        <v-card width="250px">
                            <v-card-title :class="$root.$vuetify.theme.isDark ?  '' : 'primary white--text'">
                                {{$t('globals.all')}}
                            </v-card-title>
                            <v-card-text class="mt-4 text-center font-weight-bold">
                                Clique para importar todos os dados do Eticadata
                            </v-card-text>
                            <v-card-actions>                                
                                <v-btn block class="primary" @click="syncronize('global')">
                                    {{$t('globals.import')}}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <v-card width="250px">
                            <v-card-title :class="$root.$vuetify.theme.isDark ?  '' : 'primary white--text'">
                                {{$t('globals.materials')}}
                            </v-card-title>
                            <v-card-text class="mt-4 text-center font-weight-bold">
                                Clique para importar todos os MATERIAIS do Eticadata
                            </v-card-text>
                            <v-card-actions>   
                                <v-btn block class="primary" @click="syncronize('materials')">
                                    {{$t('globals.import')}}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <v-card width="250px">
                            <v-card-title :class="$root.$vuetify.theme.isDark ?  '' : 'primary white--text'">
                                {{$t('globals.warehouses')}}
                            </v-card-title>
                            <v-card-text class="mt-4 text-center font-weight-bold">
                                Clique para importar todos os ARMAZÉNS do Eticadata
                            </v-card-text>
                            <v-card-actions>   
                                <v-btn block class="primary" @click="syncronize('warehouse')">
                                    {{$t('globals.import')}}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            {{$t('syncs.success')}}
          </DialogSuccess>
    </section>
</template>
<script>
import Syncs from '@/api/Syncs.js'
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default {
    name:"Sync",
    components:{
        DialogSuccess
    },
    data(){
        return{
            loading: true,
            success: false,
            error:{
                title: null,
                show: false,
                message:null
            }
        }
    },
    mounted(){
        this.loading = false
    },
    methods:{
        onSuccess(){
            this.success = false
        },
        async syncronize(action){
            this.loading = true
            let data = {
                tenant: this.$route.params.tenant,
                action: action
            }

            await Syncs.eticadata(data).then(() => {
                this.loading=false
            }).catch(err => {

                this.loading=false
                if(err.response.status == 422) {

                    this.error.title = this.$t('globals.error') + " " + err.response.status;

                    this.error.message = err.response.data.errors.file[0];
                    

                    this.error.show = true;  
                    return;
                }

            });
            
        }
    },
    computed:{
        crumbs: function() {
        return [
        {
            text: this.$t('globals.definitions'),
            disabled: false,
            to: '/' + this.$session.get('domain') + '/admin/settings/general',
            exact: true,
            },
            {
            text: this.$t('menu.sync'),
            disabled: true,
            to: window.location.href,
            exact: true,
            },
        ];
        }
    }
}
</script>