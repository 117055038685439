<template>
  <div>
    <v-tabs :class="!work ? 'elevation-10' : 'elevation-10'" v-model="tab" :background-color="$root.$vuetify.theme.isDark ? 'secondary' : 'primary'" dark fixed-tabs>
      <v-tab>{{$t('work.tab.detail')}}</v-tab>
      <v-tab>{{$t('globals.images')}}</v-tab>
      <v-tab>{{$t('globals.materials')}}</v-tab>
      <v-tab>{{$t('work.tab.subcontractor')}}</v-tab>
      <v-tab :disabled="!work ? true : false">{{$t('work.tab.daily_parts')}}</v-tab>
      <v-tab :disabled="!work ? true : false" v-show="$root.session.hasPermission(['super', 'template.create', 'template.read'])">{{$t('work.tab.template')}}</v-tab>
      <v-tab :disabled="!work ? true : false">{{$t('work.tab.auto')}}</v-tab>
      <v-tab :disabled="!work ? true : false" v-show="$root.session.hasPermission(['super', 'performance.calculate'])">{{$t('work.tab.performance')}}</v-tab>
      <v-tab :disabled="!work ? true : false">{{$t('work.tab.gant')}}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" touchless :class="($root.$vuetify.theme.isDark ? 'secondary' : '') + ' elevation-10'">
      <v-container class="mb-n12">
        <v-tab-item class="mt-6" :key="1">
          <v-container>
            <validation-observer ref="form" v-slot="{ invalid }">
              <v-form @submit.prevent="submit"  class="mb-16">
                <v-progress-linear v-if="loading"
                    indeterminate
                    class="global-loader"
                  ></v-progress-linear>
                <fieldset :disabled="loading">
                  <v-row v-if="method =='create'"> 
                    <v-col cols="12" md="1">
                      <span class="font-weight-bold">{{$t('globals.import')}}:</span>
                    </v-col>
                    <v-col cols="12" md="11">
                      <v-btn
                        @click="importBudgetedWork = true"
                        class="relative ml-4"
                        right
                        rounded
                        small
                        color="primary"
                        dark
                      >
                        <rp-icon icon="rp-obras-gray-filled" class="mr-2"></rp-icon>{{$t('work.import_budgeted_work')}}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" v-if="fields.budget_work_id != null">
                      <v-btn class="primary" @click="goToBudget(fields.budget_work_id)">
                        <v-icon class="mr-2">mdi-note</v-icon>
                        {{$t('globals.budget')}}
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="2">
                      <validation-provider v-slot="{ errors }" vid="code" :name="$t('globals.code')" rules="required">
                        <v-text-field
                          v-model="fields.code"
                          prepend-inner-icon="mdi-barcode"
                          :label="$t('globals.code') + '*'"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider v-slot="{ errors }" vid="work_value" :name="$t('work.budgetd_work')">
                        <v-text-field
                            v-model="fields.work_value"
                            :prepend-inner-icon="$currency_icon()"
                            :label="$t('work.budgetd_work') + '*'"
                            clearable
                            :error-messages="errors"
                          ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" md="2">
                      <validation-provider v-slot="{ errors }" vid="k" :name="$t('work.k')">
                        <v-text-field
                            v-model="fields.k"
                            prepend-inner-icon="mdi-percent"
                            :label="$t('work.k') + '*'"
                            clearable
                            @keyup="changedK"
                            :error-messages="errors"
                          ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" md="2">
                      <validation-provider v-slot="{ errors }" vid="work_value_k" :name="$t('work.work_value_k')">
                        <v-text-field
                            v-model="fields.work_value_k"
                            :disabled="true"
                            :prepend-inner-icon="$currency_icon()"
                            :label="$t('work.work_value_k') + '*'"
                            clearable
                            :error-messages="errors"
                          ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-menu
                        v-model="started_at"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider v-slot="{ errors }" vid="started_at" :name="$t('globals.start_date')" rules="required">
                            <v-text-field
                              :label="$t('globals.start_date') + '*'"
                              v-model="fields.started_at"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              clearable
                              v-bind="attrs"
                              v-on="on"
                              @click:clear="fields.started_at = null"
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </template>
                        <v-date-picker
                          v-model="fields.started_at"
                          locale="pt-pt"
                          @input="started_at = false;"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-menu
                        v-model="finished_at"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider v-slot="{ errors }" vid="finished_at" :name="$t('globals.end_date')">
                            <v-text-field
                              :label="$t('globals.end_date')"
                              v-model="fields.finished_at"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              clearable
                              v-bind="attrs"
                              v-on="on"
                              :disabled="fields.started_at.length == 0"
                              @click:clear="fields.finished_at = null"
                              v-if="fields.started_at != null"
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </template>
                        <v-date-picker
                          v-model="fields.finished_at"
                          locale="pt-pt"
                          @input="finished_at = false;"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <validation-provider v-slot="{ errors }" vid="name" :name="$t('globals.name')" rules="required">
                        <v-text-field
                          v-model="fields.name"
                          prepend-inner-icon="mdi-form-textbox"
                          :label="$t('globals.name') + '*'"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="auto">
                      <v-switch
                        inset
                        v-model="fields.add_materials_diary"
                        :label="$t('work.add_materials_diary')"
                      ></v-switch>                      
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select
                        v-model="fields.id_client"
                        :items="clients"
                        :item-text="item => item.name"
                        item-value="id"
                        menu-props="auto"
                        :label="$t('globals.client') + '*'"
                        prepend-inner-icon="mdi-factory"
                        clearable
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider v-slot="{ errors }" vid="status" :name="$t('globals.state')" rules="required">
                        <v-select
                            v-model="fields.status"
                            :items="status"
                            :item-text="item => item.name"
                            item-value="id"
                            menu-props="auto"
                            :label="$t('globals.state') + '*'"
                            prepend-inner-icon="mdi-cog"
                            :error-messages="errors"
                            clearable
                          ></v-select>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                          v-model="fields.description"
                          :label="$t('globals.description')"
                          prepend-inner-icon="mdi-card-text"
                          counter="600"
                          maxlength="600"
                          clearable
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="11">
                      <v-text-field v-model="fields.sharepoint_link"
                        :label="$t('work.sharepoint.textfield')"
                        clearable
                        prepend-inner-icon="mdi-link-box"
                      >
                      </v-text-field>

                    </v-col>
                    <v-col cols="12" md="1">
                      <Tooltip
                          color="primary"
                          :text="$t('work.sharepoint.open')"
                          top
                      >
                        <v-btn @click="openNewWindow(fields.sharepoint_link)" :disabled="fields.sharepoint_link == null" x-small fab class="primary mt-4">
                          <v-icon>mdi-open-in-app</v-icon>
                        </v-btn>
                      </Tooltip>
                    </v-col>
                  </v-row>
                  
                  <v-divider class="mt-8 mb-8"></v-divider>
                  
                  <v-row>
                    <v-col cols="12" md="5">
                      <v-select
                          v-model="fields.commissioner_id"
                          :items="commissioners"
                          :item-text="item => $t('globals.name') + ': ' + item.name + ' - ' + $t('globals.username') + ': '+ item.username"
                          item-value="id"
                          menu-props="auto"
                          :label="$t('globals.in_charge')"
                          prepend-inner-icon="mdi-account-hard-hat"
                          clearable
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="1">
                      <v-btn small class="mt-4" color="success" @click="addComissioner"><v-icon>mdi-plus</v-icon></v-btn>
                    </v-col>
                    <v-col cols="12" md="4" v-if="false">
                      <v-autocomplete
                            v-model="fields.overseer_id"
                            :items="overseers"
                            :item-text="item => $t('globals.name') + ': ' + item.name + ' - ' + $t('globals.username') + ': '+ item.username"
                            item-value="id"
                            menu-props="auto"
                            :label="$t('globals.equipment_responsible')"
                            prepend-inner-icon="mdi-account-hard-hat"
                            clearable
                          ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-autocomplete
                          v-model="fields.engineer_id"
                          :items="engineers"
                          :item-text="item => $t('globals.name') + ': ' + item.name + ' - ' + $t('globals.username') + ': '+ item.username"
                          item-value="id"
                          menu-props="auto"
                          :label="$t('globals.engineer')"
                          prepend-inner-icon="mdi-account-tie"
                          clearable
                        ></v-autocomplete>
                    </v-col>                    
                    <v-col cols="12" md="1">
                      <v-btn small class="mt-4" color="success" @click="addEngineer"><v-icon>mdi-plus</v-icon></v-btn>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-card :class="$root.$vuetify.theme.isDark ? 'secondary mb-2' : 'mb-2'">
                        <v-card-title class="primary white--text">{{$t('globals.in_charge_plural')}} *</v-card-title>
                        <v-card-text>
                          <v-simple-table :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">
                                    {{$t('globals.name')}}
                                  </th>
                                  <th class="text-left">
                                    {{$t('globals.options')}}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="comm in fields.commissioners"
                                  :key="comm.id"
                                >
                                  <td>{{ comm.name }}</td>
                                  <td>
                                    <IconRemove
                                        class="mr-2"
                                        @on-submit="deleteCommissioner(comm)"
                                      />
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-card :class="$root.$vuetify.theme.isDark ? 'secondary mb-2' : 'mb-2'">
                        <v-card-title class="primary white--text">{{$t('globals.engineer_plural')}} *</v-card-title>
                        <v-card-text>
                          <v-simple-table :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">
                                    {{$t('globals.name')}}
                                  </th>
                                  <th class="text-left">
                                    {{$t('globals.options')}}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="comm in fields.engineers"
                                  :key="comm.id"
                                >
                                  <td>{{ comm.name }}</td>
                                  <td>
                                    <IconRemove
                                        class="mr-2"
                                        @on-submit="deleteEngineer(comm)"
                                      />
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-8 mb-8"></v-divider>
                  <v-card elevation="0" :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                      <v-row no-gutters justify="center">
                        <v-col cols="12">                  
                          <v-card-title>{{$t('work.work_week_days_label')}}</v-card-title>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-checkbox
                                :label="$t('globals.week_days.monday')"
                                color="red darken-3"
                                v-model="fields.work_days.monday"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="3">                                                
                            <v-checkbox
                                :label="$t('globals.week_days.tuesday')"
                                color="red darken-3"
                                v-model="fields.work_days.tuesday"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="3">                                                
                            <v-checkbox
                                :label="$t('globals.week_days.wednesday')"
                                color="red darken-3"
                                v-model="fields.work_days.wednesday"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="3">                                                
                            <v-checkbox
                                :label="$t('globals.week_days.thursday')"
                                color="red darken-3"
                                v-model="fields.work_days.thursday"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="3">                                                
                            <v-checkbox
                                :label="$t('globals.week_days.friday')"
                                color="red darken-3"
                                v-model="fields.work_days.friday"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="3">                                                
                            <v-checkbox
                                :label="$t('globals.week_days.saturday')"
                                color="red darken-3"
                                v-model="fields.work_days.saturday"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="3">                                                
                            <v-checkbox
                                :label="$t('globals.week_days.sunday')"
                                color="red darken-3"
                                v-model="fields.work_days.sunday"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                      </v-row>

                      
                  <v-divider class="mt-8 mb-8"></v-divider>

                      <v-row class="mt-8">
                        <v-expansion-panels focusable multiple>
                          <v-expansion-panel>
                            <v-expansion-panel-header disable-icon-rotate :class="($root.$vuetify.theme.isDark ? 'secondary' : 'primary') + ' white--text'"> 
                              <h3>
                                <v-icon color="white">mdi-map-marker</v-icon> <span>{{$t('globals.address.address')}}</span>
                              </h3>
                              <template v-slot:actions>
                                <v-icon color="white">
                                  $expand
                                </v-icon>
                              </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content :class="($root.$vuetify.theme.isDark ? 'secondary' : '')">
                              <v-row v-if="false">                         
                                <v-col cols="12" md="12">
                                  <validation-provider v-slot="{ errors }" vid="morada" :name="$t('globals.address.address')" rules="required">
                                      <v-textarea
                                        v-model="fields.address"
                                        prepend-inner-icon="mdi-account-details"
                                        :label="$t('globals.address.address') + '*'"
                                        clearable
                                        :error-messages="errors"
                                      ></v-textarea>
                                    </validation-provider>
                                </v-col> 
                                <v-col cols="12" md="auto">
                                      {{$t('globals.address.postal_code')}}
                                  </v-col>
                                  <v-col cols="12" md="3">
                                      <v-otp-input
                                          plain
                                          v-model="fields.postal_code1"
                                          :length="4"
                                      ></v-otp-input>
                                  </v-col>
                                  <v-col cols="12" md="auto">
                                      <h1>-</h1>
                                  </v-col>
                                  <v-col cols="12" md="2">
                                      <v-otp-input
                                          plain
                                          v-model="fields.postal_code2"
                                          :length="3"
                                      ></v-otp-input>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                      <v-text-field
                                          :label="$t('globals.address.city')"
                                          color="accent"
                                          v-model="fields.city"
                                      ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                      <v-text-field
                                          :label="$t('globals.address.country')"
                                          color="accent"
                                          v-model="fields.country"
                                      ></v-text-field>
                                  </v-col>
                                </v-row>  
                                  <v-row>  
                                  <v-col cols="12">
                                    <v-row class="mt-4">
                                      <v-col cols="12" md="auto" class="font-weight-bold">
                                        {{$t('work.associate_warehouse')}}
                                      </v-col>
                                      <v-col cols="12" md="auto">
                                        <v-autocomplete
                                          v-model="fields.warehouse_id"
                                          :items="warehouses"
                                          dense
                                          outlined
                                          :label="$t('globals.warehouses')"
                                          item-value="id"
                                          item-text="name"
                                        />
                                      </v-col>
                                    </v-row>
                                    <Localization @localization="addLocal" :method="method" :workLocal="fields.local" :id="fields.id"/>
                                  </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-row>
                      
                      <v-row class="mt-8">
                        <v-expansion-panels focusable multiple>
                          <v-expansion-panel>
                            <v-expansion-panel-header disable-icon-rotate :class="$root.$vuetify.theme.isDark ? 'secondary' : 'primary white--text'">
                              <h3>
                                <v-icon color="white">mdi-calendar-alert</v-icon> <span>{{$t('work.work_suspension')}}</span>
                              </h3>
                              <template v-slot:actions>
                                <p>
                                  <v-icon color="error">
                                    mdi-sigma
                                  </v-icon>
                                  {{fields.suspended_dates.length}}
                                </p>
                              </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-simple-table dense>
                                    <template v-slot:default>
                                      <thead>                                
                                        <tr>
                                          <th class="text-left" width="20%">
                                            {{$t('work.period')}}
                                          </th>
                                          <th class="text-left" width="20%">
                                            {{$t('work.to')}}
                                          </th>   
                                          <th class="text-center">
                                            {{$t('work.motive')}}
                                          </th>                                  
                                          <th width="10%" class="text-center">
                                            {{$t('globals.options')}}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="(element, index) in fields.suspended_dates" v-bind:key="index + 20000">
                                          <td>{{element.from}}</td>
                                          <td>{{element.to}}</td>
                                          <td>{{element.description}}</td>
                                          <td>
                                            <IconRemove
                                              class="mr-2"
                                              @on-submit="deleteSuspendedDate(element)"                                      
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </template>
                                  </v-simple-table>
                                  
                                  <v-dialog
                                    v-model="addSuspendedDate"
                                    width="500"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        color="red lighten-2 mt-8"
                                        dark
                                        style="display: block; margin: auto;"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        {{$t('work.new_date')}}
                                      </v-btn>
                                    </template>

                                    <v-card>
                                      <v-card-title class="text-h5 primary white--text">
                                        {{$t('work.add_suspension_date')}}
                                      </v-card-title>

                                      <v-card-text>
                                        <v-row>
                                          <v-col cols="12" md="6">
                                            <v-menu
                                              v-model="addSuspendedDateFrom"
                                              :close-on-content-click="false"
                                              :nudge-right="40"
                                              transition="scale-transition"
                                              offset-y
                                              min-width="auto"
                                            >
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                  v-model="newSuspendedDate.from"
                                                  :label="$t('globals.from') + '*'"
                                                  prepend-icon="mdi-calendar"
                                                  readonly
                                                  v-bind="attrs"
                                                  v-on="on"
                                                ></v-text-field>
                                              </template>
                                              <v-date-picker
                                                v-model="newSuspendedDate.from"
                                                @input="addSuspendedDateFrom = false"
                                              ></v-date-picker>
                                            </v-menu>
                                          </v-col>
                                          <v-col cols="12" md="6">
                                            <v-menu
                                              v-model="addSuspendedDateTo"
                                              :close-on-content-click="false"
                                              :nudge-right="40"
                                              transition="scale-transition"
                                              offset-y
                                              min-width="auto"
                                            >
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                  v-model="newSuspendedDate.to"
                                                  :label="$t('globals.to') + '*'"
                                                  prepend-icon="mdi-calendar"
                                                  readonly
                                                  v-bind="attrs"
                                                  v-on="on"
                                                ></v-text-field>
                                              </template>
                                              <v-date-picker
                                                v-model="newSuspendedDate.to"
                                                @input="addSuspendedDateTo = false"
                                              ></v-date-picker>
                                            </v-menu>
                                          </v-col>
                                          <v-col cols="12" md="12">
                                            <v-textarea v-model="newSuspendedDate.description" :label="$t('work.suspension_motive')"></v-textarea>
                                          </v-col>
                                        </v-row>
                                      </v-card-text>

                                      <v-divider></v-divider>

                                      <v-card-actions>
                                        <v-btn
                                          color="primary"
                                          @click="addNewSuspendedDate"
                                        >
                                          {{$t('globals.add')}}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          color="error"
                                          @click="addSuspendedDate = false"
                                        >
                                        {{$t('globals.close')}}
                                        </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-dialog>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-row>





                      <v-row class="mt-8">
                      <v-expansion-panels focusable>
                        <v-expansion-panel>
                          <v-expansion-panel-header disable-icon-rotate :class="$root.$vuetify.theme.isDark ? 'secondary' : 'primary white--text'">
                            <h3>
                              <rp-icon :icon="$root.$vuetify.theme.isDark ? 'rp-barcode-yellow-filled' : 'rp-barcode-gray-filled'" class="mr-2"/> <span>{{$t('globals.work_units_tables.title')}}</span>
                            </h3>
                            <template v-slot:actions>
                              <v-icon color="white">
                                $expand
                              </v-icon>
                            </template>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                            <WorkUnits v-if="!loading" :fields="fields" return-object />
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-row>
                    </v-card>
                  <v-row class="mt-6" align="center" justify="space-around">
                    <v-btn :disabled="invalid" depressed color="primary" type="submit">
                      {{$t('globals.save')}}
                    </v-btn>
                  </v-row>
                  
                </fieldset>
              </v-form>

              <!-- ******************************* -->
              <!-- ******************************* -->
              <!-- ADICIONAR UNIDADE DE OBRA MODAL -->
              <!-- ******************************* -->
              <!-- ******************************* -->
              <v-dialog
                v-model="showWorkList"
                persistent
                max-width="800px">
              <v-card>
                    <fieldset>
                      <v-card-title class="primary white--text">
                              {{$t('work.add_work_unit')}}
                      </v-card-title>
                      <v-card-text>
                          <v-row class="mt-4">
                              <v-col cols="12">                    
                                <v-autocomplete
                                    v-model="newWorkUnit.work_id"
                                    :label="$t('globals.work_unit') + '*'"
                                    :items="units"
                                    :item-text="item => item.name"
                                    item-value="id"
                                    hide-selected
                                    clearable
                                    >
                                </v-autocomplete>     
                              </v-col>   
                              <v-col cols="12">
                                <v-checkbox
                                    v-model="chooseWorkUnitDate"
                                    :disabled="canChooseWorkUnitStart"
                                    :label="canChooseWorkUnitStart ? $t('work.uo_starting_point_msg1') : $t('work.uo_starting_point_msg2')"
                                ></v-checkbox>
                              </v-col>   
                              <v-col cols="12">
                                <v-menu
                                  v-model="pickerStartDate"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <validation-provider v-slot="{ errors }" vid="Data" :name="$t('work.preview_start')" rules="|">
                                        <v-text-field
                                            v-model="newWorkUnit.start_date"
                                            :disabled="chooseWorkUnitDate"
                                            :label="$t('work.preview_start')"
                                            :error-messages="errors"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </validation-provider>
                                    </template>
                                    <v-date-picker
                                      v-model="newWorkUnit.start_date"
                                      @input="pickerStartDate = false"
                                    ></v-date-picker>
                                </v-menu>
                              </v-col>                        
                              <v-col cols="12" v-if="chooseWorkUnitDate">                    
                                <v-autocomplete
                                    v-model="newWorkUnit.work_unit_id"
                                    :label="$t('work.start_after')"
                                    :items="computedChoosingWorkUnit"
                                    :item-text="item => item.unit"
                                    item-value="id_work_unit"
                                    hide-selected
                                    clearable
                                    @change="changedWorkUnitListDate"
                                    >
                                </v-autocomplete>     
                              </v-col>
                              <v-col cols="12" md="6">  
                                      <v-autocomplete                                              
                                              v-model="newWorkUnit.work_id"
                                              :label="$t('globals.code') + '*'"
                                              :items="units"
                                              :item-text="item => item.code"
                                              item-value="id"
                                              hide-selected
                                              clearable
                                              >
                                      </v-autocomplete>     
                              </v-col>
                              <v-col cols="12" md="6">
                                  <v-autocomplete
                                          v-model="newWorkUnit.work_id"
                                          :label="$t('globals.unit')"
                                          :items="units"
                                          :disabled="true"
                                          :item-text="item => item.unit"
                                          item-value="id"
                                          hide-selected
                                          clearable
                                          >
                                      </v-autocomplete>   
                              </v-col>
                              <v-divider class="mt-4 mb-4"></v-divider>
                          </v-row>
                          <v-row>
                              <v-col cols="12" md="4">
                                      <v-text-field
                                          v-model="newWorkUnit.qty_budgeted"
                                          prepend-inner-icon="mdi-form-textbox"
                                          :label="$t('globals.work_units_tables.budgeted_full')"
                                          clearable
                                      ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4">
                                      <v-text-field
                                          v-model="newWorkUnit.budgeted_income"
                                          small
                                          prepend-inner-icon="mdi-google-circles-communities"
                                          :label="$t('globals.work_units_tables.income_full')"
                                          clearable
                                      >
                                          
                                      <template v-slot:append>        
                                          <v-icon>{{ newWorkUnit.unit_measure }}</v-icon> 
                                      </template>
                                      </v-text-field>
                              </v-col>
                              <v-col cols="12" md="4">
                                      <v-text-field
                                          v-model="newWorkUnit.team"
                                          prepend-inner-icon="mdi-counter"
                                          :label="$t('globals.team')"
                                          clearable
                                      ></v-text-field>
                              </v-col>                            
                          </v-row>
                      </v-card-text>
                      <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="error"
                          depressed
                          @click="showWorkList = false"
                      >
                      {{$t('globals.cancel')}}
                      </v-btn>
                      <v-btn :disabled="newWorkUnit.qty_budgeted == null || newWorkUnit.work_id == null ||newWorkUnit.budgeted_income == null" depressed color="primary" @click="addWorkUnitToWork">
                        {{$t('globals.save')}}
                      </v-btn>            
                    </v-card-actions>
                    </fieldset>
              </v-card>
            </v-dialog>


              <v-dialog
                v-model="importBudgetedWork"
                max-width="800px">
                <v-card>
                  <v-card-title class="text-h5 primary white--text lighten-2">
                    {{$t('work.import_budgeted_work')}}
                  </v-card-title>

                  <v-card-text class="mt-2">
                    <v-select
                        v-model="importedWork"
                        :items="budgetedWorks"
                        item-value="id"
                        :item-text="item => item.name"
                        menu-props="auto"
                        label="Obras"
                        prepend-inner-icon="mdi-hard-hat"
                    ></v-select>
                    
                    {{$t('work.budgeted_accepted_work')}}
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      @click="importWorkBudgeted"
                    >
                      {{$t('globals.import')}}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <DialogSuccess :opened="success" @on-ok="onSuccess" >
                {{$t('work.work_saved')}}
              </DialogSuccess>
            </validation-observer>
          </v-container>
        </v-tab-item>
        <v-tab-item class="mt-6" :key="2">
          <WorkImages />
        </v-tab-item>
        <v-tab-item :key="3">
          <WorkMaterials />
        </v-tab-item>
        <v-tab-item :key="4">
          <SubempreitadasView @reloaded="$emit('reload', 1)" :viewOnly="method == 'create'" :subempreitadasReader="fields.subempreitadas"/>
        </V-tab-item>
        <v-tab-item class="mt-6" :key="5">
          <WorkDiaries :work_id="$route.params.id"/>
        </v-tab-item>
        <v-tab-item class="mt-6 mb-6" :key="6">
          <v-progress-linear 
            v-if="loading"
            indeterminate
          ></v-progress-linear>
          <div v-else>
          <small>{{$t('work.define_template')}}</small>
          <TemplateMaoObra class="mt-6" :key="reloadPage + 5000"
            :template_fields="template_fields"
            :workers="workers"
            return-object
            @remove="removeMaoObra"
          />
          <TemplateMaquina :key="reloadPage + 60000"
            :template_fields="template_fields"
            :machines="machines"
            return-object
            @remove="removeMaquina"
          />
          <TemplateMateriais :key="reloadPage + 70000" 
            v-if="fields.add_materials_diary"
            :template_fields="template_fields"
            :materials="materials"
            return-object
            @remove="removeMaterial"
          />
          <TemplateUnidades :key="reloadPage"
            :template_fields="template_fields"
            :units="units"
            return-object
            @remove="removeUnidade"
          />
          <v-row class="mt-8 mb-12" align="center" justify="space-around" v-if="$root.session.hasPermission(['super', 'template.create', 'template.update'])">
            <v-btn depressed color="primary" @click="saveTemplate">
              {{$t('globals.save')}}
            </v-btn>
          </v-row>
        </div>
        </v-tab-item>
        <v-tab-item class="mt-6" :key="7" v-if="method=='update'">
          <v-row v-if="!loading">
            <v-col cols="12" md="3">
              <v-card class="elevation-10 text-center" height="190">
                <v-card-title class="primary white--text text-center">
                  <span>{{$t('globals.invoiced_percent')}}</span>
                </v-card-title>
                <v-card-text class="mt-4">
                  <v-progress-circular
                    :rotate="360"
                    :size="100"
                    :width="15"
                    :value="work.invoiced.per_invoiced"
                    color="warning"
                  >
                    <span class="primary--text font-weight-medium">{{ work.invoiced.per_invoiced }}%</span>
                  </v-progress-circular>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="9">
              <v-card class="elevation-10" height="190">
                <v-card-title class="primary white--text">
                  {{$t('globals.state')}}
                  <v-spacer></v-spacer>
                  <v-btn 
                      class="success" 
                      :to="'/' + $session.get('domain') + '/admin/invoiced/self-measuring/' + $route.params.id">
                      <v-icon>mdi-plus</v-icon>{{$t('work.auto.title')}}
                  </v-btn>
                </v-card-title>
                <v-card-text class="mt-4" v-if="work.budgeted != null">
                  <v-row>
                    <v-col cols="12" md="6">
                      <span class="font-weight-bold">{{$t('globals.budgeted')}}: </span>{{$currency(work.work_value_k)}}
                    </v-col>
                    <v-col cols="12" md="6">
                      <span class="font-weight-bold">{{$t('globals.invoiced_percent')}}: </span>{{$currency(work.invoiced.total_invoiced)}}
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text class="mt-4" v-else>
                  {{$t('globals.no_info')}}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <SelfMeasurings :work_id="$route.params.id" :client_id="fields.id_client"/>
        </v-tab-item>
        <v-tab-item class="mt-6" :key="8">
          <ShowPerformance :general_work_performance="general_work_performance" :workUnitList="workUnitList" :fields="fields" :diaryParts="diariesItems"/>
        </v-tab-item>
        <v-tab-item class="mt-6" :key="9">
          <GanttTime @submit="submit();" :work_days="fields.work_days" @viewListWorkUnit="viewListWorkUnit" :openFullScreen="openFullScreen" :data="fields.work_unit_list"/>
        </v-tab-item>
      </v-container>
    </v-tabs-items>
    <ErrorSnackbar v-model="error.show" :message="error.message"/>
    <SuccessSnackBar v-model="successSnack.show" :message="successSnack.message"/>
  </div>
</template>
<script>
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
import SubempreitadasView from '@/components/works/subempreitadas/Subempreitadas.vue'
import Unit from "@/api/Unit"
import Worker from "@/api/Worker"
import Machine from "@/api/Machine"
import Material from "@/api/Materials"
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Work from "@/api/Work.js";
import ClientUsers from "@/api/ClientUsers.js";
import Templates from "@/api/Templates.js";
import Budget from "@/api/Budget.js";
import dayjs from 'dayjs'
import TemplateMaoObra from '@/components/works/templates/TemplateMaoObra'
import TemplateMaquina from '@/components/works/templates/TemplateMaquina'
import TemplateMateriais from '@/components/works/templates/TemplateMateriais'
import TemplateUnidades from '@/components/works/templates/TemplateUnidades'
import Warehouse from '@/api/Warehouse.js'
import Client from "@/api/Client"
//import APIWorkUnitList from "@/api/WorkUnitList"
import IconRemove from '@/components/ui/IconRemove.vue';
import ShowPerformance from '@/components/works/performance/ShowPerformance.vue';
//import Performance from "@/api/Performance"
import GanttTime from '@/components/works/gantt/GanttTime.vue'
import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';
import WorkImages from '@/components/works/images/WorkImages.vue';
import Localization from '@/components/works/Localization.vue'
import WorkUnits from '@/components/works/work_units/WorkUnits.vue'
import SelfMeasurings from '@/views/self_measuring/SelfMeasurings.vue'
import WorkMaterials from '@/components/works/materials/WorkMaterials.vue'
import Tooltip from '@/components/ui/Tooltip.vue';
import WorkDiaries from "@/components/works/diaries/Diaries.vue"

export default {
  components: {
    WorkUnits,
    DialogSuccess,
    TemplateMaoObra,
    TemplateMaquina,
    TemplateUnidades,
    TemplateMateriais,
    IconRemove,
    ShowPerformance,
    ErrorSnackbar,
    GanttTime,
    SubempreitadasView,
    SuccessSnackBar,
    WorkImages,
    Localization,
    SelfMeasurings,
    WorkMaterials,
    Tooltip,
    WorkDiaries
  },
  props: {
   work: Object,
   method: String,
   disabled: Boolean,
   openTab: Number
 },
 watch: { 
  
    work: {
      immediate: true, 
      async handler (val) {
        if(!val) {
          let parent = this
          await Client.list().then(({data}) => {
              parent.clients = data;
          });
          return;
        }
        this.loading = true

        let data = {...val};

        this.fields = data;


        if(data.postal_code != ''){
          if(data.postal_code != null){
            this.fields.postal_code1 = data.postal_code.split('-')[0]
            this.fields.postal_code2 = data.postal_code.split('-')[1]
          }
        }else{
          this.fields.postal_code1 = null
          this.fields.postal_code2 = null
        }

        
        if(this.fields.work_days == null){
          this.fields.work_days = {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday:false,
            sunday: false
          }
        }

        let parent = this
        
        /*await Performance.getPerformance(this.$route.params.id)
        .then(({data}) => {
          parent.general_work_performance = data
        });*/

        await Client.list().then(({data}) => {
            parent.clients = data;
        });

        if(this.fields.client != null)
          this.clients.forEach(a1 => {
            if(a1.id == parent.fields.client.id)
              parent.fields.id_client = parent.fields.client.id
          })
          
        await Worker.list().then(({data}) => {
            parent.workers = data;
        });
        await Machine.list().then(({data}) => {
            parent.machines = data;
        });
        await Material.list().then(({data}) => {
            parent.materials = data;
        });

        
        if(data.template != null){
          this.template_fields.workers = data.template.workers
          this.template_fields.workers.forEach(a1 => {
            parent.workers.forEach(a2 => {
              if(a1.worker_id == a2.id)
                a1.object = a2
            });
          });
          this.template_fields.machines = data.template.machines
          this.template_fields.machines.forEach(a1 => {
            parent.machines.forEach(a2 => {
              if(a1.machine_id == a2.id)
                a1.object = a2
            });
            
          });
          this.template_fields.materials = data.template.materials
          this.template_fields.materials.forEach(a1 => {
            parent.materials.forEach(a2 => {
              if(a1.material_id == a2.id)
                a1.object = a2
            })
          });
          this.template_fields.units = data.template.units
          this.template_fields.units.forEach(a1 => {
            parent.units.forEach(a2 => {
              if(a1.unit_id == a2.id)
                a1.object = a2
            });
          });
        }
        
        if(this.fields.started_at.length > 0){
          this.fields.started_at = dayjs(this.fields.started_at).format("YYYY-MM-DD");
        }
        
        if(this.fields.finished_at != null)
          if(this.fields.finished_at.length > 0){
            this.fields.finished_at = dayjs(this.fields.finished_at).format("YYYY-MM-DD");
          }

        this.fields.id = data.id;

        
        if(this.openTab == 99){
          this.tab = 4
          this.openFullScreen = true
        }else this.tab = this.openTab


        await this.getWorkUnitsList()      

        this.loading = false      
      }
    },
  },
  data: () => ({    
    successSnack:{
      show: false,
      message: ''
    },
    newSuspendedDate:{
      from: null,
      to: null,
      description: null
    },
    chooseWorkUnitDate: false,
    warehouses: [],
    openFullScreen:false,
    pickerStartDate: false,
    newWorkUnit:{
      id: null,
      work_id: null,
      start_date: null,
      code: null,
      team: null,
      unit: null,
      qty_budgeted: null,
      budgeted_income: null,
      edit: false,
      work_unit_id: null,
    },
    budgetedWorks: null,
    importedWork: null,
    importBudgetedWork: false,
    response:{
      id: null
    },
    reloadPage:0,
    general_work_performance: null,
    workUnitObject:{
      editableWorkUnit: null,
      method: 'create'
    },
    showWorkList: false,
    workUnitList:[],
    clients: [],
    units: [],
    machines: [],
    materials: [],
    workers: [],
    methodTemplate: 'create',
    fields: {
      id: null,
      local: null,
      k: null,
      work_value_k: null,
      address:null, 
      postal_code: null,
      postal_code1: null,
      postal_code2: null,
      city: null,
      chapters: [],
      country: null,
      code: dayjs().unix(),
      name: null,
      status:null,
      description:null,
      started_at:null,
      finished_at:null,
      commissioner_id: null,
      overseer_id: null,
      engineer_id: null,
      id_client: null,
      add_materials_diary: false,
      work_days:{
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday:false,
        sunday: false
      },
      work_unit_list: [],
      engineers: [],
      commissioners: [],
      subempreitadas: [],
      suspended_dates: []
    },
    template_fields:{
      workers: [],
      machines: [],
      materials: [],
      units: []
    },
    loading:false,
    error: {
      title: '',
      message: '',
      show: false
    },
    diariesItems: [],
    diariesTotal: 0,
    diariesLoading: false,
    status: [],
    success: false,
    started_at:false,
    finished_at:false,
    commissioners:[],
    overseers:[],
    engineers:[],
    tab: 0,
    addSuspendedDateTo: null,
    addSuspendedDateFrom: null,
    addSuspendedDate: false,
  }),
  mounted(){
    this.fillBaseData();
  },
  methods:{
    openNewWindow(link){
      window.open(link, '_blank')
    },
    goToBudget(id){
      let domain = '/' + this.$session.get('domain')
      let path = domain + '/admin/budgeting/budget/' + id


      this.$router.push(path);
    },
    addLocal(l){
      this.fields.local = l
    },
    changedK(){
      let k_value = parseFloat(this.fields.k)
      let percentage = k_value / 100;
      let new_value = parseFloat(this.fields.work_value) * parseFloat(percentage)

      this.fields.work_value_k = parseFloat(this.fields.work_value) + parseFloat(new_value)
      if(isNaN(this.fields.work_value_k))
        this.fields.work_value_k = null
    },
    deleteSuspendedDate(ob){
      let index = -1
      let i = 0
      this.fields.suspended_dates.forEach(element => {
        if(element.id == ob.id)
          index = i

        i++
      });

      if(index == -1)
        return

      this.fields.suspended_dates.splice(index, 1)

      this.successSnack.message = this.$t('work.suspension_date_removed')
      this.successSnack.show = true
    },
    addNewSuspendedDate(){
      if(this.newSuspendedDate == null)
        return
      if(this.newSuspendedDate.from == null)
        return
      if(this.newSuspendedDate.to == null)
        return

      this.fields.suspended_dates.push({
        from: this.newSuspendedDate.from,
        to: this.newSuspendedDate.to,
        description: this.newSuspendedDate.description
      });

      this.successSnack.message = this.$t('work.suspension_date_added')
      this.successSnack.show = true

      this.newSuspendedDate.from = null
      this.newSuspendedDate.to = null
      this.newSuspendedDate.description = null
    },
    async removeSubempreitada(ob){
      let i = 0
        let index = -1
        this.fields.subempreitadas.forEach(element => {
          if(element.id == ob.id){
            index = i
          }
          i++
        });

        if(index != -1){
          this.fields.subempreitadas.splice(index, 1)
        }

        await this.submit()
    },
    async addSubEmpreitada(ob){
      if(ob.id != null){
        let i = 0
        let index = -1
        this.fields.subempreitadas.forEach(element => {
          if(element.id == ob.id){
            index = i
          }
          i++
        });

        if(index != -1){
          this.fields.subempreitadas[index] = ob
        }
      }else
        this.fields.subempreitadas.push(ob)
      
      await this.submit()
    },
    calculateNumberWorkDays(element){
      let total_dias = Math.round(element.qty_budgeted / element.budgeted_income)

      var days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      let from = new Date(element.start_date) 
      let sem_trabalho = 0;
      for(let i = from; total_dias >= 0; i.setDate(i.getDate() + 1)){                
          if(this.fields.work_days[days[i.getDay()]]){
              total_dias--
          }else{
              sem_trabalho++
          }                
      }
      
      total_dias = Math.round(element.qty_budgeted / element.budgeted_income)
      

      let dias_necessarios = sem_trabalho + total_dias

      return dias_necessarios
    },
    changedWorkUnitListDate(v){
      this.fields.work_unit_list.forEach(element => {
        if(element.id_work_unit == v){
            let total_dias = Math.round(element.qty_budgeted / element.budgeted_income)

            var days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
            let from = new Date(element.start_date) 
            let sem_trabalho = 0;
            for(let i = from; total_dias >= 0; i.setDate(i.getDate() + 1)){                
                if(this.fields.work_days[days[i.getDay()]]){
                    total_dias--
                }else{
                    sem_trabalho++
                }                
            }
            
            total_dias = Math.round(element.qty_budgeted / element.budgeted_income)
            

            let dias_necessarios = sem_trabalho + total_dias

            let dt = new Date(element.start_date)
            dt.setDate(dt.getDate() + dias_necessarios)
            this.newWorkUnit.start_date = dt.getFullYear() + '-' + (dt.getMonth()+1) + '-' + dt.getDate()
        }
      });
    },
    deleteCommissioner(c){
      let i = 0
      let pos = 0
      this.fields.commissioners.forEach(element => {
        if(element.id == c.id)
          pos = i

        i++
      });

      this.fields.commissioners.splice(pos, 1)
    },
    deleteEngineer(e){
      let i = 0
      let pos = 0
      this.fields.engineers.forEach(element => {
        if(e.id == element.id)
          pos = i

        i++
      });

      this.fields.engineers.splice(pos, 1)
    },
    addEngineer(){
      this.engineers.forEach(element => {
        if(element.id == this.fields.engineer_id)
          this.fields.engineers.push(element)
      });
    },
    addComissioner(){
      this.commissioners.forEach(element => {
        if(element.id == this.fields.commissioner_id)
          this.fields.commissioners.push(element)
      });
    },
    importWorkBudgeted(){
      this.budgetedWorks.forEach(element => {
        if(element.id == this.importedWork){
          this.fields.name = element.name
          this.fields.id_client = element.client_id
          this.fields.work_value = element.budget

          this.fields.work_value_k = element.budget_k

          console.log("AQUI")
          console.log(element)
          this.fields.k = element.k

          this.fields.subempreitadas = element.subempreitadas

          // Percorrer e adicionar unidades
          element.chapters.forEach(chapter => {
            //this.fields.chapters.push(chapter)
            let classes = []
            chapter.classes.forEach(cl_ => {
              let units = []
              cl_.units.forEach(un => {
                let unidade = {}
                unidade.id = un.unit_id
                unidade.code = un.code
                unidade.unit = un.unit
                unidade.unit_measure = un.unit_measure
                unidade.qty_budgeted = un.qty
                unidade.budgeted_income = null
                unidade.team = null
                unidade.start_date = null
                unidade.work_unit_id = null
                unidade.unit_cost = un.cost
                units.push(unidade)
              });
              cl_.units = units
              classes.push({
                id: cl_.id,
                description: cl_.description,
                units: units
              })
              
            });


            this.fields.chapters.push({
              id: chapter.id,
              description: chapter.description,
              chapter_classes: chapter.chapter_classes,
              classes: classes
            })
            /*chapter.classes.forEach(chapter_class => {
              chapter_class.units.forEach(budgetUnit => {                
                this.units.forEach(unitElement => {
                  let unit = ''
                  let unit_measure = ''
                  let code = ''
                  if(budgetUnit.unit_id == unitElement.id){
                    unit = unitElement.name
                    unit_measure = unitElement.unit
                    code = unitElement.code

                    

                    this.fields.work_unit_list.push({
                      id: budgetUnit.id,
                      work_id: budgetUnit.unit_id,
                      id_work_unit: budgetUnit.unit_id,
                      unit: unit,
                      code: code,
                      unit_measure: unit_measure,
                      team: budgetUnit.budget_work_hand.length,
                      qty_budgeted: budgetUnit.qty,
                      budgeted_income: null   
                    })
                  }
                });
              });
            });*/
          });


          this.importBudgetedWork = false

          console.log(this.fields)
        }
        
      });
    },
    analyzeDependencies(){      
      this.fields.work_unit_list.forEach(element => {
        let father_dependecy = null
        if(element.id_work_unit == this.newWorkUnit.work_unit_id)
          father_dependecy = element


        if(father_dependecy != null){
          let father_days = this.calculateNumberWorkDays(father_dependecy)
          let dt = new Date(father_dependecy.start_date)
          dt.setDate(dt.getDate() + father_days)
          this.newWorkUnit.start_date = dt.getFullYear() + '-' + (dt.getMonth()+1) + '-' + dt.getDate()
        }
      });



      // Percorrer todas as Unidades de Obra
      this.fields.work_unit_list.forEach(father => {
        // Verificar se esta faz parte de alguma dependência (novo for)
        this.fields.work_unit_list.forEach(dependencia => {
          // Se alguém tiver essa dependência, atualizar o start date de acordo com o fim de trabalho da UO
          // do primeiro ciclo
          if(father.id == dependencia.work_unit_id || father.id_work_unit == dependencia.work_unit_id){
            // Temos os dias que demora a tarefa PAI a ser executada
            let father_days = this.calculateNumberWorkDays(father)
            // Então agora vamos ter de atualizar o dia de inicio da tarefa filho
            let dt = new Date(father.start_date)
            dt.setDate(dt.getDate() + father_days)
            dependencia.start_date = dt.getFullYear() + '-' + (dt.getMonth()+1) + '-' + dt.getDate()
          }

        });
      });
      // continuar para todas as unidades de obra  


      // Organizar array por datas
      let final_array = this.fields.work_unit_list.sort(
          (obA, obB)  =>  Number(obA.start_date == null ? new Date('1970-01-01') : new Date(obA.start_date)) - Number(obB.start_date == null ? new Date('1970-01-01') : new Date(obB.start_date)),
        );

      this.fields.work_unit_list = final_array

      
    },
    async addWorkUnitToWork(){
      let unit = ''
      let unit_measure = ''
      let code = ''

      this.units.forEach(element => {
        if(element.id == this.newWorkUnit.work_id){
          unit = element.name
          unit_measure = element.unit
          code = element.code
        }
      });

      if(this.chooseWorkUnitDate == false)
        this.newWorkUnit.work_unit_id = null

        
        

      if(this.newWorkUnit.edit){
        this.newWorkUnit.code = code
        this.newWorkUnit.unit = unit
        this.newWorkUnit.measure = unit_measure
        this.newWorkUnit.edit = false
        this.showWorkList = false
        
      
        // Ao editar vamos analisar dependências
        this.analyzeDependencies()

        this.cleanWorkUnit()
        return
      }



      this.fields.work_unit_list.push({
        id: 'new_' + this.fields.work_unit_list.length,
        work_id: this.newWorkUnit.work_id,
        id_work_unit: this.newWorkUnit.work_id,
        unit: unit,
        code: code,
        unit_measure: unit_measure,
        team: this.newWorkUnit.team,
        qty_budgeted: this.newWorkUnit.qty_budgeted,
        budgeted_income: this.newWorkUnit.budgeted_income,
        start_date: this.newWorkUnit.start_date,
        work_unit_id: this.newWorkUnit.work_unit_id
      })

      this.editableWorkUnit = null

      this.analyzeDependencies()

      this.cleanWorkUnit()


      this.showWorkList = false
      //await this.getWorkUnitsList()
    },
    closeWorkUnitModal(){
      this.showWorkList = false; 
      this.editableWorkUnit = null;
      this.showWorkList = false;
    },
    viewListWorkUnit(item){
      this.newWorkUnit = item
      this.newWorkUnit.edit = true

      if(item.work_unit_id != null){
        this.chooseWorkUnitDate = true
        this.fields.work_unit_list.forEach(element => {
          if(element.id == item.work_unit_id)
            this.newWorkUnit.work_unit_id = element.id_work_unit
        });
      }else{
        this.chooseWorkUnitDate = false
        this.newWorkUnit.work_unit_id = null
      } 

      this.showWorkList = true
    },
    deleteWorkUnitList(id){
      let i = 0
      let pos = 0
      let objectRemoved = null
      this.fields.work_unit_list.forEach(element => {
        if(element.id == id){
          pos = i
          objectRemoved = element
        }
        
        i++
      });

      this.fields.work_unit_list.splice(pos, 1)

      // Validar dependencias

      // Verificar se existe alguém que depende deste objeto
      this.fields.work_unit_list.forEach(element => {
        if(element.work_unit_id == objectRemoved.id_work_unit){
          element.work_unit_id = objectRemoved.work_unit_id
        }
      });
      this.analyzeDependencies()

    },
    async getWorkUnitsList(){
      /*this.workUnitList = []
      await APIWorkUnitList.get(this.$route.params.id).then((data) => {

      let info = data.data
      info.forEach(element => {        
        this.workUnitList.push({
          id: element.id,
          name: element.object.name,
          qty_budgeted: element.qty_budgeted,
          unit: element.object.unit,
          budgeted_income: element.budgeted_income,
          team:element.team,
          object: element.object
        })
      });
      });*/
    },
    cleanWorkUnit(){
      this.newWorkUnit = {
        id: null,
        work_id: null,
        code: null,
        team: null,
        unit: null,
        qty_budgeted: null,
        budgeted_income: null,
        edit: false,
      }
    },
    removeMaoObra(line_id){
      if(this.fields.template == null){
        let index = this.template_fields.workers.findIndex(item => item.id == line_id);

        this.template_fields.workers.splice(index, 1)

        this.loading = false;
        return
      }
      this.loading = true
      Templates.removeMaoObra(line_id, this.fields.template.id).then(()  =>  {
        
        let index = this.template_fields.workers.findIndex(item => item.id == line_id);

        this.template_fields.workers.splice(index, 1)

        this.loading = false;
      })
    },
    removeMaquina(line_id){
      if(this.fields.template == null){
        let index = this.template_fields.machines.findIndex(item => item.id == line_id);

        this.template_fields.machines.splice(index, 1)

        this.loading = false;
        return
      }
      this.loading = true
      Templates.removeMaquina(line_id, this.fields.template.id).then(()  =>  {
        
        let index = this.template_fields.machines.findIndex(item => item.id == line_id);

        this.template_fields.machines.splice(index, 1)

        this.loading = false;
      })
    },
    removeMaterial(line_id){
      if(this.fields.template == null){
        let index = this.template_fields.materials.findIndex(item => item.id == line_id);

        this.template_fields.materials.splice(index, 1)

        this.loading = false;
        return
      }
      this.loading = true
      Templates.removeMaterial(line_id, this.fields.template.id).then(()  =>  {
        
        let index = this.template_fields.materials.findIndex(item => item.id == line_id);

        this.template_fields.materials.splice(index, 1)

        this.loading = false;
      })
    },
    removeUnidade(line_id){
      if(this.fields.template == null){
        let index = this.template_fields.units.findIndex(item => item.id == line_id);

        this.template_fields.units.splice(index, 1)

        this.loading = false;
        return
      }
      this.loading = true
      Templates.removeUnidade(line_id, this.fields.id).then(()  =>  {
        
        let index = this.template_fields.units.findIndex(item => item.id == line_id);

        this.template_fields.units.splice(index, 1)

        this.loading = false;
      })
    },
    async saveTemplate(){
      
     /* let error = ''
      this.template_fields.machines.forEach(element => {
        if(element.started_hours == undefined || element.started_hours == '')
          error = 'Horas/KM Inicio em falta, nas máquinas'
      });

      if(error != ''){
        this.error.message = error
        this.error.show = true
        return
      }*/
      
      await Templates[this.methodTemplate](this.template_fields, this.fields.id).then(() => {

        this.success = true;

        this.reloadPage++

        this.loading = false;

        }).catch(err => {
        this.loading = false;

          if(err.response.status == 422) {

            this.$refs.form.setErrors(err.response.data.errors);
            
            return;
          }

          this.error.title = this.$t('globals.error') + " " + err.response.status;

          this.error.message = err.response.data.message;

        });
    },
    onSuccess() {
      if(this.fields.id != null) {
        this.$emit('reload', this.tab)
        this.success = false
        return
      }
      let domain = '/' + this.$session.get('domain')
      if(this.response.id != null){
        this.$router.push(domain + '/admin/works-settings/works/' + this.response.id);
      }else
        this.$router.push(domain + '/admin/works-settings/works');
    },
    async fillBaseData(){
      await Budget.accepted().then(({data}) => {
        this.budgetedWorks = Object.keys(data).map(item => {

        
          if(data[item].status == "ACCEPTED"){
            let k_value = data[item].k

            return {
              id: data[item].id,
              name: data[item].work_name,
              client_id: data[item].client_id,
              k: k_value,
              budget_k: data[item].budget_k,
              //units: data[item].budget_units,
              budget: data[item].budget,
              subempreitadas: data[item].subempreitadas,
              chapters: data[item].chapters
            }
          }

        });
        
      });
      
      await Unit.list().then(({data}) => {
          this.units = data;
      });

      Work.listStatus().then(({data}) => {
        this.status = Object.keys(data).map(item => {

          return {
            id: item,
            name: data[item],
          }

        });
      });

      ClientUsers.list().then(({data}) => {
        this.commissioners = data.filter(item => item.roles.some(role => role.name == "encarregado"));

        this.overseers = data.filter(item => item.roles.some(role => role.name == "maquinista"));

        this.engineers = data.filter(item => item.roles.some(role => role.name == "dto"));
      });


      Warehouse.list().then(({data}) => {
        this.warehouses = data
      });

    },
    submit() {
      let problem = false
      
      /*this.fields.work_unit_list.forEach(element => {
        if(element.qty_budgeted == null)
          problem = true
        if(element.budgeted_income == null)
          problem = true
      });*/

      this.fields.chapters.forEach(ch => {
        ch.classes.forEach(ch_class => {
          ch_class.units.forEach(un => {
            if(un.qty_budgeted == null)
              problem = true
            /*if(un.budgeted_income == null)
              problem = true*/
          });
        });
      });
      

      let has_week_day = false
      if(this.fields.work_days.monday)
        has_week_day = true
      if(this.fields.work_days.tuesday)
        has_week_day = true
      if(this.fields.work_days.wednesday)
        has_week_day = true
      if(this.fields.work_days.thursday)
        has_week_day = true
      if(this.fields.work_days.friday)
        has_week_day = true
      if(this.fields.work_days.saturday)
        has_week_day = true
      if(this.fields.work_days.sunday)
        has_week_day = true
        
      if(has_week_day == false){
        this.error.message = this.$t('work.week_day_missing')
        this.error.show = true
        return
      }
      
      if(problem == true){
        this.error.message = this.$t('work.unit_work_missing_fields')
        this.error.show = true
        return
      }

      // Verificar se existe pelo menos um encarregado e um engenheiro
      if(this.fields.engineers.length == 0 || this.fields.commissioners.length == 0){
        this.error.message = this.$t('work.missing_in_charge')
        this.error.show = true
        return
      }

      this.$refs.form.validate().then((result) => {
        this.loading = true;
        
        if(!result){
          this.loading = false;
          return;
        }

        if(this.fields.postal_code1 != null)
          this.fields.postal_code = this.fields.postal_code1 + '-' + this.fields.postal_code2


        // Organizar as unidades de obra por datas
     
        
        let final_array = this.fields.work_unit_list.sort(
          (obA, obB)  =>  Number(obA.start_date == null ? new Date('1970-01-01') : new Date(obA.start_date)) - Number(obB.start_date == null ? new Date('1970-01-01') : new Date(obB.start_date)),
        );

  
        this.fields.work_unit_list = final_array

        this.fields.imported_work = this.importedWork

    
        Work[this.method](this.fields, this.fields.id).then((resp) => {

          this.success = true;

          this.response.id = resp.data.id

          this.loading = false;
          
        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
            }

            this.error.title = this.$t('globals.error') + " " + err.response.status;

            this.error.message = err.response.data.message;

          });
      })
    },
    
  },
  computed: {
    getTotalSubempreitadas(){
      if(this.fields.subempreitadas == null)
        return '(0)'

      if(this.fields.subempreitadas == undefined)
        return '(0)'

      return '(' + this.fields.subempreitadas.length + ')'
    },
    computedChoosingWorkUnit(){
      let returner = []


      this.fields.work_unit_list.forEach(element => {
        if(element.id_work_unit != this.newWorkUnit.id_work_unit)
          returner.push(element)
      });
      return returner
    },
    canChooseWorkUnitStart(){
      let res = false

      this.fields.work_unit_list.forEach(element => {
        if(element.start_date != null)
          res = true
      });

      return !res
    }
  }
};
</script>
