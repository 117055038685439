<template>
    <v-card elevation="10" :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75);'" :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
        <v-container fluid>
              <validation-observer ref="form" v-slot="{ invalid }">
                <v-form @submit.prevent="submit"  class="mb-16">
                          <v-progress-linear v-if="loading"
                              indeterminate
                              class="global-loader"
                          ></v-progress-linear>
                          <fieldset :disabled="loading">
                            <v-card class="transparent elevation-0">
                                <v-card-title :class="$root.$vuetify.theme.isDark ? 'primary' : 'primary white--text'">
                                  {{$t('globals.material_composite')}}
                                </v-card-title>
                              <v-row>
                                <v-col cols="12" md="4">
                                      <validation-provider v-slot="{ errors }" vid="Reference" :name="$t('globals.reference')" rules="required">
                                          <v-text-field
                                          v-model="fields.reference"
                                          prepend-inner-icon="mdi-account-details"
                                          :label="$t('globals.reference') + ' *'"
                                          clearable
                                          :error-messages="errors"
                                          ></v-text-field>
                                      </validation-provider>
                                  </v-col>
                                <v-col cols="12" md="12">
                                      <validation-provider v-slot="{ errors }" vid="name" :name="$t('globals.name')" rules="required">
                                          <v-text-field
                                          v-model="fields.name"
                                          prepend-inner-icon="mdi-account-details"
                                          :label="$t('globals.name') + ' *'"
                                          clearable
                                          :error-messages="errors"
                                          ></v-text-field>
                                      </validation-provider>
                                  </v-col>
                                  <v-col cols="12" md="12">
                                      <validation-provider v-slot="{ errors }" vid="description" :name="$t('globals.description')" rules="required">
                                          <v-textarea
                                          v-model="fields.description"
                                          prepend-inner-icon="mdi-account-details"
                                          :label="$t('globals.description') + ' *'"
                                          clearable
                                          :hint="$t('material_composite.material_description')"
                                          :error-messages="errors"
                                          ></v-textarea>
                                      </validation-provider>
                                  </v-col>
                                  
                                  
                              </v-row>
                            </v-card>
                          </fieldset>
                          
                    <v-col cols="12">
                      <v-dialog
                          v-model="addMaterial"
                          max-width="800px"
                        >
                        
                        <v-toolbar class="primary white--text">{{$t('globals.material')}}</v-toolbar>
                          <template v-slot:activator="{ props }">
                            <v-btn
                                                    :class="$root.$vuetify.theme.isDark ? 'white primary--text mb-2' : 'primary mb-2'"
                              @click="addMaterial = true"
                              v-bind="props"
                            >
                              {{$t('material_composite.insert_material')}}
                            </v-btn>
                          </template>

                          <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                              <v-card-text>
                                <v-col cols="12">
                                    <v-select
                                        v-model="newMaterial.id"
                                        :items="materials"
                                        item-value="id"
                                        :item-text="item => item.name"
                                        menu-props="auto"
                                        :label="$t('globals.material')"
                                        prepend-inner-icon="mdi-account"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                      v-model="newMaterial.qty"
                                      prepend-inner-icon="mdi-account-details"
                                      :label="$t('globals.quantity') + ' *'"
                                      clearable
                                    ></v-text-field>
                                </v-col>
                            </v-card-text>
                            <v-card-actions class="justify-center">
                              <v-btn :disabled="newMaterial.qty == null || newMaterial.id == null" color="success" @click="addNewMaterial">{{$t('globals.insert')}}</v-btn>
                              <v-btn color="error" right @click="addMaterial = false">{{$t('globals.close')}}</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      <v-expansion-panels focusable v-model="panel">
                        <v-expansion-panel>
                          <v-expansion-panel-header disable-icon-rotate>
                            <h3>
                              <rp-icon :icon="$root.$vuetify.theme.isDark ? 'rp-barcode-yellow-filled' : 'rp-barcode-blue-filled'" class="mr-2"/> <span>{{$t('globals.materials')}}</span>
                            </h3>
                            <template v-slot:actions>
                              <p>
                                <v-icon color="error">
                                  mdi-sigma
                                </v-icon>
                                {{ fields.material_pieces.length }}
                              </p>
                            </template>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content :class="($root.$vuetify.theme.isDark ? 'secondary' : '')">
                            <v-row>
                              <v-col cols="9" xs="9" sm="9" md="12">
                                <!-- DESKTOP VERSION -->
                                <v-simple-table dense class="d-none d-lg-block">
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th class="text-center">
                                          {{$t('globals.material')}}
                                        </th>
                                        <th class="text-center">
                                          {{$t('globals.quantity')}}
                                        </th>
                                        <th class="text-center">
                                          {{$t('globals.unit_cost')}}
                                        </th>
                                        <th class="text-center">
                                          {{$t('globals.total_cost')}}
                                        </th>
                                        <th class="text-center">
                                          {{$t('globals.options')}}
                                        </th>
                                      </tr>
                                    </thead>
                                      <tbody :class="$root.$vuetify.theme.isDark ?  'secondary' : ''">
                                      <tr v-for="(element, index) in fields.material_pieces" v-bind:key="index">
                                        <td class="text-center">
                                            <label>{{element.material}}</label>
                                        </td>
                                        <td class="text-center">
                                            <label>{{element.qty}}</label>
                                        </td>
                                        <td class="text-center">
                                            <label>{{element.cost}}</label>
                                        </td>
                                        <td class="text-center">
                                            <label>{{element.qty * element.cost}}</label>
                                        </td>
                                        <td class="text-center">
                                            <label>{{element.team}}</label>
                                        </td>
                                        <td class="text-center">
                                          <v-row>
                                            <v-col cols="12" md="3">                  
                                              <IconRemove
                                                v-if="$root.session.hasPermission(['super', 'materials_composite.delete'])"
                                                @on-submit="deleteMaterialCompositePiece(element)"
                                              />
                                            </v-col>
                                          </v-row>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                                <!-- DESKTOP VERSION -->
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>

                    <v-col cols="3">
                        <v-card
                            :class="($root.$vuetify.theme.isDark ? 'primary' : 'secondary') + ' grow'"
                            outlined
                            height="125px"
                            dark
                            elevation="0"
                        >
                          <v-card-subtitle>
                              <div class="text-center mt-n4 mb-3">
                                  <span class="text-h6 font-weight-light">{{$t('globals.cost')}}</span>
                              </div>
                          </v-card-subtitle>
                          <v-card-text class="mt-n6">
                              <v-list-item three-line>
                                  <v-list-item-content>                                
                                      <v-icon
                                          large
                                          center
                                      >
                                      {{$currency_icon()}}
                                      </v-icon>
                                  </v-list-item-content>
                                  <v-list-item-content>                                
                                      <v-list-item-title>{{ totalWorkCost }}</v-list-item-title>
                                  </v-list-item-content>
                              </v-list-item>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    
                    <v-col cols="12" v-if="false">
                      <v-dialog
                          v-model="addIndirectWorkHand"
                          max-width="800px"
                        >
                          <template v-slot:activator="{ props }">
                            <v-btn
                              color="primary"
                              class="mb-2"
                              @click="addIndirectWorkHand = true"
                              v-bind="props"
                            >
                              {{$t('material_composite.insert_indirect_work_hand')}}
                            </v-btn>
                          </template>

                          <v-card>
                              <v-card-text>
                                <v-col cols="12">
                                    <v-select
                                        v-model="newWorkHand.id"
                                        :items="indirectWorkHands"
                                        item-value="id"
                                        :item-text="item => item.description"
                                        menu-props="auto"
                                        :label="$t('material_composite.indirect_work_hand')"
                                        prepend-inner-icon="mdi-account"
                                    ></v-select>
                                </v-col>                          
                                <v-col cols="12" md="6">
                                    <v-text-field
                                      v-model="newWorkHand.hour"
                                      prepend-inner-icon="mdi-account-details"
                                      :label="$t('globals.hours') + ' *'"
                                      clearable
                                    ></v-text-field>
                                </v-col>
                            </v-card-text>
                            <v-card-actions class="justify-center">
                              <v-btn :disabled="newWorkHand.id == null || newWorkHand.hour == null" color="success" @click="addNewIndirectWorkHand">{{$t('globals.insert')}}</v-btn>
                              <v-btn color="error" right @click="addIndirectWorkHand = false">{{$t('globals.close')}}</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      <v-expansion-panels focusable v-model="panel">
                        <v-expansion-panel>
                          <v-expansion-panel-header disable-icon-rotate>
                            <h3>
                              <rp-icon icon="rp-barcode-blue-filled" class="mr-2"/> <span>{{$t('material_composite.indirect_work_hand')}}</span>
                            </h3>
                            <template v-slot:actions>
                              <p>
                                <v-icon color="error">
                                  mdi-sigma
                                </v-icon>
                                350
                              </p>
                            </template>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-row>
                              <v-col cols="9" xs="9" sm="9" md="12">
                                <!-- DESKTOP VERSION -->
                                <v-simple-table dense class="d-none d-lg-block">
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th class="text-center">
                                          {{$t('globals.description')}}
                                        </th>
                                        <th class="text-center">
                                          {{$t('globals.hours')}}
                                        </th>
                                        <th class="text-center">
                                          {{$t('material_composite.hour_cost')}}
                                        </th>
                                        <th class="text-center">
                                          {{$t('globals.total_cost')}}
                                        </th>
                                        <th class="text-center">
                                          {{$t('globals.options')}}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(element, index) in fields.indirect_work_hand" v-bind:key="index + 10000">
                                        <td class="text-center">
                                            <label>{{element.work_description}}</label>
                                        </td>
                                        <td class="text-center">
                                            <label>{{element.hours}}</label>
                                        </td>
                                        <td class="text-center">
                                            <label>{{element.cost}}</label>
                                        </td>
                                        <td class="text-center">
                                            <label>{{element.hours * element.cost}}</label>
                                        </td>
                                        <td class="text-center">
                                            <label>{{element.team}}</label>
                                        </td>
                                        <td class="text-center">
                                          <v-row>
                                            <v-col cols="12" md="3">                  
                                              <IconRemove
                                                v-if="$root.session.hasPermission(['super', 'materials_composite.delete'])"
                                                @on-submit="deleteMaterialCompositePiece(element)"
                                              />
                                            </v-col>
                                          </v-row>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                                <!-- DESKTOP VERSION -->
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                    
                          
                  <v-row class="mt-6" align="center" justify="space-around">
                      <v-btn :disabled="invalid" depressed :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary'" type="submit">
                      {{$t('globals.save')}}
                      </v-btn>
                  </v-row>
                </v-form>
              </validation-observer>
          
          <DialogSuccess :opened="success" @on-ok="onSuccess" >
            {{$t('material_composite.success')}}
          </DialogSuccess>
          <SuccessSnackBar v-model="modalSuccess.show" :message="modalSuccess.message" />
        </v-container>

    </v-card>
  </template>
  <script>
  import MaterialsComposite from "@/api/MaterialsComposite.js";
  import Indirect from "@/api/Indirect.js";
  import Materials from "@/api/Materials.js"
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  import IconRemove from '@/components/ui/IconRemove.vue';
  import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';
  
  export default {
    components: {
      DialogSuccess,
      IconRemove,
      SuccessSnackBar
    },
    props: {
     material: Object,
     method: String,
     disabled: Boolean,
   },
   watch: { 
      material: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;

  
          this.fields.id = data.id;
        }
      },
    },
    beforeCreate(){
    },
    data: () => ({
      tab: 0,
      panel: 0,
      modalSuccess:{
        show: false,
        message:""
      },
      addMaterial: false, 
      addIndirectWorkHand: false,
      newWorkHand:{
        id: null,
        hour: null,
        workHandId: null
      },
      newMaterial:{
        id: null,
        work_id: null,
        qty: null
      },
      fields: {
        id: null,
        work_id: null,
        descritpion: null,
        name: null,
        qty:null,
        material_id:[],
        material_pieces: [],
        indirect_work_hand: []
      },
      loading:false,
      error: {
        title: '',
        message: '',
      },
      success: false,
      works: [],
      materials:[],
      compositeMaterials: [],
      indirectWorkHands: [],
      totalWorkCost: 0,
    }),
    mounted(){
      this.fillBaseData();
    },
    methods:{
      deleteMaterialCompositePiece(item){
        MaterialsComposite.deletePiece( item.id )
              .then(() => {
                  this.getMaterials()
              });
      },
      getMaterials(){
        Materials.list().then(({data}) => {
            this.materials = data
        });
      },
      addNewIndirectWorkHand(){
        let work_description = ''
        let cost = 0

        this.indirectWorkHands.forEach(element => {
          if(element.id == this.newWorkHand.id){
            work_description = element.description
            cost = element.cost
          }
        });

        this.fields.indirect_work_hand.push({
          id: '',
          indirect_id: this.newWorkHand.id,
          hours: this.newWorkHand.hour,
          work_description: work_description,
          cost: cost
        })

        this.modalSuccess.message=this.$t('material_composite.success_indirect_work_hand');
        this.modalSuccess.show = true;

        this.newWorkHand.id = null;
        this.newWorkHand.hour = null

        this.calculateCosts()
      },
      addNewMaterial (){
        let material = ''
        let cost = 0
        this.materials.forEach(element => {
          if(element.id == this.newMaterial.id){
            material = element.description
            cost = element.cost
          }
        });

        this.fields.material_pieces.push({
          id: '',
          material_id: this.newMaterial.id,
          qty: this.newMaterial.qty,
          material: material,
          cost: cost
        })

        this.modalSuccess.message=this.$t('material_composite.success_added')
        this.modalSuccess.show = true;

        this.newMaterial.id = null;
        this.newMaterial.qty = null

        this.calculateCosts()
      },
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        let domain = '/' + this.$session.get('domain')
        this.$router.push(domain + '/admin/budgeting/materials-composite');
      },
      fillBaseData(){
        Materials.list().then(({data}) => {
            this.materials = data
        });
        Indirect.list().then(({data}) => {
            this.indirectWorkHands = data
        });
        
        
        this.calculateCosts()
      },
      calculateCosts(){
        this.fields.material_pieces.forEach(element => {
          this.totalWorkCost += element.cost * element.qty
        });

        this.fields.indirect_work_hand.forEach(element => {
          this.totalWorkCost += element.cost * element.hours
        });

        if(this.totalWorkCost == 0 || this.totalWorkCost == null)
          this.totalWorkCost = 0
        else
          this.totalWorkCost = this.$currency(this.totalWorkCost)
      },
      submit() {
  
        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }
  
          let data = {...this.fields};
  
         
  
          MaterialsComposite[this.method](data, this.fields.id).then(() => {
  
            this.success = true;
  
            this.loading = false;
            
          }).catch(err => {
            this.loading = false;
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = this.$t('globals.error') + " " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })
      }
    },
    computed: {
      
    }
  };
  </script>
  