<template>
    <v-app>
      <div class="bg-email-confirmation">
      </div>
      <v-main>
        <v-container fill-height fluid>
            <v-card class="rounded-pill elevation-10" style="width: 50%; margin: auto; display: block;">
                <v-row justify="center" align="center">
                    <v-col cols="12" md="12">
                    <v-img
                        style="margin: auto; display: block;"
                        lazy-src="@/assets/icons/persona dreamdeal tech.png"
                        src="@/assets/icons/persona dreamdeal tech.png"
                        max-width="450"
                        contain
                        ></v-img>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <span class="text-h4">{{$t('error.woops')}}</span>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <span class="text-h6">{{$t('error.contact')}}</span>
                    </v-col>
                    <v-col cols="12" class="text-center">
                      info@reportlake.com
                    </v-col>
                </v-row>
                </v-card>
        </v-container>
      </v-main>
    </v-app>
  </template>
  <style>
  .text-white input {
      color: white !important;
    }
    .text-white label {
      color: white !important;
    }
    .text-white i {
      color: white !important;
    }
    .text-white v-messages__wrapper{
      color: white !important;
    }
  
  .bg-email-confirmation {
      background: url( '../assets/login/pexels-photo-3369239.jpeg') no-repeat;
      width: 100%;
      height: 100%;
      position: absolute;
      background-size: cover;
    }
    @media (min-width: 600px) {
      .bg-email-confirmation {
      
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        position: absolute;
        background: url( '../assets/login/pexels-photo-3369239.jpeg') no-repeat;
        
        background-size: cover;
        /*-webkit-filter: grayscale(100%); 
        filter: grayscale(100%);
        transform: scale(1.1);*/
      }
  
      .center-button{
        width: 80%;
      }
  }
  </style>
<script>
export default{
    name: 'Error',
 
}
</script>