import BaseApi from "./BaseApi";

export default class ShoppingRequest extends BaseApi{
    construct() {}
    
 
    static async search(params) {
        return await ShoppingRequest.getAxiosInstance().get(ShoppingRequest.getFullApiUrl(),{params: params});
    }

    static async create(params) {
        return await ShoppingRequest.getAxiosInstance().post(ShoppingRequest.getFullApiUrl(),params);
    }

    static async find(id) {
        return await ShoppingRequest.getAxiosInstance().get(ShoppingRequest.getFullApiUrl() + id);
    }

    static async update(params, id) {
        return await ShoppingRequest.getAxiosInstance().post(ShoppingRequest.getFullApiUrl() + id,params);
    }

    static async delete(id) {
        return await ShoppingRequest.getAxiosInstance().delete(ShoppingRequest.getFullApiUrl() + id);
    }
    
    static async list(params) {
        let url = ShoppingRequest.getFullApiUrl() + "list";

        return ShoppingRequest.getAxiosInstance().get(url,{params: params});
    }
    
    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/shopping-request/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/shopping-request/";
    }
}
