<template>
    <section class="mt-16">
      <v-progress-linear v-if="loading"
        indeterminate
        class="global-loader"
      ></v-progress-linear>
      <v-breadcrumbs
          v-if="this.$root.$vuetify.theme.isDark"
          large
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
        <v-breadcrumbs
          v-else
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-hard-hat</v-icon> <span>Edição de pedidos externos</span>
        </h1>
  
        <div class="mt-16">
  
          <template v-if="loading">
            <v-row>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row class="mt-6" align="center" justify="space-around">
              <v-skeleton-loader
                  type="button"
                ></v-skeleton-loader>
            </v-row>
          </template>
          <ExternalRequest v-else method="update" :external_request="external_request" @refresh="refresh" :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'">
            </ExternalRequest>
        </div>
    </section>
  </template>
  <script>
  import ExternalRequest from '@/components/external_request/ExternalRequest'
  import ExternalRequestAPI from "@/api/ExternalRequest.js";
  
  export default {
    components: {
        ExternalRequest
    },
    beforeCreate(){
      document.title = "Report Lake- Edição de pedido externo";
    },
    data: () => ({
        external_request: {},
        loading:true
    }),
    async mounted(){
      if(!this.$root.session.hasPermission(["super", "external_request.update"])) {
        this.$router.push('/admin');
      }
        
      await ExternalRequestAPI.find(this.$route.params.id).then(({data}) => { this.external_request = data; this.loading = false;});
      this.loading = false
    },
    methods:{
      refresh(){
        ExternalRequestAPI.find(this.$route.params.id).then(({data}) => { this.external_request = data; this.loading = false;});
      }
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/' + this.$session.get('domain') + '/admin',
            exact: true,
          },
          {
            text: 'Pedido de compra',
            disabled: false,
            to: '/' + this.$session.get('domain') + '/admin/shopping-request/' + this.external_request.id_shopping_request,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  