<template>
    <v-dialog
      v-model="dialog"
      width="50%"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="primary"
          v-bind="attrs"
          v-on="on"
        >
        <v-icon>mdi-stocking</v-icon>{{$t('materials.update_stock')}}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 primary white--text">
            {{$t('materials.update_stock')}}
        </v-card-title>

        <v-card-text class="mt-2">
            <validation-observer ref="formStockUpdater" v-slot="{ invalid }">
                <v-form @submit.prevent="submit"  class="mb-16">
                    <v-progress-linear v-if="loading"
                        indeterminate
                        class="global-loader"
                    ></v-progress-linear>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-menu
                            v-model="pickerDateChoose"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                              <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                      v-model="fields.shopping_date"
                                      :label="$t('shoppings.shopping_date')"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                  ></v-text-field>
                              </template>
                              <v-date-picker
                                :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                                v-model="fields.shopping_date"
                                @input="pickerDateChoose = false"
                              ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" md="6">
                            <validation-provider v-slot="{ errors }" vid="qty" :name="$t('globals.quantity')" rules="required">
                              <v-text-field
                                v-model="fields.qty"
                                prepend-inner-icon="mdi-counter"
                                :label="$t('globals.quantity') + ' *'"
                                @input="calculatePrices"
                                type="number"
                                clearable
                                :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="6">
                            <validation-provider v-slot="{ errors }" vid="unit" :name="$t('globals.unit_cost')" rules="required">
                              <v-text-field
                                v-model="fields.unit_cost"
                                :prepend-inner-icon="$currency_icon()"
                                :label="$t('globals.unit_cost') + ' *'"
                                @input="calculatePrices"
                                type="number"
                                clearable
                                :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="6">
                            <validation-provider v-slot="{ errors }" vid="cost" :name="$t('globals.total_cost')" rules="required">
                              <v-text-field
                                v-model="fields.cost"
                                :prepend-inner-icon="$currency_icon()"
                                :label="$t('globals.total_cost') + ' *'"
                                :disabled="true"
                                type="number"
                                clearable
                                :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-autocomplete
                                v-model="fields.supplier_id"
                                :items="suppliers"
                                :item-text="item => item.name"
                                item-value="id"
                                menu-props="auto"
                                :label="$t('globals.supplier')"
                                prepend-inner-icon="mdi-account"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                            <validation-provider v-slot="{ errors }" vid="invoice" :name="$t('invoices.attach')" rules="|">
                                <v-file-input
                                    accept=".pdf"
                                    :label="$t('invoices.attach')"
                                    v-model="fields.invoice"
                                    :error-messages="errors"
                                ></v-file-input>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row class="mt-6 mb-n16" align="center" justify="space-around">
                        <v-btn :disabled="invalid" depressed :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary'" type="submit">
                        {{$t('globals.save')}}
                        </v-btn>
                    </v-row>
                </v-form>
            </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>
<script>
import Company from "@/api/Company.js";
import Shopping from "@/api/Shopping.js";

export default{
    name: "StockUpdater",
    props:{
        material: Object
    },
    data(){
        return{
            pickerDateChoose: false,
            suppliers: [],
            dialog: false,
            loading: true,
            fields:{
                id: null,
                shopping_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                unit_cost: null,
                cost: null,
                qty: null,
                invoice: null,
                supplier_id: null
            },
            success: false
        }
    },
    mounted(){
        this.fillBaseData()
        this.loading = false
    },
    methods:{
        calculatePrices(){
            console.log(this.fields)
            if(this.fields.qty != null && this.fields.unit_cost != null){
                this.fields.cost = (parseFloat(this.fields.qty) * parseFloat(this.fields.unit_cost)).toFixed(2)
            }else if(this.fields.qty != null && this.fields.cost != null){
                this.fields.unit_cost = (parseFloat(this.fields.cost) / parseFloat(this.fields.qty)).toFixed(2)
            }else return
        },
        fillBaseData(){
            Company.list().then(({data}) => {
                this.suppliers = data;
            });
        },
        submit(){
            const formData = new FormData()
            formData.append('id', this.fields.id)
            if(this.fields.invoice != null)
                formData.append('file', this.fields.invoice)

            formData.append('supplier_id',  this.fields.supplier_id)
            formData.append('total',  this.fields.cost)
            formData.append('shopping_date', this.fields.shopping_date)

            let material_object = [{
                id: this.material.id,
                qty: this.fields.qty,
                name: this.material.name,
                cost: this.fields.unit_cost
            }]

            formData.append('materials', JSON.stringify(material_object))
            formData.append('invoiceAlreadyAdded', false)
            formData.append('removedMaterials', JSON.stringify([]))

            Shopping['create'](formData, this.fields.id).then(() => {
  
                this.success = true;

                this.loading = false;

                this.dialog = false;
                this.$emit('close-success')
                
                }).catch(err => {
                this.loading = false;

                    if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                    }

                    this.error.title = this.$t('globals.error') + " " + err.response.status;

                    this.error.message = err.response.data.message;

                });
        }
    }

}
</script>