<template>
    <v-card elevation="10" :style="this.$root.$vuetify.theme.isDark ? '' : (modalType ? '' : 'background-color: rgba(255, 255, 255, 0.75);')">
        <v-container>
            <validation-observer ref="form" v-slot="{ invalid }">
            <v-form @submit.prevent="submit"  class="mb-16">
                <v-progress-linear v-if="loading"
                    indeterminate
                    class="global-loader"
                ></v-progress-linear>
                <fieldset :disabled="loading">
                    <v-row>
                      <v-col cols="12" md="3">
                          <validation-provider v-slot="{ errors }" vid="descrição" :name="$t('globals.state')" rules="required">
                            <v-select
                                    :disabled="method=='create'"
                                    v-model="fields.status"
                                    :items="status"
                                    menu-props="auto"
                                    :error-messages="errors"
                                    :label="$t('globals.state') + ' *'"
                                    prepend-inner-icon="mdi-account"
                                ></v-select>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="9">
                          <validation-provider v-slot="{ errors }" vid="descrição" :name="$t('globals.description')" rules="required">
                              <v-text-field
                                v-model="fields.description"
                                prepend-inner-icon="mdi-account-details"
                                :label="$t('globals.description') + ' *'"
                                clearable
                                :error-messages="errors"
                              ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-menu
                            v-model="pickerDateChoose"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                              <template v-slot:activator="{ on, attrs }">
                              <validation-provider v-slot="{ errors }" vid="Data" :name="$t('globals.delivery_term')" rules="required">
                                  <v-text-field
                                      v-model="fields.work_delivery_term"
                                      :label="$t('globals.delivery_term')"
                                      :error-messages="errors"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                  ></v-text-field>
                              </validation-provider>
                              </template>
                              <v-date-picker
                                :allowed-dates="(date) => date >= new Date(new Date()+1).toISOString().substr(0, 10)"
                                v-model="fields.work_delivery_term"
                                @input="pickerDateChoose = false"
                              ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" md="12">
                          <validation-provider v-slot="{ errors }" vid="supplier" :name="$t('globals.supplier')" rules="|">
                              <v-select
                                    :disabled="method=='update'"
                                    v-model="fields.supplier_id"
                                    :items="suppliers"
                                    item-value="id"
                                    :item-text="item => item.name"
                                    menu-props="auto"
                                    :error-messages="errors"
                                    :label="$t('globals.supplier') + ' *'"
                                    prepend-inner-icon="mdi-account"
                                ></v-select>
                            </validation-provider>
                        </v-col>                  
                    </v-row>
                    <v-row>
                  <v-col cols="9" xs="9" sm="9" md="12">
                    <!-- DESKTOP VERSION -->
                    <v-simple-table dense class="d-none d-lg-block">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center">
                              {{$t('shopping_request.material_reference')}}
                            </th>
                            <th class="text-center">
                              {{$t('globals.material')}}
                            </th>
                            <th class="text-center">
                              {{$t('globals.description')}}
                            </th>
                            <th class="text-center">
                              {{$t('globals.quantity')}}
                            </th>
                            <th class="text-center">
                              {{$t('globals.unit')}}
                            </th>
                            <th class="text-center">
                              {{$t('globals.options')}}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(element, index) in fields.materials" v-bind:key="index + 10000">
                            <td class="text-center">
                                <label>{{element.material_ref}}</label>
                            </td>
                            <td class="text-center">
                                <label>{{element.material == null ? '-' : element.material}}</label>
                            </td>
                            <td class="text-center">
                                <label>{{element.description}}</label>
                            </td>
                            <td class="text-center">
                                <label>{{element.qty}}</label>
                            </td>
                            <td class="text-center">
                                <label>{{element.unit }}</label>
                            </td>
                            <td class="text-center">
                              <v-row>
                                <v-col cols="12" md="3">  
                                  <IconRemove
                                      v-if="$root.session.hasPermission(['super', 'shopping_request.delete'])"
                                      @on-submit="deleteLine(element)"
                                    />
                                </v-col>
                                <v-col cols="12" md="3">                                  
                                  <IconView
                                    class="mr-2"
                                    @click="editLine(element)"                                      
                                  />
                                </v-col>
                              </v-row>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <!-- DESKTOP VERSION -->
                    <v-dialog
                      v-model="addMaterial"
                      max-width="800px"
                      >
                      <template v-slot:activator="{ props }">
                          <div class="text-right mt-4">
                          <Tooltip
                              color="warning"
                              :text="$t('shoppings.add_material_invoice')"
                              bottom
                          >
                              <v-btn
                                  class="mx-2"
                                  fab
                                  dark
                                  large
                                  color="warning"
                                  @click="addMaterial = true;"
                                  v-bind="props"
                                  >
                                  <v-icon dark>
                                      mdi-plus
                                  </v-icon>
                              </v-btn>
                          </Tooltip>
                          </div>
                      </template>

                      <v-card>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                    v-model="newMaterial.material_ref"
                                    prepend-inner-icon="mdi-account-details"
                                    :label="$t('shopping_request.material_reference') + ' *'"
                                    clearable
                                ></v-text-field>
                              </v-col>   
                              <v-col cols="12">
                                <v-select
                                  v-model="newMaterial.material_id"
                                  :items="materials"
                                  item-value="id"
                                  :item-text="item => item.description"
                                  menu-props="auto"
                                  :label="$t('globals.material')"
                                  prepend-inner-icon="mdi-account"
                              ></v-select>
                              </v-col>                                 
                              <v-col cols="12" md="12">
                                <v-text-field
                                    v-model="newMaterial.description"
                                    prepend-inner-icon="mdi-account-details"
                                    :label="$t('globals.description')"
                                    clearable
                                ></v-text-field>
                              </v-col>                                    
                              <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="newMaterial.qty"
                                    prepend-inner-icon="mdi-account-details"
                                    type="number"
                                    :label="$t('globals.quantity')"
                                    clearable
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-select
                                    v-model="newMaterial.unit_management_id"
                                    :items="units"
                                    item-value="id"
                                    :item-text="item => item.abbreviation"
                                    menu-props="auto"
                                    :label="$t('globals.unit')"
                                    prepend-inner-icon="mdi-account"
                                ></v-select>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions class="justify-center">
                          <v-btn :disabled="newMaterial.material_ref == null || newMaterial.description==null || newMaterial.qty==null || newMaterial.unit_management_id==null" color="success" @click="insertMaterial()">{{$t('globals.insert')}}</v-btn>
                          <v-btn color="error" right @click="addMaterial = false; editMode = false;">{{$t('globals.close')}}</v-btn>
                          </v-card-actions>
                      </v-card>
                  </v-dialog>
                  </v-col>
                </v-row>
                
                <v-row class="mt-6" align="center" justify="space-around">
                    <v-btn :disabled="invalid" depressed color="primary" type="submit">
                    {{$t('globals.save')}}
                    </v-btn>
                </v-row>
                </fieldset>
            </v-form>
            <DialogSuccess :opened="success" @on-ok="onSuccess" >
                {{$t('external_request.success')}}
            </DialogSuccess>
            </validation-observer>
        </v-container>
        <Comments v-if="method!='create'" :place="this.$route.name"/>
    </v-card>
  </template>
  <script>
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  import Materials from '@/api/Materials.js'
  import ExternalRequestAPI from '@/api/ExternalRequest.js'
  import UnitsManagement from '@/api/UnitsManagement.js'
  import Tooltip from '@/components/ui/Tooltip.vue';
  import IconRemove from '@/components/ui/IconRemove.vue';
  import Company from '@/api/Company.js'
  import Comments from '@/components/ui/Comments.vue'
  import IconView from '@/components/ui/IconView.vue';
  
  export default {
    components: {
      DialogSuccess,
      Tooltip,
      IconRemove,
      Comments,
      IconView
    },
    props: {
      shopping_request: Object,
     method: String,
     disabled: Boolean,
     modalType: {
      typeof: Boolean,
      default: false
     }
   },
   watch: { 
    shopping_request: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;
          
          this.fields.id = data.id
          this.fields.work_delivery_term = data.work_delivery_term
          this.fields.supplier_id = data.supplier_id
          this.fields.supplier_delivery_term = data.supplier_delivery_term
          this.fields.work_id = data.work_id
          this.fields.status = data.status
          this.fields.materials = data.materials

        }
      },
    },
    beforeCreate(){
    },
    data: () => ({
      addMaterial: false,
      materials: [],
      editMode: false,
      newMaterial:{
        id: null,
        material_ref: null,
        qty: null,
        description: null,
        unit_management_id: null,
        material_id: null
      },
      pickerDateChoose: false, 
      fields: {
        description: null,
        work_delivery_term: null,
        supplier_id: null,
        supplier_delivery_term: null,
        work_id: null,
        status: null,
        materials: []
      },
      loading:false,
      error: {
        title: '',
        message: '',
      },
      success: false,
      suppliers:[],
      units: [],
      status: ['Pendente', 'Em andamento', 'Em armazém', 'Em processamento', 'Em trânsito', 'Concluído']
    }),
    mounted(){
      this.fillBaseData();

      if(this.method == 'create'){
        this.fields.status = this.status[0]
      }
    },
    methods:{
      editLine(item){
        this.newMaterial = item
        this.editMode = true
        this.addMaterial = true
      },
      deleteLine(item){
        let i = 0
        let pos = 0
        this.fields.materials.forEach(element => {
          if(element.id == item.id){
            pos = i
          }
          i++
        });
        this.fields.materials.splice(pos, 1)
      },
      insertMaterial(){
        let unit = ''
        let material = ''

        this.units.forEach(element => {
          if(element.id == this.newMaterial.unit_management_id)
            unit = element.abbreviation
        });

        this.materials.forEach(element => {
          if(element.id == this.newMaterial.material_id)
            material = element.description
        });



        let material_object = {
            id: this.newMaterial.id == undefined ? 'new_' + this.fields.materials.length : this.newMaterial.id,
            material_ref: this.newMaterial.material_ref,
            qty: this.newMaterial.qty,
            description: this.newMaterial.description,
            unit_management_id: this.newMaterial.unit_management_id,
            unit: unit,
            material_id: this.newMaterial.material_id,
            material: material
        }

        if(this.editMode == false){

          this.fields.materials.push(material_object)

          this.newMaterial.id = null
          this.newMaterial.material_ref = null
          this.newMaterial.qty = null
          this.newMaterial.description = null
          this.newMaterial.unit_management_id = null
        }

        if(this.editMode){
          this.fields.materials.forEach(element => {
            if(element.id == this.newMaterial.id){
              element.material = material
              element.material_id = this.newMaterial.material_id
            }
          });

          this.editMode = false
          this.addMaterial = false
        }
      },
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        let domain = '/' + this.$session.get('domain')
        this.$router.push(domain + '/admin/shopping-request');
      },
      fillBaseData(){        
        Company.list().then(({data}) => {
            this.suppliers = data
        });
        UnitsManagement.list().then(({data}) => {
            this.units = data
        });
        Materials.list().then(({data}) => {
          this.materials = data
        })
      },
      submit() {
  
        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }
  
          let data = {...this.fields};

          ExternalRequestAPI[this.method](data, this.fields.id).then(() => {
  
            this.success = true;
  
            this.loading = false;
            
          }).catch(err => {
            this.loading = false;
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = this.$t('globals.error') + " " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })
      }
    },
    computed: {
      
    }
  };
  </script>
  