var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',{staticClass:"mb-12"},[_vm._v(" "+_vm._s(_vm.$t('rendimento_uo.title'))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":_vm.$t('rendimento_uo.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"mt-4 mr-4",attrs:{"color":"success","small":""},on:{"click":_vm.searchField}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1),(_vm.fixedTable.length > 0)?_c('v-card-text',{staticClass:"mb-n12 elevation-0 rounded-xl primary white--text"},[_c('v-data-table',{staticClass:"mt-12 transparent white--text datatable-remove-on-hover",attrs:{"headers":_vm.headers,"items":_vm.fixedTable,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.tableFooter,"options":_vm.options,"hide-default-footer":"","hide-default-header":"","locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(h){return _c('th',{key:h.text,class:h.class},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(h.text))])])}),0)])]}},{key:"item.performance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.performance)+" ")]}},{key:"item.lastMonth.performance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.lastMonth.performance)+" ")]}},{key:"item.atualYear.atualYear",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.atualYear.atualYear)+" ")]}},{key:"item.lastYear.lastYear",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.lastYear.lastYear)+" ")]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('rendimento_uo.your_search'))+" \""+_vm._s(_vm.search)+"\" "+_vm._s(_vm.$t('rendimento_uo.no_found'))+". ")])]},proxy:true},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('Tooltip',{staticClass:"my-4 mr-4",attrs:{"color":"error","text":_vm.$t('rendimento_uo.table_remove'),"top":""}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.deleteFixedItem(item)}}},[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v("mdi-trash-can")])],1)],1)]}}],null,false,651520906)})],1):_vm._e(),_c('v-card-text',[_c('v-data-table',{staticClass:"mt-12",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.tableFooter,"options":_vm.options,"locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.performance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.performance)+" ")]}},{key:"item.lastMonth.performance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.lastMonth.performance)+" ")]}},{key:"item.atualYear.atualYear",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.atualYear.atualYear)+" ")]}},{key:"item.lastYear.lastYear",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.lastYear.lastYear)+" ")]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('rendimento_uo.your_search'))+" \""+_vm._s(_vm.search)+"\" "+_vm._s(_vm.$t('rendimento_uo.no_found'))+". ")])]},proxy:true},{key:"item.options",fn:function(ref){
var item = ref.item;
return [(_vm.toFixVisible(item))?_c('Tooltip',{staticClass:"my-4 mr-4",attrs:{"color":"primary","text":"Fixar pesquisa","top":""}},[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.fixedItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-auto-fix")])],1)],1):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }