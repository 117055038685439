<template>
    <section class="mt-16" >
        <h2 class="text-h6 font-weight-bold ml-4">{{$t('settings.company.detail')}}</h2>
        <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
        ></v-progress-linear>
        <div style="position:relative"  v-if="!loading">
            <v-container>
                <validation-observer ref="form" v-slot="{ invalid }">
                    <v-form @submit.prevent="submit">
                        <v-card flat class="mb-8" elevation="10" :style="$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'" :class="$root.$vuetify.theme.isDark ? 'secondary elevation-0' : ''">
                            <v-card-subtitle class="font-weight-bold">
                                {{$t('settings.company.general')}}
                            </v-card-subtitle>
                            <v-card-text>
                                <v-row class="text-caption">
                                    <v-col cols="12" md="4">
                                        <v-text-field
                                            :label="$t('settings.company.name')"
                                            color="accent"
                                            :disabled="true"
                                            v-model="fields.name"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field
                                            :label="$t('globals.nif')"
                                            color="accent"
                                            :disabled="true"
                                            v-model="fields.nif"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field
                                            :label="$t('globals.email')"
                                            color="accent"
                                            :disabled="true"
                                            v-model="fields.email"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-subtitle class="font-weight-bold">
                                {{$t('globals.address.address')}}
                            </v-card-subtitle>
                            <v-card-text>
                                <v-row class="text-caption">
                                    <v-col cols="12" md="12">
                                        <v-text-field
                                            :disabled="!$root.session.hasPermission(['super', 'company_address_headquarter.update'])"
                                            :label="$t('globals.address.address')"
                                            color="accent"
                                            v-model="fields.address"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="auto">
                                        {{$t('globals.address.postal_code')}}
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-otp-input
                                            :disabled="!$root.session.hasPermission(['super', 'company_address_headquarter.update'])"
                                            plain
                                            v-model="fields.postal_code1"
                                            :length="4"
                                        ></v-otp-input>
                                    </v-col>
                                    <v-col cols="12" md="auto">
                                        <h1>-</h1>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-otp-input
                                            :disabled="!$root.session.hasPermission(['super', 'company_address_headquarter.update'])"
                                            plain
                                            v-model="fields.postal_code2"
                                            :length="3"
                                        ></v-otp-input>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            :disabled="!$root.session.hasPermission(['super', 'company_address_headquarter.update'])"
                                            :label="$t('globals.address.city')"
                                            color="accent"
                                            v-model="fields.city"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            :disabled="!$root.session.hasPermission(['super', 'company_address_headquarter.update'])"
                                            :label="$t('globals.address.country')"
                                            color="accent"
                                            v-model="fields.country"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card-actions class="text-center justify-center">
                            <v-btn elevation="10" :disabled="invalid" depressed color="warning" type="submit">
                                {{$t('globals.save')}}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </validation-observer>
            </v-container>
        </div>
        
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            {{$t('settings.company.success')}}
        </DialogSuccess>
    </section>
</template>
<script>
import Configurations from '@/api/Configurations.js'
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
export default{
    components: {
        DialogSuccess
    },
    data(){
        return{
            loading: false,
            success:false,
            fields:{
                id: null,
                address:'',
                postal_code1: '',
                postal_code2: '',
                postal_code: '',
                city: '',
                country: '',
                nif: null,
                email: null,
                name: null
            }
        }
    },
    mounted(){
        this.loading = true
        this.fillBaseData()
    },
    methods:{
        onSuccess(){
            this.success = false
        },
        async fillBaseData(){
            await Configurations.get().then(({data}) => {
                this.fields.address = data.data.address
                this.fields.id = data.data.id
                this.fields.city = data.data.city
                this.fields.country = data.data.country
                this.fields.nif = data.NIF
                this.fields.email = data.email
                this.fields.name = data.name

                if(data.data.postal_code != null){
                    this.fields.postal_code1 = data.data.postal_code.split('-')[0]
                    this.fields.postal_code2 = data.data.postal_code.split('-')[1]
                }
            })

            this.loading = false
        },
        async submit(){
            this.fields.postal_code = this.fields.postal_code1 + '-' + this.fields.postal_code2
            await Configurations.address(this.fields, this.fields.id).then(() => {

                this.success = true;

                this.loading = false;

                }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                this.$refs.form.setErrors(err.response.data.errors);

                return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

                });
        }
    }
}
</script>