import BaseApi from "./BaseApi";

export default class Downloads extends BaseApi{
    construct() {}
    

    static async get(id) {
        return await Downloads.getAxiosInstance().get(Downloads.getFullApiUrl() + id);
    }

    static async delete(id) {
        return await Downloads.getAxiosInstance().delete(Downloads.getFullApiUrl() + id);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/download/images/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/download/images/";
    }
    
}