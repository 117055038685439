<template>
    <v-row>
        <v-col cols="12">
        <div class="d-flex flex-row align-center">
            <h2 class="secondary--text subtitle-1">
            {{$t('globals.materials')}}
            </h2>
            <v-spacer />
        </div>
        <v-simple-table dense class="d-none d-lg-block">
            <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-center rounded-pill rounded-r-0" style="width: 35%;">
                        {{$t('globals.name')}}
                    </th>
                    <th class="text-center">
                        {{$t('globals.reference')}}
                    </th>
                    <th class="text-center">
                        {{$t('globals.quantity')}}
                    </th>
                    <th class="text-center">
                        {{$t('globals.options')}}
                    </th>
                </tr>
            </thead>
            <tbody :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                <tr v-for="material in template_fields.materials" v-bind:key="material.id">
                <td class="text-center">
                    <validation-provider v-slot="{ errors }" vid="material" :name="$t('globals.material')" rules="required">
                    <v-combobox
                        v-model="material.object"
                        :label="$t('globals.material') + ' *'"
                        :items="materials"
                        :item-text="item => item.reference + ' - ' + item.name"
                        item-value="id"
                        hide-selected
                        :error-messages="errors"
                        @input="material.reference = $event.reference; material.id = $event.id; material.name = $event.name; material.description = $event.description;"
                    >
                    </v-combobox>
                    </validation-provider>
                </td>
                <td class="text-center">
                    {{ material.object == null ? '-' : material.object.reference }}
                </td>
                <td class="text-center">
                    <validation-provider v-slot="{ errors }" vid="quantity" :name="$t('globals.quantity')" rules="required">
                    <v-text-field
                        v-model="material.quantity"
                        :disabled="true"
                        :label="$t('globals.quantity') + ' *'"
                        :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                </td>
                <td class="text-center">
                    <v-text-field
                    v-model="material.reason"
                    :disabled="true"
                    label="Razão"
                    ></v-text-field>
                </td>
                <td class="text-center"> 
                    <v-col cols="12" md="6">
                        <v-btn
                        width="100px"
                        dark
                        small
                        color="secondary"
                        @click="error.show = true"
                        >
                        <small>Duplicar</small>
                        <v-icon small>mdi-plus-circle-multiple-outline</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="6" class="mt-n2">
                        <v-btn
                        width="100px"
                        dark
                        small
                        color="error"
                        @click="removeMaterial(material)"
                        >
                        <small>Eliminar</small>
                        <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                    </v-col>
                </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
        <v-data-iterator
            :items="template_fields.materials"
            item-key="id"
            :items-per-page="-1"
            hide-default-footer
            class="d-block d-lg-none"
        >
            <template v-slot:default="{ items }">
            <v-row>
                <v-col
                v-for="material in items"
                :key="material.id"
                cols="12"
                sm="6"
                md="4"
                lg="3"
                >
                <v-card>
                    <v-list
                    dense
                    >
                    <v-list-item>
                        <v-list-item-content>{{$t('globals.name')}}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <validation-provider v-slot="{ errors }" vid="material" :name="$t('globals.material')" rules="required">
                            <v-combobox
                            v-model="material.object"
                            :label="$t('globals.material') + ' *'"
                            :items="materials"
                            :item-text="item => item.name"
                            item-value="id"
                            hide-selected
                            :error-messages="errors"
                            @input="material.reference = $event.reference; material.id = $event.id; material.name = $event.name"
                            >
                            </v-combobox>
                        </validation-provider>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>{{$t('globals.reference')}}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                            {{ material.object == null ? '-' : material.object.reference }}
                        </v-list-item-content>
                    </v-list-item>                            
                    <v-list-item>
                        <v-list-item-content>{{$t('globals.reason')}}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <v-text-field
                            v-model="material.reason"
                            label="Razão"
                        ></v-text-field>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="true">
                        <v-list-item-content>{{$t('globals.options')}}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <v-row>
                            <v-col cols="12" md="6">
                            <v-btn
                                dark
                                small
                                block
                                color="secondary"
                                @click="error.show = true"
                            >
                                <v-icon small>mdi-plus-circle-multiple-outline</v-icon>
                            </v-btn>
                            </v-col>
                            <v-col cols="12" md="6">
                            <v-btn
                                dark
                                small
                                block
                                color="error"
                                @click="removeMaterial(material)"
                            >
                                <v-icon small>mdi-delete</v-icon>
                            </v-btn>
                            </v-col>
                        </v-row>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list>
                </v-card>
                </v-col>
            </v-row>
            </template>
        </v-data-iterator>
        <v-row justify="center">
            <v-btn
            fab
            dark
            small
            color="primary"
            class="mt-7"
            @click="addMaterial"
            >
            <v-icon small>mdi-plus</v-icon>
            </v-btn>
        </v-row>
    </v-col>
        <ErrorSnackbar v-model="error.show" :message="error.message"/>
        <DialogConfirmation 
            v-if="openConfirmationDialog" 
            title="Remover Item" 
            type="warning" 
            :opened="openConfirmationDialog" 
            @on-submit="submitRemove" 
            @on-cancel="openConfirmationDialog = false">
                {{$t('global_messages.are_you_sure_remove')}}
        </DialogConfirmation>
    </v-row>
</template>
<script>
import dayjs from 'dayjs'
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
import DialogConfirmation from '@/components/ui/DialogConfirmation.vue';

export default {
    components:{
        DialogConfirmation,
        ErrorSnackbar
    },
    props: {
        template_fields: Object,
        materials: Array
    },
    data: () => ({
        openConfirmationDialog: false,
        error:{
            show: false,
            message: 'Ação indisponível em template'
        },
        toSend:{
            id: null
        }
    }),
    mounted(){
        
    },
    methods:{
        addMaterial() {
            this.template_fields.materials.push({
                id: dayjs().unix(),
                name: "",
                reference: "-",
                quantity: 0,
                reason: ''
            });
        },
        removeMaterial(materialObject) {
            this.toSend.id = materialObject.id
            this.openConfirmationDialog = true
        },
        async submitRemove(){
            await this.$emit('remove', this.toSend.id)
            this.openConfirmationDialog = false
        }
    }
}
</script>