import BaseApi from "./BaseApi";

export default class Machine extends BaseApi{
    construct() {}
    
    static async list(params) {
        let url = Machine.getFullApiUrl() + "list";

        return Machine.getAxiosInstance().get(url,{params: params});
    }

    static async search(params) {
        return await Machine.getAxiosInstance().get(Machine.getFullApiUrl(),{params: params});
    }

    static async create(params) {
        return await Machine.getAxiosInstance().post(Machine.getFullApiUrl(),params);
    }

    static async update(params, id) {
        return await Machine.getAxiosInstance().put(Machine.getFullApiUrl() + id,params);
    }

    static async delete(id) {
        return await Machine.getAxiosInstance().delete(Machine.getFullApiUrl() + id);
    }

    static async find(id) {
        return await Machine.getAxiosInstance().get(Machine.getFullApiUrl() + id);
    }

    static async exportEquipments(params) {
        let url = Machine.getFullApiUrl() + "export";

        return Machine.getAxiosInstance().post(url, params);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/machines/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/machines/";
    }
}