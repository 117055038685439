import BaseApi from "./BaseApi";

export default class Costs extends BaseApi{
    construct() {}

    static async calculateCosts(params){
        let url = Costs.getFullApiUrl() + 'calculate';
        
        return Costs.getAxiosInstance().get(url,{params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/costs/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/costs/";
    }
}