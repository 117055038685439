<template>
    <v-container fluid>
        <v-row justify="end">
            <v-col cols="12" md="auto">
                <svg height="25" width="25">
                    <circle cx="25" cy="25" r="40" stroke="black" stroke-width="3" fill="orange" />
                </svg>
            </v-col>
            <v-col cols="12" md="auto">
                {{$t('globals.works_label.waiting')}}
            </v-col>
            <v-col cols="12" md="auto">
                <svg height="25" width="25">
                    <circle cx="25" cy="25" r="40" stroke="black" stroke-width="3" fill="green" />
                </svg>
            </v-col>
            <v-col cols="12" md="auto">
                {{$t('globals.works_label.started')}}
            </v-col>
            <v-col cols="12" md="auto">
                <svg height="25" width="25">
                    <circle cx="25" cy="25" r="40" stroke="black" stroke-width="3" fill="red" />
                </svg>
            </v-col>
            <v-col cols="12" md="auto">
                {{$t('globals.works_label.finished')}}
            </v-col>
        </v-row>
        <GmapMap
          v-if="!loading"
          class="mt-4 elevation-10"
          ref="gmap"
          :center="center"
          :options="getOptions"
          :zoom="zoom"
          map-type-id="roadmap"
          style="width: 100%; height: 500px"
        >

        <GmapMarker
            v-for="m in startedMarkers"
            :key="m.id"
            :icon="getIcon(m.status)"
            :clickable="true"
            :position="getPosition(m.lat, m.lng)"
            @click="openMark(m)"
          />

          <GmapMarker
            v-for="m in waitingMarkers"
            :key="m.id"
            :icon="getIcon(m.status)"
            :clickable="true"
            :position="getPosition(m.lat, m.lng)"
            @click="openMark(m)"
          />

          <GmapMarker
            v-for="m in finishedMarks"
            :key="m.id"
            :icon="getIcon(m.status)"
            :clickable="true"
            :position="getPosition(m.lat, m.lng)"
            @click="openMark(m)"
          />
          
            <gmap-info-window
                :options="{
                maxWidth: 400,
                pixelOffset: { width: 0, height: -35 }
                }"
                :position="infoWindowOpen.position"
                :opened="infoWindowOpen.show"
                @closeclick="infoWindowOpen.show=false"
                >
                <v-container fluid>
                    <v-row>
                        <v-col class="my-0 py-1" cols="12" :md="showItem(item) ? 6 : 12" v-for="(item, index) in infoWindowOpen.msg" :key="item">
                            <div v-if="infoWindowOpen.methereology">
                                <span v-if="showItem(item)" :class="index % 2 != 0 ? 'font-weight-bold' : ''">{{item}}</span>
                                <div v-else>
                                    <v-img style="margin: auto; display: block;" width="50" :src="item" />
                                    <v-divider class="mt-2 mb-2"></v-divider>
                                </div>
                            </div>
                            <div v-else>
                                <span v-if="showItem(item)" :class="index % 2 === 0 ? 'font-weight-bold' : ''">{{item}}</span>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </gmap-info-window>
        
        </GmapMap>
    </v-container>
</template>
<script>
export default{
    name:"MapMarkers",
    props:{
        markers: Array
    },
    data(){
        return{
            infoWindowOpen: {
                show: false,
                msg: [],
                methereology: false,
                position: {
                    lat: null,
                    lng: null
                }
            },
            loading: true,
            zoom: 6,
            center:{
                lat: 40.536605939754,
                lng: -7.8471145229422
            },
        }
    },
    mounted(){
        this.loading = false
    },
    methods:{
        showItem(item){
            //weatherapi
            item = item +''
            if(item.includes('weatherapi'))
                return false
        
        
            return true
        },
        openMark(m){
            this.infoWindowOpen.show = false
            this.infoWindowOpen.msg = [];
            
            this.infoWindowOpen.msg.push('Nome:')
            this.infoWindowOpen.msg.push(m.name)
            this.infoWindowOpen.methereology = false
            
            if(m.methereology != null)
            {                
                this.infoWindowOpen.methereology = true
                
                this.infoWindowOpen.msg = [];
                this.infoWindowOpen.msg.push(m.methereology.day.condition.icon)

                this.infoWindowOpen.msg.push('Nome:')
                this.infoWindowOpen.msg.push(m.name)

                this.infoWindowOpen.msg.push('Max Cº:')
                this.infoWindowOpen.msg.push(m.methereology.day.maxtemp_c)
                this.infoWindowOpen.msg.push('Min Cº:')
                this.infoWindowOpen.msg.push(m.methereology.day.mintemp_c)
                this.infoWindowOpen.msg.push('Previsão:')
                this.infoWindowOpen.msg.push(m.methereology.day.condition.text)
                this.infoWindowOpen.msg.push('Precipitação (mm):')
                this.infoWindowOpen.msg.push(m.methereology.day.totalprecip_mm)
                this.infoWindowOpen.msg.push('Vento (kph):')
                this.infoWindowOpen.msg.push(m.methereology.day.maxwind_kph)    
            }
            
            this.infoWindowOpen.position = {
                lat: parseFloat(m.lat),
                lng: parseFloat(m.lng)
            }
            this.infoWindowOpen.show = true
        },
        getPosition(lat, lng){
            return {
                lat: lat,
                lng: lng
            }
        },
        getIcon(status){
            if(status == 'STARTED'){
                return {
                    path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
                    fillColor: "green",
                    fillOpacity: 0.6,
                    strokeWeight: 0,
                    rotation: 0,
                    scale: 2,
                }
            }

            if(status == 'FINISHED'){
                return {
                    path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
                    fillColor: "red",
                    fillOpacity: 0.6,
                    strokeWeight: 0,
                    rotation: 0,
                    scale: 2,
                }
            }
            
            return {
                path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
                fillColor: "orange",
                fillOpacity: 0.6,
                strokeWeight: 0,
                rotation: 0,
                scale: 2,
            }
        },
    },
    computed:{
        getOptions(){
            let options = {
                clickableIcons: false,
                mapTypeControl: false, 
                streetViewControl: false,
                fullscreenControl: false,
                styles: [
                    {
                    "elementType": "geometry",
                    "stylers": [
                        {
                        "color": "#f5f5f5"
                        }
                    ]
                    },
                    {
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                        "visibility": "off"
                        }
                    ]
                    },
                    {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                        "color": "#616161"
                        }
                    ]
                    },
                    {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                        "color": "#f5f5f5"
                        }
                    ]
                    },
                    {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                        "color": "#bdbdbd"
                        }
                    ]
                    },
                    {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                        "color": "#eeeeee"
                        }
                    ]
                    },
                    {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                        "color": "#757575"
                        }
                    ]
                    },
                    {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                        "color": "#e5e5e5"
                        }
                    ]
                    },
                    {
                    "featureType": "poi.park",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                        "color": "#9e9e9e"
                        }
                    ]
                    },
                    {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [
                        {
                        "color": "#ffffff"
                        }
                    ]
                    },
                    {
                    "featureType": "road.arterial",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                        "color": "#757575"
                        }
                    ]
                    },
                    {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                        {
                        "color": "#dadada"
                        }
                    ]
                    },
                    {
                    "featureType": "road.highway",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                        "color": "#616161"
                        }
                    ]
                    },
                    {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                        "color": "#9e9e9e"
                        }
                    ]
                    },
                    {
                    "featureType": "transit.line",
                    "elementType": "geometry",
                    "stylers": [
                        {
                        "color": "#e5e5e5"
                        }
                    ]
                    },
                    {
                    "featureType": "transit.station",
                    "elementType": "geometry",
                    "stylers": [
                        {
                        "color": "#eeeeee"
                        }
                    ]
                    },
                    {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                        "color": "#c9c9c9"
                        }
                    ]
                    },
                    {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                        "color": "#9e9e9e"
                        }
                    ]
                    }
                ]
                }


            return options
        },
        waitingMarkers(){
            let wm = []

            this.markers.forEach(element => {
                if(element.status == 'WAITING')
                    wm.push(element)
            });

            return wm
        },
        startedMarkers(){
            let wm = []

            this.markers.forEach(element => {
                if(element.status == 'STARTED')
                    wm.push(element)
            });
            

            return wm
        },
        finishedMarks(){
            let wm = []

            this.markers.forEach(element => {
                if(element.status == 'FINISHED')
                    wm.push(element)
            });
            

            return wm
        }
    }
}
</script>