import BaseApi from "./BaseApi";

export default class Budget extends BaseApi{
    construct() {}
    
 
    static async search(params) {
        return await Budget.getAxiosInstance().get(Budget.getFullApiUrl(),{params: params});
    }

    static async create(params) {
        return await Budget.getAxiosInstance().post(Budget.getFullApiUrl(),params);
    }

    static async find(id) {
        return await Budget.getAxiosInstance().get(Budget.getFullApiUrl() + id);
    }

    static async update(params, id) {
        return await Budget.getAxiosInstance().put(Budget.getFullApiUrl() + id,params);
    }

    static async delete(id) {
        return await Budget.getAxiosInstance().delete(Budget.getFullApiUrl() + id);
    }
    
    static async list(params) {
        let url = Budget.getFullApiUrl() + "list";

        return Budget.getAxiosInstance().get(url,{params: params});
    }

    static async accepted(params) {
        let url = Budget.getFullApiUrl() + "accepted";

        return Budget.getAxiosInstance().get(url,{params: params});
    }

    static async addInvoice(params){
        return await Budget.getAxiosInstance().post(Budget.getFullApiUrl() + 'invoices',params);
    }

    static async approveBudget(params, id){
        return await Budget.getAxiosInstance().put(Budget.getFullApiUrl() + "accept/" + id,params);
    }

    
    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/budget/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/budget/";
    }
}
