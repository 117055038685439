<template>
  <v-app>
    
    <div class="bg">
    </div>
    <v-main>
      <v-container fill-height>
        <v-row justify="center" align="center">
          
          <!-- DESKTOP VERSION -->
          <v-col cols="12" class="d-none d-md-block">
            <v-card 
              elevation="0" 
              shaped 
              style="background-color: transparent"
            >
            
            <div class="d-flex justify-center pb-6">
              <v-img
                lazy-src="@/assets/icons/persona dreamdeal tech.png"
                src="@/assets/icons/persona dreamdeal tech.png"
                contain
                max-width="350px"
              ></v-img>
            </div>

            <p class="text-h4 text-center font-weight-black pb-8 mb-8" style="color:#111b2d;">Bem vindo de volta!</p>
            <validation-observer
              ref="login"
              v-slot="{ invalid, dirty }"
            >
              <v-form @submit.prevent="submit">
                <v-row>
                  <v-col
                    cols="12"
                    md="5"
                    offset-md="4"
                  >
                    <validation-provider name="Email\Username" vid="username" rules="required" v-slot="{ errors }">
                      <v-text-field 
                        :class="$root.$vuetify.theme.isDark ? 'center-button' : 'text-white center-button'"
                        color="#111b2d"
                        v-model="username" 
                        label="Email\Username" 
                        prepend-inner-icon="mdi-account" 
                        :error-messages="errors">
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="5"
                    offset-md="4"
                  >
                    <validation-provider name="Password" vid="password" rules="required" v-slot="{ errors }">
                      <v-text-field 
                        :class="$root.$vuetify.theme.isDark ? 'center-button' : 'text-white center-button'"
                        v-model="password" 
                        color="#111b2d"
                        type="password" 
                        label="Password" 
                        prepend-inner-icon="mdi-lock" 
                        :error-messages="errors">
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>                
                  <v-col
                    cols="12"
                    md="5"
                    offset-md="4"
                  >
                    <v-btn
                      text
                      color="white"
                      class="mt-n10 center-button font-weight-thin"
                      type="button"
                      @click="reset_password.opened = !reset_password.opened"
                    >
                      Esqueci-me da password
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="5"
                    offset-md="4"
                  >
                    <v-btn
                      depressed
                      color="#111b2d"
                      class="center-button"
                      style="color: white;"
                      type="submit"
                      :disabled="invalid && !dirty"
                      rounded
                    >
                      Login
                    </v-btn>
                    <v-btn                      
                      class="mt-4 mb-n4 font-weight-bold center-button"                      
                      rounded
                      style="background-color: #ffffff !important; opacity: 0.7;"
                      color="#111b2d"
                      @click="microsoftLogin"
                    >
                      <v-icon>mdi-microsoft</v-icon>
                      {{ $msal.isAuthenticated() ? user.userName : 'Usar conta Microsoft' }}
                    </v-btn>
                  <v-col 
                    cols="12"
                    md="5"
                    offset-md="3"
                    class="mt-4">
                      <v-btn
                        rounded
                        depressed
                        small
                        class="center-button"
                        @click="$msal.signOut()"
                        v-if="$msal.isAuthenticated()"
                      >
                        <v-icon>mdi-logout</v-icon>
                        Logout
                      </v-btn>
                    </v-col> 
                  </v-col>
                </v-row>
              </v-form>
            </validation-observer>            
            </v-card>
          </v-col>          
          <!-- DESKTOP VERSION -->

          <!-- MOBILE VERSION -->
          <v-col cols="12" class="d-md-none">
            <v-card 
              elevation="0" 
              shaped 
              style="background-color: transparent"
            > 
            <v-row>
              <v-col cols="12">
                <div class="d-flex justify-center pb-6">
                  <v-img
                    lazy-src="@/assets/icons/persona dreamdeal tech.png"
                    src="@/assets/icons/persona dreamdeal tech.png"
                    contain
                  ></v-img>
                </div>
              </v-col>
              <v-col cols="12">                
                <p class="text-h4 text-center font-weight-black pb-8 mb-8" style="color:#111b2d;">Bem vindo de volta!</p>
              </v-col>
            </v-row>

              <validation-observer
              ref="login"
              v-slot="{ invalid, dirty }"
            >
              <v-form @submit.prevent="submit">
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <validation-provider name="Email\Username" vid="username" rules="required" v-slot="{ errors }">
                      <v-text-field 
                        :class="$root.$vuetify.theme.isDark ? 'center-button' : 'text-white center-button'"
                        color="#111b2d"
                        v-model="username" 
                        label="Email\Username" 
                        prepend-inner-icon="mdi-account" 
                        :error-messages="errors">
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <validation-provider name="Password" vid="password" rules="required" v-slot="{ errors }">
                      <v-text-field 
                        :class="$root.$vuetify.theme.isDark ? 'center-button' : 'text-white center-button'"
                        v-model="password" 
                        color="#111b2d"
                        type="password" 
                        label="Password" 
                        prepend-inner-icon="mdi-lock" 
                        :error-messages="errors">
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>                
                  <v-col
                    cols="12"
                  >
                    <v-btn
                      text
                      color="white"
                      class="mt-n10 center-button font-weight-thin"
                      type="button"
                      @click="reset_password.opened = !reset_password.opened"
                    >
                      Esqueci-me da password
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-btn
                      depressed
                      color="#111b2d"
                      class="center-button"
                      style="color: white;"
                      type="submit"
                      :disabled="invalid && !dirty"
                      rounded
                    >
                      Login
                    </v-btn>
                    <v-btn                      
                      class="mt-4 mb-n4 font-weight-bold center-button"                      
                      rounded
                      style="background-color: #ffffff !important; opacity: 0.7;"
                      color="#111b2d"
                      @click="microsoftLogin"
                    >
                      <v-icon>mdi-microsoft</v-icon>
                      {{ $msal.isAuthenticated() ? user.userName : 'Usar conta Microsoft' }}
                    </v-btn>
                  <v-col cols="12" md="12">
                      <v-btn
                        block
                        small
                        @click="$msal.signOut()"
                        v-if="$msal.isAuthenticated()"
                      >
                        <v-icon>mdi-logout</v-icon>
                        Logout
                      </v-btn>
                    </v-col> 
                  </v-col>
                </v-row>
              </v-form>
            </validation-observer>      


            </v-card>
          </v-col>
          <!-- MOBILE VERSION -->
        </v-row>
      </v-container>
    </v-main>
      <v-dialog
        v-model="reset_password.opened"
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="h5">Reset Password</span>
          </v-card-title>
          <v-card-text>
            <validation-observer v-if="!reset_password.reseted" ref="reset" v-slot="{ invalid }">
              <v-form @submit.prevent="reset">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <validation-provider v-slot="{ errors }" vid="email" name="Email" rules="required|email">
                        <v-text-field
                          v-model="reset_password.email"
                          prepend-inner-icon="mdi-at"
                          label="Email"
                          type="email"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row class="mt-6" align="center" justify="space-around">
                    <v-col align="center" justify="space-around">
                      <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                        Enviar email
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </validation-observer>
            <v-container v-else>
              <v-row>
                <v-col
                    cols="12"
                    md="6"
                    offset-md="3"
                  >
                  <h2 class="success--text text-center">Email enviado!</h2>
                  <v-row class="mt-6" align="center" justify="space-around">
                    <v-icon
                      large
                      class="text-center"
                      color="success"
                    >
                      mdi-checkbox-marked-circle-outline
                    </v-icon>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="reset_password.opened = false"
            >
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <Cookie :snackbar="showWindowCookie" @cookie-accept="cookieAccept" @cookie-reject="cookieReject" />
      
  </v-app>
</template>
<style>
  .text-white input {
    color: white !important;
  }
  .text-white label {
    color: white !important;
  }
  .text-white i {
    color: white !important;
  }
  .text-white v-messages__wrapper{
    color: white !important;
  }

  .center-button{
    width: 100%;
  }
  .bg {
    background: url( '../assets/login/pexels-photo-3369239.jpeg') no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
  }

  @media (min-width: 600px) {
    .bg {
    
      width: -webkit-fill-available;
      height: -webkit-fill-available;
      position: absolute;
      background: url( '../assets/login/pexels-photo-3369239.jpeg') no-repeat;
      
      background-size: cover;
      /*-webkit-filter: grayscale(100%); 
      filter: grayscale(100%);
      transform: scale(1.1);*/
    }

    .center-button{
      width: 80%;
    }
}
</style>
<script>
import User from "@/api/User.js"
import { msalMixin } from 'vue-msal'; 
import Cookie from "@/components/cookies/Cookie.vue"

export default {
  components: {
    Cookie
  },
  mixins: [msalMixin],
  data: () => ({
    showCookieBar: false,
    showCookie: false,
    username: "",
    password: "",
    reset_password: {
      opened: false,
      email: "",
      reseted: false,
    }
  }),
  beforeCreate(){
    document.title = "Report Lake- Login";

    if(localStorage['auth'] == 1){
      this.$router.push('/admin');
    }
  },
  mounted(){
    //this.$cookies.set('reportlake_portal_cookie', true, '1s')
    let cookieLogin = this.$cookies.get('login-try');
    let cookie = this.$cookies.get('reportlake_portal_cookie');
    
    if(cookie == null)
        this.showCookieBar = true
    
    if(cookieLogin){
      if(this.$msal.isAuthenticated()){
        User.microsoftLogin(this.$msal.data.user.userName, this.$msal.data.user.idToken.oid)
            .then(({data}) => {

                if(data.two_factor){
                  this.$router.push('/2fa');

                  return;
                }

                localStorage['auth'] = 1;

                this.$cookies.remove('login-try');

                this.$router.push('/admin');
            }).catch(err => {

              if(err.response.status == 422) {

                this.$refs.login.setErrors(err.response.data.errors);
                
                return;
              }
          });
        }
    }
  },
  methods:{
    cookieReject(){
      this.$cookies.set('reportlake_portal_cookie', null, '1s');
      this.showCookieBar = false
    },
    cookieAccept(ob){
      this.$cookies.set('reportlake_portal_cookie', JSON.stringify(ob), '7d');
      this.showCookieBar = false
    },
    async microsoftLogin(){
      this.$cookies.set('login-try', true);

      if(this.$msal.isAuthenticated()){
        User.logout(this.$root.session != undefined ? this.$root.session.id : null)
            .then(() => {
            }).catch(() => {
            })
        User.microsoftLogin(this.$msal.data.user.userName, this.$msal.data.user.idToken.oid)
            .then(({data}) => {

              if(data.two_factor){
                this.$router.push('/2fa');

                return;
              }

              localStorage['auth'] = 1;

              this.$cookies.remove('login-try');


              this.$router.push('/admin');
            }).catch(err => {
              
              if(err.response.status == 422) {

                this.$refs.login.setErrors(err.response.data.errors);
                
                return;
              }
          });
        }else{
          this.$msal.signIn()
        }
    },
    submit() {
        this.$refs.login.validate().then((valid) => {
          if (!valid) {
            return;
          }
          
          User.logout(this.$root.session != undefined ? this.$root.session.id : null)
            .then(() => {
              this.login();
            }).catch(() => {
              this.login();
            })
        });
      },
      login(){
        let pwd_enc = this.$encryptValue(this.password);
        User.login(this.username, pwd_enc)
          .then(({data}) => {

              if(data.two_factor){
                this.$router.push('/2fa');

                return;
              }

              localStorage['auth'] = 1;


              this.$router.push('/admin');
          }).catch(err => {

            if(err.response.status == 422) {

              this.$refs.login.setErrors(err.response.data.errors);
              
              return;
            }
        });
      },
      reset () {
        this.$refs.reset.validate().then((valid) => {
          if (!valid) {
            return;
          }

          User.reset(this.reset_password.email)
            .then(() => {

                this.reset_password.reseted = true;
            }).catch(err => {

              if(err.response.status == 422) {

                this.$refs.reset.setErrors(err.response.data.errors);
                
                return;
              }

              this.reset_password.reseted = false;

          });
        });
      },
  },
  computed: {
      showWindowCookie(){
        return this.showCookieBar
      },
      user() {
        let user = null;
        if (this.msal.isAuthenticated) { // Note that the dollar sign ($) is missing from this.msal
          user = {
            ...this.msal.user,
            profile: {}
          }
          if (this.msal.graph && this.msal.graph.profile) {
              user.profile = this.msal.graph.profile
          }
        }
        
        return user;
      }
  }
};
</script>
