var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mt-16"},[(this.$root.$vuetify.theme.isDark)?_c('v-breadcrumbs',{class:this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0',attrs:{"large":"","items":_vm.crumbs,"divider":"/","light":""}}):_c('v-breadcrumbs',{class:this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0',attrs:{"items":_vm.crumbs,"divider":"/","light":""}}),_c('h1',{staticClass:"primary--text"},[_c('rp-icon',{staticClass:"mr-2",attrs:{"icon":"rp-cog-blue-filled"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('general.title')))])],1),(!_vm.loading)?_c('div',{staticStyle:{"position":"relative"}},[_c('v-container',[_c('v-row',{attrs:{"dense":"","justify":"center"}},[(_vm.$root.session.hasPermission(["super", "roles.read"]))?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"grow",attrs:{"color":"primary","outlined":"","height":"125px","dark":"","elevation":"24"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('div',{staticStyle:{"width":"min-content"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('rp-icon',{attrs:{"size":"xlarge","icon":"rp-shield-gray-filled"}})],1)],1)],1)])],1),_c('v-card-subtitle',{staticClass:"mt-n6"},[_c('div',{staticClass:"text-center mb-3"},[_c('span',{staticClass:"text-h6 font-weight-light"},[_vm._v(_vm._s(_vm.$t('menu.roles_management')))])])])],1),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"opacity":0.9,"absolute":"","color":"#036358"}},[_c('div',{staticClass:"text-center"},[_c('p',[_vm._v(_vm._s(_vm.$t('menu.roles_management_text')))]),_c('v-btn',{attrs:{"color":"secondary","to":"/" + _vm.$session.get("domain") + "/admin/accesses/roles"}},[_vm._v(_vm._s(_vm.$t('globals.open')))])],1)]):_vm._e()],1)],1)]}}],null,false,2073187460)})],1):_vm._e(),(_vm.$root.session.hasPermission(["super", "users.read"]))?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"grow",attrs:{"color":"primary","outlined":"","height":"125px","dark":"","elevation":"24"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('div',{staticStyle:{"width":"min-content"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('rp-icon',{attrs:{"size":"xlarge","icon":"rp-colaboradores-gray-filled"}})],1)],1)],1)])],1),_c('v-card-subtitle',{staticClass:"mt-n6"},[_c('div',{staticClass:"text-center mb-3"},[_c('span',{staticClass:"text-h6 font-weight-light"},[_vm._v(_vm._s(_vm.$t('general.users_management')))])])])],1),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"opacity":0.9,"absolute":"","color":"#036358"}},[_c('div',{staticClass:"text-center"},[_c('p',[_vm._v(_vm._s(_vm.$t('general.users_management_text')))]),_c('v-btn',{attrs:{"color":"secondary","to":"/" + _vm.$session.get("domain") + "/admin/accesses/users"}},[_vm._v(_vm._s(_vm.$t('globals.open')))])],1)]):_vm._e()],1)],1)]}}],null,false,2746513038)})],1):_vm._e(),(_vm.$root.session.hasPermission(["super", "warehouses.read"]) && _vm.$root.configurations.hasFeature(["eticadata.config"]))?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"grow",attrs:{"color":"primary","outlined":"","height":"125px","dark":"","elevation":"24"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('div',{staticStyle:{"width":"min-content"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('rp-icon',{attrs:{"size":"xlarge","icon":"rp-colaboradores-gray-filled"}})],1)],1)],1)])],1),_c('v-card-subtitle',{staticClass:"mt-n6"},[_c('div',{staticClass:"text-center mb-3"},[_c('span',{staticClass:"text-h6 font-weight-light"},[_vm._v(_vm._s(_vm.$t('globals.warehouses')))])])])],1),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"opacity":0.9,"absolute":"","color":"#036358"}},[_c('div',{staticClass:"text-center"},[_c('p',[_vm._v(_vm._s(_vm.$t('warehouses.search_description')))]),_c('v-btn',{attrs:{"color":"secondary","to":"/" + _vm.$session.get("domain") + "/admin/settings/warehouses"}},[_vm._v(_vm._s(_vm.$t('globals.open')))])],1)]):_vm._e()],1)],1)]}}],null,false,3718410144)})],1):_vm._e(),(_vm.$root.session.hasPermission(["super", "logs.read"]))?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"grow",attrs:{"color":"primary","outlined":"","height":"125px","dark":"","elevation":"24"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('div',{staticStyle:{"width":"min-content"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('rp-icon',{attrs:{"size":"xlarge","icon":"rp-paperclip-gray-filled"}})],1)],1)],1)])],1),_c('v-card-subtitle',{staticClass:"mt-n6"},[_c('div',{staticClass:"text-center mb-3"},[_c('span',{staticClass:"text-h6 font-weight-light"},[_vm._v(_vm._s(_vm.$t('menu.logs')))])])])],1),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"opacity":0.9,"absolute":"","color":"#036358"}},[_c('div',{staticClass:"text-center"},[_c('p',[_vm._v(_vm._s(_vm.$t('general.logs_text')))]),_c('v-btn',{attrs:{"color":"secondary","to":"/" + _vm.$session.get("domain") + "/admin/logs"}},[_vm._v(_vm._s(_vm.$t('globals.open')))])],1)]):_vm._e()],1)],1)]}}],null,false,60938803)})],1):_vm._e(),(false)?_c('v-col',{staticClass:"d-none d-lg-block",attrs:{"cols":"2"}}):_vm._e(),(_vm.$root.session.hasPermission(["super", "configurations.read"]))?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"grow",attrs:{"color":"primary","outlined":"","height":"125px","dark":"","elevation":"24"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('div',{staticStyle:{"width":"min-content"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('rp-icon',{attrs:{"size":"xlarge","icon":"rp-earth-gray-filled"}})],1)],1)],1)])],1),_c('v-card-subtitle',{staticClass:"mt-n6"},[_c('div',{staticClass:"text-center mb-3"},[_c('span',{staticClass:"text-h6 font-weight-light"},[_vm._v(_vm._s(_vm.$t('general.general_config')))])])])],1),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"opacity":0.9,"absolute":"","color":"#036358"}},[_c('div',{staticClass:"text-center"},[_c('p',[_vm._v(_vm._s(_vm.$t('general.general_company_config')))]),_c('v-btn',{attrs:{"color":"secondary","to":"/" + _vm.$session.get("domain") + "/admin/settings/global_configurations"}},[_vm._v(_vm._s(_vm.$t('globals.open')))])],1)]):_vm._e()],1)],1)]}}],null,false,2863407148)})],1):_vm._e(),(_vm.$root.session.hasPermission(["super", "imports.create"]))?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"grow",attrs:{"color":"primary","outlined":"","height":"125px","dark":"","elevation":"24"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('div',{staticStyle:{"width":"min-content"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('rp-icon',{attrs:{"size":"xlarge","icon":"rp-earth-gray-filled"}})],1)],1)],1)])],1),_c('v-card-subtitle',{staticClass:"mt-n6"},[_c('div',{staticClass:"text-center mb-3"},[_c('span',{staticClass:"text-h6 font-weight-light"},[_vm._v(_vm._s(_vm.$t('menu.imports')))])])])],1),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"opacity":0.9,"absolute":"","color":"#036358"}},[_c('div',{staticClass:"text-center"},[_c('p',[_vm._v(_vm._s(_vm.$t('general.import_data')))]),_c('v-btn',{attrs:{"color":"secondary","to":"/" + _vm.$session.get("domain") + "/admin/settings/import"}},[_vm._v(_vm._s(_vm.$t('globals.open')))])],1)]):_vm._e()],1)],1)]}}],null,false,2824172487)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"grow",attrs:{"color":"primary","outlined":"","height":"125px","dark":"","elevation":"24"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('div',{staticStyle:{"width":"min-content"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-icon',{attrs:{"large":"","center":""}},[_vm._v(" "+_vm._s(_vm.$vuetify.theme.dark ? "mdi-white-balance-sunny" : "mdi-weather-night")+" ")])],1)],1)],1)])],1),_c('v-card-subtitle',{staticClass:"mt-n6"},[_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"text-h6 font-weight-light"},[_vm._v(_vm._s(_vm.$vuetify.theme.dark ? _vm.$t('menu.light_mode') : _vm.$t('menu.dark_mode')))])])])],1),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"opacity":0.9,"absolute":"","color":"#036358"}},[_c('div',{staticClass:"text-center"},[_c('p',[_vm._v(_vm._s(_vm.$t('general.modes')))]),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.toggleTheme}},[_vm._v(_vm._s(_vm.$t('globals.change')))])],1)]):_vm._e()],1)],1)]}}],null,false,2337946158)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"grow",attrs:{"color":"primary","outlined":"","height":"125px","dark":"","elevation":"24"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('div',{staticStyle:{"width":"min-content"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('rp-icon',{attrs:{"size":"xlarge","icon":"rp-permissions-gray-filled"}})],1)],1)],1)])],1),_c('v-card-subtitle',{staticClass:"mt-n6"},[_c('div',{staticClass:"text-center mb-3"},[_c('span',{staticClass:"text-h6 font-weight-light"},[_vm._v(_vm._s(_vm.$t('menu.permissions_list')))])])])],1),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"opacity":0.9,"absolute":"","color":"#036358"}},[_c('div',{staticClass:"text-center"},[_c('p',[_vm._v(_vm._s(_vm.$t('general.permissions_config')))]),_c('v-btn',{attrs:{"color":"secondary","to":"/" + _vm.$session.get("domain") + "/admin/permissions"}},[_vm._v(_vm._s(_vm.$t('globals.open')))])],1)]):_vm._e()],1)],1)]}}],null,false,1270882958)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"grow",attrs:{"color":"primary","outlined":"","height":"125px","dark":"","elevation":"24"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('div',{staticStyle:{"width":"min-content"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('rp-icon',{attrs:{"size":"xlarge","icon":"rp-permissions-gray-filled"}})],1)],1)],1)])],1),_c('v-card-subtitle',{staticClass:"mt-n6"},[_c('div',{staticClass:"text-center mb-3"},[_c('span',{staticClass:"text-h6 font-weight-light"},[_vm._v(_vm._s(_vm.$t('menu.billings')))])])])],1),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"opacity":0.9,"absolute":"","color":"#036358"}},[_c('div',{staticClass:"text-center"},[_c('p',[_vm._v(_vm._s(_vm.$t('menu.billings')))]),_c('v-btn',{attrs:{"color":"secondary","to":"/" + _vm.$session.get("domain") + "/admin/billings"}},[_vm._v(_vm._s(_vm.$t('globals.open')))])],1)]):_vm._e()],1)],1)]}}],null,false,3165254051)})],1),(_vm.$root.session.hasPermission(["super", "eticadata.load"]) && this.$root.configurations.hasFeature(["eticadata.config"]))?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"grow",attrs:{"color":"primary","outlined":"","height":"125px","dark":"","elevation":"24"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('div',{staticStyle:{"width":"min-content"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('rp-icon',{attrs:{"size":"xlarge","icon":"rp-permissions-gray-filled"}})],1)],1)],1)])],1),_c('v-card-subtitle',{staticClass:"mt-n6"},[_c('div',{staticClass:"text-center mb-3"},[_c('span',{staticClass:"text-h6 font-weight-light"},[_vm._v(_vm._s(_vm.$t('menu.sync')))])])])],1),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"opacity":0.9,"absolute":"","color":"#036358"}},[_c('div',{staticClass:"text-center"},[_c('p',[_vm._v(_vm._s(_vm.$t('general.sync')))]),_c('v-btn',{attrs:{"color":"secondary","to":"/" + _vm.$session.get("domain") + "/admin/syncs"}},[_vm._v(_vm._s(_vm.$t('globals.open')))])],1)]):_vm._e()],1)],1)]}}],null,false,1237021098)})],1):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }