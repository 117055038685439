<template>
    <section class="mt-16" v-if="!loading" :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
        <h2 class="text-h6 font-weight-bold ml-4">{{$t('settings.alerts_tab.select')}}</h2>
        <div style="position:relative" :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
            <v-container >
                <validation-observer ref="form" v-slot="{ invalid }">
                    <v-form @submit.prevent="submit">
                        <v-card flat elevation="10" :style="$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'" :class="$root.$vuetify.theme.isDark ? 'secondary elevation-0 mb-8' : 'mb-8'">
                            <v-card-subtitle class="font-weight-bold">
                                {{$t('settings.alerts_tab.diaries_to_do')}}:
                            </v-card-subtitle>
                            <v-card-text>
                                <v-row class="text-caption">
                                    <v-col cols="12" md="2">
                                        <v-checkbox
                                            :label="$t('globals.week_days.monday')" 
                                            color="accent"
                                            v-model="fields.diaries_to_do.monday"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="2">                                                
                                        <v-checkbox
                                            :label="$t('globals.week_days.tuesday')" 
                                            style="color: #9DA6BA;"
                                            color="accent"
                                            v-model="fields.diaries_to_do.tuesday"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="2">                                                
                                        <v-checkbox
                                            :label="$t('globals.week_days.wednesday')" 
                                            style="color: #9DA6BA;"
                                            color="accent"
                                            v-model="fields.diaries_to_do.wednesday"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="2">                                                
                                        <v-checkbox
                                            :label="$t('globals.week_days.thursday')" 
                                            style="color: #9DA6BA;"
                                            color="accent"
                                            v-model="fields.diaries_to_do.thursday"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="4">                                                
                                        <v-checkbox
                                            :label="$t('globals.week_days.friday')" 
                                            style="color: #9DA6BA;"
                                            color="accent"
                                            v-model="fields.diaries_to_do.friday"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="2">                                                
                                        <v-checkbox
                                            :label="$t('globals.week_days.saturday')" 
                                            style="color: #9DA6BA;"
                                            color="accent"
                                            v-model="fields.diaries_to_do.saturday"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="2">                                                
                                        <v-checkbox
                                            :label="$t('globals.week_days.sunday')" 
                                            style="color: #9DA6BA;"
                                            color="accent"
                                            v-model="fields.diaries_to_do.sunday"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-text class="mt-n6">
                                <v-text-field
                                    :label="$t('settings.alerts_tab.delay')"
                                    v-model="fields.diaries_to_do_limit"
                                    type="number"
                                    prepend-inner-icon="mdi-clock"
                                    :hint="$t('settings.alerts_tab.define')"
                                ></v-text-field>
                            </v-card-text>
                        </v-card>
                        <v-card elevation="10" :style="$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'" :class="$root.$vuetify.theme.isDark ? 'secondary elevation-0 mb-8' : 'mb-8'">
                            <v-card-subtitle class="text-body-1 font-weight-bold">
                                {{$t('settings.alerts_tab.diaries_to_approve')}}:
                            </v-card-subtitle>
                            <v-card-text>
                                <v-row class="text-caption">
                                    <v-col cols="12" md="2">
                                        <v-checkbox
                                            :label="$t('globals.week_days.monday')" 
                                            style="color: #9DA6BA;"
                                            color="accent"
                                            v-model="fields.diaries_to_approve.monday"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="2">                                                
                                        <v-checkbox
                                            :label="$t('globals.week_days.tuesday')" 
                                            style="color: #9DA6BA;"
                                            color="accent"
                                            v-model="fields.diaries_to_approve.tuesday"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="2">                                                
                                        <v-checkbox
                                            :label="$t('globals.week_days.wednesday')" 
                                            style="color: #9DA6BA;"
                                            color="accent"
                                            v-model="fields.diaries_to_approve.wednesday"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="2">                                                
                                        <v-checkbox
                                            :label="$t('globals.week_days.thursday')" 
                                            style="color: #9DA6BA;"
                                            color="accent"
                                            v-model="fields.diaries_to_approve.thursday"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="4">                                                
                                        <v-checkbox
                                            :label="$t('globals.week_days.friday')" 
                                            style="color: #9DA6BA;"
                                            color="accent"
                                            v-model="fields.diaries_to_approve.friday"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="2">                                                
                                        <v-checkbox
                                            :label="$t('globals.week_days.saturday')" 
                                            style="color: #9DA6BA;"
                                            color="accent"
                                            v-model="fields.diaries_to_approve.saturday"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="2">                                                
                                        <v-checkbox
                                            :label="$t('globals.week_days.sunday')" 
                                            style="color: #9DA6BA;"
                                            color="accent"
                                            v-model="fields.diaries_to_approve.sunday"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-text>
                                <v-text-field
                                    type="number"
                                    :label="$t('settings.alerts_tab.delay')"
                                    v-model="fields.diaries_to_approve_limit"
                                    prepend-inner-icon="mdi-clock"
                                    :hint="$t('settings.alerts.define_approve')"
                                ></v-text-field>
                            </v-card-text>
                        </v-card>
                        
                        <v-card-actions class="text-center justify-center">
                            <v-btn elevation="10" :disabled="invalid" depressed color="warning" type="submit">
                                {{$t('globals.save')}}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </validation-observer>
            </v-container>
        </div>
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            {{$t('settings.alerts.success')}}
        </DialogSuccess>
    </section>
</template>
<script>
import Configurations from "@/api/Configurations.js";
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default{
    components:{
        DialogSuccess
    },
    data(){
        return{
            loading: true,
            success: false,
            error:{
                title: '',
                message:'',
                show: false,
            },
            fields:{                
                diaries_to_do:{
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false,
                    saturday: false,
                    sunday: false
                },
                diaries_to_approve:{
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false,
                    saturday: false,
                    sunday: false
                }
            }
        }
    },
    async mounted(){
        this.loading = true
        await Configurations.get().then(({data}) => {
          this.fields = data.data
          
          if(this.fields.diaries_to_do == null){
            this.fields.diaries_to_do = {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false
            }
          }
          if(this.fields.diaries_to_approve == null){
            this.fields.diaries_to_approve = {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false
            }
          }
          this.loading = false
        });
    },
    methods:{
        onSuccess(){
            this.success = false
        },
        async submit(){
            await Configurations.update(this.fields, this.fields.id).then(() => {

                this.success = true;

                this.loading = false;
                
                }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        }        
    },
    computed: {
        crumbs: function() {
        return [
            {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
            },
            {
            text: 'Alertas',
            disabled: true,
            to: window.location.href,
            exact: true,
            },
        ];
        }
    }
}
</script>