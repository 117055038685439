import BaseApi from "./BaseApi";

export default class Worker extends BaseApi{
    construct() {}
    
    static async list(params) {
        let url = Worker.getFullApiUrl() + "list";

        return Worker.getAxiosInstance().get(url,{params: params});
    }

    static async search(params) {
        return await Worker.getAxiosInstance().get(Worker.getFullApiUrl(),{params: params});
    }

    static async searchDiaries(params) {
        return await Worker.getAxiosInstance().get(Worker.getFullApiUrl() + 'diaries' ,{params: params});
    }

    static async create(params) {
        return await Worker.getAxiosInstance().post(Worker.getFullApiUrl(),params);
    }

    static async update(params, id) {
        return await Worker.getAxiosInstance().put(Worker.getFullApiUrl() + id,params);
    }

    static async delete(id) {
        return await Worker.getAxiosInstance().delete(Worker.getFullApiUrl() + id);
    }

    static async find(id) {
        return await Worker.getAxiosInstance().get(Worker.getFullApiUrl() + id);
    }

    static async exportReport(params) {
        let url = Worker.getFullApiUrl() + "export";

        return Worker.getAxiosInstance().post(url, params);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/workers/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/workers/";
    }
}