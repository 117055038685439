import BaseApi from "./BaseApi";

export default class ExternalRequest extends BaseApi{
    construct() {}
    
 
    static async search(params) {
        return await ExternalRequest.getAxiosInstance().get(ExternalRequest.getFullApiUrl(),{params: params});
    }

    static async create(params) {
        return await ExternalRequest.getAxiosInstance().post(ExternalRequest.getFullApiUrl(),params);
    }

    static async find(id) {
        return await ExternalRequest.getAxiosInstance().get(ExternalRequest.getFullApiUrl() + id);
    }

    static async export(id, tenant) {
        let url = ExternalRequest.getFullApiUrl(tenant) + 'export/' + id
        
        return await ExternalRequest.getAxiosInstanceDownload(url)
    }

    static async invoice(id, tenant){
        let url = ExternalRequest.getFullApiUrl(tenant) + 'invoice/' + id
        
        return await ExternalRequest.getAxiosInstanceDownload(url)
    }

    static async update(params, id) {
        return await ExternalRequest.getAxiosInstance().post(ExternalRequest.getFullApiUrl() + id,params);
    }

    static async delete(id) {
        return await ExternalRequest.getAxiosInstance().delete(ExternalRequest.getFullApiUrl() + id);
    }
    
    static async list(params) {
        let url = ExternalRequest.getFullApiUrl() + "list";

        return ExternalRequest.getAxiosInstance().get(url,{params: params});
    }
    
    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/external-request/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/external-request/";
    }
}
