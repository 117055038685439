import BaseApi from "./BaseApi";

export default class Role extends BaseApi{
    construct() {}

    static async search(params) {
        return await Role.getAxiosInstance().get(Role.getFullApiUrl(),{params: params});
    }

    static async create(params) {
        return await Role.getAxiosInstance().post(Role.getFullApiUrl(),params);
    }

    static async update(params, id) {
        return await Role.getAxiosInstance().put(Role.getFullApiUrl() + id,params);
    }

    static async delete(id) {
        return await Role.getAxiosInstance().delete(Role.getFullApiUrl() + id);
    }

    static async find(id) {
        return await Role.getAxiosInstance().get(Role.getFullApiUrl() + id);
    }

    static async list(tenant = null) {
        let url = Role.getFullApiUrl(tenant) + "list";

        return Role.getAxiosInstance().get(url);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/roles/";
    }

    static getFullApiUrl(tenant = null){
        let domain = location.pathname.split('/')[1]
        if(tenant != null)
            domain = tenant;
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/roles/";
    }
}