var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:_vm.$root.$vuetify.theme.isDark ? 'secondary' : ''},[_c('v-container',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"mb-16",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.loading)?_c('v-progress-linear',{staticClass:"global-loader",attrs:{"indeterminate":""}}):_vm._e(),_c('fieldset',{attrs:{"disabled":_vm.loading}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"reference","name":_vm.$t('globals.reference'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":_vm.$t('globals.reference') + '*',"clearable":"","error-messages":errors},model:{value:(_vm.fields.reference),callback:function ($$v) {_vm.$set(_vm.fields, "reference", $$v)},expression:"fields.reference"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"vid":"name","name":_vm.$t('globals.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":_vm.$t('globals.name') + '*',"clearable":"","error-messages":errors},model:{value:(_vm.fields.name),callback:function ($$v) {_vm.$set(_vm.fields, "name", $$v)},expression:"fields.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"vid":"description","name":_vm.$t('globals.description'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":_vm.$t('globals.description') + '*',"clearable":"","error-messages":errors},model:{value:(_vm.fields.description),callback:function ($$v) {_vm.$set(_vm.fields, "description", $$v)},expression:"fields.description"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"unit","name":_vm.$t('globals.unit'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.units,"item-value":"id","item-text":function (item) { return item.abbreviation; },"menu-props":"auto","error-messages":errors,"label":_vm.$t('globals.unit') + ' *',"prepend-inner-icon":"mdi-account"},model:{value:(_vm.fields.unit_management_id),callback:function ($$v) {_vm.$set(_vm.fields, "unit_management_id", $$v)},expression:"fields.unit_management_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('Tooltip',{attrs:{"color":"primary","text":_vm.$t('shoppings.add_new_unit'),"bottom":""}},[_c('v-btn',{class:_vm.$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary',attrs:{"fab":"","small":""},on:{"click":function($event){_vm.addUnit = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"cost","name":_vm.$t('globals.cost'),"rules":"|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":true,"prepend-inner-icon":"mdi-account-details","label":_vm.$t('globals.cost'),"clearable":"","error-messages":errors},model:{value:(_vm.fields.cost),callback:function ($$v) {_vm.$set(_vm.fields, "cost", $$v)},expression:"fields.cost"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"stock","name":_vm.$t('globals.stock'),"rules":"|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":true,"prepend-inner-icon":"mdi-account-details","label":_vm.$t('globals.stock'),"clearable":"","error-messages":errors},model:{value:(_vm.fields.stock),callback:function ($$v) {_vm.$set(_vm.fields, "stock", $$v)},expression:"fields.stock"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-6",attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{attrs:{"disabled":invalid,"depressed":"","color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('globals.save'))+" ")])],1)],1)],1),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":_vm.onSuccess}},[_vm._v(" "+_vm._s(_vm.$t('shoppings.material_saved'))+" ")])]}}])})],1),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.addUnit),callback:function ($$v) {_vm.addUnit=$$v},expression:"addUnit"}},[(_vm.addUnit)?_c('AddUnit',{on:{"close":function($event){_vm.addUnit = false},"close-update":function($event){return _vm.fillBaseData()}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }