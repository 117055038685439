import BaseApi from "./BaseApi";

export default class Subempreiteiro extends BaseApi{
    construct() {}

    static async search(params) {
        return await Subempreiteiro.getAxiosInstance().get(Subempreiteiro.getFullApiUrl(),{params: params});
    }

    static async searchWorksCarriedOut(params) {
        return await Subempreiteiro.getAxiosInstance().get(Subempreiteiro.getFullApiUrl() + 'works-carried-out',{params: params});
    }    

    static async create(params) {
        return await Subempreiteiro.getAxiosInstance().post(Subempreiteiro.getFullApiUrl(),params);
    }

    static async update(params, id) {
        return await Subempreiteiro.getAxiosInstance().put(Subempreiteiro.getFullApiUrl() + id,params);
    }

    static async delete(id) {
        return await Subempreiteiro.getAxiosInstance().delete(Subempreiteiro.getFullApiUrl() + id);
    }

    static async find(id) {
        return await Subempreiteiro.getAxiosInstance().get(Subempreiteiro.getFullApiUrl() + id);
    }

    static async list(params, tenant = null) {
        let url = Subempreiteiro.getFullApiUrl(tenant) + "list";

        return Subempreiteiro.getAxiosInstance().get(url,{params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/subempreiteiros/";
    }

    static getFullApiUrl(tenant = null){
        let domain = location.pathname.split('/')[1]
        if(tenant != null)
            domain = tenant
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/subempreiteiros/";
    }
}