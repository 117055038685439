<template>
    <v-form @submit.prevent="submit" v-if="!loading">
        <v-card elevation="0" class="mt-16" :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'" :class="$root.$vuetify.theme.isDark ? 'secondary elevation-0' : ''">
            <v-card-title class="font-weight-bold">{{$t('settings.meals_tab.meals_day')}}</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4">
                        <div class="d-flex mt-4">
                            <p style="color: #9DA6BA !important">{{$t('settings.meals_tab.breakfast')}}:</p>
                            <v-switch
                                class="mt-n1 ml-2"
                                v-model="fields.day_breakfast"
                                inset
                                :label="fields.day_breakfast ? $t('globals.yes') : $t('globals.no')"
                            ></v-switch>
                        </div>
                    </v-col>
                    <v-col cols="12" md="4">
                        <div class="d-flex mt-4">
                            <p style="color: #9DA6BA !important">{{$t('settings.meals_tab.lunch')}}:</p>
                            <v-switch
                                class="mt-n1 ml-2"
                                v-model="fields.day_lunch"
                                inset
                                :label="fields.day_lunch ? $t('globals.yes') : $t('globals.no')"
                            ></v-switch>
                        </div>
                    </v-col>
                    <v-col cols="12" md="4">
                        <div class="d-flex mt-4">
                            <p style="color: #9DA6BA !important">{{$t('settings.meals_tab.dinner')}}:</p>
                            <v-switch
                                class="mt-n1 ml-2"
                                v-model="fields.day_dinner"
                                inset
                                :label="fields.day_dinner ? $t('globals.yes') : $t('globals.no')"
                            ></v-switch>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
       
        
            <v-card-title class="font-weight-bold">{{$t('settings.meals.meals_night')}}</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4">
                        <div class="d-flex mt-4">
                            <p style="color: #9DA6BA !important">{{$t('settings.meals_tab.breakfast')}}:</p>
                            <v-switch
                                class="mt-n1 ml-2"
                                v-model="fields.night_breakfast"
                                inset
                                :label="fields.night_breakfast ? $t('globals.yes') : $t('globals.no')"
                            ></v-switch>
                        </div>
                    </v-col>
                    <v-col cols="12" md="4">
                        <div class="d-flex mt-4">
                            <p style="color: #9DA6BA !important">{{$t('settings.meals_tab.lunch')}}:</p>
                            <v-switch
                                class="mt-n1 ml-2"
                                v-model="fields.night_lunch"
                                inset
                                :label="fields.night_lunch ? $t('globals.yes') : $t('globals.no')"
                            ></v-switch>
                        </div>
                    </v-col>
                    <v-col cols="12" md="4">
                        <div class="d-flex mt-4">
                            <p style="color: #9DA6BA !important">{{$t('settings.meals_tab.dinner')}}:</p>
                            <v-switch
                                class="mt-n1 ml-2"
                                v-model="fields.night_dinner"
                                inset
                                :label="fields.night_dinner ? $t('globals.yes') : $t('globals.no')"
                            ></v-switch>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card-actions class="text-center justify-center">
            <v-btn elevation="10" depressed color="warning" type="submit">
                {{$t('globals.save')}}
            </v-btn>
        </v-card-actions>
        
    <DialogSuccess :opened="success" @on-ok="onSuccess" >
        {{$t('settings.meals.success')}}
    </DialogSuccess>
    </v-form>
</template>
<script>
import Configurations from "@/api/Configurations.js";
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default{
    components:{
        DialogSuccess
    },
    data(){
        return{
            loading: true,
            success: false,
            error:{
                title: '',
                message:'',
                show: false,
            },
            fields:{
                id: null,
                day_breakfast: null,
                day_dinner: null,
                day_lunch: null,
                night_breakfast: null,
                night_dinner: null,
                night_lunch: null,
                work_hours: null,
                extra_50: null,
                extra_75: null,
                extra_100: null,
                diaries_to_approve_limit: null,
                diaries_to_do_limit: null,
                branding: null,
            }
        }
    },
    async mounted(){
        this.loading = true
        await Configurations.get().then(({data}) => {
          this.fields = data.data
          
          this.loading = false
        });
    },
    methods:{
        onSuccess(){
            this.success = false
        },
        async submit(){
            await Configurations.update(this.fields, this.fields.id).then(() => {

                this.success = true;

                this.loading = false;
                
                }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        }    
    },
    computed: {
        crumbs: function() {
        return [
            {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
            },
            {
            text: 'Refeições',
            disabled: true,
            to: window.location.href,
            exact: true,
            },
        ];
        }
    }
}
</script>
<style>
.v-input--switch--inset .v-input--switch__track{
    border: 5px solid #111b2d !important;
}
</style>