<template>
  <v-card elevation="10" :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75);'" :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
    <v-container>
      <validation-observer ref="form" v-slot="{ invalid }">
        <v-form @submit.prevent="submit"  class="mb-16">
          <v-progress-linear v-if="loading"
              indeterminate
              class="global-loader"
            ></v-progress-linear>
          <fieldset :disabled="loading">
            <v-row>
              <v-col cols="12" md="6">
                <v-radio-group class="font-weight-bold" prepend-icon="mdi-compare" :label="$t('my_profile.theme') + ':'" v-model="fields.theme" @change="changeTheme" row>
                  <v-radio color="primary" class="font-weight-light" v-for="theme in $themes" v-bind:key="theme.name" :label="theme.name" :value="theme"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6">
                <v-switch
                  class="d-none d-lg-block"
                  style="float: right;"
                  v-model="fields.is_active"
                  :color="$root.$vuetify.theme.isDark ? 'white' : 'primary'"
                  :true-value="1"
                  :false-value="0"
                  :label="$t('my_profile.active')"
                  hide-details
                  inset
                ></v-switch>
                <v-switch
                  class="d-sm-none"
                  v-model="fields.is_active"
                  :color="$root.$vuetify.theme.isDark ? 'white' : 'primary'"
                  :true-value="1"
                  :false-value="0"
                  :label="$t('my_profile.active')"
                  hide-details
                  inset
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  dense outlined
                  v-model="fields.code"
                  prepend-inner-icon="mdi-barcode"
                  :label="$t('my_profile.id')"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" vid="name" :name="$t('globals.name')" rules="required">
                  <v-text-field 
                    dense outlined
                    v-model="fields.name"
                    prepend-inner-icon="mdi-account"
                    :label="$t('globals.name') + '*'"
                    clearable
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="3">
                  <v-text-field
                    dense outlined
                    v-model="fields.phone_number"
                    prepend-inner-icon="mdi-phone"
                    :label="$t('globals.telephone')"
                    clearable
                  ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" vid="email" :name="$t('globals.email')" rules="required|email">
                  <v-text-field
                    dense outlined
                    v-model="fields.email"
                    prepend-inner-icon="mdi-at"
                    :label="$t('globals.email') + '*'"
                    clearable
                    type="email"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" vid="username" :name="$t('globals.username')" rules="required">
                  <v-text-field
                    v-model="fields.username"
                    dense outlined
                    prepend-inner-icon="mdi-account-details"
                    :label="$t('globals.username') + '*'"
                    clearable
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row v-if="$root.session.id != fields.id">
              <v-col cols="12">
                <validation-provider v-slot="{ errors }" vid="roles" :name="$t('globals.roles')">
                  <multiple-select
                    v-model="fields.roles"
                    :items="roles"
                    dense outlined
                    item-text="display_name"
                    item-value="id"
                    menu-props="auto"
                    :label="$t('globals.roles') + '*'"
                    prepend-inner-icon="mdi-shield-account"
                    :error-messages="errors"
                    @input="changedRoles"
                  ></multiple-select>
                </validation-provider>
              </v-col>
            </v-row>
              <v-row>
              <v-col cols="12" md="3" v-if="$root.session.hasPermission(['super'])">
                  <v-select
                    v-model="fields.lunch_hour"
                    :items="lunch_hours"
                    dense outlined
                    :item-text="item => item.hour"
                    item-value="hour"
                    menu-props="auto"
                    :label="$t('my_profile.lunch_hour')"
                    prepend-inner-icon="mdi-clock"
                    clearable
                  ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <validation-provider v-slot="{ errors }" vid="password" :name="$t('globals.password')">
                  <v-text-field
                    v-model="fields.password"
                    dense outlined
                    prepend-inner-icon="mdi-form-textbox-password"
                    type="password"
                    :label="$t('globals.password')"
                    clearable
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>              
              <v-col cols="12" md="5" v-if="$root.session.hasPermission(['super', 'workers.read'])">
                  <v-autocomplete
                    :items="workers"
                    dense outlined
                    :label="$t('globals.workers')"
                    :item-text="item=> item.code == null ? item.name : item.code + ' - ' + item.name"
                    item-value="id"
                    v-model="fields.worker_id"
                    clearable
                  />
                </v-col>
            </v-row>
            <v-row v-if="roleAdminActive">
              <v-col cols="12">
                <validation-provider v-slot="{ errors }" vid="obras" :name="$t('globals.works')">
                  <multiple-select
                    v-if="roleAdminActive"
                    v-model="fields.getWorksAdmin"
                    :items="obras"
                    item-text="name"
                    item-value="id"
                    menu-props="auto"
                    :label="$t('globals.works') + '*'"
                    prepend-inner-icon="mdi-shield-account"
                    :error-messages="errors"
                  ></multiple-select>
                  <v-select
                    v-else
                    disabled
                    chips
                    :label="$t('globals.works') +  '*'"
                    v-model="fields.getWorksAdmin"
                    :items="obras"
                    item-text="name"
                    item-value="id"
                    multiple
              ></v-select>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <template v-if="$root.session.id == $route.params.id">
                <template v-if="qrcode">
                  <v-col cols="12">
                    <div v-html="qrcode"></div>
                  </v-col>

                  <v-col cols="12" md="4">
                    <validation-provider v-slot="{ errors }" vid="two_fa_code" :name="$t('my_profile.code2fa')" rules="required">
                      <v-text-field
                        v-model="fields.two_fa_code"
                        prepend-inner-icon="mdi-numeric"
                        :label="$t('my_profile.code2fa') + '*'"
                        clearable
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </template>

                <v-col cols="12">
                  <v-switch
                    v-model="fields.has_2fa"
                    color="success"
                    @change="toggle2Fa"
                    label="2FA"
                    hide-details
                  ></v-switch>
                </v-col>
              </template>
            </v-row>
            <v-row no-gutters v-if="isCommissionerOrEnginneer()">
              <v-col cols="12">
                <v-card class="elevation-0">
                  <v-card-title class="primary white--text">
                    {{$t('globals.works')}}
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center" width="90%">
                              {{$t('globals.work')}}
                            </th>
                            <th class="text-end" width="10%">
                              {{$t('globals.options')}}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in fields.works" :key="item.id">
                            <td>
                              <span>{{item.code}} - {{item.name}}</span>
                            </td>
                            <td class="text-end">
                              <IconRemove
                                @on-submit="removeWork(item)"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2" class="text-center">                              
                              <v-btn @click="addWorkWorker = false; addWorkWorker = true;" class="success mt-4 elevation-0" fab>
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="auto">
                <v-switch
                  v-model="fields.notify_emails"
                  inset
                  :label="'Receber alertas no meu email'"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="auto">
                <v-switch
                  v-model="fields.notify_application"
                  inset
                  :label="'Receber alertas na aplicação'"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="auto" v-if="method=='update'">
                <v-btn v-if="fields.worker_id == null" @click="registerWorkerAccount" rounded class="primary mt-3">
                  <v-icon>mdi-account</v-icon>
                  {{$t('my_profile.register_worker_account')}}
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="canJoinAccount">
                  <v-col cols="12">
                    <v-btn
                      v-if="fields.microsoft_identifier == null"
                      class="mt-4"
                      color="#6E7A90"
                      dark
                      @click="microsoftLogin"
                    >
                    <div v-if="!$msal.isAuthenticated()">
                      <v-icon class="mr-2" >mdi-microsoft</v-icon>{{$t('my_profile.register_microsoft')}}
                    </div>
                    <div v-else>
                      <v-icon class="mr-2">mdi-microsoft</v-icon>{{$t('my_profile.register')}} {{$msal.data.user.userName}}
                    </div>
                    </v-btn>
                    <v-btn
                      v-else
                      class="mt-4"
                      disabled
                      outlined
                    >
                      <v-icon class="mr-2">mdi-microsoft</v-icon>{{$t('my_profile.configured_microsoft')}}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row >
                  <v-col cols="12">
                    <v-btn
                      v-if="fields.microsoft_identifier == null"
                      class="mt-4"
                      outlined
                      @click="microsoftLogin"
                    >
                    <div v-if="!$msal.isAuthenticated()">
                      <v-icon class="mr-2" >mdi-microsoft</v-icon>{{$t('my_profile.register_microsoft')}}
                    </div>
                    <div v-else>
                      <v-icon class="mr-2">mdi-microsoft</v-icon>{{$t('my_profile.register')}} {{$msal.data.user.userName}}
                    </div>
                    </v-btn>
                    <v-btn
                      v-else
                      class="mt-4"
                      disabled
                      outlined
                    >
                      <v-icon class="mr-2">mdi-microsoft</v-icon>{{$t('my_profile.configured_microsoft')}}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="mt-n4" v-if="fields.microsoft_identifier == null">                    
                    <p color="primary"><small>{{$t('my_profile.microsoft')}}</small></p>
                  </v-col>
                  
                  <v-col cols="12" md="12" class="mt-n4" v-if="fields.microsoft_identifier == null">
                    <v-btn  
                      outlined
                      small
                      @click="$msal.signOut()"
                      v-if="$msal.isAuthenticated()"
                    >
                      <v-icon>mdi-logout</v-icon>
                      Logout
                    </v-btn>
                  </v-col> 
                </v-row>

            <v-row class="mt-6" align="center" justify="space-around">
              <v-col align="center" justify="space-around">
                <v-btn :disabled="invalid" depressed color="warning" class="mr-5 ml-5 mb-5" type="submit">
                  {{$t('globals.save')}}
                </v-btn>

                <v-btn v-if="!!user && !user.is_verified" depressed color="warning" class="mr-5 ml-5 mb-5" type="button" @click="resendActivationEmail">
                  {{$t('my_profile.email_activation')}}
                </v-btn>

                <v-btn depressed color="warning" class="mr-5 ml-5 mb-5" type="button" @click="reset">
                  {{$t('my_profile.reset')}}
                </v-btn>

                
              </v-col>
            </v-row>
            <v-row v-if="$root.session.email == 'erbifs17@gmail.com' || $root.session.email == 'erbi.silva@dreamdealtech.pt'">
              <v-btn  depressed color="primary" class="mr-5 ml-5 mb-5" type="button" @click="manda">
                Partes diarias por fazer
              </v-btn>
              <v-btn  depressed color="primary" class="mr-5 ml-5 mb-5" type="button" @click="mandaPD">
                  Partes diarias por aprovar
                </v-btn>
            </v-row>
          </fieldset>
        </v-form>
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
          {{$t('my_profile.success')}}
        </DialogSuccess>
        <v-dialog
            v-model="password_reseted"
            max-width="600px"
          >
          <v-card>
            <v-card-title>
              <span class="h5">{{$t('my_profile.reset')}}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      md="6"
                      offset-md="3"
                    >
                    <h2 class="success--text text-center">{{$t('my_profile.email_sent')}}!</h2>
                    <v-row class="mt-6" align="center" justify="space-around">
                      <v-icon
                        large
                        class="text-center"
                        color="success"
                      >
                        mdi-checkbox-marked-circle-outline
                      </v-icon>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="password_reseted = false"
              >
              {{$t('globals.close')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

          <v-dialog
            v-model="activation_email_resent"
            max-width="600px"
          >
            <v-card>
              <v-card-title>
                <span class="h5">{{$t('my_profile.email_for_activation')}}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                        cols="12"
                        md="6"
                        offset-md="3"
                      >
                      <h2 class="success--text text-center">{{$t('my_profile.email_sent')}}!</h2>
                      <v-row class="mt-6" align="center" justify="space-around">
                        <v-icon
                          large
                          class="text-center"
                          color="success"
                        >
                          mdi-checkbox-marked-circle-outline
                        </v-icon>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="activation_email_resent = false"
                >
                {{$t('globals.close')}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="addWorkWorker"
            width="50%"
          >
            <v-card class="elevation-0 ma-0 pa-0" style="overflow: hidden;">
              <v-card-title class="primary white--text">
                {{$t('work.add_new')}}
              </v-card-title>
              <v-card-text>
                <v-row class="mt-4">
                  <v-col cols="12" md="12">
                    <v-autocomplete
                      :items="getObrasWorker"
                      item-value="id"
                      label="Obra"
                      v-model="choosed_work"
                      dense outlined
                      :item-text="item => item.code + ' - ' + item.name"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col cols="12" align="center" justify="center">
                    <v-btn @click="addWorkToWorkerFunction" class="success">
                      {{$t('globals.add')}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        <ErrorSnackbar v-model="error.show" :message="error.message"/>
      </validation-observer>
    </v-container>
  </v-card>
</template>
<script>
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
import Emails from "@/api/Emails.js";
import LunchHour from "@/api/LunchHour.js";
import User from "@/api/User.js";
import ClientUsers from "@/api/ClientUsers.js";
import Role from "@/api/Role.js";
import Worker from "@/api/Worker.js"
import Work from "@/api/Work.js";
import MultipleSelect from '@/components/ui/MultipleSelect.vue';
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import IconRemove from '@/components/ui/IconRemove.vue';
import { msalMixin } from 'vue-msal'; 

export default {
  components: {
    MultipleSelect,
    DialogSuccess,
    IconRemove,
    ErrorSnackbar
  },
  mixins: [msalMixin],
  props: {
   user: Object,
   method: String,
   disabled: Boolean,
 },
 watch: { 
  '$route.params.id'() {
    let parent = this
    ClientUsers.find(this.$route.params.id)
      .then((r) => {
        parent.fields = r.data
      })
    },
    user: {
      immediate: true, 
      handler (val) {
        this.fields.theme = this.$themes[0];

        if(!val) {
          return;
        }

        let data = {...val};

        data.roles = data.roles.map((item) => { return item.id});

        this.fields = data;

        this.fields.id = data.id;

        this.fields.works = data.works

        this.fields.theme = this.$themes.find((item) => data.theme == null ? false : item.name == data.theme.name)

        if(this.fields.theme == undefined || this.fields.theme.length == 0){
          this.fields.theme = this.$themes[0];
        }
      }
    },
  },
  beforeCreate(){
  },
  data: () => ({
    microsoftRegister: false,
    addWorkWorker: false,
    lunch_hours: [],
    choosed_work: null,
    workers: [],
    fields: {
      id: null,
      name: null,
      username:null,
      email:null,
      phone_number:null,
      is_active:0,
      has_2fa: null,
      two_fa_code: null,
      roles:[],
      theme: null,
      password: null,
      lunch_hour: null,
      getWorksAdmin: [],
      notify_application: false,
      notify_emails: false,
      worker_id: null,
      works:[]
    },
    qrcode:null,
    loading:false,
    error: {
      title: '',
      message: '',
    },
    roles: [],
    obras: [],
    password_reseted:false,
    activation_email_resent:false,
    success: false,
    roleAdminActive: false
  }),
  async mounted(){
    
    if(this.$route.params.data != undefined){
      let data = JSON.parse(this.$route.params.data)
      
      this.fields.name = data.name
      this.fields.email = data.email
      this.fields.phone_number = data.phone
      this.fields.code = data.code
      this.fields.worker_id = data.worker_id
    }

    await this.fillBaseData();

    if(this.fields.id == this.$root.session.id)
      this.microsoftRegister = true

    
    let compareString = 'administrativo'

    this.roles.forEach(element => {
      this.fields.roles.forEach(roleElement => {
        if(element.id == roleElement)
          if(element.name.toUpperCase() == compareString.toUpperCase())
            this.roleAdminActive = true   
      });

    });

    Worker.list().then(({data}) =>  {
      this.workers = data
    })

  },
  methods:{
    isCommissionerOrEnginneer(){
      if(this.fields == null) return
      if(this.fields.roles == null) return
      if(this.roles == null) return

      let isIt = false
      this.roles.forEach(element => {
        this.fields.roles.forEach(r => {
          if(element.id == r){
            if(element.name == 'encarregado')
              isIt = true
            if(element.name == 'dto')
              isIt = true
          }
        });
      });
      return isIt
    },
    addWorkToWorkerFunction(){
      this.obras.forEach(element => {
        if(this.choosed_work == element.id){
          this.fields.works.push({
            id: element.id,
            work_id: element.id,
            code: element.code,
            name: element.name
          })
        }
      });
    },
    removeWork(w){
      let pos = -1
      let i = 0
      this.fields.works.forEach(element => {
        if(element.work_id == w.work_id){
          pos = i
        }
        i++
      });

      if(pos != -1){
        this.fields.works.splice(pos, 1)
      }
    },
    registerWorkerAccount(){
      let data = {
        name: this.fields.name,
        email: this.fields.email,
        phone: this.fields.phone_number,
        code: this.fields.code,
        user_id: this.fields.id
      }
      
      this.$router.push('/' + this.$session.get('domain') + '/admin/workers-settings/workers/create/' + JSON.stringify(data))
    },
    changedRoles(r){
      let compareString = 'administrativo'
      this.roleAdminActive = false
      this.roles.forEach(element => {

        r.forEach(roleElement => {
          if(element.id == roleElement)
            if(element.name.toUpperCase() == compareString.toUpperCase())
              this.roleAdminActive = true   
        });

      });
    },
    microsoftLogin(){
      if(this.$msal.isAuthenticated()){
        if(this.$msal.data.user.userName == this.$root.session.email){
          User.registerExternalLogin(this.$msal.data.user.userName, this.$msal.data.user.idToken.oid, 'microsoft', this.$root.session.id)
            .then(() => {

              this.$cookies.remove('addMicrosoftAccount');
              window.location.reload()
            }).catch(err => {

              if(err.response.status == 422) {

                this.$refs.login.setErrors(err.response.data.errors);
                
                return;
              }
          });
        }
      }else this.$msal.signIn()
    },
    mandaPD(){
      Emails.diariesToApprove().then(() => {
        
      });
    },
    manda(){
      Emails.diariesToDo().then(() => {
        
      });
    },
    onSuccess() {
      if(this.fields.id != null) {
        this.success = false
        return
      }
      let domain = '/' + this.$session.get('domain')
      this.$router.push(domain + '/admin/accesses/users');
    },
    changeTheme(theme){
      this.setTheme(theme)

      this.fields.theme = theme;
    },
    async fillBaseData(){
      await Role.list(this.$route.params.id == undefined ? this.$session.get('domain') : null).then(({data}) => {
        this.roles = data;
      });

      Work.list(null, this.$route.params.id == undefined ? this.$session.get('domain') : null).then(({data}) => {
          this.obras = data;
      });

      LunchHour.list(this.$route.params.id == undefined ? this.$session.get('domain') : null).then(({data}) => {
        this.lunch_hours = data;
      });
    },
    toggle2Fa()
    {
      if(this.fields.has_2fa){
          User.enable2FA()
          .then(() => {
            this.render2Fa()
          });

          return;
      }
      
      User.disable2FA()
          .then(() => {
            this.qrcode = null;
          });
    },
    render2Fa(){
      User.get2FA()
        .then(({data}) => {
          this.qrcode = data.svg;
        });
    },
    reset () {
      User.reset(this.user.email)
          .then(() => {

              this.password_reseted = true;
          });
    },
    resendActivationEmail() {
      ClientUsers.resendActivationEmail(this.user.id)
        .then(() => {

            this.activation_email_resent = true;
        });
    },
    submit() {

      this.$refs.form.validate().then((result) => {
        this.loading = true;
        
        if(!result){
          this.loading = false;
          return;
        }

        if(this.fields.password != undefined)
          this.fields.password = this.$encryptValue(this.fields.password)

        ClientUsers[this.method](this.fields, this.$route.params.id == undefined ? this.$session.get('domain') : null).then(({data}) => {

          this.success = true;

          this.loading = false;

          if(data.id == this.$root.session.id){
            let user = User.getInstance(data);

            this.$root.session = user;

            return;
          }
          this.fields.password = ''
          this.setTheme(this.$root.session.theme);
          
        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);

              if(err.response.data.errors.code != undefined){
                this.error.message = 'Código de utilizador inválido'
                this.error.show = true
              }
                            
              return;
            }

            this.error.title = this.$t('globals.error') + " " + err.response.status;

            this.error.message = err.response.data.message;

          });
      })
    }
  },
  computed: {
    getObrasWorker(){
      let aux = []

      this.obras.forEach(obra_1 => {
        let flag = false
        this.fields.works.forEach(element => {
          if(obra_1.id == element.work_id)
            flag = true
        });
        if(flag == false)
          aux.push(obra_1)
      });
      return aux
    },
    getWorkChooseRole(){
      return this.roleAdminActive
    },
    canJoinAccount(){
      if(Object.keys(this.$route.params).length == 0)
        return true

      return false
    }
  }
};
</script>
