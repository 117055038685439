<template>
  <section class="mt-16">
    <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-bulldozer</v-icon> <span>{{$t('machines.crumb_add')}}</span>
      </h1>

      <div class="mt-16">
        <machine method="create"></machine>
      </div>
  </section>
</template>
<script>
import Machine from '@/components/machines/Machine'

export default {
  components: {
    Machine
  },
  beforeCreate(){
    document.title = "Report Lake - " + this.$t('machines.crumb_add');
  },
  data: () => ({
    
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "machines.create"])) {
      this.$router.push('/admin');
    }

  },
  methods:{
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: this.$t('menu.dashboard'),
          disabled: false,
          to: '/' + this.$session.get('domain') + '/admin',
          exact: true,
        },
        {
          text: this.$t('machines.crumb_management'),
          disabled: false,
          to: '/' + this.$session.get('domain') + '/admin/works-settings/machines',
          exact: true,
        },
        {
          text: this.$t('machines.crumb_add'),
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
