import BaseApi from "./BaseApi";

export default class Holiday extends BaseApi{
    construct() {}
    
    static async list(params) {
        let url = Holiday.getFullApiUrl() + "list";

        return Holiday.getAxiosInstance().get(url,{params: params});
    }

    static async search(params) {
        return await Holiday.getAxiosInstance().get(Holiday.getFullApiUrl(),{params: params});
    }

    static async create(params) {
        return await Holiday.getAxiosInstance().post(Holiday.getFullApiUrl(),params);
    }

    static async update(params, id) {
        return await Holiday.getAxiosInstance().put(Holiday.getFullApiUrl() + id,params);
    }

    static async delete(id) {
        return await Holiday.getAxiosInstance().delete(Holiday.getFullApiUrl() + id);
    }

    static async find(id) {
        return await Holiday.getAxiosInstance().get(Holiday.getFullApiUrl() + id);
    }

    static async exists(params) {
        let url = Holiday.getFullApiUrl() + "exists";

        return await Holiday.getAxiosInstance().get(url,{params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/holidays/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/holidays/";
    }
}