<template>
    <v-container fluid>
        <v-row justify="end">
            <v-col cols="12" align="end">
                <v-btn small @click="addChapterDialog = true" class="primary white--text">
                    <v-icon>mdi-plus</v-icon>{{$t('work.add_chapter')}}
                </v-btn>
            </v-col>
        </v-row>
        <v-card class="elevation-1 mt-4" v-for="(element, index) in fields.chapters" v-bind:key="index + 10000">
            <v-card-title class="primary white--text">
                {{element.description}}
                <v-btn x-small class="ml-4 warning white--text" @click="addClassDialog = true; selectedChapter = element; classes_array = element.chapter_classes">
                    <v-icon>mdi-plus</v-icon>{{$t('work.add_class')}}
                </v-btn>
                <IconRemove
                    v-if="$root.session.hasPermission(['super']) || $root.session.hasRole(['ceo', 'dto'])"
                    class="mr-2"
                    @on-submit="deleteChapter(element)"                                      
                />
            </v-card-title>
            <v-divider></v-divider>
            <v-card v-for="(c, index_chapter) in element.classes" v-bind:key="index_chapter + 2000000" class="elevation-0">
                <v-card-subtitle class="font-weight-bold">
                    <v-icon color="primary">mdi-arrow-right-bold-circle</v-icon><span class="ml-4">{{c.description}}</span>
                    <IconRemove
                        v-if="$root.session.hasPermission(['super']) || $root.session.hasRole(['ceo', 'dto'])"
                        class="mr-2"
                        @on-submit="deleteClasse(element, c)"                                      
                    />
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-card-text>
                    <v-simple-table dense :class="($root.$vuetify.theme.isDark ? 'secondary' : '') + ' d-none d-lg-block'">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-center">
                                    {{$t('globals.work_units_tables.code')}}
                                </th>
                                <th class="text-center">
                                    {{$t('globals.work_units_tables.name')}}
                                </th>
                                <th class="text-center">
                                    {{$t('globals.work_units_tables.budgeted')}}
                                </th>
                                <th class="text-center">
                                    {{$t('globals.work_units_tables.unit')}}
                                </th>
                                <th class="text-center">
                                    {{$t('globals.work_units_tables.income')}}
                                </th>
                                <th class="text-center">
                                    {{$t('globals.work_units_tables.team')}}
                                </th>
                                <th class="text-center">
                                    {{$t('globals.work_units_tables.start')}}
                                </th>
                                <th class="text-center">
                                    {{$t('globals.work_units_tables.options')}}
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(un, index_unit) in c.units" v-bind:key="index_unit + 3000000">
                                <td class="text-center">
                                    <label>{{un.code}}</label>
                                </td>
                                <td class="text-center">
                                    <label>{{un.unit}}</label>
                                </td>
                                <td class="text-center">
                                    <label>{{un.qty_budgeted}}</label>
                                </td>
                                <td class="text-center">
                                    <label>{{un.unit_measure}}</label>
                                </td>
                                <td class="text-center">
                                    <label>{{un.budgeted_income}}</label>
                                </td>
                                <td class="text-center">
                                    <label>{{un.team}}</label>
                                </td>
                                <td class="text-center text-no-wrap">
                                    <label>{{un.start_date}}</label>
                                </td>
                                <td class="text-center">
                                    <v-row>
                                    <v-col cols="12" md="3">

                                        <IconView
                                            @click="viewListWorkUnit(un, element, c)"                                      
                                        />
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <IconRemove
                                        v-if="$root.session.hasPermission(['super']) || $root.session.hasRole(['ceo', 'dto'])"
                                        class="mr-2"
                                        @on-submit="deleteWorkUnitList(un, element, c)"                                      
                                        />

                                    </v-col>
                                    </v-row>
                                </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions>
                    <v-row justify="center"> 
                        <v-col cols="12" md="auto">                   
                            <v-btn small class="success" fab @click="openUnitDialog(c, element)">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-card>


    <v-dialog
        v-model="addChapterDialog"
        persistente
        max-width="800px"
    >
        <v-card>
            <v-card-title class="primary white--text">
                {{$t('chapter.crumb_add')}}
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="newChapter.id"
                            :label="$t('chapter.chapter')"
                            :items="chapters"
                            :item-text="item => item.description"
                            item-value="id"
                            hide-selected
                            clearable
                            >
                        </v-autocomplete>     
                    </v-col>
                    <v-col cols="12" align="center">
                        <v-btn class="success" @click="addChapter">{{$t('chapter.crumb_add')}}</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog
        v-model="addClassDialog"
        persistente
        max-width="800px"
    >
        <v-card>
            <v-card-title class="primary white--text">
                {{$t('chapter_class.crumb_add')}}
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="newClass.id"
                            :label="$t('menu.chapter_class')"
                            :items="classes_array"
                            :item-text="item => item.description"
                            item-value="id"
                            hide-selected
                            clearable
                            >
                        </v-autocomplete>     
                    </v-col>
                    <v-col cols="12" align="center">
                        <v-btn class="success" @click="addClass">{{$t('chapter_class.crumb_add')}}</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>



    <v-dialog 
        v-model="addUnitDialog"
        persistente
        max-width="800px"
    >
        <v-card>
            <v-card-title class="primary white--text">
                    {{$t('work.add_work_unit')}}
            </v-card-title>
            <v-card-text>
                <v-row class="mt-4">
                    <v-col cols="12">                    
                        <v-autocomplete
                            v-model="newUnit.id"
                            :label="$t('globals.work_unit') + '*'"
                            :items="units_array"
                            :item-text="item => item.code + ' - ' + item.name"
                            item-value="unit_id"
                            hide-selected
                            clearable
                            @change="workUnitChanged"
                            >
                        </v-autocomplete> 
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox
                            v-model="chooseWorkUnitDate"
                            :disabled="!canChooseWorkUnitStart"
                            :label="!canChooseWorkUnitStart ? $t('work.uo_starting_point_msg1') : $t('work.uo_starting_point_msg2')"
                        ></v-checkbox>
                    </v-col> 
                    <v-col cols="12">
                        <v-menu
                            v-model="pickerStartDate"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <validation-provider v-slot="{ errors }" vid="Data" :name="$t('work.preview_start')" rules="|">
                                <v-text-field
                                    v-model="newUnit.start_date"
                                    :disabled="chooseWorkUnitDate"
                                    :label="$t('work.preview_start')"
                                    :error-messages="errors"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </validation-provider>
                            </template>
                            <v-date-picker
                                v-model="newUnit.start_date"
                                @input="pickerStartDate = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>   
                    <v-col cols="12" v-if="chooseWorkUnitDate">                    
                        <v-autocomplete
                            v-model="newUnit.work_unit_id"
                            :label="$t('work.start_after')"
                            :items="computedChoosingWorkUnit"
                            :item-text="item => item.unit"
                            item-value="id_work_unit"
                            hide-selected
                            clearable
                            @change="changedWorkUnitListDate"
                            >
                        </v-autocomplete>     
                    </v-col>
                    <v-col cols="12" md="4">  
                            <v-autocomplete                                              
                                v-model="newUnit.id"
                                :label="$t('globals.code') + '*'"
                                :items="units_array"
                                :item-text="item => item.code"
                                item-value="id"
                                hide-selected
                                clearable
                                >
                        </v-autocomplete>     
                    </v-col>
                    
                    <v-col cols="12" md="4">
                        <v-text-field
                            v-model="newUnit.unit_cost"
                            type="number"
                            :label="$t('globals.cost') + '(' + $store.state.coin + ')'"
                            >
                        </v-text-field>   
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-autocomplete
                            v-model="newUnit.id"
                            :label="$t('globals.unit')"
                            :items="units_array"
                            :disabled="true"
                            :item-text="item => item.unit_measure"
                            item-value="id"
                            hide-selected
                            clearable
                            >
                        </v-autocomplete>   
                    </v-col>
                    <v-divider class="mt-4 mb-4"></v-divider>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                            <v-text-field
                                v-model="newUnit.qty_budgeted"
                                prepend-inner-icon="mdi-form-textbox"
                                :label="$t('globals.work_units_tables.budgeted_full')"
                                clearable
                            ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                            <v-text-field
                                v-model="newUnit.budgeted_income"
                                small
                                prepend-inner-icon="mdi-google-circles-communities"
                                :label="$t('globals.work_units_tables.income_full')"
                                clearable
                            >
                                
                            <template v-slot:append>        
                                <v-icon>{{ newUnit.unit_measure }}</v-icon> 
                            </template>
                            </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                            <v-text-field
                                v-model="newUnit.team"
                                prepend-inner-icon="mdi-counter"
                                :label="$t('globals.team')"
                                clearable
                            ></v-text-field>
                    </v-col>                            
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="success" @click="addUnit">{{$t('globals.save')}}</v-btn>
            </v-card-actions>

<DialogSuccess :opened="success" @on-ok="onSuccess" >
    {{$t('work_units.work_unit_success')}}
</DialogSuccess>
        </v-card>
    </v-dialog>

    </v-container>
</template>
<script>
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

import ChapterAPI from '@/api/Chapter.js'
import ChapterClassAPI from '@/api/ChapterClass.js'

export default{
    name:"WorkUnits",
    props: ['fields'],
    components:{
        IconRemove,
        IconView,
        DialogSuccess
    },
    data(){
        return{            
            success:false,
            showWorkList: false,
            chooseWorkUnitDate: false,
            addChapterDialog: false,
            addUnitDialog: false,
            addClassDialog: false,
            pickerStartDate: false,
            selectedChapter: null,
            selectedClass: null,
            selectedUnit: null,
            chapter_class_objects: [],
            chapters: [],
            units:[],
            classes_array: [],
            units_array: [],
            newChapter:{
                id: null
            },
            newClass:{
                id: null,
            },
            newUnit:{
                id: null,
                start_date: null,
                unit_cost: null
            },
            newWorkUnit:{
                id: null,
                unit_id: null,
                start_date: null,
                code: null,
                team: null,
                unit: null,
                qty_budgeted: null,
                budgeted_income: null,
                edit: false,
                work_unit_id: null,
                unit_cost: null
            },
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{        
        onSuccess(){
            this.newWorkUnit = {
                id: null,
                unit_id: null,
                start_date: null,
                code: null,
                team: null,
                unit: null,
                qty_budgeted: null,
                budgeted_income: null,
                edit: false,
                work_unit_id: null,
                unit_cost: null
            }
            this.success = false
            this.addUnitDialog = false; 
        },
        workUnitChanged(id){
            let cost = 0
            this.units_array.forEach(element => {
                if(element.id == id)
                    cost = element.value
            });
            this.newUnit.unit_cost = cost
        },
        deleteChapter(chapter){
            let i = 0;
            let pos = -1
            this.fields.chapters.forEach(element => {
                if(element.id == chapter.id){
                    pos = i
                }
                i++
            });
            if(pos != -1)
                this.fields.chapters.splice(pos, 1)
        },
        deleteClasse(chapter, class_item){
            this.fields.chapters.forEach(element => {
                if(element.id == chapter.id){
                    let i = 0;
                    let pos = -1
                    chapter.classes.forEach(cl_classes => {
                        if(cl_classes.id == class_item.id){
                            pos = i
                        }
                        i++
                    });
                    if(pos != -1)
                        chapter.classes.splice(pos, 1)
                }
            });
        },
        openUnitDialog(c, element){
            this.selectedChapter = element; 
            this.selectedClass = c; 
            this.units_array = c.units_array
            if(this.units_array == undefined){
                this.chapter_class_objects.forEach(cClass => {
                    if(cClass.id == c.id)
                        this.units_array = cClass.units
                });
            }
            
            this.addUnitDialog = true; 
        },
        changedWorkUnitListDate(v){
            this.fields.chapters.forEach(chap => {
                chap.classes.forEach(chap_class => {
                    chap_class.units.forEach(element => {
                        
                        if(element.id == v){
                            let total_dias = Math.round(element.qty_budgeted / element.budgeted_income)

                            var days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
                            let from = new Date(element.start_date) 
                            let sem_trabalho = 0;
                            for(let i = from; total_dias >= 0; i.setDate(i.getDate() + 1)){                
                                if(this.fields.work_days[days[i.getDay()]]){
                                    total_dias--
                                }else{
                                    sem_trabalho++
                                }                
                            }
                            
                            total_dias = Math.round(element.qty_budgeted / element.budgeted_income)
                            

                            let dias_necessarios = sem_trabalho + total_dias

                            let dt = new Date(element.start_date)
                            dt.setDate(dt.getDate() + dias_necessarios)
                            this.newUnit.start_date = dt.getFullYear() + '-' + (dt.getMonth()+1) + '-' + dt.getDate()
                        }
                    });
                });
            });
        },
        addUnit(){
            let unidade = null
            this.units_array.forEach(element => {
                if(element.unit_id == this.newUnit.id){
                    unidade = {}
                    unidade.id = element.unit_id
                    unidade.code = element.code
                    unidade.unit = element.name
                    unidade.unit_measure = element.unit
                    unidade.qty_budgeted = this.newUnit.qty_budgeted
                    unidade.budgeted_income = this.newUnit.budgeted_income
                    unidade.team = this.newUnit.team
                    unidade.start_date = this.newUnit.start_date
                    unidade.work_unit_id = this.newUnit.work_unit_id
                    unidade.unit_cost = element.value
                    unidade.unit_measure = element.unit_measure
                }
            });

            if(this.selectedUnit != null){
                this.selectedUnit = this.newUnit
                this.success = true
                return
            }


            this.fields.chapters.forEach(element => {
                if(element.id == this.selectedChapter.id){
                    element.classes.forEach(cl_element => {
                        if(cl_element.id == this.selectedClass.id){
                            cl_element.units.push(unidade)
                        }
                    });
                }
            });
            
            this.success = true
        },
        addClass(){
            let cl = null
            this.chapter_class_objects.forEach(element => {
                if(element.id == this.newClass.id){
                    cl = element
                    cl.units_array = cl.units
                    cl.units = []
                }
            });
            
            this.fields.chapters.forEach(element => {
                if(element.id == this.selectedChapter.id){
                    element.classes.push(cl)
                }
            });
        },
        addChapter(){
            this.chapters.forEach(element => {
                if(element.id == this.newChapter.id)
                    this.fields.chapters.push({
                        id: element.id,
                        description: element.description,
                        classes: [],
                        chapter_classes: element.chapter_classes
                    })
            });
        },
        fillBaseData(){
            ChapterAPI.list().then(({data}) => {
                this.chapters = data
            });
            ChapterClassAPI.list().then(({data}) => {
                this.chapter_class_objects = data
            });
        },
        viewListWorkUnit(item, chapter, class_item){
            if(class_item.units_array == undefined){
                this.chapter_class_objects.forEach(element => {
                    if(element.id == class_item.id)
                        this.units_array = element.units
                });
            }else this.units_array = class_item.units_array
            

            this.selectedChapter = chapter
            this.selectedClass = class_item
            this.selectedUnit = item
            this.newUnit = item
            
            this.addUnitDialog = true
        },
        deleteWorkUnitList(item, chapter, class_item){
            this.fields.chapters.forEach(element => {
                if(element.id == chapter.id){
                    chapter.classes.forEach(cl_classes => {
                        if(cl_classes.id == class_item.id){
                            let i = 0;
                            let pos = -1;
                            cl_classes.units.forEach(un => {
                                if(un.id == item.id){
                                    pos = i
                                }
                                i++
                            });
                            if(pos != -1)
                                cl_classes.units.splice(pos, 1)
                        }
                    });
                }
            });
        },
        addWorkUnitToWork(){

        }
    },
    computed:{
        computedChoosingWorkUnit(){
            let returner = []
            
            this.fields.chapters.forEach(chap => {
                chap.classes.forEach(chap_class => {
                    chap_class.units.forEach(element => {
                        if(element.id_work_unit != this.newUnit.id_work_unit)
                        returner.push(element)
                    });
                });
            });
            return returner
        },
        canChooseWorkUnitStart(){
            let res = false

            if(this.fields.chapters == undefined)
                return res;

            this.fields.chapters.forEach(chap => {
                chap.classes.forEach(chap_class => {
                    chap_class.units.forEach(element => {
                        if(element.start_date != null)
                            res = true
                    });
                });
            });

            return res
        }
    }
}
</script>