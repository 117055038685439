<template>
    <section class="mt-16">
      <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <rp-icon icon="rp-money-blue-filled" class="mr-2"/> <span>{{$t('costs.crumb_title')}}</span>
      </h1>

        <div style="position:relative" class="mb-4" v-if="costs != null">
            <v-container>
              <v-speed-dial
                  absolute
                  v-model="speedDial"
                  top
                  right
                  direction="bottom"
                  :open-on-hover="true"
              >
                  <template v-slot:activator>
                  <v-tooltip right color="primary">
                      <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          v-bind="attrs" v-on="on"
                          v-model="speedDial"
                          color="primary"
                          dark
                          x-large
                          fab
                      >
                          <v-icon v-if="speedDial">
                          mdi-close
                          </v-icon>
                          <v-icon v-else>
                          mdi-dots-vertical
                          </v-icon>
                      </v-btn>
                      </template>
                      <span>{{$t('globals.options')}}</span>
                  </v-tooltip>
                  </template>
                  <v-tooltip right color="info">
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                      v-bind="attrs" v-on="on"
                      fab
                      dark
                      small
                      color="info"
                      large
                      @click="searching = true; "
                      >
                      <v-icon>mdi-filter</v-icon>
                      </v-btn>
                  </template>
                  <span>{{$t('globals.search')}}</span>
                  </v-tooltip>            
              </v-speed-dial>
            </v-container>
            <v-row>
              <v-col cols="12">
                <v-card elevation="0" :class="this.$root.$vuetify.theme.isDark ? 'secondary' : ''">
                  <v-card-title>{{$t('costs.works')}}</v-card-title>
                  <v-card-subtitle>{{$t('costs.works_cost')}}</v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                  <v-card
                    class="grow"
                    color="secondary"
                    outlined
                    height="125px"
                    dark
                    elevation="0"
                >
                  <v-card-subtitle>
                      <div class="text-center mt-n4 mb-3">
                          <span class="text-h6 font-weight-light">{{$t('costs.previewed_cost_card')}}</span>
                      </div>
                  </v-card-subtitle>
                  <!-- DESKTOP -->
                  <v-card-text class="d-none d-lg-block ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="auto">                            
                        <v-icon
                            large
                            center
                        >
                        {{$currency_icon()}}
                        </v-icon>
                      </v-col>
                      <v-col cols="12" md="auto" class="text-h6 font-weight-bold white--text ml-4">
                        {{ parseValue(costs.total_work_value) }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
                  <!-- MOBILE -->
                  <v-card-text class="d-md-none ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="12" class="font-weight-bold white--text text-center">
                        {{ parseValue(costs.total_work_value) }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="3">
                  <v-card
                    class="grow"
                    color="secondary"
                    outlined
                    height="125px"
                    dark
                    elevation="0"
                >
                  <v-card-subtitle>
                      <div class="text-center mt-n4 mb-3">
                          <span class="text-h6 font-weight-light">{{$t('costs.actual_cost_card')}}</span>
                      </div>
                  </v-card-subtitle>
                  <!-- DESKTOP -->
                  <v-card-text class="d-none d-lg-block ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="auto">                            
                        <v-icon
                            large
                            center
                        >
                        {{$currency_icon()}}
                        </v-icon>
                      </v-col>
                      <v-col cols="12" md="auto" class="text-h6 font-weight-bold white--text ml-4">
                        {{ parseValue(costs.actual_cost) }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
                  <!-- MOBILE -->
                  <v-card-text class="d-md-none ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="12" class="font-weight-bold white--text text-center">
                        {{ parseValue(costs.actual_cost) }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="3">
                  <v-card
                    class="grow"
                    color="grey darken-1"
                    outlined
                    height="125px"
                    dark
                    elevation="0"
                >
                  <v-card-subtitle>
                      <div class="text-center mt-n4 mb-3">
                          <span class="text-h6 font-weight-light">{{$t('costs.actual_invoiced')}}</span>
                      </div>
                  </v-card-subtitle>
                  <!-- DESKTOP -->
                  <v-card-text class="d-none d-lg-block ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="auto">                            
                        <v-icon
                            large
                            center
                        >
                        {{$currency_icon()}}
                        </v-icon>
                      </v-col>
                      <v-col cols="12" md="auto" class="text-h6 font-weight-bold white--text ml-4">
                        {{ $currency(getFaturacaoAtual(costs)) }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
                  <!-- MOBILE -->
                  <v-card-text class="d-md-none ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="12" class="font-weight-bold white--text text-center">
                        {{ $currency(getFaturacaoAtual(costs)) }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="3">
                  <v-card
                    class="grow"
                    color="grey darken-1"
                    outlined
                    height="125px"
                    dark
                    elevation="0"
                >
                  <v-card-subtitle>
                      <div class="text-center mt-n4 mb-3">
                          <span class="text-h6 font-weight-light">{{$t('costs.pending_invoiced')}}</span>
                      </div>
                  </v-card-subtitle>
                  <!-- DESKTOP -->
                  <v-card-text class="d-none d-lg-block ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="auto">                            
                        <v-icon
                            large
                            center
                        >
                        {{$currency_icon()}}
                        </v-icon>
                      </v-col>
                      <v-col cols="12" md="auto" class="text-h6 font-weight-bold white--text ml-4">
                        {{ $currency(costs.total_work_value - getFaturacaoAtual(costs)) }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
                  <!-- MOBILE -->
                  <v-card-text class="d-md-none ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="12" class="font-weight-bold white--text text-center">
                        {{ $currency(costs.total_work_value - getFaturacaoAtual(costs)) }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-card width="100%" elevation="0"  :class="this.$root.$vuetify.theme.isDark ? 'secondary' : ''">
                <v-card-title>
                  {{$t('costs.descriptive')}}
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('globals.filter')"
                    single-line
                    hide-details
                ></v-text-field>
                </v-card-title>
                <v-data-table
                :headers="headers"
                :footer-props="tableFooter"
                :items="costs.works"
                :search="search"
                :class="this.$root.$vuetify.theme.isDark ? 'secondary' : ''"
                >
                <template v-slot:item.started_at="{item}">
                    {{ parsedata(item.started_at) }}
                </template>

                <template v-slot:item.work_value="{item}">
                    {{ $currency(item.work_value) }}
                </template>
                
                <template v-slot:item.total_work_cost="{item}">
                    {{ $currency(item.total_work_cost) }}
                </template>

                <template v-slot:item.total_invoiced="{item}">
                    {{ $currency(item.total_invoiced) }}
                </template>

                <template v-slot:item.percentage_invoiced="{item}">
                    {{ item.percentage_invoiced }}%
                </template>
              </v-data-table>
            </v-card>
            </v-row>

            
            <v-row>
              <Workers class="mt-4" :costs="costs" />
            </v-row>
            <v-row>
              <Machines class="mt-6" :costs="costs" />
            </v-row>
            <v-row>
              <Materials class="mt-6" :costs="costs" />
            </v-row>
            <v-row>
              <Units class="mt-6" :costs="costs" />
            </v-row>
            <v-row>
              <Subempreitada class="mt-6" :costs="costs" />
            </v-row>
        </div>

        <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetWorks">
              {{$t('globals.clean_fields')}}
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchWorks">
            
            <v-list-item>
                <v-select
                    v-model="filter.work_id"
                    :label="$t('globals.work')"
                    :filter="filterAutocomplete"
                    :items="works"
                    :item-text="item => item.code + ' - ' + item.name"
                    item-value="id"
                    hide-selected
                >
                </v-select>
            </v-list-item>

            <v-list-item>
                <v-select
                    v-model="filter.work_type"
                    :label="$t('globals.state')"
                    :filter="filterAutocomplete"
                    :items="status"
                    :item-text="item => item.name"
                    item-value="id"
                    hide-selected
                >
                </v-select>
            </v-list-item>

            <v-list-item v-if="false">
              <v-col cols="12" md="12">
                <v-dialog
                ref="dialogPicker1"
                v-model="modalPicker1"
                :return-value.sync="filter.inicio"
                persistent
                width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="filter.inicio"
                        label="Data inicio"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="filter.inicio"
                    :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                    scrollable
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="modalPicker1 = false"
                    >
                        {{$t('globals.cancel')}}
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.dialogPicker1.save(filter.inicio)"
                    >
                        {{$t('globals.ok')}}
                    </v-btn>
                    </v-date-picker>
                </v-dialog>
              </v-col>
              
            </v-list-item>

            <v-list-item v-if="false">
              <v-col cols="12" md="12">
                <v-dialog
                    ref="dialogPicker2"
                    v-model="modalPicker2"
                    :return-value.sync="filter.fim"
                    persistent
                    width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="filter.fim"
                        label="Data fim"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    
                    </template>
                    
                    <v-date-picker
                    v-model="filter.fim"
                    :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                    scrollable
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="modalPicker2 = false"
                    >
                        {{$t('globals.cancel')}}
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.dialogPicker2.save(filter.fim)"
                    >
                        {{$t('globals.ok')}}
                    </v-btn>
                    </v-date-picker>
                </v-dialog>
            </v-col>
            </v-list-item>

            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              {{$t('globals.search')}}
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
      </v-navigation-drawer>
      </section>
</template>
<script>
import Work from "@/api/Work.js";
import Costs from "@/api/Costs.js";
import Workers from "@/components/costs/Workers"
import Machines from "@/components/costs/Machines"
import Materials from "@/components/costs/Materials"
import Units from "@/components/costs/Units"
import Subempreitada from "@/components/costs/Subempreitada"

export default{
    components:{
        Workers,
        Machines,
        Units,
        Subempreitada,
        Materials
    },
    mounted(){
        this.fillBaseData()
    },
    data(){
        return{                  
            search: '',
            modalPicker1: false,
            modalPicker2: false,
            loading: true, 
            costs: null,
            works: [],  
            status: [],
            filter:{
                work_id: null,
                work_type: null,
                inicio: null,
                fim: null
            },
            searching: false,  
            speedDial:false,
            options: {}
        }
    },
    methods:{
          getFaturacaoAtual(data){
            console.log(data)
            let total = 0
            data.works.forEach(element => {
              total = total + element.total_invoiced
            });
            return total
          },
          parsedata(dt){
            return dt.split(' ')[0]
          },
          parseValue(v){
            return this.$currency(v)
          },
          async searchWorks(){

            this.loading = true;

            await Costs.calculateCosts(this.filter).then(response => {

              this.costs = response.data

              this.loading = false;

            });
        },
        filterAutocomplete(item, queryText, itemText) {
            if (item.header) return false

            const hasValue = val => val != null ? val : ''

            const text = hasValue(itemText)
            const query = hasValue(queryText)

            return text.toString()
                .toLowerCase()
                .indexOf(query.toString().toLowerCase()) > -1
        },
        async fillBaseData(){
            this.loading = true
            await Work.list().then(({data}) => {
                this.works = data;
            });
            Work.listStatus().then(({data}) => {
              this.status = Object.keys(data).map(item => {

                return {
                  id: item,
                  name: data[item],
                }

              });
            });
            this.searchWorks()
            this.loading = false
        },
        resetWorks(){
            this.filter = {
                
            } ;
            this.searchWorks()
        },
    },
    computed: {
        tableFooter(){
          let t = this.$t("globals.lines_datatable")
          let a = [5, 10, 15, 20];
          return {
            'items-per-page-options': a,
            'items-per-page-text': t
          }
        }, 
        headers(){
          let he = [
          {
                  text: this.$t('globals.code'),
                  value: 'code'
              },
              {
                  text: this.$t('globals.work'),
                  value: 'name'
              },
              /*{
                  text: this.$t('costs.started_at'),
                  value: 'started_at'
              },*/
              {
                  text: this.$t('costs.previewed_cost'),
                  value: 'work_value_k'
              },
              {
                  text: this.$t('costs.actual_cost'),
                  value: 'total_work_cost'
              },
              {
                  text: this.$t('shoppings.total_invoiced'),
                  value: 'total_invoiced'
              },
              {
                  text: this.$t('globals.invoiced_percent'),
                  value: 'percentage_invoiced'
              },
              {
                  text: this.$t('globals.state'),
                  value: 'status'
              }
          ]

          return he
        },
        crumbs: function() {
            return [
                {
                text: this.$t('menu.dashboard'),
                disabled: false,
                to: '/admin',
                exact: true,
                },
                {
                text: this.$t('costs.crumb_title'),
                disabled: true,
                to: window.location.href,
                exact: true,
                },
            ];
        }
    }
}
</script>