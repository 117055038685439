<template>
    <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">        
        <validation-observer ref="form" v-slot="{  }">
            <fieldset>
            <v-form @submit.prevent="editCompany == null ? addCompany() : updateCompany()" :disabled="loading">
                <v-card-text>
                <DialogSuccess :opened="success" @on-ok="success = false; $emit('close');" >
                    {{$t('supplier.company_updated')}}
                </DialogSuccess>
                <v-row>
                    <v-col cols="12">
                        <validation-provider v-slot="{ errors }" vid="name" :name="$t('globals.name')" rules="required">
                            <v-text-field
                                :disabled="true"
                                v-model="company.name"
                                prepend-inner-icon="mdi-account-details"
                                :label="$t('globals.name') + ' *'"
                                clearable
                                :error-messages="errors"
                            ></v-text-field>
                        </validation-provider>
                    </v-col>
                    <v-col cols="12" md="4">
                        <validation-provider v-slot="{ errors }" vid="nif" :name="$t('globals.nif')" rules="|">
                            <v-text-field
                                v-model="company.nif"
                                prepend-inner-icon="mdi-finance"
                                :label="$t('globals.nif') + ' *'"
                                clearable
                                :error-messages="errors"
                            ></v-text-field>
                        </validation-provider>
                    </v-col>                
                    <v-col cols="12" md="8">
                        <validation-provider v-slot="{ errors }" vid="email" :name="$t('globals.email')" rules="|">
                            <v-text-field
                                v-model="company.email"
                                prepend-inner-icon="mdi-at"
                                :label="$t('globals.email')"
                                clearable
                                :error-messages="errors"
                            ></v-text-field>
                        </validation-provider>
                    </v-col>
                    <v-col cols="12" md="4">
                        <validation-provider v-slot="{ errors }" vid="contact" :name="$t('globals.contact')" rules="|">
                            <v-text-field
                                v-model="company.contact"
                                prepend-inner-icon="mdi-card-account-phone"
                                :label="$t('globals.contact')"
                                clearable
                                :error-messages="errors"
                            ></v-text-field>
                        </validation-provider>
                    </v-col>  
                    <v-col cols="12">
                        <v-card-title :class="$root.$vuetify.theme.isDark ? 'warning white--text' : 'primary white--text'">{{$t('globals.invoices')}} <span class="ml-4"><small>({{$t('shoppings.total_invoiced')}}: {{ company.invoices_total != 0 ? $currency(company.invoices_total) : '0 ' + $store.state.coin }})</small></span></v-card-title>
                    </v-col>
                    <v-col cols="12">
                        <InvoicesList  @reload="$emit('reload')" class="mt-n12" place="companies"/>               
                    </v-col>    	
                </v-row>
                </v-card-text>                        
                </v-form>
            </fieldset>            
        </validation-observer>
    </v-card>
</template>
<script>
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Company from "@/api/Company.js";
import InvoicesList from '@/components/shopping/InvoicesList.vue'

export default {
    components:{
        InvoicesList,
        DialogSuccess
    },
    props:{
        editCompany: Object
    },
    watch:{
        editCompany:{
            immediate: true,
            handler(val){
                if(val == null)
                    return
                this.company = val
            }
        }
    },
    mounted(){
        if(this.editCompany != null){
            this.company.name = this.editCompany.name
            this.company.contact = this.editCompany.contact
        }
    },
    data(){
        return{
            tab: 0,
            loading: false,
            success:false,
            company:{
                name: '',
                deleted_company: false,
                contact: null,
                nif: null,
                email: null
            }
        }
    },
    methods:{
        updateCompany(){
            this.loading = true
            Company.update(this.company, this.editCompany.id)
                .then(() => {
                    this.success = true;

                    this.loading = false;

                    this.$emit('close-update')
                });
        },
        addCompany(){
            this.loading = true
            
            Company['create'](this.company).then(() => {

                this.success = true;

                this.loading = false;

                this.$emit('close-update')

                }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        }
    }
}
</script>