import BaseApi from "./BaseApi";

export default class Faults extends BaseApi{
    construct() {}
    
  
    static async search(params) {
        return await Faults.getAxiosInstance().get(Faults.getFullApiUrl(),{params: params});
    }

    static async create(params) {
        return await Faults.getAxiosInstance().post(Faults.getFullApiUrl(),params);
    }

    static async update(params, id) {
        return await Faults.getAxiosInstance().put(Faults.getFullApiUrl() + id,params);
    }

    static async delete(id) {
        return await Faults.getAxiosInstance().delete(Faults.getFullApiUrl() + id);
    }

    static async find(id) {
        return await Faults.getAxiosInstance().get(Faults.getFullApiUrl() + id);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/faults/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/faults/";
    }
}