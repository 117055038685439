<template>
    <v-container fluid class="mb-8">
        <v-data-table
            :headers="diariesHeaders"
            :items="diariesItems"
            :server-items-length="diariesTotal"
            :loading="diariesLoading"
            :footer-props="diariesTableFooter"
            :options.sync="diariesOptions"
            :class="this.$root.$vuetify.theme.isDark ? 'secondary' : ''"
            locale="pt-pt"
          >
            <template v-slot:item.work="{ item }">
              {{diariesWorks != null ? diariesWorks.find(i => i.id == item.work_id).code + " : " + diariesWorks.find(i => i.id == item.work_id).name : "-"}}
            </template>

            <template v-slot:item.status="{ item }">
              {{diariesStatus != null ? diariesStatus.find(i => i.id == item.status).name : "-"}}
            </template>

            <template v-slot:item.date="{ item }">
              {{getTableDate(item.date)}}
            </template>

            <template v-slot:item.actions="{item}">
              <IconView
                class="mr-2"
                @click="viewDiary(item)"
              />
            </template>
          </v-data-table>
    </v-container>
</template>
<script>
import dayjs from 'dayjs'
import Diary from "@/api/Diary.js";
import Work from "@/api/Work.js";
import IconView from '@/components/ui/IconView.vue';

export default{
    name:"Diaries",
    components:{
        IconView
    },
    data(){
        return{
            diariesItems: [],
            diariesTotal: 0,
            diariesLoading: true,
            diariesWorks: [],
            diariesOptions: {},
            filter: {
                work_id: null,
            },
            diariesStatus: []
        }
    },
    props:{
        work_id: String,
    },
    mounted(){
        this.fillBaseData()
    },
    watch:{
        'diariesOptions': {
        immediate: false,
        handler () {
            setTimeout(() => {this.searchDiaries()}, 10);
        },
        deep: true,
        },
    },
    methods:{
        fillBaseData(){

            Work.list().then(({data}) => {
                this.diariesWorks = data;
            });
            
            Diary.listStatus().then(({data}) => {
                this.diariesStatus = Object.keys(data).map(item => {return {id: item, name: data[item]}});
            });

            this.filter = {
                work_id: this.work_id
            }

            this.diariesOptions = {}
        },
        getTableDate(date) {
            return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
        },
        viewDiary(item){
            let domain = '/' + this.$session.get('domain')
            this.$router.push(domain + '/admin/diaries/' + item.id);
        },
        searchDiaries(){
            this.diariesLoading = true;

            let filter = {...this.filter};
            let request = {
                sortBy: this.diariesOptions.sortBy,
                sortDesc: this.diariesOptions.sortDesc,
                page: this.diariesOptions.page,
                limit: this.diariesOptions.itemsPerPage,
            };

            this.filter.work_id = this.work_id
            
            Object.assign(request, filter);

            localStorage["diaries-workv2-" + this.$root.session.id] = JSON.stringify({
                filter: this.filter,
                options: this.diariesOptions,
            });

           

            Diary.search(request).then(response => {

                let data = response.data;

                this.diariesTotal = data.total;

                this.diariesItems = data.data.map((item) => {
                    return item;
                });

                this.diariesLoading = false;

            });
            },
    },
    computed:{
        diariesTableFooter(){
            let t = this.$t("globals.lines_datatable")
            let a = [5, 10, 15, 20];
            return {
                'items-per-page-options': a,
                'items-per-page-text': t
            }
        }, 
        diariesHeaders(){
            let dh = [
            {
            text: this.$t('globals.code'),
            sortable: true,
            value: 'code',
            },
            {
            text: this.$t('globals.work'),
            sortable: true,
            value: 'work',
            },
            {
            text: this.$t('globals.state'),
            sortable: true,
            value: 'status',
            },
            {
            text: this.$t('globals.data'),
            sortable: true,
            value: 'date',
            },
            { 
            text: this.$t('globals.options'),
            value: 'actions', 
            sortable: false 
            },
        ]
        return dh
        },
    }
}
</script>