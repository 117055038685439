<template>
    <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
        <validation-observer ref="form" v-slot="{ invalid }">
            <fieldset>
              <v-form @submit.prevent="editClient == null ? addClient() : updateClient()" :disabled="loading">
                <v-card-title :class="$root.$vuetify.theme.isDark ? 'white--text subtitle-1 primary' : 'white--text subtitle-1 primary'">
                    {{$t('client.add_crumb')}}
                </v-card-title>
                <v-card-text>
                  <DialogSuccess :opened="success" @on-ok="success = false; $emit('close-update');" >
                    {{$t('client.success_add')}}
                  </DialogSuccess>
                  <v-row>
                    <v-col cols="12" md="6">
                        <validation-provider v-slot="{ errors }" vid="reference" :name="$t('globals.reference')" rules="required">
                            <v-text-field
                                v-model="client.reference"
                                prepend-inner-icon="mdi-barcode"
                                :label="$t('globals.reference') + ' *'"
                                clearable
                                :error-messages="errors"
                            ></v-text-field>
                        </validation-provider>
                    </v-col>
                    <v-col cols="12">
                        <validation-provider v-slot="{ errors }" vid="name" :name="$t('globals.name')" rules="required">
                            <v-text-field
                                v-model="client.name"
                                prepend-inner-icon="mdi-account-details"
                                :label="$t('globals.name') + ' *'"
                                clearable
                                :error-messages="errors"
                            ></v-text-field>
                        </validation-provider>
                    </v-col>
                    <v-col cols="12" md="4">
                            <v-text-field
                                v-model="client.nif"
                                prepend-inner-icon="mdi-ticket-confirmation"
                                :label="$t('globals.nif') + ' *'"
                                clearable
                            ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <validation-provider v-slot="{ errors }" vid="email" :name="$t('globals.email')" rules="email">
                            <v-text-field
                                v-model="client.email"
                                prepend-inner-icon="mdi-email-box"
                                :label="$t('globals.email')"
                                clearable
                                :error-messages="errors"
                            ></v-text-field>
                        </validation-provider>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            v-model="client.telephone"
                            prepend-inner-icon="mdi-phone"
                            :label="$t('globals.contact')"
                            clearable
                        ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col><small>{{$t('client.defect_window')}}</small></v-col>                    
                  </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        depressed
                        @click="$emit('close')"
                    >
                        {{$t('globals.cancel')}}
                    </v-btn>
                    <v-btn :disabled="invalid" depressed :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary'" type="submit">
                        {{$t('globals.save')}}
                    </v-btn> 
                </v-card-actions>
                </v-form>
            </fieldset>            
        </validation-observer>
    </v-card>
</template>
<script>
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Client from "@/api/Client.js";

export default {
    components:{
        DialogSuccess
    },
    props:{
        editClient: Object
    },
    mounted(){
        if(this.editClient != null){
            this.client.name = this.editClient.name
            this.client.reference = this.editClient.reference
            this.client.nif = this.editClient.nif
            this.client.telephone = this.editClient.telephone
            this.client.email = this.editClient.email
        }
    },
    data(){
        return{
            loading: false,
            success:false,
            client:{
                name: '',
                reference: null,
                nif: null,
                email: null,
                telephone: null,
                deleted_client: false
            }
        }
    },
    methods:{
        updateClient(){
            this.loading = true
            Client.update(this.client, this.editClient.id)
                .then(() => {
                    this.success = true;

                    this.loading = false;

                    this.$emit('close-update')
                });
        },
        addClient(){
            this.loading = true
            
            Client['create'](this.client).then(() => {

                this.success = true;

                this.loading = false;

                }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        }
    }
}
</script>