var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mb-8",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-dialog',{attrs:{"max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var props = ref.props;
return [_c('div',{staticClass:"text-left mt-4"},[_c('Tooltip',{attrs:{"color":"warning","text":_vm.$t('work.add_image'),"bottom":""}},[_c('v-btn',_vm._b({staticClass:"mx-2",attrs:{"dark":"","small":"","color":"warning"},on:{"click":function($event){_vm.addImages = true;}}},'v-btn',props,false),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(_vm._s(_vm.$t('work.add_image'))+" ")],1)],1)],1)]}}]),model:{value:(_vm.addImages),callback:function ($$v) {_vm.addImages=$$v},expression:"addImages"}},[_c('v-card',{class:_vm.$root.$vuetify.theme.isDark ? 'secondary' : ''},[_c('v-toolbar',{class:_vm.$root.$vuetify.theme.isDark ? 'secondary' : 'primary white--text'},[_vm._v(" "+_vm._s(_vm.$t('globals.indirect_work_hand'))+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"vid":"Inicio","name":_vm.$t('globals.start_date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('globals.start_date'),"error-messages":errors,"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.image_object.associated_day),callback:function ($$v) {_vm.$set(_vm.image_object, "associated_day", $$v)},expression:"image_object.associated_day"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.image_object.pickerDatafotos),callback:function ($$v) {_vm.$set(_vm.image_object, "pickerDatafotos", $$v)},expression:"image_object.pickerDatafotos"}},[_c('v-date-picker',{on:{"input":function($event){_vm.image_object.pickerDatafotos = false}},model:{value:(_vm.image_object.associated_day),callback:function ($$v) {_vm.$set(_vm.image_object, "associated_day", $$v)},expression:"image_object.associated_day"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Adicione o seu comentário"},model:{value:(_vm.image_object.comment),callback:function ($$v) {_vm.$set(_vm.image_object, "comment", $$v)},expression:"image_object.comment"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-center mb-8"},[_c('VueUploadMultipleImage',{ref:"imageUploader",staticClass:"image-uploader",attrs:{"dragText":_vm.$t('diaries.insert_images'),"browseText":_vm.$t('diaries.click_here'),"showPrimary":false,"data-images":_vm.images,"idUpload":"myIdUpload","editUpload":"myIdEdit","maxImage":200},on:{"upload-success":_vm.uploadImageSuccess,"before-remove":_vm.beforeRemove}})],1)])],1)],1),_c('v-card-actions',{staticClass:"justify-center"})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","sort-by":"associated_day","group-by":"associated_day","show-group-by":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('v-container',{class:_vm.$root.$vuetify.theme.isDark ? '' : 'primary white--text',attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"white--text",on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus')+" ")]),_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.$t('globals.day'))+": "),_c('small',[_vm._v(_vm._s(_vm.formatDate(items[0].associated_day)))])])],1),(items[0].diary_id != null)?_c('v-col',{staticClass:"mt-n4",attrs:{"cols":"12","md":"auto"}},[_c('span',{on:{"click":function($event){return _vm.viewDiary(items[0])}}},[_c('small',[_vm._v(_vm._s(_vm.$t('globals.options'))+": ")]),_c('v-icon',{staticClass:"ml-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"white","small":""}},[_vm._v("mdi-eye")])],1)]):_vm._e(),_c('v-col',{staticClass:"mt-n4",attrs:{"cols":"12","md":"auto"}},[_c('span',{on:{"click":function($event){return _vm.downloadImages(items[0])}}},[_c('v-icon',{staticStyle:{"cursor":"pointer"},attrs:{"color":"white","small":""}},[_vm._v("mdi-download")])],1)])],1)],1)]}},{key:"item.files",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"mt-4 mb-4"},[_c('v-img',{attrs:{"aspect-ratio":"1.7","contain":"","src":item.path}}),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('globals.comment'))+":")])]),_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_vm._v(" "+_vm._s(item.comment)+" ")])],1)],1)],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }