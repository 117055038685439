var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{class:this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16',style:(this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'),attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.tableFooter,"options":_vm.options,"locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$currency(item.total))+" ")]}},(_vm.place == 'materials')?{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.materialCost(item))+" ")]}}:null,(_vm.place == 'materials')?{key:"item.qty",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.materialQty(item))+" ")]}}:null,(_vm.place == 'materials')?{key:"item.cost_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$currency(_vm.materialQty(item) * _vm.materialCost(item)))+" ")]}}:null,{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[(_vm.$root.session.hasPermission(["super", "shopping.update"]))?_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('IconView',{attrs:{"small":""},on:{"click":function($event){return _vm.viewInvoice(item)}}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"1"}},[(item.invoice_path != null)?_c('IconDownload',{attrs:{"text":_vm.$t('globals.download')},on:{"click":function($event){return _vm.download(item)}}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[(_vm.$root.session.hasPermission(['super', 'shopping.delete']))?_c('IconRemove',{on:{"on-submit":function($event){return _vm.deleteInvoice(item)}}}):_vm._e()],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.invoiceListDialog),callback:function ($$v) {_vm.invoiceListDialog=$$v},expression:"invoiceListDialog"}},[_c('Shopping',{attrs:{"method":"update","modalType":true,"shopping":_vm.selectedInvoice}})],1),_c('SuccessSnackBar',{attrs:{"message":_vm.modalSuccess.message},model:{value:(_vm.modalSuccess.show),callback:function ($$v) {_vm.$set(_vm.modalSuccess, "show", $$v)},expression:"modalSuccess.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }