<template>
    <v-btn icon
          v-bind="$attrs"
          v-on="$listeners"
    >
      <Tooltip
        color="success"
        :text="text"
        top
      >
        <v-icon
          small
          color="warning"
          @click="$emit('click')"
        >
          mdi-briefcase-download
        </v-icon>
      </Tooltip>
    </v-btn>
  </template>
  
  <script>
    import Tooltip from '@/components/ui/Tooltip.vue';
  
    export default {
      name: 'uiIconDownload',
      props: {
        text: String
      },
      components: {
        Tooltip
      },
    }
  </script>
  
  <style lang="scss" scoped>
  </style>