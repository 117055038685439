<template>
    <section class="mt-16">
      <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <rp-icon icon="rp-exportar-blue-filled" class="mr-2"/> <span>{{$t('export_list.title')}}</span>
        </h1>
    
  
        <div style="position:relative">
            <ExportList />
        </div>
    </section>
</template>
<script>
import ExportList from '@/components/files/ExportList.vue';

export default{
    components:{
        ExportList
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: this.$t('menu.dashboard'),
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: this.$t('export_list.title'),
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
}
</script>