<template>
  <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
    <v-tabs v-model="tab" :background-color="$root.$vuetify.theme.isDark ? 'secondary' : 'primary'" dark fixed-tabs>
      <v-tab>{{$t('globals.worker')}}</v-tab>
      <v-tab :disabled="method == 'create'">{{$t('globals.daily_parts')}}</v-tab>
    </v-tabs>
    <v-container>
    <v-tabs-items v-model="tab" touchless :class="($root.$vuetify.theme.isDark ? 'secondary' : '') + ' elevation-0'">
      <v-tab-item class="mt-6" :key="1">
        <validation-observer ref="form" v-slot="{ invalid }">
          <v-form @submit.prevent="submit"  class="mb-16">
            <v-progress-linear v-if="loading"
                indeterminate
                class="global-loader"
              ></v-progress-linear>
            <fieldset :disabled="loading">
              <v-row>
                <v-col cols="12" md="3">
                  <validation-provider v-slot="{ errors }" vid="code" :name="$t('globals.id')" rules="required">
                    <v-text-field
                      v-model="fields.code"
                      prepend-inner-icon="mdi-barcode"
                      :label="$t('globals.id') + ' *'"
                      clearable
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="3">
                  <validation-provider v-slot="{ errors }" vid="name" :name="$t('globals.name')" rules="required">
                    <v-text-field
                      v-model="fields.name"
                      prepend-inner-icon="mdi-account-details"
                      :label="$t('globals.name') + ' *'"
                      clearable
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                      v-model="fields.phone_number"
                      prepend-inner-icon="mdi-phone"
                      :label="$t('globals.telephone')"
                      clearable
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                      v-model="fields.email"
                      prepend-inner-icon="mdi-at"
                      :label="$t('globals.email')"
                      clearable
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select
                      v-model="fields.lunch_hour"
                      :items="lunch_hours"
                      :item-text="item => item.hour"
                      item-value="hour"
                      menu-props="auto"
                      :label="$t('globals.lunch_hour')"
                      prepend-inner-icon="mdi-clock"
                      clearable
                    ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" md="4">
                    <v-checkbox
                      :color="$root.$vuetify.theme.isDark ? 'white' : 'primary'"
                      v-model="checkbox"
                      :label="$t('machines.add_company')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-select
                      :disabled="!checkbox"
                      v-model="fields.id_company"
                      :items="companies"
                      :item-text="item => item.name"
                      item-value="id"
                      menu-props="auto"
                      :label="$t('globals.company') + ' *'"
                      prepend-inner-icon="mdi-factory"
                      clearable
                    ></v-select>
                  </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="3">
                  <validation-provider v-slot="{ errors }" vid="value" :name="$t('globals.cost')" rules="min_value:0">
                    <v-currency-field
                        v-model="fields.value"
                        :prepend-inner-icon="$currency_icon()"
                        :label="$t('globals.cost') + '/h'"
                        @blur="fields.value = fields.value == 0 ? null : fields.value"
                        clearable
                        :error-messages="errors"
                      />
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="4">
                  <validation-provider v-slot="{ errors }" vid="category_id" :name="$t('globals.category')" rules="required">
                    <v-select
                        v-model="fields.category_id"
                        :items="categories"
                        item-text="name"
                        item-value="id"
                        menu-props="auto"
                        :label="$t('globals.category')"
                        prepend-inner-icon="mdi-shape"
                        clearable
                        :error-messages="errors"
                    ></v-select>
                    <v-row justify="end" class="px-3">
                      <v-btn
                        :color="$root.$vuetify.theme.isDark ? 'white' : 'secondary'"
                        text
                        outlined
                        right
                        small
                        depressed
                        @click="creatingCategory = true"
                      >
                        {{$t('category.add_new')}}
                      </v-btn>
                    </v-row>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="4" v-if="$root.session.hasPermission(['super', 'users.read'])">
                  <v-autocomplete
                    :items="users"
                    :label="$t('globals.users')"
                    :item-text="item=> item.code == null ? item.name : item.code + ' - ' + item.name"
                    item-value="id"
                    v-model="fields.user_id"
                    clearable
                  />
                </v-col>
                <v-col cols="12" md="8" align="end" v-if="method=='update'">
                  <v-btn v-if="fields.user_id == null" @click="createUserAccount" rounded class="success">
                    <v-icon>mdi-account</v-icon>
                    {{$t('workers.create_user')}}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="mt-6" align="center" justify="space-around">
                <v-btn :disabled="invalid" depressed :class="($root.$vuetify.theme.isDark ? 'white primary--text' : 'primary') + ' mr-5 ml-5 mb-5'" type="submit">
                    {{$t('globals.save')}}
                  </v-btn>
              </v-row>
            </fieldset>
          </v-form>
          <DialogSuccess :opened="success" @on-ok="onSuccess" >
            {{$t('workers.add_new')}}
          </DialogSuccess>
        </validation-observer>
      
      </v-tab-item>
      <v-tab-item :key="2" v-if="method=='update'">
        <WorkerDiaries />
      </v-tab-item>
      </v-tabs-items>
      <ErrorSnackbar v-model="error.show" :message="error.message"/>

      <v-dialog
          v-model="creatingCategory"
          persistent
          max-width="600px"
        >
          <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
            <validation-observer ref="formCategory" v-slot="{ invalid }">
              <v-progress-linear v-if="loadingCategory"
              indeterminate
              class="global-loader"
              ></v-progress-linear>
              <fieldset :disabled="loadingCategory">
                <v-form @submit.prevent="submitCategory">
                  <v-card-title :class="$root.$vuetify.theme.isDark ? 'white--text subtitle-1 primary' : 'white--text subtitle-1 primary'">                 
                      {{$t('globals.category')}}
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <validation-provider v-slot="{ errors }" vid="name" :name="$t('globals.name')" rules="required">
                            <v-text-field
                              v-model="category.name"
                              prepend-inner-icon="mdi-form-textbox"
                              :label="$t('globals.name')+  ' *'"
                              clearable
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <validation-provider v-slot="{ errors }" vid="value" :name="$t('globals.cost')" rules="required|min_value:0">
                            <v-currency-field
                                v-model="category.value"
                                :prepend-inner-icon="$currency_icon()"
                                :label="$t('globals.cost')+  ' *'"
                                clearable
                                :error-messages="errors"
                              />
                            </validation-provider>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      depressed
                      @click="creatingCategory = false"
                    >
                      {{$t('globals.cancel')}}
                    </v-btn>
                    <v-btn :disabled="invalid" depressed :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary'" type="submit">
                      {{$t('globals.save')}}
                    </v-btn>            
                  </v-card-actions>
                </v-form>
              </fieldset>
            </validation-observer>
          </v-card>
        </v-dialog>
    
    
    
  </v-container>
    </v-card>
</template>
<script>
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Category from "@/api/Category.js";
import Company from "@/api/Company.js";
import Worker from "@/api/Worker.js";
import LunchHour from "@/api/LunchHour.js";
import ClientUsers from "@/api/ClientUsers.js"
import dayjs from 'dayjs'
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';

import WorkerDiaries from "@/components/workers/WorkerDiaries.vue"

export default {
  components: {
    DialogSuccess,
    ErrorSnackbar,
    WorkerDiaries
  },
  props: {
   worker: Object,
   method: String,
   disabled: Boolean,
 },
 watch: { 
    worker: {
      immediate: true, 
      handler (val) {
        if(!val) {
          return;
        }

        let data = {...val};

        this.fields = data;

        this.fields.category_id = data.category.id;

        this.fields.id = data.id;

        if(data.company != null){
          this.fields.id_company = data.company.id
          this.checkbox = true
        }
      }
    },
  },
  beforeCreate(){
  },
  data: () => ({
    tab: 0,
    users:[],
    creatingCategory: false,
    loadingCategory: false,
    lunch_hours: [],
    fields: {
      id: null,
      code: dayjs().unix(),
      name:null,
      phone_number:null,
      value:null,
      category_id:null,
      id_company: null,
      lunch_hour: null,
      email: null,
      user_id: null
    },
    category: {
      name: null,
      value: null,
    },
    loading:false,
    error: {
      title: '',
      message: '',
    },
    categories: [],
    companies: [],
    success: false,
    checkbox:false,
  }),
  mounted(){
    if(this.$route.params.data != undefined){
      let data = JSON.parse(this.$route.params.data)
      
      this.fields.name = data.name
      this.fields.email = data.email
      this.fields.phone_number = data.phone
      this.fields.code = data.code
      this.fields.user_id = data.user_id
    }
    
    this.fillBaseData();
  },
  methods:{
    createUserAccount(){
      let data = {
        name: this.fields.name,
        email: this.fields.email,
        phone: this.fields.phone_number,
        code: this.fields.code,
        worker_id: this.fields.id
      }
      
      this.$router.push('/' + this.$session.get('domain') + '/admin/accesses/users/create/' + JSON.stringify(data))
    },
    onSuccess() {
      if(this.fields.id != null) {
        this.success = false
        return
      }
      let domain = '/' + this.$session.get('domain')
      this.$router.push(domain + '/admin/workers-settings/workers');
    },
    fillBaseData(){
      Category.list().then(({data}) => {
        this.categories = data;
      });

      Company.list().then(({data}) => {
        this.companies = data;
      });

      LunchHour.list().then(({data}) => {
        this.lunch_hours = data;
      });

      ClientUsers.list().then(({data})  =>  {
        this.users = data
      })
    },
    submitCategory(){
      this.$refs.formCategory.validate().then(() => {
        this.creatingCategory = true;

        Category.create(this.category).then(({data}) => {

          this.categories.push(data);

          this.creatingCategory = false;

          this.fields.category_id = data.id;

          this.category = {};
        });
      });
    },
    submit() {

      if(this.checkbox == false)
        this.fields.id_company = null

      this.$refs.form.validate().then((result) => {
        this.loading = true;
        
        if(!result){
          this.loading = false;
          return;
        }

        Worker[this.method](this.fields, this.fields.id).then(() => {

          this.success = true;

          this.loading = false;
          
        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);

              if(err.response.data.errors.code != undefined){
                this.error.message = this.$t('workers.worker_id_invalid')
                this.error.show = true
              }
              
              return;
            }

            this.error.title = this.$t('globals.error') + " " + err.response.status;

            this.error.message = err.response.data.message;

          });
      })
    }
  },
  computed: {
    
  }
};
</script>
