<template>
    <section class="mt-16">
      <v-breadcrumbs
          v-if="this.$root.$vuetify.theme.isDark"
          large
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
        <v-breadcrumbs
          v-else
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-hard-hat</v-icon> <span>{{$t('shopping_request.crumb_new')}}</span>
        </h1>
  
        <div class="mt-16" :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'">
          <ShoppingRequest method="create"></ShoppingRequest>
        </div>
    </section>
  </template>
  <script>
  import ShoppingRequest from '@/components/shopping_request/ShoppingRequest'
  
  export default {
    components: {
        ShoppingRequest
    },
    beforeCreate(){
      document.title = "Report Lake - " + this.$t('shopping_request.crumb_new');
    },
    data: () => ({
      
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "shopping_request.create"])) {
        this.$router.push('/admin');
      }
  
    },
    methods:{
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: this.$t('menu.dashboard'),
            disabled: false,
            to: '/' + this.$session.get('domain') + '/admin',
            exact: true,
          },
          {
            text: this.$t('menu.material_request'),
            disabled: false,
            to: '/' + this.$session.get('domain') + '/admin/shopping-request',
            exact: true,
          },
          {
            text: this.$t('shopping_request.crumb_new'),
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  