<template>
    <section class="mt-16">
        <v-progress-linear v-if="loading"
        indeterminate
        class="global-loader"
      ></v-progress-linear>
      <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
        <h1 class="primary--text">
        <v-icon color="primary">mdi-earth</v-icon> <span>{{$t('menu.general_configurations')}}</span>
        </h1>
        <div style="position:relative; background-color: transparent !important;" v-if="!loading">
            <v-container style="background-color: transparent !important">
                <validation-observer ref="form" v-slot="{ invalid }">
                <v-form @submit.prevent="submit">
                    <v-card style="background-color: transparent !important;" elevation="0">
                    <v-tabs id="global-tab" centered v-model="tab" background-color="primary" dark fixed-tabs>
                            <v-tabs-slider color="warning"></v-tabs-slider>
                            <v-tab>
                                <v-icon left>
                                mdi-domain
                                </v-icon>
                                {{$t('settings.profile')}}
                            </v-tab>
                            <v-tab>
                                <v-icon left>
                                mdi-clock
                                </v-icon>
                                {{$t('settings.schedules')}}
                            </v-tab>
                            <v-tab v-if='$root.session.hasPermission(["super", "meals_configuration.read"])'>
                                <v-icon left>
                                mdi-food-apple
                                </v-icon>
                                {{$t('settings.meals')}}
                            </v-tab>
                            <v-tab v-if='$root.session.hasPermission(["super", "alerts_configuration.read"])'>
                                <v-icon left>
                                mdi-alert
                                </v-icon>
                                {{$t('settings.alerts')}}
                            </v-tab>
                            <v-tab v-if='$root.session.hasPermission(["super", "branding.read"])'>
                                <v-icon left>
                                mdi-palette
                                </v-icon>
                                {{$t('settings.branding')}}
                            </v-tab>

                            <v-tab-item :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                                <CompanyProfile />
                            </v-tab-item>
                        
                            <v-tab-item :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                                <Schedule />
                            </v-tab-item>
        

                            <v-tab-item :class="$root.$vuetify.theme.isDark ? 'secondary' : ''" v-if='$root.session.hasPermission(["super", "meals_configuration.read"])'>
                                <Meals />
                            </v-tab-item>

                            <v-tab-item :style="$root.$vuetify.theme.isDark ? '' : 'background-color: transparent !important;'" :class="$root.$vuetify.theme.isDark ? 'secondary' : ''" v-if='$root.session.hasPermission(["super", "alerts_configuration.read"])'>
                                <Alerts :style="$root.$vuetify.theme.isDark ? '' : 'background-color: transparent !important;'" :class="$root.$vuetify.theme.isDark ? 'secondary' : ''" />
                            </v-tab-item>

                            <v-tab-item v-if='$root.session.hasPermission(["super", "branding.read"])' :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                                <BrandingView :style="$root.$vuetify.theme.isDark ? '' : 'background-color: transparent !important;'"/>
                            </v-tab-item>
                        </v-tabs>
                        <v-card-actions v-if="false">
                            <v-spacer></v-spacer>
                            <v-btn :disabled="invalid" depressed color="primary" type="submit">
                                {{$t('globals.save')}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
                </validation-observer>
            </v-container>
        </div>
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
          {{$t('settings.success_save')}}
        </DialogSuccess>
    <ErrorSnackbar v-model="error.show" :message="error.message"/>
    </section>
</template>
<script>
import Configurations from "@/api/Configurations.js";
import Branding from "@/api/Branding.js";
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
import Meals from '@/views/settings/Meals.vue';
import CompanyProfile from '@/views/settings/CompanyProfile.vue'
import Schedule from '@/views/settings/Schedule.vue'
import Alerts from '@/views/settings/Alerts.vue'
import BrandingView from '@/views/settings/Branding.vue'

export default{
    components:{
        DialogSuccess,
        Meals,
        ErrorSnackbar,
        Schedule,
        Alerts,
        BrandingView,
        CompanyProfile
    },
    data: () => ({    
        error:{
            message: '',
            show: false,
        },
        logo_changed: false,
        img_url: null,
        tab : 0,
        rules: [
            value => !value || value.size < 2000000 || this.$t('settings.image_to_big'),
        ],   
        days_starting: [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28   
        ],
        loading: false,
        success: false,
        fields:{
            id: null,
            day_breakfast: null,
            day_dinner: null,
            day_lunch: null,
            night_breakfast: null,
            night_dinner: null,
            night_lunch: null,
            work_hours: null,
            extra_50: null,
            extra_75: null,
            extra_100: null,
            diaries_to_approve_limit: null,
            diaries_to_do_limit: null,
            branding: null,
            diaries_to_do:{
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false
            },
            diaries_to_approve:{
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false
            }
        }
    }),
    async mounted(){
        this.loading = true
        await Configurations.get().then(({data}) => {
          this.fields = data
          if(data.logo != null)
            this.img_url = data.logo.replace(/([^:])(\/\/+)/g, '$1/'); //process.env.VUE_APP_API_URL + data.logo.replace(/([^:])(\/\/+)/g, '$1/');

          
          if(this.fields.diaries_to_do == null){
            this.fields.diaries_to_do = {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false
            }
          }
          if(this.fields.diaries_to_approve == null){
            this.fields.diaries_to_approve = {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false
            }
          }
          this.loading = false
        });
    },
    methods:{
        onFileChange(file){
            if(file == null)
                return

            let tamanho = 2000000; // 2 MB

            if(file.size >= tamanho){
                this.fields.branding = null
                this.error.message = this.$t('settings.image_less_2')
                this.error.show = true
                return
            }

            this.logo_changed = true;

            const reader = new FileReader();

            reader.onload = e => {
                this.img_url = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        onSuccess(){
            this.success = false
            

            if(this.logo_changed){
                this.logo_changed = false
                window.location.reload(true)
            }

        },
        async submit(){
            if(this.tab != 3){
                await Configurations.update(this.fields, this.fields.id).then(() => {
    
                    this.success = true;

                    this.loading = false;
                    
                    }).catch(err => {
                    this.loading = false;

                    if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                    }

                    this.error.title = this.$t('globals.error') + " " + err.response.status;

                    this.error.message = err.response.data.message;

                });
            }else{
                if(this.fields.branding == null)
                    return
                const formData = new FormData();
                formData.append('file', this.fields.branding)
                await Branding.create(formData).then(() => {
    
                    this.success = true;

                    this.loading = false;
                    
                    }).catch(err => {
                    this.loading = false;

                    if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                    }

                    this.error.title = this.$t('globals.error') + " " + err.response.status;

                    this.error.message = err.response.data.message;

                });
            }
        }
    },
    computed: {
        crumbs: function() {
        return [
            {
            text: this.$t('globals.definitions'),
            disabled: false,
            to: '/' + this.$session.get('domain') + '/admin/settings/general',
            exact: true,
            },
            {
            text: this.$t('menu.general_configurations'),
            disabled: true,
            to: window.location.href,
            exact: true,
            },
        ];
        }
    }
}
</script>
<style>
    #global-tab .v-tabs-bar{
        width: 100% !important;
    }

    #global-tab .v-tabs-slider-wrapper{
        margin-bottom: 1vh !important;
    }

@media (min-width: 600px) {
    #global-tab .v-tabs-bar{
        width: 75% !important;
        left: 50%;
        -webkit-transform: translate(-50%, 0%); 
        transform: translate(-50%, 0%);
    }

    #global-tab .v-tabs-slider-wrapper{
        margin-bottom: 1vh !important;
    }
}

#global-tab div.theme--light.v-tabs-items{
    background-color: transparent !important;
}
.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  
</style>