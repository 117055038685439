<template>
    <v-container fluid>
        <v-data-table 
            :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'"
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
            locale="pt-pt"
        >

        <template v-slot:item.total="{item}">
            {{ $currency(item.total) }}
        </template>

        <template v-slot:item.cost="{item}" v-if="place == 'materials'">
            {{ materialCost(item) }}
        </template>

        <template v-slot:item.qty="{item}" v-if="place == 'materials'">
            {{ materialQty(item) }}
        </template>

        <template v-slot:item.cost_total="{item}" v-if="place == 'materials'">
            {{ $currency(materialQty(item) * materialCost(item)) }}
        </template>

        <template v-slot:item.actions="{item}">
            <v-row>
                <v-col cols="12" md="1"
                    v-if='$root.session.hasPermission(["super", "shopping.update"])'>
                    <IconView
                        small
                        @click="viewInvoice(item)"
                    />

                </v-col>
                <v-col cols="12" md="1">                    
                    <IconDownload
                    :text="$t('globals.download')"
                    v-if="item.invoice_path != null"
                    @click="download(item)"
                    />
                </v-col>
                
                <v-col cols="12" md="1">                  
                    <IconRemove
                    v-if="$root.session.hasPermission(['super', 'shopping.delete'])"
                    @on-submit="deleteInvoice(item)"
                    />
                </v-col>
            
            </v-row>
        </template>
        </v-data-table>
        <v-dialog
            v-model="invoiceListDialog"
            max-width="800px"
        >
            <Shopping method="update" :modalType="true" :shopping="selectedInvoice"/>
        </v-dialog>
        <SuccessSnackBar v-model="modalSuccess.show" :message="modalSuccess.message" />
    </v-container>
</template>
<script>
import ShoppingAPI from '@/api/Shopping.js'
import Shopping from '@/components/shopping/Shopping.vue'
import IconView from '@/components/ui/IconView.vue';
import IconDownload from '@/components/ui/IconDownload.vue';
import IconRemove from '@/components/ui/IconRemove.vue';
import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';

export default{
    components:{
        IconView,
        Shopping,
        IconRemove,
        IconDownload,
        SuccessSnackBar
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchInvoices()}, 10);
        },
        deep: true,
      },
    },
    props:{
        place: String
    },
    data(){
        return{
            modalSuccess:{
                show: false,
                message: ''
            },
            selectedInvoice: null,
            invoiceListDialog: false,
            loading: false,
            items: [],
            total: 0,
            options: {},
        }
    },
    mounted(){
        this.fillBaseData();
    },
    methods:{
        deleteInvoice(item){
            ShoppingAPI.delete(item.id)
                .then(() => {
                    this.modalSuccess.message = 'Fatura eliminada com sucesso'
                    this.modalSuccess.show = true
                    this.$emit('reload')
                    this.searchInvoices()
                });
        },
        download(item){
            ShoppingAPI.downloadInvoice(item.id).then((response) => {
                let documentName =  "Fatura";

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", documentName + '.pdf');
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        materialQty(item){
            let qty = ''
            item.materials.forEach(element => {
                if(element.material_id == this.$route.params.id)
                    qty = element.qty
            });
            return qty
        },
        materialCost(item){
            let cost = ''
            item.materials.forEach(element => {
                if(element.material_id == this.$route.params.id)
                    cost = element.unit_price
            });
            return cost
        },
        viewInvoice(item){
            this.selectedInvoice = item
            this.invoiceListDialog = true
        },
        fillBaseData(){
        },
        searchInvoices(event){
            if(!!event && event.type == 'submit'){
                this.options.page = 1;
            }
    
            this.loading = true;
    
            let filter = {...this.filter};
    
            let request = {
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                page: this.options.page,
                limit: this.options.itemsPerPage,
                supplier: this.place == 'companies' ? this.$route.params.id : null,
                material: this.place == 'materials' ? this.$route.params.id : null
            };
        
            Object.assign(request, filter);
    
            localStorage["shopping-invoices-" + this.$root.session.id] = JSON.stringify({
                filter: this.filter,
                options: this.options,
            });
    
            ShoppingAPI.search(request).then(response => {
    
                let data = response.data;
        
                this.total = data.total;
        
                this.items = data.data.map((item) => {
        
                    this.searching = false;
        
                    return item;
                });
        
                this.loading = false;
    
            });
        },
    },
    computed:{    
        headers(){
            let he = [
                {
                    text: this.$t('globals.data'),
                    value: 'shopping_date'
                },
                {
                    text: this.$t('globals.total'),
                    value: 'total'
                },
                {
                    text: this.$t('globals.options'),
                    value: 'actions'
                }
            ]

            if(this.place == 'materials'){
                he = [
                    {
                        text: this.$t('globals.data'),
                        value: 'shopping_date'
                    },              
                    {
                        text: this.$t('globals.quantity'),
                        value: 'qty'
                    },
                    {
                        text: this.$t('globals.unit_price'),
                        value: 'cost'
                    },  
                    {
                        text: this.$t('globals.total_cost'),
                        value: 'cost_total'
                    }, 
                    {
                        text: this.$t('globals.options'),
                        value: 'actions'
                    }
                ]
            }
            return he
        },
        tableFooter(){
          let t = this.$t("globals.lines_datatable")
          let a = [5, 10, 15, 20];
          return {
            'items-per-page-options': a,
            'items-per-page-text': t
          }
        }, 
    }
}
</script>