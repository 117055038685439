<template>
    <v-dialog
      v-model="dialog"
      persistent
      width="auto"
    >
        <v-card width="350" height="135" style="background-color: white; overflow: hidden;" elevation="0">
            <HashLoader
                class="mt-4"
                style="margin: auto;"
                :loading="true"
                color='#3C4F6F'
            />
            <v-row>
                <v-col cols="12">
                    <p class="text-center mt-4 mb-4">{{$t('global_messages.wait_a_moment')}}</p>
                    <p class="text-center mt-n4 mb-4"><small>{{ loadedMessage }}</small></p>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>

</template>
<script>
import { HashLoader } from '@saeris/vue-spinners'

export default{
    components: {
        HashLoader
    },
    data(){
        return{
            loadedMessage: '',
            dialog: true,
            loaderMessages:[
                'Estamos a trabalhar no que precisa',
                '"Se estás cansado, aprende a descansar, não desistas"',
                '"Tu és o teu próprio limite"',
                '"Não sejas um prisioneiro do teu passado"',
                'Apaixonados por desenvolver novas ideias! - Dreamdeal',
                'Dúvidas e/ou problemas? Consulte a plataforma Atlas',
                'Estamos quase a terminar o seu pedido',
                '"O futuro depende do que fizermos hoje"',
                '"A melhor forma de prever o futuro é criá-lo"'
            ]
        }
    },
    mounted(){
        this.loadedMessage = this.loaderMessages[0]

        setInterval(this.changeMessage, 5000);
    },
    methods:{
        changeMessage(){
            let n = Math.floor(Math.random() * 9);

            this.loadedMessage = this.loaderMessages[n];
        }
    }
}
</script>