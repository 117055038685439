<template>
    <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
      <v-container>
    <validation-observer ref="form" v-slot="{ invalid }">
      <v-form @submit.prevent="submit"  class="mb-16">
         <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
        <fieldset :disabled="loading">
          <v-row>
            <v-col cols="12" md="12">
              <validation-provider v-slot="{ errors }" vid="code" :name="$t('globals.code')" rules="required">
                <v-text-field
                  v-model="fields.code"
                  outlined
                  dense
                  prepend-inner-icon="mdi-code-array"
                  :label="$t('globals.code') + '*'"
                  clearable
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="12">
              <validation-provider v-slot="{ errors }" vid="description" :name="$t('globals.description')" rules="required">
                <v-textarea
                  dense
                  outlined
                  v-model="fields.description"
                  prepend-inner-icon="mdi-form-textbox"
                  :label="$t('globals.description') + '*'"
                  clearable
                  :error-messages="errors"
                ></v-textarea>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="auto">
              <v-btn small class="primary" @click="createClass = true;">
                {{$t('chapter_class.create_new')}}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                      <tr>
                          <th class="text-center">
                          {{$t('globals.description')}}
                          </th>
                          <th class="text-center">
                          {{$t('globals.options')}}
                          </th>
                      </tr>
                    </thead>
                    <tbody :class="$root.$vuetify.theme.isDark ?  'secondary' : ''">
                      <tr v-for="(element, index) in fields.chapter_classes" v-bind:key="index + 40000">
                          <td class="text-center">
                              <label v-if="!element.id.includes('new')">{{element.code == null ? element.description : element.code + ' - ' + element.description}}</label>
                              <v-autocomplete
                                v-else
                                v-model="element.chapter_id"
                                :items="chapterClasses"
                                :item-text="item => item.code == null ? item.description : item.code + ' - ' + item.description"
                                class="mt-4 mb-n4"
                                item-value="id"
                                menu-props="auto"
                                :label="$t('globals.description')"
                                prepend-inner-icon="mdi-account"
                            ></v-autocomplete>
                          </td>
                          <td class="text-center">
                            <IconRemove
                                @on-submit="deleteChapterClass(element)"
                            />
                          </td>
                      </tr>
                    </tbody>
                </template>
                </v-simple-table>
                <!-- DESKTOP VERSION -->
            </v-col>
            <v-col cols="12" align="center">
              <v-btn fab small class="success" @click="addLine">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-6" align="center" justify="space-around">
            <v-col align="center" justify="space-around">
              <v-btn :disabled="invalid" depressed :class="($root.$vuetify.theme.isDark ? 'white primary--text' : 'primary') + ' mr-5 ml-5 mb-5'" type="submit">
                {{$t('globals.save')}}
              </v-btn>
            </v-col>
          </v-row>
        </fieldset>
      </v-form>
      <DialogSuccess :opened="success" @on-ok="onSuccess" >
          {{$t('chapter.success_save')}}
      </DialogSuccess>
    </validation-observer>

    <v-dialog v-model="createClass"
      max-width="850">

      <v-card class="elevation-0">
        <v-card-title class="primary white--text">
          {{$t('chapter_class.create_new')}}
        </v-card-title>
        <v-card-text>
          <ChapterClass @close="createClass=false; fillBaseData()" :quick="true" class="elevation-0" method="create" />
        </v-card-text>
      </v-card>

    </v-dialog>
   
    </v-container>
    </v-card>
  </template>
  <script>
  import ChapterAPI from "@/api/Chapter.js";
  import ChapterClassAPI from "@/api/ChapterClass.js"
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  import IconRemove from '@/components/ui/IconRemove.vue';
  import ChapterClass from "@/components/chapter-class/ChapterClass.vue"
  
  export default {
    components: {
      DialogSuccess,
      IconRemove,
      ChapterClass
    },
    props: {
     chapter: Object,
     method: String,
     disabled: Boolean,
   },
   watch: { 
      chapter: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;
        }
      },
    },
    mounted(){
      this.fillBaseData()
    },
    data: () => ({
      addChapterClass: false,
      createClass: false,
      fields: {
        id: null,
        description: null,
        chapter_classes: [],
        code: null
      },
      chapterClasses:[],
      loading:false,
      error: {
        title: '',
        message: '',
      },
      success: false,
    }),
    methods:{
      deleteChapterClass(item){
        let pos = -1
        let i = 0;

        this.fields.chapter_classes.forEach(element => {
          if(element.chapter_id == item.chapter_id)
            pos = i

            i++
        });

        if(pos != -1){
          this.fields.chapter_classes.splice(pos, 1)
        }
      },
      addLine(){
        this.fields.chapter_classes.push({
          id: 'new-' + new Date().getTime(),
          description: null,
          newLine: true
        })
      },
      fillBaseData(){
        ChapterClassAPI.list().then(({data}) => {
            this.chapterClasses = data.map((item) => {
              if(item.chapter_id == null)
                return item
              
            });
        });
      },
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        let domain = '/' + this.$session.get('domain')
        this.$router.push(domain + '/admin/elements/chapters');
      },
      submit() {
  
        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }
  
          ChapterAPI[this.method](this.fields, this.fields.id).then(() => {
  
            this.success = true;
  
            this.loading = false;

            this.$emit('refresh')
            
          }).catch(err => {
            this.loading = false;
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = this.$t('globals.error') + " " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })
      }
    },
    computed: {
      
    }
  };
  </script>
  