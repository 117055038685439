<template>
    <v-row>
        <v-col cols="12">
        <div class="d-flex flex-row align-center">
            <h2 class="secondary--text subtitle-1">
            {{$t('globals.machines')}}
            </h2>
            <v-spacer />
        </div>
        <v-simple-table dense class="d-none d-lg-block">
            <template v-slot:default>
            <thead>
                <tr>
                <th class="text-center rounded-pill rounded-r-0" style="width: 35%;">
                    {{$t('globals.name')}}
                </th>
                <th class="text-center">
                    {{$t('globals.code')}}
                </th>
                <th class="text-center">
                    {{$t('globals.diesel')}}
                </th>
                <th class="text-center">
                    {{$t('diaries.hours_km_start')}}
                </th>
                <th class="text-center">
                    {{$t('diaries.hours_km_end')}}
                </th>
                <th class="text-center">
                    {{$t('diaries.stopped_in_order')}}
                </th>
                <th class="text-center rounded-pill rounded-l-0">
                    {{$t('globals.options')}}
                </th>
                </tr>
            </thead>
            <tbody :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                <tr v-for="machine in template_fields.machines" v-bind:key="machine.id">
                <td class="text-center">
                    <validation-provider v-slot="{ errors }" vid="machine" :name="$t('globals.machine')" rules="required">
                    <v-combobox
                        v-model="machine.object"
                        :label="$t('globals.machine') + ' *'"
                        :items="machines"
                        :item-text="item => item.code + ' - ' + item.name"
                        item-value="id"
                        hide-selected
                        :error-messages="errors"
                        @input="machine.code = $event.code; machine.id = $event.id; machine.name = $event.name; machine.started_hours = $event.next_start"
                    >
                    </v-combobox>
                    </validation-provider>
                </td>
                <td class="text-center">
                    {{ machine.code }}
                </td>
                <td class="text-center">
                    <validation-provider v-slot="{ errors }" vid="diesel" :name="$t('globals.diesel')" rules="required">
                    <v-text-field
                        v-model="machine.diesel"
                        :disabled="true"
                        :label="$t('globals.diesel') + ' *'"
                        :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                </td>
                <td class="text-center">
                    <validation-provider v-slot="{ errors }" vid="diesel" :name="$t('diaries.hours_km_start') " rules="required">
                    <v-text-field
                        v-model="machine.started_hours"
                        :disabled="true"
                        :label="$t('diaries.hours_km_start') + ' *'"
                        :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                </td>
                <td class="text-center">
                    <validation-provider v-slot="{ errors }" vid="diesel" :name="$t('diaries.hours_km_end')" rules="required">
                    <v-text-field
                        v-model="machine.finished_hours"
                        :disabled="true"
                        :label="$t('diaries.hours_km_end') + ' *'"
                        :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                </td>
                <td class="text-center">
                    <v-text-field
                    v-model="machine.stopped_reason"
                    :disabled="true"
                    label="Razão"
                    ></v-text-field>
                </td>
                <td class="text-center"> 
                    <v-col cols="12" md="6">
                        <v-btn
                        width="100px"
                        dark
                        small
                        color="secondary"
                        @click="error.show = true"
                        >
                        <small>Duplicar</small>
                        <v-icon small>mdi-plus-circle-multiple-outline</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="6" class="mt-n2">
                        <v-btn
                        width="100px"
                        dark
                        small
                        color="error"
                        @click="removeMachine(machine)"
                        >
                        <small>Eliminar</small>
                        <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                    </v-col>
                </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
        <v-data-iterator
            :items="template_fields.machines"
            item-key="id"
            :items-per-page="-1"
            hide-default-footer
            class="d-block d-lg-none"
        >
            <template v-slot:default="{ items }">
            <v-row>
                <v-col
                v-for="machine in items"
                :key="machine.id"
                cols="12"
                sm="6"
                md="4"
                lg="3"
                >
                <v-card>
                    <v-list
                    dense
                    >
                    <v-list-item>
                        <v-list-item-content>{{$t('globals.name')}}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <validation-provider v-slot="{ errors }" vid="machine" :name="$t('globals.machine')" rules="required">
                            <v-combobox
                            v-model="machine.object"
                            :label="$t('globals.machine') + ' *'"
                            :items="machines"
                            :item-text="item => item.name"
                            item-value="id"
                            hide-selected
                            :error-messages="errors"
                            @input="machine.code = $event.code; machine.id = $event.id; machine.name = $event.name"
                            >
                            </v-combobox>
                        </validation-provider>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>{{$t('globals.code')}}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        {{ machine.code }}
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>{{$t('globals.diesel')}}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <validation-provider v-slot="{ errors }" vid="diesel" :name="$t('globals.diesel')" rules="required">
                            <v-text-field
                            v-model="machine.diesel"
                            :label="$t('globals.diesel') + ' *'"
                            :error-messages="errors"
                            ></v-text-field>
                        </validation-provider>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>{{$t('diaries.hours_km_start')}}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <validation-provider v-slot="{ errors }" vid="diesel" :name="$t('diaries.hours_km_start')" rules="required">
                            <v-text-field
                            v-model="machine.started_hours"
                            :label="$t('diaries.hours_km_start') + ' *'"
                            :error-messages="errors"
                            ></v-text-field>
                        </validation-provider>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>{{$t('diaries.hours_km_end')}}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <validation-provider v-slot="{ errors }" vid="diesel" :name="$t('diaries.hours_km_end')" rules="required">
                            <v-text-field
                            v-model="machine.finished_hours"
                            :label="$t('diaries.hours_km_end') + ' *'"
                            :error-messages="errors"
                            ></v-text-field>
                        </validation-provider>
                        </v-list-item-content>
                    </v-list-item>                                
                    <v-list-item>
                        <v-list-item-content>{{$t('diaries.stopped_in_order')}}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <v-text-field
                            v-model="machine.stopped_reason"
                            label="Razão"
                        ></v-text-field>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="true">
                        <v-list-item-content>{{$t('globals.options')}}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <v-row>
                            <v-col cols="12" md="6">
                            <v-btn
                                dark
                                small
                                block
                                color="secondary"
                                @click="error.show = true"
                            >
                                <v-icon small>mdi-plus-circle-multiple-outline</v-icon>
                            </v-btn>
                            </v-col>
                            <v-col cols="12" md="6">
                            <v-btn
                                dark
                                small
                                block
                                color="error"
                                @click="removeMachine(machine)"
                            >
                                <v-icon small>mdi-delete</v-icon>
                            </v-btn>
                            </v-col>
                        </v-row>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list>
                </v-card>
                </v-col>
            </v-row>
            </template>
        </v-data-iterator>
        <v-row justify="center">
            <v-btn
            fab
            dark
            small
            color="primary"
            class="mt-7"
            @click="addMachine"
            >
            <v-icon small>mdi-plus</v-icon>
            </v-btn>
        </v-row>
    </v-col>
        <ErrorSnackbar v-model="error.show" :message="error.message"/>
        <DialogConfirmation 
            v-if="openConfirmationDialog" 
            title="Remover Item" 
            type="warning" 
            :opened="openConfirmationDialog" 
            @on-submit="submitRemove" 
            @on-cancel="openConfirmationDialog = false">
                {{$t('global_messages.are_you_sure_remove')}}
        </DialogConfirmation>
    </v-row>
</template>
<script>
import dayjs from 'dayjs'
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
import DialogConfirmation from '@/components/ui/DialogConfirmation.vue';

export default {
    components:{
        DialogConfirmation,
        ErrorSnackbar
    },
    props: {
        template_fields: Object,
        machines: Array
    },
    data: () => ({
        openConfirmationDialog: false,
        error:{
            show: false,
            message: 'Ação indisponível em template'
        },
        toSend:{
            id: null
        }
    }),
    mounted(){
        
    },
    methods:{
        addMachine() {
            this.template_fields.machines.push({
                id: dayjs().unix(),
                name: "",
                code: "-",
                diesel: 0,
                started_hours: 0,
                finished_hours: 0,
                stopped_reason: "",
                worked_hours: []
            });
        },
        removeMachine(machineObject) {
            this.toSend.id = machineObject.id
            this.openConfirmationDialog = true
        },
        async submitRemove(){
            await this.$emit('remove', this.toSend.id)
            this.openConfirmationDialog = false
        }
    }
}
</script>