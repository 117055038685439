<template>
    <section class="mt-16">
      <v-breadcrumbs
          v-if="this.$root.$vuetify.theme.isDark"
          large
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
        <v-breadcrumbs
          v-else
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-hard-hat</v-icon> <span>{{$t('work.auto.crumb_edit')}}</span>
        </h1>
  
        <div class="mt-16">
          <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
  
          <template v-if="loading">
            <v-row>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row class="mt-6" align="center" justify="space-around">
              <v-skeleton-loader
                  type="button"
                ></v-skeleton-loader>
            </v-row>
          </template>
          <SelfMeasuring v-else method="update" :selfMeasure="selfMeasure" @refresh="refresh" :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'">
        </SelfMeasuring>
        </div>
    </section>
  </template>
  <script>
  import SelfMeasuring from '@/components/self_measuring/SelfMeasuring'
  import SelfMeasuringAPI from "@/api/SelfMeasuring.js";
  
  export default {
    components: {
        SelfMeasuring
    },
    beforeCreate(){
      document.title = "Report Lake - " + this.$t('work.auto.crumb_edit');
    },
    data: () => ({
        selfMeasure: {},
        loading:true
    }),
    mounted(){
      if(!this.$root.session.hasPermission(["super", "self_measuring.update"])) {
        this.$router.push('/admin');
      }
  
      SelfMeasuringAPI.find(this.$route.params.id).then(({data}) => { this.selfMeasure = data; this.loading = false;});
    },
    methods:{
      refresh(){
        SelfMeasuringAPI.find(this.$route.params.id).then(({data}) => { this.selfMeasure = data; this.loading = false;});
      }
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: this.$t('menu.dashboard'),
            disabled: false,
            to: '/' + this.$session.get('domain') + '/admin',
            exact: true,
          },
          {
            text: this.$t('menu.invoiced'),
            disabled: true,
            to: '',
            exact: true,
          },
          {
            text: this.$t('work.auto.crumb_edit'),
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  