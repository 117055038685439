<template>
  <v-theme-provider root>
    <v-app>
        
      
        <v-navigation-drawer
          class="text-white"
          :color="getComputedColor"
          style="z-index: 100;"
          app
          :mini-variant="showMini"
          v-model="showMenu"
          width="300"
        >
        <v-list nav
        >
        <v-badge
        v-if="false"
          bordered
          @click.native="openMenu"
          color="error"
          :icon="!showMini ? 'mdi-arrow-expand-left' : 'mdi-arrow-expand-right'"
          overlap 
          tile
          class="d-none d-lg-block mt-0 mr-2"
          style="position: absolute; right: 0; z-index: 9999;"
        >
        </v-badge>
          <v-container class="fill-height fill-width" fluid 
              v-if="!showMini">
              <v-img 
                src="@/assets/icons/logo negativo.png"
                :aspect-ratio="16/9"
                style="
                      display: block;
                      margin-left: auto;
                      margin-right: auto;
                      zoom: 65%;"
                contain
                max-height="100px"
                width="100%"
              />
          </v-container>
          <v-container class="fill-height fill-width" fluid 
              v-else>
              <v-avatar
                size="35px"
                class="ml-n2 mt-2"
              >
                <img
                  alt="Avatar"
                  src="@/assets/icons/fav/favicons-28.png"
                >
              </v-avatar>  
          </v-container>
          <v-divider class="mb-2" color="white" v-if="logo_url != null"></v-divider>
            <v-row>
              <v-col cols="12">              
                <div style="color: white !important;" :class="!showMini ? 'text-center mb-n4' : 'text-center mb-n2 ml-n2'">
                  <v-btn icon link :to="'/' + $session.get('domain') + '/admin/notifications'">
                    <div>
                      <v-badge :content="notifications" :value="notifications" color="error" bottom overlap>
                      <v-avatar
                        class="ml-1"
                        :size="!showMini ? '30px' : '25px'"
                      >
                        <img
                          alt="Avatar"
                          src="@/assets/icons/icons/png/icons_Prancheta 1-15.png"
                        >
                      </v-avatar>  
                      </v-badge>            
                      <span v-if="!showMini" class="ml-6 font-weight-bold" style="color: white !important;">{{ this.$root.session == undefined ? '' : this.$root.session.username }}</span>
                    </div>
                  </v-btn>
                    <p class="mt-n6"><br/><span v-if="!showMini" class="text-overline" style="font-size:8px !important;">({{ this.$root.session == undefined ? '' : this.$root.session.email }})</span></p>
                </div>
              </v-col>
              
            </v-row>
  
          <v-divider color="white"></v-divider>
          <template v-if="loading">
            <v-skeleton-loader
              v-for="i in items.length"
              v-bind:key="i"
              type="list-item"
            ></v-skeleton-loader>
          </template>
          <template v-for="item in items" v-else>
                <v-list-item :style="'background-color: ' + getColor(item) + ';'" link :to="item.link" :exact="item.exact" :key="item.title" v-if="item.items == 0">
                    <v-list-item-icon>
                      <rp-icon :icon="item.icon" />
                    </v-list-item-icon>
                    <v-list-item-title style="color: white">{{ item.title }}</v-list-item-title>
                </v-list-item> 
                <v-list-group :style="'background-color: ' + getColor(item) + ';'" :group="item.group" :key="item.title" v-else>
                  <template v-slot:activator>
                    <v-list-item-icon>
                        <!-- <v-icon>{{ item.icon }}</v-icon>-->
                        <rp-icon :icon="item.icon" />
                      </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.title" style="color: white"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  
                  <template v-for="child in item.items">
                        <v-list-item :style="'background-color: ' + getColor(child) + ';'" link :to="child.link" :exact="child.exact" :key="child.title" v-if="child.has_permission">
                          <v-list-item-icon>
                            <rp-icon :icon="child.icon" />
                          </v-list-item-icon>
                          <v-list-item-title v-text="child.title" style="color: white"></v-list-item-title>
                      </v-list-item> 
                      </template>
                </v-list-group> 
          </template>
          <v-divider color="white"></v-divider>
              <v-list-item :style="'background-color: ' + getColor({color: 'primary'}) + ';'" class="fixedBottom" @click="$refs.Tickets.openDialog()">
                  <v-list-item-icon>
                    <rp-icon icon="rp-ticket-gray-outlined"></rp-icon>
                  </v-list-item-icon>
                  <v-list-item-title style="color: white;">{{$t('menu.ticket')}}</v-list-item-title>
              </v-list-item> 
          <div v-if="!loading">
              <v-list-item :style="'background-color: ' + getColor({color: 'secondary'}) + ';'" class="fixedBottom" :to="'/' + $session.get('domain') + '/admin/how-to-do'">
                  <v-list-item-icon>
                    <rp-icon icon="rp-info-gray-outlined"></rp-icon>
                  </v-list-item-icon>
                  <v-list-item-title style="color: white;">{{$t('menu.how_to_do')}}</v-list-item-title>
              </v-list-item> 
        </div>
        </v-list>
  
  
  
        
        
        <template v-slot:append>
          <div v-if="!loading">
          <v-list>
            <v-divider color="white" v-if="showMini"></v-divider>
            <v-list-item>            
                <v-btn icon class="ml-2" style="left: 0; position: absolute;" :to="'/' + $session.get('domain') + '/admin/settings/general'">
                  <rp-icon icon="rp-cog-gray-filled"></rp-icon>
                </v-btn>
                <v-spacer v-if="!showMini"></v-spacer>
                <v-btn v-if="!showMini" @click="logout" icon class="mr-2" style="right: 0; position: absolute;">
                  <rp-icon icon="rp-logout-gray-outlined"></rp-icon>
                </v-btn>
            </v-list-item>
            
            <v-list-item v-if="showMini" :style="'background-color: ' + getColor({color: 'primary'}) + ';'">            
                <v-btn @click="logout" icon class="ml-2" style="left: 0; position: absolute;">
                  <rp-icon icon="rp-logout-gray-outlined"></rp-icon>
                </v-btn>
            </v-list-item>
  
  
          </v-list>
        </div>
        </template>
          
        
  
      </v-navigation-drawer>
      
  
      <CreateTicket v-if="releaseTickets" ref="Tickets"/> 
  
      <v-alert
        v-if="layoutAlerts.maximumWorkers"
        border="top"
        color="red lighten-2 text-center"
        class="d-none d-lg-block mt-6"
        icon="mdi-alert"
        style="z-index: 9999; left: 50%; width: 50%; position: fixed; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);"
        dark
      >
        <div class="text-body-2">Chegou ao limite do número de colaboradores para a sua subscrição. Dentro de 2 dias irá ser ativa uma nova subscrição</div>
      </v-alert>
      <v-alert
        v-if="layoutAlerts.maximumWorkers"
        border="top"
        color="red lighten-2 text-center"
        class="d-block d-lg-none mt-11"
        icon="mdi-alert"
        style="z-index: 99; left: 50%; width: 100%; position: fixed; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);"
        dark
      >
        <div class="text-body-2">Chegou ao limite do número de colaboradores para a sua subscrição. Dentro de 2 dias irá ser ativa uma nova subscrição</div>
      </v-alert>
  
  
      <v-main :class="this.$root.$vuetify.theme.isDark ? 'background-main-image-dark' : 'background-main-image'">
        <v-btn
            class="mt-2 ml-1 floating-button primary"
            style="z-index: 99;"
            @click="openMenu"
            dark
            top
            left
            fab
          >
          <v-app-bar-nav-icon></v-app-bar-nav-icon>
        </v-btn>
        <v-progress-linear v-if="loading"
        indeterminate
        class="global-loader"
      ></v-progress-linear>
        <v-container v-on="$listeners" fluid v-if="!loading">
            <v-row class="pa-0 ma-0">
                <v-col offset="0" cols="12" offset-lg="1" lg="10">
                    <router-view @read-notifications="notifications = 0"></router-view>
                </v-col>
            </v-row>
        </v-container>
        <Cookie :snackbar="showWindowCookie" @cookie-accept="cookieAccept" @cookie-reject="cookieReject" />
      </v-main>
      <v-footer padless>
     
        <v-col
          class="text-center"
          cols="12"
        >
          {{ new Date().getFullYear() }} — <strong :class="this.$root.$vuetify.theme.isDark ? 'white--text' : 'primary--text'">Report Lake V{{getVersion()}}</strong>
        </v-col>
      </v-footer>
    </v-app>
  </v-theme-provider>
  </template>
  <script>
  import User from "@/api/User.js"
  import Notification from "@/api/Notification.js"
  import Configurations from "@/api/Configurations.js"
  import CreateTicket from "@/components/tickets/CreateTicket"
  import Cookie from "@/components/cookies/Cookie.vue"
  
  export default {
    components: {
      CreateTicket,
      Cookie
    },
    watch: {
      showMenu: {
        immediate: false,
        handler (val) {
           localStorage['menu-show'] = val;
        },
      },
      showMini: {
        immediate: false,
        handler (val) {
           localStorage['menu-mini'] = val;
        },
      },
      $route: {
          immediate: true,
          handler() {
            if(this.$root.session == undefined){
              return
            }
  
            let theme = this.$root.session.theme;
  
            theme = theme.length == 0 ? this.$themes[0] : theme
            
            this.setTheme(theme);
  
            this.setCssVars();
          }
      },
    },
    data: () => ({
        dialog: true,
        showCookieBar: false,
        logo_url: null,
        releaseTickets: false,
        notifications: null,
        newNotification: false,
        showMenu: false,
        showMini: false,
        isRender: true,
        configuration: {},
        user: {},
        items: [],
        loading: true,
        groupSelectedColor: {},
        admins: [
          ['Management', 'mdi-account-multiple-outline'],
          ['Settings', 'mdi-cog-outline'],
        ],
        cruds: [
          ['Create', 'mdi-plus-outline'],
          ['Read', 'mdi-file-outline'],
          ['Update', 'mdi-update'],
          ['Delete', 'mdi-delete'],
        ],
        layoutAlerts:{
          maximumWorkers: false
        },
        applicationData:{
          coin: '€'
        }

    }),
    mounted(){
      let cookie = this.$cookies.get('reportlake_portal_cookie');
      //this.$cookies.set('reportlake_portal_cookie', JSON.stringify(cookie), '1s');

      if(cookie == null)
        this.showCookieBar = true
    },
    async beforeMount(){
      this.$vuetify.theme.dark = +localStorage['dark-mode'];
  
      await User.getSession(false).then(async ({data}) =>  {
        this.user = User.getInstance(data);
  
        let theme = data.theme;
  
        theme = theme.length == 0 ? this.$themes[0] : theme
  
        this.setTheme(theme);
  
        this.$root.session = this.user;
        this.$session.start()
        this.$session.set('domain', data.domain)
  
        
        this.listNotifications()
  
        await this.loadConfigurations()
  
        this.releaseTickets = true
  
        
        if(this.user.valid_subscription == false){
          this.$router.push(this.user.domain + '/invalid')
          return
        }
  
        if(!location.pathname.includes(data.domain))
          if(!location.pathname.includes('my-user'))
            this.$router.push(data.domain + '/admin')
  
        localStorage['auth'] = 1;
  
        this.loading = false;
  
        this.setCssVars();
  
      }).catch((error) => {
  
        console.error(error);
  
        localStorage['auth'] = 0;
  
        this.$router.push('/');
      });
  
      
  
      this.showMini = localStorage['menu-mini'] == "true";
  
      let showMenu = localStorage['menu-show'];
  
      this.showMenu = showMenu != undefined ? showMenu == "true" : true;
  
      this.showMenu = this.showMenu || !this.$vuetify.breakpoint.mdAndDown;
    },
    computed:{ 
      showWindowCookie(){
        return this.showCookieBar
      },
      getComputedColor(){
        return this.$root.$vuetify.theme.isDark ? this.$root.$vuetify.theme.themes.dark.primary : this.$root.$vuetify.theme.themes.light.primary
      },
      getEmail(){
        if(this.$root.session == undefined)
          return ''
        return this.$root.session.email
      },
      getUsername(){
        if(this.$root.session == undefined)
          return ''
        return this.$root.session.username
      },
      getDomain(){
        return this.$session.get('domain')
      }
    },
    methods:{
      cookieReject(){
        this.$cookies.set('reportlake_portal_cookie', null, '1s');
        this.showCookieBar = false
      },
      cookieAccept(ob){
        this.$cookies.set('reportlake_portal_cookie', JSON.stringify(ob), '7d');
        this.showCookieBar = false
      },
      getColor(item){
        return this.$root.$vuetify.theme.isDark ? this.$vuetify.theme.themes.dark[item.color] : this.$vuetify.theme.themes.light[item.color]
      },
      checkPermissions(p){
        return this.$root.session.hasPermission(p)
      },
      async loadConfigurations(){
        await Configurations.get(this.$session.get('domain')).then((resp) => {
       
          let data = resp.data.data
          if(data.logo != null)
                this.logo_url = data.logo.replace(/([^:])(\/\/+)/g, '$1/'); //process.env.VUE_APP_API_URL + data.logo.replace(/([^:])(\/\/+)/g, '$1/');
  
          this.configuration = Configurations.getInstance(data)
  
          this.layoutAlerts.maximumWorkers = resp.data.alert_workers_maximum

          this.$store.state.coin = resp.data.coin

          this.$store.state.language = resp.data.language

          this.$store.state.company_configurations = resp.data

          this.$i18n.locale = this.$store.state.language.toLowerCase()
  
          this.$root.configurations = this.configuration

          if(resp.data.trial_over)
            this.$router.push('/' + this.$session.get('domain') + '/trial-over')
  
          this.setMenu();
  
  
        }).catch((error) => {
  
          console.error(error);
  
          localStorage['auth'] = 0;
  
          this.$router.push('/');
        });
      },
      getVersion(){
              return process.env.VUE_APP_SECRET_VERSION
      },
      listNotifications() {
        Notification.list(1, 0, this.$session.get('domain')).then(({data}) => {
            this.notifications = data.length
        });
      },
      setCssVars(){
        let color = this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.primaryLight
            : this.$vuetify.theme.themes.light.primaryLight;
  
        this.groupSelectedColor = {
          '--group-selected': color,
        };
      },
      toggleTheme(){
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
  
        localStorage['dark-mode'] = Number(this.$vuetify.theme.dark);
      },
      logout() {
        User.logout(this.$root.session.id)
          .then(() => {
            localStorage['auth'] = 0;
  
            this.$router.push('/');
          });
      },
      openMenu(){
  
        if(!this.$vuetify.breakpoint.mdAndDown){
  
          this.showMini = !this.showMini;
  
          return;
        }
  
        this.showMini = false;
  
        this.showMenu = !this.showMenu;
      },
      setMenu() {
        let domain = this.$session.get('domain')
        let menu = [{
          title: this.$t('menu.dashboard'),
          icon: "rp-dashboard-gray-outlined",
          color: 'secondary',
          has_permission: this.$root.session.hasPermission(["super", "works.read", "diaries.read"]),
          exact: true,
          link: "/" + domain + "/admin",
          items:[],
        },
        {
          title: this.$t('menu.profile'),
          icon: "rp-user-gray-outlined",
          color: 'primary',
          has_permission: true,
          link: '/admin/my-user/',
          exact: false,
          items:[],
        },
        {
          title: this.$t('menu.articulado'),
          icon: "rp-diagram-gray-outlined",
          color: 'primary',
          has_permission: true,
          group: "/" + domain + "/admin/elements",
          link: '',
          exact: false,
          items:[
            {
              title: this.$t('menu.chapter'),
              icon: "rp-folder-gray-outlined",
              color: 'primary',
              has_permission: true,
              link: '/' + domain + '/admin/elements/chapters/',
              exact: false,
              items:[],
            },
            {
              title: this.$t('menu.chapter_class'),
              icon: "rp-shield-gray-outlined",
              color: 'primary',
              has_permission: true,
              link: '/' + domain + '/admin/elements/chapters-class/',
              exact: false,
              items:[],
            },            
            {
              title: this.$t('menu.work_units_management'),
              icon: "rp-barcode-gray-outlined",
              has_permission: this.$root.session.hasPermission(["super", "units.read"]),
              exact: false,
              link: "/" + domain + "/admin/elements/units",
              items:[],
            },
          ],
        },
        {
          title: this.$t('menu.invoiced'),
          icon: "rp-obras-gray-outlined",
          color: 'secondary',
          has_permission: this.$root.session.hasPermission(["super", "faturacao_menu.read"]),
          exact: false,
          group: "/" + domain + "/admin/invoiced",
          link: "",
          items:[
            {
              title: this.$t('menu.invoiced'),
              icon: "rp-obras-gray-outlined",
              has_permission: this.$root.session.hasPermission(["super", "faturacao_menu.read"]),
              exact: false,
              link: "/" + domain + "/admin/invoiced",
              items:[],
            },
            {
              title: this.$t('menu.costs'),
              icon: "rp-money-gray-filled",
              has_permission: this.$root.configurations.hasFeature(["custos.obra"]) && this.$root.session.hasPermission(["super", "costs.read"]),
              exact: false,
              link: "/" + domain + "/admin/invoiced/costs",
              items:[],
            },
            {
              title: this.$t('menu.self_measuring'),
              icon: "rp-money-gray-filled",
              has_permission: this.$root.session.hasPermission(["super", "self_measuring.read"]),
              exact: false,
              link: "/" + domain + "/admin/invoiced/self-measurings",
              items:[],
            },
          ]
        },
        {
          title: this.$t('menu.works'),
          icon: "rp-obras-gray-outlined",
          color: 'secondary',
          has_permission: this.$root.session.hasPermission(["super", "works.read", "machines.read"]),
          exact: false,
          group: "/" + domain + "/admin/works-settings",
          link: "",
          items:[
            {
              title: this.$t('menu.works_management'),
              icon: "rp-obras-gray-outlined",
              has_permission: this.$root.session.hasPermission(["super", "works.read"]),
              exact: false,
              link: "/" + domain + "/admin/works-settings/works",
              items:[],
            },
            {
              title: this.$t('menu.subcontractors_management'),
              icon: "rp-obras-gray-outlined",
              has_permission: this.$root.session.hasPermission(["super", "subempreiteiro.read"]),
              exact: false,
              link: "/" + domain + "/admin/works-settings/subempreiteiros",
              items:[],
            },
            {
              title: this.$t('menu.machines_management'),
              icon: "rp-truck-gray-outlined",
              has_permission: this.$root.session.hasPermission(["super", "machines.read"]),
              exact: false,
              link: "/" + domain + "/admin/works-settings/machines",
              items:[],
            },
          ],
        },
        {
          title: this.$t('menu.daily_parts_management'),
          icon: "rp-partes-diarias-gray-outlined",
          color: 'primary',
          has_permission: this.$root.session.hasPermission(["super", "diaries.read"]),
          exact: true,
          link: "/" + domain + "/admin/diaries",
          items:[],
        },
        {
          title: this.$t('menu.shopping'),
          icon: "rp-barcode-gray-outlined",
          color: 'secondary',
          has_permission: this.$root.session.hasPermission(["super", "shopping.read"]),
          exact: true,
          link: "/" + domain + "/admin/shopping",
          items:[]
        },
        {
          title: this.$t('menu.material_request'),
          icon: "rp-truck-gray-outlined",
          color: 'primary',
          has_permission: this.$root.session.hasPermission(["super", "shopping_request.read"]),
          exact: true,
          link: "/" + domain + "/admin/shopping-request",
          items:[]
        },
        {
          title: this.$t('menu.budgeting'),
          icon: "rp-partes-diarias-gray-outlined",
          color: 'secondary',
          has_permission: this.$root.session.hasPermission(["super", "budget_work.read", "budget_work.create", "materials_composite.read", "materials_composite.create", "materials.read", "materials.create", "indirect_work_hand.create", "indirect_work_hand.read", "units_management.create", "units_management.read"]),
          exact: true,
          link: "/" + domain + "/admin/budgeting",
          items:[
            {
              title: this.$t('budgeting.crumb_management'),
              icon: "rp-obras-gray-outlined",
              has_permission: this.$root.session.hasPermission(["super", "budget_work.read", "budget_work.create"]),
              exact: false,
              link: "/" + domain + "/admin/budgeting/works",
              items:[],
            },
            {
              title: this.$t('menu.material_composite_management'),
              icon: "rp-diagram-gray-outlined",
              has_permission: this.$root.session.hasPermission(["super", "materials_composite.read", "materials_composite.create"]),
              exact: false,
              link: "/" + domain + "/admin/budgeting/materials-composite",
              items:[],
            },
            {
              title: this.$t('menu.material_management'),
              icon: "rp-empresas-gray-outlined",
              has_permission: this.$root.session.hasPermission(["super", "materials.read", "materials.create"]),
              exact: false,
              link: "/" + domain + "/admin/budgeting/materials",
              items:[],
            },
            {
              title: this.$t('menu.indirect_work_hand_management'),
              icon: "rp-colaboradores-gray-outlined",
              has_permission: this.$root.session.hasPermission(["super", "indirect_work_hand.read", "indirect_work_hand.create"]),
              exact: false,
              link: "/" + domain + "/admin/budgeting/indirect_work_hand",
              items:[],
            },
            {
              title: this.$t('menu.units_measure_management'),
              icon: "rp-graphic-gray-outlined",
              has_permission: this.$root.session.hasPermission(["super", "units_management.read", "units_management.create"]),
              exact: false,
              link: "/" + domain + "/admin/budgeting/units_management",
              items:[],
            },
          ],
        },
        {
          title: this.$t('menu.suppliers_management'),
          icon: "rp-empresas-gray-outlined",
          color: 'primary',
          has_permission: this.$root.session.hasPermission(["super", "companies.read"]),
          exact: true,
          link: "/" + domain + "/admin/companies",
          items:[],
        },
        {
          title: this.$t('menu.clients_management'),
          icon: "rp-clientes-gray-outlined",
          color: 'secondary',
          has_permission: this.$root.session.hasPermission(["super", "clients.read"]),
          exact: true,
          link: "/" + domain + "/admin/clients",
          items:[],
        },
        {
          title: this.$t('menu.exports'),
          icon: "rp-exportar-gray-outlined",
          color: 'primary',
          has_permission: this.$root.session.hasPermission(["super", "export.read"]),
          exact: true,
          link: "/" + domain + "/admin/exports",
          items:[],
        },
        /*{
          title: "Acessos",
          icon: "rp-folder-gray-outlined",
          has_permission: this.$root.session.hasPermission(["super", "users.read", "roles.read"]),
          exact: false,
          group:"/" + domain + "/admin/accesses",
          items:[
            {
              title: "Gestão de Utilizadores",
              icon: "rp-colaboradores-gray-outlined",
              has_permission: this.$root.session.hasPermission(["super", "users.read"]),
              exact: false,
              link: "/" + this.$session.get("domain") + "/admin/accesses/users",
              items:[],
            },
            {
              title: "Gestão de Roles",
              icon: "rp-diagram-gray-outlined",
              has_permission: this.$root.session.hasPermission(["super", "roles.read"]),
              exact: false,
              link: "/" + this.$session.get("domain") + "/admin/accesses/roles",
              items:[],
            }
          ]
        },*/
        {
          title: this.$t('menu.workers'),
          icon: "rp-colaboradores-gray-outlined",
          color: 'secondary',
          has_permission: this.$root.session.hasPermission(["super", "workers.read"]),
          exact: false,
          group:"/" + domain + "/admin/workers-settings",
          items:[
            {
              title: this.$t('menu.workers_management'),
              icon: "rp-colaboradores-gray-outlined",
              has_permission: this.$root.session.hasPermission(["super", "workers.read", "categories.read"]),
              exact: false,
              link: "/" + domain + "/admin/workers-settings/workers",
              items:[],
            },
            {
              title: this.$t('menu.category_management'),
              icon: "rp-category-gray-outlined",
              has_permission: this.$root.session.hasPermission(["super", "categories.read"]),
              exact: true,
              link: "/" + domain + "/admin/workers-settings/categories",
              items:[],
            },
            {
              title: this.$t('menu.lunch_management'),
              icon: "rp-apple-gray-outlined",
              has_permission: this.$root.session.hasPermission(["super", "lunch_hours.read"]),
              exact: true,
              link: "/" + domain + "/admin/workers-settings/lunch-hour",
              items:[],
            },
            {
              title: this.$t('menu.holidays_management'),
              icon: "rp-calendar-gray-outlined",
              has_permission: this.$root.session.hasPermission(["super", "holidays.read"]),
              exact: true,
              link: "/" + domain + "/admin/workers-settings/holidays",
              items:[],
            },
            {
              title: this.$t('menu.faults_management'),
              icon: "rp-alert-gray-outlined",
              has_permission: this.$root.session.hasPermission(["super", "faults.read"]),
              exact: true,
              link: "/" + domain + "/admin/workers-settings/faults",
              items:[],
            }
          ],
        },
        /*{
          title: "Definições",
          icon: "rp-cog-gray-outlined",
          has_permission: this.$root.session.hasPermission(["super", "roles.read", "users.read", "logs.read"]),
          link: "",
          group:"/" + domain + "/admin/settings",
          exact: false,
          items:[
            {
              title: "Alertas",
              icon: "rp-alert-gray-outlined",
              has_permission: this.$root.session.hasPermission(["super", "alerts_configuration.read"]),
              link: "/" + domain + "/admin/settings/alerts",
              exact: false,
              items:[],
            },
            {
              title: "Refeições",
              icon: "rp-apple-gray-outlined",
              has_permission: this.$root.session.hasPermission(["super", "meals_configuration.read"]),
              link: "/" + domain + "/admin/settings/meals",
              exact: false,
              items:[],
            },
            {
              title: "Branding",
              icon: "rp-star-gray-filled",
              has_permission: this.$root.session.hasPermission(["super", "branding.read"]),
              link: "/" + domain + "/admin/settings/branding",
              exact: false,
              items:[],
            },
            {
              title: "Horários",
              icon: "rp-clock-gray-outlined",
              has_permission: true,
              link: "/" + domain + "/admin/settings/schedule",
              exact: false,
              items:[],
            },
            /*{
              title: "Gestão de Roles",
              icon: "mdi-shield-account",
              has_permission: this.$root.session.hasPermission(["super", "roles.read"]),
              link: "/" + domain + "/admin/settings/roles",
              exact: false,
              items:[],
            },
            {
              title: "Gestão de Utilizadores",
              icon: "mdi-account-multiple",
              has_permission: this.$root.session.hasPermission(["super", "users.read"]),
              link: "/" + domain + "/admin/settings/users",
              exact: false,
              items:[],
            },
            {
              title: "Logs",
              icon: "mdi-paperclip",
              link: "/" + domain + "/admin/settings/logs",
              has_permission: this.$root.session.hasPermission(["super", "logs.read"]),
              exact: false,
              items:[],
            },
            {
              title: "Configurações",
              icon: "mdi-earth",
              link: "/" + domain + "/admin/settings/global_configurations",
              has_permission: this.$root.session.hasPermission(["super", "configurations.read"]),
              exact: false,
              items:[],
            }
          ],
        }*/
      ];
        let fixed_array = []
        let index = 0
        menu.forEach(element => {
          if(element.has_permission){
            if(index == 0){
              index = 1
              element.color = 'primary'
              fixed_array.push(element)
            }else{
              index = 0
              element.color = 'secondary'
              fixed_array.push(element)
            }

            
            
            element.items.forEach(child => {              
              child.color = element.color
            });

            
          }
        });
  
        this.items = fixed_array.filter((item) => {
          
          return item.has_permission;
        })
      }
    },
  };
  </script>
  <style scoped>
    .floating-button{
      position: fixed !important;
      top: 0 !important;
    }
    .background-main-image{
      background: url( 'assets/background/background-30.jpg') no-repeat;
      width: -webkit-fill-available;
      height: -webkit-fill-available;
      background-size: cover;
    }
    .background-main-image-dark{
      background: url( 'assets/background/background-black.jpg') no-repeat;

      
      width: -webkit-fill-available;
      height: -webkit-fill-available;
      background-size: cover;
    }
    .v-list-group--active{
      background-color: var(--group-selected);
      border-radius: 4px;
    }
    .fixedBottom {
      position: static !important;
      bottom: 0 !important;
      width: 100%;
    }
    .center {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }
  </style>