import BaseApi from "./BaseApi";

export default class Billings extends BaseApi{
    construct() {}

    static async search(params) {
        return await Billings.getAxiosInstance().get(Billings.getFullApiUrl(),{params: params});
    }

    static async getPlans() {
        return await Billings.getAxiosInstance().get(Billings.getFullApiUrl() + 'get-plans');
    }

    static async find(id) {
        return await Billings.getAxiosInstance().get(Billings.getFullApiUrl() + id);
    }

    static async getUnpaid() {
        return await Billings.getAxiosInstance().get(Billings.getFullApiUrl() + 'get-unpaid');
    }

    static async requestPayment(params) {
        return await Billings.getAxiosInstance().post(Billings.getFullApiUrl() + 'payment-request' ,params);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/billings/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/billings/";
    }
}