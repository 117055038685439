var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:_vm.$root.$vuetify.theme.isDark ? 'secondary' : ''},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('fieldset',[_c('v-form',{attrs:{"disabled":_vm.loading},on:{"submit":function($event){$event.preventDefault();_vm.editClient == null ? _vm.addClient() : _vm.updateClient()}}},[_c('v-card-title',{class:_vm.$root.$vuetify.theme.isDark ? 'white--text subtitle-1 primary' : 'white--text subtitle-1 primary'},[_vm._v(" "+_vm._s(_vm.$t('client.add_crumb'))+" ")]),_c('v-card-text',[_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":function($event){_vm.success = false; _vm.$emit('close-update');}}},[_vm._v(" "+_vm._s(_vm.$t('client.success_add'))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"reference","name":_vm.$t('globals.reference'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-barcode","label":_vm.$t('globals.reference') + ' *',"clearable":"","error-messages":errors},model:{value:(_vm.client.reference),callback:function ($$v) {_vm.$set(_vm.client, "reference", $$v)},expression:"client.reference"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"name","name":_vm.$t('globals.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":_vm.$t('globals.name') + ' *',"clearable":"","error-messages":errors},model:{value:(_vm.client.name),callback:function ($$v) {_vm.$set(_vm.client, "name", $$v)},expression:"client.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-ticket-confirmation","label":_vm.$t('globals.nif') + ' *',"clearable":""},model:{value:(_vm.client.nif),callback:function ($$v) {_vm.$set(_vm.client, "nif", $$v)},expression:"client.nif"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"email","name":_vm.$t('globals.email'),"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-email-box","label":_vm.$t('globals.email'),"clearable":"","error-messages":errors},model:{value:(_vm.client.email),callback:function ($$v) {_vm.$set(_vm.client, "email", $$v)},expression:"client.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-phone","label":_vm.$t('globals.contact'),"clearable":""},model:{value:(_vm.client.telephone),callback:function ($$v) {_vm.$set(_vm.client, "telephone", $$v)},expression:"client.telephone"}})],1)],1),_c('v-row',[_c('v-col',[_c('small',[_vm._v(_vm._s(_vm.$t('client.defect_window')))])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","depressed":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('globals.cancel'))+" ")]),_c('v-btn',{class:_vm.$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary',attrs:{"disabled":invalid,"depressed":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('globals.save'))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }