import BaseApi from "./BaseApi";

export default class Category extends BaseApi{
    construct() {}
    
    static async list(params) {
        let url = Category.getFullApiUrl() + "list";

        return Category.getAxiosInstance().get(url,{params: params});
    }

    static async search(params) {
        return await Category.getAxiosInstance().get(Category.getFullApiUrl(),{params: params});
    }

    static async create(params) {
        return await Category.getAxiosInstance().post(Category.getFullApiUrl(),params);
    }

    static async update(params, id) {
        return await Category.getAxiosInstance().put(Category.getFullApiUrl() + id,params);
    }

    static async delete(id) {
        return await Category.getAxiosInstance().delete(Category.getFullApiUrl() + id);
    }

    static async find(id) {
        return await Category.getAxiosInstance().get(Category.getFullApiUrl() + id);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/categories/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/categories/";
    }
}