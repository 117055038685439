<template>
    <section class="mt-16">
      <v-breadcrumbs
          v-if="this.$root.$vuetify.theme.isDark"
          large
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
        <v-breadcrumbs
          v-else
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
            <rp-icon icon="rp-clientes-blue-filled" class="mr-2"/> <span>{{$t('menu.billings')}}</span>
        </h1>
        <v-row>
          <v-col cols="12" md="auto">
            <rp-icon
                icon="rp-eye-blue-filled"
                class="mb-n2"
              /><v-icon class="ml-2" color="secondary" small>mdi-forward</v-icon>
              <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.visualize')}}</span>
          </v-col>
          <v-col cols="12" md="auto">
            <v-icon color="red" class="ml-4">
              mdi-delete
            </v-icon><v-icon class="ml-2" small color="secondary">mdi-forward</v-icon>
            <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.remove')}}</span>
          </v-col>
        </v-row>

        <div style="position:relative">
        
        
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t('globals.options')}}</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="secondary" v-if="addMore">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  :to="'/' + $session.get('domain') + '/admin/billing/create'"
                  v-if="$root.session.hasPermission(['super', 'billings.create'])"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{$t('globals.register')}}</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
          locale="pt-pt"
        >
        <template v-slot:item.created_at="{ item }">
            {{ getTableDate(item.created_at)}}
        </template>

        <template v-slot:item.value="{ item }">
          {{$currency(item.value, '€')}}
        </template>

        <template v-slot:item.paid="{ item }">
          <v-img v-if="item.paid" src="@/assets/icons/app/certinho.png" max-width="20px"></v-img>
          <v-img v-else src="@/assets/icons/app/certinho_unchecked.png" max-width="20px"></v-img>
        </template>

        <template v-slot:item.paid_at="{ item }">
          <span v-if="item.paid == false">-</span>
          <span v-else>{{item.paid_at}}</span>
        </template>

        <template v-slot:item.type="{ item }">
          <span>{{item.subscription == null ? '-' : item.subscription.subscription_typology}}</span>
        </template>

        <template v-slot:item.start="{ item }">
          {{ item.subscription == null ? '-' : item.subscription.subscription_start == null ? '-' : getTableDate(item.subscription.subscription_start)}}
        </template>

        <template v-slot:item.end="{ item }">
          {{item.subscription == null ? '-' : item.subscription.subscription_end == null ? '-' : getTableDate(item.subscription.subscription_end)}}
        </template>

        <template v-slot:item.opcoes="{ item }">
          <v-row>
            <v-col cols="12" md="auto">
              <IconView
                  class="mr-2"
                  @click="viewBilling(item)"
                />

                <div v-if="false">
              <IconRemove
                  v-if="$root.session.hasPermission(['super', 'billings.delete'])"
                  :reverse="filter.deleted_client"
                  @on-submit="deleteClient(item)"
                />
                </div>

            </v-col>
          </v-row>
        </template>

          
        </v-data-table>
        
      </div>
      <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetBillings">
              {{$t('globals.clean_fields')}}
            </v-btn>
          </v-list-item>
        </v-list>

      </v-navigation-drawer>


    </section>
</template>
<script>
import dayjs from 'dayjs';
import Billings from "@/api/Billings.js";
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';

export default {
components: {
    IconRemove,
    IconView
},
watch: {
    'options': {
    immediate: false,
    handler () {
        setTimeout(() => {this.searchBillings()}, 10);
    },
    deep: true,
    },
},
data: () => ({
    addMore: false,
    items: [],
    total: 0,
    loading: false,
    searching: false,
    options: {},
    speedDial:false,
    filter: {
        name:null,
    },
    status: [],
    approved:false,
}),
mounted(){

    /*if(!this.$root.session.hasPermission(["super", "diaries.read"])) {
    this.$router.push('/admin');
    }*/

    //this.searchClients();
},
methods:{
    getTableDate(date) {
        return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
    },
    fillBaseData() {
    this.addClient = false
    if(localStorage["billings-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["billings-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
    }
    },
    searchBillings(event){
        if(!!event && event.type == 'submit'){
            this.options.page = 1;
        }

        this.loading = true;

        let filter = {...this.filter};
        
        let request = {
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            page: this.options.page,
            limit: this.options.itemsPerPage,
        };
        
        Object.assign(request, filter);

        localStorage["billings-" + this.$root.session.id] = JSON.stringify({
            filter: this.filter,
            options: this.options,
        });

        Billings.search(request).then(response => {

            let data = response.data;

            this.total = data.total;

            this.items = data.data.map((item) => {

            this.searching = false;

            return item;  
            });

            let unPaid = 0
            this.items.forEach(element => {
              if(element.paid == false)
                unPaid++
            });

            if(unPaid >= 2)
              this.addMore = false
            else this.addMore = true

            this.loading = false;

        });
    },
    resetBillings()
    {
        this.searchBillings();
    },
    deleteBilling(item){
        if(this.filter.deleted_client)
            item.deleted_client = false;
        else
            item.deleted_client = true
        Billings.update(item, item.id)
            .then(() => {
            this.searchBillings();
            });
    },
    viewBilling(item){
        let domain = '/' + this.$session.get('domain')
        let path = domain + '/admin/billing/' + item.id
  
  
        this.$router.push(path);
    }
},
computed: {
    headers(){
        let he = [
            {
              text: this.$t('globals.type'),
              sortable: true,
              value: 'type',
            },
            {
              text: this.$t('globals.price'),
              sortable: true,
              value: 'value',
            },
            {
              text: this.$t('billings.method'),
              sortable: true,
              value: 'payment_method',
            },
            {
              text: this.$t('billings.paid'),
              sortable: true,
              value: 'paid',
            },
            {
              text: this.$t('billings.paid_at'),
              sortable: true,
              value: 'paid_at',
            },
            {
              text: this.$t('billings.start'),
              sortable: true,
              value: 'start',
            },
            {
              text: this.$t('billings.end'),
              sortable: true,
              value: 'end',
            },
            {
              text: this.$t('globals.options'),
              sortable: false,
              width: '200px',
              value: 'opcoes',
            }
        ]
        return he
    },
    tableFooter(){
        let t = this.$t("globals.lines_datatable")
        let a = [5, 10, 15, 20];
        return {
            'items-per-page-options': a,
            'items-per-page-text': t
        }
    }, 
    crumbs: function() {
        return [
            {
            text: this.$t('menu.dashboard'),
            disabled: false,
            to: '/admin',
            exact: true,
            },
            {
            text: this.$t('menu.billings'),
            disabled: true,
            to: window.location.href,
            exact: true,
            },
        ];
    }
}
};
</script>