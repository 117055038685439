var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:_vm.$root.$vuetify.theme.isDark ? 'secondary' : ''},[_c('v-toolbar',{directives:[{name:"show",rawName:"v-show",value:(!_vm.quick),expression:"!quick"}],staticClass:"primary",attrs:{"dark":""}},[_c('v-tabs',{attrs:{"id":"global-tab-unit","active-class":"white--text"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"warning"}}),_c('v-tab',[_vm._v(_vm._s(_vm.$t('work_units.tabs.general')))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t('work_units.tabs.history')))])],1)],1),_c('v-tabs-items',{class:_vm.$root.$vuetify.theme.isDark ? 'secondary' : '',model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:1,staticClass:"mt-6"},[_c('v-container',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"mb-16",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.loading)?_c('v-progress-linear',{staticClass:"global-loader",attrs:{"indeterminate":""}}):_vm._e(),_c('fieldset',{attrs:{"disabled":_vm.loading}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":_vm.quick ? '6' : '3'}},[_c('validation-provider',{attrs:{"vid":"code","name":_vm.$t('globals.code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-barcode","label":_vm.$t('globals.code') + '*',"clearable":"","error-messages":errors},model:{value:(_vm.fields.code),callback:function ($$v) {_vm.$set(_vm.fields, "code", $$v)},expression:"fields.code"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":_vm.quick ? '6' : '3'}},[_c('validation-provider',{attrs:{"vid":"name","name":_vm.$t('globals.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-form-textbox","label":_vm.$t('globals.name') +'*',"clearable":"","error-messages":errors},model:{value:(_vm.fields.name),callback:function ($$v) {_vm.$set(_vm.fields, "name", $$v)},expression:"fields.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":_vm.quick ? '6' : '3'}},[_c('validation-provider',{attrs:{"vid":"unit","name":_vm.$t('globals.unit'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(false)?_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-form-textbox","label":_vm.$t('globals.unit') + '*',"clearable":"","error-messages":errors},model:{value:(_vm.fields.unit),callback:function ($$v) {_vm.$set(_vm.fields, "unit", $$v)},expression:"fields.unit"}}):_vm._e(),_c('v-select',{attrs:{"items":_vm.unitsManagement,"item-value":"id","item-text":function (item) { return item.abbreviation; },"menu-props":"auto","error-messages":errors,"label":_vm.$t('globals.unit') + '*',"prepend-inner-icon":"mdi-form-textbox"},model:{value:(_vm.fields.unit_management_id),callback:function ($$v) {_vm.$set(_vm.fields, "unit_management_id", $$v)},expression:"fields.unit_management_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":_vm.quick ? '6' : '3'}},[_c('validation-provider',{attrs:{"vid":"value","name":_vm.$t('globals.cost'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":_vm.$currency_icon(),"label":_vm.$t('globals.cost') +  '*',"clearable":"","error-messages":errors},model:{value:(_vm.fields.value),callback:function ($$v) {_vm.$set(_vm.fields, "value", $$v)},expression:"fields.value"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":_vm.quick ? 'auto' : '12'}},[_c('validation-provider',{attrs:{"vid":"description","name":_vm.$t('globals.description'),"rules":"|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('globals.description'),"clearable":"","error-messages":errors},model:{value:(_vm.fields.description),callback:function ($$v) {_vm.$set(_vm.fields, "description", $$v)},expression:"fields.description"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"chapter_id","name":_vm.$t('globals.chapter'),"rules":"|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.chapters,"clearable":"","error-messages":errors,"item-text":function (item) { return item.code == null ? item.description : item.code + ' - ' + item.description; },"item-value":"id","label":_vm.$t('globals.chapter')},model:{value:(_vm.fields.chapter_id),callback:function ($$v) {_vm.$set(_vm.fields, "chapter_id", $$v)},expression:"fields.chapter_id"}})]}}],null,true)})],1),(_vm.fields.chapter_id != null)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"class_id","name":_vm.$t('globals.chapter_class'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.getClasses,"error-messages":errors,"clearable":"","item-value":"id","item-text":function (item) { return item.code == null ? item.description : item.code + ' - ' + item.description; },"label":_vm.$t('globals.chapter_class') + ' *'},model:{value:(_vm.fields.class_id),callback:function ($$v) {_vm.$set(_vm.fields, "class_id", $$v)},expression:"fields.class_id"}})]}}],null,true)})],1):_vm._e()],1),_c('v-row',{staticClass:"mt-6",attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{class:(_vm.$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary') + ' mr-5 ml-5 mb-5',attrs:{"disabled":invalid,"depressed":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('globals.save'))+" ")])],1)],1)],1)],1),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":_vm.onSuccess}},[_vm._v(" "+_vm._s(_vm.$t('work_units.success'))+" ")])]}}])})],1)],1),_c('v-tab-item',{key:2,staticClass:"mt-6"},[_c('v-container',[_c('v-data-table',{class:this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16',style:(this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'),attrs:{"headers":_vm.headers,"items":_vm.units_history,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.tableFooter,"options":_vm.options,"locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$currency(item.price))+" ")]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }