<template>
  <v-btn icon 
        v-bind="$attrs"
        v-on="$listeners">
    <Tooltip
      color="success"
      :text="text"
      top
    >
      <v-icon
        small
        color="success"
      >
        mdi-file-export
      </v-icon>
    </Tooltip>
  </v-btn>
</template>

<script>
  import Tooltip from '@/components/ui/Tooltip.vue';

  export default {
    name: 'uiIconView',
    props: {
      text: String
    },
    components: {
      Tooltip
    },
  }
</script>

<style lang="scss" scoped>
</style>