<template>
        <v-dialog
            v-model="dialog"
            :fullscreen="false"
            persistent
            max-width="1000px"
        >        
        <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                <v-toolbar
                    class="sticky-toolbar"
                    dark
                    color="primary"
                    >                    
                    <v-toolbar-title>{{$t('ticket.new')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                    <v-btn
                        icon
                        dark
                        @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <validation-observer ref="form_ticket" v-slot="{ invalid }">      
                        <v-form class="mb-16 mt-12" @submit.prevent="addTicket">
                            <v-row>               
                                <v-col cols="12" md="12">
                                    <validation-provider v-slot="{ errors }" vid="title" :name="$t('globals.title')" rules="required">
                                        <v-text-field :error-messages="errors" 
                                                :label="$t('globals.title') + ' *'" 
                                                v-model="ticket.title">
                                        </v-text-field>
                                    </validation-provider>
                                </v-col>    
                            </v-row>
                            <v-row>               
                                <v-col cols="12" md="12">
                                    <validation-provider v-slot="{ errors }" vid="description" :name="$t('globals.description')" rules="required">
                                        <v-textarea
                                        outlined
                                        name="input-7-1"
                                        v-model="ticket.description"
                                        :error-messages="errors"
                                        :label="$t('globals.description') + ' *'" 
                                        ></v-textarea>
                                    </validation-provider>
                                </v-col>    
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-file-input
                                        multiple
                                        :label="$t('ticket.attach_files')"
                                        v-model="ticket.files"
                                    ></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row class="mt-6" align="center" justify="space-around">
                            <v-col align="center" justify="space-around">
                                <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                                {{$t('globals.save')}}
                                </v-btn>
                            </v-col>
                            </v-row>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
          {{$t('ticket.success')}}
        </DialogSuccess>
        </v-dialog>
</template>
<script>
import Ticket from "@/api/Ticket.js"
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default {
    components:{
        DialogSuccess
    },
    data(){
        return{
            success: false,
            dialog: false,
            ticket: {
                title: '',
                description: '',
                files: null,
            }
        }
    },
    watch:{
        tenant(){
        }
    },
    mounted(){
    },
    methods:{
        onSuccess(){
            this.ticket = {
                title: '',
                description: '',
                files: '',
            }
            this.success = false
        },
        addTicket(){
            const formData = new FormData()
            if(this.ticket != null)
                if(this.ticket.files != null)
                    for (var i = 0; i < this.ticket.files.length; i++ ){
                        let file = this.ticket.files[i];
                        formData.append('files[]', file);
                    }

      
            
            formData.append('title', this.ticket.title)
            formData.append('description', this.ticket.description)

            let tamanho = 0;
            if(this.ticket != null)
                    if(this.ticket.files != null)
                        tamanho = this.ticket.files.length
                        
            formData.append('total_files', tamanho)

            Ticket.create(formData).then(() => {
                this.success = true;

                this.loading = false;
            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        openDialog(){
            this.dialog = true
        }
    }
}
</script>