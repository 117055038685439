import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { ValidationObserver, ValidationProvider} from 'vee-validate';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import '@babel/polyfill'
import '@/assets/style.css'
import axios from "axios";
import router from './router'
import dayjs from 'dayjs'
import VCurrencyField from 'v-currency-field'
import DatetimePicker from 'vuetify-datetime-picker'
import VueLazyload from 'vue-lazyload'
import EncryptValue from '@/plugins/encrypt'
import VueSession from 'vue-session'
import msal from 'vue-msal'
import VueCookies from 'vue-cookies'
import { VueSpinners } from '@saeris/vue-spinners'
import i18n from './i18n'
import './plugins/validations'
import currency from './plugins/currency'
import getFileExtension from './plugins/getFileExtension'
import getFilename from './plugins/getFilename'
import getIconDocument from './plugins/getIconDocument'
import currency_icon from './plugins/currency_icon'
import VCreditCard from 'v-credit-card';
import * as VueGoogleMaps from 'vue2-google-maps'

import MyIcon from '@/components/ui/MyIcon.vue'

Vue.component('v-credit-card', VCreditCard);

Vue.use(msal, {
  auth: {
    clientId: process.env.VUE_APP_MICROSOFT_CLIENT,
  },
});

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS,
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    coin: '€'
  }
})

Vue.use(VueSession)

Vue.config.productionTip = false

Vue.prototype.$currency = currency
Vue.prototype.$getFileExtension = getFileExtension
Vue.prototype.$getFilename = getFilename
Vue.prototype.$getIconDocument = getIconDocument
Vue.prototype.$currency_icon = currency_icon

Vue.component('ValidationProvider', ValidationProvider);

Vue.component('ValidationObserver', ValidationObserver);

Vue.component('rp-icon', MyIcon)

Vue.use(VueLazyload)
Vue.use(VueCookies, { expires: '7d'})
Vue.use(VueSpinners)

Vue.use(DatetimePicker);

axios.interceptors.request.use(
  function(config) {
        config.withCredentials = true;
        config.headers['Content-Type'] = "application/json";
        config.headers['Accept'] = "application/json";
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

Vue.prototype.$fatalError = true;

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    router.push('/')
  }
  return Promise.reject(error)
})

const themes = [
    {
        name: "Padrão",
        light: {
          primary: "#111b2d",
          primaryLight: "#111b2d0",
          secondary: "#29334b",
          accent: "#9DA6BA",
          error: "#f44336",
          warning: "#E7C02C",
          info: "#03a9f4",
          success: "#4caf50",
          anchor: '#222B3F',
        },
        dark: {
          primary: "#1e1e1e",
          primaryLight: "#29334b",
          secondary: "#555555",
          accent: "#9DA6BA",
          error: "#f44336",
          warning: "#E7C02C",
          info: "#03a9f4",
          success: "#4caf50",
          anchor: '#222B3F',
      },
    },
    /*{
        name: "Tema 1",
        light: {
            primary: "#009688",
            primaryLight: "#00968810",
            secondary: "#8bc34a",
            accent: "#cddc39",
            error: "#f44336",
            warning: "#ff9800",
            info: "#2196f3",
            success: "#4caf50"
      },
        dark:  {
            primary: "#009688",
            primaryLight: "#00968810",
            secondary: "#8bc34a",
            accent: "#cddc39",
            error: "#f44336",
            warning: "#ff9800",
            info: "#2196f3",
            success: "#4caf50"
        }
    },
    {
        name:"Tema 2",
        light: {
            primary: "#607d8b",
            primaryLight: "#607d8b10",
            secondary: "#009688",
            accent: "#00bcd4",
            error: "#e91e63",
            warning: "#ffc107",
            info: "#03a9f4",
            success: "#8bc34a"
        },
        dark:{
            primary: "#9e9e9e",
            primaryLight: "#9e9e9e10",
            secondary: "#B2DFDB",
            accent: "#00bcd4",
            error: "#e91e63",
            warning: "#ffc107",
            info: "#03a9f4",
            success: "#8bc34a"
        },
    }*/
];

Vue.prototype.$themes = themes;

Vue.prototype.$encryptValue = EncryptValue

Vue.mixin({
  methods: {
    setTheme: function(theme) {
        const dark = theme.dark;

        const light = theme.light;
        
        Object.keys(dark).forEach(i => {
            this.$vuetify.theme.themes.dark[i] = dark[i];
        });
        Object.keys(light).forEach(i => {
            this.$vuetify.theme.themes.light[i] = light[i];
        });
          
          this.$vuetify.groupSelectedColor = this.$vuetify.theme.dark ? theme.dark.primaryLight
          : theme.light.primaryLight;
    },
    formatDate(date){
      return date == null ? null : dayjs(date).format('DD/MM/YYYY');
    },
  }
});

Vue.use(VCurrencyField, { 
	locale: 'pt-BR',
	decimalLength: 2,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue: 0,
    valueAsInteger: false,
    allowNegative: true
})


new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
