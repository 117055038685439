<template>
    <section class="mt-16">
      <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <rp-icon icon="rp-graphic-blue-filled" class="mr-2"/> <span>{{$t('menu.units_measure_management')}}</span>
        </h1>
        <v-row>
          <v-col cols="12" md="auto">
            <rp-icon
              icon="rp-eye-blue-filled"
              class="mb-n2"
            />
            <v-icon class="ml-2" color="secondary" small>mdi-forward</v-icon>
            <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.visualize')}}</span>
          </v-col>
          <v-col cols="12" md="auto">
            <v-icon color="red" class="ml-4">
              mdi-delete
            </v-icon><v-icon color="secondary" class="ml-2" small>mdi-forward</v-icon>
            <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.remove')}}</span>
          </v-col>
        </v-row>
        

        
  
        <div style="position:relative">
          
          <v-container>
            <v-speed-dial
              absolute
              v-model="speedDial"
              top
              right
              direction="bottom"
              :open-on-hover="true"
            >
              <template v-slot:activator>
                <v-tooltip right color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" v-on="on"
                      v-model="speedDial"
                      color="primary"
                      dark
                      x-large
                      fab
                    >
                      <v-icon v-if="speedDial">
                        mdi-close
                      </v-icon>
                      <v-icon v-else>
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('globals.options')}}</span>
                </v-tooltip>
              </template>
              <v-tooltip right color="success">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="success"
                    large
                    @click="exportUnitsList"
                  >
                    <v-icon>mdi-paperclip</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('globals.export')}}</span>
              </v-tooltip>
              <v-tooltip right color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="secondary"
                    large
                    @click="openNewModalUnit()"
                    v-if="$root.session.hasPermission(['super', 'units_management.create'])"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('globals.register')}}</span>
              </v-tooltip>
            </v-speed-dial>
          </v-container>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
            locale="pt-pt"
          >
          <template v-slot:item.created_at="{ item }">
              {{ getTableDate(item.created_at)}}
          </template>
  
          <template v-slot:item.updated_at="{ item }">
              {{ getTableDate(item.updated_at)}}
          </template>
  
          <template v-slot:item.actions="{ item }">
            <v-row>
              <v-col cols="12" md="auto">
                <IconView
                    class="mr-2"
                    @click="viewUnit(item)"
                  />

                <IconRemove
                    v-if="$root.session.hasPermission(['super', 'units_management.delete'])"
                    :reverse="filter.deleted_unit"
                    @on-submit="deleteUnit(item)"
                  />

              </v-col>
            </v-row>
          </template>
  
            
          </v-data-table>
          
        </div>
        <v-navigation-drawer
            fixed
            right
            v-model="searching"
            hide-overlay
            width="520"
            class="pt-6"
          >
          <v-list>
            <v-list-item>
              <v-btn fab small color="secondary" @click="searching = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
  
              <v-btn absolute right small color="error" @click="resetUnits">
                {{$t('globals.clean_fields')}}
              </v-btn>
            </v-list-item>
          </v-list>
  
        </v-navigation-drawer>
        <DialogSuccess :opened="approved" @on-ok="approved = false" >
          {{$t('unit_measure.success_add')}}
        </DialogSuccess>
        <v-dialog
          v-model="addUnit"
          max-width="800px"
        >
          <AddUnit 
            v-if="addUnit"
            :editUnit="unit_selected"
            @close="addUnit = false"
            @close-update="fillBaseData()"
          />
        </v-dialog>
    </section>
  </template>
  <script>
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  import dayjs from 'dayjs';
  import AddUnit from '@/components/units_management/Add.vue';
  import UnitsManagement from "@/api/UnitsManagement.js";
  import IconRemove from '@/components/ui/IconRemove.vue';
  import IconView from '@/components/ui/IconView.vue';
  import Export from '@/api/Export.js'
  
  export default {
    components: {
      AddUnit,
      DialogSuccess,
      IconRemove,
      IconView
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchUnits()}, 10);
        },
        deep: true,
      },
    },
    data: () => ({
      unit_selected: null,
      items: [],
      total: 0,
      loading: false,
      searching: false,
      options: {},
      speedDial:false,
      filter: {
        name:null,
        deleted_unit: false
      },
      status: [],
      approved:false,
      addUnit: false
    }),
    mounted(){
  
      /*if(!this.$root.session.hasPermission(["super", "diaries.read"])) {
        this.$router.push('/admin');
      }*/
  
      //this.searchClients();
    },
    methods:{
      exportUnitsList(){
        let data = 'units_management';
        Export.generalExport(data)
          .then((resp) => {            
              window.open(resp.data.url, '_blank')
              Export.delete( resp.data.path)
              .then(() => {
              });
          });
      },
      openNewModalUnit(){
        this.unit_selected = null
        this.addUnit = true
      },
      getTableDate(date) {
        return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
      },
      fillBaseData() {
        this.addUnit = false
        if(localStorage["units_management-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["units_management-" + this.$root.session.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
      },
      searchUnits(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
        
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          deleted_unit: this.filter.deleted_unit
        };
      
        Object.assign(request, filter);
  
        localStorage["units_management-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        UnitsManagement.search(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;  
          });
  
          this.loading = false;
  
        });
      },
      resetUnits()
      {
        this.searchUnits();
      },
      deleteUnit(item){
        UnitsManagement.delete(item.id)
          .then(() => {
            this.searchUnits();
          });
      },
      viewUnit(item){
        this.unit_selected = item
        this.addUnit = true
      }
    },
    computed: {
      headers(){
        let he = [
          {
            text: this.$t('globals.abbreviation'),
            sortable: true,
            value: 'abbreviation',
          },
          {
            text: this.$t('globals.description'),
            sortable: true,
            value: 'description',
          },
          {
            text: this.$t('globals.created_at'),
            sortable: true,
            value: 'created_at',
          },
          {
            text: this.$t('globals.last_change_at'),
            sortable: true,
            value: 'updated_at',
          },
          {
            text: this.$t('globals.options'),
            value: 'actions', 
            width: '200px',
            sortable: false 
          },
        ]

        return he
      },
      tableFooter(){
        let t = this.$t("globals.lines_datatable")
        let a = [5, 10, 15, 20];
        return {
          'items-per-page-options': a,
          'items-per-page-text': t
        }
      }, 
      crumbs: function() {
        return [
          {
            text: this.$t('menu.dashboard'),
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: this.$t('menu.units_measure_management'),
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  