import BaseApi from "./BaseApi";

export default class Templates extends BaseApi{
    construct() {}
 
    static async create(params, id) {
        return await Templates.getAxiosInstance().post(Templates.getFullApiUrl() + id, params);
    }

    static async removeMaoObra(line, id){
        return await Templates.getAxiosInstance().delete(Templates.getFullApiUrl() + 'remove/maoobra/' + line + '/' + id);
    }

    static async removeMaquina(line, id){
        return await Templates.getAxiosInstance().delete(Templates.getFullApiUrl() + 'remove/maquina/' + line + '/' + id);
    }

    static async removeMaterial(line, id){
        return await Templates.getAxiosInstance().delete(Templates.getFullApiUrl() + 'remove/material/' + line + '/' + id);
    }

    static async removeUnidade(line, id){
        return await Templates.getAxiosInstance().delete(Templates.getFullApiUrl() + 'remove/unidade/' + line + '/' + id);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/templates/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/templates/";
    }

}