<template>
  <section class="mt-16">
    <v-breadcrumbs
      large
      :items="[]"
      divider="/"
      class="pl-0"
    ></v-breadcrumbs>

    <h1 class="primary--text">
      <v-icon color="primary">mdi-view-dashboard</v-icon> <span>{{$t('dashboard.title')}}</span>
    </h1>
    <v-row>
      <v-col cols="12" :md="$root.session.hasRole(['encarregado']) ? '4' : '6'" v-if="$root.session.hasPermission(['super', 'works.read', 'works.create'])">
        <Card height="125px" class="mt-4 mb-4">
          <v-card-title>
            <span :class="$root.$vuetify.theme.isDark ? 'white--text font-weight-bold' : 'font-weight-bold'">
              {{$t('dashboard.card_obras.title')}}
            </span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" v-if="$root.session.hasPermission(['super', 'works.read'])">
                <v-btn
                  depressed
                  :color="$root.$vuetify.theme.isDark ? 'secondary' : 'primary'"
                  class="mr-4"
                  type="button"
                  large
                  block
                  link
                  @click="$router.push('/' + $route.params.tenant + '/admin/works-settings/works')"
                >
                  {{$t('dashboard.card_obras.see')}}
                </v-btn>
              </v-col>
              <v-col cols="12" md="6" v-if="$root.session.hasPermission(['super', 'works.create'])">
                <v-btn
                  depressed
                  :color="$root.$vuetify.theme.isDark ? 'secondary' : 'primary'"
                  class="mr-4"
                  type="button"
                  large
                  block
                  link
                  @click="$router.push('/' + $route.params.tenant + '/admin/works-settings/works/create')"
                >
                  {{$t('dashboard.card_obras.register')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </Card>
      </v-col>
      <v-col  cols="12" :md="$root.session.hasRole(['encarregado']) ? '4' : '6'" v-if="$root.session.hasPermission(['super', 'diaries.read', 'diaries.create'])">
        <Card height="125px" class="mt-4 mb-4">
          <v-card-title>
            <span :class="$root.$vuetify.theme.isDark ? 'white--text font-weight-bold' : 'font-weight-bold'">
              {{$t('dashboard.card_diarias.title')}}
            </span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col  cols="12" md="6" v-if="$root.session.hasPermission(['super', 'diaries.read'])">
                <v-btn
                  depressed
                  :color="$root.$vuetify.theme.isDark ? 'secondary' : 'primary'"
                  class="mr-4"
                  type="button"
                  large
                  block
                  link
                  @click="$router.push('/' + $route.params.tenant + '/admin/diaries')"
                >
                  {{$t('dashboard.card_diarias.see')}}
                </v-btn>
              </v-col>
              <v-col  cols="12" md="6" v-if="$root.session.hasPermission(['super', 'diaries.create'])">
                <v-btn
                  depressed
                  :color="$root.$vuetify.theme.isDark ? 'secondary' : 'primary'"
                  class="mr-4"
                  type="button"
                  large
                  block
                  link
                  @click="$router.push('/' + $route.params.tenant + '/admin/diaries/create')"
                >
                {{$t('dashboard.card_diarias.register')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </Card>
      </v-col>
      <v-col cols="12" md="4" v-if="$root.session.hasRole(['encarregado'])">
        <Card class="mt-4 mb-4" height="125px">
          <v-card-title>
            <span :class="$root.$vuetify.theme.isDark ? 'white--text font-weight-bold' : 'font-weight-bold'">
              {{$t('diaries.worked_hours')}}/{{$t('globals.month')}}
            </span>
          </v-card-title>
          <v-card-text>
            <v-row v-if="statistics != null">
              <v-col align="center" cols="12" md="12" v-if="$root.session.hasPermission(['super', 'works.read'])">
                <span class="text-h4">{{workedHours}}h</span>
              </v-col>
            </v-row>
          </v-card-text>
        </Card>
      </v-col>
    </v-row>
    <v-tabs slider-color="warning" fixed-tabs class="pt-4" v-model="tab" @change="onChangeTab">
      <v-tab :class="$root.$vuetify.theme.isDark ? 'secondary white--text' : 'primary white--text rounded-pill rounded-r-0'" v-show="$root.session.hasPermission(['super', 'works.read'])">{{$t('dashboard.tabs.works')}}</v-tab>
      <v-tab :class="$root.$vuetify.theme.isDark ? 'secondary white--text' : 'primary white--text'" v-show="$root.session.hasPermission(['super']) || $root.session.hasRole(['dto']) || $root.session.hasRole(['ceo'])">{{$t('dashboard.tabs.general')}}</v-tab>
      <v-tab :class="$root.$vuetify.theme.isDark ? 'secondary white--text' : 'primary white--text rounded-pill rounded-l-0'" v-show="$root.session.hasPermission(['super', 'diaries.read'])">{{$t('dashboard.tabs.statistics')}}</v-tab>
    </v-tabs>
    
    <v-tabs-items v-model="tab">
    <v-progress-linear 
      v-if="loading"
      class="mt-2"
      indeterminate
    ></v-progress-linear>
      <v-tab-item class="mt-6" :key="1">
        <MapMarkers :markers="locations" />
        <SpecialDashboard :worksCounters="worksCounters" :diariesCounters="diariesCounters"/>
        <div>
        <v-navigation-drawer
          fixed
          right
          v-model="openWorksFilter"
          hide-overlay
          width="520"
          class="pt-6"
        >
          <v-list>
            <v-list-item>
              <v-btn fab small color="secondary" @click="openWorksFilter = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>

              <v-btn absolute right small color="error" @click="resetWorkersFilters">
                {{$t('dashboard.filters.clean')}}
              </v-btn>
            </v-list-item>
          </v-list>
          <v-list class="pt-10">
            <v-form @submit.prevent="searchWorks">
              <v-list-item>
                <v-row>
                  <v-col cols="6">
                    <v-menu
                      v-model="startDate"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider v-slot="{ errors }" vid="filterWorks.start_date" :name="$t('dashboard.filters.start_date')" rules="required">
                          <v-text-field
                            :label="$t('dashboard.filters.start_date')"
                            v-model="filterWorks.start_date"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="filterWorks.start_date = null"
                            :error-messages="errors"
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="filterWorks.start_date"
                        locale="pt-pt"
                        @input="startDate = false;"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      v-model="endDate"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider v-slot="{ errors }" vid="endDate" :name="$t('dashboard.filters.end_date')" rules="required">
                          <v-text-field
                            :label="$t('dashboard.filters.end_date')"
                            v-model="filterWorks.end_date"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="filterWorks.end_date = null"
                            :error-messages="errors"
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="filterWorks.end_date"
                        locale="pt-pt"
                        @input="endDate = false;"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item>
                <v-select
                    v-model="filterWorks.status"
                    :items="worksStatus"
                    item-text="name"
                    item-value="id"
                    menu-props="auto"
                    :label="$t('dashboard.filters.status')"
                    prepend-inner-icon="mdi-list-status"
                    clearable
                  ></v-select>
              </v-list-item>

              <v-list-item v-if="!$root.session.hasRole(['encarregado'])">
                <v-select
                    v-model="filterWorks.commissioner_id"
                    :items="commissioners"
                    :item-text="item => 'Nome: ' + item.name + ' - Username: '+ item.username"
                    item-value="id"
                    menu-props="auto"
                    :label="$t('dashboard.filters.charge')"
                    prepend-inner-icon="mdi-account-hard-hat"
                    clearable
                  ></v-select>
              </v-list-item>

              <v-list-item v-if="!$root.session.hasRole(['maquinista'])">
                <v-select
                    v-model="filterWorks.overseer_id"
                    :items="overseers"
                    :item-text="item => 'Nome: ' + item.name + ' - Username: '+ item.username"
                    item-value="id"
                    menu-props="auto"
                    :label="$t('dashboard.filters.equipment_responsible')"
                    prepend-inner-icon="mdi-account-hard-hat"
                    clearable
                  ></v-select>
              </v-list-item>

              <v-list-item v-if="!$root.session.hasRole(['dto'])">
                <v-select
                    v-model="filterWorks.engineer_id"
                    :items="engineers"
                    :item-text="item => 'Nome: ' + item.name + ' - Username: '+ item.username"
                    item-value="id"
                    menu-props="auto"
                    :label="$t('dashboard.filters.engineer')"
                    prepend-inner-icon="mdi-account-tie"
                    clearable
                  ></v-select>
              </v-list-item>

              <v-row
                class="mt-10"
                align="center"
                justify="space-around"
              >
              <v-btn
                color="primary"
                type="submit"
              >
                {{$t('dashboard.filters.search')}}
              </v-btn>
              </v-row>
            </v-form>
          </v-list>
        </v-navigation-drawer>
        </div>
      </v-tab-item>
      <v-tab-item class="mt-6" :key="2">
        <RecursosHumanos v-if="$root.session.hasPermission(['super']) || $root.session.hasRole(['dto']) || $root.session.hasRole(['ceo'])" @loading="loading = true" @loaded="loading = false"/>
      </v-tab-item>
      <v-tab-item class="mt-6" :key="3">
        <v-row v-if="encarregado != null && $root.session.hasRole(['encarregado'])">
          <v-col cols="12" md="3">
            <Card class="mt-4 mb-4">
              <v-card-title>{{$t('dashboard.mensal_daily_parts')}}</v-card-title>
              <v-card-text>
                <h3>{{encarregado.n_partes_diarias}}</h3>
              </v-card-text>
            </Card>
          </v-col>
          <v-col cols="12" md="3">
            <Card class="mt-4 mb-4">
              <v-card-title>{{$t('dashboard.daily_parts_delayed')}}</v-card-title>
              <v-card-text>
                <h3>{{encarregado.atrasadas}}</h3>
              </v-card-text>
            </Card>
          </v-col>
          <v-col cols="12" md="4">
            <Card class="mt-4 mb-4">
              <v-card-title>{{$t('dashboard.in_time')}}</v-card-title>
              <v-card-text>
                <h3>{{encarregado.atempada}}%</h3>
              </v-card-text>
            </Card>
          </v-col>
        </v-row>
        <v-speed-dial
          absolute
          v-model="speedDial"
          top
          right
          direction="bottom"
          :open-on-hover="true"
        >
          <template v-slot:activator>
            <v-tooltip right color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  v-model="speedDial"
                  fab
                  dark
                  small
                  color="primary"
                  large
                  @click="openDiariesFilter = true"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>{{$t('globals.search')}}</span>
            </v-tooltip>
          </template>
        </v-speed-dial>
        <Chart
          :key="diariesGraphKey"
          class="mt-16"
          id="first-chart"
          :options="diariesGraphOptions"
          :series="diariesGraphSeries"
        />
        <!-- INFORMACAO NOVA -->
        <RendimentoMO  />
        <RendimentoUO  />
        <!-- FIM INFORMACAO NOVA-->
        <h2 class="secondary--text mt-4" v-if="false">
          <v-icon color="secondary">mdi-numeric</v-icon> <span>{{$t('dashboard.counters.title')}}</span>
        </h2>
        <v-row v-if="false">
          <v-col cols="12" md="3">
            <Card class="mt-4 mb-4">
              <v-card-title>{{$t('dashboard.counters.total')}}</v-card-title>
              <v-card-text>
                <h3>{{diariesCounters.total}}</h3>
              </v-card-text>
            </Card>
          </v-col>
          <v-col cols="12" md="3">
            <Card class="mt-4 mb-4">
              <v-card-title>{{$t('dashboard.counters.approved')}}</v-card-title>
              <v-card-text>
                <h3>{{diariesCounters.finished}}</h3>
              </v-card-text>
            </Card>
          </v-col>
          <v-col cols="12" md="3" v-if="false">
            <Card class="mt-4 mb-4">
              <v-card-title>{{$t('dashboard.counters.started_approved')}}</v-card-title>
              <v-card-text>
                <h3>{{diariesCounters.initiated}}</h3>
              </v-card-text>
            </Card>
          </v-col>
          <v-col cols="12" md="3">
            <Card class="mt-4 mb-4">
              <v-card-title>{{$t('dashboard.counters.to_approve')}}</v-card-title>
              <v-card-text>
                <h3>{{diariesCounters.total- diariesCounters.finished}}</h3>
              </v-card-text>
            </Card>
          </v-col>
        </v-row>
        <v-navigation-drawer
          fixed
          right
          v-model="openDiariesFilter"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="openDiariesFilter = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetDiariesFilters">
              {{$t('globals.clean_fields')}}
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchDiaries">
            <v-list-item>
                <v-row>
                  <v-col cols="6">
                    <v-menu
                      v-model="diariesStartDate"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider v-slot="{ errors }" vid="filterDiaries.start_date" :name="$t('globals.start_date')" rules="required">
                          <v-text-field
                            :label="$t('globals.start_date')"
                            v-model="filterDiaries.start_date"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="filterDiaries.start_date = null"
                            :error-messages="errors"
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="filterDiaries.start_date"
                        locale="pt-pt"
                        @input="diariesStartDate = false;"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      v-model="diariesEndDate"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider v-slot="{ errors }" vid="end_date" :name="$t('globals.end_date')" rules="required">
                          <v-text-field
                            :label="$t('globals.end_date')"
                            v-model="filterDiaries.end_date"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="filterDiaries.end_date = null"
                            :error-messages="errors"
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="filterDiaries.end_date"
                        locale="pt-pt"
                        @input="diariesEndDate = false;"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-list-item>
            <v-list-item>
              <v-select
                  v-model="filterDiaries.work_id"
                  :items="works"
                  :item-text="item => $t('globals.name') + ': ' + item.name + ' - ' + $t('globals.code') + ': '+ item.code"
                  item-value="id"
                  menu-props="auto"
                  :label="$t('globals.works')"
                  prepend-inner-icon="mdi-account-hard-hat"
                  clearable
                ></v-select>
            </v-list-item>
            <v-list-item>
              <v-select
                  v-model="filterDiaries.status"
                  :items="diariesStatus"
                  item-text="name"
                  item-value="id"
                  menu-props="auto"
                  :label="$t('globals.state')"
                  prepend-inner-icon="mdi-list-status"
                  clearable
                ></v-select>
            </v-list-item>

            <v-list-item v-if="!$root.session.hasRole(['encarregado'])">
              <v-select
                  v-model="filterDiaries.commissioner_id"
                  :items="commissioners"
                  :item-text="item => $t('globals.name') + ': ' + item.name + ' - ' + $t('globals.name') + ': '+ item.username"
                  item-value="id"
                  menu-props="auto"
                  :label="$t('globals.in_charge')"
                  prepend-inner-icon="mdi-account-hard-hat"
                  clearable
                ></v-select>
            </v-list-item>

            <v-list-item v-if="!$root.session.hasRole(['maquinista'])">
              <v-select
                  v-model="filterDiaries.overseer_id"
                  :items="overseers"
                  :item-text="item => $t('globals.name') + ': ' + item.name + ' - ' + $t('globals.name') + ': '+ item.username"
                  item-value="id"
                  menu-props="auto"
                  :label="$t('globals.equipment_responsible')"
                  prepend-inner-icon="mdi-account-hard-hat"
                  clearable
                ></v-select>
            </v-list-item>

            <v-list-item v-if="!$root.session.hasRole(['dto'])">
              <v-select
                  v-model="filterDiaries.engineer_id"
                  :items="engineers"
                  :item-text="item => $t('globals.name') + ': ' + item.name + ' - ' + $t('globals.name') + ': '+ item.username"
                  item-value="id"
                  menu-props="auto"
                  :label="$t('globals.engineer')"
                  prepend-inner-icon="mdi-account-tie"
                  clearable
                ></v-select>
            </v-list-item>

            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              {{$t('globals.search')}}
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
        </v-navigation-drawer>
      </v-tab-item>
    </v-tabs-items>
    <FirstTime v-if="showFirst"/>
  </section>
</template>
<script>
//https://apexcharts.com/vue-chart-demos/bar-charts/grouped/
import Chart from '@/components/ui/Chart.vue';
import Card from '@/components/ui/Card.vue';
import User from "@/api/User.js";
import Diary from "@/api/Diary.js";
import Work from "@/api/Work.js";
import Dashboard from "@/api/Dashboard.js";
import dayjs from 'dayjs'
import SpecialDashboard from '@/views/dashboard/SpecialDashboard.vue';
import RecursosHumanos from '@/views/dashboard/RecursosHumanos.vue';
import RendimentoMO from '@/views/dashboard/RendimentoMO.vue';
import RendimentoUO from '@/views/dashboard/RendimentoUO.vue';
import MapMarkers from '@/components/ui/maps/MapMarkers.vue'
import FirstTime from '@/views/dashboard/FirstTime.vue'



export default {
  components: {
    Chart,
    Card,
    SpecialDashboard,
    RecursosHumanos,
    RendimentoMO,
    RendimentoUO,
    MapMarkers,
    FirstTime
  },
   beforeCreate(){
    document.title = "Reportlake - Dashboard";
  },
  data() {
    return {
      showFirst: false,
      rendimentosMO: null,
      loading: false,
      colors:[],
      tab: 0,
      worksFilter: {},
      locations: [],
      dummyData:{"days":[["Dom","19/09"],["Seg","20/09"],["Ter","21/09"],["Qua","22/09"],["Qui","23/09"],["Sex","24/09"],["Su00e1b","25/09"],["Dom","26/09"],["Seg","27/09"],["Ter","28/09"],["Qua","29/09"],["Qui","30/09"],["Sex","01/10"],["Su00e1b","02/10"],["Dom","03/10"],["Seg","04/10"],["Ter","05/10"],["Qua","06/10"],["Qui","07/10"],["Sex","08/10"],["Su00e1b","09/10"],["Dom","10/10"],["Seg","11/10"],["Ter","12/10"],["Qua","13/10"],["Qui","14/10"],["Sex","15/10"],["Su00e1b","16/10"]],"ttlSales":1431.66,"ttlNet":1430.16,"ttlQty":30,"qtys":[2,2,2,2,2,2,0,0,0,0,0,0,0,0,0,0,0,1,0,2,1,2,1,2,3,2,2,2],"values":[99.8,99.8,99.8,99.8,99.8,99.8,0,0,0,0,0,0,0,0,0,0,0,17.26,0,99.8,49.9,99.8,49.9,99.8,117,99.8,99.8,99.8],"netValues":[99.8,99.8,99.8,99.8,99.8,99.8,0,0,0,0,0,0,0,0,0,0,0,16.51,0,99.8,49.9,99.8,49.9,99.8,116.25,99.8,99.8,99.8],"salesQty":30},
      openWorksFilter: false,
      speedDial: false,
      filterWorks: {
        start_date:dayjs().subtract(7, 'days').format("YYYY-MM-DD"),
        end_date:dayjs().format("YYYY-MM-DD"),
        commissioner_id:null,
        overseer_id:null,
        engineer_id:null,
        status: null,
      },
      filterDiaries: {
        start_date:dayjs().subtract(7, 'days').format("YYYY-MM-DD"),
        end_date:dayjs().format("YYYY-MM-DD"),
        commissioner_id:null,
        overseer_id:null,
        engineer_id:null,
        status: null,
      },
      statistics: null,
      openDiariesFilter: false,
      worksStatus: [],
      diariesStatus: [],
      works: [],
      commissioners:[],
      overseers:[],
      engineers:[],
      startDate: false,
      endDate: false,
      diariesStartDate: false,
      diariesEndDate: false,
      diariesGraphKey: 0,
      worksGraphKey: 0,
      diariesGraph: {
        days:[],
        qty:[]
      },
      worksGraph: {
        days:[],
        qty:[]
      },
      worksCounters: {
        total: 0,
        finished: 0,
        initiated: 0,
        opened: 0
      },
      diariesCounters: {
        total: 0,
        finished: 0,
        initiated: 0,
        opened: 0
      },
      encarregado:null,
    }
  },
  computed: {
    workedHours(){
      if(this.statistics == null)
        return 0

      if(this.statistics.encarregado_horas == undefined)
      return 0

      return this.statistics.encarregado_horas
    },
    worksGraphOptions() {
      return {
        colors: this.colors,
        stroke: {
          width: [2, 2],
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            columnWidth: '100%',
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            enabled: true,
            inverseColors: true,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 6,
            sizeOffset: 3,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
          categories: this.worksGraph.days,
          labels: {
            rotate: 0,
            hideOverlappingLabels: true,
            trim: false,
            style: {
              fontFamily: 'Nunito, Lato, Arial, sans-serif',
              fontWeight: 700,
            },
            minHeight: 60,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: [
          {
            seriesName: this.$t('globals.quantity'),
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: true,
              color: '#ffa500',
            },
            labels: {
              style: {
                colors: '#ffa500',
              },
              formatter(val) {
                if (Number.isInteger(val)) {
                  return val
                } else if (val === 5e-324) {
                  return 0
                }
              },
            },
            title: {
              text: this.$t('globals.quantity'),
              style: {
                color: '#ffa500',
                fontFamily: 'Nunito, Lato, Arial, sans-serif',
                fontSize: 'inherit',
                fontWeight: 700,
              },
            },
            tooltip: {
              enabled: false,
            },
          },
        ],
        chart: {
          type: 'line',
          toolbar: {
            show: true,
            tools: {
              download: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
            },
            autoSelected: 'zoom',
          },
        },
        tooltip: {
          enabled: true,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
          offsetY: 10,
          markers: {
            strokeColor: '#fff',
            fillColors: this.colors,
          },
        },
      }
    },
    worksGraphSeries() {
      return [
        {
          name: 'Obras',
          data: this.worksGraph.qty || [],
        },
      ]
    },
    diariesGraphOptions() {
      return {
        colors: this.colors,
        stroke: {
          width: [2, 2],
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            columnWidth: '100%',
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            enabled: true,
            inverseColors: true,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 6,
            sizeOffset: 3,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
          categories: this.diariesGraph.days,
          labels: {
            rotate: 0,
            hideOverlappingLabels: true,
            trim: false,
            style: {
              fontFamily: 'Nunito, Lato, Arial, sans-serif',
              fontWeight: 700,
            },
            minHeight: 60,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: [
          {
            seriesName: this.$t('globals.quantity'),
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: true,
              color: '#ffa500',
            },
            labels: {
              style: {
                colors: '#ffa500',
              },
              formatter(val) {
                if (Number.isInteger(val)) {
                  return val
                } else if (val === 5e-324) {
                  return 0
                }
              },
            },
            title: {
              text: this.$t('globals.quantity'),
              style: {
                color: '#ffa500',
                fontFamily: 'Nunito, Lato, Arial, sans-serif',
                fontSize: 'inherit',
                fontWeight: 700,
              },
            },
            tooltip: {
              enabled: false,
            },
          },
        ],
        chart: {
          type: 'line',
          toolbar: {
            show: true,
            tools: {
              download: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
            },
            autoSelected: 'zoom',
          },
        },
        tooltip: {
          enabled: true,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
          offsetY: 10,
          markers: {
            strokeColor: '#fff',
            fillColors: this.colors,
          },
        }
      }
    },
    diariesGraphSeries() {
      return [
        {
          name: 'Partes Diárias',
          data: this.diariesGraph.qty || [],
        },
      ]
    },
  },
  mounted(){
    if(this.$root.session.tutorial_seen == false){
      this.showFirst = true
    }
    
    if(localStorage["dashboard-" + this.$root.session.id] != undefined){
  
      let data = JSON.parse(localStorage["dashboard-" + this.$root.session.id]);

      this.tab = data.tab
    }
  },
  async beforeMount() {
    this.loading = true

    await Work.listStatus().then(({data}) => {
      this.worksStatus = Object.keys(data).map(item => {return {id: item, name: data[item]}});
    });
    await Work.list().then(({data}) => {
      this.works = data.map((item) => {        
          if(item.lat != null && item.lng != null){
            this.locations.push({
              lat: parseFloat(item.lat),
              lng: parseFloat(item.lng),
              status: item.status,
              name: item.name,
              methereology: JSON.parse(item.metadata)
            })
          }

          return item;
      });
    });
    
    await this.searchDiaries()

    await this.searchWorks()

    await this.getColors()

    let parent = this
    await Dashboard.getStatistics().then(({data}) => {
      parent.encarregado = data.encarregado
      this.statistics = data
    });


    await User.list().then(({data}) => {

      this.commissioners = data.filter(item => item.roles?.some(role => role.name == "encarregado"));

      this.overseers = data.filter(item => item.roles?.some(role => role.name == "maquinista"));

      this.engineers = data.filter(item => item.roles?.some(role => role.name == "dto"));
    });

    await Diary.listStatus().then(({data}) => {
      this.diariesStatus = Object.keys(data).map(item => {return {id: item, name: data[item]}});
    });
    
    this.loading = false

  },
  methods: {
    onChangeTab() {
      this.worksGraphKey +=1
      this.diariesGraphKey +=1
      this.worksPerformanceGraphKeyR1 += 1
      this.worksPerformanceGraphKeyR2 += 1

      localStorage["dashboard-" + this.$root.session.id] = JSON.stringify({
          tab: this.tab
      });
    },
    getColors() {
      this.colors = [
        '#ffa500',
        '#8470f2',
        '#0000ff'
      ]
    },
    resetWorkersFilters() {
      this.filterWorks= {
        start_date:dayjs().subtract(7, 'days').format("YYYY-MM-DD"),
        end_date:dayjs().format("YYYY-MM-DD"),
        commissioner_id:null,
        overseer_id:null,
        engineer_id:null,
        status: null,
      }
    },
    resetDiariesFilters() {
      this.filterDiaries= {
        start_date:dayjs().subtract(7, 'days').format("YYYY-MM-DD"),
        end_date:dayjs().format("YYYY-MM-DD"),
        commissioner_id:null,
        overseer_id:null,
        engineer_id:null,
        status: null,
      }
    },
    searchWorks() {
      Dashboard.works(this.filterWorks).then(({data}) => {
        this.worksGraph= {
          days:[],
          qty:[]
        }
        Object.entries(data).forEach(e=> {
          this.worksGraph.days.push(e[0])
          this.worksGraph.qty.push(e[1])
        })
        this.worksGraphKey +=1
        this.openWorksFilter = false
      });

      Dashboard.worksCounters().then(({data}) => {
        this.worksCounters= {
          total:data.total,
          finished:data.finished,
          initiated:data.initiated,
          opened:data.opened,
          total_user_works:data.total_user_works,
          total_works:data.total_works,
          total_user_works_initiated:data.total_user_works_initiated,
          total_works_initiated:data.total_works_initiated,
          total_user_works_toInitiate:data.total_user_works_toInitiate,
          total_works_toInitiate:data.total_works_toInitiate,
          total_user_works_finished:data.total_user_works_finished,
          total_works_finished:data.total_works_finished,
        }
      });
    },
    searchDiaries() {
      Dashboard.diaries(this.filterDiaries).then(({data}) => {
        this.diariesGraph= {
          days:[],
          qty:[]
        }
        Object.entries(data).forEach(e=> {
          this.diariesGraph.days.push(e[0])
          this.diariesGraph.qty.push(e[1])
        })
        this.diariesGraphKey +=1
        this.openDiariesFilter = false
      });

      Dashboard.diariesCounters().then(({data}) => {
        this.diariesCounters= {
          total:data.total,
          finished:data.finished,
          initiated:data.initiated
        }
      });
    }
  },
};
</script>
<style scoped>
::v-deep .v-navigation-drawer {
  z-index: 99999;
}
</style>


