<template>
    <v-card elevation="10" :style="this.$root.$vuetify.theme.isDark ? '' : (modalType ? '' : 'background-color: rgba(255, 255, 255, 0.75);')" :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
      <v-card-title class="primary white--text">
        {{$t('shoppings.shopping_list')}}
      </v-card-title>
        <v-container>
            <validation-observer ref="form" v-slot="{ invalid }">
            <v-form @submit.prevent="submit"  class="mb-16">
                <v-progress-linear v-if="loading"
                    indeterminate
                    class="global-loader"
                ></v-progress-linear>
                <fieldset :disabled="loading">
                    <v-row>
                        <v-col cols="12" md="12" v-if="method=='update' && fields.supplier_id != null">
                          <validation-provider v-slot="{ errors }" vid="supplier" :name="$t('globals.supplier')" rules="required">
                              <v-select
                                    :disabled="method=='update'"
                                    v-model="fields.supplier_id"
                                    :items="suppliers"
                                    item-value="id"
                                    :item-text="item => item.name"
                                    menu-props="auto"
                                    :error-messages="errors"
                                    :label="$t('globals.supplier') + ' *'"
                                    prepend-inner-icon="mdi-account"
                                ></v-select>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-menu
                            v-model="pickerDateChoose"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                              <template v-slot:activator="{ on, attrs }">
                              <validation-provider v-slot="{ errors }" vid="Data" :name="$t('shoppings.shopping_date')" rules="required">
                                  <v-text-field
                                      v-model="fields.shopping_date"
                                      :label="$t('shoppings.shopping_date')"
                                      :error-messages="errors"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                  ></v-text-field>
                              </validation-provider>
                              </template>
                              <v-date-picker
                                :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                                v-model="fields.shopping_date"
                                @input="pickerDateChoose = false"
                              ></v-date-picker>
                          </v-menu>
                        </v-col>    
                        <v-col cols="12" md="3">
                          <validation-provider v-slot="{ errors }" vid="total" :name="$t('globals.total')" rules="required">
                              <v-text-field
                                :disabled="true"
                                v-model="fields.total"
                                prepend-inner-icon="mdi-account-details"
                                :label="$t('globals.total') + ' *'"
                                clearable
                                :error-messages="errors"
                              ></v-text-field>
                          </validation-provider>
                        </v-col>                                               
                        <v-col cols="12" md="6">
                          <v-file-input
                              v-if="fields.invoice_path == null"
                              accept=".pdf"
                              :label="$t('invoices.attach')"
                              v-model="fields.invoice_path"
                          ></v-file-input>
                          <IconDownload
                          :text="$t('globals.download')"
                          v-else
                          @click="download(fields)"
                          />
                        </v-col>                     
                    </v-row>
                    <v-row>
                  <v-col cols="9" xs="9" sm="9" md="12">
                    <!-- DESKTOP VERSION -->
                    <v-simple-table dense class="d-none d-lg-block">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center">
                              {{$t('globals.material')}}
                            </th>
                            <th class="text-center">
                              {{$t('globals.quantity')}}
                            </th>
                            <th class="text-center">
                              {{$t('globals.cost')}}
                            </th>
                            <th class="text-center">
                              {{$t('globals.total_cost')}}
                            </th>
                            <th class="text-center">
                              {{$t('globals.options')}}
                            </th>
                          </tr>
                        </thead>
                        <tbody :class="$root.$vuetify.theme.isDark ?  'secondary' : ''">
                          <tr v-for="(element, index) in fields.materials" v-bind:key="index + 10000">
                            <td class="text-center">
                                <label>{{element.material}}</label>
                            </td>
                            <td class="text-center">
                                <label>{{element.qty}}</label>
                            </td>
                            <td class="text-center">
                                <label>{{element.cost}}</label>
                            </td>
                            <td class="text-center">
                                <label>{{element.qty * element.cost}}</label>
                            </td>
                            <td class="text-center">
                              <v-row>
                                <v-col cols="12" md="3">  
                                  <IconRemove
                                      v-if="$root.session.hasPermission(['super', 'shopping_invoices.delete'])"
                                      @on-submit="deleteLine(element)"
                                    />
                                </v-col>
                              </v-row>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <!-- DESKTOP VERSION -->
                    <v-dialog
                      v-model="addMaterial"
                      max-width="800px"
                      >
                      <template v-slot:activator="{ props }">
                          <div class="text-right mt-4">
                          <Tooltip
                              color="warning"
                              :text="$t('shoppings.add_material_invoice')"
                              bottom
                          >
                              <v-btn
                                  class="mx-2"
                                  fab
                                  dark
                                  large
                                  color="warning"
                                  @click="addMaterial = true;"
                                  v-bind="props"
                                  >
                                  <v-icon dark>
                                      mdi-plus
                                  </v-icon>
                              </v-btn>
                          </Tooltip>
                          </div>
                      </template>

                      <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" md="10">
                                  <v-select
                                      v-model="newMaterial.id"
                                      :items="materials"
                                      :item-text="item => item.reference + ' - ' + item.name"
                                      item-value="id"
                                      menu-props="auto"
                                      :label="$t('globals.material')"
                                      prepend-inner-icon="mdi-account"
                                      @change="changedMaterial"
                                  ></v-select>
                              </v-col>     
                              <v-col cols="12" md="2">
                                <Tooltip
                                    color="warning"
                                    :text="$t('shoppings.add_new_material')"
                                    bottom
                                >
                                    <v-btn                  
                                        class="mx-2"
                                        fab
                                        dark
                                        large
                                        color="warning"
                                        @click="addNewMaterial = true;"
                                        >
                                        <v-icon dark>
                                            mdi-plus
                                        </v-icon>
                                    </v-btn>
                                </Tooltip>
                              </v-col>                            
                              <v-col cols="12" md="3">
                                <v-text-field
                                    :disabled="true"
                                    v-model="newMaterial.unit"
                                    prepend-inner-icon="mdi-account-details"
                                    :label="$t('globals.unit')"
                                    clearable
                                ></v-text-field>
                              </v-col>                                    
                              <v-col cols="12" md="9">
                                <v-text-field
                                    v-model="newMaterial.qty"
                                    prepend-inner-icon="mdi-account-details"
                                    type="number"
                                    :label="$t('globals.quantity')"
                                    clearable
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                    v-model="newMaterial.unit_price"
                                    prepend-inner-icon="mdi-account-details"
                                    type="number"
                                    :label="$t('globals.unit_price')"
                                    clearable
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions class="justify-center">
                          <v-btn :disabled="newMaterial.id == null || newMaterial.qty==null || newMaterial.unit_price==null" color="success" @click="insertMaterial()">{{$t('globals.insert')}}</v-btn>
                          <v-btn color="error" right @click="addMaterial = false">{{$t('globals.close')}}</v-btn>
                          </v-card-actions>
                      </v-card>
                  </v-dialog>
                  </v-col>
                </v-row>
                
                <v-row class="mt-6" align="center" justify="space-around">
                    <v-btn :disabled="invalid" depressed color="primary" type="submit">
                    {{$t('globals.save')}}
                    </v-btn>
                </v-row>
                </fieldset>
            </v-form>
            <DialogSuccess :opened="success" @on-ok="onSuccess" >
                {{$t('shoppings.success_saved')}}
            </DialogSuccess>
            </validation-observer>
            
            <v-dialog
              v-model="addNewMaterial"
              max-width="800px">
                <MaterialDialog v-if="addNewMaterial" @reload="getMaterials(); addNewMaterial = false;"/>  
            </v-dialog>
          
        </v-container>
            
    </v-card>
  </template>
  <script>
  import MaterialDialog from '@/components/materials/MaterialDialog.vue';
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  import Company from '@/api/Company.js'
  import Materials from '@/api/Materials.js'
  import Shopping from '@/api/Shopping.js'
  import Tooltip from '@/components/ui/Tooltip.vue';
  import IconDownload from '@/components/ui/IconDownload.vue';
  import IconRemove from '@/components/ui/IconRemove.vue';
  
  export default {
    name: "ShoppingComponent",
    components: {
      MaterialDialog,
      DialogSuccess,
      Tooltip,
      IconDownload,
      IconRemove,
    },
    props: {
     shopping: Object,
     method: String,
     disabled: Boolean,
     modalType: {
      typeof: Boolean,
      default: false
     }
   },
   watch: { 
    shopping: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;
  
          this.fields.id = data.id;
          this.fields.supplier_id = data.supplier_id
          this.fields.total = data.total
          this.fields.invoice_path = data.invoice_path
          this.fields.shopping_date = data.shopping_date
          this.fields.materials = data.materials

          if(this.fields.invoice_path != '' || this.fields.invoice_path != null)
            this.fields.invoiceAlreadyAdded = true

          this.fields.removedMaterials = []
        }
      },
    },
    beforeCreate(){
    },
    data: () => ({
      addNewMaterial: false,
      addMaterial: false,
      newMaterial:{
        id: null,
        qty: null,
        unit_price: null,
        unit: null
      },
      pickerDateChoose: false, 
      fields: {
        id: null,
        supplier_id: null,
        total: null,
        invoice_path: null,
        shopping_date: null,
        materials: [],
        invoiceAlreadyAdded: false,
        removedMaterials:[]
      },
      loading:false,
      error: {
        title: '',
        message: '',
      },
      success: false,
      suppliers:[],
      materials: []
    }),
    mounted(){
      this.fillBaseData();
    },
    methods:{
      changedMaterial(item){
        this.materials.forEach(element => {
          if(element.id == item)
            this.newMaterial.unit = element.abbreviation
        });
      },
      deleteLine(item){
        let i = 0; let pos = 0;
        this.fields.materials.forEach(element => {
          if(element.id == item.id)
            pos = i
          
            i++
        });

        this.fields.removedMaterials.push(this.fields.materials[pos])

        this.fields.materials.splice(pos, 1)
      },
      download(item){
          Shopping.downloadInvoice(item.id).then((response) => {
              let documentName =  "Fatura";

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", documentName + '.pdf');
              document.body.appendChild(link);
              link.click();
              link.remove();
          }).catch(err => {
              this.loading = false;

              if(err.response.status == 422) {

                  this.$refs.form.setErrors(err.response.data.errors);
                  
                  return;
              }

              this.error.title = this.$t('globals.error') + " " + err.response.status;

              this.error.message = err.response.data.message;

          });
      },
      insertMaterial(){
        let material = ''

        this.materials.forEach(element => {
            if(element.id == this.newMaterial.id){
              material = element.description
            }
        });


        let material_object = {
            id: this.newMaterial.id,
            material: material,
            qty: this.newMaterial.qty,
            cost: this.newMaterial.unit_price
        }

        this.fields.materials.push(material_object)

        this.newMaterial.id = null
        this.newMaterial.qty = null

        this.calculateTotal()
      },
      calculateTotal(){
        let custo = 0
        this.fields.materials.forEach(element => {
          custo = custo + (element.cost * element.qty)
          
        });
        this.fields.total = custo
      },
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }

        let domain = '/' + this.$session.get('domain')
        this.$router.push(domain + '/admin/shopping');
      },
      getMaterials(){
        Materials.list().then(({data}) => {
            this.materials = data
        });
      },
      fillBaseData(){
        Company.list().then(({data}) => {
            this.suppliers = data
        });

        Materials.list().then(({data}) => {
            this.materials = data
        });

       
      },
      submit() {
  
        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }
  
          //let data = {...this.fields};

          
          const formData = new FormData()
          formData.append('id', this.fields.id)
          if(this.fields.invoice_path != null)
            if(!this.fields.invoiceAlreadyAdded)
              formData.append('file', this.fields.invoice_path)



          formData.append('supplier_id',  this.fields.supplier_id)
          formData.append('total',  this.fields.total)
          formData.append('shopping_date', this.fields.shopping_date)
          formData.append('materials', JSON.stringify(this.fields.materials))
          formData.append('invoiceAlreadyAdded', this.fields.invoiceAlreadyAdded)
          formData.append('removedMaterials', JSON.stringify(this.fields.removedMaterials))

   
  
         
  
          Shopping[this.method](formData, this.fields.id).then(() => {
  
            this.success = true;
  
            this.loading = false;
            
          }).catch(err => {
            this.loading = false;
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = this.$t('globals.error') + " " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })
      }
    },
    computed: {
      
    }
  };
  </script>
  