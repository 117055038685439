<template>
  <v-app>
    <div class="bg-email-confirmation">
    </div>
    <v-main>
      <v-container fill-height>
        <v-row justify="center" align="center">
          <v-col cols="12">

            <div class="d-flex justify-center mt-12" style="left: 0; right: 0; top: 0; position: absolute;">
              <v-img
              lazy-src="@/assets/icons/persona dreamdeal tech.png"
              src="@/assets/icons/persona dreamdeal tech.png"
              max-width="450"
              contain
              ></v-img>
            </div>

            <v-container v-if="!allSuccess">
            <h1 v-if="!error" class="h1 pb-8 text-center">Confirmação de Email</h1>
            
            <validation-observer
              ref="email"
              v-slot="{ invalid }"
            >
              <v-form @submit.prevent="submit" v-if="!error">
                <v-row>
                  <v-col
                      cols="12"
                      md="6"
                      offset-md="4"
                    >
                    <v-row>
                      <v-col
                        cols="12"
                      >
                        <validation-provider name="Password" vid="password" rules="required" v-slot="{ errors }">
                          <v-text-field color="#111b2d" :class="$root.$vuetify.theme.isDark ? 'center-button' : 'text-white center-button'" v-model="password" label="Password *" type="password" prepend-inner-icon="mdi-form-textbox-password" :error-messages="errors"></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <validation-provider name="Confirmação Password" vid="password_confirmation" rules="required|password:@password" v-slot="{ errors }">
                          <v-text-field color="#111b2d" :class="$root.$vuetify.theme.isDark ? 'center-button' : 'text-white center-button'" v-model="password_confirmation" label="Confirmação Password *" type="password" prepend-inner-icon="mdi-form-textbox-password" :error-messages="errors"></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    offset-md="4"
                  >
                    <v-btn
                      depressed
                      color="#111b2d"
                      class="center-button"
                      style="color: white;"
                      type="submit"
                      :disabled="invalid"
                      large
                      rounded
                    >
                      Validar Email
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <v-row v-else>
                <v-col
                    cols="12"
                    md="6"
                    offset-md="3"
                  >
                  <h2 class="error--text text-center">Não é possível ativar a sua conta</h2>
                  <p class="body-1 text-center mt-5">Para ativar a sua conta, entre em contacto para: <a class="text-decoration-none font-weight-bold secundary--text" :href="'mailto:' + email">{{email}}</a></p>
                  <p>Erro: {{errorText}}</p>
                  <v-row class="mt-5">
                    <v-col
                      cols="12"
                      md="6"
                      offset-md="3"
                    >
                      <v-btn
                        depressed
                        color="#111b2d"
                        class="center-button"
                        style="color: white;"
                        type="button"
                        large
                        block
                        rounded
                        link
                        @click="goTo()"
                      >
                        Página Inicial
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </validation-observer>
            <div class="d-flex justify-center mt-8" v-if="false">
              <span class="text-h6 mr-3">Powered By: </span>
              <v-img
              lazy-src="@/assets/logo_dream.png"
              src="@/assets/logo_dream.png"
              max-width="125"
              contain
              ></v-img>
            </div>
          </v-container>
          <v-container v-if="allSuccess">
            <v-row>
              <v-col cols="12" md="6" offset-md="3">
                <h1 class="h1 pb-8 text-center">Sucesso na confirmação de email</h1>
                <p class="body-1 text-center mt-5">O seu email foi confirmado com sucesso. Dirija-se agora á página de login para entrar na sua conta</p>
              </v-col>
            </v-row>
            <v-row class="mt-5">
                    <v-col
                      cols="12"
                      md="6"
                      offset-md="3"
                    >
                      <v-btn
                        depressed
                        color="#111b2d"
                        class="center-button"
                        style="color: white;"
                        type="button"
                        large
                        block
                        link
                        @click="goTo()"
                      >
                        Página Inicial
                      </v-btn>
                    </v-col>
                  </v-row>           
          </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<style>
.text-white input {
    color: white !important;
  }
  .text-white label {
    color: white !important;
  }
  .text-white i {
    color: white !important;
  }
  .text-white v-messages__wrapper{
    color: white !important;
  }

.bg-email-confirmation {
    background: url( '../assets/login/image00007.jpeg') no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
  }
  @media (min-width: 600px) {
    .bg-email-confirmation {
    
      width: -webkit-fill-available;
      height: -webkit-fill-available;
      position: absolute;
      background: url( '../assets/login/image00007.jpeg') no-repeat;
      
      background-size: cover;
      /*-webkit-filter: grayscale(100%); 
      filter: grayscale(100%);
      transform: scale(1.1);*/
    }

    .center-button{
      width: 80%;
    }
}
</style>
<script>
import User from "@/api/User.js"

export default {
  components: {
  },
  data: () => ({
    allSuccess: false,
    password: "",
    password_confirmation: "",
    error: false,
    errorText:"",
  }),
  beforeCreate(){

    document.title = "Report Lake - Confirmação Email";

    if(localStorage['auth'] == 1){
      this.$router.push('/admin');
    }
  },
  computed: {
    email: function(){
      return process.env.VUE_APP_EMAIL;
    }
  },
  methods:{
    goTo(){
      window.open('/')
    },
    submit () {
        this.$refs.email.validate().then((valid) => {
          if (!valid) {
            return;
          }

          let payload = {
            id: this.$route.params.id,
            password: this.$encryptValue(this.password),
            password_confirmation: this.$encryptValue(this.password_confirmation),
          }

          User.confirmEmail(payload)
            .then(() => {

                this.allSuccess = true
                
            }).catch(err => {

              if(err.response.status == 422) {

                this.$refs.email.setErrors(err.response.data.errors);
                
                return;
              }

              this.error = true;

              this.errorText = err.message

          });
        });
      },
  }
};
</script>
