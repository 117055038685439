<template>
  <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
      <v-toolbar class="primary" dark v-show="!quick">
        <v-tabs v-model="tab" id="global-tab-unit" active-class="white--text">
          <v-tabs-slider color="warning"></v-tabs-slider>
          <v-tab>{{$t('work_units.tabs.general')}}</v-tab>
          <v-tab>{{$t('work_units.tabs.history')}}</v-tab>
        </v-tabs>
      </v-toolbar>
    <v-tabs-items v-model="tab" :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
      <v-tab-item class="mt-6" :key="1">
        <v-container>
          <validation-observer ref="form" v-slot="{ invalid }">
            <v-form @submit.prevent="submit"  class="mb-16">
              <v-progress-linear v-if="loading"
                  indeterminate
                  class="global-loader"
                ></v-progress-linear>
              <fieldset :disabled="loading">
                <v-row>
                  <v-col cols="12" :md="quick ? '6' : '3'">
                    <validation-provider v-slot="{ errors }" vid="code" :name="$t('globals.code')" rules="required">
                      <v-text-field
                        v-model="fields.code"
                        prepend-inner-icon="mdi-barcode"
                        :label="$t('globals.code') + '*'"
                        clearable
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" :md="quick ? '6' : '3'">
                    <validation-provider v-slot="{ errors }" vid="name" :name="$t('globals.name')" rules="required">
                      <v-text-field
                        v-model="fields.name"
                        prepend-inner-icon="mdi-form-textbox"
                        :label="$t('globals.name') +'*'"
                        clearable
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" :md="quick ? '6' : '3'">
                    <validation-provider v-slot="{ errors }" vid="unit" :name="$t('globals.unit')" rules="required">
                      <v-text-field
                        v-if="false"
                        v-model="fields.unit"
                        prepend-inner-icon="mdi-form-textbox"
                        :label="$t('globals.unit') + '*'"
                        clearable
                        :error-messages="errors"
                      ></v-text-field>
                      <v-select
                          v-model="fields.unit_management_id"
                          :items="unitsManagement"
                          item-value="id"
                          :item-text="item => item.abbreviation"
                          menu-props="auto"
                          :error-messages="errors"
                          :label="$t('globals.unit') + '*'"
                          prepend-inner-icon="mdi-form-textbox"
                      ></v-select>
                    </validation-provider>
                  </v-col>          
                  <v-col cols="12" :md="quick ? '6' : '3'">
                    <validation-provider v-slot="{ errors }" vid="value" :name="$t('globals.cost')" rules="required">
                      <v-text-field
                        v-model="fields.value"
                        :prepend-inner-icon="$currency_icon()"
                        :label="$t('globals.cost') +  '*'"
                        clearable
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>      
                  <v-col cols="12" :md="quick ? 'auto' : '12'">
                    <validation-provider v-slot="{ errors }" vid="description" :name="$t('globals.description')" rules="|">
                      <v-textarea
                        v-model="fields.description"
                        :label="$t('globals.description')"
                        clearable
                        :error-messages="errors"
                      ></v-textarea>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="4">
                    <validation-provider v-slot="{ errors }" vid="chapter_id" :name="$t('globals.chapter')" rules="|">
                      <v-autocomplete
                        v-model="fields.chapter_id"
                        :items="chapters"
                        clearable
                        :error-messages="errors"
                        :item-text="item => item.code == null ? item.description : item.code + ' - ' + item.description"
                        item-value="id"
                        :label="$t('globals.chapter')"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="4" v-if="fields.chapter_id != null">
                    <validation-provider v-slot="{ errors }" vid="class_id" :name="$t('globals.chapter_class')" rules="required">
                      <v-autocomplete
                        v-model="fields.class_id"
                        :items="getClasses"
                        :error-messages="errors"
                        clearable
                        item-value="id"
                        :item-text="item => item.code == null ? item.description : item.code + ' - ' + item.description"
                        :label="$t('globals.chapter_class') + ' *'"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row class="mt-6" align="center" justify="space-around">
                  <v-col align="center" justify="space-around">
                    <v-btn :disabled="invalid" depressed :class="($root.$vuetify.theme.isDark ? 'white primary--text' : 'primary') + ' mr-5 ml-5 mb-5'" type="submit">
                      {{$t('globals.save')}}
                    </v-btn>
                  </v-col>
                </v-row>
              </fieldset>
            </v-form>
            <DialogSuccess :opened="success" @on-ok="onSuccess" >
                {{$t('work_units.success')}}
            </DialogSuccess>
          </validation-observer>
        </v-container>
      </v-tab-item>
      <v-tab-item class="mt-6" :key="2">
        <v-container>
          <v-data-table 
            :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'"
            :headers="headers"
            :items="units_history"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
            locale="pt-pt"
        >

        <template v-slot:item.price="{ item }">
          {{ $currency(item.price) }}
        </template>
        </v-data-table>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
    </v-card>
</template>
<script>
import Unit from "@/api/Unit.js";
import Chapter from "@/api/Chapter.js"
import UnitsHistory from "@/api/UnitsHistory.js"
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import UnitsManagement from "@/api/UnitsManagement.js"

export default {
  components: {
    DialogSuccess
  },
  props: {
   unit: Object,
   method: String,
   disabled: Boolean,
   reload: {
    typeof: Boolean,
    default: true
   },
   quick: {
    typeof:Boolean,
    default: false
   }
 },
 watch: { 
    'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchHistory()}, 10);
        },
        deep: true,
      },
    unit: {
      immediate: true, 
      handler (val) {
        this.fields.theme = this.$themes[0];

        if(!val) {
          return;
        }

        let data = {...val};

        this.fields = data;
      }
    },
  },
  beforeCreate(){
  },
  data: () => ({
    units_history: [],
    total: 0,
    options: {},
    tab: null,
    fields: {
      id: null,
      code: null,
      name:null,
      description: null,
      unit_management_id:null,
      unit: null,
      value: null,
      chapter_id: null,
      class_id: null
    },
    loading:false,
    error: {
      title: '',
      message: '',
    },
    success: false,
    unitsManagement: [],
    chapters: []
  }),
  mounted(){
    this.fillBaseData();
  },
  methods:{
    fillBaseData(){
      UnitsManagement.list().then(({data}) => {
          this.unitsManagement = data
      });
      Chapter.list().then(({data})  =>  {
        this.chapters = data
      })
    },
    searchHistory(){

      let filter = {...this.filter};


      let request = {
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        page: this.options.page,
        limit: this.options.itemsPerPage,
        unit_id: this.$route.params.id
      };

      Object.assign(request, filter);

      localStorage["units-history-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      UnitsHistory.search(request).then(response => {

        let data = response.data;

        this.total = data.total;

        this.units_history = data.data.map((item) => {

          return item;
        });


      });
      },
    onSuccess() {
      if(this.fields.id != null) {
        this.success = false
        return
      }
      let domain = '/' + this.$session.get('domain')

      if(this.reload)
        this.$router.push(domain + '/admin/elements/units');
      else{
        this.$emit('close-update')
        this.success = false
      }
    },
    submit() {
      this.$refs.form.validate().then((result) => {
        this.loading = true;
        
        if(!result){
          this.loading = false;
          return;
        }
        this.unitsManagement.forEach(element => {
          if(element.id == this.fields.unit_management_id)
            this.fields.unit = element.abbreviation
        });

      

        Unit[this.method](this.fields, this.fields.id).then(() => {

          this.success = true;

          this.loading = false;
          
        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
            }

            this.error.title = this.$t('globals.error') + " " + err.response.status;

            this.error.message = err.response.data.message;

          });
      })
    }
  },
  computed: {
    getClasses(){
      let classes = []
      this.chapters.forEach(element => {
        if(element.id == this.fields.chapter_id)
          classes = element.chapter_classes
      });
      
      return classes
    },
    headers(){
      let he = [
        {
          text: this.$t('work_units.work_reference'),
          value: 'budget.work_reference'
        },
        {
          text: this.$t('globals.work'),
          value: 'budget.work_name'
        },
        {
          text: this.$t('globals.price'),
          value: 'price'
        },
        {
          text: this.$t('globals.unit'),
          value: 'unit_measure'
        },
        {
          text: this.$t('globals.data'),
          value: 'created_at'
        }
      ]

      return he
    },
    tableFooter(){
      let t = this.$t("globals.lines_datatable")
      let a = [5, 10, 15, 20];
      return {
        'items-per-page-options': a,
        'items-per-page-text': t
      }
    },     
  }
};
</script>
<style>

#global-tab-unit .v-tabs-bar{
        width: 100% !important;
    }

    #global-tab-unit .v-tabs-slider-wrapper{
        margin-bottom: 1vh !important;
    }

@media (min-width: 600px) {
    #global-tab-unit .v-tabs-bar{
        left: 50%;
        -webkit-transform: translate(-50%, 0%); 
        transform: translate(-50%, 0%);
    }

    #global-tab-unit .v-tabs-slider-wrapper{
        margin-bottom: 1vh !important;
    }
}

#global-tab-unit div.theme--light.v-tabs-items{
    background-color: transparent !important;
}
</style>