import BaseApi from "./BaseApi";

export default class Import extends BaseApi{
    construct() {}
    
    static async create(params) {
        return await Import.getAxiosInstance().post(Import.getFullApiUrl(),params);
    }

    static async getTemplate(params) {
        let url = Import.getFullApiUrl() + 'template';

        return Import.getAxiosInstance().get(url, {params:params});
    }
    
    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/import/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/import/";
    }
}
