import BaseApi from "./BaseApi";

export default class Configurations extends BaseApi{
    construct() {}

    static async update(params, id) {
        return await Configurations.getAxiosInstance().put(Configurations.getFullApiUrl() + id,params);
    }

    static async address(params, id) {
        return await Configurations.getAxiosInstance().put(Configurations.getFullApiUrl() + 'address/' + id,params);
    }

    static async get(tenant = null) {
        return await Configurations.getAxiosInstance().get(Configurations.getFullApiUrl(tenant));
    }

    static getInstance(data) {
        let config = new Configurations(data);

        Object.assign(config, data);

        config.features = config.features.map((item) => {
            return item.name;
        });

        config.features_id = config.features.map((item) => {
            return item.feature_id;
        });

        return config;
    }

    hasFeature(features = []) {

        return this.features.some(feature => features.indexOf(feature) >= 0);
    }
  

    static getFullApiUrl(tenant = null){
        let domain = location.pathname.split('/')[1]

        if(tenant != null)
            domain = tenant
            
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/configurations/";
    }
}