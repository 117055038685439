import BaseApi from "./BaseApi";

export default class Client extends BaseApi{
    construct() {}
    
 
    static async search(params) {
        return await Client.getAxiosInstance().get(Client.getFullApiUrl(),{params: params});
    }

    static async searchWorks(params) {
        return await Client.getAxiosInstance().get(Client.getFullApiUrl() + 'works' ,{params: params});
    }

    static async create(params) {
        return await Client.getAxiosInstance().post(Client.getFullApiUrl(),params);
    }

    static async find(id) {
        return await Client.getAxiosInstance().get(Client.getFullApiUrl() + id);
    }

    static async update(params, id) {
        return await Client.getAxiosInstance().put(Client.getFullApiUrl() + id,params);
    }
    
    static async list(params) {
        let url = Client.getFullApiUrl() + "list";

        return Client.getAxiosInstance().get(url,{params: params});
    }
    
    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/client/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/client/";
    }
}
