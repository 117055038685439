<template>
    <v-card elevation="10" :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75);'" :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
        <v-container fluid>
              <validation-observer ref="form" v-slot="{ invalid }">
                <v-form @submit.prevent="submit"  class="mb-16">
                          <v-progress-linear v-if="loading"
                              indeterminate
                              class="global-loader"
                          ></v-progress-linear>
                          <fieldset :disabled="loading">
                            <v-card v-if="!loading">
                                <v-card-title :class="$root.$vuetify.theme.isDark ? 'primary' : 'primary white--text'">
                                    {{$t('globals.budget')}} {{ fields.reference != null ? '- ' + fields.reference : '' }}
                                    <v-spacer></v-spacer>
                                    <v-speed-dial
                                        v-if="method=='update'"
                                        v-model="speedDial"
                                        direction="bottom"
                                        :open-on-hover="true"
                                        >
                                        <template v-slot:activator>
                                            <v-tooltip right color="primary">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                    v-bind="attrs" v-on="on"
                                                    v-model="speedDial"
                                                    :class="$root.$vuetify.theme.isDark ? 'white primary--text elevation-0' : 'primary elevation-0'"
                                                    dark
                                                    >
                                                    <v-icon v-if="speedDial">
                                                        mdi-close
                                                    </v-icon>
                                                    <v-icon v-else>
                                                        mdi-dots-horizontal
                                                    </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{$t('globals.options')}}</span>
                                                </v-tooltip>
                                            </template>   
                                            <v-tooltip right color="warning">
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs" v-on="on"
                                                    fab
                                                    dark
                                                    small
                                                    color="warning"
                                                    large
                                                    @click="exportBudgetUnitsList()"
                                                >
                                                    <v-icon>mdi-domain</v-icon>
                                                </v-btn>
                                                </template>
                                                <span>{{$t('budgeting.export_work_unit')}}</span>
                                            </v-tooltip> 
                                            <v-tooltip right color="success">
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs" v-on="on"
                                                    fab
                                                    dark
                                                    small
                                                    color="success"
                                                    large
                                                    @click="exportBudget()"
                                                >
                                                    <v-icon>mdi-file-excel-box</v-icon>
                                                </v-btn>
                                                </template>
                                                <span>{{$t('globals.export_budget')}}</span>
                                            </v-tooltip>           
                                        </v-speed-dial>
                                </v-card-title>
                            </v-card>
                                <v-row class="mt-4">
                                    <v-col cols="12" md="9">
                                    <validation-provider v-slot="{ errors }" vid="work_reference" :name="$t('work_units.work_reference')" rules="required">
                                        <v-text-field
                                            :disabled="lock_budget"
                                            v-model="fields.work_reference"
                                            prepend-inner-icon="mdi-account-details"
                                            :error-messages="errors"
                                            :label="$t('work_units.work_reference') + ' *'"
                                            clearable
                                        ></v-text-field>
                                    </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                    <validation-provider v-slot="{ errors }" vid="work" :name="$t('globals.state')" rules="required">
                                        <v-select
                                            v-model="fields.status"
                                            :disabled="method=='create' || lock_budget"
                                            :items="status"
                                            menu-props="auto"
                                            item-text="text"
                                            item-value="value"
                                            :label="$t('globals.state')"
                                            prepend-inner-icon="mdi-account"
                                            :error-messages="errors"
                                            @change="changeStatus"
                                        ></v-select>
                                    </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                    <validation-provider v-slot="{ errors }" vid="work" :name="$t('globals.work_name')" rules="required">
                                        <v-text-field
                                            :disabled="lock_budget"
                                            v-model="fields.work_name"
                                            prepend-inner-icon="mdi-account-details"
                                            :error-messages="errors"
                                            :label="$t('globals.work_name') + ' *'"
                                            clearable
                                        ></v-text-field>
                                    </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                    <validation-provider v-slot="{ errors }" vid="work" :name="$t('globals.address.address')" rules="|">
                                        <v-text-field
                                            :disabled="lock_budget"
                                            v-model="fields.address"
                                            prepend-inner-icon="mdi-account-details"
                                            :error-messages="errors"
                                            :label="$t('globals.address.address')"
                                            clearable
                                        ></v-text-field>
                                    </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="11">
                                    <validation-provider v-slot="{ errors }" vid="client" :name="$t('globals.client')" rules="required">
                                        <v-select
                                            v-model="fields.client_id"
                                            :disabled="lock_budget"
                                            :items="clients"
                                            item-value="id"
                                            :item-text="item => item.name"
                                            menu-props="auto"
                                            :label="$t('globals.client') + ' *'"
                                            prepend-inner-icon="mdi-account"
                                            :error-messages="errors"
                                            @change="changeStatus"
                                        ></v-select>
                                    </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="1">
                                        <v-dialog
                                        v-model="addClient"
                                        max-width="800px"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                            class="primary"
                                            dark
                                            fab
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <AddClient 
                                            v-if="addClient"
                                            @close="addClient = false"
                                            @close-update="addClient = false; fillBaseData()"
                                        />
                                        </v-dialog>
                                    </v-col>
                                    <v-col cols="12" md="2" v-show="false">
                                        <v-switch 
                                            class="text-caption"
                                            color="success"
                                            v-model="add_general_charges"
                                            label="Encargos gerais"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="2" v-show="false">
                                        <validation-provider v-slot="{ errors }" vid="general_charges" :name="$t('globals.general_charges')" rules="between|required">
                                            <v-text-field
                                                v-model="fields.general_charges"
                                                type="number"
                                                :disabled="!add_general_charges"
                                                prepend-inner-icon="mdi-account-details"
                                                :error-messages="errors"
                                                :label="$t('globals.general_charges') + ' *'"
                                                @change="calculateBudget()"
                                                clearable
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field
                                            :disabled="true"
                                            :value="$currency(fields.budget)"
                                            prepend-inner-icon="mdi-account-details"
                                            :label="$t('globals.cost')"
                                            clearable
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                    <validation-provider v-slot="{ errors }" vid="k" name="k" rules="required">
                                        <v-text-field
                                            v-model="fields.k"
                                            prepend-inner-icon="mdi-account-details"
                                            :error-messages="errors"
                                            label="K (%) *"
                                            type="number"
                                            :disabled="lock_budget || canWrite == false"
                                            @keyup="globalKChanged"                                 
                                            clearable
                                        ></v-text-field>
                                        <!--@change="calculateBudget"-->
                                    </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                    <v-text-field
                                        :disabled="true"
                                        :value="isNaN(fields.budget_witk_k) ? '' : $currency(fields.budget_witk_k)"
                                        prepend-inner-icon="mdi-account-details"
                                        :label="$t('budgeting.budget_k')"
                                        clearable
                                        ></v-text-field>
                                    </v-col>
                                </v-row>    

                                <!-- 
                                    ************
                                    ************
                                    ************
                                    ADICIONAR CAPITULOS
                                    ************
                                    ************
                                    ************
                                -->                          
                                <Chapters :lock_k_fields="lock_k_fields" @update-budget="updateBudget" :fields="fields" :lock_budget="lock_budget" return-object/>
                                <!--
                                    ***********
                                    ***********
                                    ***********
                                    WORK UNITS
                                    ***********
                                    ***********
                                    ***********
                                -->                               
                                <v-row v-if="false" class="mt-8">
                                    <v-expansion-panels focusable v-for="(item, index) in fields.budget_units" :key="index">
                                        <v-expansion-panel class="mt-4">
                                            <v-expansion-panel-header :class="$root.$vuetify.theme.isDark ? 'primary' : ''">
                                                <v-row no-gutters>
                                                    <v-col cols="12">
                                                        <span class="text-body-1 font-weight-light">{{ item.code }} - {{ item.unit }}</span> 
                                                        <div class="text-right">
                                                            <span>{{ item.manual ? $currency(item.qty * item.cost) : calculateUnitTotal(item) }}  </span>
                                                        </div> 
                                                    </v-col>
                                                    <v-col
                                                        cols="12" md="8"
                                                        class="text--secondary mt-2"
                                                    >
                                                        <v-fade-transition leave-absolute>
                                                        <v-row
                                                            no-gutters
                                                            style="width: 100%"
                                                        >
                                                            <v-col cols="4">
                                                                {{$t('globals.cost')}}: {{ $currency(item.cost) }}
                                                            </v-col>
                                                            <v-col cols="4">
                                                                {{$t('globals.unit')}}: {{ item.unit_measure }}
                                                            </v-col>
                                                            <v-col cols="4">
                                                                {{$t('globals.quantity')}}: {{ item.qty }}
                                                            </v-col>
                                                        </v-row>
                                                        </v-fade-transition>
                                                    </v-col>
                                                    </v-row>
                                                     
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                                                <v-row class=mt-4>
                                                    <v-checkbox
                                                        v-model="item.manual"
                                                        :label="$t('budgeting.manual_calculation')"
                                                        color="red"
                                                        hide-details
                                                    ></v-checkbox>
                                                    <v-spacer></v-spacer>
                                                    <div v-if="!lock_budget">
                                                        <IconRemove
                                                            v-if="$root.session.hasPermission(['super', 'budget_work.delete'])"
                                                            @on-submit="deleteBudgetUnit(item)"
                                                        />
                                                    </div>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="12" md="2">
                                                        <v-text-field
                                                            v-model="item.qty"
                                                            type="number"
                                                            prepend-inner-icon="mdi-account-details"
                                                            @change="calculateNewUnitCosts(item)"
                                                            :label="$t('globals.quantity')"
                                                            clearable
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="2" v-if="item.manual">
                                                        <v-text-field
                                                            v-model="item.cost"
                                                            prepend-inner-icon="mdi-account-details"
                                                            :label="$t('globals.cost') + ' (' + $store.state.coin + ')'"
                                                            @change="calculateNewUnitCosts(item)"
                                                            clearable
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="2" v-if="item.manual">
                                                        <v-text-field
                                                            :disabled="true"
                                                            v-model="item.total_cost"
                                                            prepend-inner-icon="mdi-account-details"
                                                            :label="$t('globals.total_cost')"
                                                            clearable
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>





                                            <div v-if="!item.manual">
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- MateriaisS SIMPLES -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->                                                
                                                <v-expansion-panels focusable>
                                                    <v-expansion-panel class="mt-4">
                                                            <v-expansion-panel-header :class="($root.$vuetify.theme.isDark ? 'primary' : 'primary')">
                                                                <template v-slot:actions>
                                                                    <v-icon color="white">
                                                                    $expand
                                                                    </v-icon>
                                                                </template>
                                                                <span class="white--text elevation-0 text-h6">{{ item.budget_materials.length }} - {{$t('budgeting.material_singular_plural')}}<span class="ml-4"><small>({{ priceQtyTotal(item.budget_materials) }})</small></span></span>                           
                                                            </v-expansion-panel-header>
                                                        <v-expansion-panel-content :class="($root.$vuetify.theme.isDark ? 'secondary' : '')">
                                                    
                                                            <v-card-text>
                                                            <v-dialog
                                                                v-model="addMaterial"
                                                                max-width="800px"
                                                                >
                                                                <v-toolbar class="primary white--text">{{$t('globals.material')}}</v-toolbar>
                                                                <template v-slot:activator="{ props }">
                                                                    <div class="text-right mt-4">                                                                        
                                                                    <Tooltip
                                                                        color="warning"
                                                                        :text="$t('budgeting.add_new_material')"
                                                                        bottom
                                                                    >
                                                                        <v-btn
                                                                            v-if="!lock_budget"
                                                                            class="mx-2"
                                                                            fab
                                                                            dark
                                                                            large
                                                                            color="warning"
                                                                            @click="addMaterial = true; choosedUnit = item;"
                                                                            v-bind="props"
                                                                            >
                                                                            <v-icon dark>
                                                                                mdi-plus
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </Tooltip>
                                                                    </div>
                                                                </template>

                                                                <v-card>
                                                                    <v-card-text>
                                                                        <v-col cols="12">
                                                                            <v-autocomplete
                                                                                v-model="newMaterial.id"
                                                                                :items="materials"
                                                                                :item-text="item => item.name"
                                                                                item-value="id"
                                                                                menu-props="auto"
                                                                                :label="$t('globals.material')"
                                                                                prepend-inner-icon="mdi-account"
                                                                            ></v-autocomplete>
                                                                        </v-col>
                                                                        <v-col cols="12" md="4">
                                                                            <v-text-field
                                                                                v-model="newMaterial.qty"
                                                                                type="number"
                                                                                prepend-inner-icon="mdi-account-details"
                                                                                :label="$t('globals.quantity')"
                                                                                clearable
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                    </v-card-text>
                                                                    <v-card-actions class="justify-center">
                                                                    <v-btn :disabled="newMaterial.id == null || newMaterial.qty==null" color="success" @click="addNewMaterial()">{{$t('globals.insert')}}</v-btn>
                                                                    <v-btn color="error" right @click="addMaterial = false">{{$t('globals.close')}}</v-btn>
                                                                    </v-card-actions>
                                                                </v-card>
                                                            </v-dialog>
                                                            <v-col cols="9" xs="9" sm="9" md="12" v-if="item.budget_materials.length > 0">
                                                                <!-- DESKTOP VERSION -->
                                                                <v-simple-table dense class="d-none d-lg-block">
                                                                <template v-slot:default>
                                                                    <thead>
                                                                    <tr>
                                                                        <th class="text-center" width="50%">
                                                                            {{$t('globals.material')}}
                                                                        </th>
                                                                        <th class="text-center" width="5%">
                                                                            {{$t('globals.quantity')}}
                                                                        </th>
                                                                        <th class="text-center" width="20%">
                                                                            {{$t('globals.cost')}}
                                                                        </th>
                                                                        <th class="text-center" width="20%">
                                                                            {{$t('globals.total_cost')}}
                                                                        </th>
                                                                        <th class="text-center" width="5%">
                                                                            {{$t('globals.options')}}
                                                                        </th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody :class="$root.$vuetify.theme.isDark ?  'secondary' : ''">
                                                                    <tr v-for="(element, index) in item.budget_materials" v-bind:key="index + 10000">
                                                                        <td class="text-center">
                                                                            <label>{{element.material}}</label>
                                                                        </td>
                                                                        <td class="text-center">
                                                                            <label>{{element.qty}}</label>
                                                                        </td>
                                                                        <td class="text-center">
                                                                            <v-text-field class="centered-input"
                                                                                :append-icon="$currency_icon()"
                                                                                style="margin: auto; width: 50%;" single-line type="number" flat v-model="element.cost"

                                                                            ></v-text-field>
                                                                        </td>
                                                                        <td class="text-center">
                                                                            <label>{{ $currency(element.cost * element.qty) }}</label>
                                                                        </td>
                                                                        <td class="text-center">
                                                                        <v-row>
                                                                            <v-col cols="12" md="3" v-if="!lock_budget">                  
                                                                                <IconRemove
                                                                                    v-if="$root.session.hasPermission(['super', 'budget_work.delete'])"
                                                                                    @on-submit="deleteMaterial(item, element)"
                                                                                />
                                                                            </v-col>
                                                                        </v-row>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </template>
                                                                </v-simple-table>
                                                                <!-- DESKTOP VERSION -->
                                                            </v-col>
                                                            <v-col cols="9" xs="9" sm="9" md="12" v-else class="text-center">{{$t('works.no_data')}}</v-col>

                                                            </v-card-text>
                                                        </v-expansion-panel-content>
                                                    </v-expansion-panel>
                                                </v-expansion-panels>
                                                <v-divider></v-divider>

                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- Materiais COMPOSTOS -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->      
                                                <v-expansion-panels focusable>
                                                    <v-expansion-panel>
                                                            <v-expansion-panel-header :class="($root.$vuetify.theme.isDark ? 'primary' : 'primary')">
                                                                <template v-slot:actions>
                                                                    <v-icon color="white">
                                                                    $expand
                                                                    </v-icon>
                                                                </template>
                                                                <span class="white--text elevation-0 text-h6">{{ item.budget_materials_composite.length }} - {{$t('globals.materials_composite')}}<span class="ml-4"><small>({{ priceQtyTotal(item.budget_materials_composite) }})</small></span></span>                    
                                                            </v-expansion-panel-header>
                                                        <v-expansion-panel-content :class="($root.$vuetify.theme.isDark ? 'secondary' : '')">
                                                    
                                                            <v-card-text>
                                                            <v-dialog
                                                                v-model="addMaterialComposite"
                                                                max-width="800px"
                                                                >
                                                                <v-toolbar class="primary white--text">{{$t('globals.material_composite')}}</v-toolbar>
                                                                <template v-slot:activator="{ props }">
                                                                    <div class="text-right mt-4">
                                                                    <Tooltip
                                                                        color="warning"
                                                                        :text="$t('budgeting.add_new_composite')"
                                                                        bottom
                                                                    >
                                                                        <v-btn                                                                        
                                                                            v-if="!lock_budget"
                                                                            class="mx-2"
                                                                            fab
                                                                            dark
                                                                            large
                                                                            color="warning"
                                                                            @click="addMaterialComposite = true; choosedUnit = item;"
                                                                            v-bind="props"
                                                                            >
                                                                            <v-icon dark>
                                                                                mdi-plus
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </Tooltip>
                                                                    </div>
                                                                </template>

                                                                <v-card>
                                                                    <v-card-text>
                                                                        <v-col cols="12">
                                                                            <v-autocomplete
                                                                                v-model="newMaterialComposite.id"
                                                                                :items="materials_composite"
                                                                                :item-text="item => item.name"
                                                                                item-value="id"
                                                                                menu-props="auto"
                                                                                :label="$t('globals.material_composite')"
                                                                                prepend-inner-icon="mdi-account"
                                                                            ></v-autocomplete>
                                                                        </v-col>
                                                                        <v-col cols="12" md="4">
                                                                            <v-text-field
                                                                                v-model="newMaterialComposite.qty"
                                                                                type="number"
                                                                                prepend-inner-icon="mdi-account-details"
                                                                                :label="$t('globals.quantity')"
                                                                                clearable
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                    </v-card-text>
                                                                    <v-card-actions class="justify-center">
                                                                    <v-btn :disabled="newMaterialComposite.id == null || newMaterialComposite.qty==null" color="success" @click="addNewMaterialComposite(item)">{{$t('globals.insert')}}</v-btn>
                                                                    <v-btn color="error" right @click="addMaterialComposite = false">{{$t('globals.close')}}</v-btn>
                                                                    </v-card-actions>
                                                                </v-card>
                                                            </v-dialog>
                                                            <v-col cols="9" xs="9" sm="9" md="12" v-if="item.budget_materials_composite.length > 0">
                                                                <!-- DESKTOP VERSION -->
                                                                <v-simple-table dense class="d-none d-lg-block">
                                                                <template v-slot:default>
                                                                    <thead>
                                                                    <tr>
                                                                        <th class="text-center" width="50%">
                                                                            {{$t('globals.material_composite')}}
                                                                        </th>
                                                                        <th class="text-center" width="5%">
                                                                            {{$t('globals.quantity')}}
                                                                        </th>
                                                                        <th class="text-center" width="20%">
                                                                            {{$t('globals.cost')}}
                                                                        </th>
                                                                        <th class="text-center" width="20%">
                                                                            {{$t('globals.total_cost')}}
                                                                        </th>
                                                                        <th class="text-center" width="5%">
                                                                            {{$t('globals.options')}}
                                                                        </th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody :class="$root.$vuetify.theme.isDark ?  'secondary' : ''">
                                                                    <tr v-for="(element, index) in item.budget_materials_composite" v-bind:key="index + 30000">
                                                                        <td class="text-center">
                                                                            <label>{{element.material}}</label>
                                                                        </td>
                                                                        <td class="text-center">
                                                                            <label>{{element.qty}}</label>
                                                                        </td>
                                                                        <td class="text-center">
                                                                            <v-text-field class="centered-input"
                                                                                :append-icon="$currency_icon()"
                                                                                style="margin: auto; width: 50%;" single-line type="number" flat v-model="element.cost"

                                                                            ></v-text-field>
                                                                        </td>
                                                                        <td class="text-center">
                                                                            <label>{{ $currency(element.cost * element.qty) }}</label>
                                                                        </td>
                                                                        <td class="text-center">
                                                                        <v-row>
                                                                            <v-col cols="12" md="3" v-if="!lock_budget">                  
                                                                                <IconRemove
                                                                                    v-if="$root.session.hasPermission(['super', 'budget_work.delete'])"
                                                                                    @on-submit="deleteMaterialComposite(item, element)"
                                                                                />
                                                                            </v-col>
                                                                        </v-row>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </template>
                                                                </v-simple-table>
                                                                <!-- DESKTOP VERSION -->
                                                            </v-col>                                                            
                                                            <v-col cols="9" xs="9" sm="9" md="12" v-else class="text-center">{{$t('works.no_data')}}</v-col>

                                                            </v-card-text>
                                                        </v-expansion-panel-content>
                                                    </v-expansion-panel>
                                                </v-expansion-panels>

                                                <v-divider></v-divider>

                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!--     MÁQUINAS    -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->      
                                                <v-expansion-panels focusable>
                                                    <v-expansion-panel>
                                                            <v-expansion-panel-header :class="($root.$vuetify.theme.isDark ? 'primary' : 'primary')">
                                                                <template v-slot:actions>
                                                                    <v-icon color="white">
                                                                    $expand
                                                                    </v-icon>
                                                                </template>
                                                                <span class="white--text elevation-0 text-h6">{{ item.budget_machines.length }} - {{$t('budgeting.machines_singular_plural')}}<span class="ml-4"><small>({{ priceHoursTotal(item.budget_machines) }})</small></span></span>
                                                            </v-expansion-panel-header>
                                                        <v-expansion-panel-content :class="($root.$vuetify.theme.isDark ? 'secondary' : '')">
                                                    
                                                            <v-card-text>
                                                            <v-dialog
                                                                v-model="addMachine"
                                                                max-width="800px"
                                                                >
                                                                <v-toolbar class="primary white--text">{{$t('globals.machines')}}</v-toolbar>
                                                                <template v-slot:activator="{ props }">
                                                                    <div class="text-right mt-4">
                                                                    <Tooltip
                                                                        color="warning"
                                                                        text="Adicionar nova máquina"
                                                                        bottom
                                                                    >
                                                                        <v-btn
                                                                            v-if="!lock_budget"
                                                                            class="mx-2"
                                                                            fab
                                                                            dark
                                                                            large
                                                                            color="warning"
                                                                            @click="addMachine = true; choosedUnit = item;"
                                                                            v-bind="props"
                                                                            >
                                                                            <v-icon dark>
                                                                                mdi-plus
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </Tooltip>
                                                                    </div>
                                                                </template>

                                                                <v-card>
                                                                    <v-card-text>
                                                                        <v-col cols="12">
                                                                            <v-autocomplete
                                                                                v-model="newMachine.id"
                                                                                :items="machines"
                                                                                :item-text="item => item.code + ' - ' + item.name"
                                                                                item-value="id"
                                                                                menu-props="auto"
                                                                                :label="$t('globals.machines')"
                                                                                prepend-inner-icon="mdi-account"
                                                                            ></v-autocomplete>
                                                                        </v-col>
                                                                        <v-col cols="12" md="4">
                                                                            <v-text-field
                                                                                v-model="newMachine.hours"
                                                                                prepend-inner-icon="mdi-account-details"
                                                                                type="number"
                                                                                :label="$t('globals.hours')"
                                                                                clearable
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                    </v-card-text>
                                                                    <v-card-actions class="justify-center">
                                                                    <v-btn :disabled="newMachine.id == null || newMachine.hours==null" color="success" @click="addBudgetMachine()">{{$t('globals.insert')}}</v-btn>
                                                                    <v-btn color="error" right @click="addMachine = false">{{$t('globals.close')}}</v-btn>
                                                                    </v-card-actions>
                                                                </v-card>
                                                            </v-dialog>
                                                            <v-col cols="9" xs="9" sm="9" md="12" v-if="item.budget_machines.length > 0">
                                                                <!-- DESKTOP VERSION -->
                                                                <v-simple-table dense class="d-none d-lg-block">
                                                                <template v-slot:default>
                                                                    <thead>
                                                                    <tr>
                                                                        <th class="text-center" width="50%">
                                                                            {{$t('globals.machine')}}
                                                                        </th>
                                                                        <th class="text-center" width="5%">
                                                                            {{$t('globals.hours')}}
                                                                        </th>
                                                                        <th class="text-center" width="20%">
                                                                            {{$t('globals.cost')}}
                                                                        </th>
                                                                        <th class="text-center" width="20%">
                                                                            {{$t('globals.total_cost')}}
                                                                        </th>
                                                                        <th class="text-center" width="5%">
                                                                            {{$t('globals.options')}}
                                                                        </th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody :class="$root.$vuetify.theme.isDark ?  'secondary' : ''">
                                                                    <tr v-for="(element, index) in item.budget_machines" v-bind:key="index + 20000">
                                                                        <td class="text-center">
                                                                            <label>{{element.machine}}</label>
                                                                        </td>
                                                                        <td class="text-center">
                                                                            <label>{{element.hours}}</label>
                                                                        </td>
                                                                        <td class="text-center">
                                                                            <v-text-field class="centered-input"
                                                                                :append-icon="$currency_icon()"
                                                                                style="margin: auto; width: 50%;" single-line type="number" flat v-model="element.cost"

                                                                            ></v-text-field>
                                                                        </td>
                                                                        <td class="text-center">
                                                                            <label>{{ $currency(element.cost * element.hours) }}</label>
                                                                        </td>
                                                                        <td class="text-center">
                                                                        <v-row>
                                                                            <v-col cols="12" md="3" v-if="!lock_budget">                  
                                                                                <IconRemove
                                                                                    v-if="$root.session.hasPermission(['super', 'budget_work.delete'])"
                                                                                    @on-submit="deleteMachine(item, element)"
                                                                                />
                                                                            </v-col>
                                                                        </v-row>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </template>
                                                                </v-simple-table>
                                                                <!-- DESKTOP VERSION -->
                                                            </v-col>                                                            
                                                            <v-col cols="9" xs="9" sm="9" md="12" v-else class="text-center">{{$t('works.no_data')}}</v-col>

                                                            </v-card-text>
                                                        </v-expansion-panel-content>
                                                    </v-expansion-panel>
                                                </v-expansion-panels>

                                                <v-divider></v-divider>

                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!--     Mão Obra    -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->
                                                <!-- ||||||||||||||| -->      
                                                <v-expansion-panels focusable>
                                                    <v-expansion-panel>
                                                            <v-expansion-panel-header :class="($root.$vuetify.theme.isDark ? 'primary' : 'primary')">
                                                                <template v-slot:actions>
                                                                    <v-icon color="white">
                                                                    $expand
                                                                    </v-icon>
                                                                </template>
                                                                <span class="white--text elevation-0 text-h6">{{ item.budget_work_hand.length }} - {{$t('budgeting.work_hand_singular_plural')}}<span class="ml-4"><small>({{ priceHoursTotal(item.budget_work_hand) }})</small></span></span>
                                                            </v-expansion-panel-header>
                                                        <v-expansion-panel-content :class="($root.$vuetify.theme.isDark ? 'secondary' : '')">
                                                    
                                                            <v-card-text>
                                                            <v-dialog
                                                                v-model="addWorkHand"
                                                                max-width="800px"
                                                                >
                                                                <v-toolbar class="primary white--text">{{$t('globals.work_hand')}}</v-toolbar>
                                                                <template v-slot:activator="{ props }">
                                                                    <div class="text-right mt-4">
                                                                    <Tooltip
                                                                        color="warning"
                                                                        :text="$t('budgeting.add_work_hand')"
                                                                        bottom
                                                                    >
                                                                        <v-btn
                                                                            v-if="!lock_budget"
                                                                            class="mx-2"
                                                                            fab
                                                                            dark
                                                                            large
                                                                            color="warning"
                                                                            @click="addWorkHand = true; choosedUnit = item;"
                                                                            v-bind="props"
                                                                            >
                                                                            <v-icon dark>
                                                                                mdi-plus
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </Tooltip>
                                                                    </div>
                                                                </template>

                                                                <v-card>
                                                                    <v-card-text>
                                                                        <v-col cols="12">
                                                                            <v-checkbox
                                                                                v-model="newWorkHand.role"
                                                                                :label="$t('budgeting.choose_category')"
                                                                            ></v-checkbox>
                                                                        </v-col>
                                                                        <v-col cols="12" v-if="!newWorkHand.role">
                                                                            <v-autocomplete
                                                                                v-model="newWorkHand.id"
                                                                                :items="workers"
                                                                                :item-text="item => item.code + ' - ' + item.name"
                                                                                item-value="id"
                                                                                menu-props="auto"
                                                                                :label="$t('globals.workers')"
                                                                                prepend-inner-icon="mdi-account"
                                                                            ></v-autocomplete>
                                                                        </v-col>
                                                                        <v-col cols="12" v-if="newWorkHand.role">
                                                                            <v-autocomplete
                                                                                v-model="newWorkHand.id"
                                                                                :items="categories"
                                                                                :item-text="item => item.name"
                                                                                item-value="id"
                                                                                menu-props="auto"
                                                                                :label="$t('globals.categories')"
                                                                                prepend-inner-icon="mdi-account"
                                                                            ></v-autocomplete>
                                                                        </v-col>
                                                                        <v-col cols="12" md="4">
                                                                            <v-text-field
                                                                                v-model="newWorkHand.hours"
                                                                                type="number"
                                                                                prepend-inner-icon="mdi-account-details"
                                                                                :label="$t('globals.hours')"
                                                                                clearable
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                    </v-card-text>
                                                                    <v-card-actions class="justify-center">
                                                                    <v-btn :disabled="newWorkHand.id == null || newWorkHand.hours==null" color="success" @click="addBudgetWorkHand()">{{$t('globals.insert')}}</v-btn>
                                                                    <v-btn color="error" right @click="addWorkHand = false">{{$t('globals.close')}}</v-btn>
                                                                    </v-card-actions>
                                                                </v-card>
                                                            </v-dialog>
                                                            <v-col cols="9" xs="9" sm="9" md="12" v-if="item.budget_work_hand.length > 0">
                                                                <!-- DESKTOP VERSION -->
                                                                <v-simple-table dense class="d-none d-lg-block">
                                                                <template v-slot:default>
                                                                    <thead>
                                                                    <tr>
                                                                        <th class="text-center" width="50%">
                                                                        {{$t('globals.worker')}}
                                                                        </th>
                                                                        <th class="text-center" width="5%">
                                                                        {{$t('globals.hours')}}
                                                                        </th>
                                                                        <th class="text-center" width="20%">
                                                                        {{$t('globals.cost')}}
                                                                        </th>
                                                                        <th class="text-center" width="20%">
                                                                        {{$t('globals.total_cost')}}
                                                                        </th>
                                                                        <th class="text-center" width="5%">
                                                                        {{$t('globals.options')}}
                                                                        </th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody :class="$root.$vuetify.theme.isDark ?  'secondary' : ''">
                                                                    <tr v-for="(element, index) in item.budget_work_hand" v-bind:key="index + 40000">
                                                                        <td class="text-center">
                                                                            <label>{{element.worker}}</label>
                                                                        </td>
                                                                        <td class="text-center">
                                                                            <label>{{element.hours}}</label>
                                                                        </td>
                                                                        <td class="text-center">
                                                                            <v-text-field class="centered-input"
                                                                                :append-icon="$currency_icon()"
                                                                                style="margin: auto; width: 50%;" single-line type="number" flat v-model="element.cost"

                                                                            ></v-text-field>
                                                                        </td>
                                                                        <td class="text-center">
                                                                            <label>{{ $currency(element.cost * element.hours) }}</label>
                                                                        </td>
                                                                        <td class="text-center">
                                                                        <v-row>
                                                                            <v-col cols="12" md="3" v-if="!lock_budget">                  
                                                                                <IconRemove
                                                                                    v-if="$root.session.hasPermission(['super', 'budget_work.delete'])"
                                                                                    @on-submit="deleteWorkHand(item, element)"
                                                                                />
                                                                            </v-col>
                                                                        </v-row>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </template>
                                                                </v-simple-table>
                                                                <!-- DESKTOP VERSION -->
                                                            </v-col>
                                                            <v-col cols="9" xs="9" sm="9" md="12" v-else class="text-center">{{$t('works.no_data')}}</v-col>

                                                            </v-card-text>
                                                        </v-expansion-panel-content>
                                                    </v-expansion-panel>
                                                </v-expansion-panels>

                                            </div>

                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                    
                                    
                                </v-row>
                                

                                
                                <!-- ||||||||||||||| -->
                                <!-- ||||||||||||||| -->
                                <!-- ||||||||||||||| -->
                                <!-- ||||||||||||||| -->
                                <!-- ||||||||||||||| -->
                                <!--Mão Obra INDIRETA-->
                                <!-- ||||||||||||||| -->
                                <!-- ||||||||||||||| -->
                                <!-- ||||||||||||||| -->
                                <!-- ||||||||||||||| -->
                                <!-- ||||||||||||||| -->   
                                                        
                                <v-card class="mt-8 ">
                                    <v-card-title :class="($root.$vuetify.theme.isDark ? 'primary' : 'primary') + ' white--text elevation-0'">{{$t('globals.indirect_work_hand')}}<v-spacer></v-spacer>{{ calculateTotalWorkHand(fields.indirect_work_hand) }}</v-card-title>
                                </v-card>   
                                <v-row>
                                    <v-card-text>
                                        <v-dialog
                                            v-model="addIndirectWorkHand"
                                            max-width="800px"
                                            >
                                            <template v-slot:activator="{ props }">
                                                <div class="text-right mt-4">
                                                <Tooltip
                                                    color="warning"
                                                    text="Adicionar mão de obra indireta"
                                                    bottom
                                                >
                                                    <v-btn
                                                        v-if="!lock_budget"
                                                        class="mx-2"
                                                        fab
                                                        dark
                                                        large
                                                        color="warning"
                                                        @click="addIndirectWorkHand = true;"
                                                        v-bind="props"
                                                        >
                                                        <v-icon dark>
                                                            mdi-plus
                                                        </v-icon>
                                                    </v-btn>
                                                </Tooltip>
                                                </div>
                                            </template>

                                            <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                                                <v-toolbar :class="$root.$vuetify.theme.isDark ? 'secondary' : 'primary white--text'">
                                                    {{$t('globals.indirect_work_hand')}}
                                                </v-toolbar>
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <v-select
                                                                v-model="newIndirectHand.id"
                                                                :items="indirect"
                                                                :item-text="item => item.description"
                                                                item-value="id"
                                                                menu-props="auto"
                                                                :label="$t('globals.indirect_work_hand')"
                                                                prepend-inner-icon="mdi-account"
                                                            ></v-select>
                                                        </v-col>
                                                        <v-col cols="12" md="4">
                                                            <v-text-field
                                                                v-model="newIndirectHand.hours"
                                                                prepend-inner-icon="mdi-account-details"
                                                                type="number"
                                                                :label="$t('globals.hours')"
                                                                @change="calculateIndirectTotalCost"
                                                                clearable
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" md="4">
                                                            <v-text-field
                                                                v-model="newIndirectHand.working_days"
                                                                prepend-inner-icon="mdi-account-details"
                                                                type="number"
                                                                :label="$t('budgeting.work_days')"
                                                                @change="calculateIndirectTotalCost"
                                                                clearable
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" md="4">
                                                            <v-text-field
                                                                :disabled="true"
                                                                v-model="newIndirectHand.total_cost"
                                                                prepend-inner-icon="mdi-account-details"
                                                                :label="$t('globals.total_cost')"
                                                                clearable
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                                <v-card-actions class="justify-center">
                                                <v-btn :disabled="newIndirectHand.id == null || newIndirectHand.hours==null" color="success" @click="addIndirectWorkHandData()">{{$t('globals.insert')}}</v-btn>
                                                <v-btn color="error" right @click="addIndirectWorkHand = false">{{$t('globals.close')}}</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                        <v-col cols="9" xs="9" sm="9" md="12" v-if="fields.indirect_work_hand.length > 0">
                                            <!-- DESKTOP VERSION -->
                                            <v-simple-table dense class="d-none d-lg-block">
                                            <template v-slot:default>
                                                <thead>
                                                <tr>
                                                    <th class="text-center">
                                                    {{$t('globals.description')}}
                                                    </th>
                                                    <th class="text-center">
                                                    {{$t('globals.hours')}}
                                                    </th>
                                                    <th class="text-center">
                                                    {{$t('budgeting.work_days')}}
                                                    </th>
                                                    <th class="text-center">
                                                    {{$t('globals.cost')}}
                                                    </th>
                                                    <th class="text-center">
                                                    {{$t('globals.total_cost')}}
                                                    </th>
                                                    <th class="text-center">
                                                    {{$t('globals.options')}}
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody :class="$root.$vuetify.theme.isDark ?  'secondary' : ''">
                                                <tr v-for="(element, index) in fields.indirect_work_hand" v-bind:key="index + 60000">
                                                    <td class="text-center">
                                                        <label>{{element.indirect}}</label>
                                                    </td>
                                                    <td class="text-center">
                                                        <label>{{element.hours}}</label>
                                                    </td>
                                                    <td class="text-center">
                                                        <label>{{element.working_days}}</label>
                                                    </td>
                                                    <td class="text-center">
                                                        <label>{{ $currency(element.cost) }}</label>
                                                    </td>
                                                    <td class="text-center">
                                                        <label>{{ $currency(element.total_cost) }}</label>
                                                    </td>
                                                    <td class="text-center">
                                                    <v-row>
                                                        <v-col cols="12" md="3" v-if="!lock_budget">                  
                                                            <IconRemove
                                                                v-if="$root.session.hasPermission(['super', 'budget_work.delete'])"
                                                                @on-submit="deleteIndirectWorkHand(element)"
                                                            />
                                                        </v-col>
                                                    </v-row>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </template>
                                            </v-simple-table>
                                            <!-- DESKTOP VERSION -->
                                        </v-col>                                        
                                        <v-col cols="9" xs="9" sm="9" md="12" v-else class="text-center">{{$t('works.no_data')}}</v-col>

                                    </v-card-text>                                    
                                </v-row>

                        <!-- ||||||||||||||| -->
                        <!-- ||||||||||||||| -->
                        <!-- ||||||||||||||| -->
                        <!-- ||||||||||||||| -->
                        <!-- ||||||||||||||| -->
                        <!--SUBEMPREITADAS-->
                        <!-- ||||||||||||||| -->
                        <!-- ||||||||||||||| -->
                        <!-- ||||||||||||||| -->
                        <!-- ||||||||||||||| -->
                        <!-- ||||||||||||||| -->   
                                                
                        <v-card class="mt-8 ">
                            <v-card-title :class="($root.$vuetify.theme.isDark ? 'primary' : 'primary') + ' white--text elevation-0'">{{$t('globals.subcontractors')}}<v-spacer></v-spacer>{{ calculateTotalSubempreitadas(fields.subempreitadas) }}</v-card-title>
                        </v-card>   
                        <v-row>
                            <v-card-text>
                                <v-dialog
                                    v-model="addSubempreitada"
                                    max-width="800px"
                                    >
                                    <template v-slot:activator="{ props }">
                                        <div class="text-right mt-4">
                                        <Tooltip
                                            color="warning"
                                            :text="$t('budgeting.add_subcontractor')"
                                            bottom
                                        >
                                            <v-btn
                                                v-if="!lock_budget"
                                                class="mx-2"
                                                fab
                                                dark
                                                large
                                                color="warning"
                                                @click="addSubempreitada = true;"
                                                v-bind="props"
                                                >
                                                <v-icon dark>
                                                    mdi-plus
                                                </v-icon>
                                            </v-btn>
                                        </Tooltip>
                                        </div>
                                    </template>

                                    <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                                        <v-toolbar :class="$root.$vuetify.theme.isDark ? 'secondary' : 'primary white--text'">
                                            {{$t('globals.subcontractor')}}
                                        </v-toolbar>
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-select
                                                        v-model="newSubempreitada.subempreiteiro_id"
                                                        :items="subempreiteiros"
                                                        :item-text="item => item.name"
                                                        item-value="id"
                                                        menu-props="auto"
                                                        :label="$t('globals.subcontractor_person')"
                                                        prepend-inner-icon="mdi-account"
                                                    ></v-select>
                                                </v-col>
                                                <v-col cols="12" md="12">
                                                    <v-text-field
                                                        v-model="newSubempreitada.name"
                                                        prepend-inner-icon="mdi-account-details"                                                        
                                                        :label="$t('budgeting.subcontractor_name')"
                                                        clearable
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="12">
                                                    <v-textarea
                                                        v-model="newSubempreitada.description"
                                                        prepend-inner-icon="mdi-account-details"                                                        
                                                        :label="$t('globals.description')"
                                                        clearable
                                                    ></v-textarea>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-text-field
                                                        v-model="newSubempreitada.value"
                                                        :prepend-inner-icon="$currency_icon()"     
                                                        type="number"                                                   
                                                        :label="$t('globals.cost')"
                                                        clearable
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-menu
                                                        v-model="dataPicker"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="newSubempreitada.starting_date"
                                                            :label="$t('subcontractors.work_date')"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                        v-model="newSubempreitada.starting_date"
                                                        @input="dataPicker = false"
                                                        ></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-card-actions class="justify-center">
                                        <v-btn color="success" @click="addSubempreitadaData()">{{$t('globals.insert')}}</v-btn>
                                        <v-btn color="error" right @click="addSubempreitada = false">{{$t('globals.close')}}</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-col cols="9" xs="9" sm="9" md="12" v-if="fields.subempreitadas.length > 0">
                                    <!-- DESKTOP VERSION -->
                                    <v-simple-table dense class="d-none d-lg-block">
                                    <template v-slot:default>
                                        <thead>
                                        <tr>
                                            <th class="text-center">
                                            {{$t('globals.subcontractor_person')}}
                                            </th>
                                            <th class="text-center">
                                            {{$t('globals.name')}}
                                            </th>
                                            <th class="text-center">
                                            {{$t('globals.description')}}
                                            </th>
                                            <th class="text-center">
                                            {{$t('globals.cost')}}
                                            </th>
                                            <th class="text-center">
                                            {{$t('budgeting.work_of_day')}}
                                            </th>
                                            <th class="text-center">
                                            {{$t('globals.options')}}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody :key="reloadSubEmpreitada" :class="$root.$vuetify.theme.isDark ?  'secondary' : ''">
                                        <tr v-for="(element, index) in fields.subempreitadas" v-bind:key="index + 32000">
                                            <td class="text-center">
                                                <label>{{element.subempreiteiro_name}}</label>
                                            </td>
                                            <td class="text-center">
                                                <label>{{element.name}}</label>
                                            </td>
                                            <td class="text-center">
                                                <label>{{element.description}}</label>
                                            </td>
                                            <td class="text-center">
                                                <label>{{ $currency(element.value) }}</label>
                                            </td>
                                            <td class="text-center">
                                                <label>{{ element.starting_date }}</label>
                                            </td>
                                            <td class="text-center">
                                            <v-row>
                                                <v-col cols="12" md="3" v-if="!lock_budget">                  
                                                    <IconRemove
                                                        v-if="$root.session.hasPermission(['super', 'budget_work.delete'])"
                                                        @on-submit="deleteSubempreitadaData(element)"
                                                    />
                                                </v-col>
                                            </v-row>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </template>
                                    </v-simple-table>
                                    <!-- DESKTOP VERSION -->
                                </v-col>                                        
                                <v-col cols="9" xs="9" sm="9" md="12" v-else class="text-center">{{$t('works.no_data')}}</v-col>

                            </v-card-text>                                    
                        </v-row>





                                <v-card class="mt-8">
                                    <v-card-title :class="($root.$vuetify.theme.isDark ? 'primary' : 'primary') + ' white--text elevation-0'">{{$t('budgeting.indirect_costs')}}<v-spacer></v-spacer>{{ $currency((fields.stable_cost * fields.stable_days) + (fields.general_expenses_cost * fields.general_expenses_days)) }}</v-card-title>
                                </v-card> 
                                <v-card-text>
                                    <v-expansion-panels focusable>
                                        <v-expansion-panel class="mt-4">
                                            <v-expansion-panel-header :class="($root.$vuetify.theme.isDark ? 'primary' : 'white--text primary')">
                                                <v-row no-gutters>
                                                    <v-col cols="12">
                                                        <v-row>
                                                            {{$t('globals.construction_site')}}
                                                            <v-spacer></v-spacer>
                                                            <span class="mr-4">{{ $currency(fields.stable_cost * fields.stable_days) }}</span>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                                <template v-slot:actions>
                                                    <v-icon color="white" class="mt-n2">
                                                    $expand
                                                    </v-icon>
                                                </template>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content :class="($root.$vuetify.theme.isDark ? 'secondary' : '')">
                                                <v-row class=mt-4>
                                                    <v-col cols="12" md="4">
                                                        <v-text-field
                                                            :disabled="lock_budget"
                                                            v-model="fields.stable_cost"
                                                            @change="calculateBudget"
                                                            prepend-inner-icon="mdi-account-details"
                                                            :label="$t('globals.cost') + ' (' + $store.state.coin + ')'"
                                                            clearable
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="4">
                                                        <v-text-field
                                                            :disabled="lock_budget"
                                                            v-model="fields.stable_days"
                                                            @change="calculateBudget"
                                                            prepend-inner-icon="mdi-account-details"
                                                            :label="$t('budgeting.n_days')"
                                                            clearable
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="auto">
                                                        <v-btn class="primary mt-2" @click="modalSuccess.message=$t('budgeting.construction_site_added'); modalSuccess.show=true;">{{$t('globals.ok')}}</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="mt-4">
                                            <v-expansion-panel-header :class="($root.$vuetify.theme.isDark ? 'primary' : 'white--text primary')">
                                                <v-row no-gutters>
                                                    <v-col cols="12">
                                                        <v-row>
                                                            {{$t('budgeting.general_expenses')}}
                                                            <v-spacer></v-spacer>
                                                            <span class="mr-4">{{ $currency(fields.general_expenses_cost * fields.general_expenses_days) }}</span>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>                                                
                                                <template v-slot:actions>
                                                    <v-icon color="white" class="mt-n2">
                                                    $expand
                                                    </v-icon>
                                                </template>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content :class="($root.$vuetify.theme.isDark ? 'secondary' : '')">
                                                <v-row class=mt-4>
                                                    <v-col cols="12" md="4">
                                                        <v-text-field
                                                            :disabled="lock_budget"
                                                            type="number"
                                                            v-model="fields.general_expenses_cost"
                                                            @change="calculateBudget"
                                                            prepend-inner-icon="mdi-account-details"
                                                            :label="$t('globals.cost') + ' (' + $store.state.coin + ')'"
                                                            clearable
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="4">
                                                        <v-text-field
                                                            :disabled="lock_budget"
                                                            type="number"
                                                            @change="calculateBudget"
                                                            v-model="fields.general_expenses_days"
                                                            prepend-inner-icon="mdi-account-details"
                                                            :label="$t('budgeting.n_days')"
                                                            clearable
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="auto">
                                                        <v-btn class="primary mt-2" @click="modalSuccess.message=$t('budgeting.general_expenses_added'); modalSuccess.show=true;">{{$t('globals.ok')}}</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-card-text>


                    </fieldset>
                          
                    
                <v-divider></v-divider>
                <v-row class="mt-6" align="center" justify="space-around" v-if="!lock_budget">
                    <v-btn :disabled="invalid" depressed :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary white--text'" type="submit">
                    {{$t('globals.save')}}
                    </v-btn>
                </v-row>
                </v-form>
              </validation-observer>

            <v-dialog
                v-model="unitsNoCost"
                width="900px"
            >
                <v-card>
                    <v-card-title>
                        {{$t('budgeting.work_unit_validation')}}
                    </v-card-title>
                    <v-card-subtitle>
                        {{$t('budgeting.please_correct')}}
                    </v-card-subtitle>
                    <v-card-text>
                        <v-simple-table>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    {{$t('globals.work_unit')}}
                                </th>
                                <th>
                                    {{$t('budgeting.section')}}
                                </th>
                                <th class="text-left">
                                    {{$t('budgeting.problem')}}
                                </th>
                                </tr>
                            </thead>
                            <tbody v-for="item in unitsValidation" :key="item.unit">
                                <tr v-for="(i, index) in item.problems" :key="index+8000">
                                    <td>{{ item.unit }}</td>
                                    <td>{{ i.place }}</td>
                                    <td> 
                                        {{ i.erro }} {{$t('budgeting.with_value_0')}} {{$store.state.coin}}
                                        <v-icon color="error">mdi-alert-circle</v-icon>
                                    </td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="primary" @click="unitsNoCost = false">{{$t('globals.ok')}}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
          
          <DialogSuccess :opened="success" @on-ok="onSuccess" >
            {{$t('budgeting.budget_saved')}}
          </DialogSuccess>
          <SuccessSnackBar v-model="modalSuccess.show" :message="modalSuccess.message" />
          
          <DialogConfirmation 
            :title="$t('budgeting.are_you_sure_accept_budget_title')" 
            type="warning" 
            :opened="openConfirmBudgetStatus" 
            @on-submit="approveBudget"
            @on-cancel="openConfirmBudgetStatus = false; fields.status = $t('budgeting.states.ACCEPTED.value');">
              {{$t('budgeting.are_you_sure_accept_budget')}}
          </DialogConfirmation>
        </v-container>

        <v-dialog
            v-if="openMaterialComposite"
            v-model="openMaterialCompositePieces"
            max-width="800px"
            >
            <v-card>
                <v-card-title class="primary white--text">
                    {{$t('budgeting.material_composite_composition')}}
                </v-card-title>
                <v-card-text>
                    <v-textarea
                        v-model="materialCompositePiecesShow.description"
                        outlined
                        class="mt-6"
                        readonly
                        :label="$t('globals.description') + ':'"
                    >

                    </v-textarea>
                    <!-- DESKTOP VERSION -->
                    <v-simple-table dense class="d-none d-lg-block">
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-center">
                                {{$t('globals.material')}}
                                </th>
                                <th class="text-center">
                                {{$t('globals.quantity')}}
                                </th>
                                <th class="text-center">
                                {{$t('globals.cost')}}
                                </th>
                                <th class="text-center">
                                {{$t('globals.total_cost')}}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(element, index) in materialCompositePiecesShow.pieces" v-bind:key="index + 70000">
                                <td class="text-center">
                                    <label>{{element.material}}</label>
                                </td>
                                <td class="text-center">
                                    <label>{{element.qty}}</label>
                                </td>
                                <td class="text-center">
                                    <label>{{ element.cost }}</label>
                                </td>
                                <td class="text-center">
                                    <label>{{ element.cost * element.qty }}</label>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <!-- DESKTOP VERSION -->
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn color="error" right @click="openMaterialCompositePieces = false">{{$t('globals.close')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
          v-model="addNewUnitModal"
          max-width="800px"
        >
          <UnitDialog 
            v-if="addNewUnitModal"
            method="create"
            :reload="false"
            @close="addNewUnitModal = false"
            @close-update="fillBaseData(); addNewUnitModal=false;"
          />
        </v-dialog>

    </v-card>
  </template>
  <script>
  import Budget from "@/api/Budget.js"
  import Unit from "@/api/Unit.js"
  import Indirect from "@/api/Indirect.js"
  import Category from "@/api/Category.js"
  import Client from '@/api/Client.js'
  import Worker from "@/api/Worker.js"
  import Machine from "@/api/Machine.js"
  import MaterialsComposite from "@/api/MaterialsComposite.js"
  import Materials from "@/api/Materials.js"
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';
  import IconRemove from '@/components/ui/IconRemove.vue';
  import DialogConfirmation from '@/components/ui/DialogConfirmation.vue';
  import UnitDialog from '@/components/units/Unit'
  import Tooltip from '@/components/ui/Tooltip.vue';
  import Export from '@/api/Export.js'
  import Subempreiteiro from "@/api/Subempreiteiro.js"
  import ChapterAPI from "@/api/Chapter.js"

  import Chapters from "@/components/budget/components/Chapters.vue"
  import AddClient from '@/components/clients/Add.vue';
  
  export default {
    components: {
      DialogSuccess,
      SuccessSnackBar,
      IconRemove,
      DialogConfirmation,
      UnitDialog,
      Tooltip,
      Chapters,
      AddClient
    },
    props: {
     budget: Object,
     method: String,
     disabled: Boolean,
   },
   watch: { 
    budget: {
        immediate: true, 
        handler (val) {
            if(!val) {
                return;
            }
    
            let data = {...val};
    
            this.fields = data;

            if(this.fields.general_expenses_cost == null)
                this.fields.general_expenses_cost = 0

            if(this.fields.general_expenses_days == null)
                this.fields.general_expenses_days = 0

            if(this.fields.stable_cost == null)
                this.fields.stable_cost = 0

            if(this.fields.stable_days == null)
                this.fields.stable_days = 0

            if(this.fields.general_charges == null)
                this.fields.general_charges = 0
  
            this.fields.id = data.id;
        }
      },
    },
    beforeCreate(){
    },
    data: () => ({
        add_general_charges: false,
        addClient: false,
        lock_k_fields: false,
        reloadSubEmpreitada: 0,
        dataPicker: false,
        unitsNoCost: false,
        speedDial: false,
        chapters: [],
        addNewUnitModal: false,
        materialCompositePiecesShow: {
            pieces: null,
            description: null
        },
        openMaterialCompositePieces: false,
        openConfirmBudgetStatus: false,
        choosedUnit: null,
        newIndirectHand:{
            id: null,
            hours: null,
            cost: null,
            working_days: null,
            total_cost: null
        },
        newWorkHand:{
            id: null,
            hours: null,
            cost: null,
            role: false
        },
        newMachine:{
            id: null,
            hours: null,
            cost: null
        },
        newMaterial:{
            id: null,
            qty: null,
            cost: null
        },
        newMaterialComposite:{
            id: null, 
            qty: null,
            cost: null,
            pieces: null
        },
        newUnit:{
            id: null,
            qty: null,
            cost: null,
            total_cost: null,
            unit: null,
        },
        newChapter:{
            id: null,
            description: null,
            chapter_classes: []
        },
        newSubempreitada:{
            id: null,
            budget_work_id: null,
            subempreiteiro_id: null,
            name: null,
            description: null,
            value: null, 
            starting_date: null,
            proposal: null,
            invoice_path: null
        },
        addMaterial: false,
        addMaterialComposite: false,
        addMachine: false,
        addWorkHand: false,
        addIndirectWorkHand: false,
        addSubempreitada: false,
        addUnit: false,
        addChapter: false,
        addClasse: false,
        modalSuccess:{
            show: false,
            message:""
        },
        fields: {
            id: null,
            work_reference: null,
            address: null,
            work_id: null,
            budget: null,
            budget_witk_k: null,
            k: null, 
            status: '',
            chapters: [],
            classes: [],
            budget_units: [
            ],
            indirect_work_hand: [],
            subempreitadas: [],
            general_expenses_cost: 0,
            general_expenses_days: 0,
            stable_cost: 0,
            stable_days: 0,
            general_charges: 0,
            reference: null
        },
        loading:false,
        error: {
            title: '',
            message: '',
        },
        success: false,
        works:[],
        units: [],
        materials_composite: [],
        materials: [],
        machines: [],
        workers: [],
        indirect: [],
        clients:[],
        categories: [],
        lock_budget: false,
        unitsValidation: [],
        subempreiteiros: []
    }),
    mounted(){
        if(this.method == 'create')
            this.fields.status = this.$t('budgeting.states.ON_BUDGET')

        this.fillBaseData();
        this.calculateBudget();

        if(this.fields.status == this.$t('budgeting.states.ACCEPTED.value'))
            this.lock_budget = true

        if(this.budget != null){
            if(this.budget.k != null)
                this.fields.k = this.budget.k
        }


    },
    methods:{
        globalKChanged(){
            if(this.fields.k == null){
                this.lock_k_fields = false
                return
            }
            if(this.fields.k == ''){
                this.lock_k_fields = false
                return
            }
            
            this.lock_k_fields = true
        },
        updateBudget(){
            let total_com_k = 0
            let total_sem_k = 0
            let custos_gerais_totais = 0
            let ks = {
                total: 0,
                sum: 0
            }
            

            this.fields.subempreitadas.forEach(element => {
                custos_gerais_totais = custos_gerais_totais + parseFloat(element.value)
            });

            this.fields.indirect_work_hand.forEach(element => {
                custos_gerais_totais = custos_gerais_totais + (element.hours * element.cost * element.working_days)
            });

            custos_gerais_totais = custos_gerais_totais + (parseFloat(this.fields.stable_cost) * parseFloat(this.fields.stable_days))

            custos_gerais_totais = custos_gerais_totais + (parseFloat(this.fields.general_expenses_cost) * parseFloat(this.fields.general_expenses_days))

           

            let encargos_percentagem = 1
            if(this.fields.general_charges != null){
                if(this.fields.general_charges != ''){
                    encargos_percentagem = parseFloat(this.fields.general_charges) / 100
                    encargos_percentagem = encargos_percentagem + 1
                }
            }


            this.fields.chapters.forEach(chapter => {
                let soma_total_capitulo = 0

                chapter.classes.forEach(classe => {
                    let soma_total_classe = 0
                    classe.units.forEach(unit => {
                        if(unit.manual == false){
                            total_sem_k = total_sem_k + (parseFloat(unit.cost) * parseFloat(unit.qty))
                
                            soma_total_classe = soma_total_classe + (parseFloat(unit.cost) * parseFloat(unit.qty))

                        }else{
                            let totalCost = 0
                            unit.budget_materials.forEach(ob => {
                                totalCost += (parseFloat(ob.cost) * parseFloat(ob.qty))
                            });

                            unit.budget_materials_composite.forEach(ob => {
                                totalCost += (parseFloat(ob.cost) * parseFloat(ob.qty))
                            });

                            unit.budget_machines.forEach(ob => {
                                totalCost += (parseFloat(ob.cost) * parseFloat(ob.hours))
                            });

                            unit.budget_work_hand.forEach(ob => {
                                totalCost += (parseFloat(ob.cost) * parseFloat(ob.hours))
                            });

                            soma_total_classe = totalCost * unit.qty
                            if(isNaN(soma_total_classe))
                                soma_total_classe = 0
                                
                            
                            total_sem_k = total_sem_k + soma_total_classe
                        }
                    });


                    if(classe.add_chapter_class_margin){
                        let percentagem = parseFloat(classe.chapter_class_margin) / 100
                        let valor = soma_total_classe * percentagem;
                        total_com_k += (soma_total_classe + valor)

                        // ks
                        ks.total = ks.total + parseFloat(classe.chapter_class_margin)
                        ks.sum++
                    }else{
                        if(chapter.add_chapter_margin == false){
                            total_com_k = total_com_k + soma_total_classe
                            
                        }
                    }
                    soma_total_capitulo = soma_total_capitulo + soma_total_classe
                });

                if(chapter.add_chapter_margin){
                    let percentagem = parseFloat(chapter.chapter_margin) / 100
                    let valor = soma_total_capitulo * percentagem;
                    total_com_k += (soma_total_capitulo + valor)

                    
                    // ks
                    ks.total = ks.total + parseFloat(chapter.chapter_margin)
                    ks.sum++
                }
            });
            
            this.fields.budget = (total_sem_k + custos_gerais_totais) * encargos_percentagem
            

            if(this.lock_k_fields == true){
                let percentage = parseFloat(this.fields.k) / 100
                let value = this.fields.budget * percentage
                this.fields.budget_witk_k = this.fields.budget + value

                this.fields.aux = total_com_k
            }else{
                if(total_com_k == 0)
                    if(total_sem_k > 0)
                        total_com_k = total_sem_k

                        

                this.fields.budget_witk_k = ((total_com_k - total_sem_k) + ((total_sem_k + custos_gerais_totais)*encargos_percentagem)).toFixed(2)
                
            }

            console.log(this.budget)
            if(this.budget != null)
                this.fields.budget_witk_k = this.budget.budget_k

            if(ks.total > 0)
                this.fields.k = (ks.total / ks.sum).toFixed(2)
        },
        isNewFile(f){
            if(f.new_file == undefined)
                return true

            if(f.new_file == null)
                return true

            return false
        },
        downloadProposta(object){
            Budget['subempreitadaProposal_download'](object.id).then((response) => {
                let documentName =  this.getFilename(object.proposal)

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", documentName + '.pdf');
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        exportBudget(){
            let data = 'budget';
            Export.exportBudget(data, {id: this.$route.params.id})
            .then((resp) => {            
                window.open(resp.data.url, '_blank')
                Export.delete( resp.data.path)
                .then(() => {
                });
            });
        },
        exportBudgetUnitsList(){
            let data = 'budget_units';
            Export.generalExport(data, {id: this.$route.params.id})
            .then((resp) => {            
                window.open(resp.data.url, '_blank')
                Export.delete( resp.data.path)
                .then(() => {
                });
            });
        },
        calculateGlobalUnit(){
            let cost = 0
            this.fields.budget_units.forEach(element => {
                if(element.manual){
                    cost = cost + element.qty * element.cost
                }else{
                    element.budget_materials.forEach(element => {
                        cost += (element.cost * element.qty)
                    });

                    element.budget_materials_composite.forEach(element => {
                        cost += (element.cost * element.qty)
                    });

                    element.budget_machines.forEach(element => {
                        cost += (element.cost * element.hours)
                    });

                    element.budget_work_hand.forEach(element => {
                        cost += (element.cost * element.hours)
                    });
                }
            });
            return this.$currency(cost)
        },
        deleteBudgetUnit(item){
            let i = 0
            let pos = 0
            this.fields.budget_units.forEach(element => {
                if(element.id == item.id)
                    pos = i

                i++
            });
            this.fields.budget_units.splice(pos, 1)
        },
        calculateNewUnitCosts(item = null){
            let cost = 0
            let unit = ''
            this.units.forEach(element => {
                if(element.id == this.newUnit.id){
                    cost = element.value
                    unit = element.unit
                }
            });

            this.newUnit.unit = unit

            this.newUnit.cost = cost

            if(typeof item == "string"){
                this.calculateBudget()
                return
            }

            if(item == null){
                if(this.newUnit.qty != null)
                    this.newUnit.total_cost = this.newUnit.qty * this.newUnit.cost
            }else{
                item.total_cost = item.qty * item.cost
            }

            this.calculateBudget()
        },
        calculateIndirectTotalCost(){
            if(this.newIndirectHand.working_days == null)
                return
            if(this.newIndirectHand.hours == null)
                return
            
            let find_cost = 0
            this.indirect.forEach(element => {
                if(element.id == this.newIndirectHand.id)
                    find_cost = element.cost
            });
            
            this.newIndirectHand.total_cost = (this.newIndirectHand.hours * this.newIndirectHand.working_days) * find_cost
        },
        calculateTotalWorkHand(item){
            let total = 0

            item.forEach(element => {
                total += element.cost * element.hours * element.working_days
            });

            if(total == null || total == 0 || total == undefined)
                return total = '0 ' + this.$store.state.coin

            return this.$currency(total)
        },
        calculateTotalSubempreitadas(item){
            let total = 0

            item.forEach(element => {
                total += parseInt(element.value)
            });

            if(total == null || total == 0 || total == undefined)
                return total = '0 ' + this.$store.state.coin

            return this.$currency(total)
        },
        calculateUnitTotal(item){
            let totalCost = 0

            item.budget_materials.forEach(element => {
                totalCost += (element.cost * element.qty)
            });

            item.budget_materials_composite.forEach(element => {
                totalCost += (element.cost * element.qty)
            });

            item.budget_machines.forEach(element => {
                totalCost += (element.cost * element.hours)
            });

            item.budget_work_hand.forEach(element => {
                totalCost += (element.cost * element.hours)
            });

            if(totalCost == null || totalCost == 0 || totalCost == undefined){
                item.cost = 0
                return totalCost = '0 ' + this.$store.state.coin
            }

            item.cost = totalCost

            return this.$currency(totalCost)
        },
        priceHoursTotal(prices){
            let total = 0
            prices.forEach(element => {
                total = total + (element.cost * element.hours)
            });

            if(total == null || total == 0 || total == undefined)
                return total = '0 ' + this.$store.state.coin
            return this.$currency(total)
        },
        priceQtyTotal(prices){
            let total = 0
            prices.forEach(element => {
                total = total + (element.cost * element.qty)
            });

            if(total == null || total == 0 || total == undefined)
                return total = '0 ' + this.$store.state.coin
            return this.$currency(total)
        },
        openMaterialComposite(pieces, element){
            this.openMaterialCompositePieces = true
            this.materialCompositePiecesShow.pieces = pieces
            this.materialCompositePiecesShow.description = element.description
        },
        approveBudget(){
            let data = {...this.fields};


            Budget.approveBudget(data, this.fields.id).then(() => {
    
                this.success = true;

                this.loading = false;

                window.location.reload()
                
            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        changeStatus(){
            if(this.fields.status == 'Aceite')
                this.openConfirmBudgetStatus = true
        },
        deleteIndirectWorkHand(indirect){
            let pos = 0
            let i = 0
            this.fields.indirect_work_hand.forEach(element => {
                if(element.id == indirect.id){
                    pos = i    
                }
                i++
            });

            this.fields.indirect_work_hand.splice(pos, 1)

            this.calculateBudget()
        },
        deleteSubempreitadaData(subempreitada){
            let pos = 0
            let i = 0
            this.fields.subempreitadas.forEach(element => {
                if(element.id == subempreitada.id){
                    pos = i    
                }
                i++
            });

            this.fields.subempreitadas.splice(pos, 1)

            this.calculateBudget()
        },
        deleteWorkHand(unit, workHand){
            let pos = null
            this.fields.budget_units.forEach(element => {                
                if(element.id == unit.id){                    
                    let i = 0
                    element.budget_work_hand.forEach(element => {
                        if(element.id == workHand.id)
                            pos = i
                        
                        i++
                    });

                    element.budget_work_hand.splice(pos, 1);
                }
            });

            this.calculateBudget()
        },
        deleteMachine(unit, machine){
            let pos = null
            this.fields.budget_units.forEach(element => {                
                if(element.id == unit.id){                    
                    let i = 0
                    element.budget_machines.forEach(element => {
                        if(element.id == machine.id)
                            pos = i
                        
                        i++
                    });

                    element.budget_machines.splice(pos, 1);
                }
            });

            this.calculateBudget()
        },
        deleteMaterial(unit, material){
            let pos = null
            this.fields.budget_units.forEach(element => {                
                if(element.id == unit.id){                    
                    let i = 0
                    element.budget_materials.forEach(element => {
                        if(element.id == material.id)
                            pos = i
                        
                        i++
                    });

                    element.budget_materials.splice(pos, 1);
                }
            });

            this.calculateBudget()
        },
        
        deleteMaterialComposite(unit, material){
            let pos = null
            this.fields.budget_units.forEach(element => {                
                if(element.id == unit.id){                    
                    let i = 0
                    element.budget_materials_composite.forEach(element => {
                        if(element.id == material.id)
                            pos = i
                        
                        i++
                    });

                    element.budget_materials_composite.splice(pos, 1);
                }
            });
            
            this.calculateBudget()
        },
        addIndirectWorkHandData(){
            let indirect = ''
            let cost = 0

            this.indirect.forEach(element => {
                if(element.id == this.newIndirectHand.id){
                    indirect = element.description
                    cost = element.cost
                }
            });

            let indirect_object = {
                id: this.newIndirectHand.id,
                indirect: indirect,
                hours: this.newIndirectHand.hours,
                working_days: this.newIndirectHand.working_days,
                total_cost: this.newIndirectHand.total_cost,
                cost: cost
            }

            this.fields.indirect_work_hand.push(indirect_object)

            this.newIndirectHand.id = null
            this.newIndirectHand.hours = null

            this.calculateBudget()
        },
        addSubempreitadaData(){
            let subempreiteiro = null

            this.subempreiteiros.forEach(element => {
                if(element.id == this.newSubempreitada.subempreiteiro_id){
                    subempreiteiro = element
                }
            });

            let subempreitada_object = {
                id:                     this.newSubempreitada.id,
                subempreiteiro_name:    subempreiteiro.name,
                subempreiteiro_id:      this.newSubempreitada.subempreiteiro_id,
                name:                   this.newSubempreitada.name,
                description:            this.newSubempreitada.description,
                value:                  this.newSubempreitada.value,
                starting_date:          this.newSubempreitada.starting_date,
                proposal:               this.newSubempreitada.proposal,
                invoice_path:           null,
                new_file:               true,                
            }

            this.fields.subempreitadas.push(subempreitada_object)

            this.reloadSubEmpreitada++

            this.newSubempreitada.id = null
            this.newSubempreitada.subempreiteiro_id = null
            this.newSubempreitada.name = null
            this.newSubempreitada.description = null
            this.newSubempreitada.value = null
            this.newSubempreitada.starting_date = null
            this.newSubempreitada.proposal = null

            // Assumir que existe subempreitada então a partida deverá haver pelo menos 1 dia de trabalho na obra

            if(this.fields.general_expenses_days == 0)
                this.fields.general_expenses_days = 1

            this.calculateBudget()
        },
        addBudgetWorkHand(){
            let worker = ''
            let cost = 0

            if(this.newWorkHand.role == true){
                this.categories.forEach(element => {
                    if(element.id == this.newWorkHand.id){                    
                        worker = element.name
                        cost = element.value
                    }
                });
            }else{
                this.workers.forEach(element => {
                    if(element.id == this.newWorkHand.id){                    
                        worker = element.name
                        cost = element.value
                    }
                });
            }
            

            let workHand_object = {
                id: this.newWorkHand.id,
                worker: worker,
                hours: this.newWorkHand.hours,
                cost: cost,
                role: this.newWorkHand.role
            }

            this.choosedUnit.budget_work_hand.push(workHand_object)

            this.newWorkHand.id = null
            this.newWorkHand.hours = null

            this.calculateBudget()
        },
        addBudgetMachine(){
            let machine = ''
            let cost = 0
            this.machines.forEach(element => {
                if(element.id == this.newMachine.id){                    
                    machine = element.name
                    cost = element.value
                }
            });

            let machine_object = {
                id: this.newMachine.id,
                machine: machine,
                hours: this.newMachine.hours,
                cost: cost
            }

            this.choosedUnit.budget_machines.push(machine_object)

            this.newMachine.id = null
            this.newMachine.hours = null

            this.calculateBudget()
        },
        addNewMaterial(){
            let material = ''
            let cost = 0

            this.materials.forEach(element => {
                if(element.id == this.newMaterial.id){
                    material = element.description
                    cost = element.cost
                }
            });


            let material_object = {
                id: this.newMaterial.id,
                material: material,
                qty: this.newMaterial.qty,
                cost: cost
            }

            this.choosedUnit.budget_materials.push(material_object)

            this.newMaterial.id = null
            this.newMaterial.qty = null

            this.calculateBudget()
        },
        addNewMaterialComposite(){
            let material = ''
            let cost = 0
            let pieces = []
            
            this.materials_composite.forEach(element => {
                if(element.id == this.newMaterialComposite.id){
                    material = element.name
                    cost = element.total_cost
                    pieces = element.pieces
                }
            });

            let materials_composite = {
                id: this.newMaterialComposite.id,
                material: material,
                qty: this.newMaterialComposite.qty,
                cost: cost,
                pieces: pieces
            }

            this.choosedUnit.budget_materials_composite.push(materials_composite)

            this.newMaterialComposite.id = null
            this.newMaterialComposite.qty = null

            this.calculateBudget()
        },
        addNewUnit(){
            let unit = ''
            let code = ''
            this.units.forEach(element => {
                if(element.id == this.newUnit.id){
                    unit = element.name
                    code = element.code
                }
            });
            this.fields.budget_units.push({
                id: this.newUnit.id,
                unit: unit,
                code: code,
                manual: false,
                qty: this.newUnit.qty,
                unit_measure: this.newUnit.unit,
                cost: this.newUnit.cost,
                total_cost: this.newUnit.total_cost,
                budget_materials: [],
                budget_materials_composite: [],
                budget_machines: [],
                budget_work_hand: []
            })

            this.newUnit.id = null
        },
        addNewChapter(){
            let description = ''
            let chapter_classes = []
            this.chapters.forEach(element => {
                if(element.id == this.newChapter.id){
                    description = element.description
                    chapter_classes = element.chapter_classes
                }
            });
            this.fields.chapters.push({
                id: this.newChapter.id,
                description: description,
                chapter_classes: chapter_classes
            })
        },
        calculateBudget(){
            // Calcular preço das subempreitadas e acrescentar as despesas gerais
            /*let general_costs = 0
            this.fields.subempreitadas.forEach(element => {
                general_costs = element.value
            });
            this.fields.general_expenses_cost = parseFloat(this.fields.general_expenses_cost) + parseFloat(general_costs)*/

            let custos = 0
            let total_ks = 0
            let sum_ks = 0

            // Subempreitadas
            this.fields.subempreitadas.forEach(element => {
                custos = custos + parseFloat(element.value)
            });


            this.fields.chapters.forEach(chapter => {
                let chapter_cost = 0
                chapter.classes.forEach(chapter_class => {
                    let chapter_class_cost = 0

                    chapter_class.units.forEach(budget => {
                        if(budget.manual == false){
                            chapter_class_cost = chapter_class_cost + budget.cost
                        }else{
                            let totalCost = 0
                            budget.budget_materials.forEach(ob => {
                                totalCost += (parseFloat(ob.cost) * parseFloat(ob.qty))
                            });

                            budget.budget_materials_composite.forEach(ob => {
                                totalCost += (parseFloat(ob.cost) * parseFloat(ob.qty))
                            });

                            budget.budget_machines.forEach(ob => {
                                totalCost += (parseFloat(ob.cost) * parseFloat(ob.hours))
                            });

                            budget.budget_work_hand.forEach(ob => {
                                totalCost += (parseFloat(ob.cost) * parseFloat(ob.hours))
                            });

                            chapter_class_cost = totalCost * budget.qty
                            if(isNaN(chapter_class_cost))
                                chapter_class_cost = 0
                        }

                    });
                    
                    let margin = 1
                    if(chapter_class.chapter_class_margin != null){
                        margin = chapter_class.chapter_class_margin / 100
                        let increment = chapter_class_cost * margin;
                        chapter_class_cost = chapter_class_cost + increment

                        // Ks
                        total_ks = chapter_class.chapter_class_margin
                        sum_ks++
                    }

                    chapter_cost += chapter_class_cost
                });
                        

                    let margin = 1
                    if(chapter.chapter_margin != null){
                        margin = chapter.chapter_margin / 100
                        let increment = chapter_cost * margin;
                        chapter_cost = chapter_cost + increment
                        // Ks
                        total_ks = chapter.chapter_margin
                        sum_ks++
                    }
                    
                    custos = custos + chapter_cost
            });

            this.fields.indirect_work_hand.forEach(element => {
                custos = custos + (element.hours * element.cost * element.working_days)
            });

            custos = custos + (parseFloat(this.fields.stable_cost) * parseFloat(this.fields.stable_days))

            custos = custos + (parseFloat(this.fields.general_expenses_cost) * parseFloat(this.fields.general_expenses_days))
            
            let encargos_percentagem = 0
            if(this.fields.general_charges != null)
                if(this.fields.general_charges != '')
                    encargos_percentagem = parseFloat(this.fields.general_charges) / 100
                    
            
            this.fields.budget = (custos + (parseFloat(encargos_percentagem) * custos))
            
            
            this.fields.budget = parseFloat(this.fields.budget).toFixed(2)

        

            // Calcular média do K
            this.fields.k = total_ks / sum_ks
        },
        onSuccess() {
            if(this.fields.id != null) {
            this.success = false
            return
            }
            let domain = '/' + this.$session.get('domain')
            this.$router.push(domain + '/admin/budgeting/works');
        },
        async fillBaseData(){                
            Client.list().then(({data}) => {
                this.clients = data
            });   

            ChapterAPI.list().then(({data}) => {
                this.chapters = data
            });

            Unit.list().then(({data}) => {
                this.units = data
            });

            MaterialsComposite.list().then(({data}) => {
                this.materials_composite = data
            });

            Materials.list().then(({data}) => {
                this.materials = data
            });

            Machine.list().then(({data}) => {
                this.machines = data
            });

            Worker.list().then(({data}) => {
                this.workers = data
            });

            Indirect.list().then(({data}) => {
                this.indirect = data
            });

            Category.list().then(({data}) => {
                this.categories = data
            });

            Subempreiteiro.list().then(({data}) => {
                this.subempreiteiros = data
            })            
        },
        addUnitValidationError(unit, e, place){
            let found = false
            this.unitsValidation.forEach(element => {
                if(element.unit == unit){
                    element.problems.push({
                        erro: e,
                        place: place
                    })
                    found = true
                }
            });

            if(!found){
                this.unitsValidation.push({
                    unit: unit,
                    problems: []
                })
                this.addUnitValidationError(unit, e, place)
            }
        },
        getFilename(file){
            if(file == null)
                return ''

            if(file instanceof File)
                file = file.name
            
            return file.substring(file.lastIndexOf('/')+1)
        },
        submit() {
            let hasProblem = false
            this.unitsValidation = []
        
            
            
            this.fields.chapters.forEach(chapter => {
                chapter.classes.forEach(chapter_class => {
                    chapter_class.units.forEach(element => {

                        if(element.manual == false){
                            element.budget_materials.forEach(m => {
                                if(m.cost <= 0){
                                    this.addUnitValidationError(element.unit, m.material, 'materiais')
                                    hasProblem = true
                                }
                            });
                            element.budget_machines.forEach(m => {
                                if(m.cost <= 0){
                                    this.addUnitValidationError(element.unit, m.machine, 'máquinas')
                                    hasProblem = true
                                }
                            });
                            element.budget_materials_composite.forEach(m => {
                                if(m.cost <= 0){
                                    this.addUnitValidationError(element.unit, m.material, 'materiais compostos')
                                    hasProblem = true
                                }
                            });
                            element.budget_work_hand.forEach(m => {
                                if(m.cost <= 0){
                                    this.addUnitValidationError(element.unit, m.worker, 'mão de obra')
                                    hasProblem = true
                                }
                            });
                        }
                    })
                })
            });

            if(hasProblem){
                this.unitsNoCost = true
                return
            }

            let a = false

            if(a){
                return;
            }
            
    
            this.$refs.form.validate().then((result) => {
            this.loading = true;
            
            if(!result){
                this.loading = false;
                return;
            }
    
            let data = {...this.fields};
    
            
    
            Budget[this.method](data, this.fields.id).then(() => {

                this.success = true;
    
                this.loading = false;
           
        
    
                
            }).catch(err => {
                this.loading = false;
    
                if(err.response.status == 422) {
    
                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }
    
                this.error.title = this.$t('globals.error') + " " + err.response.status;
    
                this.error.message = err.response.data.message;
    
                });
            })
        }
        },
        computed: {
            canWrite(){
                let flag = false
                this.fields.chapters.forEach(element => {
                    if(element.add_chapter_margin)
                        flag = true

                    element.classes.forEach(classe => {
                        if(classe.add_chapter_class_margin)
                            flag = true
                    });
                });

                return !flag
            },
            status(){
                let s = [this.$t('budgeting.states.ON_BUDGET'), this.$t('budgeting.states.ACCEPTED'), this.$t('budgeting.states.WAITING')]

                return s
            }
        }
  };
  </script>
  
<style>
.centered-input input {
  text-align: center
}
</style>