<template>
    <section class="mt-16">
        <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>

        <h1 class="primary--text">
            <rp-icon icon="rp-cog-blue-filled" class="mr-2"/> <span>{{$t('import_data.crumb_import')}}</span>
        </h1>
        <div style="position:relative" v-if="!loading">
            <validation-observer ref="form" v-slot="{ invalid }">
                <v-form @submit.prevent="submit">
                    <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <validation-provider v-slot="{ errors }" vid="Tipo" :name="$t('import_data.choose_type')" rules="required">
                                        <v-select
                                            v-model="type"
                                            :items="destiny"
                                            menu-props="auto"
                                            :label="$t('import_data.choose_type')"
                                            :error-messages="errors"
                                            persistent-hint
                                            prepend-icon="mdi-file-delimited"
                                        ></v-select>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-btn class="success" @click="exportTemplateFile()" :disabled="type == null">
                                        <v-icon class="mr-2">mdi-export</v-icon>
                                        {{$t('import_data.export_template')}}
                                    </v-btn>
                                </v-col>  
                                <v-col cols="12">
                                    <validation-provider v-slot="{ errors }" vid="Ficheiro" :name="$t('import_data.upload')" rules="required">
                                        <v-file-input
                                            show-size
                                            :label="$t('import_data.upload')"
                                            v-model="file"
                                            :error-messages="errors"
                                            accept=".csv, .xlsx"
                                        ></v-file-input>
                                    </validation-provider> 
                                </v-col>
                            </v-row>                           
                            <v-row class="mt-6" align="center" justify="space-around">
                            <v-col align="center" justify="space-around">
                                <v-btn :disabled="invalid" depressed :class="($root.$vuetify.theme.isDark ? 'white primary--text' : 'primary') + ' mr-5 ml-5 mb-5'" type="submit">
                                    {{$t('globals.import')}}
                                </v-btn>
                            </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-form>
            </validation-observer>
        </div>
        <Loader v-if="loaderAction"></Loader>  
        <v-dialog
            v-model="importResult.show"
            width="600px"
            >
            <v-card>
                <v-card-title class="primary">
                    <span class="text-h5 mr-4 white--text">{{$t('import_data.export_result')}}: </span><span :class="importResult.msg.success ? 'success--text' : 'error--text'">{{ importResult.msg.success ? $t('import_data.good'): $t('import_data.error') }}</span>
                </v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    {{$t('import_data.line')}}
                                </th>
                                <th class="text-left">
                                    {{$t('import_data.column')}}
                                </th>
                                <th class="text-left">
                                    {{$t('globals.obs')}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="(m, index) in importResult.msg.errors"
                            :key="index"
                            >
                            <td>{{ m.row }}</td>
                            <td>{{ m.attribute }}</td>
                            <td>
                                <ul v-for="erro in m.errors" :key="erro">
                                    <li>{{ erro }}</li>
                                </ul>
                            </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions>
                <v-btn
                    color="green darken-1"
                    text
                    @click="importResult.show = false"
                >
                {{$t('globals.ok')}}
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
    </section>
</template>
<script>
import Import from '@/api/Import.js'
import Loader from '@/components/ui/Loader.vue'

export default{
    components:{
        Loader
    },
    data(){
        return{
            importResult: {
                show: false,
                msg: ''
            },
            loaderAction: false,
            loading: true,
            type: null,
            file: null,
            success: false,
            destiny: ['Fornecedores', 'Unidades de Obra', 'Materiais', 'Mão Obra Indireta', 'Unidades de medida', 'Máquinas', 'Colaboradores', 'Clientes']
        }
    },
    mounted(){
        this.loading = false
    },
    methods:{
        exportTemplateFile(){
            /*

            if(this.fields.fileType >= 12 && this.fields.fileType <= 16){
                let data = ''
                this.files.forEach(element => {
                    if(element.id == this.fields.fileType)
                        data = element.type
                });
                Export.generalExport(data)
                .then((resp) => {            
                    window.open(resp.data.url, '_blank')
                    Export.delete( resp.data.path)
                    .then(() => {
                    });
                });
            }


            Import.getTemplate(data)
                .then((resp) => {      
                window.open(resp.data, '_blank')               
            });

            */
            let data = {
                type: this.type
            }
            Import.getTemplate(data)
                .then((resp) => {      
                window.open(resp.data, '_blank')               
            });
        },
        submit(){
            this.loaderAction = true
            this.$refs.form.validate().then((result) => {
                this.loading = true;
                
                if(!result){
                    this.loading = false;
                    return;
                }
                
                const formData = new FormData()
                formData.append('file', this.file)
                formData.append('type', this.type)

                Import.create(formData).then((dt) => {
  
                    this.success = true;

                    this.loading = false;

                    this.loaderAction = false

                    this.importResult.msg = dt.data
                    this.importResult.show = true
                    
                    }).catch(err => {
                    this.loading = false;

                        if(err.response.status == 422) {

                        this.$refs.form.setErrors(err.response.data.errors);
                        
                        return;
                        }

                        this.error.title = this.$t('globals.error') + " " + err.response.status;

                        this.error.message = err.response.data.message;

                        this.loaderAction = false

                    });
            })
        }
    },
    computed:{
        crumbs: function() {
        return [
        {
            text: this.$t('globals.definitions'),
            disabled: false,
            to: '/' + this.$session.get('domain') + '/admin/settings/general',
            exact: true,
            },
            {
            text: this.$t('import_data.crumb_import'),
            disabled: true,
            to: window.location.href,
            exact: true,
            },
        ];
        }
    }
}
</script>