<template>
    <v-container fluid v-if="loading == false">
        <v-dialog
            v-model="fullscreen"
            fullscreen
            transition="dialog-top-transition"
        >        
            <v-card 
                    :class="$root.$vuetify.theme.isDark ? 'secondary white--text' : ''">
                <v-toolbar
                    dark
                    color="primary"
                >
                    <v-toolbar-title>Gantt {{ getWeekNumber }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                        icon
                        dark
                        @click="fullscreen = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <Gantt 
                    @viewListWorkUnit="viewListWorkUnit"
                    @fullscreen="fullscreen = !fullscreen"
                    @submit="$emit('submit', 99)"        
                    :tasks="tasks"
                    :dialog="true"
                    :defaultInitDate="initDate"
                    :defaultEndDate="endDate"
                    propZoom="50" 
                    :edit="edit"
                />
            </v-card>
        </v-dialog>
        <Gantt 
            propZoom="75" 
            :zoomAvailable="false" 
            @viewListWorkUnit="viewListWorkUnit"
            @fullscreen="fullscreen = !fullscreen"    
            @submit="$emit('submit')"
            :defaultInitDate="initDate"
            :defaultEndDate="endDate"
            :tasks="tasks"
            :edit="edit"
        />
    </v-container>
</template>
<script>
import Gantt from '@/components/ui/Gantt/Gantt.vue'
export default{
    name: 'GanttTime',
    components:{
        Gantt
    },
    props:{
        data: Array,
        openFullScreen: Boolean, 
        work_days: Object,
        defaultTodayDate: {
            typeof: Date,
            default: null
        },
        edit: {
            typeof: Boolean,
            default: true
        }
    },
    mounted(){
        if(this.defaultTodayDate != null){
            const today = new Date(this.defaultTodayDate);
            const firstDay = new Date(
                today.setDate(today.getDate() - today.getDay()),
            );
            const lastDay = new Date(
                today.setDate(today.getDate() - today.getDay() + 6),
            );
            this.initDate = firstDay
            this.endDate = lastDay
        }

        let ar = []
        this.data.forEach(element => {
            let endData = new Date(element.start_date)
            // Este é o total de dias úteis... Vamos verificar se desde o ínicio da UO até ao final estas datas são seguidas
            // Ou se temos de contar com alguma paragem
            let total_dias = Math.round(element.qty_budgeted / element.budgeted_income)

            var days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
            let from = new Date(element.start_date) 
            let sem_trabalho = 0;
            for(let i = from; total_dias >= 0; i.setDate(i.getDate() + 1)){                
                if(this.work_days[days[i.getDay()]]){
                    total_dias--
                }else{
                    sem_trabalho++
                }                
            }
            
            total_dias = Math.round(element.qty_budgeted / element.budgeted_income)
            

            let dias_necessarios = sem_trabalho + total_dias
            endData.setDate(endData.getDate() + dias_necessarios)
            endData = endData.getFullYear() + '-' + (endData.getMonth()+1) + '-' + endData.getDate()

            
            
            ar.push({
                id: element.id,
                task: element.unit,
                init: element.start_date,
                end: endData,
                days: dias_necessarios,
                per: element.per,
                ignore: false
            })
        });
        this.tasks = ar

        this.fullscreen = this.openFullScreen

        this.loading = false
    },
    methods:{
        viewListWorkUnit(e){
            let request = null
            this.data.forEach(element => {
                if(element.id == e.id)
                    request = element
            })
            this.$emit('viewListWorkUnit', request)
        }
    },
    data(){
        return{
            loading: true,
            fullscreen: false,
            endDate: null,
            initDate: null
        }
    },
    computed:{        
        getWeekNumber(){
            if(this.defaultTodayDate == null)
                return ''

            let currentDate = new Date(this.defaultTodayDate)
            let year = new Date(currentDate.getFullYear(), 0, 1);
            let days = Math.floor((currentDate - year) / (24 * 60 * 60 * 1000));

            return 'Semana: ' + Math.ceil(( currentDate.getDay() + 1 + days) / 7)
        },
    }
}
</script>