<template>
    <v-container fluid class="mt-n16 mb-6">
        <v-data-table
            :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'"
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
            locale="pt-pt"
        >

        <template v-slot:item.invoiced="{item}">
            {{ $currency(item.invoiced.total_invoiced) }}
        </template>
            
        </v-data-table>
    </v-container>
</template>
<script>
import ClientAPI from "@/api/Client.js";

export default{
    name: "ClientWorks",
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchClientWorks()}, 10);
        },
        deep: true,
      },
    },
    data(){
        return{
            items: [],
            total: 0,
            loading: true,
            options: {}
        }
    },
    mounted(){
        this.fillBaseData()
        this.loading = false
    },
    methods:{      
        async fillBaseData(){
            await this.searchClientWorks()
        },
        searchClientWorks(event){
            if(!!event && event.type == 'submit'){
                this.options.page = 1;
            }
    
            this.loading = true;
    
            let filter = {...this.filter};
    
            let request = {
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                page: this.options.page,
                limit: this.options.itemsPerPage,
                id_client: this.$route.params.id
            };
        
            Object.assign(request, filter);
    
            localStorage["client-works-" + this.$root.session.id] = JSON.stringify({
                filter: this.filter,
                options: this.options,
            });
    
            ClientAPI.searchWorks(request).then(response => {
    
                let data = response.data;
        
                this.total = data.total;
        
                this.items = data.data.map((item) => {
        
                    this.searching = false;
        
                    return item;
                });
        
                this.loading = false;
    
            });
        },
    },
    computed:{      
        headers(){
            let he = [          
                {
                    text: this.$t('globals.code'),
                    value: 'code'
                },
                {
                    text: this.$t('globals.work'),
                    value: 'name'
                },
                {
                    text: this.$t('shoppings.total_invoiced'),
                    value: 'invoiced'
                },
                ]

                return he
            },            
        tableFooter(){
            let t = this.$t("globals.lines_datatable")
            let a = [5, 10, 15, 20];
            return {
                'items-per-page-options': a,
                'items-per-page-text': t
            }
        },
    }
}
</script>