var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-16",class:_vm.$root.$vuetify.theme.isDark ? 'secondary elevation-0' : '',style:(this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'),attrs:{"elevation":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('settings.schedule.work_hours'))+":")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"vid":"work_hours","name":_vm.$t('globals.work_hours'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"readonly":"","prepend-inner-icon":"mdi-clock","label":_vm.$t('globals.work_hours') + ' *',"error-messages":errors},model:{value:(_vm.fields.work_hours),callback:function ($$v) {_vm.$set(_vm.fields, "work_hours", $$v)},expression:"fields.work_hours"}})]}}])})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('settings.schedule.start_month'))+":")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"vid":"days","name":_vm.$t('globals.days'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"readonly":"","items":_vm.days_starting,"menu-props":"auto","label":_vm.$t('globals.days'),"prepend-inner-icon":"mdi-view-day-outline","error-messages":errors},model:{value:(_vm.fields.starting_month),callback:function ($$v) {_vm.$set(_vm.fields, "starting_month", $$v)},expression:"fields.starting_month"}})]}}])})],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-8",attrs:{"color":"#6E7A90"}}),_c('v-divider',{attrs:{"color":"#6E7A90"}}),_c('v-divider',{staticClass:"mb-8",attrs:{"color":"#6E7A90"}}),_c('v-card-title',[_vm._v(_vm._s(_vm.$t('settings.schedule.extra_hours_classify'))+":")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"50","name":"50","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"readonly":"","prepend-inner-icon":"mdi-clock","label":"50%","error-messages":errors},model:{value:(_vm.fields.extra_50),callback:function ($$v) {_vm.$set(_vm.fields, "extra_50", $$v)},expression:"fields.extra_50"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"75","name":"75","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"readonly":"","prepend-inner-icon":"mdi-clock","label":"75%","error-messages":errors},model:{value:(_vm.fields.extra_75),callback:function ($$v) {_vm.$set(_vm.fields, "extra_75", $$v)},expression:"fields.extra_75"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"100","name":"100","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"readonly":"","prepend-inner-icon":"mdi-clock","label":"100%","error-messages":errors},model:{value:(_vm.fields.extra_100),callback:function ($$v) {_vm.$set(_vm.fields, "extra_100", $$v)},expression:"fields.extra_100"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('small',[_vm._v("("+_vm._s(_vm.$t('settings.schedule.example'))+")")])])],1)],1),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":_vm.onSuccess}},[_vm._v(" "+_vm._s(_vm.$t('settings.schedule.success'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }