<template>
    <section>
        <div style="position:relative">
          <v-container>
            <v-speed-dial
              v-if="false"
              absolute
              v-model="speedDial"
              top
              right
              direction="bottom"
              :open-on-hover="true"
            >
              <template v-slot:activator>
                <v-tooltip right color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" v-on="on"
                      v-model="speedDial"
                      color="primary"
                      dark
                      x-large
                      fab
                    >
                      <v-icon v-if="speedDial">
                        mdi-close
                      </v-icon>
                      <v-icon v-else>
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('globals.options')}}</span>
                </v-tooltip>
              </template>              
              <v-tooltip right color="info" v-if="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="info"
                    large
                    @click="searching = true"
                  >
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('globals.search')}}</span>
              </v-tooltip>              
            </v-speed-dial>
          </v-container>

          <v-row v-if="$store.state.company_configurations.eticadata != null && hasFeature('eticadata.config')">
            <v-col cols="12" md="6" align="start">              
              <v-btn @click="dialog = true" rounded class="success">
                <v-icon class="mr-2">mdi-cog-transfer</v-icon>
                {{$t('globals.transfer')}}
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" align="end">              
              <v-row>
                <v-col cols="12" class="font-weight-bold">                  
                  {{$t('work.sync_to')}}:
                </v-col>
                <v-col cols="12">                  
                  <v-btn @click="sync.show=true" color="warning" outlined rounded>Eticadata</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
  
          <v-data-table 
            :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'"
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
            locale="pt-pt"
          >
          
            <template v-slot:item.actions="{item}">
              <v-row>
                <v-col cols="12" md="auto">
                  <v-btn text fab outlined small @click="removeMaterial(item)">
                    <v-icon>mdi-swap-horizontal</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="auto">
                  <v-btn text fab outlined small @click="openHistory(item)">
                    <v-icon>mdi-history</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
                                
          </v-data-table>
        </div>
        <v-navigation-drawer
            fixed
            right
            v-model="searching"
            hide-overlay
            width="520"
            class="pt-6"
          >
          <v-list>
            <v-list-item>
              <v-btn fab small color="secondary" @click="searching = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
  
              <v-btn absolute right small color="error" @click="resetLocation">
                {{$t('globals.clean_fields')}}
              </v-btn>
            </v-list-item>
          </v-list>
          <v-list class="pt-10">
            <v-form @submit.prevent="searchMaterialsLocation">
              
              
  
              <v-row
                class="mt-10"
                align="center"
                justify="space-around"
              >
              <v-btn
                color="primary"
                type="submit"
              >
              {{$t('globals.search')}}
              </v-btn>
              </v-row>
            </v-form>
          </v-list>
        </v-navigation-drawer>
  
        
        <ErrorSnackbar v-model="error.show" :message="error.message"/>

        
      <SuccessSnackBar v-model="modalSuccess.show" :message="modalSuccess.message"/>

      <v-dialog
          v-model="dialog"
          width="900px"
        >
          <v-toolbar class="primary white--text">{{$t('globals.material')}}</v-toolbar>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <span class="mt-8">{{$t('materials.transfer')}}</span>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="materialWork.material_id"
                    :label="$t('globals.materials')"
                    :items="materials"
                    item-value="id"
                    :item-text="item => item.reference + ' - ' + item.name"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                    v-model="materialWork.material_id"
                    :label="$t('globals.stock')"
                    :items="materials"
                    item-value="id"
                    disabled
                    :item-text="item => item.stock"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    type="number"
                    v-model="materialWork.qty"
                    :label="$t('globals.quantity')"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row align="center" class="mb-6" justify="space-around">
                <v-btn @click="transferMaterial" :disabled="materialWork.material_id == null && materialWork.qty == null" depressed :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary white--text'">             
                  {{$t('globals.save')}}
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
      </v-dialog>



      <v-dialog
          v-model="dialogRemove"
          width="900px"
        >
          <v-toolbar class="primary white--text">{{$t('globals.material')}}</v-toolbar>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <span class="mt-8">{{$t('materials.remove')}}</span>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    readonly
                    v-model="materialWork.material_id"
                    :label="$t('globals.material')"
                    :items="materials"
                    item-value="id"
                    :item-text="item => item.reference + ' - ' + item.name"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    type="number"
                    v-model="materialWork.qty"
                    :label="$t('globals.quantity')"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="materialWork.has_work"
                    :label="$t('materials.transfer_other_work')"
                  />
                </v-col>
                <v-col cols="12" md="6" v-if="materialWork.has_work">
                  <v-autocomplete
                    v-model="materialWork.work_id_destination"
                    :label="$t('globals.work')"
                    :items="computeWorks"
                    item-value="id"
                    :item-text="item => item.code + ' - ' + item.name"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row align="center" class="mb-6" justify="space-around">
                <v-btn @click="removeMaterialAction" :disabled="materialWork.work == null && materialWork.qty == null" depressed :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary white--text'">             
                  {{$t('globals.save')}}
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
      </v-dialog>

      <v-dialog v-model="history.open"
          width="900px">
        <v-toolbar class="primary white--text">{{$t('globals.history')}}</v-toolbar>
        <v-card class="elevation-0">
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
              <thead>
                  <tr>
                  <th>
                      {{$t('globals.user')}}
                  </th>
                  <th class="text-left">
                      {{$t('globals.quantity')}}
                  </th>
                  <th class="text-left">
                      {{$t('globals.data')}}
                  </th>
                  <th class="text-left">
                      {{$t('globals.from')}}
                  </th>
                  <th class="text-left">
                      {{$t('globals.to_para')}}
                  </th>
                  </tr>
              </thead>
              <tbody v-for="item in history.item.story" :key="item.id">
                  <tr>
                      <td>{{ item.who }}</td>
                      <td>{{ item.action }}</td>
                      <td>{{ item.created_at }}</td>
                      <td>{{ item.from_work }}</td>
                      <td>{{ item.to_work }}</td>
                  </tr>
              </tbody>
            </template>
          </v-simple-table>
          </v-card-text>
        </v-card>
      </v-dialog>

      
    <DialogConfirmation 
      v-if="sync.show" 
      :title="$t('menu.sync')" 
      type="info" 
      :opened="sync.show" 
      @on-submit="startSync" 
      @on-cancel="sync.show = false">
      {{$t('syncs.are_you_sure')}}
    </DialogConfirmation>

    <SuccessSnackBar v-model="success" :message="$t('syncs.success')"/>

    </section>
  </template>
  <script>
  import Work from '@/api/Work.js'
  import Materials from '@/api/Materials.js'
  import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
  import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';
  import DialogConfirmation from '@/components/ui/DialogConfirmation.vue';
  import Syncs from '@/api/Syncs.js'
  
  export default {
    components: {
      ErrorSnackbar,
      SuccessSnackBar,
      DialogConfirmation
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchMaterialsWork()}, 10);
        },
        deep: true,
      },
    },
    data: () => ({
      dialog: false,
      sync:{
        show: false,
      },
      dialogRemove: false,
      materialWork:{
        work_id: null,
        qty: null,
        material_id: null,
        aux: null,
        has_work: false,
        work_id_destination: null
      },
      error: {
        title: '',
        message: '',
        show:false,
      },
      items: [],
      works: [],
      materials: [],
      total: 0,
      loading: false,
      searching: false,
      options: {},
      speedDial:false,
      filter: {
        work_id: null
      },
      success: false,
      modalSuccess:{
        show: false,
        msg: ''
      },
      history:{
        item: null,
        open: false
      }
    }),
    mounted(){
      this.modalSuccess.msg = this.$t('materials.success');

      if(!this.$root.session.hasPermission(["super", "materials.read"])) {
        this.$router.push('/admin');
      }

  
  
      this.fillBaseData();
    },
    methods:{
      hasFeature(f){
        if(this.$root.configurations == null)
          return false;

        if(this.$root.configurations.features == null)
          return false;

        let r = false

        this.$root.configurations.features.forEach(element => {
          if(element == f)
            r = true
        });

        return r
      },
      async startSync(){
        this.loading = true
        let data = {
            tenant: this.$route.params.tenant,
            action: 'materials'
        }

        await Syncs.eticadata(data).then(() => {
            this.loading=false
            this.sync.show = false
            this.success = true
        }).catch(err => {

            this.loading=false
            if(err.response.status == 422) {

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.errors.file[0];
                

                this.error.show = true;  
                return;
            }

        });
      },
      openHistory(item){
        this.history.item = null
        this.history.open = false
        this.history.item = item
        this.history.open = true
      },
      removeMaterial(item){
        this.materialWork = item
        this.materialWork.work_id_destination = null
        this.materialWork.aux = item.qty
        this.dialogRemove = true
      },
      removeMaterialAction(){
        this.materialWork.work_id = this.$route.params.id

        this.loading = true

        if(this.materialWork.aux < this.materialWork.qty){
          this.error.message = this.$t('materials.error_transfer')
          this.error.show = true
          this.loading = false
          return
        }

        Work.deleteMaterial(this.materialWork).then(() => {
    
          this.modalSuccess.show = true;

          this.loading = false;

          this.dialogRemove = false

          this.searchMaterialsWork()

        }).catch(err => {
            this.loading = false;

            if(err.response.status == 422) {

                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
            }

            this.error.title = this.$t('globals.error') + " " + err.response.status;

            this.error.message = err.response.data.message;

          });
      },
      transferMaterial(){
        let material_escolhido = null
        this.materials.forEach(element => {
          if(element.id == this.materialWork.material_id)
            material_escolhido = element
        });
        
        this.materialWork.work_id = this.$route.params.id

        this.loading = true

        if(material_escolhido.stock < this.materialWork.qty){
          this.error.message = this.$t('materials.error_transfer')
          this.error.show = true
          this.loading = false
          return
        }

        Work.addMaterial(this.materialWork).then(() => {
    
          this.modalSuccess.show = true;

          this.loading = false;

          this.dialog = false

          this.searchMaterialsWork()
          
      }).catch(err => {
          this.loading = false;

          if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
          }

          this.error.title = this.$t('globals.error') + " " + err.response.status;

          this.error.message = err.response.data.message;

        });
      },
      fillBaseData() {
  
        if(localStorage["materials-work-list-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["materials-work-list-" + this.$root.session.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }

        Materials.list().then(({data}) => {
            this.materials = data
        });

        Work.list().then(({data}) => {
            this.works = data
        });
      },
      searchMaterialsWork(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;

        this.filter.work_id = this.$route.params.id
  
        let filter = {...this.filter};
        
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          filter: filter
        };
      
        Object.assign(request, filter);
  
        localStorage["materials-work-list-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        Work.getMaterials(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });
  
          this.loading = false;
  
        });
      },
      resetLocation()
      {
        this.filter = {
          is_active: 1
        };
  
        this.searchMaterialsWork();
      },
    },
    computed: {
      computeWorks(){
        let toUse = []
        this.works.forEach(element => {
          if(element.id != this.$route.params.id)
            toUse.push(element)
        });
        return toUse
      },
      headers(){
        let he = [          
          {
            text: this.$t('globals.code'),
            sortable: true,
            value: 'material.reference'
          },         
          {
            text: this.$t('globals.material'),
            sortable: true,
            value: 'material.name'
          },          
          {
            text: this.$t('globals.quantity'),
            sortable: true,
            value: 'qty'
          },           
          {
            text: this.$t('globals.created_at'),
            sortable: true,
            value: 'created_at'
          },           
          {
            text: this.$t('globals.updated_at'),
            sortable: true,
            value: 'updated_at'
          },             
          {
            text: this.$t('globals.options'),
            sortable: true,
            value: 'actions'
          },     
        ]
        return he
      },
      tableFooter(){
        let t = this.$t("globals.lines_datatable")
        let a = [5, 10, 15, 20];
        return {
          'items-per-page-options': a,
          'items-per-page-text': t
        }
      }
    }
  };
  </script>
  