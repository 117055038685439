import BaseApi from "./BaseApi";

export default class Export extends BaseApi{
    construct() {}
    
    static async exportDiary(id, data) {
        let url = Export.getFullApiUrl() + "diary" + "/" + id;

        return Export.getAxiosInstance().get(url, {params:data});
    }

    static async exportDiaries(data){
        let url = Export.getFullApiUrl() + "export_diaries";

        return Export.getAxiosInstance().get(url, {params:data});
    }

    static async exportMORendimentos(id, data) {
        let url = Export.getFullApiUrl() + "mo_rendimentos" + "/" + id;

        return Export.getAxiosInstance().get(url, {params:data});
    }

    static async exportRendimentoPorUO(id, data) {
        let url = Export.getFullApiUrl() + "rendomento_uo" + "/" + id;

        return Export.getAxiosInstance().get(url, {params:data});
    }

    static async exportRendimentoUOs(data) {
        let url = Export.getFullApiUrl() + "rendimentos_all_uo";

        return Export.getAxiosInstance().get(url, {params:data});
    }

    static async generalExport(data, params = null) {
        let url = Export.getFullApiUrl() + data;

        return Export.getAxiosInstance().get(url, {params:params});
    }

    static async exportBudget(data, params = null) {
        let url = Export.getFullApiUrl() + data;

        return Export.getAxiosInstance().get(url + '/' + params.id);
    }    

    static async delete(id) {
        return await Export.getAxiosInstance().delete(Export.getFullApiUrl() + 'file/' + id);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/export/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/export/";
    }
}