<template>
    <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
        <validation-observer ref="form" v-slot="{ invalid }">
            <fieldset>
              <v-form @submit.prevent="editUnit == null ? addUnit() : updateUnit()" :disabled="loading">
                <v-card-title :class="$root.$vuetify.theme.isDark ? 'white--text subtitle-1 primary' : 'white--text subtitle-1 primary'">
                  
                    {{$t('units_management.add_new')}}
                  
                </v-card-title>
                <v-card-text>
                  <DialogSuccess :opened="success" @on-ok="success = false;" >
                    {{$t('units_management.success')}}
                  </DialogSuccess>
                  <v-row>
                    <v-col cols="12">
                        <validation-provider v-slot="{ errors }" vid="abbreviation" :name="$t('globals.abbreviation')" rules="required">
                            <v-text-field
                                v-model="unit.abbreviation"
                                prepend-inner-icon="mdi-account-details"
                                :label="$t('globals.abbreviation') + ' *'"
                                clearable
                                :error-messages="errors"
                            ></v-text-field>
                        </validation-provider>
                    </v-col>
                    <v-col cols="12">
                        <validation-provider v-slot="{ errors }" vid="description" :name="$t('globals.description')" rules="required">
                            <v-text-field
                                v-model="unit.description"
                                prepend-inner-icon="mdi-account-details"
                                :label="$t('globals.description') + ' *'"
                                clearable
                                :error-messages="errors"
                            ></v-text-field>
                        </validation-provider>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        depressed
                        @click="$emit('close')"
                    >
                        {{$t('globals.cancel')}}
                    </v-btn>
                    <v-btn :disabled="invalid" depressed color="primary" type="submit">
                    {{$t('globals.save')}}
                    </v-btn> 
                </v-card-actions>
                </v-form>
            </fieldset>            
        </validation-observer>
    </v-card>
</template>
<script>
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import UnitsManagement from "@/api/UnitsManagement.js";

export default {
    name:'AddUnitComponent',
    components:{
        DialogSuccess
    },
    props:{
        editUnit: Object
    },
    mounted(){
        if(this.editUnit != null){
            this.unit.abbreviation = this.editUnit.abbreviation
            this.unit.description = this.editUnit.description
        }
    },
    data(){
        return{
            loading: false,
            success:false,
            unit:{
                abbreviation: '',
                description: ''
            }
        }
    },
    methods:{
        updateUnit(){
            this.loading = true
            UnitsManagement.update(this.unit, this.editUnit.id)
                .then(() => {
                    this.success = true;

                    this.loading = false;

                    this.$emit('close-update')
                });
        },
        addUnit(){
            this.loading = true
            
            UnitsManagement['create'](this.unit).then(() => {

                this.success = true;

                this.loading = false;

                this.$emit('close-update')

                }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        }
    }
}
</script>