var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:_vm.$root.$vuetify.theme.isDark ? 'secondary' : '',style:(this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75);'),attrs:{"elevation":"10"}},[_c('v-container',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"mb-16",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.loading)?_c('v-progress-linear',{staticClass:"global-loader",attrs:{"indeterminate":""}}):_vm._e(),_c('fieldset',{attrs:{"disabled":_vm.loading}},[_c('v-card',{staticClass:"transparent elevation-0"},[_c('v-card-title',{class:_vm.$root.$vuetify.theme.isDark ? 'primary' : 'primary white--text'},[_vm._v(" "+_vm._s(_vm.$t('globals.indirect_work_hand'))+" ")]),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"vid":"description","name":_vm.$t('globals.description'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":_vm.$t('globals.description') + ' *',"clearable":"","error-messages":errors},model:{value:(_vm.fields.description),callback:function ($$v) {_vm.$set(_vm.fields, "description", $$v)},expression:"fields.description"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"cost","name":_vm.$t('globals.cost'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":_vm.$t('globals.cost') + ' *',"clearable":"","error-messages":errors},model:{value:(_vm.fields.cost),callback:function ($$v) {_vm.$set(_vm.fields, "cost", $$v)},expression:"fields.cost"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('validation-provider',{attrs:{"vid":"company","name":_vm.$t('globals.supplier'),"rules":"|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.companies,"item-value":"id","item-text":function (item) { return item.name; },"menu-props":"auto","error-messages":errors,"label":_vm.$t('globals.supplier'),"prepend-inner-icon":"mdi-account"},model:{value:(_vm.fields.company_id),callback:function ($$v) {_vm.$set(_vm.fields, "company_id", $$v)},expression:"fields.company_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('Tooltip',{attrs:{"color":"primary","text":_vm.$t('indirect_work_hand.add_new_supplier'),"bottom":""}},[_c('v-btn',{class:_vm.$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary',attrs:{"fab":"","small":""},on:{"click":function($event){_vm.addCompany = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"company","name":_vm.$t('globals.internal'),"rules":"|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"color":_vm.$root.$vuetify.theme.isDark ? 'white' : 'primary',"error-messages":errors,"label":_vm.$t('globals.internal')},model:{value:(_vm.fields.internal),callback:function ($$v) {_vm.$set(_vm.fields, "internal", $$v)},expression:"fields.internal"}})]}}],null,true)})],1)],1)],1),_c('v-row',{staticClass:"mt-6",attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{class:_vm.$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary',attrs:{"disabled":invalid,"depressed":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('globals.save'))+" ")])],1)],1)],1),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":_vm.onSuccess}},[_vm._v(" "+_vm._s(_vm.$t('indirect_work_hand.success_saved'))+" ")])]}}])})],1),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.addCompany),callback:function ($$v) {_vm.addCompany=$$v},expression:"addCompany"}},[(_vm.addCompany)?_c('AddCompany',{on:{"close":function($event){_vm.addCompany = false},"close-update":function($event){return _vm.fillBaseData();}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }