import BaseApi from "./BaseApi";

export default class User extends BaseApi{
    construct() {}
    
    static async login(username, password) {
        return User.startRequest().then(() => {
            let url = BaseApi.getApiUrl() + "login";

            let data = {
                username: username,
                password: password,
                place: 'client',
                api: 'normal'
            }

            return User.getAxiosInstance().post(url, data);
        });
    }

    // Microsoft login
    static async microsoftLogin(username, oid) {
        return User.startRequest().then(() => {
            let url = BaseApi.getApiUrl() + "login";

            let data = {
                username: username,
                password: oid,
                place: 'client',
                api: 'microsoft'
            }

            return User.getAxiosInstance().post(url, data);
        });
    }

    static async login2Fa(code) {
        let url = BaseApi.getApiUrl() + "two-factor-challenge";

        return User.getAxiosInstance().post(url, {code: code});
    }

    static async confirmEmail(params) {
        return User.startRequest().then(() => {

            let url = User.getApiUrl() + "verify-email";
            
            return User.getAxiosInstance().post(url, params);
        });
    }

    static async updatePassword(params) {
        return User.startRequest().then(() => {
            let url = User.getApiUrl() + "password";

            return User.getAxiosInstance().put(url, params);
        });
    }

    static async reset(email) {
        let url = User.getApiUrl() + "reset";

        return User.getAxiosInstance().post(url, {email: email});
    }

    static async logout(id) {
        let url = BaseApi.getApiUrl() + "logout";

        return User.getAxiosInstance().post(url, {user: id});
    }

    static async getSession() {
        let url = User.getApiUrl() + "session";

        return User.getAxiosInstance().get(url);
    }

    static async list(params) {
        let url = User.getApiUrl() + "list";

        return User.getAxiosInstance().get(url,{params: params});
    }

    static async search(params) {
        return await User.getAxiosInstance().get(User.getApiUrl(),{params: params});
    }

    static async create(params) {
        return await User.getAxiosInstance().post(User.getApiUrl(),params);
    }

    static async update(params, id) {
        return await User.getAxiosInstance().put(User.getApiUrl() + id,params);
    }

    static async resendActivationEmail(id) {
        return await User.getAxiosInstance().post(User.getApiUrl() + id + "/email-verification");
    }

    static async updateActivation(user) {
        let params = {
            name: user.name,
            email: user.email,
            username: user.username,
            phone_number: user.phone_number,
            is_active: user.is_active,
            roles: user.roles.map((item) => {return item.id}),
        };

        return await User.getAxiosInstance().put(User.getApiUrl() + user.id,params);
    }

    static async delete(id) {
        return await User.getAxiosInstance().delete(User.getApiUrl() + id);
    }

    static async find(id) {
        return await User.getAxiosInstance().get(User.getApiUrl() + id);
    }

    static async enable2FA() {
        return await User.getAxiosInstance().post(BaseApi.getApiUrl() + "user/two-factor-authentication");
    }

    static async disable2FA() {
        return await User.getAxiosInstance().delete(BaseApi.getApiUrl() + "user/two-factor-authentication");
    }

    static async get2FA() {
        return await User.getAxiosInstance().get(BaseApi.getApiUrl() + "user/two-factor-qr-code");
    }

    static getInstance(data) {
        let user = new User(data);

        Object.assign(user, data);

        user.permissions = user.permissions.map((item) => {
            return item.name;
        });

        user.roles = user.roles.map((item) => {
            return item.name;
        });

        return user;
    }

    static async registerExternalLogin(username, oid, platform, id) {
        let url = User.getApiUrl() + "register/" + platform + "/" + id;

        let data = {
            username: username,
            password: oid,
            place: 'c4',
            api: platform
        }

        return User.getAxiosInstance().post(url, data);
    }

    hasPermission(permissions = []) {

        return this.permissions.some(permission => permissions.indexOf(permission) >= 0);
    }

    hasRole(roles = []) {

        return this.roles.some(role => roles.indexOf(role) >= 0);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/users/";
    }
}