<template>
  <v-btn 
        v-bind="$attrs"
        v-on="$listeners"
        icon>
    <Tooltip
      color="primary"
      :text="$t('globals.visualize')"
      top
    >
      <rp-icon
         icon="rp-eye-blue-filled" size="xsmall"/>

    </Tooltip>
  </v-btn>
</template>

<script>
  import Tooltip from '@/components/ui/Tooltip.vue';
  export default {
    name: 'uiIconView',
    components: {
      Tooltip
    },
  }
</script>

<style lang="scss" scoped>
</style>