<template>
    <section class="mt-16">
      <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <rp-icon icon="rp-apple-blue-filled" class="mr-2"/> <span>{{$t('menu.lunch_management')}}</span>
        </h1>
        <v-row>
          <v-col cols="12" md="auto">
            <rp-icon
              icon="rp-eye-blue-filled"
              class="mb-n2"
            /><v-icon class="ml-2" small color="secondary">mdi-forward</v-icon>
            <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.visualize')}}</span>
          </v-col>
          <v-col cols="12" md="auto">
            <v-icon color="red" class="ml-4">
              mdi-delete
            </v-icon><v-icon class="ml-2" small color="secondary">mdi-forward</v-icon>
            <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.remove')}}</span>
          </v-col>
        </v-row>
  
        <div style="position:relative">
          <v-container>
            <v-speed-dial
              absolute
              v-model="speedDial"
              top
              right
              direction="bottom"
              :open-on-hover="true"
            >
              <template v-slot:activator>
                <v-tooltip right color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" v-on="on"
                      v-model="speedDial"
                      color="primary"
                      dark
                      x-large
                      fab
                    >
                      <v-icon v-if="speedDial">
                        mdi-close
                      </v-icon>
                      <v-icon v-else>
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('globals.options')}}</span>
                </v-tooltip>
              </template>
              <v-tooltip right color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="secondary"
                    large
                    :to="'/' + $session.get('domain') + '/admin/workers-settings/lunch-hour/create'"
                    v-if="$root.session.hasPermission(['super', 'lunch_hour.create'])"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('globals.register')}}</span>
              </v-tooltip>
            </v-speed-dial>
          </v-container>
  
          <v-expansion-panels v-if="items.length > 0">
            <v-expansion-panel
              v-for="(item,i) in items"
              :key="i"
            >
            <v-expansion-panel-header :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
              <v-row>
                <v-col cols="12">
                  <strong>{{ item.hour }} horas</strong><small> ({{ item.merged.length }} utilizadores associados) </small>
                  <IconView
                    class="ml-4 mr-2"
                    @click="viewLunchHour(item)"
                  />
                  <IconRemove
                    v-if="item.workers.length == 0 && item.users.length == 0 && $root.session.hasPermission(['super', 'lunch_hours.delete'])"
                    class="mr-2"
                    @on-submit="deleteLunchHour(item)"
                  />
                </v-col>                
              </v-row>
            </v-expansion-panel-header>
            <v-divider></v-divider>
            <v-expansion-panel-content class="mt-2">
              <v-row v-for="(worker, x) in item.merged" :key="x">
                <v-col cols="12">
                  {{ worker.name }}

              </v-col>
              <v-divider></v-divider>
              
              
              </v-row>
              <v-row v-if="item.workers.length == 0">
                <v-col cols="12">
                  <p class="caption">({{$t('lunch_hours.no_data')}})</p>
                </v-col>
              </v-row>
            </v-expansion-panel-content>

            </v-expansion-panel>
          </v-expansion-panels>

          <v-container v-else :class="'primary--text'">
            <small>{{$t('lunch_hours.none_created')}}</small>
          </v-container>
          
        </div>
        <v-navigation-drawer
            fixed
            right
            v-model="searching"
            hide-overlay
            width="520"
            class="pt-6"
          >
          <v-list>
            <v-list-item>
              <v-btn fab small color="secondary" @click="searching = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
  
              <v-btn absolute right small color="error" @click="resteLunchHour">
                {{$t('globals.clean_fields')}}
              </v-btn>
            </v-list-item>
          </v-list>
          <v-list class="pt-10">
            <v-form @submit.prevent="searchLunchHours">
              
              <v-list-item>
                <v-text-field
                  v-model="filter.name"
                  prepend-inner-icon="mdi-form-textbox"
                  :label="$t('globals.name')"
                  clearable
                ></v-text-field>
              </v-list-item>
  
              <v-row
                class="mt-10"
                align="center"
                justify="space-around"
              >
              <v-btn
                color="primary"
                type="submit"
              >
                {{$t('globals.search')}}
              </v-btn>
              </v-row>
            </v-form>
          </v-list>
        </v-navigation-drawer>
    </section>
  </template>
  <script>
  import IconRemove from '@/components/ui/IconRemove.vue';
  import IconView from '@/components/ui/IconView.vue';
  import LunchHour from "@/api/LunchHour.js";
  import dayjs from 'dayjs';
  
  export default {
    components: {
      IconRemove,
      IconView
    },
    watch: {
      'options': {
        immediate: true,
        handler () {
          setTimeout(() => {this.searchLunchHours()}, 10);
        },
        deep: true,
      },
    },
    beforeCreate(){
      document.title = "Reportlake - " + this.$t('menu.lunch_management');
    },
    data: () => ({
      items: [],
      total: 0,
      loading: false,
      searching: false,
      options: {},
      speedDial:false,
      filter: {
        code:null,
        name: null,
      },
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "lunch_hour.read"])) {
        this.$router.push('/admin');
      }
      this.fillBaseData();
    },
    methods:{
      getTableDate(date) {
        return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
      },
      fillBaseData() {
  
        if(localStorage["lunch_hour-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["lunch_hour-" + this.$root.session.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }

      },
      searchLunchHours(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
  
        let request = {
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };
      
        Object.assign(request, filter);
  
        localStorage["lunch_hour-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        LunchHour.list(this.$session.get('domain')).then(response => {
  
          let data = response.data;
  
          this.items = data
  
          this.loading = false;
  
        });
      },
      resteLunchHour()
      {
        this.filter = {
        };
  
        this.searchLunchHours();
      },
      viewLunchHour(item){
        let domain = '/' + this.$session.get('domain')
        this.$router.push(domain + '/admin/workers-settings/lunch-hour/' + item.id);
      },
      deleteLunchHour(item){
        LunchHour.delete(item.id)
          .then(() => {
            this.searchLunchHours();
          });
      },
    },
    computed: {
      headers(){
        let he = [
          {
            text: this.$t('globals.hours'),
            value: 'hour',
          },
          {
            text: this.$t('globals.workers'),
          },
        ]
        return he
      },
      tableFooter(){
        let t = this.$t("globals.lines_datatable")
        let a = [5, 10, 15, 20];
        return {
          'items-per-page-options': a,
          'items-per-page-text': t
        }
      }, 
      crumbs: function() {
        return [
          {
            text: this.$t('menu.dashboard'),
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: this.$t('menu.lunch_management'),
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  