<template>
    <v-dialog
        transition="dialog-bottom-transition"
        width="1024"
        height="1024"
        scrollable
      >
        <template v-slot:activator="{ on, attrs }">
            <v-btn     
                color="error"
                fab
                dark
                fixed
                v-bind="attrs"
                v-on="on"
                bottom
                right
            >
            <v-icon>mdi-comment</v-icon>
            </v-btn>
        </template>
        <template v-slot:default="">
          <v-card>
            <v-toolbar
              color="primary"
              dark
              >{{$t('comments.title')}}
            </v-toolbar>
            <v-card-text style="height: 800px;" v-if="comments.length > 0">
              <v-timeline dense id="card_comment">
                <v-timeline-item
                  v-for="c in comments"
                  :key="c.id"
                  large
                >
                  <template v-slot:icon>
                    <v-avatar class="white">
                      <v-icon>mdi-account</v-icon>
                    </v-avatar>
                  </template>
                  <v-card class="elevation-2">
                    <v-card-title class="text-h5">
                      {{c.comment}}
                    </v-card-title>
                    <v-card-text>
                      {{c.created_at}} - {{c.user.name}}
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
            <v-card-text v-else class="text-center mt-4">              
              <span>{{$t('comments.no_data')}}</span>
            </v-card-text>
            <v-card-actions>
              <v-row class="mt-6 ma-0 pa-0">
                <v-col cols="12">
                    <v-textarea 
                        outlined
                        label="Comentário"
                        v-model="fields.comment" />
                </v-col>
                <v-col cols="12" align="end" class="mt-n12">
                  <v-btn
                      text
                      class="primary"
                      @click="addComment"
                    >
                    {{$t('comments.comment')}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
            <DialogSuccess :opened="success" @on-ok="success = false" >
              {{$t('comments.success')}}
            </DialogSuccess>
          </v-card>
        </template>
      </v-dialog>
</template>
<script>
import Work from "@/api/Work.js"
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default{
    name: "WorkComments",
    components:{
      DialogSuccess
    },
    props:{
      comments: Array
    },
    data(){
        return{
            fields:{
                id: null,
                comment: null,
                created_at: null,
                updated_at: null,
                user_id: null,
                work_id: null
            },
            success: false,
            loading: true,
            error:{
                title: null,
                message: null,
                show: false
            }
        }
    },
    mounted(){
      this.fields.work_id = this.$route.params.id

      this.loading = false

    },
    methods:{
        addComment(){
            Work.comment(this.fields).then(() => {
                
                this.success = true;

                this.loading = false;

                this.fields.comment = null

                this.$emit('new-comment')

            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        }
    }
}
</script>