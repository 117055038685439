<template>
    <section class="mt-16">
        <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>

        <h1 class="primary--text">
            <rp-icon icon="rp-cog-blue-filled" class="mr-2"/> <span>{{$t('general.title')}}</span>
        </h1>
        <div style="position:relative" v-if="!loading">
            <v-container>
                <v-row dense justify="center">
                    <v-col cols="12" md="4" v-if='$root.session.hasPermission(["super", "roles.read"])'>
                        <v-hover>
                            <template v-slot:default="{ hover }">
                                <v-card
                                    class="grow"
                                    color="primary"
                                    outlined
                                    height="125px"
                                    dark
                                    elevation="24"
                                >
                                <v-row justify="center">
                                    <v-card-text>
                                        <v-row justify="center">
                                            <div style="width: min-content;">
                                                <v-list-item three-line>
                                                    <v-list-item-content>       
                                                        <rp-icon size="xlarge" icon="rp-shield-gray-filled"></rp-icon> 
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </div>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-subtitle class="mt-n6">
                                        <div class="text-center mb-3">
                                            <span class="text-h6 font-weight-light">{{$t('menu.roles_management')}}</span>
                                        </div>
                                    </v-card-subtitle>
                                </v-row>
                                <v-fade-transition>
                                <v-overlay
                                    v-if="hover"
                                    :opacity="0.9"
                                    absolute
                                    color="#036358"
                                >
                                <div class="text-center">
                                    <p>{{$t('menu.roles_management_text')}}</p>   
                                    <v-btn color="secondary" :to='"/" + $session.get("domain") + "/admin/accesses/roles"'>{{$t('globals.open')}}</v-btn>
                                </div>
                                </v-overlay>
                                </v-fade-transition>
                                </v-card>
                            </template>
                        </v-hover>
                    </v-col>

                    <v-col cols="12" md="4" v-if='$root.session.hasPermission(["super", "users.read"])'>
                        <v-hover>
                            <template v-slot:default="{ hover }">
                                <v-card
                                    class="grow"
                                    color="primary"
                                    outlined
                                    height="125px"
                                    dark
                                    elevation="24"
                                >
                                <v-row justify="center">
                                    <v-card-text>
                                        <v-row justify="center">
                                            <div style="width: min-content;">
                                                <v-list-item three-line>
                                                    <v-list-item-content>       
                                                        <rp-icon size="xlarge" icon="rp-colaboradores-gray-filled"></rp-icon> 
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </div>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-subtitle class="mt-n6">
                                        <div class="text-center mb-3">
                                            <span class="text-h6 font-weight-light">{{$t('general.users_management')}}</span>
                                        </div>
                                    </v-card-subtitle>
                                </v-row>
                                <v-fade-transition>
                                <v-overlay
                                    v-if="hover"
                                    :opacity="0.9"
                                    absolute
                                    color="#036358"
                                >
                                <div class="text-center">
                                    <p>{{$t('general.users_management_text')}}</p>   
                                    <v-btn color="secondary" :to='"/" + $session.get("domain") + "/admin/accesses/users"'>{{$t('globals.open')}}</v-btn>
                                </div>
                                </v-overlay>
                                </v-fade-transition>
                                </v-card>
                            </template>
                        </v-hover>
                    </v-col>

                    <v-col cols="12" md="4" v-if='$root.session.hasPermission(["super", "warehouses.read"]) && $root.configurations.hasFeature(["eticadata.config"])'>
                        <v-hover>
                            <template v-slot:default="{ hover }">
                                <v-card
                                    class="grow"
                                    color="primary"
                                    outlined
                                    height="125px"
                                    dark
                                    elevation="24"
                                >
                                <v-row justify="center">
                                    <v-card-text>
                                        <v-row justify="center">
                                            <div style="width: min-content;">
                                                <v-list-item three-line>
                                                    <v-list-item-content>       
                                                        <rp-icon size="xlarge" icon="rp-colaboradores-gray-filled"></rp-icon> 
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </div>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-subtitle class="mt-n6">
                                        <div class="text-center mb-3">
                                            <span class="text-h6 font-weight-light">{{$t('globals.warehouses')}}</span>
                                        </div>
                                    </v-card-subtitle>
                                </v-row>
                                <v-fade-transition>
                                <v-overlay
                                    v-if="hover"
                                    :opacity="0.9"
                                    absolute
                                    color="#036358"
                                >
                                <div class="text-center">
                                    <p>{{$t('warehouses.search_description')}}</p>   
                                    <v-btn color="secondary" :to='"/" + $session.get("domain") + "/admin/settings/warehouses"'>{{$t('globals.open')}}</v-btn>
                                </div>
                                </v-overlay>
                                </v-fade-transition>
                                </v-card>
                            </template>
                        </v-hover>
                    </v-col>


                    <v-col cols="12" md="4" v-if='$root.session.hasPermission(["super", "logs.read"])'>
                        <v-hover>
                            <template v-slot:default="{ hover }">
                                <v-card
                                    class="grow"
                                    color="primary"
                                    outlined
                                    height="125px"
                                    dark
                                    elevation="24"
                                >
                                <v-row justify="center">
                                    <v-card-text>
                                        <v-row justify="center">
                                            <div style="width: min-content;">
                                                <v-list-item three-line>
                                                    <v-list-item-content>       
                                                        <rp-icon size="xlarge" icon="rp-paperclip-gray-filled"></rp-icon> 
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </div>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-subtitle class="mt-n6">
                                        <div class="text-center mb-3">
                                            <span class="text-h6 font-weight-light">{{$t('menu.logs')}}</span>
                                        </div>
                                    </v-card-subtitle>
                                </v-row>
                                <v-fade-transition>
                                <v-overlay
                                    v-if="hover"
                                    :opacity="0.9"
                                    absolute
                                    color="#036358"
                                >
                                <div class="text-center">
                                    <p>{{$t('general.logs_text')}}</p>   
                                    <v-btn color="secondary" :to='"/" + $session.get("domain") + "/admin/logs"'>{{$t('globals.open')}}</v-btn>
                                </div>
                                </v-overlay>
                                </v-fade-transition>
                                </v-card>
                            </template>
                        </v-hover>
                    </v-col>

                    <v-col v-if="false" cols="2" class="d-none d-lg-block"></v-col>

                    <v-col cols="12" md="4" v-if='$root.session.hasPermission(["super", "configurations.read"])'>
                        <v-hover>
                            <template v-slot:default="{ hover }">
                                <v-card
                                    class="grow"
                                    color="primary"
                                    outlined
                                    height="125px"
                                    dark
                                    elevation="24"
                                >
                                <v-row justify="center">
                                    <v-card-text>
                                        <v-row justify="center">
                                            <div style="width: min-content;">
                                                <v-list-item three-line>
                                                    <v-list-item-content>       
                                                        <rp-icon size="xlarge" icon="rp-earth-gray-filled"></rp-icon> 
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </div>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-subtitle class="mt-n6">
                                        <div class="text-center mb-3">
                                            <span class="text-h6 font-weight-light">{{$t('general.general_config')}}</span>
                                        </div>
                                    </v-card-subtitle>
                                </v-row>
                                <v-fade-transition>
                                <v-overlay
                                    v-if="hover"
                                    :opacity="0.9"
                                    absolute
                                    color="#036358"
                                >
                                <div class="text-center">
                                    <p>{{$t('general.general_company_config')}}</p>   
                                    <v-btn color="secondary" :to='"/" + $session.get("domain") + "/admin/settings/global_configurations"'>{{$t('globals.open')}}</v-btn>
                                </div>
                                </v-overlay>
                                </v-fade-transition>
                                </v-card>
                            </template>
                        </v-hover>
                    </v-col>

                    <v-col cols="12" md="4" v-if='$root.session.hasPermission(["super", "imports.create"])'>
                        <v-hover>
                            <template v-slot:default="{ hover }">
                                <v-card
                                    class="grow"
                                    color="primary"
                                    outlined
                                    height="125px"
                                    dark
                                    elevation="24"
                                >
                                <v-row justify="center">
                                    <v-card-text>
                                        <v-row justify="center">
                                            <div style="width: min-content;">
                                                <v-list-item three-line>
                                                    <v-list-item-content>       
                                                        <rp-icon size="xlarge" icon="rp-earth-gray-filled"></rp-icon> 
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </div>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-subtitle class="mt-n6">
                                        <div class="text-center mb-3">
                                            <span class="text-h6 font-weight-light">{{$t('menu.imports')}}</span>
                                        </div>
                                    </v-card-subtitle>
                                </v-row>
                                <v-fade-transition>
                                <v-overlay
                                    v-if="hover"
                                    :opacity="0.9"
                                    absolute
                                    color="#036358"
                                >
                                <div class="text-center">
                                    <p>{{$t('general.import_data')}}</p>   
                                    <v-btn color="secondary" :to='"/" + $session.get("domain") + "/admin/settings/import"'>{{$t('globals.open')}}</v-btn>
                                </div>
                                </v-overlay>
                                </v-fade-transition>
                                </v-card>
                            </template>
                        </v-hover>
                    </v-col>

                    <v-col cols="12" md="4">
                        <v-hover>
                            <template v-slot:default="{ hover }">
                                <v-card
                                    class="grow"
                                    color="primary"
                                    outlined
                                    height="125px"
                                    dark
                                    elevation="24"
                                >
                                <v-row justify="center">
                                    <v-card-text>
                                        <v-row justify="center">
                                            <div style="width: min-content;">
                                                <v-list-item three-line>
                                                    <v-list-item-content>       
                                                        <v-icon
                                                            large
                                                            center
                                                        >
                                                            {{$vuetify.theme.dark ? "mdi-white-balance-sunny" :  "mdi-weather-night"}}
                                                        </v-icon>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </div>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-subtitle class="mt-n6">
                                        <div class="text-center">
                                            <span class="text-h6 font-weight-light">{{$vuetify.theme.dark ? $t('menu.light_mode') :  $t('menu.dark_mode') }}</span>
                                        </div>
                                    </v-card-subtitle>
                                </v-row>
                                <v-fade-transition>
                                <v-overlay
                                    v-if="hover"
                                    :opacity="0.9"
                                    absolute
                                    color="#036358"
                                >
                                <div class="text-center">
                                    <p>{{$t('general.modes')}}</p>   
                                    <v-btn color="secondary"  @click="toggleTheme">{{$t('globals.change')}}</v-btn>
                                </div>
                                </v-overlay>
                                </v-fade-transition>
                                </v-card>
                            </template>
                        </v-hover>
                    </v-col>

                    <v-col cols="12" md="4">
                        <v-hover>
                            <template v-slot:default="{ hover }">
                                <v-card
                                    class="grow"
                                    color="primary"
                                    outlined
                                    height="125px"
                                    dark
                                    elevation="24"
                                >
                                <v-row justify="center">
                                    <v-card-text>
                                        <v-row justify="center">
                                            <div style="width: min-content;">
                                                <v-list-item three-line>
                                                    <v-list-item-content>       
                                                        <rp-icon size="xlarge" icon="rp-permissions-gray-filled"></rp-icon> 
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </div>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-subtitle class="mt-n6">
                                        <div class="text-center mb-3">
                                            <span class="text-h6 font-weight-light">{{$t('menu.permissions_list')}}</span>
                                        </div>
                                    </v-card-subtitle>
                                </v-row>
                                <v-fade-transition>
                                <v-overlay
                                    v-if="hover"
                                    :opacity="0.9"
                                    absolute
                                    color="#036358"
                                >
                                <div class="text-center">
                                    <p>{{$t('general.permissions_config')}}</p>   
                                    <v-btn color="secondary" :to='"/" + $session.get("domain") + "/admin/permissions"'>{{$t('globals.open')}}</v-btn>
                                </div>
                                </v-overlay>
                                </v-fade-transition>
                                </v-card>
                            </template>
                        </v-hover>
                    </v-col>
                    
                    <v-col cols="12" md="4">
                        <v-hover>
                            <template v-slot:default="{ hover }">
                                <v-card
                                    class="grow"
                                    color="primary"
                                    outlined
                                    height="125px"
                                    dark
                                    elevation="24"
                                >
                                <v-row justify="center">
                                    <v-card-text>
                                        <v-row justify="center">
                                            <div style="width: min-content;">
                                                <v-list-item three-line>
                                                    <v-list-item-content>       
                                                        <rp-icon size="xlarge" icon="rp-permissions-gray-filled"></rp-icon> 
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </div>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-subtitle class="mt-n6">
                                        <div class="text-center mb-3">
                                            <span class="text-h6 font-weight-light">{{$t('menu.billings')}}</span>
                                        </div>
                                    </v-card-subtitle>
                                </v-row>
                                <v-fade-transition>
                                <v-overlay
                                    v-if="hover"
                                    :opacity="0.9"
                                    absolute
                                    color="#036358"
                                >
                                <div class="text-center">
                                    <p>{{$t('menu.billings')}}</p>   
                                    <v-btn color="secondary" :to='"/" + $session.get("domain") + "/admin/billings"'>{{$t('globals.open')}}</v-btn>
                                </div>
                                </v-overlay>
                                </v-fade-transition>
                                </v-card>
                            </template>
                        </v-hover>

                        <!--
                            
          has_permission: this.$root.session.hasPermission(["super", "billings.read"]),
          link: "/" + domain + "/admin/billings/",
                        -->

                    </v-col>

                    <v-col cols="12" md="4" v-if='$root.session.hasPermission(["super", "eticadata.load"]) && this.$root.configurations.hasFeature(["eticadata.config"])'>
                        <v-hover>
                            <template v-slot:default="{ hover }">
                                <v-card
                                    class="grow"
                                    color="primary"
                                    outlined
                                    height="125px"
                                    dark
                                    elevation="24"
                                >
                                <v-row justify="center">
                                    <v-card-text>
                                        <v-row justify="center">
                                            <div style="width: min-content;">
                                                <v-list-item three-line>
                                                    <v-list-item-content>       
                                                        <rp-icon size="xlarge" icon="rp-permissions-gray-filled"></rp-icon> 
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </div>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-subtitle class="mt-n6">
                                        <div class="text-center mb-3">
                                            <span class="text-h6 font-weight-light">{{$t('menu.sync')}}</span>
                                        </div>
                                    </v-card-subtitle>
                                </v-row>
                                <v-fade-transition>
                                <v-overlay
                                    v-if="hover"
                                    :opacity="0.9"
                                    absolute
                                    color="#036358"
                                >
                                <div class="text-center">
                                    <p>{{$t('general.sync')}}</p>   
                                    <v-btn color="secondary" :to='"/" + $session.get("domain") + "/admin/syncs"'>{{$t('globals.open')}}</v-btn>
                                </div>
                                </v-overlay>
                                </v-fade-transition>
                                </v-card>
                            </template>
                        </v-hover>
                    </v-col>
                    
                </v-row>
            </v-container>
        </div>
    </section>
</template>
<script>
export default{
    data(){
        return{
            loading: false,
            overlay:{
                roles: true
            }
        }
    },
    mounted(){
        console.log(this.$root.configurations.hasFeature(["eticadata.config"]))
    },
    methods:{        
        toggleTheme(){
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;

        localStorage['dark-mode'] = Number(this.$vuetify.theme.dark);
        },
    },
    computed:{
        crumbs: function() {
        return [
            {
            text: this.$t('general.title'),
            disabled: true,
            to: window.location.href,
            exact: true,
            },
        ];
        }
    }
}
</script>