<template>
  <section class="mt-16">
    <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-account-multiple</v-icon> <span>{{$t('menu.users_management')}}</span>
      </h1>

      <v-row>
          <v-col cols="12" md="auto">
            <rp-icon
              icon="rp-eye-blue-filled"
              class="mb-n2"
            />
            <v-icon class="ml-2" color="secondary" small>mdi-forward</v-icon>
            <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.visualize')}}</span>
          </v-col>
          <v-col cols="12" md="auto">            
            <v-icon color="red" class="ml-4">
              mdi-delete
            </v-icon><v-icon color="secondary" class="ml-2" small>mdi-forward</v-icon>
            <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.remove')}}</span>
          </v-col>
        </v-row>

      <div style="position:relative">
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t('globals.options')}}</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="info"
                  large
                  @click="searching = true"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>{{$t('globals.search')}}</span>
            </v-tooltip>
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  :to="'/' + $session.get('domain') + '/admin/accesses/users/create'"
                  v-if="$root.session.hasPermission(['super', 'users.create'])"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{$t('globals.register')}}</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
          locale="pt-pt"
        >

        <template v-slot:item.init="{item}">
          
          <Tooltip
              color="primary"
              :text="$t('my_profile.hasWorkerAccount')"
              top
          >
            <v-icon @click="openWorkerAccount(item)" color="primary" v-if="item.worker_id != null">mdi-account</v-icon>
          </Tooltip>
        </template>

          <template v-slot:item.is_active="{ item }">
            <v-container class="px-0" fluid>
              <v-row align="center"
                  justify="center">
                  <v-col>
                    <v-switch
                      style="margin-top:0px"
                      :disabled="!$root.session.hasPermission(['super', 'users.update']) || item.username == 'super'"
                      v-model="item.is_active"
                      color="success"
                      @change="toggleUser(item)"
                      hide-details
                    ></v-switch>
                  </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item.roles="{item}">
            <div v-for="r in item.roles" v-bind:key="r.id">
              <h6>{{ r.display_name }}</h6>
            </div>
          </template>

          <template v-slot:item.actions="{item}">
            <IconView
              class="mr-2"
              @click="viewUser(item)"
            />
            <IconRemove
              v-if="item.can_delete == true && $root.session.hasPermission(['super', 'users.delete']) && item.username != 'super'"
              class="mr-2"
              @on-submit="deleteUser(item)"
            />
          </template>
        </v-data-table>
      </div>
      <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetUsers">
              {{$t('globals.clean_fields')}}
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchUsers">
            
            <v-list-item>
              <v-text-field
                v-model="filter.name"
                prepend-inner-icon="mdi-account-details"
                :label="$t('globals.name')"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-text-field
                v-model="filter.email"
                prepend-inner-icon="mdi-at"
                :label="$t('globals.email')"
                type="email"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-text-field
                v-model="filter.username"
                prepend-inner-icon="mdi-account-details"
                :label="$t('globals.username')"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-text-field
                v-model="filter.phone_number"
                prepend-inner-icon="mdi-phone"
                :label="$t('globals.telephone')"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-switch
                style="margin-top:0px"
                label="Ativo"
                v-model="filter.is_active"
                color="success"
                :true-value="1"
                :false-value="0"
                hide-details
              ></v-switch>
            </v-list-item>

            <v-list-item>
               <multiple-select
                  v-model="filter.role_ids"
                  :items="roles"
                  item-text="display_name"
                  item-value="id"
                  menu-props="auto"
                  :label="$t('globals.roles')"
                  prepend-inner-icon="mdi-shield-account"
              ></multiple-select>
            </v-list-item>

            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              {{$t('globals.search')}}
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
      </v-navigation-drawer>
  </section>
</template>
<script>
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import ClientUsers from "@/api/ClientUsers.js";
//import User from "@/api/User.js";
import Role from "@/api/Role.js";
import MultipleSelect from '@/components/ui/MultipleSelect.vue';
import Tooltip from '@/components/ui/Tooltip.vue';

export default {
  components: {
    MultipleSelect,
    IconRemove,
    IconView,
    Tooltip
  },
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchUsers()}, 10);
      },
      deep: true,
    },
  },
  beforeCreate(){
    document.title = "Report Lake - " + this.$t('menu.users_management');

    Role.list().then(({data}) => {
      this.roles = data;
    });
  },
  data: () => ({
    items: [],
    total: 0,
    loading: false,
    searching: false,
    options: {},
    speedDial:false,
    filter: {
      name: null,
      email:null,
      username:null,
      phone_number:null,
      is_active:1,
      role_ids:[],
    },
    roles:[]
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "users.read"])) {
      this.$router.push('/admin');
    }

    this.fillBaseData();

  },
  methods:{
    openWorkerAccount(item){
      if(item == null) return;

      if(item.worker_id == null) return;

      this.$router.push('/' + this.$session.get('domain') + '/admin/workers-settings/workers/' + item.worker_id)
    },
    fillBaseData() {

      if(localStorage["users-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["users-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }
    },
    searchUsers(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        page: this.options.page,
        limit: this.options.itemsPerPage,
      };
    
      Object.assign(request, filter);

      localStorage["users-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      ClientUsers.search(request).then(response => {

        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });


        this.loading = false;

      });
    },
    resetUsers()
    {
      this.filter = {
        is_active: 1
      };

      this.searchUsers();
    },
    viewUser(item){
      let domain = '/' + this.$session.get('domain')
      this.$router.push(domain + '/admin/accesses/users/' + item.id);
    },
    deleteUser(item){
      ClientUsers.delete(item, this.$session.get('domain'))
        .then(() => {
          this.searchUsers();
        });
    },
    toggleUser(item) {
      ClientUsers.updateActivation(item)
        .then(() => {
          this.searchUsers();
        });
    }
  },
  computed: {
    headers(){
      let he = [
        {
          text: '',
          sortable: false,
          align: "center",
          value:"init"
        },
        {
          text: this.$t('globals.name'),
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('globals.email'),
          sortable: false,
          value: 'email',
        },
        {
          text: this.$t('globals.username'),
          sortable: false,
          value: 'username',
        },
        {
          text: this.$t('globals.telephone'),
          sortable: false,
          value: 'phone_number',
        },
        {
          text: this.$t('globals.roles'),
          sortable: false,
          value: 'roles',
        },
        {
          text: this.$t('globals.active'),
          sortable: false,
          value: 'is_active',
        },
        { 
          text: this.$t('globals.options'),
          value: 'actions', 
          sortable: false 
        },
      ]
      return he
    },
    tableFooter(){
      let t = this.$t("globals.lines_datatable")
      let a = [5, 10, 15, 20];
      return {
        'items-per-page-options': a,
        'items-per-page-text': t
      }
    }, 
    crumbs: function() {
      return [
        {
          text: this.$t('globals.definitions'),
          disabled: false,
          to: '/' + this.$session.get('domain') + '/admin/settings/general',
          exact: true,
        },
        {
          text: this.$t('menu.users_management'),
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
