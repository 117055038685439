import BaseApi from "./BaseApi";

export default class Notification extends BaseApi{
    construct() {}

    static async list(unread, offset = 0, tenant = null) {
        let params = {
            unread: unread,
            offset: offset
        }
        return await Notification.getAxiosInstance().get(Notification.getFullApiUrl(tenant),{params: params});

    }

    static async read() {
        const { data: response } = await Notification.getAxiosInstance().post(Notification.getFullApiUrl());

        return response;
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/notifications/";
    }

    static getFullApiUrl(tenant = null){
        let domain = location.pathname.split('/')[1]
        if(tenant != null)
            domain = tenant

        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/notifications/";
    }

}