<template>
  <v-container fluid class="mb-8">    
        <v-row v-if="getTasks.length > 0">
            <v-col cols="12">
                <span class="font-weight-bold text-body-1 ml-4">Opções: </span>
                <v-btn v-if="dialog" @click="shrink = !shrink" small rounded class="primary">
                    {{ shrink ? 'Detalhar tabela' : 'Esconder detalhes' }}
                </v-btn>
                <v-btn 
                    left top
                    class="white--text primary ml-2 mr-2"
                    icon @click="$emit('fullscreen')"><v-icon>mdi-fullscreen</v-icon>
                </v-btn>
                <v-btn 
                    v-if="zoomAvailable"
                    left top
                    class="white--text primary ml-2 mr-2"
                    icon @click="zoomIn"><v-icon>mdi-magnify-plus-outline</v-icon>
                </v-btn>
                <v-btn 
                    v-if="zoomAvailable"
                    left top
                    class="white--text primary ml-2 mr-2"
                    icon @click="zoomOut"><v-icon>mdi-magnify-minus-outline</v-icon>
                </v-btn>
                <span v-if="dialog"> {{ zoom }}%</span>
            </v-col>
            <v-col cols="12">
                <small>* O total de dias é calculado com base nos dias de trabalho definidos para a sua obra, no separador "Detalhe"</small>
            </v-col>
            <v-col cols="12" class="py-0 mt-n4 mb-2" v-if="dialog">
                <small>** Pode aumentar ou diminuir o tamanho da tabela das Unidades de Obra usando o botão "Detalhar tabela"</small>
            </v-col>
            <v-col cols="12" md="auto" v-if="edit">
                <v-dialog
                    ref="dialogPicker1"
                    v-model="modalPicker1"
                    :return-value.sync="datePicker1"
                    persistent
                    width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="datePicker1"
                        label="De"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="datePicker1"
                    scrollable
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary'"
                        @click="modalPicker1 = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        text
                        :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary'"
                        @click="$refs.dialogPicker1.save(datePicker1)"
                    >
                        OK
                    </v-btn>
                    </v-date-picker>
                </v-dialog>
            </v-col>
            <v-col cols="12" md="auto" v-if="edit">
                <v-dialog
                    ref="dialogPicker2"
                    v-model="modalPicker2"
                    :return-value.sync="datePicker2"
                    persistent
                    width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="datePicker2"
                        label="A"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="datePicker2"
                    scrollable
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary'"
                        @click="modalPicker2 = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        text
                        :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary'"
                        @click="$refs.dialogPicker2.save(datePicker2)"
                    >
                        OK
                    </v-btn>
                    </v-date-picker>
                </v-dialog>                
            </v-col>
        </v-row>
        <v-row :style="getstyle"  v-if="getTasks.length > 0">
          <v-col :cols="!dialog ? (shrink ? (edit == false ? '4' : '2') : '5') :  (shrink ? (edit == false ? '4' : '2') : '4')">
              <v-simple-table>
                  <template v-slot:default>
                    <thead :class="$root.$vuetify.theme.isDark ? '' : ''">
                          <tr>
                              <th :class="dialog ? 'text-h6 font-weight-light' : 'text-caption font-weight-light'" v-if="edit">
                                  Op.
                              </th>                            
                              <th :class="dialog ? 'text-h6 font-weight-light' : 'text-caption font-weight-light'">
                                  Tarefa
                              </th>
                              <th v-show="!shrink" :class="dialog ? 'text-h6 font-weight-light' : 'text-caption font-weight-light'">
                                  Inicio
                              </th>
                              <th v-show="!shrink" :class="dialog ? 'text-h6 font-weight-light' : 'text-caption font-weight-light'">
                                  Fim
                              </th>
                              <th v-show="!shrink" :class="dialog ? 'text-h6 font-weight-light' : 'text-caption font-weight-light'">
                                  %
                              </th>
                          </tr>
                      </thead>
                      <tbody :class="$root.$vuetify.theme.isDark ? (edit == false ? '' : 'secondary') : ''">
                          <tr
                              v-for="(task, index) in getTasks"
                              :key="index"
                          >
                                <td v-if="edit"><v-icon @click="editTask(task)">mdi-pencil</v-icon></td>
                                <td :class="dialog ? 'text-h6 font-weight-light' : 'text-caption font-weight-light'" :style="getTableHeight()">{{ task.task.length > 50 ? task.task.substring(0, 50) + '(...)' : task.task }}</td>
                                <td v-show="!shrink" :class="dialog ? 'text-no-wrap text-h6 font-weight-light' : 'text-no-wrap text-caption font-weight-light'">
                                    {{ task.init }}
                                </td>
                                <td v-show="!shrink" :class="dialog ? 'text-no-wrap text-h6 font-weight-light' : 'text-no-wrap text-caption font-weight-light'">{{ task.end }}</td>
                                <td v-show="!shrink" :class="dialog ? 'text-no-wrap text-h6 font-weight-light' : 'text-no-wrap text-caption font-weight-light'">{{ task.per }}</td>
                          </tr>
                      </tbody>
                  </template>
              </v-simple-table>
          </v-col>
          <v-col :cols="!dialog ? (shrink ? (edit == false ? '8' : '10') : '7') : (shrink ? (edit == false ? '8' : '10') : '8')">
              <v-simple-table>
                  <template v-slot:default>
                      <thead :class="$root.$vuetify.theme.isDark ? '' : ''">
                          <tr>                                
                              <th style="min-width: 80px;" v-for="date in dates" :key="date.full" :class="dialog ? 'text-h6 font-weight-light text-no-wrap' : 'text-no-wrap text-caption font-weight-light'">
                                  {{ date.day }} {{ getMonth(date.month) }}
                              </th>
                          </tr>
                      </thead>
                      <tbody :class="$root.$vuetify.theme.isDark ? (edit == false ? '' : 'secondary') : ''">
                          <tr v-for="(task, index) in getTasks" :key="index">
                              <td :colspan="getTask(date, task).colspan" 
                                    v-for="date in filteredDates(task)" :key="date.full"
                                    :style="getTableHeight()"
                                >
                                <CardComponent :per="getTask(date, task).value" :color="getTask(date, task).color" :t="getTask(date, task).id+''" v-if="getTask(date, task).task != ''"/>
                              </td>
                          </tr>
                      </tbody>
                  </template>
              </v-simple-table>
          </v-col>    
      </v-row>
        <v-row  v-if="getTasks.length <= 0" class="justify-center">
            <v-alert
                dense
                type="info"
            >   
                {{$t('diaries.programmed')}}
            </v-alert>
        </v-row>
            
      <v-row align="center" justify="space-around" v-if="edit && getTasks > 0">
            <v-btn depressed color="primary" @click="$emit('submit')">
                {{$t('globals.save')}}
            </v-btn>
        </v-row>
  </v-container>
</template>
<script>
import CardComponent from './CardComponent.vue'

export default{
  name: 'Gantt',
  props:{
    propZoom: {
        typeof: String,
        defaut: 100
    },
    zoomAvailable: {
        typeof: Boolean,
        default: true
    },
    tasks: Array,
    dialog:{
        typeof: Boolean,
        default: false
    },
    defaultInitDate: {
        typeof: Date,
        default: null,
    },
    defaultEndDate: {
        typeof: Date,
        default: null
    },
    edit:{
        typeof: Boolean,
        default: true
    }
  },
  watch:{
    datePicker1:{
        immediate: true,
        handler(val){
            if(val == null) return
            this.initDate = val
            this.calculateFirstDay()
            this.calculateLastDay()
        }
    },
    datePicker2:{
        immediate: true,
        handler(val){
            if(val == null) return
            this.calculateFirstDay()
            this.calculateLastDay()
        }
    }
  },
  components:{
    CardComponent
  },
  mounted(){
      if(this.propZoom != null)
        this.zoom = parseInt(this.propZoom)

        if(this.dialog == false)
            this.shrink = false



        // Calcular o dia de inicio
        this.calculateFirstDay()

        // Calcular o dia de fim
        this.calculateLastDay()
        
        if(this.edit == false){
            this.shrink = true
        }


        this.datePicker1 = new Date(this.defaultInitDate == null ? this.initDate : this.defaultInitDate).toISOString().substr(0, 10)
        this.datePicker2 = new Date(this.defaultEndDate == null ? this.endDate: this.defaultEndDate).toISOString().substr(0, 10)

  },
  data(){
      return{
        initDate: null,
        endDate: null, 
        modalPicker2: false,
        datePicker2: null, 
        modalPicker1: false,
        datePicker1: null, 
        tableHeight: 80,
        shrink: true,
        zoom: 100,
      }
  },
  methods:{
    calculateFirstDay(){
        if(this.defaultInitDate != null){
            this.initDate = this.defaultInitDate
            return
        }

            
            
        let smallerDate = null
        this.getTasks.forEach(element => {           
            if(smallerDate == null){
                smallerDate = element.init
            }else{
                if(new Date(smallerDate) >= new Date(element.init))
                smallerDate = element.init
            }            
        });  
        this.initDate = smallerDate
    },
    calculateLastDay(){
        if(this.defaultEndDate != null){
            this.endDate = this.defaultEndDate;
            return
        }


        let biggestDate = null
        this.getTasks.forEach(element => {           
            if(biggestDate == null){
                biggestDate = element.end
            }else{
                if(new Date(biggestDate) < new Date(element.end))
                    biggestDate = element.end
            }            
        });  

        this.endDate = biggestDate
    },
    zoomIn(){
        this.zoom >= 100 ? this.zoom = 100 : this.zoom = this.zoom + 5;
        if(this.zoom < 100)
            this.tableHeight = this.tableHeight + 5
    },
    zoomOut(){
        this.zoom <= 0 ? this.zoom = 0 : this.zoom = this.zoom - 5;

        if(this.zoom > 0)
            if(this.tableHeight <= 80)
                this.tableHeight = 80
            else this.tableHeight = this.tableHeight - 5
    },
    getTableHeight(){
        return 'height: ' + this.tableHeight + 'px !important;';
    },
    editTask(t){
        this.$emit('viewListWorkUnit', t)
    },
    getTask(item, task){
        let variable = ''
        let color = ''
        
        let startDT = new Date(task.init)
        startDT.setHours(0,0,0,0);
        let endDT = new Date(task.end)
        endDT.setHours(0,0,0,0);
        

        let comparatorDT = new Date(item.full)
        comparatorDT.setHours(0,0,0,0);


     
        let colspan = 1
        let t = ''
        if(comparatorDT.toString() === startDT.toString()){
            colspan = task.days
            variable = task.per
            color = 'accent'
            if(task.per >= 50 && task.per < 100)
                color = 'warning'
            if(task.per >= 100)
                color = 'success'
            t = task.task

            task.ignore = true
        }

        return {
            value: variable,
            color: color,
            colspan: colspan,
            task: t,
            id: task.id
        }
    },
    getMonth(v){
        if(v == 1)
            return 'Jan'
            
        if(v == 2)
            return 'Fev'
            
        if(v == 3)
            return 'Mar'
            
        if(v == 4)
            return 'Abr'
            
        if(v == 5)
            return 'Mai'
            
        if(v == 6)
            return 'Jun'
            
        if(v == 7)
            return 'Jul'
            
        if(v == 8)
            return 'Ago'
            
        if(v == 9)
            return 'Set'
            
        if(v == 10)
            return 'Out'
            
        if(v == 11)
            return 'Nov'
            
        if(v == 12)
            return 'Dez'
    },
    filteredDates(t){        
        let starting = new Date(this.initDate)
        let ending = new Date(this.endDate)

        let arrayDates = []

        for(let i = starting; i <= ending; i.setDate(i.getDate() + 1)){
            // Só nos interessa o dia 1 da tarefa, os restantes é para ignorar para o colspan

            let start = new Date(t.init)
            let end = new Date(t.init)
            let total_days = t.days - 1
            
            end.setDate(end.getDate()+total_days);

            if(i > start && i <= end){
                t.ignore = true
            }else{
                arrayDates.push({
                    day:        i.getDate(),
                    month:      i.getMonth()+1,
                    year:       i.getFullYear(),
                    full:       i.getFullYear()+'-'+(i.getMonth()+1)+'-'+i.getDate()
                })
            }
        }

        return arrayDates
    },
  },
  computed:{
        dates(){
            let starting = new Date(this.initDate)
            let ending = new Date(this.endDate)

            let arrayDates = []

            for(let i = starting; i <= ending; i.setDate(i.getDate() + 1)){
                arrayDates.push({
                    day:        i.getDate(),
                    month:      i.getMonth()+1,
                    year:       i.getFullYear(),
                    full:       i.getFullYear()+'-'+(i.getMonth()+1)+'-'+i.getDate()
                })
            }

            return arrayDates
        },
        getTasks(){
            let taskArray = []
            this.tasks.forEach(element => {
                if(element.init != null){
                    if(this.edit == false){
                        if(new Date(element.init) >= new Date(this.defaultInitDate == null ? this.initDate : this.defaultInitDate) && new Date(element.init) <= new Date(this.defaultEndDate == null ? this.endDate : this.defaultEndDate))
                            taskArray.push(element)
                    }else{
                        taskArray.push(element)
                    }
                }
            });
            
            return taskArray
        },
        getstyle(){
            return 'zoom: ' + this.zoom + '%;'
        }
  }
}
</script>