var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mt-16"},[(this.$root.$vuetify.theme.isDark)?_c('v-breadcrumbs',{class:this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0',attrs:{"large":"","items":_vm.crumbs,"divider":"/","light":""}}):_c('v-breadcrumbs',{class:this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0',attrs:{"items":_vm.crumbs,"divider":"/","light":""}}),_c('h1',{staticClass:"primary--text"},[_c('rp-icon',{staticClass:"mr-2",attrs:{"icon":"rp-graphic-blue-filled"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('menu.units_measure_management')))])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('rp-icon',{staticClass:"mb-n2",attrs:{"icon":"rp-eye-blue-filled"}}),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"secondary","small":""}},[_vm._v("mdi-forward")]),_c('span',{class:this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'},[_vm._v(_vm._s(_vm.$t('globals.visualize')))])],1),_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-icon',{staticClass:"ml-4",attrs:{"color":"red"}},[_vm._v(" mdi-delete ")]),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"secondary","small":""}},[_vm._v("mdi-forward")]),_c('span',{class:this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'},[_vm._v(_vm._s(_vm.$t('globals.remove')))])],1)],1),_c('div',{staticStyle:{"position":"relative"}},[_c('v-container',[_c('v-speed-dial',{attrs:{"absolute":"","top":"","right":"","direction":"bottom","open-on-hover":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","x-large":"","fab":""},model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},'v-btn',attrs,false),on),[(_vm.speedDial)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('globals.options')))])])]},proxy:true}]),model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},[_c('v-tooltip',{attrs:{"right":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"success","large":""},on:{"click":_vm.exportUnitsList}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-paperclip")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('globals.export')))])]),_c('v-tooltip',{attrs:{"right":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$root.session.hasPermission(['super', 'units_management.create']))?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"secondary","large":""},on:{"click":function($event){return _vm.openNewModalUnit()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('globals.register')))])])],1)],1),_c('v-data-table',{class:this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16',attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.tableFooter,"options":_vm.options,"locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTableDate(item.created_at))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTableDate(item.updated_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('IconView',{staticClass:"mr-2",on:{"click":function($event){return _vm.viewUnit(item)}}}),(_vm.$root.session.hasPermission(['super', 'units_management.delete']))?_c('IconRemove',{attrs:{"reverse":_vm.filter.deleted_unit},on:{"on-submit":function($event){return _vm.deleteUnit(item)}}}):_vm._e()],1)],1)]}}])})],1),_c('v-navigation-drawer',{staticClass:"pt-6",attrs:{"fixed":"","right":"","hide-overlay":"","width":"520"},model:{value:(_vm.searching),callback:function ($$v) {_vm.searching=$$v},expression:"searching"}},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"fab":"","small":"","color":"secondary"},on:{"click":function($event){_vm.searching = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-btn',{attrs:{"absolute":"","right":"","small":"","color":"error"},on:{"click":_vm.resetUnits}},[_vm._v(" "+_vm._s(_vm.$t('globals.clean_fields'))+" ")])],1)],1)],1),_c('DialogSuccess',{attrs:{"opened":_vm.approved},on:{"on-ok":function($event){_vm.approved = false}}},[_vm._v(" "+_vm._s(_vm.$t('unit_measure.success_add'))+" ")]),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.addUnit),callback:function ($$v) {_vm.addUnit=$$v},expression:"addUnit"}},[(_vm.addUnit)?_c('AddUnit',{attrs:{"editUnit":_vm.unit_selected},on:{"close":function($event){_vm.addUnit = false},"close-update":function($event){return _vm.fillBaseData()}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }