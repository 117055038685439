<template>
    <section class="mt-16">
      <v-breadcrumbs
          v-if="this.$root.$vuetify.theme.isDark"
          large
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
        <v-breadcrumbs
          v-else
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <rp-icon icon="rp-obras-blue-filled" class="mr-2"/> <span>{{$t('globals.warehouse')}}</span>
        </h1>
  
  
        <div style="position:relative">
          <v-container>
            <v-speed-dial
              absolute
              v-model="speedDial"
              top
              right
              direction="bottom"
              :open-on-hover="true"
            >
              <template v-slot:activator>
                <v-tooltip right color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" v-on="on"
                      v-model="speedDial"
                      color="primary"
                      dark
                      x-large
                      fab
                    >
                      <v-icon v-if="speedDial">
                        mdi-close
                      </v-icon>
                      <v-icon v-else>
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('globals.options')}}</span>
                </v-tooltip>
              </template>
              <v-tooltip right color="info">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="info"
                    large
                    @click="searching = true"
                  >
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('globals.search')}}</span>
              </v-tooltip>
            </v-speed-dial>
          </v-container>
  
          <v-data-table 
            :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'"
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
            locale="pt-pt"
          >
          
          <template v-slot:item.actions="{item}">
            <div v-if='$root.session.hasPermission(["super", "warehouses.update"])'>
              <Tooltip
                  color="primary"
                  text="Definir como armazém principal"
                  bottom
              >
                <v-btn outlined color="primary" small v-if="item.is_principal == false" fab @click="global_id = item.id; openConfirmBudgetStatus = true;">
                  P
                </v-btn>
              </Tooltip>
            </div>
          </template>
          
  
           
            
          </v-data-table>
        </div>
        <v-navigation-drawer
            fixed
            right
            v-model="searching"
            hide-overlay
            width="520"
            class="pt-6"
          >
          <v-list>
            <v-list-item>
              <v-btn fab small color="secondary" @click="searching = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
  
              <v-btn absolute right small color="error" @click="resetWarehouses">
                {{$t('globals.clean_fields')}}
              </v-btn>
            </v-list-item>
          </v-list>
          <v-list class="pt-10">
            <v-form @submit.prevent="searchWarehouses">
              
              <v-list-item>
                <v-text-field
                  v-model="filter.name"
                  prepend-inner-icon="mdi-account-details"
                  :label="$t('globals.name')"
                  clearable
                ></v-text-field>
              </v-list-item>

              <v-list-item>
                <v-text-field
                  v-model="filter.address"
                  prepend-inner-icon="mdi-account-details"
                  :label="$t('globals.address.address')"
                  clearable
                ></v-text-field>
              </v-list-item>

              <v-list-item>
                <v-text-field
                  v-model="filter.local"
                  prepend-inner-icon="mdi-account-details"
                  :label="$t('globals.localization')"
                  clearable
                ></v-text-field>
              </v-list-item>

              <v-list-item>
                <v-text-field
                  v-model="filter.postal_code"
                  prepend-inner-icon="mdi-account-details"
                  :label="$t('globals.address.posta_code')"
                  clearable
                ></v-text-field>
              </v-list-item>

              <v-list-item>
                <v-text-field
                  v-model="filter.email"
                  prepend-inner-icon="mdi-account-details"
                  :label="$t('globals.email')"
                  clearable
                ></v-text-field>
              </v-list-item>

              <v-list-item>
                <v-text-field
                  v-model="filter.phone"
                  prepend-inner-icon="mdi-account-details"
                  :label="$t('globals.contact')"
                  clearable
                ></v-text-field>
              </v-list-item>
  
              
              <v-row
                class="mt-10"
                align="center"
                justify="space-around"
              >
              <v-btn
                color="primary"
                type="submit"
              >
                {{$t('globals.search')}}
              </v-btn>
              </v-row>
            </v-form>
          </v-list>
        </v-navigation-drawer>  
        
        <ErrorSnackbar v-model="error.show" :message="error.message"/>

        <DialogConfirmation 
            :title="'Tem a certeza?'" 
            type="warning" 
            v-if="openConfirmBudgetStatus"
            :opened="openConfirmBudgetStatus" 
            @on-submit="definePrincipal"
            @on-cancel="openConfirmBudgetStatus = false;">
              Tem a certeza que quer definir este armazém como principal?
          </DialogConfirmation>
    </section>
  </template>
  <script>
  import Warehouse from "@/api/Warehouse.js";
  import dayjs from 'dayjs';
  import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
  import Tooltip from '@/components/ui/Tooltip.vue';
  import DialogConfirmation from '@/components/ui/DialogConfirmation.vue';
  
  export default {
    components: {
      DialogConfirmation,
      ErrorSnackbar,
      Tooltip
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchWarehouses()}, 10);
        },
        deep: true,
      },
    },
    beforeCreate(){
      document.title = "Report Lake - " + this.$t('globals.wareouses');
    },
    data: () => ({
      openConfirmBudgetStatus: false,
      modalPicker1: false,
      modalPicker2: false,
      global_id: null,
      datePicker1: null,
      datePicker2: null,
      error: {
        title: '',
        message: '',
        show:false,
      },
      items: [],
      total: 0,
      loading: false,
      searching: false,
      options: {},
      speedDial:false,
      filter: {
        name: null,
        address: null,
        local: null,
        postal_code: null,
        phone: null,
        email: null
      },
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "warehouses.read"])) {
        this.$router.push('/admin');
      }
  
  
      this.fillBaseData();
    },
    methods:{
      definePrincipal(){
        let data = {
          warehouse_id: this.global_id
        }
        Warehouse.setPrincipal(data).then(() => {
          this.searchWarehouses()
          this.openConfirmBudgetStatus = false
          
        });
      },
      getTableDate(date) {
        return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
      },
      fillBaseData() {
  
        if(localStorage["warehouses-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["warehouses-" + this.$root.session.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
      },
      searchWarehouses(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
  
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };
      
        Object.assign(request, filter);
  
        localStorage["warehouses-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        Warehouse.search(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });
  
          this.loading = false;
  
        });
      },
      resetWarehouses()
      {
        this.filter = {
          is_active: 1
        };
  
        this.searchWarehouses();
      },
    },
    computed: {
        headers(){
          let he = [
            {
            text: this.$t('globals.name'),
            sortable: true,
            value: 'name'
            },
            {
              text: this.$t('globals.address.address'),
              sortable: true,
              value: 'address'
            },
            {
              text: this.$t('globals.localization'),
              sortable: true,
              value: 'local'
            },
            {
              text: this.$t('globals.address.postal_code'),
              sortable: true,
              value: 'postal_code'
            },
            {
              text: this.$t('globals.email'),
              sortable: true,
              value: 'email'
            },
            {
              text: this.$t('globals.contact'),
              sortable: true,
              value: 'phone'
            },
            {
              text: this.$t('globals.options'),
              sortable: true,
              value: 'actions'
            },
          ]
          return he
        },
        tableFooter(){
          let t = this.$t("globals.lines_datatable")
          let a = [5, 10, 15, 20];
          return {
            'items-per-page-options': a,
            'items-per-page-text': t
          }
        }, 
        crumbs: function() {
            return [
            {
                text: this.$t('menu.dashboard'),
                disabled: false,
                to: '/admin',
                exact: true,
            },
            {
                text: this.$t('globals.warehouse'),
                disabled: true,
                to: window.location.href,
                exact: true,
            },
            ];
        }
    }
  };
  </script>
  