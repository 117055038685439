var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:_vm.$root.$vuetify.theme.isDark ? 'secondary' : '',style:(this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75);'),attrs:{"elevation":"10"}},[_c('v-tabs',{class:!_vm.client ? 'elevation-10' : 'elevation-10',attrs:{"background-color":_vm.$root.$vuetify.theme.isDark ? 'secondary' : 'primary',"dark":"","fixed-tabs":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(_vm._s(_vm.$t('client.tabs.general')))]),_c('v-tab',{attrs:{"disabled":_vm.method == 'create'}},[_vm._v(_vm._s(_vm.$t('client.tabs.works')))])],1),_c('v-tabs-items',{class:(_vm.$root.$vuetify.theme.isDark ? 'secondary' : '') + ' elevation-10',attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-container',{staticClass:"mb-n12"},[_c('v-tab-item',{key:1,staticClass:"mt-6"},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"mb-16",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.loading)?_c('v-progress-linear',{staticClass:"global-loader",attrs:{"indeterminate":""}}):_vm._e(),_c('fieldset',{attrs:{"disabled":_vm.loading}},[_c('v-card',{staticClass:"transparent elevation-0"},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"reference","name":_vm.$t('globals.reference'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-barcode","label":_vm.$t('globals.reference') + ' *',"clearable":"","error-messages":errors},model:{value:(_vm.fields.reference),callback:function ($$v) {_vm.$set(_vm.fields, "reference", $$v)},expression:"fields.reference"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('validation-provider',{attrs:{"vid":"name","name":_vm.$t('globals.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":_vm.$t('globals.name') + ' *',"clearable":"","error-messages":errors},model:{value:(_vm.fields.name),callback:function ($$v) {_vm.$set(_vm.fields, "name", $$v)},expression:"fields.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"description","name":_vm.$t('globals.nif'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-ticket-confirmation","label":_vm.$t('globals.nif') + ' *',"clearable":"","error-messages":errors},model:{value:(_vm.fields.nif),callback:function ($$v) {_vm.$set(_vm.fields, "nif", $$v)},expression:"fields.nif"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('validation-provider',{attrs:{"vid":"unit","name":_vm.$t('globals.email'),"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-email-box","label":_vm.$t('globals.email'),"clearable":"","error-messages":errors},model:{value:(_vm.fields.email),callback:function ($$v) {_vm.$set(_vm.fields, "email", $$v)},expression:"fields.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-phone","label":_vm.$t('globals.contact'),"clearable":""},model:{value:(_vm.fields.telephone),callback:function ($$v) {_vm.$set(_vm.fields, "telephone", $$v)},expression:"fields.telephone"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('small',[_vm._v(_vm._s(_vm.$t('client.defect_window')))])])],1)],1),_c('v-row',{staticClass:"mt-6",attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{class:_vm.$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary',attrs:{"disabled":invalid,"depressed":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('globals.save'))+" ")])],1)],1)],1),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":_vm.onSuccess}},[_vm._v(" "+_vm._s(_vm.$t('client.success_add'))+" ")])]}}])})],1),_c('v-tab-item',{key:2,staticClass:"mt-6"},[(_vm.method == 'update')?_c('ClientWorks'):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }