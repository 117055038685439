<template>
    <v-container fluid class="ma-0 pa-0">
        <v-row>
            <v-col cols="12">
            <v-card elevation="10" :class="this.$root.$vuetify.theme.isDark ? 'secondary' : ''">
                <v-card-title>{{$t('globals.workers')}}</v-card-title>
                <v-card-subtitle>{{$t('costs.workers_total_cost')}}</v-card-subtitle>
            </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card
                class="grow"
                color="secondary"
                outlined
                height="125px"
                dark
                elevation="0"
            >
                <v-card-subtitle>
                    <div class="text-center mt-n4 mb-3">
                        <span class="text-h6 font-weight-light">{{$t('costs.total_cost')}}</span>
                    </div>
                </v-card-subtitle>
                <!-- DESKTOP -->
                <v-card-text class="d-none d-lg-block ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="auto">                            
                        <v-icon
                            large
                            center
                        >
                        {{$currency_icon()}}
                        </v-icon>
                      </v-col>
                      <v-col cols="12" md="auto" class="text-h6 font-weight-bold white--text ml-4">
                        {{ parseValue(costs.workers.total_cost) }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
                  <!-- MOBILE -->
                  <v-card-text class="d-md-none ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="12" class="font-weight-bold white--text text-center">
                        {{ parseValue(costs.workers.total_cost) }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
            </v-card>
            </v-col>

            <v-col cols="12" md="3">
                <v-card
                class="grow"
                color="secondary"
                outlined
                height="125px"
                dark
                elevation="0"
            >
                <v-card-subtitle>
                    <div class="text-center mt-n4 mb-3">
                        <span class="text-h6 font-weight-light">{{$t('costs.per_day')}}</span>
                    </div>
                </v-card-subtitle>
                <!-- DESKTOP -->
                <v-card-text class="d-none d-lg-block ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="auto">                            
                        <v-icon
                            large
                            center
                        >
                        {{$currency_icon()}}
                        </v-icon>
                      </v-col>
                      <v-col cols="12" md="auto" class="text-h6 font-weight-bold white--text ml-4">
                        {{ parseValue(costs.workers.total_day_cost) }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
                  <!-- MOBILE -->
                  <v-card-text class="d-md-none ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="12" class="font-weight-bold white--text text-center">
                        {{ parseValue(costs.workers.total_day_cost) }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
            </v-card>
            </v-col>

            <v-col cols="12" md="3">
                <v-card
                class="grow"
                color="secondary"
                outlined
                height="125px"
                dark
                elevation="0"
            >
                <v-card-subtitle>
                    <div class="text-center mt-n4 mb-3">
                        <span class="text-h6 font-weight-light">{{$t('costs.sum_hours')}}</span>
                    </div>
                </v-card-subtitle>
                <!-- DESKTOP -->
                <v-card-text class="d-none d-lg-block ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="auto" class="text-h6 font-weight-bold white--text ml-4">
                        {{ costs.workers.total_hours }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
                  <!-- MOBILE -->
                  <v-card-text class="d-md-none ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="12" class="font-weight-bold white--text text-center">
                        {{ costs.workers.total_hours }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
            </v-card>
            </v-col>

            <v-col cols="12" md="3">
                <v-card
                class="grow"
                color="secondary"
                outlined
                height="125px"
                dark
                elevation="0"
            >
                <v-card-subtitle>
                    <div class="text-center mt-n4 mb-3">
                        <span class="text-h6 font-weight-light">{{$t('costs.extra_hours')}}</span>
                    </div>
                </v-card-subtitle>
                <!-- DESKTOP -->
                <v-card-text class="d-none d-lg-block ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="auto" class="text-h6 font-weight-bold white--text ml-4">
                        {{ costs.workers.total_hours - costs.workers.without_extra }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
                  <!-- MOBILE -->
                  <v-card-text class="d-md-none ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="12" class="font-weight-bold white--text text-center">
                        {{ costs.workers.total_hours - costs.workers.without_extra }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
            </v-card>
            </v-col>

            <v-col cols="12" md="3">
                <v-card
                class="grow"
                color="secondary"
                outlined
                height="125px"
                dark
                elevation="0"
            >
                <v-card-subtitle>
                    <div class="text-center mt-n4 mb-3">
                        <span class="text-h6 font-weight-light">{{$t('costs.total50')}}</span>
                    </div>
                </v-card-subtitle>
                <!-- DESKTOP -->
                <v-card-text class="d-none d-lg-block ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="auto" class="text-h6 font-weight-bold white--text ml-4">
                        {{ costs.workers.total_50 }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
                  <!-- MOBILE -->
                  <v-card-text class="d-md-none ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="12" class="font-weight-bold white--text text-center">
                        {{ costs.workers.total_50 }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
            </v-card>
            </v-col>

            <v-col cols="12" md="3">
                <v-card
                class="grow"
                color="secondary"
                outlined
                height="125px"
                dark
                elevation="0"
            >
                <v-card-subtitle>
                    <div class="text-center mt-n4 mb-3">
                        <span class="text-h6 font-weight-light">{{$t('costs.total75')}}</span>
                    </div>
                </v-card-subtitle>
                <!-- DESKTOP -->
                <v-card-text class="d-none d-lg-block ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="auto" class="text-h6 font-weight-bold white--text ml-4">
                        {{ costs.workers.total_75 }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
                  <!-- MOBILE -->
                  <v-card-text class="d-md-none ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="12" class="font-weight-bold white--text text-center">
                        {{ costs.workers.total_75 }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
            </v-card>
            </v-col>

            <v-col cols="12" md="3">
                <v-card
                class="grow"
                color="secondary"
                outlined
                height="125px"
                dark
                elevation="0"
            >
                <v-card-subtitle>
                    <div class="text-center mt-n4 mb-3">
                        <span class="text-h6 font-weight-light">{{$t('costs.total100')}}</span>
                    </div>
                </v-card-subtitle>
                <!-- DESKTOP -->
                <v-card-text class="d-none d-lg-block ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="auto" class="text-h6 font-weight-bold white--text ml-4">
                        {{ costs.workers.total_100 }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
                  <!-- MOBILE -->
                  <v-card-text class="d-md-none ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="12" class="font-weight-bold white--text text-center">
                        {{ costs.workers.total_100 }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
            </v-card>
            </v-col>

            <v-col cols="12" md="3">
                <v-card
                class="grow"
                color="secondary"
                outlined
                height="125px"
                dark
                elevation="0"
            >
                <v-card-subtitle>
                    <div class="text-center mt-n4 mb-3">
                        <span class="text-h6 font-weight-light">{{$t('costs.lunch_hour')}}</span>
                    </div>
                </v-card-subtitle>
                <!-- DESKTOP -->
                <v-card-text class="d-none d-lg-block ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="auto" class="text-h6 font-weight-bold white--text ml-4">
                        {{ costs.workers.lunch_hour == null ? 1 : costs.workers.lunch_hour }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
                  <!-- MOBILE -->
                  <v-card-text class="d-md-none ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="12" class="font-weight-bold white--text text-center">
                        {{ costs.workers.lunch_hour == null ? 1 : costs.workers.lunch_hour }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
            </v-card>
            </v-col>

            <v-card width="100%" elevation="0"  :class="this.$root.$vuetify.theme.isDark ? 'secondary pa-0 ma-0' : 'pa-0 ma-0'">
                <v-card-title>
                {{$t('costs.descriptive_worker')}}
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('globals.filter')"
                    single-line
                    hide-details
                ></v-text-field>
                </v-card-title>
                <v-data-table
                    :headers="headers"
                    :items="costs.workers.table"
                    :search="search"
                    :footer-props="tableFooter"
                    :expanded.sync="expanded"
                    :single-expand="true"
                    item-key="worker.id"
                    :class="this.$root.$vuetify.theme.isDark ? 'secondary' : ''"
                    :options.sync="options"   
                    style="white-space: nowrap;"
                    show-expand
                >

                <template v-slot:item.extra="{item}">
                    {{ item.total_hours - item.without_extra }}
                </template>

                <template v-slot:item.worker_value="{item}">
                    {{ parseValue(item.worker.value) }}
                </template>
            
                <template v-slot:item.total_cost="{item}">
                    {{ parseValue(item.total_cost) }}
                </template>

                <template v-slot:item.total_cost_day="{item}">
                    {{ parseValue(item.total_cost_day) }}
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <v-simple-table :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th>{{$t('globals.work')}}</th>
                                    <th>{{$t('globals.data')}}</th>
                                    <th>{{$t('costs.start')}}</th>
                                    <th>{{$t('costs.end')}}</th>
                                    <th>{{$t('costs.night_work')}}</th>
                                    <th>{{$T('costs.sleep_over')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="diary in item.diaries" :key="diary.code">
                                        <td>{{ diary.name }}</td>
                                        <td>{{ diary.date }}</td>
                                        <td>{{ parseHour(diary.started_hour) }}:{{ parseMinute(diary.started_minute) }}</td>
                                        <td>{{ parseHour(diary.finished_hour) }}:{{ parseMinute(diary.finished_minute) }}</td>
                                        <td>{{ diary.night_work == false ? $t('globals.no') : $t('globals.yes') }}</td>
                                        <td>{{ diary.slept_night == false ? $t('globals.no') : $t('globals.nyeso') }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </td>
                </template>
            </v-data-table>
            </v-card>
        </v-row>
    </v-container>
</template>
<script>
export default{
    props: ['costs'],
    data(){
        return{         
            options: {},   
            expanded: [],
            search: '',
        }
    },
    methods:{
        parseHour(h){
            if(h < 10)
                return '0' + h
            
            return h
        },
        parseMinute(m){
            if(m < 10)
                return '0' + m
            
            return m
        },
        parseValue(v){
            return this.$currency(v)
          },
    },
    computed:{
      tableFooter(){
        let t = this.$t("globals.lines_datatable")
        let a = [5, 10, 15, 20];
        return {
          'items-per-page-options': a,
          'items-per-page-text': t
        }
      }, 
      headers(){
        let he = [
          {
              text: this.$t('globals.code'),
              value: 'worker.code'
          },
          {
              text: this.$t('globals.worker'),
              value: 'worker.name'
          },
          {
              text: this.$t('globals.price'),
              value: 'worker_value',
              align: 'center'
          },
          {
              text: this.$t('costs.total_hours'),
              value: 'total_hours',
              align: 'center'
          },
          {
              text: this.$t('costs.extra'),
              value: 'extra',
              align: 'center'
          },
          {
              text: this.$t('costs.lunch'),
              value: 'lunch_hour',
              align: 'center'
          },
          {
              text: this.$t('costs.value_50'),
              value: 'total_50',
              align: 'center'
          },
          {
              text: this.$t('costs.value_75'),
              value: 'total_75',
              align: 'center'
          },
          {
              text: this.$t('costs.value_100'),
              value: 'total_100',
              align: 'center'
          },
          {
              text: this.$t('globals.cost'),
              value: 'total_cost',
              align: 'center'
          },
          {
              text: this.$t('globals.daily_costs'),
              value: 'total_cost_day',
              align: 'center'
          }
        ]
        return he
      }
    }
}
</script>