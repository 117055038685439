<template>
    <section class="mt-16">
      <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-clock</v-icon> <span>{{$t('lunch_hours.crumb_add')}}</span>
        </h1>
  
        <div class="mt-16">
          <LunchHour method="create"></LunchHour>
        </div>
    </section>
  </template>
  <script>
  import LunchHour from '@/components/lunchHour/workers/LunchHour'
  
  export default {
    components: {
        LunchHour
    },
    beforeCreate(){
      document.title = "Reportlake - " + this.$t('lunch_hours.crumb_add');
    },
    data: () => ({
      
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "lunch_hour.create"])) {
        this.$router.push('/admin');
      }
  
    },
    methods:{
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: this.$t('menu.dashboard'),
            disabled: false,
            to: '/' + this.$session.get('domain') + '/admin',
            exact: true,
          },
          {
            text: this.$t('menu.lunch_management'),
            disabled: false,
            to: '/' + this.$session.get('domain') + '/admin/workers-settings/lunch-hour',
            exact: true,
          },
          {
            text: this.$t('lunch_hours.crumb_add'),
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  