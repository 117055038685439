<template>
  <section class="mt-16">
    <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-hard-hat</v-icon> <span>{{$t('work.crumb_add_work')}}</span>
      </h1>

      <div class="mt-16" :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'">
        <work method="create"></work>
      </div>
  </section>
</template>
<script>
import Work from '@/components/works/Work'

export default {
  components: {
    Work
  },
  beforeCreate(){
    document.title = "Report Lake - " + this.$t('work.crumb_add_work');
  },
  data: () => ({
    
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "works.create"])) {
      this.$router.push('/admin');
    }

  },
  methods:{
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: this.$t('menu.dashboard'),
          disabled: false,
          to: '/' + this.$session.get('domain') + '/admin',
          exact: true,
        },
        {
          text: this.$t('work.crumb_management'),
          disabled: false,
          to: '/' + this.$session.get('domain') + '/admin/works-settings/works',
          exact: true,
        },
        {
          text: this.$t('work.crumb_add_work'),
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
