<template>
  <v-card 
    v-bind="$attrs"
    v-on="$listeners"
    :loading="loading"
    elevation="10"
  >
    <slot></slot>
  </v-card>
</template>

<script>
  export default {
    name: 'uiCard',
    props: {
      loading: {
        type: Boolean,
        default: false,
      }
    }
  }
</script>

<style lang="scss" scoped>
::v-deep .v-card__title {
  color: black !important;
  font-weight: bold;
}
</style>