<template>
    <img style="cursor:pointer;" class="mt-1"
        :src="path" 
        v-bind="$attrs"
        v-on="$listeners"
        :style="getStyle"
        contain
    />
</template>
<script>
export default{
    props:{
        icon:{
            typeof: String,
            default: ''
        },
        size:{
            typeof: String,
            default: 'medium'
        }
    },
    data(){
        return{
            icons: [
                {
                    name:     'rp-dashboard-blue-filled',
                    value:    'icons_Prancheta 1-01.png'
                },
                {
                    name:     'rp-dashboard-gray-outlined',
                    value:    'icons_Prancheta 1-04.png'
                },
                {
                    name:     'rp-dashboard-yellow-filled',
                    value:    'icons_Prancheta 1-05.png'
                },
                {
                    name:     'rp-user-gray-outlined',
                    value:    'icons_Prancheta 1-16.png'
                },
                {
                    name:     'rp-user-blue-filled',
                    value:    'icons_Prancheta 1-13.png'
                },
                {
                    name:     'rp-colaboradores-blue-filled',
                    value:    'icons_Prancheta 1-19.png'
                },
                {
                    name:     'rp-colaboradores-gray-filled',
                    value:    'icons_Prancheta 1-21.png'
                },
                {
                    name:     'rp-colaboradores-gray-outlined',
                    value:    'icons_Prancheta 1-22.png'
                },
                {
                    name:     'rp-exportar-gray-outlined',
                    value:    'icons_Prancheta 1-31.png'
                },
                {
                    name:     'rp-exportar-blue-filled',
                    value:    'icons_Prancheta 1-28.png'
                },
                {
                    name:     'rp-logout-gray-outlined',
                    value:    'icons_Prancheta 1-31.png'
                },
                {
                    name:     'rp-logout-blue-filled',
                    value:    'icons_Prancheta 1-28.png'
                },
                {
                    name:     'rp-clock-blue-filled',
                    value:    'icons_Prancheta 1-34.png'
                },
                {
                    name:     'rp-clock-gray-outlined',
                    value:    'icons_Prancheta 1-37.png'
                },
                {
                    name:     'rp-apple-gray-outlined',
                    value:    'icons_Prancheta 1-43.png'
                },
                {
                    name:     'rp-apple-blue-filled',
                    value:    'icons_Prancheta 1-40.png'
                },
                {
                    name:     'rp-email-gray-outlined',
                    value:    'icons_Prancheta 1-49.png'
                },
                {
                    name:     'rp-email-blue-filled',
                    value:    'icons_Prancheta 1-46.png'
                },
                {
                    name:     'rp-obras-gray-filled',
                    value:    'icons_Prancheta 1-60.png'
                },
                {
                    name:     'rp-obras-gray-outlined',
                    value:    'icons_Prancheta 1-61.png'
                },
                {
                    name:     'rp-obras-blue-filled',
                    value:    'icons_Prancheta 1-58.png'
                },
                {
                    name:     'rp-cog-gray-outlined',
                    value:    'icons_Prancheta 1-67.png'
                },
                {
                    name:     'rp-cog-gray-filled',
                    value:    'icons_Prancheta 1-66.png'
                },
                {
                    name:     'rp-cog-blue-filled',
                    value:    'icons_Prancheta 1-64.png'
                },
                {
                    name:     'rp-calendar-gray-outlined',
                    value:    'icons_Prancheta 1-73.png'
                },
                {
                    name:     'rp-calendar-blue-filled',
                    value:    'icons_Prancheta 1-70.png'
                },
                {
                    name:     'rp-clientes-gray-outlined',
                    value:    'icons_Prancheta 1-85.png'
                },
                {
                    name:     'rp-clientes-blue-filled',
                    value:    'icons_Prancheta 1-82.png'
                },
                {
                    name:     'rp-partes-diarias-blue-filled',
                    value:    'icons_Prancheta 1-88.png'
                },
                {
                    name:     'rp-partes-diarias-gray-outlined',
                    value:    'icons_Prancheta 1-91.png'
                },
                {
                    name:     'rp-earth-blue-filled',
                    value:    'icons_Prancheta 1-94.png'
                },
                {
                    name:     'rp-earth-gray-filled',
                    value:    'icons_Prancheta 1-96.png'
                },
                {
                    name:     'rp-earth-gray-outlined',
                    value:    'icons_Prancheta 1-97.png'
                },
                {
                    name:     'rp-empresas-blue-filled',
                    value:    'icons_Prancheta 1-100.png'
                },
                {
                    name:     'rp-empresas-gray-outlined',
                    value:    'icons_Prancheta 1-103.png'
                },
                {
                    name:     'rp-alert-blue-filled',
                    value:    'icons_Prancheta 1-106.png'
                },
                {
                    name:     'rp-alert-white-filled',
                    value:    'icons_Prancheta 1-108.png'
                },
                {
                    name:     'rp-alert-gray-outlined',
                    value:    'icons_Prancheta 1-109.png'
                },
                {
                    name:     'rp-category-gray-outlined',
                    value:    'icons_Prancheta 1-113.png'
                },
                {
                    name:     'rp-category-blue-filled',
                    value:    'icons_Prancheta 1-112.png'
                },
                {
                    name:     'rp-graphic-blue-filled',
                    value:    'icons_Prancheta 1-121.png'
                },
                {
                    name:     'rp-graphic-blue-outlined',
                    value:    'icons_Prancheta 1-122.png'
                },
                {
                    name:     'rp-graphic-gray-filled',
                    value:    'icons_Prancheta 1-123.png'
                },
                {
                    name:     'rp-graphic-gray-outlined',
                    value:    'icons_Prancheta 1-124.png'
                },
                {
                    name:     'rp-graphic-yellow-filled',
                    value:    'icons_Prancheta 1-125.png'
                },
                {
                    name:     'rp-graphic-yellow-outlined',
                    value:    'icons_Prancheta 1-126.png'
                },
                {
                    name:     'rp-truck-blue-filled',
                    value:    'icons_Prancheta 1-127.png'
                },
                {
                    name:     'rp-truck-gray-outlined',
                    value:    'icons_Prancheta 1-130.png'
                },
                {
                    name:     'rp-folder-blue-filled',
                    value:    'icons_Prancheta 1-133.png'
                },
                {
                    name:     'rp-folder-gray-outlined',
                    value:    'icons_Prancheta 1-136.png'
                },
                {
                    name:     'rp-star-gray-filled',
                    value:    'icons_Prancheta 1-142.png'
                },
                {
                    name:     'rp-star-blue-filled',
                    value:    'icons_Prancheta 1-139.png'
                },
                {
                    name:     'rp-money-gray-filled',
                    value:    'icons_Prancheta 1-146.png'
                },
                {
                    name:     'rp-money-blue-filled',
                    value:    'icons_Prancheta 1-145.png'
                },
                {
                    name:     'rp-diagram-gray-outlined',
                    value:    'icons_Prancheta 1-151.png'
                },
                {
                    name:     'rp-diagram-yellow-outlined',
                    value:    'icons_Prancheta 1-153.png'
                },
                {
                    name:     'rp-diagram-blue-filled',
                    value:    'icons_Prancheta 1-148.png'
                },
                {
                    name:     'rp-eye-gray-outlined',
                    value:    'icons_Prancheta 1-157.png'
                },
                {
                    name:     'rp-eye-blue-filled',
                    value:    'icons_Prancheta 1-154.png'
                },
                {
                    name:     'rp-eye-gray-filled',
                    value:    'icons_Prancheta 1-156.png'
                },
                {
                    name:     'rp-barcode-gray-outlined',
                    value:    'icons_Prancheta 1-163.png'
                },
                {
                    name:     'rp-barcode-blue-filled',
                    value:    'icons_Prancheta 1-160.png'
                },
                {
                    name:     'rp-barcode-gray-filled',
                    value:    'icons_Prancheta 1-162.png'
                },
                {
                    name:     'rp-barcode-yellow-filled',
                    value:    'icons_Prancheta 1-164.png'
                },
                {
                    name:     'rp-ticket-blue-filled',
                    value:    'icons_Prancheta 1-190.png'
                },
                {
                    name:     'rp-permissions-gray-filled',
                    value:    'icons_Prancheta 1-191.png'
                },
                {
                    name:     'rp-permissions-gray-outlined',
                    value:    'icons_Prancheta 1-194.png'
                },
                {
                    name:     'rp-ticket-gray-outlined',
                    value:    'icons_Prancheta 1-194.png'
                },
                {
                    name:     'rp-paperclip-blue-filled',
                    value:    'icons_Prancheta 1-220.png'
                },
                {
                    name:     'rp-paperclip-gray-filled',
                    value:    'icons_Prancheta 1-221.png'
                },
                {
                    name:     'rp-paperclip-gray-outlined',
                    value:    'icons_Prancheta 1-224.png'
                },
                {
                    name:     'rp-shield-blue-filled',
                    value:    'icons_Prancheta 1-238.png'
                },
                {
                    name:     'rp-shield-gray-filled',
                    value:    'icons_Prancheta 1-239.png'
                },
                {
                    name:     'rp-shield-gray-outlined',
                    value:    'icons_Prancheta 1-242.png'
                },
                {
                    name:     'rp-info-blue-filled',
                    value:    'icons_Prancheta 1-244.png'
                },
                {
                    name:     'rp-info-gray-outlined',
                    value:    'icons_Prancheta 1-248.png'
                },
            ]
        }
    },
    computed:{
        getStyle(){
            return 'width: ' + this.width + ';height: ' + this.height;
        },
        path(){
            let i = ''
            this.icons.forEach(element => {
                if(element.name == this.icon)
                    i = 'icons/icons/png/' + element.value;
            });
            if(i == '')
                return i

            return require('@/assets/'+ i)
        },
        width(){
            if(this.size == 'xlarge')
                return '34px'

            if(this.size == 'large')
                return '30px'
            
            if(this.size == 'medium')
                return '24px';

            if(this.size == 'small')
                return '20px';

            if(this.size == 'xsmall')
                return '16px';

            return '24px';
        },
        height(){
            if(this.size == 'xlarge')
                return '34px'

            if(this.size == 'large')
                return '30px'

            if(this.size == 'medium')
                return '24px';

            if(this.size == 'small')
                return '20px';
            
            if(this.size == 'xsmall')
                return '16px';
            return '24px';
        }
    }
    
}
</script>