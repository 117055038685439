<template>
    <v-card elevation="10" :style="this.$root.$vuetify.theme.isDark ? '' : (modalType ? '' : 'background-color: rgba(255, 255, 255, 0.75);')" :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
        <v-toolbar :class="$root.$vuetify.theme.isDark ? 'secondary' : 'primary white--text'">
            {{ method == 'create' ? 'Criação de requisição externa' : 'Atualizar requisição externa'}}
            <v-spacer></v-spacer>
            
            <v-speed-dial
              v-if="method=='update'"
              v-model="speedDial"
              direction="bottom"
              :open-on-hover="true"
            >
              <template v-slot:activator>
                <v-tooltip right color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" v-on="on"
                      v-model="speedDial"
                      :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary'"
                      dark
                      x-large
                    >
                      <v-icon v-if="speedDial">
                        mdi-close
                      </v-icon>
                      <v-icon v-else>
                        mdi-dots-horizontal
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Opções</span>
                </v-tooltip>
              </template>
              <v-tooltip right color="success">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="success"
                    large
                    @click="exportPDF()"
                  >
                    <v-icon>mdi-file-pdf-box</v-icon>
                  </v-btn>
                </template>
                <span>Exportar PDF</span>
              </v-tooltip>      
              <v-tooltip right color="warning" v-if="fields.shopping_invoice != null">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="warning"
                    large
                    @click="exportInvoice()"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>Exportar Fatura</span>
              </v-tooltip>               
            </v-speed-dial>
        </v-toolbar>
        
        <v-container>
            <validation-observer ref="form" v-slot="{ invalid }">
            <v-form @submit.prevent="submit"  class="mb-16">
                <v-progress-linear v-if="loading"
                    indeterminate
                    class="global-loader"
                ></v-progress-linear>
                <fieldset :disabled="loading">
                    <v-row>
                      <v-col cols="12" md="12">
                            <v-text-field
                              v-if="method!='create'"
                              :disabled="true"
                              v-model="fields.reference"
                              prepend-inner-icon="mdi-account-details"
                              label="Referência interna"
                              clearable
                            ></v-text-field>
                        </v-col>                        
                      <v-col cols="12" md="6">
                          <validation-provider v-slot="{ errors }" vid="obra" :name="$t('globals.work')" rules="required">
                            <v-autocomplete
                                    :disabled="true"
                                    v-model="fields.work_id"
                                    :items="works"
                                    menu-props="auto"
                                    :error-messages="errors"
                                    :label="$t('globals.work') +  ' *'"
                                    item-value="id"
                                    :item-text="item => item.code + ' - ' + item.name"
                                    prepend-inner-icon="mdi-account"
                                ></v-autocomplete>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-expansion-panels focusable>
                            <v-expansion-panel class="mt-4">
                              <v-expansion-panel-header  v-slot="{ open }"  :class="($root.$vuetify.theme.isDark ? 'secondary' : 'accent') + ' white--text'"> 
                                <v-row v-if="open">
                                  <v-col cols="12">
                                    <strong>Morada</strong>
                                  </v-col>
                                </v-row> 
                                <v-row v-if="!open">
                                  <v-col cols="12" md="auto">
                                    <strong>Morada:</strong>
                                  </v-col>
                                  <v-col cols="12" md="auto" v-if="fields.address != null">
                                    {{ fields.address }}
                                  </v-col>
                                  <v-col cols="12" md="auto" v-if="fields.postal_code1 != null">
                                    {{ fields.postal_code1 }} - {{ fields.postal_code2 }}
                                  </v-col>
                                  <v-col cols="12" md="auto" v-if="fields.city != null">
                                    {{ fields.city }}
                                  </v-col>
                                  <v-col cols="12" md="auto" v-if="fields.country != null">
                                    {{ fields.country }}
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content :class="($root.$vuetify.theme.isDark ? 'secondary' : '')">
                                <v-row>                                 
                                  <v-col cols="12" md="12">
                                    <validation-provider v-slot="{ errors }" vid="morada" :name="$t('globals.address.address')" rules="required">
                                        <v-textarea
                                          v-model="fields.address"
                                          :disabled="lockEdition"
                                          prepend-inner-icon="mdi-account-details"
                                          :label="$t('globals.address.address') + ' *'"
                                          clearable
                                          :error-messages="errors"
                                        ></v-textarea>
                                      </validation-provider>
                                  </v-col> 
                                  <v-col cols="12" md="auto">
                                        Código-postal
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-otp-input
                                            plain
                                            v-model="fields.postal_code1"
                                            :disabled="lockEdition"
                                            :length="4"
                                        ></v-otp-input>
                                    </v-col>
                                    <v-col cols="12" md="auto">
                                        <h1>-</h1>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-otp-input
                                            plain
                                            v-model="fields.postal_code2"
                                            :disabled="lockEdition"
                                            :length="3"
                                        ></v-otp-input>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            label="Cidade"
                                            color="accent"
                                            v-model="fields.city"
                                            :disabled="lockEdition"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            label="Pais"
                                            color="accent"
                                            v-model="fields.country"
                                            :disabled="lockEdition"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>  
                        <v-col cols="12" md="12">
                          <validation-provider v-slot="{ errors }" vid="descrição" :name="$t('globals.description')" rules="required">
                              <v-text-field
                                v-model="fields.description"
                                prepend-inner-icon="mdi-account-details"
                                :label="$t('globals.description') + ' *'"
                                :disabled="lockEdition"
                                clearable
                                :error-messages="errors"
                              ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-menu
                            v-model="pickerDateChoose"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                              <template v-slot:activator="{ on, attrs }">
                              <validation-provider v-slot="{ errors }" vid="Data" :name="$t('globals.delivery_term')" rules="required">
                                  <v-text-field
                                      v-model="fields.delivery_term"
                                      :label="$t('globals.delivery_term') + ' *'"
                                      :disabled="lockEdition"
                                      :error-messages="errors"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                  ></v-text-field>
                              </validation-provider>
                              </template>
                              <v-date-picker
                                :allowed-dates="(date) => date >= new Date(new Date()+1).toISOString().substr(0, 10)"
                                v-model="fields.delivery_term"
                                @input="pickerDateChoose = false"
                              ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12">
                            <validation-provider v-slot="{ errors }" vid="Condição Pagamento" :name="$t('globals.payment_condition')" rules="required">
                              <v-select
                                  v-model="fields.payment_condition"
                                  :items="['Fatura a 30 dias', 'Fatura a 60 dias', 'Fatura a 90 dias', 'Fatura a 180 dias', 'Fatura a 360 dias']"
                                  menu-props="auto"
                                  :disabled="lockEdition"
                                  :error-messages="errors"
                                  :label="$t('globals.payment_condition') + '*'"
                                  prepend-inner-icon="mdi-account"
                              ></v-select>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" md="12">
                          <validation-provider v-slot="{ errors }" vid="supplier" :name="$t('globals.supplier')" rules="|">
                              <v-select
                                    v-model="fields.supplier_id"
                                    :items="suppliers"
                                    item-value="id"
                                    :item-text="item => item.name"
                                    :disabled="lockEdition"
                                    menu-props="auto"
                                    :error-messages="errors"
                                    :label="$t('globals.supplier') + ' *'"
                                    prepend-inner-icon="mdi-account"
                                ></v-select>
                            </validation-provider>
                        </v-col>                  
                    </v-row>
                    <v-row>
                  <v-col cols="9" xs="9" sm="9" md="12">
                    <!-- DESKTOP VERSION -->
                    <v-simple-table dense class="d-none d-lg-block">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center">
                              Referência Material
                            </th>
                            <th class="text-center">
                              Material
                            </th>
                            <th class="text-center">
                              Descrição
                            </th>
                            <th class="text-center">
                              Quantidade
                            </th>
                            <th class="text-center">
                              Custo
                            </th>
                            <th class="text-center">
                              Unidade
                            </th>
                            <th class="text-center">
                              Total
                            </th>
                            <th class="text-center">
                              Opções
                            </th>
                          </tr>
                        </thead>
                        <tbody :class="$root.$vuetify.theme.isDark ?  'secondary' : ''">
                          <tr v-for="(element, index) in fields.materials" v-bind:key="index + 10000">
                            <td class="text-center">
                                <label>{{element.material_ref}}</label>
                            </td>
                            <td class="text-center">
                                <label>{{element.material == null ? '-' : element.material}}</label>
                            </td>
                            <td class="text-center">
                                <label>{{element.description}}</label>
                            </td>
                            <td class="text-center">
                                <label>{{element.qty}}</label>
                            </td>
                            <td class="text-center">
                                <label>{{element.cost}}</label>
                            </td>
                            <td class="text-center">
                                <label>{{element.unit }}</label>
                            </td>
                            <td class="text-center">
                                <label>{{ element.cost != null ? element.qty * element.cost : '-'}}</label>
                            </td>
                            <td class="text-center">
                              <v-row>
                                <v-col cols="12" md="3" v-if="!lockEdition">  
                                  <IconRemove
                                      v-if="$root.session.hasPermission(['super', 'shopping_request.delete'])"
                                      @on-submit="deleteLine(element)"
                                    />
                                </v-col>
                                <v-col cols="12" md="3" v-if="!lockEdition">                                  
                                  <IconView
                                    class="mr-2"
                                    @click="editLine(element)"                                      
                                  />
                                </v-col>
                              </v-row>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <!-- DESKTOP VERSION -->
                    <v-dialog
                      v-model="addMaterial"
                      max-width="800px"
                      >
                      <template v-slot:activator="{ props }">
                          <div class="text-right mt-4">
                          <Tooltip
                              color="warning"
                              text="Adicionar material à fatura"
                              bottom
                              v-if="!lockEdition"
                          >
                              <v-btn
                                  class="mx-2"
                                  fab
                                  dark
                                  large
                                  color="warning"
                                  @click="addMaterial = true;"
                                  v-bind="props"
                                  >
                                  <v-icon dark>
                                      mdi-plus
                                  </v-icon>
                              </v-btn>
                          </Tooltip>
                          </div>
                      </template>

                      <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                        <v-toolbar :class="$root.$vuetify.theme.isDark ? 'secondary' : 'primary white--text'">
                          Material
                        </v-toolbar>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                    v-model="newMaterial.material_ref"
                                    prepend-inner-icon="mdi-account-details"
                                    label="Referência material *"
                                    clearable
                                ></v-text-field>
                              </v-col>   
                              <v-col cols="12">
                                <v-autocomplete
                                  v-model="newMaterial.material_id"
                                  :items="materials"
                                  item-value="id"
                                  :item-text="item => item.reference + ' - ' + item.description"
                                  menu-props="auto"
                                  label="Material"
                                  prepend-inner-icon="mdi-account"
                              ></v-autocomplete>
                              </v-col>                                 
                              <v-col cols="12" md="12">
                                <v-text-field
                                    v-model="newMaterial.description"
                                    prepend-inner-icon="mdi-account-details"
                                    label="Descrição *"
                                    clearable
                                ></v-text-field>
                              </v-col>                                    
                              <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="newMaterial.qty"
                                    prepend-inner-icon="mdi-account-details"
                                    type="number"
                                    label="Quantidade *"
                                    clearable
                                ></v-text-field>
                              </v-col>                                
                              <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="newMaterial.cost"
                                    prepend-inner-icon="mdi-account-details"
                                    type="number"
                                    label="Custo unitário *"
                                    clearable
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-select
                                    v-model="newMaterial.unit_management_id"
                                    :items="units"
                                    item-value="id"
                                    :item-text="item => item.abbreviation"
                                    menu-props="auto"
                                    label="Unidade *"
                                    prepend-inner-icon="mdi-account"
                                ></v-select>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions class="justify-center">
                          <v-btn :disabled="newMaterial.cost == null || newMaterial.material_ref == null || newMaterial.description==null || newMaterial.qty==null || newMaterial.unit_management_id==null" color="success" @click="insertMaterial()">Inserir</v-btn>
                          <v-btn color="error" right @click="addMaterial = false; editMode = false;">Fechar</v-btn>
                          </v-card-actions>
                      </v-card>
                  </v-dialog>
                  </v-col>
                </v-row>
                
                <v-row class="mt-6" align="center" justify="space-around">
                    <v-btn :disabled="invalid" depressed color="primary" type="submit" v-if="!lockEdition">
                    Gravar
                    </v-btn>
                </v-row>
                </fieldset>
            </v-form>
            <DialogSuccess :opened="success" @on-ok="onSuccess" >
                Nova compra gravada com sucesso!
            </DialogSuccess>
            <ErrorSnackbar v-model="error.show" :message="error.message"/>
            </validation-observer>
        </v-container>
    </v-card>
  </template>
  <script>
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  import Materials from '@/api/Materials.js'
  import Work from '@/api/Work.js'
  import ExternalRequestAPI from '@/api/ExternalRequest.js'
  import UnitsManagement from '@/api/UnitsManagement.js'
  import Tooltip from '@/components/ui/Tooltip.vue';
  import IconRemove from '@/components/ui/IconRemove.vue';
  import Company from '@/api/Company.js'
  import IconView from '@/components/ui/IconView.vue';
  import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
  
  export default {
    components: {
      DialogSuccess,
      Tooltip,
      IconRemove,
      IconView,
      ErrorSnackbar
    },
    props: {
      external_request: Object,
     method: String,
     disabled: Boolean,
     modalType: {
      typeof: Boolean,
      default: false
     }
   },
   watch: { 
    external_request: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
          let materials_array = data.materials

          this.fields = data;
          this.fields.materials = []

          this.fields.id = data.id
          this.fields.reference = data.reference
          this.fields.supplier_id = data.supplier_id
          this.fields.delivery_term = data.delivery_term
          this.fields.work_id = data.work_id
          this.fields.status = data.status
          this.fields.shopping_invoice = data.shopping_invoice
          
          if(this.method == 'update')
            if(data.shopping_status != 'Em andamento')
              this.lockEdition = true
          

          if(data.postal_code != ''){
            if(data.postal_code != null){
              this.fields.postal_code1 = data.postal_code.split('-')[0]
              
              this.fields.postal_code2 = data.postal_code.split('-')[1]
            }
          }

          
          if(this.method == 'create'){
            materials_array.forEach(element => {
              let disponivel = element.stock - element.reserved

              if(disponivel - element.qty <= 0){
                this.fields.materials.push(element)
              }
            });
          }else{
            this.fields.materials = materials_array
          }
        }
      },
    },
    data: () => ({
      lockEdition: false,
      speedDial: false,
      addMaterial: false,
      materials: [],
      editMode: false,
      newMaterial:{
        id: null,
        material_ref: null,
        qty: null,
        description: null,
        unit_management_id: null,
        material_id: null,
        cost: null
      },
      pickerDateChoose: false, 
      addressChooser: null,
      fields: {
        postal_code: null,
        postal_code1: null,
        postal_code2: null,
        shopping_invoice: null,
        city: null,
        country: null,
        address: null,
        payment_condition: null,
        reference: null,
        id_shopping_request: null,
        supplier_proposal_ref: null,
        description: null,
        work_delivery_term: null,
        supplier_id: null,
        supplier_delivery_term: null,
        work_id: null,
        status: null,
        materials: [],
      },
      loading:false,
      error: {
        title: '',
        message: '',
        show: false
      },
      success: false,
      suppliers:[],
      units: [],
      works:[]
    }),
    mounted(){
      this.fillBaseData();
      if(this.$route.params.budget_work_id != undefined)
        this.fields.id_shopping_request = this.$route.params.budget_work_id

    },
    methods:{
      async exportInvoice(){
        await ExternalRequestAPI.invoice(this.fields.id)
          .then((response) => {
            
            let documentName = 'Fatura_' + this.fields.reference;

            const blob = new Blob([response.data])
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", documentName + '.pdf');
            document.body.appendChild(link);
            link.click();
            link.remove();

          });
      },
      async exportPDF(){
        await ExternalRequestAPI.export(this.fields.id)
          .then((response) => {
            
            let documentName = this.fields.reference;

            const blob = new Blob([response.data])
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", documentName + '.pdf');
            document.body.appendChild(link);
            link.click();
            link.remove();

          });
        
      },
      editLine(item){
        this.newMaterial = item
        this.editMode = true
        this.addMaterial = true
      },
      deleteLine(item){
        let i = 0
        let pos = 0
        this.fields.materials.forEach(element => {
          if(element.id == item.id){
            pos = i
          }
          i++
        });
        this.fields.materials.splice(pos, 1)
      },
      insertMaterial(){
        let unit = ''
        let material = ''

        this.units.forEach(element => {
          if(element.id == this.newMaterial.unit_management_id)
            unit = element.abbreviation
        });

        this.materials.forEach(element => {
          if(element.id == this.newMaterial.material_id)
            material = element.description
        });



        let material_object = {
            id: this.newMaterial.id == undefined ? 'new_' + this.fields.materials.length : this.newMaterial.id,
            material_ref: this.newMaterial.material_ref,
            qty: this.newMaterial.qty,
            description: this.newMaterial.description,
            unit_management_id: this.newMaterial.unit_management_id,
            unit: unit,
            material_id: this.newMaterial.material_id,
            material: material,
            cost: this.newMaterial.cost,
        }

        if(this.editMode == false){

          this.fields.materials.push(material_object)

          this.newMaterial.id = null
          this.newMaterial.material_ref = null
          this.newMaterial.qty = null
          this.newMaterial.description = null
          this.newMaterial.unit_management_id = null
        }

        if(this.editMode){
          this.fields.materials.forEach(element => {
            if(element.id == this.newMaterial.id){
              element.material = material
              element.material_id = this.newMaterial.material_id
            }
          });

          this.editMode = false
          this.addMaterial = false
        }
      },
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        let domain = '/' + this.$session.get('domain')
        this.$router.push(domain + '/admin/shopping-request');
      },
      fillBaseData(){        
        Work.list().then(({data}) => {
          this.works = data
        })  
        Company.list().then(({data}) => {
            this.suppliers = data
        });
        UnitsManagement.list().then(({data}) => {
            this.units = data
        });
        Materials.list().then(({data}) => {
          this.materials = data
        })
      },
      submit() {
        let problem = false
        this.fields.materials.forEach(element => {
          if(element.cost == null)
            problem = true
        });
        if(problem){
          this.error.message = 'Materiais sem custos associados'
          this.error.show = true
          return
        }
  
        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }
  
          let data = {...this.fields};

          ExternalRequestAPI[this.method](data, this.fields.id).then((resp) => {
  
            this.success = true;
  
            this.loading = false;

            
            if(this.method == 'create')
              this.$router.push('/' + this.$session.get('domain') + '/admin/external-request/' + resp.data.id)

            
          }).catch(err => {
            this.loading = false;
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = this.$t('globals.error') + " " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })
      }
    },
    computed: {
      
    }
  };
  </script>
  