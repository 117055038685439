<template>
    <v-snackbar
      v-model="snackbar"
      color="#111b2d"
      bottom
      :center="mobile ? true:false"
      :right="mobile ? false:true"
      :timeout="-1"
      rounded="lg"
      elevation="24"
      class="text-justify"
      :vertical="true"
    >
    <v-container fluid>
        <div :class="mobile ? 'font-weight-bold text-body-3' : 'font-weight-bold text-justify text-body-1'">
            {{$t('cookies.presentation')}}
        </div>
        
        <Transition name="bounce">
            <v-container v-if="showOptions">
                <v-divider class="mt-2"></v-divider>
                <v-switch
                    v-model="cookie.necessary"
                    :label="$t('cookies.necessary_label')"
                    readonly
                    color="warning"
                ></v-switch>
                    {{$t('cookies.necessary')}}
                <v-switch
                    v-model="cookie.statistics"
                    :label="$t('cookies.statistics_label')"
                    color="warning"
                ></v-switch>
                    {{$t('cookies.statistics')}}
            </v-container>
        </Transition>
    </v-container>

        <v-divider></v-divider>
      <template v-slot:action="{ attrs }">
        <div class="text-center">
            <v-btn
                dark
                color="error"
                @click="$emit('cookie-reject')"                
                class="mt-2 font-weight-bold mb-4 white--text">
                <v-icon class="mr-3">mdi-alert-circle</v-icon>
                {{$t('cookies.discord')}}
            </v-btn>
            <v-btn
                dark
                color="warning"
                @click="showOptions = !showOptions"
                class="mt-2 mx-4 font-weight-bold mb-4 white--text">
                <v-icon class="mr-3">mdi-cog</v-icon>
                {{$t('cookies.options')}}
            </v-btn>
            <v-btn
                class="mt-2 greener--text font-weight-bold mb-4 white--text"
                color="#C7D3DC" dark outlined
                :attrs="attrs"
                elevation="2"
                @click="$emit('cookie-accept', cookie)"
            >
                <v-icon class="mr-3">mdi-cookie</v-icon>
                {{$t('cookies.agree')}}
            </v-btn>
        </div>
      </template>
    </v-snackbar>
</template>
<script>
export default{
    beforeMount(){
        this.checkMobile()
    },
    mounted(){
        window.addEventListener('resize', this.handleResize);
    },
    props:{
        snackbar: Boolean
    },
    data(){
        return{
            mobile: false,
            showOptions: false,
            cookie:{
                necessary: true,
                statistics: true
            }
        }
    },
    methods: {
        handleResize() {
            this.checkMobile()
        },
        checkMobile(){
            if(screen.width <= 760)
                this.mobile = true
            else
                this.mobile = false
        }
    }
}
</script>
<style>
@media(max-width: 850px){
    .v-snack__action {
        width: 100% !important;
        margin: auto !important; 
        display: block !important;
    }
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>