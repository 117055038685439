var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"50%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-stocking")]),_vm._v(_vm._s(_vm.$t('materials.update_stock'))+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 primary white--text"},[_vm._v(" "+_vm._s(_vm.$t('materials.update_stock'))+" ")]),_c('v-card-text',{staticClass:"mt-2"},[_c('validation-observer',{ref:"formStockUpdater",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"mb-16",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.loading)?_c('v-progress-linear',{staticClass:"global-loader",attrs:{"indeterminate":""}}):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('shoppings.shopping_date'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fields.shopping_date),callback:function ($$v) {_vm.$set(_vm.fields, "shopping_date", $$v)},expression:"fields.shopping_date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.pickerDateChoose),callback:function ($$v) {_vm.pickerDateChoose=$$v},expression:"pickerDateChoose"}},[_c('v-date-picker',{attrs:{"allowed-dates":function (date) { return date <= new Date(new Date()+1).toISOString().substr(0, 10); }},on:{"input":function($event){_vm.pickerDateChoose = false}},model:{value:(_vm.fields.shopping_date),callback:function ($$v) {_vm.$set(_vm.fields, "shopping_date", $$v)},expression:"fields.shopping_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"qty","name":_vm.$t('globals.quantity'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-counter","label":_vm.$t('globals.quantity') + ' *',"type":"number","clearable":"","error-messages":errors},on:{"input":_vm.calculatePrices},model:{value:(_vm.fields.qty),callback:function ($$v) {_vm.$set(_vm.fields, "qty", $$v)},expression:"fields.qty"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"unit","name":_vm.$t('globals.unit_cost'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":_vm.$currency_icon(),"label":_vm.$t('globals.unit_cost') + ' *',"type":"number","clearable":"","error-messages":errors},on:{"input":_vm.calculatePrices},model:{value:(_vm.fields.unit_cost),callback:function ($$v) {_vm.$set(_vm.fields, "unit_cost", $$v)},expression:"fields.unit_cost"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"cost","name":_vm.$t('globals.total_cost'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":_vm.$currency_icon(),"label":_vm.$t('globals.total_cost') + ' *',"disabled":true,"type":"number","clearable":"","error-messages":errors},model:{value:(_vm.fields.cost),callback:function ($$v) {_vm.$set(_vm.fields, "cost", $$v)},expression:"fields.cost"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.suppliers,"item-text":function (item) { return item.name; },"item-value":"id","menu-props":"auto","label":_vm.$t('globals.supplier'),"prepend-inner-icon":"mdi-account"},model:{value:(_vm.fields.supplier_id),callback:function ($$v) {_vm.$set(_vm.fields, "supplier_id", $$v)},expression:"fields.supplier_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"invoice","name":_vm.$t('invoices.attach'),"rules":"|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"accept":".pdf","label":_vm.$t('invoices.attach'),"error-messages":errors},model:{value:(_vm.fields.invoice),callback:function ($$v) {_vm.$set(_vm.fields, "invoice", $$v)},expression:"fields.invoice"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-6 mb-n16",attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{class:_vm.$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary',attrs:{"disabled":invalid,"depressed":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('globals.save'))+" ")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }