<template>
    <section class="mt-16">
      <v-breadcrumbs
          v-if="this.$root.$vuetify.theme.isDark"
          large
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
        <v-breadcrumbs
          v-else
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-hard-hat</v-icon> <span>{{$t('materials.crumb_edit')}}</span>
        </h1>
  
        <div class="mt-16">
          <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
  
          <template v-if="loading">
            <v-row>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row class="mt-6" align="center" justify="space-around">
              <v-skeleton-loader
                  type="button"
                ></v-skeleton-loader>
            </v-row>
          </template>
          <Material v-else method="update" :material="material" @refresh="refresh" :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'">
        </Material>
        </div>
    </section>
  </template>
  <script>
  import Material from '@/components/materials/Material'
  import Materials from "@/api/Materials.js";
  
  export default {
    components: {
      Material
    },
    beforeCreate(){
      document.title = "Report Lake - " + this.$t('materials.crumb_edit');
    },
    data: () => ({
        material: {},
        loading:true
    }),
    mounted(){
      if(!this.$root.session.hasPermission(["super", "materials.update"])) {
        this.$router.push('/admin');
      }
  
      Materials.find(this.$route.params.id).then(({data}) => { this.material = data; this.loading = false;});
    },
    methods:{
      refresh(){
        Materials.find(this.$route.params.id).then(({data}) => { this.material = data; this.loading = false;});
      }
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: this.$t('menu.dashboard'),
            disabled: false,
            to: '/' + this.$session.get('domain') + '/admin',
            exact: true,
          },
          {
            text: this.$t('menu.material_management'),
            disabled: false,
            to: '/' + this.$session.get('domain') + '/admin/budgeting/materials',
            exact: true,
          },
          {
            text: this.$t('materials.crumb_edit'),
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  