<template>
  <section class="mt-16">
    <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-hard-hat</v-icon> <span>{{$t('work.crumb_work')}}</span>
      </h1>

      <div class="mt-16">
        <v-progress-linear v-if="loading"
          indeterminate
          class="global-loader"
        ></v-progress-linear>
        <WorkComments @new-comment="reload" :comments="work.comments"/>

        <template v-if="loading">
          <v-row>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row class="mt-6" align="center" justify="space-around">
            <v-skeleton-loader
                type="button"
              ></v-skeleton-loader>
          </v-row>
        </template>
        <work v-else method="update" @reload="reload" :openTab="openTab" :work="work" :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'"></work>
      </div>
  </section>
</template>
<script>
import Work from '@/components/works/Work'
import WorkApi from "@/api/Work.js";
import WorkComments from "@/views/works_settings/works/WorkComments.vue"

export default {
  components: {
    Work,
    WorkComments
  },
  beforeCreate(){
    document.title = "Report Lake - " + this.$t('work.crumb_work');
  },
  data: () => ({
      work: {},
      loading:true,
      openTab: 0
  }),
  mounted(){
    if(!this.$root.session.hasPermission(["super", "works.update"])) {
      this.$router.push('/admin');
    }

    WorkApi.find(this.$route.params.id).then(({data}) => { this.work = data; this.loading = false;});
  },
  methods:{
    reload(tab){
      this.loading = true
      if(tab != undefined || tab != null)
        this.openTab = tab

      WorkApi.find(this.$route.params.id).then(({data}) => { this.work = data; this.loading = false;});
    }
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: this.$t('menu.dashboard'),
          disabled: false,
          to: '/' + this.$session.get('domain') + '/admin',
          exact: true,
        },
        {
          text: this.$t('work.crumb_management'),
          disabled: false,
          to: '/' + this.$session.get('domain') + '/admin/works-settings/works',
          exact: true,
        },
        {
          text: this.$t('work.crumb_work'),
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
