<template>
    <validation-observer ref="form" v-slot="{ invalid }">
      <v-form @submit.prevent="submit"  class="mb-16">
         <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
        <fieldset :disabled="loading">
          <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
            <v-row class="ma-2 pa-2">
              <v-col cols="12" md="2">
                <validation-provider v-slot="{ errors }" vid="hour" :name="$t('lunch_hours.lunch_hour')" rules="required">
                  <v-text-field
                    :disabled="method == 'create' ? false : true"
                    v-model="fields.hour"
                    prepend-inner-icon="mdi-clock"
                    :label="$t('lunch_hours.lunch_hour')"
                    clearable
                    type="number"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>         
              <v-col cols="12" md="3" v-if="$route.params.id != undefined">
                  <v-autocomplete
                    v-model="selectedWorker"
                    :items="workers"
                    item-value="id"
                    multiple
                    :item-text="item => item.name"
                    menu-props="auto"
                    :label="$t('globals.workers')"
                    prepend-inner-icon="mdi-account"
                  ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="2" v-if="$route.params.id != undefined">
                <v-btn depressed color="success" class="mt-2" @click="adicionarWorker()" :disabled='!$root.session.hasPermission(["super", "lunch_hours.update"])'>
                  {{$t('globals.add')}}
                </v-btn>
              </v-col>  
              <v-col cols="12" md="3" v-if="$route.params.id != undefined">
                  <v-autocomplete
                    v-model="selectedUser"
                    :items="users"
                    item-value="id"
                    multiple
                    :item-text="item => item.name"
                    menu-props="auto"
                    :label="$t('globals.users')"
                    prepend-inner-icon="mdi-account"
                  ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="2" v-if="$route.params.id != undefined">
                <v-btn depressed color="success" class="mt-2" @click="adicionarUser()" :disabled='!$root.session.hasPermission(["super", "lunch_hours.update"])'>
                  {{$t('globals.add')}}
                </v-btn>
              </v-col> 
            </v-row>
            <v-row v-if="$route.params.id != undefined">
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="fields.merged"
                  :loading="loading"
                  locale="pt-pt"
                >
                <template v-slot:item.actions="{item}">
                  <IconRemove
                    v-if='$root.session.hasPermission(["super", "lunch_hours.update"])'
                    class="mr-2"
                    @on-submit="deleteUserFromHour(item)"
                  />
                </template>
              </v-data-table>
              </v-col>
            </v-row>
            <v-row class="mt-6" align="center" justify="space-around">
              <v-col align="center" justify="space-around">
                <v-btn v-if='$root.session.hasPermission(["super", "lunch_hours.update"])' :disabled="invalid" depressed :class="($root.$vuetify.theme.isDark ? 'white primary--text' : 'primary') + ' mr-5 ml-5 mb-5'" type="submit">
                  {{$t('globals.save')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </fieldset>
      </v-form>
      <DialogSuccess :opened="success" @on-ok="onSuccess" >
        {{$t('lunch_hours.add_success')}}
      </DialogSuccess>
    </validation-observer>
  </template>
  <script>
  import LunchHour from "@/api/LunchHour.js";
  import Worker from "@/api/Worker.js";
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  import IconRemove from '@/components/ui/IconRemove.vue';
  import User from "@/api/User.js";
  
  export default {
    components: {
      DialogSuccess,
      IconRemove
    },
    props: {
     lunchHour: Object,
     method: String,
     disabled: Boolean,
   },
   watch: { 
    lunchHour: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;

        }
      },
    },
    mounted(){
      this.fillBaseData()
    },
    data: () => ({
        selectedWorker: [],
        selectedUser: [],
        workers: [],
        users: [],
      fields: {
        id: null,
        hour:null,
      },
      loading:false,
      error: {
        title: '',
        message: '',
      },
      success: false,
    }),
    methods:{
      adicionarUser(){
        this.loading = true

        let data = {
          hour: this.fields.hour,
          user: this.selectedUser
        }

        LunchHour.addHourToUser(data).then(() => {

          this.$emit('reload');
          this.success = true
          this.loading = false

        });
      },
      adicionarWorker(){
        this.loading = true

        let data = {
          hour: this.fields.hour,
          user: this.selectedWorker
        }

        LunchHour.addHourToWorker(data).then(() => {

          this.$emit('reload');
          this.success = true
          this.loading = false

        });
      },
      fillBaseData(){
        Worker.list().then(({data}) => {
          this.workers = data;
        });
        User.list().then(({data}) => {
          this.users = data;
        });
      },
      deleteUserFromHour(item){
        this.loading = true
        LunchHour.deleteHourFromWorker(item.id).then(() => {

          this.$emit('reload');
          this.success = true
          this.loading = false
          
        });
        
        this.loading = true
        LunchHour.deleteHourFromUser(item.id).then(() => {

          this.$emit('reload');
          this.success = true
          this.loading = false
          
        });
      },
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        let domain = '/' + this.$session.get('domain')
        this.$router.push(domain + '/admin/workers-settings/lunch-hour');
      },
      submit() {
  
        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }
  
          LunchHour[this.method](this.fields, this.fields.id).then(() => {
  
            this.success = true;
  
            this.loading = false;
            
          }).catch(err => {
            this.loading = false;
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = this.$t('globals.error') + " " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })
      }
    },
    computed: {
      headers(){
        let he = [
          {
            text: this.$t('globals.name'),
            sortable: false,
            value: 'name',
          },
          { 
            text: this.$t('globals.options'), 
            value: 'actions', 
            sortable: false 
          },
        ]
        return he
      }
    }
  };
  </script>
  