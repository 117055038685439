import BaseApi from "./BaseApi";

export default class SelfMeasuring extends BaseApi{
    construct() {}
 
    static async loadWork(id){
        return await SelfMeasuring.getAxiosInstance().get(SelfMeasuring.getFullApiUrl() + 'loadWork/' + id);
    }
 
    static async search(params) {
        return await SelfMeasuring.getAxiosInstance().get(SelfMeasuring.getFullApiUrl(),{params: params});
    }

    static async create(params) {
        return await SelfMeasuring.getAxiosInstance().post(SelfMeasuring.getFullApiUrl(),params);
    }

    static async find(id) {
        return await SelfMeasuring.getAxiosInstance().get(SelfMeasuring.getFullApiUrl() + id);
    }

    static async update(params, id) {
        return await SelfMeasuring.getAxiosInstance().post(SelfMeasuring.getFullApiUrl() + id,params);
    }

    static async delete(id) {
        return await SelfMeasuring.getAxiosInstance().delete(SelfMeasuring.getFullApiUrl() + id);
    }
    
    static async list(params) {
        let url = SelfMeasuring.getFullApiUrl() + "list";

        return SelfMeasuring.getAxiosInstance().get(url,{params: params});
    }

    static async saveAttachments(params, id){
        return await SelfMeasuring.getAxiosInstance().post(SelfMeasuring.getFullApiUrl() + 'attachments/' + id,params);
    }
    
    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/self-measuring/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/self-measuring/";
    }
}
