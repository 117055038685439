<template>
    <v-card class="elevation-0 ma-0 pa-0">
        <v-card-text class="ma-0 pa-0">
            <v-expansion-panels flat>
                <v-expansion-panel class="mt-4" v-for="(item, index) in chapter.classes" :key="index">
                    <v-expansion-panel-header :class="$root.$vuetify.theme.isDark ? 'primary' : ''">
                        <template v-slot:actions>
                            <span class="mt-1">{{chapterCost(item)}}</span>
                            <v-btn fab class="primary mt-n1 ml-5" x-small>
                                <v-icon class="icon" color="white">$expand</v-icon>
                            </v-btn>
                        </template>
                        <v-row
                            no-gutters
                            @click.stop=''
                            class="mt-n5"
                        >
                            <v-col cols="12" md="12" class="font-weight-bold">
                                <span class="font-weight-bold text-body-1">{{chapter_index}}.{{index+1}}. {{ item.description}}</span>
                                <span v-if="!lock_budget">
                                    <IconRemove
                                        v-if="$root.session.hasPermission(['super', 'budget_work.delete'])"
                                        @on-submit="deleteClass(item)"
                                    />
                                </span>                              
                            </v-col>
                            <v-col cols="12" md="auto" v-if="!canHaveMargin && !lock_k_fields">
                                <v-checkbox
                                    v-model="item.add_chapter_class_margin"
                                    :label="$t('global_messages.add_margin')"
                                    @change="changeChapterClassMargin(item)"
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="2" class="ml-4" v-if="!canHaveMargin && !lock_k_fields">
                                <v-text-field
                                    v-if="item.add_chapter_class_margin"
                                    :disabled="lock_budget"
                                    v-model="item.chapter_class_margin"
                                    prepend-inner-icon="mdi-percent"
                                    :label="$t('globals.margin')"
                                    clearable
                                    @change="calculateClassMargin()"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-divider></v-divider>
                    <v-expansion-panel-content style="width: 100% !important;" class="ma-0 pa-0">
                                <UnitsClasses class="ma-0 pa-0" :classe_index="chapter_index + '.' + (index+1)" :classe_margin="item.chapter_class_margin+''" @add-chapter-class="addChapterClass = true" @price-change="priceChange" @add_unit="addNewUnit" :unit_class="item" :fields="fields" :lock_budget="lock_budget" return-object/>  
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
         
        </v-card-text>
        <v-col cols="12" md="auto" align="end" v-if="false">
            <v-btn
                v-if="!lock_budget"
                color="success"
                @click="addChapterClass = true"
                small
            >
                <v-icon>mdi-plus</v-icon>{{$t('globals.chapter_class')}}
            </v-btn>
        </v-col>

        <v-dialog
            v-model="addChapterClass"
            max-width="800px"
            >
            

            <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">                                        
                <v-toolbar :class="$root.$vuetify.theme.isDark ? 'secondary' : 'primary white--text'">
                    {{$t('globals.chapter')}}
                </v-toolbar>
                <v-card-text>
                    <v-row class="mt-2">
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="chapter_class.id"
                                :items="computeClasses(chapter)"
                                :item-text="item => item.description"
                                item-value="id"
                                menu-props="auto"
                                :label="$t('globals.chapter_class')"
                                prepend-inner-icon="mdi-account"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>                                            
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn :disabled="chapter_class.id == null" color="success" @click="addNewChapterClass">{{$t('globals.insert')}}</v-btn>
                    <v-btn color="error" right @click="addChapterClass = false">{{$t('globals.close')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


    </v-card>
</template>
<script>
import UnitsClasses from "@/components/budget/components/Units.vue"
import IconRemove from "@/components/ui/IconRemove"

export default{
    name:"Chapters",
    components:{
        UnitsClasses,
        IconRemove
    },
    props:{
        fields: Object,
        lock_budget: Boolean,
        chapter: Object,
        canHaveMargin: Boolean,
        chapter_margin: String,
        lock_k_fields: Boolean,
        chapter_index: String
    },
    data(){
        return{
            chapter_class: {
                id: null,
                total_cost: 0
            },
            addChapterClass: false,
            chapter_classes: [],
            reloadChapterCost:0
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        changeChapterClassMargin(item){
            if(item.add_chapter_class_margin == false)
                item.chapter_class_margin = null
                
            this.$emit('with-margin')
        },
        calculateClassMargin(){
            this.$forceUpdate();
        },
        deleteClass(item){
            let pos = -1
            let i = 0
            this.chapter.classes.forEach(element => {
                if(element.id == item.id){
                    pos = i
                }
                i++
            });

            this.chapter.classes.splice(pos, 1)
        },
        chapterCost(item){
            let total = 0;
            let margin = 1           

            item.units.forEach(element => {
                if(element.manual == false){
                    total = total + (parseFloat(element.qty) * parseFloat(element.cost))
                }else{
                    let totalCost = 0
                    element.budget_materials.forEach(ob => {
                        totalCost += (parseFloat(ob.cost) * parseFloat(ob.qty))
                    });

                    element.budget_materials_composite.forEach(ob => {
                        totalCost += (parseFloat(ob.cost) * parseFloat(ob.qty))
                    });

                    element.budget_machines.forEach(ob => {
                        totalCost += (parseFloat(ob.cost) * parseFloat(ob.hours))
                    });

                    element.budget_work_hand.forEach(ob => {
                        totalCost += (parseFloat(ob.cost) * parseFloat(ob.hours))
                    });
                    total = totalCost * element.qty
                    if(isNaN(total))
                        total = 0                    
                }
            });


            if(item.chapter_class_margin != null){
                margin = parseFloat(item.chapter_class_margin) / 100;
                let increment = total * margin;
                total = total + increment
            }

            let total_saver = total
            if(this.chapter_margin != null){
                item.chapter_class_margin = parseFloat(this.chapter_margin)
            }

            if(isNaN(total))
                total = total_saver

            if(isNaN(total))
                return '-'

            return this.$currency(total)
        },
        priceChange(price){
            this.chapter_class.total_cost = price
        },
        fillBaseData(){
            
        },
        addNewUnit(unit, unit_class){
            let pos = -1
            let i = 0;
            this.fields.chapters.forEach(element => {
                if(element.id == this.chapter.id){
                    pos = i;
                }
                i++
            });

            let class_pos = -1;
            let class_i = 0;

            this.fields.chapters[pos].classes.forEach(element => {
                if(element.id == unit_class.id){
                    class_pos = class_i
                }
                class_i++
            });
            
            this.fields.chapters[pos].classes[class_pos].units.push({
                id: unit.id,
                description: unit.code + ' - ' + unit.name,
                cost: unit.cost,
                unit_cost: unit.cost,
                total_cost: unit.total_cost,
                qty: unit.qty,
                unit_measure: unit.unit,
                code: unit.code,
                name: unit.name,
                long_description: unit.description,
                unit: unit.name,
                manual: false,
                budget_materials: [],
                budget_materials_composite: [],
                budget_machines: [],
                budget_work_hand: []
            })
        },
        addNewChapterClass(){
            let description = ''
            
            this.chapter.chapter_classes.forEach(element => {
                if(element.id == this.chapter_class.id){
                    description = element.description
                }
            });
            let pos = -1
            let i = 0;
            this.fields.chapters.forEach(element => {
                if(element.id == this.chapter.id){
                    pos = i;
                }
                i++
            });

            this.fields.chapters[pos].classes.push({
                id: this.chapter_class.id,
                description: description,
                units: [],
                chapter_class_margin: null,
                add_chapter_class_margin: false
            })
        },
        computeClasses(item){
            let used_chapters = []
            item.classes.forEach(element => {
                used_chapters.push(element.id)
            });
            
            
            let to_use = []
            item.chapter_classes.forEach(element => {
                if(!used_chapters.includes(element.id))
                    to_use.push(element)
            });

            return to_use
        }
    }
}
</script>