<template>
  <v-btn 
        v-bind="$attrs"
        v-on="$listeners"
        icon>
    <Tooltip
      v-if="!reverse"
      color="error"
      :text="$t('globals.remove')"
      top
    >
      <v-icon
        small
        color="error"
        @click.stop="openConfirmationDialog = true"
      >
        mdi-delete
      </v-icon>
    </Tooltip>

    <Tooltip
      v-else
      color="warning"
      :text="$t('globals.restore')"
      top
    >
      <v-icon
        small
        color="warning"
        v-bind="$attrs"
        v-on="$listeners"
        @click.stop="openConfirmationDialog = true"
      >
        mdi-backup-restore
      </v-icon>
    </Tooltip>
    
    <DialogConfirmation 
      v-if="openConfirmationDialog && !reverse" 
      :title="$t('global_messages.remove_title')"  
      type="warning" 
      :opened="openConfirmationDialog" 
      @on-submit="submit" 
      @on-cancel="openConfirmationDialog = false">
        {{$t('global_messages.are_you_sure_remove')}}
    </DialogConfirmation>

    <DialogConfirmation 
      v-else
      :title="$t('global_messages.restore_title')" 
      type="warning" 
      :opened="openConfirmationDialog" 
      @on-submit="submit" 
      @on-cancel="openConfirmationDialog = false">
      {{$t('global_messages.are_you_sure_restore')}}
    </DialogConfirmation>
  </v-btn>
</template>

<script>
  import DialogConfirmation from '@/components/ui/DialogConfirmation.vue';
  import Tooltip from '@/components/ui/Tooltip.vue';
  export default {
    name: 'uiIconRemove',
    components: {
      DialogConfirmation,
      Tooltip
    },
    props:{
      reverse:{
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        openConfirmationDialog: false,
      }
    },
    methods: {
      submit() {
        this.openConfirmationDialog = false
        this.$emit('on-submit')
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>