function currency_icon() {
    let coin = this.$store.state.coin
    
    if(coin == '€')
        return 'mdi-currency-eur';
    if(coin == 'R$')
        return 'mdi-currency-brl';

    return 'mdi-currency-eur'
}
  
  export default currency_icon