import BaseApi from "./BaseApi";

export default class Work extends BaseApi{
    construct() {}

    static async search(params) {
        return await Work.getAxiosInstance().get(Work.getFullApiUrl(),{params: params});
    }

    static async addMaterial(params){
        return await Work.getAxiosInstance().post(Work.getFullApiUrl() + 'material-work/add-material' , params);
    }  
    
    static async deleteMaterial(params){
        return await Work.getAxiosInstance().delete(Work.getFullApiUrl() + 'material-work/remove-material' , {params:params});
    }   

    static async searchImages(params){
        return await Work.getAxiosInstance().get(Work.getFullApiUrl() + 'images' ,{params: params});
    }

    static async setLocal(params) {
        return await Work.getAxiosInstance().post(Work.getFullApiUrl() + 'setLocal',params);
    }

    static async getMaterials(params){
        return await Work.getAxiosInstance().get(Work.getFullApiUrl() + 'material-work' ,{params: params});
    }

    static async getLocations(params) {
        let url = Work.getFullApiUrl() + "locations";

        return Work.getAxiosInstance().get(url,{params: params});
    }

    static async create(params) {
        return await Work.getAxiosInstance().post(Work.getFullApiUrl(),params);
    }

    static async comment(params) {
        return await Work.getAxiosInstance().post(Work.getFullApiUrl() + 'comment',params);
    }

    static async update(params, id) {
        return await Work.getAxiosInstance().put(Work.getFullApiUrl() + id,params);
    }

    static async delete(id) {
        return await Work.getAxiosInstance().delete(Work.getFullApiUrl() + id);
    }

    static async find(id) {
        return await Work.getAxiosInstance().get(Work.getFullApiUrl() + id);
    }

    static async list(params, tenant = null) {
        let url = Work.getFullApiUrl(tenant) + "list";

        return Work.getAxiosInstance().get(url,{params: params});
    }

    static async listStatus() {
        let url = Work.getFullApiUrl() + "status";

        return Work.getAxiosInstance().get(url);
    }

    static async start(id) {
        let url = Work.getFullApiUrl() + id + "/start";

        return Work.getAxiosInstance().post(url);
    }

    static async finish(id) {
        let url = Work.getFullApiUrl() + id + "/finish";

        return Work.getAxiosInstance().post(url);
    }

    static async exportEquipments(id, params) {
        let url = Work.getFullApiUrl() + id + "/equipments/export";

        return Work.getAxiosInstance().post(url, params);
    }

    static async createImage(params, id) {
        return await Work.getAxiosInstance().post(Work.getFullApiUrl() + 'image/add/' + id,params);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/works-settings/works/";
    }

    static getFullApiUrl(tenant = null){
        let domain = location.pathname.split('/')[1]
        if(tenant != null)
            domain = tenant
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/works-settings/works/";
    }
}