<template>
  <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
    <v-container>
      <validation-observer ref="form" v-slot="{ invalid }">
        <v-form @submit.prevent="submit"  class="mb-16">
          <v-progress-linear v-if="loading"
              indeterminate
              class="global-loader"
            ></v-progress-linear>
          <fieldset :disabled="loading">
            <v-row>
              <v-col cols="12" md="4">
                <validation-provider v-slot="{ errors }" vid="code" :name="$t('globals.code')" rules="required">
                  <v-text-field
                    v-model="fields.code"
                    prepend-inner-icon="mdi-barcode"
                    :label="$t('globals.code') + '*'"
                    clearable
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="4">
                <validation-provider v-slot="{ errors }" vid="name" :name="$t('globals.name')" rules="required">
                  <v-text-field
                    v-model="fields.name"
                    prepend-inner-icon="mdi-form-textbox"
                    :label="$t('globals.name') + '*'"
                    clearable
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="4">              
                <validation-provider v-slot="{ errors }" vid="value" :name="$t('globals.cost_hour')" rules="required|min_value:0">
                  <v-currency-field
                      v-model="fields.value"
                      :prepend-icon="$currency_icon()"
                      :label="$t('globals.cost_hour') + ' *'"
                      clearable
                      :error-messages="errors"
                    />
                  </validation-provider>         
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="auto">
                <v-switch
                  v-model="fields.control_diesel"
                  inset
                  :label="$t('machines.control_diesel')"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="auto">
                <v-switch
                  v-model="fields.control_adblue"
                  inset
                  :label="$t('machines.control_adblue')"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                  <v-row>
                    <v-col cols="12" md="4">
                    <v-checkbox
                      :color="$root.$vuetify.theme.isDark ? 'white' : 'primary'"
                      v-model="checkbox"
                      :label="$t('machines.add_company')"
                    ></v-checkbox>
                  </v-col>
                  <v-col>
                    <v-select
                      :disabled="!checkbox"
                      v-model="fields.id_company"
                      :items="companies"
                      :item-text="item => item.name"
                      item-value="id"
                      menu-props="auto"
                      :label="$t('globals.company') + ' *'"
                      prepend-inner-icon="mdi-factory"
                      clearable
                    ></v-select>
                  </v-col>
                  </v-row>
              </v-col>
            </v-row>
            <v-row class="mt-6" align="center" justify="space-around">
              <v-col align="center" justify="space-around">
                <v-btn :disabled="invalid" depressed :class="($root.$vuetify.theme.isDark ? 'white primary--text' : 'primary') + ' mr-5 ml-5 mb-5'" type="submit">
                  {{$t('globals.save')}}
                </v-btn>
              </v-col>
            </v-row>
          </fieldset>
        </v-form>
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            {{$t('machines.success')}}
        </DialogSuccess>
      </validation-observer>
    </v-container>
  </v-card>
</template>
<script>
import Machine from "@/api/Machine.js";
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Company from "@/api/Company.js";

export default {
  components: {
    DialogSuccess
  },
  props: {
   machine: Object,
   method: String,
   disabled: Boolean,
 },
 watch: { 
    machine: {
      immediate: true, 
      handler (val) {
        this.fields.theme = this.$themes[0];

        if(!val) {
          return;
        }

        let data = {...val};

        this.fields = data;

        if(data.company != null){
          this.fields.id_company = data.company.id
          this.checkbox = true
        }
      }
    },
  },
  beforeCreate(){
  },
  data: () => ({
    checkbox: false,
    companies: [],
    fields: {
      id: null,
      code: null,
      name:null,
      value:null,
      id_company: null,
      control_adblue: null,
      control_diesel: null
    },
    loading:false,
    error: {
      title: '',
      message: '',
    },
    success: false,
  }),
  mounted(){
    Company.list().then(({data}) => {
        this.companies = data;
      });
  },
  methods:{
    onSuccess() {
      if(this.fields.id != null) {
        this.success = false
        return
      }
      let domain = '/' + this.$session.get('domain')
      this.$router.push(domain + '/admin/works-settings/machines');
    },
    submit() {

      if(this.checkbox == false)
        this.fields.id_company = null

      this.$refs.form.validate().then((result) => {
        this.loading = true;
        
        if(!result){
          this.loading = false;
          return;
        }

        Machine[this.method](this.fields, this.fields.id).then(() => {

          this.success = true;

          this.loading = false;
          
        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
            }

            this.error.title = this.$t('globals.error') + " " + err.response.status;

            this.error.message = err.response.data.message;

          });
      })
    }
  },
  computed: {
    
  }
};
</script>
