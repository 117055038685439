<template>
    <section class="mt-16">
      <v-breadcrumbs
          v-if="this.$root.$vuetify.theme.isDark"
          large
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
        <v-breadcrumbs
          v-else
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-hard-hat</v-icon> <span>{{$t('subcontractors.crumb_edit')}}</span>
        </h1>
  
        <div class="mt-16">
          <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
  
          <template v-if="loading">
            <v-row>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row class="mt-6" align="center" justify="space-around">
              <v-skeleton-loader
                  type="button"
                ></v-skeleton-loader>
            </v-row>
          </template>
          <Subempreiteiro v-else method="update" @reload="reload" :openTab="openTab" :subempreiteiro="subempreiteiro" :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'"></Subempreiteiro>
        </div>
    </section>
  </template>
  <script>
  import Subempreiteiro from '@/components/works/subempreitadas/Subempreiteiro'
  import SubempreiteiroAPI from "@/api/Subempreiteiro.js";
  
  export default {
    components: {
        Subempreiteiro
    },
    beforeCreate(){
      document.title = "Report Lake - " + this.$t('subcontractors.crumb_edit');
    },
    data: () => ({
        subempreiteiro: {},
        loading:true,
        openTab: 0
    }),
    mounted(){
      if(!this.$root.session.hasPermission(["super", "subempreiteiros.update"])) {
        this.$router.push('/admin');
      }
  
      SubempreiteiroAPI.find(this.$route.params.id).then(({data}) => { this.subempreiteiro = data; this.loading = false;});
    },
    methods:{
      reload(tab){
        this.loading = true
        if(tab != undefined || tab != null)
          this.openTab = tab
  
          SubempreiteiroAPI.find(this.$route.params.id).then(({data}) => { this.subempreiteiro = data; this.loading = false;});
      }
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: this.$t('menu.dashboard'),
            disabled: false,
            to: '/' + this.$session.get('domain') + '/admin',
            exact: true,
          },
          {
            text: this.$t('subcontractors.crumb_management'),
            disabled: false,
            to: '/' + this.$session.get('domain') + '/admin/works-settings/subempreiteiros',
            exact: true,
          },
          {
            text: this.$t('subcontractors.crumb_edit'),
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  