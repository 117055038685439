var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{class:!_vm.subempreiteiro ? 'elevation-10 d-none' : 'elevation-10',attrs:{"background-color":_vm.$root.$vuetify.theme.isDark ? 'secondary' : 'primary',"dark":"","fixed-tabs":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(_vm._s(_vm.$t('subcontractors.tabs.detail')))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t('subcontractors.tabs.effected'))+" "+_vm._s(_vm.getTotalDone))])],1),_c('v-tabs-items',{class:(_vm.$root.$vuetify.theme.isDark ? 'secondary' : '') + ' elevation-10',attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-container',{staticClass:"mb-n12"},[_c('v-tab-item',{key:1,staticClass:"mt-6"},[_c('v-card',{class:_vm.$root.$vuetify.theme.isDark ? 'secondary elevation-0' : 'elevation-0'},[_c('v-container',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"mb-16",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.loading)?_c('v-progress-linear',{staticClass:"global-loader",attrs:{"indeterminate":""}}):_vm._e(),_c('fieldset',{attrs:{"disabled":_vm.loading}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"vid":"nome","name":_vm.$t('globals.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":_vm.$t('globals.name') + '*',"clearable":"","error-messages":errors},model:{value:(_vm.fields.name),callback:function ($$v) {_vm.$set(_vm.fields, "name", $$v)},expression:"fields.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"email","name":_vm.$t('globals.email'),"rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-at","label":_vm.$t('globals.email')+  '*',"clearable":"","error-messages":errors},model:{value:(_vm.fields.email),callback:function ($$v) {_vm.$set(_vm.fields, "email", $$v)},expression:"fields.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"telephone","name":_vm.$t('globals.contact'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-phone","label":_vm.$t('globals.contact'),"clearable":"","error-messages":errors},model:{value:(_vm.fields.telephone),callback:function ($$v) {_vm.$set(_vm.fields, "telephone", $$v)},expression:"fields.telephone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"nif","name":_vm.$t('globals.nif'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-barcode","label":_vm.$t('globals.nif') + '*',"clearable":"","error-messages":errors},model:{value:(_vm.fields.nif),callback:function ($$v) {_vm.$set(_vm.fields, "nif", $$v)},expression:"fields.nif"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"iban","name":_vm.$t('globals.iban'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-barcode","label":_vm.$t('globals.iban') + '*',"clearable":"","error-messages":errors},model:{value:(_vm.fields.iban),callback:function ($$v) {_vm.$set(_vm.fields, "iban", $$v)},expression:"fields.iban"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":_vm.$t('globals.obs')},model:{value:(_vm.fields.notes),callback:function ($$v) {_vm.$set(_vm.fields, "notes", $$v)},expression:"fields.notes"}})],1)],1),_c('v-row',{staticClass:"mt-6",attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{class:(_vm.$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary') + ' mr-5 ml-5 mb-5',attrs:{"disabled":invalid,"depressed":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('globals.save'))+" ")])],1)],1)],1)],1),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":_vm.onSuccess}},[_vm._v(" "+_vm._s(_vm.$t('subcontractors.save_message'))+" ")])]}}])})],1)],1)],1),_c('v-tab-item',{key:2,staticClass:"mt-6"},[_c('v-container',{staticClass:"mb-14",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.$t('subcontractors.tabs.effected')))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('WorksCarriedOut')],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }