import BaseApi from "./BaseApi";

export default class MaterialsComposite extends BaseApi{
    construct() {}
    
 
    static async search(params) {
        return await MaterialsComposite.getAxiosInstance().get(MaterialsComposite.getFullApiUrl(),{params: params});
    }

    static async create(params) {
        return await MaterialsComposite.getAxiosInstance().post(MaterialsComposite.getFullApiUrl(),params);
    }

    static async find(id) {
        return await MaterialsComposite.getAxiosInstance().get(MaterialsComposite.getFullApiUrl() + id);
    }

    static async update(params, id) {
        return await MaterialsComposite.getAxiosInstance().put(MaterialsComposite.getFullApiUrl() + id,params);
    }

    static async delete(id) {
        return await MaterialsComposite.getAxiosInstance().delete(MaterialsComposite.getFullApiUrl() + id);
    }

    static async deletePiece(id) {
        return await MaterialsComposite.getAxiosInstance().delete(MaterialsComposite.getFullApiUrl() + 'piece/' + id);
    }
    
    static async list(params) {
        let url = MaterialsComposite.getFullApiUrl() + "list";

        return MaterialsComposite.getAxiosInstance().get(url,{params: params});
    }
    
    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/materials-composite/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/materials-composite/";
    }
}
