import BaseApi from "./BaseApi";

export default class Comments extends BaseApi{
    construct() {}
    
 
    static async search(params) {
        return await Comments.getAxiosInstance().get(Comments.getFullApiUrl(),{params: params});
    }

    static async create(params) {
        return await Comments.getAxiosInstance().post(Comments.getFullApiUrl(),params);
    }

    static async find(id) {
        return await Comments.getAxiosInstance().get(Comments.getFullApiUrl() + id);
    }

    static async update(params, id) {
        return await Comments.getAxiosInstance().post(Comments.getFullApiUrl() + id,params);
    }

    static async delete(id) {
        return await Comments.getAxiosInstance().delete(Comments.getFullApiUrl() + id);
    }
    
    static async list(params) {
        let url = Comments.getFullApiUrl() + "list";

        return Comments.getAxiosInstance().get(url,{params: params});
    }
    
    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/comments/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/comments/";
    }
}
