<template>
    <v-card class="elevation-0 ma-0 pa-0">
        <v-card-text class="ma-0 pa-0">
            <v-expansion-panels flat>
                <v-expansion-panel class="mt-4" v-for="(item, index) in unit_class.units" :key="index">
                    <v-expansion-panel-header :class="$root.$vuetify.theme.isDark ? 'primary' : ''">
                        <template v-slot:actions>
                            <v-btn fab class="primary mt-n10" x-small>
                                <v-icon color="white">
                                $expand
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-row no-gutters @click.stop=""
                            class="mt-n6">
                            <v-col cols="12">
                                <span class="text-body-1 font-weight-light">{{classe_index}}.{{index+1}}. {{ item.unit }} ({{$t('globals.code')}}: {{item.code}})</span><br/>
                                <span class="text-caption font-weight-light">{{ item.long_description }}</span><br/>
                                <div v-if="false" class="text-right">
                                    <span>{{ !item.manual ? unitCostTotal(item.qty * item.cost) : unitCostTotal(calculateUnitTotal(item)) }}</span>
                                </div> 
                            </v-col>
                            <v-col
                                cols="12" md="12"
                                class="text--secondary mt-4"
                            >
                                <v-fade-transition leave-absolute>
                                <v-row
                                    style="width: 100%"
                                >
                                <v-dialog
                                            v-if="editorModal"
                                            v-model="editorModal"
                                            max-width="600px"
                                            scrollable
                                        >
                                    <ModalTextArea 
                                                    return-object
                                                    vid="editor_local" 
                                                    :title="$t('globals.free_text')" 
                                                    :label="$t('globals.free_text')" 
                                                    v-model="localToEdit.local"/>
                                    </v-dialog>
                                    <v-col cols="12" md="2">
                                        <v-text-field dense disabled outlined :value="singleUnitCost(item)" :label="$t('globals.cost')"/>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field dense disabled outlined v-model="item.unit_measure" :label="$t('globals.unit')"/>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field dense disabled outlined v-model="item.qty" :label="$t('globals.quantity')"/>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field dense disabled outlined :value="totalCostCalc(item)" :label="$t('globals.total_cost')"/>
                                    </v-col>
                                    <v-col cols="12" md="4">                                        
                                        <v-text-field dense outlined v-model="item.local" label="Local" @click="localToEdit = item; editorModal = true;"/>
                                    </v-col>
                                </v-row>
                                </v-fade-transition>
                            </v-col>
                            </v-row>
                                
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-checkbox
                                v-model="item.manual"
                                :label="$t('budgeting.manual_calculation')"
                                color="red"
                                hide-details
                                dense
                                @change="calculateNewUnitCosts(item)"
                            ></v-checkbox>
                            <v-spacer></v-spacer>
                            <div v-if="!lock_budget">
                                <IconRemove
                                    v-if="$root.session.hasPermission(['super', 'budget_work.delete'])"
                                    @on-submit="deleteBudgetUnit(item)"
                                />
                            </div>
                        </v-row>
                        <v-divider class="mt-2"></v-divider>
                        <v-row class="mt-4">
                            <v-col cols="12" md="3">
                                <v-text-field
                                    v-model="item.qty"
                                    type="number"
                                    outlined dense
                                    prepend-inner-icon="mdi-account-details"
                                    @change="calculateNewUnitCosts(item)"
                                    :label="$t('globals.quantity')"
                                    clearable
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3" v-if="!item.manual">
                                <v-text-field
                                    v-model="item.cost"
                                    outlined dense
                                    prepend-inner-icon="mdi-account-details"
                                    :label="$t('globals.cost') + ' (' + $store.state.coin + ')'"
                                    @change="calculateNewUnitCosts(item)"
                                    clearable
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3" v-if="!item.manual">
                                <v-text-field
                                    :disabled="true"
                                    outlined dense
                                    v-model="item.total_cost"
                                    prepend-inner-icon="mdi-account-details"
                                    :label="$t('globals.total_cost')"
                                    clearable
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <div v-if="item.manual">
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- MateriaisS SIMPLES -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->                                                
                            <v-expansion-panels flat>
                                <v-expansion-panel class="mt-4">
                                    <v-divider></v-divider>
                                    <v-expansion-panel-header :class="($root.$vuetify.theme.isDark ? 'primary' : '')">
                                        <template v-slot:actions>
                                            <v-icon>
                                            $expand
                                            </v-icon>
                                        </template>
                                        <span class="elevation-0 text-h6">{{ item.budget_materials.length }} - {{$t('budgeting.material_singular_plural')}}<span class="ml-4"><small>({{ priceQtyTotal(item.budget_materials) }})</small></span></span>                           
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content :class="($root.$vuetify.theme.isDark ? 'secondary' : '')">
                                
                                        <v-card-text>
                                        <v-dialog
                                            v-model="addMaterial"
                                            max-width="800px"
                                            >
                                            <v-toolbar class="primary white--text">{{$t('globals.material')}}</v-toolbar>
                                            <template v-slot:activator="{ props }">
                                                <div class="text-right mt-4">                                                                        
                                                <Tooltip
                                                    color="warning"
                                                    :text="$t('budgeting.add_new_material')"
                                                    bottom
                                                >
                                                    <v-btn
                                                        v-if="!lock_budget"
                                                        class="mx-2"
                                                        fab
                                                        dark
                                                        large
                                                        color="warning"
                                                        @click="addMaterial = true; choosedUnit = item;"
                                                        v-bind="props"
                                                        >
                                                        <v-icon dark>
                                                            mdi-plus
                                                        </v-icon>
                                                    </v-btn>
                                                </Tooltip>
                                                </div>
                                            </template>

                                            <v-card>
                                                <v-card-text>
                                                    <v-col cols="12">
                                                        <v-autocomplete
                                                            v-model="newMaterial.id"
                                                            :items="materials"
                                                            :item-text="item => item.name"
                                                            item-value="id"
                                                            menu-props="auto"
                                                            :label="$t('globals.material')"
                                                            prepend-inner-icon="mdi-account"
                                                        ></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" md="4">
                                                        <v-text-field
                                                            v-model="newMaterial.qty"
                                                            type="number"
                                                            prepend-inner-icon="mdi-account-details"
                                                            :label="$t('globals.quantity')"
                                                            clearable
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-card-text>
                                                <v-card-actions class="justify-center">
                                                <v-btn :disabled="newMaterial.id == null || newMaterial.qty==null" color="success" @click="addNewMaterial()">{{$t('globals.insert')}}</v-btn>
                                                <v-btn color="error" right @click="addMaterial = false">{{$t('globals.close')}}</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                        <v-col cols="12" xs="12" sm="12" md="12" v-if="item.budget_materials.length > 0">
                                            <!-- DESKTOP VERSION -->
                                            <v-simple-table dense class="d-none d-lg-block">
                                            <template v-slot:default>
                                                <thead>
                                                <tr>
                                                    <th class="text-center" width="50%">
                                                        {{$t('globals.material')}}
                                                    </th>
                                                    <th class="text-center" width="5%">
                                                        {{$t('globals.quantity')}}
                                                    </th>
                                                    <th class="text-center" width="20%">
                                                        {{$t('globals.cost')}}
                                                    </th>
                                                    <th class="text-center" width="20%">
                                                        {{$t('globals.total_cost')}}
                                                    </th>
                                                    <th class="text-center" width="5%">
                                                        {{$t('globals.options')}}
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody :class="$root.$vuetify.theme.isDark ?  'secondary' : ''">
                                                <tr v-for="(element, index) in item.budget_materials" v-bind:key="index + 10000">
                                                    <td class="text-center">
                                                        <label>{{element.material}}</label>
                                                    </td>
                                                    <td class="text-center">
                                                        <label>{{element.qty}}</label>
                                                    </td>
                                                    <td class="text-center">
                                                        <v-text-field 
                                                            class="centered-input"
                                                            :append-icon="$currency_icon()"
                                                            single-line type="number" flat v-model="element.cost"

                                                        ></v-text-field>
                                                    </td>
                                                    <td class="text-center">
                                                        <label>{{ $currency(element.cost * element.qty) }}</label>
                                                    </td>
                                                    <td class="text-center">
                                                    <v-row>
                                                        <v-col cols="12" md="3" v-if="!lock_budget">                  
                                                            <IconRemove
                                                                v-if="$root.session.hasPermission(['super', 'budget_work.delete'])"
                                                                @on-submit="deleteMaterial(item, element)"
                                                            />
                                                        </v-col>
                                                    </v-row>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </template>
                                            </v-simple-table>
                                            <!-- DESKTOP VERSION -->
                                        </v-col>
                                        <v-col cols="9" xs="9" sm="9" md="12" v-else class="text-center">{{$t('works.no_data')}}</v-col>

                                        </v-card-text>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>

                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- Materiais COMPOSTOS -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->      
                            <v-expansion-panels flat>
                                <v-expansion-panel>
                                    <v-divider></v-divider>
                                    <v-expansion-panel-header :class="($root.$vuetify.theme.isDark ? 'primary' : '')">
                                        <template v-slot:actions>
                                            <v-icon>
                                            $expand
                                            </v-icon>
                                        </template>
                                        <span class="elevation-0 text-h6">{{ item.budget_materials_composite.length }} - {{$t('globals.materials_composite')}}<span class="ml-4"><small>({{ priceQtyTotal(item.budget_materials_composite) }})</small></span></span>                    
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content :class="($root.$vuetify.theme.isDark ? 'secondary' : '')">
                                
                                        <v-card-text>
                                        <v-dialog
                                            v-model="addMaterialComposite"
                                            max-width="800px"
                                            >
                                            <v-toolbar class="primary white--text">{{$t('globals.material_composite')}}</v-toolbar>
                                            <template v-slot:activator="{ props }">
                                                <div class="text-right mt-4">
                                                <Tooltip
                                                    color="warning"
                                                    :text="$t('budgeting.add_new_composite')"
                                                    bottom
                                                >
                                                    <v-btn                                                                        
                                                        v-if="!lock_budget"
                                                        class="mx-2"
                                                        fab
                                                        dark
                                                        large
                                                        color="warning"
                                                        @click="addMaterialComposite = true; choosedUnit = item;"
                                                        v-bind="props"
                                                        >
                                                        <v-icon dark>
                                                            mdi-plus
                                                        </v-icon>
                                                    </v-btn>
                                                </Tooltip>
                                                </div>
                                            </template>

                                            <v-card>
                                                <v-card-text>
                                                    <v-col cols="12">
                                                        <v-autocomplete
                                                            v-model="newMaterialComposite.id"
                                                            :items="materials_composite"
                                                            :item-text="item => item.name"
                                                            item-value="id"
                                                            menu-props="auto"
                                                            :label="$t('globals.material_composite')"
                                                            prepend-inner-icon="mdi-account"
                                                        ></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" md="4">
                                                        <v-text-field
                                                            v-model="newMaterialComposite.qty"
                                                            type="number"
                                                            prepend-inner-icon="mdi-account-details"
                                                            :label="$t('globals.quantity')"
                                                            clearable
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-card-text>
                                                <v-card-actions class="justify-center">
                                                <v-btn :disabled="newMaterialComposite.id == null || newMaterialComposite.qty==null" color="success" @click="addNewMaterialComposite(item)">{{$t('globals.insert')}}</v-btn>
                                                <v-btn color="error" right @click="addMaterialComposite = false">{{$t('globals.close')}}</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                        <v-col cols="12" xs="12" sm="12" md="12" v-if="item.budget_materials_composite.length > 0">
                                            <!-- DESKTOP VERSION -->
                                            <v-simple-table dense class="d-none d-lg-block">
                                                <template v-slot:default>
                                                    <thead>
                                                    <tr>
                                                        <th class="text-center" width="50%">
                                                            {{$t('globals.material_composite')}}
                                                        </th>
                                                        <th class="text-center" width="5%">
                                                            {{$t('globals.quantity')}}
                                                        </th>
                                                        <th class="text-center" width="20%">
                                                            {{$t('globals.cost')}}
                                                        </th>
                                                        <th class="text-center" width="20%">
                                                            {{$t('globals.total_cost')}}
                                                        </th>
                                                        <th class="text-center" width="5%">
                                                            {{$t('globals.options')}}
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody :class="$root.$vuetify.theme.isDark ?  'secondary' : ''">
                                                    <tr v-for="(element, index) in item.budget_materials_composite" v-bind:key="index + 30000">
                                                        <td class="text-center">
                                                            <label>{{element.material}}</label>
                                                        </td>
                                                        <td class="text-center">
                                                            <label>{{element.qty}}</label>
                                                        </td>
                                                        <td class="text-center">
                                                            <v-text-field 
                                                                class="centered-input"
                                                                :append-icon="$currency_icon()"
                                                                single-line type="number" flat v-model="element.cost"

                                                            ></v-text-field>
                                                        </td>
                                                        <td class="text-center">
                                                            <label>{{ $currency(element.cost * element.qty) }}</label>
                                                        </td>
                                                        <td class="text-center">
                                                        <v-row>
                                                            <v-col cols="12" md="3" v-if="!lock_budget">                  
                                                                <IconRemove
                                                                    v-if="$root.session.hasPermission(['super', 'budget_work.delete'])"
                                                                    @on-submit="deleteMaterialComposite(item, element)"
                                                                />
                                                            </v-col>
                                                        </v-row>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                            <!-- DESKTOP VERSION -->
                                        </v-col>                                                            
                                        <v-col cols="9" xs="9" sm="9" md="12" v-else class="text-center">{{$t('works.no_data')}}</v-col>

                                        </v-card-text>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>



                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!--     MÁQUINAS    -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->      
                            <v-expansion-panels flat>
                                <v-expansion-panel>
                                    <v-divider></v-divider>
                                    <v-expansion-panel-header :class="($root.$vuetify.theme.isDark ? 'primary' : '')">
                                        <template v-slot:actions>
                                            <v-icon>
                                            $expand
                                            </v-icon>
                                        </template>
                                        <span class="elevation-0 text-h6">{{ item.budget_machines.length }} - {{$t('budgeting.machines_singular_plural')}}<span class="ml-4"><small>({{ priceHoursTotal(item.budget_machines) }})</small></span></span>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content :class="($root.$vuetify.theme.isDark ? 'secondary' : '')">
                                
                                        <v-card-text>
                                        <v-dialog
                                            v-model="addMachine"
                                            max-width="800px"
                                            >
                                            <v-toolbar class="primary white--text">{{$t('globals.machines')}}</v-toolbar>
                                            <template v-slot:activator="{ props }">
                                                <div class="text-right mt-4">
                                                <Tooltip
                                                    color="warning"
                                                    text="Adicionar nova máquina"
                                                    bottom
                                                >
                                                    <v-btn
                                                        v-if="!lock_budget"
                                                        class="mx-2"
                                                        fab
                                                        dark
                                                        large
                                                        color="warning"
                                                        @click="addMachine = true; choosedUnit = item;"
                                                        v-bind="props"
                                                        >
                                                        <v-icon dark>
                                                            mdi-plus
                                                        </v-icon>
                                                    </v-btn>
                                                </Tooltip>
                                                </div>
                                            </template>

                                            <v-card>
                                                <v-card-text>
                                                    <v-col cols="12">
                                                        <v-autocomplete
                                                            v-model="newMachine.id"
                                                            :items="machines"
                                                            :item-text="item => item.code + ' - ' + item.name"
                                                            item-value="id"
                                                            menu-props="auto"
                                                            :label="$t('globals.machines')"
                                                            prepend-inner-icon="mdi-account"
                                                        ></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" md="4">
                                                        <v-text-field
                                                            v-model="newMachine.hours"
                                                            prepend-inner-icon="mdi-account-details"
                                                            type="number"
                                                            :label="$t('globals.hours')"
                                                            clearable
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-card-text>
                                                <v-card-actions class="justify-center">
                                                <v-btn :disabled="newMachine.id == null || newMachine.hours==null" color="success" @click="addBudgetMachine()">{{$t('globals.insert')}}</v-btn>
                                                <v-btn color="error" right @click="addMachine = false">{{$t('globals.close')}}</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                        <v-col cols="12" xs="12" sm="12" md="12" v-if="item.budget_machines.length > 0">
                                            <!-- DESKTOP VERSION -->
                                            <v-simple-table dense class="d-none d-lg-block">
                                            <template v-slot:default>
                                                <thead>
                                                <tr>
                                                    <th class="text-center" width="50%">
                                                        {{$t('globals.machine')}}
                                                    </th>
                                                    <th class="text-center" width="5%">
                                                        {{$t('globals.hours')}}
                                                    </th>
                                                    <th class="text-center" width="20%">
                                                        {{$t('globals.cost')}}
                                                    </th>
                                                    <th class="text-center" width="20%">
                                                        {{$t('globals.total_cost')}}
                                                    </th>
                                                    <th class="text-center" width="5%">
                                                        {{$t('globals.options')}}
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody :class="$root.$vuetify.theme.isDark ?  'secondary' : ''">
                                                <tr v-for="(element, index) in item.budget_machines" v-bind:key="index + 20000">
                                                    <td class="text-center">
                                                        <label>{{element.machine}}</label>
                                                    </td>
                                                    <td class="text-center">
                                                        <label>{{element.hours}}</label>
                                                    </td>
                                                    <td class="text-center">
                                                        <v-text-field 
                                                            class="centered-input"
                                                            :append-icon="$currency_icon()"
                                                            single-line type="number" flat v-model="element.cost"

                                                        ></v-text-field>
                                                    </td>
                                                    <td class="text-center">
                                                        <label>{{ $currency(element.cost * element.hours) }}</label>
                                                    </td>
                                                    <td class="text-center">
                                                    <v-row>
                                                        <v-col cols="12" md="3" v-if="!lock_budget">                  
                                                            <IconRemove
                                                                v-if="$root.session.hasPermission(['super', 'budget_work.delete'])"
                                                                @on-submit="deleteMachine(item, element)"
                                                            />
                                                        </v-col>
                                                    </v-row>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </template>
                                            </v-simple-table>
                                            <!-- DESKTOP VERSION -->
                                        </v-col>                                                            
                                        <v-col cols="9" xs="9" sm="9" md="12" v-else class="text-center">{{$t('works.no_data')}}</v-col>

                                        </v-card-text>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>

                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!--     Mão Obra    -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->
                            <!-- ||||||||||||||| -->      
                            <v-expansion-panels flat>
                                <v-expansion-panel>
                                    <v-divider></v-divider>
                                    <v-expansion-panel-header :class="($root.$vuetify.theme.isDark ? 'primary' : '')">
                                        <template v-slot:actions>
                                            <v-icon>
                                            $expand
                                            </v-icon>
                                        </template>
                                        <span class="elevation-0 text-h6">{{ item.budget_work_hand.length }} - {{$t('budgeting.work_hand_singular_plural')}}<span class="ml-4"><small>({{ priceHoursTotal(item.budget_work_hand) }})</small></span></span>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content :class="($root.$vuetify.theme.isDark ? 'secondary' : '')">
                                
                                        <v-card-text>
                                        <v-dialog
                                            v-model="addWorkHand"
                                            max-width="800px"
                                            >
                                            <v-toolbar class="primary white--text">{{$t('globals.work_hand')}}</v-toolbar>
                                            <template v-slot:activator="{ props }">
                                                <div class="text-right mt-4">
                                                <Tooltip
                                                    color="warning"
                                                    :text="$t('budgeting.add_work_hand')"
                                                    bottom
                                                >
                                                    <v-btn
                                                        v-if="!lock_budget"
                                                        class="mx-2"
                                                        fab
                                                        dark
                                                        large
                                                        color="warning"
                                                        @click="addWorkHand = true; choosedUnit = item;"
                                                        v-bind="props"
                                                        >
                                                        <v-icon dark>
                                                            mdi-plus
                                                        </v-icon>
                                                    </v-btn>
                                                </Tooltip>
                                                </div>
                                            </template>

                                            <v-card>
                                                <v-card-text>
                                                    <v-col cols="12">
                                                        <v-checkbox
                                                            v-model="newWorkHand.role"
                                                            :label="$t('budgeting.choose_category')"
                                                        ></v-checkbox>
                                                    </v-col>
                                                    <v-col cols="12" v-if="!newWorkHand.role">
                                                        <v-autocomplete
                                                            v-model="newWorkHand.id"
                                                            :items="workers"
                                                            :item-text="item => item.code + ' - ' + item.name"
                                                            item-value="id"
                                                            menu-props="auto"
                                                            :label="$t('globals.workers')"
                                                            prepend-inner-icon="mdi-account"
                                                        ></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" v-if="newWorkHand.role">
                                                        <v-autocomplete
                                                            v-model="newWorkHand.id"
                                                            :items="categories"
                                                            :item-text="item => item.name"
                                                            item-value="id"
                                                            menu-props="auto"
                                                            :label="$t('globals.categories')"
                                                            prepend-inner-icon="mdi-account"
                                                        ></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" md="4">
                                                        <v-text-field
                                                            v-model="newWorkHand.hours"
                                                            type="number"
                                                            prepend-inner-icon="mdi-account-details"
                                                            :label="$t('globals.hours')"
                                                            clearable
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-card-text>
                                                <v-card-actions class="justify-center">
                                                <v-btn :disabled="newWorkHand.id == null || newWorkHand.hours==null" color="success" @click="addBudgetWorkHand()">{{$t('globals.insert')}}</v-btn>
                                                <v-btn color="error" right @click="addWorkHand = false">{{$t('globals.close')}}</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                        <v-col cols="12" xs="12" sm="12" md="12" v-if="item.budget_work_hand.length > 0">
                                            <!-- DESKTOP VERSION -->
                                            <v-simple-table dense class="d-none d-lg-block">
                                            <template v-slot:default>
                                                <thead>
                                                <tr>
                                                    <th class="text-center" width="50%">
                                                    {{$t('globals.worker')}}
                                                    </th>
                                                    <th class="text-center" width="5%">
                                                    {{$t('globals.hours')}}
                                                    </th>
                                                    <th class="text-center" width="20%">
                                                    {{$t('globals.cost')}}
                                                    </th>
                                                    <th class="text-center" width="20%">
                                                    {{$t('globals.total_cost')}}
                                                    </th>
                                                    <th class="text-center" width="5%">
                                                    {{$t('globals.options')}}
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody :class="$root.$vuetify.theme.isDark ?  'secondary' : ''">
                                                <tr v-for="(element, index) in item.budget_work_hand" v-bind:key="index + 40000">
                                                    <td class="text-center">
                                                        <label>{{element.worker}}</label>
                                                    </td>
                                                    <td class="text-center">
                                                        <label>{{element.hours}}</label>
                                                    </td>
                                                    <td class="text-center">
                                                        <v-text-field 
                                                            class="centered-input"
                                                            :append-icon="$currency_icon()"
                                                            single-line type="number" flat v-model="element.cost"

                                                        ></v-text-field>
                                                    </td>
                                                    <td class="text-center">
                                                        <label>{{ $currency(element.cost * element.hours) }}</label>
                                                    </td>
                                                    <td class="text-center">
                                                    <v-row>
                                                        <v-col cols="12" md="3" v-if="!lock_budget">                  
                                                            <IconRemove
                                                                v-if="$root.session.hasPermission(['super', 'budget_work.delete'])"
                                                                @on-submit="deleteWorkHand(item, element)"
                                                            />
                                                        </v-col>
                                                    </v-row>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </template>
                                            </v-simple-table>
                                            <!-- DESKTOP VERSION -->
                                        </v-col>
                                        <v-col cols="9" xs="9" sm="9" md="12" v-else class="text-center">{{$t('works.no_data')}}</v-col>

                                        </v-card-text>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>




                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>



        
          
        </v-card-text>
        <v-row class="mt-4" justify="end" v-if="false">
            <v-col cols="12" md="auto" align="end">
                <v-btn
                    v-if="!lock_budget"
                    color="primary"
                    @click="addUnitClass = true"
                    small
                >
                    <v-icon>mdi-plus</v-icon>{{$t('globals.unit')}}
                </v-btn>
            </v-col>
            <v-col cols="12" md="auto" align="end" v-if="false">
                    <v-btn
                        v-if="!lock_budget"
                        color="success"
                        @click="$emit('add-chapter-class')"
                        small
                    >
                        <v-icon>mdi-plus</v-icon>{{$t('globals.chapter_class')}}
                    </v-btn>
                </v-col>
        </v-row>
        <v-dialog
            v-model="addUnitClass"
            max-width="800px"
            >
            <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">                                        
                <v-toolbar :class="$root.$vuetify.theme.isDark ? 'secondary' : 'primary white--text'">
                            {{$t('globals.work_unit')}}
                        </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="unit.id"
                                :items="units"
                                :item-text="item => item.code + ' - ' + item.name"
                                item-value="id"
                                menu-props="auto"
                                :label="$t('globals.unit')"
                                @change="calculateNewUnitCosts"
                                prepend-inner-icon="mdi-account"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                :value="computeTextArea"
                                menu-props="auto"
                                :disabled="true"
                                :label="$t('globals.description')"
                                prepend-inner-icon="mdi-account"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field
                                v-model="unit.qty"
                                type="number"
                                prepend-inner-icon="mdi-account-details"
                                @change="calculateNewUnitCosts"
                                :label="$t('globals.quantity')"
                                clearable
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field
                                v-model="unit.unit"
                                :disabled="true"
                                prepend-inner-icon="mdi-account-details"
                                :label="$t('globals.unit_measure')"
                                clearable
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                :disabled="true"
                                v-model="unit.cost"
                                prepend-inner-icon="mdi-account-details"
                                :label="$t('globals.cost')"
                                clearable
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                :disabled="true"
                                v-model="unit.total_cost"
                                prepend-inner-icon="mdi-account-details"
                                :label="$t('globals.total_cost')"
                                clearable
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="justify-center">
                <v-btn :disabled="unit.id == null" color="success" @click="addNewUnit">{{$t('globals.insert')}}</v-btn>
                <v-btn color="error" right @click="addUnitClass = false">{{$t('globals.close')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-card>
</template>
<script>
import UnitAPI from "@/api/Unit.js"
import Materials from "@/api/Materials.js"
import Worker from "@/api/Worker.js"
import Machine from "@/api/Machine.js"
import Category from "@/api/Category.js"
import MaterialsComposite from "@/api/MaterialsComposite.js"
import IconRemove from "@/components/ui/IconRemove"
import Tooltip from '@/components/ui/Tooltip.vue';
import ModalTextArea from '@/components/ui/ModalTextArea.vue';

export default{
    name:"Chapters",
    components:{
        IconRemove,
        Tooltip,
        ModalTextArea
    },
    props:{
        fields: Object,
        lock_budget: Boolean,
        unit_class: Object,
        classe_margin: String,
        classe_index: String
    },
    data(){
        return{
            localToEdit: null,
            editorModal: false,
            addMaterial: false,
            addMaterialComposite: false,
            addMachine: false,
            addWorkHand: false,
            choosedUnit: null,
            newMaterial:{
                id: null,
                qty: null,
                cost: null
            },
            newMaterialComposite:{
                id: null, 
                qty: null,
                cost: null,
                pieces: null
            },
            unit: {
                id: null,
                qty: null,
                unit: null,
                description: null,
                cost: null,
                total_cost: null
            },
            newMachine:{
                id: null,
                hours: null,
                cost: null
            },
            newWorkHand:{
                id: null,
                hours: null,
                cost: null,
                role: false
            },
            addUnitClass: false,
            units: [],
            materials: [],
            materials_composite: [],
            machines: [],
            workers: [],
            categories: []
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        singleUnitCost(item){
            if(item.manual == false)
                return this.$currency(item.cost)

            let totalCost = 0
            item.budget_materials.forEach(element => {
                totalCost += (parseFloat(element.cost) * parseFloat(element.qty))
            });

            item.budget_materials_composite.forEach(element => {
                totalCost += (parseFloat(element.cost) * parseFloat(element.qty))
            });

            item.budget_machines.forEach(element => {
                totalCost += (parseFloat(element.cost) * parseFloat(element.hours))
            });

            item.budget_work_hand.forEach(element => {
                totalCost += (parseFloat(element.cost) * parseFloat(element.hours))
            });
            if(isNaN(totalCost))
                totalCost = 0;
                    
            return this.$currency(totalCost)
        },
        totalCostCalc(item){
            if(item.manual == false){
                return this.$currency(item.cost * item.qty)
            }else{
                let totalCost = 0
                item.budget_materials.forEach(element => {
                    totalCost += (parseFloat(element.cost) * parseFloat(element.qty)) * parseFloat(item.qty)
                });

                item.budget_materials_composite.forEach(element => {
                    totalCost += (parseFloat(element.cost) * parseFloat(element.qty)) * parseFloat(item.qty)
                });

                item.budget_machines.forEach(element => {
                    totalCost += (parseFloat(element.cost) * parseFloat(element.hours)) * parseFloat(item.qty)
                });

                item.budget_work_hand.forEach(element => {
                    totalCost += (parseFloat(element.cost) * parseFloat(element.hours)) * parseFloat(item.qty)
                });
                if(isNaN(totalCost))
                    totalCost = 0;
                    
                return this.$currency(totalCost)
            }
        },
        unitCostTotal(v){
            let v_saver = v
            if(this.classe_margin != null){
                let mar = this.classe_margin / 100
                let new_v = v * mar
                v = v + new_v
            }

            if(isNaN(v))
                v = v_saver
                
            return this.$currency(v)
        },
        calculateUnitTotal(item){
            let totalCost = 0
            if(!item.manual){
                item.budget_materials.forEach(element => {
                    totalCost += (parseFloat(element.cost) * parseFloat(element.qty)) * parseFloat(item.qty)
                });

                item.budget_materials_composite.forEach(element => {
                    totalCost += (parseFloat(element.cost) * parseFloat(element.qty)) * parseFloat(item.qty)
                });

                item.budget_machines.forEach(element => {
                    totalCost += (parseFloat(element.cost) * parseFloat(element.hours)) * parseFloat(item.qty)
                });

                item.budget_work_hand.forEach(element => {
                    totalCost += (parseFloat(element.cost) * parseFloat(element.hours)) * parseFloat(item.qty)
                });
            }else{
                totalCost = item.cost
            }

            if(totalCost == null || totalCost == 0 || totalCost == undefined){
                item.cost = 0
                return totalCost = '0 ' + this.$store.state.coin
            }


            item.cost = totalCost
            totalCost = totalCost * item.qty
            return totalCost
        },
        priceHoursTotal(prices){
            let total = 0
            prices.forEach(element => {
                total = total + (element.cost * element.hours)
            });

            if(total == null || total == 0 || total == undefined)
                return total = '0 ' + this.$store.state.coin
            return this.$currency(total)
        },
        priceQtyTotal(prices){
            let total = 0
            prices.forEach(element => {
                total = total + (element.cost * element.qty)
            });

            if(total == null || total == 0 || total == undefined)
                return total = '0 ' + this.$store.state.coin
            return this.$currency(total)
        },
        addNewMaterial(){
            let material = ''
            let cost = 0

            this.materials.forEach(element => {
                if(element.id == this.newMaterial.id){
                    material = element.name
                    cost = element.cost
                }
            });


            let material_object = {
                id: this.newMaterial.id,
                material: material,
                qty: this.newMaterial.qty,
                cost: cost
            }

            this.choosedUnit.budget_materials.push(material_object)

            this.newMaterial.id = null
            this.newMaterial.qty = null

            //this.calculateBudget()
        },
        addNewMaterialComposite(){
            let material = ''
            let cost = 0
            let pieces = []
            
            this.materials_composite.forEach(element => {
                if(element.id == this.newMaterialComposite.id){
                    material = element.name
                    cost = element.total_cost
                    pieces = element.pieces
                }
            });

            let materials_composite = {
                id: this.newMaterialComposite.id,
                material: material,
                qty: this.newMaterialComposite.qty,
                cost: cost,
                pieces: pieces
            }

            this.choosedUnit.budget_materials_composite.push(materials_composite)

            this.newMaterialComposite.id = null
            this.newMaterialComposite.qty = null

            //this.calculateBudget()
        },
        deleteBudgetUnit(item){
            let pos = -1;
            let i = 0;
            this.unit_class.units.forEach(element => {
                if(element.id == item.id){
                    pos = i
                }
                i++
            });

            this.unit_class.units.splice(pos, 1)
        },
        calculateNewUnitCosts(item = null){
            let cost = 0
            let unit = ''
            this.units.forEach(element => {
                if(element.id == this.unit.id){
                    cost = element.value
                    unit = element.unit
                }
            });

            this.unit.unit = unit

            this.unit.cost = cost

            if(typeof item == "string"){
                //this.calculateBudget()
                return
            }

            if(item == null){
                if(this.unit.qty != null)
                    this.unit.total_cost = this.unit.qty * this.unit.cost
            }else{
                item.total_cost = item.qty * item.cost
            }

            //this.calculateBudget()
        },
        addBudgetMachine(){
            let machine = ''
            let cost = 0
            this.machines.forEach(element => {
                if(element.id == this.newMachine.id){                    
                    machine = element.name
                    cost = element.value
                }
            });

            let machine_object = {
                id: this.newMachine.id,
                machine: machine,
                hours: this.newMachine.hours,
                cost: cost
            }

            this.choosedUnit.budget_machines.push(machine_object)

            this.newMachine.id = null
            this.newMachine.hours = null

            //this.calculateBudget()
        },
        addBudgetWorkHand(){
            let worker = ''
            let cost = 0

            if(this.newWorkHand.role == true){
                this.categories.forEach(element => {
                    if(element.id == this.newWorkHand.id){                    
                        worker = element.name
                        cost = element.value
                    }
                });
            }else{
                this.workers.forEach(element => {
                    if(element.id == this.newWorkHand.id){                    
                        worker = element.name
                        cost = element.value
                    }
                });
            }
            

            let workHand_object = {
                id: this.newWorkHand.id,
                worker: worker,
                hours: this.newWorkHand.hours,
                cost: cost,
                role: this.newWorkHand.role
            }

            this.choosedUnit.budget_work_hand.push(workHand_object)

            this.newWorkHand.id = null
            this.newWorkHand.hours = null

            //this.calculateBudget()
        },
        fillBaseData(){
            let params = {
                class_id: this.unit_class.id
            }
            UnitAPI.list(params).then(({data}) => {
                this.units = data
            });
            Materials.list().then(({data}) => {
                this.materials = data
            });
            MaterialsComposite.list().then(({data}) => {
                this.materials_composite = data
            });
            Machine.list().then(({data}) => {
                this.machines = data
            });
            Worker.list().then(({data}) => {
                this.workers = data
            });
            Category.list().then(({data}) => {
                this.categories = data
            });
        },
        addNewUnit(){
            let ob = null
            this.units.forEach(element => {
                if(element.id == this.unit.id)
                    ob = element
            });

            console.log(ob)

            this.unit.id = ob.id
            this.unit.code = ob.code
            this.unit.name = ob.name
            this.unit.cost = ob.value
            this.unit.description = ob.description
            this.unit.total_cost = this.unit.qty * this.unit.cost
            



            this.$emit('add_unit', this.unit, this.unit_class)
        },
    },
    computed:{
        computeTextArea(){
            if(this.unit == null)
                return ''
            if(this.unit.id == null)
                return ''

            let des = ''
            
            this.units.forEach(element => {
                if(element.id == this.unit.id)
                    des = element.description
            });
            return des
        }
    }
}
</script>