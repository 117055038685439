<template>
    <v-card elevation="10" :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75);'" :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
          <v-tabs v-model="tab" :background-color="$root.$vuetify.theme.isDark ? 'secondary' : 'primary'" dark fixed-tabs>
            <v-tab>{{$t('globals.material')}}</v-tab>
            <v-tab :disabled="method == 'create'" v-if="$store.state.company_configurations.eticadata == null">{{$t('globals.localization')}}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" touchless :class="($root.$vuetify.theme.isDark ? 'secondary' : '') + ' elevation-0'">
            <v-container fluid>
              <v-tab-item class="mt-6" :key="1">
                <validation-observer ref="form" v-slot="{ invalid }">
                  <v-form @submit.prevent="submit"  class="mb-16">
                      <v-progress-linear v-if="loading"
                          indeterminate
                          class="global-loader"
                      ></v-progress-linear>
                      <fieldset :disabled="$store.state.company_configurations != null">                  
                        <v-card class="transparent elevation-0">
                          <v-row class="mt-2">
                              <v-col cols="12" md="3">
                                  <validation-provider v-slot="{ errors }" vid="reference" :name="$t('globals.reference')" rules="required">
                                      <v-text-field
                                      v-model="fields.reference"
                                      prepend-inner-icon="mdi-account-details"
                                      :label="$t('globals.reference') + ' *'"
                                      clearable
                                      :error-messages="errors"
                                      ></v-text-field>
                                  </validation-provider>
                              </v-col>
                              <v-col cols="12" md="9">
                                  <validation-provider v-slot="{ errors }" vid="name" :name="$t('globals.name')" rules="required">
                                      <v-text-field
                                      v-model="fields.name"
                                      prepend-inner-icon="mdi-account-details"
                                      :label="$t('globals.name') + ' *'"
                                      clearable
                                      :error-messages="errors"
                                      ></v-text-field>
                                  </validation-provider>
                              </v-col>
                              <v-col cols="12" md="12">
                                  <validation-provider v-slot="{ errors }" vid="description" :name="$t('globals.description')" rules="required">
                                      <v-textarea
                                      v-model="fields.description"
                                      prepend-inner-icon="mdi-account-details"
                                      :label="$t('globals.description') + ' *'"
                                      clearable
                                      :error-messages="errors"
                                      ></v-textarea>
                                  </validation-provider>
                              </v-col>
                              <v-col cols="12" md="2">
                                  <validation-provider v-slot="{ errors }" vid="unit" :name="$t('globals.unit')" rules="required">
                                    <v-select
                                          :disabled="$store.state.company_configurations.eticadata != null"
                                          v-model="fields.unit_management_id"
                                          :items="units"
                                          item-value="id"
                                          :item-text="item => item.abbreviation"
                                          menu-props="auto"
                                          :error-messages="errors"
                                          :label="$t('globals.unit') + ' *'"
                                          prepend-inner-icon="mdi-account"
                                      ></v-select>
                                  </validation-provider>
                              </v-col>
                              <v-col cols="12" md="1" v-if="$store.state.company_configurations.eticadata == null">
                                <Tooltip
                                    color="primary"
                                    :text="$t('shoppings.add_new_unit')"
                                    bottom
                                >
                                <v-btn :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary'" fab small @click="addUnit = true">
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                </Tooltip>
                              </v-col>
                              <v-col cols="12" md="3">
                                  <validation-provider v-slot="{ errors }" vid="cost" :name="$t('globals.cost')" rules="|">
                                    <v-text-field
                                      :disabled="true"
                                      v-model="fields.cost"
                                      :label="$t('globals.cost') + '(' + $store.state.coin + ')'"
                                      :prepend-inner-icon="$currency_icon()"
                                      clearable
                                      :error-messages="errors"
                                      ></v-text-field>
                                  </validation-provider>
                              </v-col>
                              <v-col cols="12" md="3" v-if="method == 'update'">
                                  <validation-provider v-slot="{ errors }" vid="stock" :name="$t('globals.total_warehouses')" rules="|">
                                    <v-text-field
                                      :disabled="true"
                                      v-model="fields.stock_warehouses"
                                      prepend-inner-icon="mdi-account-details"
                                      :label="$t('globals.total_warehouses')"
                                      clearable
                                      :error-messages="errors"
                                      ></v-text-field>
                                  </validation-provider>
                              </v-col>
                              <v-col cols="12" md="3" v-if="method == 'update'">
                                  <validation-provider v-slot="{ errors }" vid="stock" :name="$t('globals.total_material')" rules="|">
                                    <v-text-field
                                      :disabled="true"
                                      v-model="fields.main_warehouse"
                                      prepend-inner-icon="mdi-account-details"
                                      :label="$t('globals.total_material')"
                                      clearable
                                      :error-messages="errors"
                                      ></v-text-field>
                                  </validation-provider>
                              </v-col>
                          </v-row>
                          <v-row no-gutters justify="end" v-if="method == 'update' && $store.state.company_configurations == null">
                            <v-col cols="12" md="auto">
                              <StockUpdater @close-success="success = true; reloadInvoices++; $emit('refresh')" :material="fields"/>
                            </v-col>
                          </v-row>
                          <div v-if="method != 'create' && $store.state.company_configurations == null">
                            <v-card class="elevation-0 mt-4">
                              <v-card-title :class="$root.$vuetify.theme.isDark ? 'primary' : 'primary white--text'">
                                {{$t('globals.history')}}
                              </v-card-title>
                              <v-card-text>
                                <InvoicesList :key="reloadInvoices" place="materials"/>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-card>
                      
                      <v-row class="mt-6" align="center" justify="space-around" v-if="$store.state.company_configurations.eticadata == null">
                          <v-btn :disabled="invalid" depressed :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary'" type="submit">
                          {{$t('globals.save')}}
                          </v-btn>
                      </v-row>
                      </fieldset>
                  </v-form>
                  <DialogSuccess :opened="success" @on-ok="onSuccess" >
                      {{$t('shoppings.material_saved')}}
                  </DialogSuccess>
                  </validation-observer>
              </v-tab-item>
              <v-tab-item class="mt-6" :key="2" v-if="method != 'create'">
                <Localization @update-material="$emit('refresh')" :material="material"/>
              </v-tab-item>
            </v-container>
          </v-tabs-items>
        <v-dialog
          v-model="addUnit"
          max-width="800px"
        >
          <AddUnit 
            v-if="addUnit"
            @close="addUnit = false"
            @close-update="fillBaseData()"
          />
        </v-dialog>
    </v-card>
  </template>
  <script>
  import Materials from "@/api/Materials.js";
  import Shopping from "@/api/Shopping.js"
  import Company from "@/api/Company.js";
  import AddUnit from '@/components/units_management/Add.vue';
  import UnitsManagement from "@/api/UnitsManagement.js"
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  import Tooltip from '@/components/ui/Tooltip.vue';
  import InvoicesList from '@/components/shopping/InvoicesList.vue';
  import StockUpdater from '@/components/materials/StockUpdater.vue'
  import Localization from '@/components/materials/Local/Localization.vue'
  
  export default {
    name: 'MaterialComponent',
    components: {
      Localization,
      AddUnit,
      DialogSuccess,
      Tooltip,
      InvoicesList,
      StockUpdater
    },
    props: {
     material: Object,
     method: String,
     disabled: Boolean,
   },
   watch: { 
    material: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;
          if(this.fields.cost != null)
            this.fields.cost = this.fields.cost.toFixed(2)

          this.total = this.fields.invoices.length
  
          this.fields.id = data.id;
        }
      },
    },
    beforeCreate(){
    },
    data: () => ({
      tab: 0,
      reloadInvoices: 800,
      addUnit: false,
      pickerInicio: false, 
      addInvoice: false,
      options: {},
      total: 0,
      panel: 0,
      newInvoice:{
        qty:null,
        cost: null,
        total_price: null,
        invoice_date: null,
        file: null,
        supplier_id: null
      },
      fields: {
        id: null,
        descritpion: null,
        name: null,
        unit_management_id:null,
        cost: null,
        reference: null
      },
      loading:false,
      error: {
        title: '',
        message: '',
      },
      success: false,
      units:[],
      companies: [],
    }),
    mounted(){
      this.fillBaseData();
    },
    methods:{
      calculateCost(place){
        if(place == 'cost')
          this.newInvoice.total_price = (this.newInvoice.cost * this.newInvoice.qty).toFixed(2)
        else
          this.newInvoice.cost = (this.newInvoice.total_price / this.newInvoice.qty).toFixed(2)
        
      },
      getExtension(path){
        if(path == '' || path == null)
          return '-'
        return path.split('.')[1]
      },
      download(item){
        Shopping.downloadInvoice(item.id).then((response) => {
                let documentName =  "Fatura";

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", documentName + '.pdf');
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
      },
      deleteMaterialInvoice(item){
        Materials.deleteInvoice(item.id)
          .then(() => {
            this.$emit('refresh')
          });
      },
      addNewInvoice(){
        const formData = new FormData()
        formData.append('material_id', this.$route.params.id)
        if(this.newInvoice.file != null)
          formData.append('file', this.newInvoice.file)

        formData.append('qty',  this.newInvoice.qty)
        formData.append('supplier_id',  this.newInvoice.supplier_id)
        formData.append('cost', this.newInvoice.cost)
        formData.append('total_price', this.newInvoice.total_price)
        formData.append('invoice_date', this.newInvoice.invoice_date)

        this.fields.cost += this.newInvoice.cost

        Materials.addInvoice(formData, this.tenant).then(() => {
        
            this.success = true;

            this.loading = false;

            this.$emit('refresh')

        }).catch(err => {
            this.loading = false;

            if(err.response.status == 422) {

                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
            }

            this.error.title = this.$t('globals.error') + " " + err.response.status;

            this.error.message = err.response.data.message;

        });
      },
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        
        let domain = '/' + this.$session.get('domain')
        this.$router.push(domain + '/admin/budgeting/materials');
        

        
      },
      fillBaseData(){
        UnitsManagement.list().then(({data}) => {
            this.units = data
        });

        Company.list().then(({data}) => {
            this.companies = data
        });
      },
      submit() {
  
        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }
  
          let data = {...this.fields};
  
         
  
          Materials[this.method](data, this.fields.id).then(() => {
  
            this.success = true;
  
            this.loading = false;

            
          }).catch(err => {
            this.loading = false;
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = this.$t('globals.error') + " " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })
      }
    },
    computed: {    
      headers(){
        let he = [
          {
            text: this.$t('invoices.invoice_date'),
            sortable: true,
            value: 'shopping_date',
          },
          {
            text: this.$t('globals.quantity'),
            sortable: true,
            value: 'qty',
          },
          {
            text: this.$t('globals.unit_cost'),
            sortable: true,
            value: 'cost',
          },
          {
            text: this.$t('globals.total_cost'),
            sortable: true,
            value: 'total_price',
          },
          {
            text: this.$t('globals.supplier'),
            sortable: true,
            value: 'supplier'
          },
          { 
            text: this.$t('globals.options'), 
            value: 'actions', 
            sortable: false 
          },
        ]
        return he
      },
      tableFooter(){
          let t = this.$t("globals.lines_datatable")
          let a = [5, 10, 15, 20];
          return {
            'items-per-page-options': a,
            'items-per-page-text': t
          }
        }, 
    }
  };
  </script>
  