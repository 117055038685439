a<template>
    <section class="mt-16">
      <div v-if="work_id == null">
        <v-breadcrumbs
            v-if="this.$root.$vuetify.theme.isDark"
            large
            :items="crumbs"
            divider="/"
            light
            :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
          ></v-breadcrumbs>
          <v-breadcrumbs
            v-else
            :items="crumbs"
            divider="/"
            light
            :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
          ></v-breadcrumbs>
    
          <h1 class="primary--text">
            <rp-icon icon="rp-empresas-blue-filled" class="mr-2"/> <span>{{$t('menu.self_measuring')}}</span>
          </h1>
          <v-row>
            <v-col cols="12" md="auto">
              <rp-icon
                  icon="rp-eye-blue-filled"
                  class="mb-n2"
                /><v-icon class="ml-2" color="secondary" small>mdi-forward</v-icon>
                <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.visualize')}}</span>
            </v-col>
            <v-col cols="12" md="auto">
              <v-icon color="red" class="ml-4">
                mdi-delete
              </v-icon><v-icon class="ml-2" small color="secondary">mdi-forward</v-icon>
              <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.remove')}}</span>
            </v-col>
          </v-row>
        </div>
        
  
        
  
        <div style="position:relative">
          <v-container v-if="work_id == null">
            <v-speed-dial
              absolute
              v-model="speedDial"
              top
              right
              direction="bottom"
              :open-on-hover="true"
            >
              <template v-slot:activator>
                <v-tooltip right color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" v-on="on"
                      v-model="speedDial"
                      color="primary"
                      dark
                      x-large
                      fab
                    >
                      <v-icon v-if="speedDial">
                        mdi-close
                      </v-icon>
                      <v-icon v-else>
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('globals.options')}}</span>
                </v-tooltip>
              </template>
              <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="info"
                  large
                  @click="searching = true"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>{{$t('globals.search')}}</span>
            </v-tooltip>
              
            </v-speed-dial>
          </v-container>
  
          <v-data-table 
            :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'"
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
            locale="pt-pt"
          >
          <template v-slot:item.percentage_invoiced="{ item }">
            {{item.percentage_invoiced}}%
          </template>
          <template v-slot:item.invoiced="{ item }">
            {{$currency(item.invoiced)}}
          </template>

          <template v-slot:item.actions="{item}">
              <v-row>
                <v-col cols="12" md="auto"
                    v-if='$root.session.hasPermission(["super", "materials.update"])'>
                  <IconView
                    small
                    @click="viewSelfMeasure(item)"
                  />
  
                  
                  <IconRemove
                    v-if="false"
                    @on-submit="deleteSelfMeasure(item)"
                  />
                </v-col>
              </v-row>
            </template>
           
          </v-data-table>
        </div>
        <v-navigation-drawer
            fixed
            right
            v-model="searching"
            hide-overlay
            width="520"
            class="pt-6"
          >
          <v-list>
            <v-list-item>
              <v-btn fab small color="secondary" @click="searching = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
  
              <v-btn absolute right small color="error" @click="resetSelfMeasure">
                {{$t('globals.clean_fields')}}
              </v-btn>
            </v-list-item>
          </v-list>
          <v-list class="pt-10">
            <v-form @submit.prevent="searchSelfMeasure">
              
              <v-list-item>
                <v-autocomplete
                  v-model="filter.client_id"
                  item-value="id"
                  item-text="name"
                  :items="clients"
                  prepend-inner-icon="mdi-barcode"
                  :label="$t('globals.client')"
                  clearable
                ></v-autocomplete>
              </v-list-item>
              
              <v-list-item>
                <v-autocomplete
                  v-model="filter.work_id"
                  item-value="id"
                  :item-text="item => item.code + ' - ' + item.name"
                  :items="works"
                  prepend-inner-icon="mdi-hard-hat"
                  :label="$t('globals.work')"
                  clearable
                ></v-autocomplete>
              </v-list-item>
              
  
              <v-row
                class="mt-10"
                align="center"
                justify="space-around"
              >
              <v-btn
                color="primary"
                type="submit"
              >
              {{$t('globals.search')}}
              </v-btn>
              </v-row>
            </v-form>
          </v-list>
        </v-navigation-drawer>
  
        
        <ErrorSnackbar v-model="error.show" :message="error.message"/>
    </section>
  </template>
  <script>
  import IconRemove from '@/components/ui/IconRemove.vue';
  import IconView from '@/components/ui/IconView.vue';
  import SelfMeasuring from "@/api/SelfMeasuring.js";
  import Client from "@/api/Client.js";
  import Work from "@/api/Work.js";
  import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
  
  export default {
    components: {
      IconRemove,
      IconView,
      ErrorSnackbar,
    },
    props:{
      work_id:{
        typeof: String,
        default: null
      },
      client_id: {
        typeof: String,
        default: null
      }
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchSelfMeasure()}, 10);
        },
        deep: true,
      },
    },
    beforeCreate(){
      document.title = "Report Lake - " + this.$t('menu.self_measuring');
    },
    data: () => ({
      error: {
        title: '',
        message: '',
        show:false,
      },
      items: [],
      clients: [],
      total: 0,
      loading: false,
      searching: false,
      options: {},
      works: [],
      speedDial:false,
      filter: {
        client_id: null,
        work_id: null
      },
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "self_measuring.read"])) {
        this.$router.push('/admin');
      }

        
  
      this.fillBaseData();
    },
    methods:{
      deleteSelfMeasure(item){
        console.log(item)
      },
      fillBaseData() {
  
        if(localStorage["self-measure-search-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["self-measure-search-" + this.$root.session.id]);
  
          this.filter = data.filter;

          if(this.$route.params.client_id != null){
            if(this.$route.params.client_id != undefined)
              this.filter.client_id = this.$route.params.client_id
          }
  
          this.options = data.options;
        }

        Work.list().then(({data}) => {
          this.works = data
        }) 
  
        Client.list().then(({data}) => {
            this.clients = data;
        });
        
      },
      searchSelfMeasure(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        this.filter.client_id = this.client_id
        this.filter.work_id = this.work_id
        let filter = {...this.filter};

        console.log("AQUI")
        console.log(this.filter)
  
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          work_id: this.work_id,
        };
      
        Object.assign(request, filter);
  
        localStorage["self-measure-search-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        SelfMeasuring.search(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });
  
          this.loading = false;
  
        });
      },
      resetSelfMeasure()
      {
        this.filter = {
          is_active: 1
        };
  
        this.searchSelfMeasure();
      },
      viewSelfMeasure(item){
        let domain = '/' + this.$session.get('domain')
        let path = domain + '/admin/invoiced/self-measuring/' + item.work_id + '/' + item.id
  
  
        this.$router.push(path);
      }
    },
    computed: {
      headers(){
        let he = [          
          {
            text: this.$t('globals.work'),
            sortable: true,
            value: 'work.name'
          },
          {
            text: this.$t('globals.invoiced_percent'),
            sortable: true,
            value: 'percentage_invoiced'
          },
          {
            text: this.$t('shoppings.total_invoiced'),
            sortable: true,
            value: 'invoiced'
          },
          {
            text: this.$t('globals.data'),
            sortable: true,
            value: 'data'
          },
          
          { 
            text: this.$t('globals.options'),
            value: 'actions', 
            width: '200px',
            sortable: false 
          },
        ]
        return he
      },
      tableFooter(){
        let t = this.$t("globals.lines_datatable")
        let a = [5, 10, 15, 20];
        return {
          'items-per-page-options': a,
          'items-per-page-text': t
        }
      }, 
      crumbs: function() {
        return [
          {
            text: this.$t('menu.dashboard'),
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: this.$t('menu.self_measuring'),
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  