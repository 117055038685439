<template>
  
  <v-container>
      <!-- ROLES DTO E CEO -->
      <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
        <v-card-title>{{$t('special_dashboard.on_going_works')}}</v-card-title>
        <v-card-text>
          <v-row>
              <v-col cols="12" md="6">
                <Chart
                  v-if="worksPerformanceGraphSeriesR1[0].data.length > 0"
                  :key="worksPerformanceGraphKeyR1"
                  class="mt-16"
                  id="performance-work-chart"
                  :options="worksPerformanceGraphoOptionsR1"
                  :series="worksPerformanceGraphSeriesR1"
                  :height="calculateHeightL1"
                />
                <p v-else>{{$t('globals.without_information')}}</p>
              </v-col>
              <v-col cols="12" md="6">
                <Chart
                  v-if="workPreviewTimeSeries[0].data.length > 0"
                  :key="workPreviewTimeKey"
                  class="mt-16"
                  id="first-chart"
                  :options="workPreviewTimeOptions"
                  :series="workPreviewTimeSeries"
                  :height="calculateHeightL1"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card :class="$root.$vuetify.theme.isDark ? 'secondary mt-2' : 'mt-2'" v-if="$root.session.hasPermission(['super']) || $root.session.hasRole(['dto']) || $root.session.hasRole(['ceo'])">
        <v-card-title>{{$t('special_dashboard.finished_works')}}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
                <Chart
                  v-if="worksFinishedPerformanceGraphSeriesR1[0].data.length > 0"
                  :key="worksFinishedPerformanceGraphKeyR1"
                  class="mt-16"
                  id="performance-work-chart"
                  :options="worksFinishedPerformanceGraphoOptionsR1"
                  :series="worksFinishedPerformanceGraphSeriesR1"
                  :height="calculateHeightL2"
                />
                <p v-else>{{$t('globals.without_information')}}</p>
              </v-col>
              <v-col cols="12" md="6">
                <Chart
                  v-if="workFinishedPreviewTimeSeries[0].data.length > 0"
                  :key="workFinishedPreviewTimeKey"
                  class="mt-16"
                  id="first-chart"
                  :options="workPreviewFinishedTimeOptions"
                  :series="workFinishedPreviewTimeSeries"
                  :height="calculateHeightL2"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-row v-if="$root.session.hasPermission(['super']) || $root.session.hasRole(['dto']) || $root.session.hasRole(['ceo'])">
          <v-col cols="12" md="4" lg="3">
            <v-card :class="$root.$vuetify.theme.isDark ? 'secondary mt-2' : 'mt-2'">
              <v-card-title>
                <span :class="$root.$vuetify.theme.isDark ? 'white--text text-subtitle-1 font-weight-bold' : 'text-subtitle-1 font-weight-bold'">
                  {{$t('special_dashboard.obras_dto_total')}}
                </span>
              </v-card-title>
              
              <v-card-text>
                <h3>{{worksCounters.total_user_works}} / {{worksCounters.total}}</h3>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" lg="3">
            <v-card :class="$root.$vuetify.theme.isDark ? 'secondary mt-2' : 'mt-2'">
              <v-card-title>
                <span :class="$root.$vuetify.theme.isDark ? 'white--text text-subtitle-1 font-weight-bold' : 'text-subtitle-1 font-weight-bold'">
                  {{$t('special_dashboard.started_dto_total')}}
                </span>
              </v-card-title>
              <v-card-text>
                <h3>{{worksCounters.total_user_works_initiated}} / {{worksCounters.total_works_initiated}}</h3>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" lg="3">
            <v-card :class="$root.$vuetify.theme.isDark ? 'secondary mt-2' : 'mt-2'">
              <v-card-title>
                <span :class="$root.$vuetify.theme.isDark ? 'white--text text-subtitle-1 font-weight-bold' : 'text-subtitle-1 font-weight-bold'">
                  {{$t('special_dashboard.to_initiate_dto')}}
                </span>
              </v-card-title>
              <v-card-text>
                <h3>{{worksCounters.total_user_works_toInitiate}} / {{worksCounters.total_works_toInitiate}}</h3>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" lg="3">
            <v-card :class="$root.$vuetify.theme.isDark ? 'secondary mt-2' : 'mt-2'">
              <v-card-title>
                <span :class="$root.$vuetify.theme.isDark ? 'white--text text-subtitle-1 font-weight-bold' : 'text-subtitle-1 font-weight-bold'">
                  {{$t('special_dashboard.finished_dto')}}
                </span>
              </v-card-title>
              <v-card-text>
                <h3>{{worksCounters.total_user_works_finished}} / {{worksCounters.total_works_finished}}</h3>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="$root.session.hasPermission(['super']) || $root.session.hasRole(['dto']) || $root.session.hasRole(['ceo'])">
          <v-col cols="12">
            <v-toolbar :class="$root.$vuetify.theme.isDark ? 'secondary rounded-pill' : 'primary rounded-pill'">
              <span class="white--text text-h6 font-weight-bold">{{$t('globals.daily_parts')}}</span>
            </v-toolbar>            
          </v-col>
          <v-col cols="12" md="3">
            <v-card :class="$root.$vuetify.theme.isDark ? 'secondary mt-2' : 'mt-2'">
                <v-card-title>
                  <span :class="$root.$vuetify.theme.isDark ? 'white--text text-subtitle-1 font-weight-bold' : 'text-subtitle-1 font-weight-bold'">
                    {{$t('special_dashboard.daily_total')}}
                  </span>
                </v-card-title>
              <v-card-text>
                <h3>{{diariesCounters.total}}</h3>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card :class="$root.$vuetify.theme.isDark ? 'secondary mt-2' : 'mt-2'">
                <v-card-title>
                  <span :class="$root.$vuetify.theme.isDark ? 'white--text text-subtitle-1 font-weight-bold' : 'text-subtitle-1 font-weight-bold'">
                    {{$t('special_dashboard.approved')}}
                  </span>
                </v-card-title>
              <v-card-text>
                <h3>{{diariesCounters.finished}}</h3>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" v-if="false">
            <v-card :class="$root.$vuetify.theme.isDark ? 'secondary mt-2' : 'mt-2'">
                <v-card-title>
                  <span :class="$root.$vuetify.theme.isDark ? 'white--text text-subtitle-1 font-weight-bold' : 'text-subtitle-1 font-weight-bold'">
                    {{$t('special_dashboard.approved_init')}}
                  </span>
                </v-card-title>
              <v-card-text>
                <h3>{{diariesCounters.initiated}}</h3>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card :class="$root.$vuetify.theme.isDark ? 'secondary mt-2' : 'mt-2'">
                <v-card-title>
                  <span :class="$root.$vuetify.theme.isDark ? 'white--text text-subtitle-1 font-weight-bold' : 'text-subtitle-1 font-weight-bold'">
                    {{$t('special_dashboard.to_approve')}}
                  </span>
                </v-card-title>
              <v-card-text>
                <h3>{{diariesCounters.total- diariesCounters.finished}}</h3>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="$root.session.hasPermission(['super']) || $root.session.hasRole(['dto']) || $root.session.hasRole(['ceo'])">
          <v-col cols="12">
            <v-toolbar :class="$root.$vuetify.theme.isDark ? 'secondary rounded-pill' : 'primary rounded-pill'">
              <span class="white--text text-h6 font-weight-bold">{{$t('special_dashboard.daily_48_delayed')}}</span>
            </v-toolbar>            
          </v-col>
          <v-col cols="12" md="auto">
            <v-autocomplete
              v-model="filter.commissioner_id"
              :items="commissioners"
              menu-props="auto"
              :label="$t('globals.in_charge')"
              item-text="name"
              item-value="id"
              persistent-hint
              prepend-icon="mdi-account"
              clearable
              single-line
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="auto">
            <v-btn :color="$root.$vuetify.theme.isDark ? 'secondary' : 'primary'" @click="searchDelayedDiaries">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="delayedDiaries"
              :server-items-length="totalDelayed"
              :loading="loading"
              :footer-props="tableFooter"
              :options.sync="options"
              locale="pt-pt"
            >

            <template v-slot:item.actions="{ item }">
            <IconView
                class="mr-2"
                @click="$router.push('/' + $route.params.tenant + '/admin/diaries/create/' + item.work.id + '/' + item.missing_day)"
              />
          </template>


            </v-data-table>
          </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Dashboard from "@/api/Dashboard.js";
import User from "@/api/User.js";
import DelayedDiaries from "@/api/DelayedDiaries.js";
import Chart from '@/components/ui/Chart.vue';
import IconView from '@/components/ui/IconView.vue'

export default {
    components:{
        Chart,
        IconView
    },
    props: ['worksCounters', 'diariesCounters'],
    watch: {
        'options': {
        immediate: false,
        handler () {
            setTimeout(() => {this.searchDelayedDiaries()}, 10);
        },
        deep: true,
        },
    },
    data(){
        return{    
          filter: {
            commissioner_id:null,
          },
          loading: true,
          headers: [
          {
            text: 'Obra',
            sortable: true,
            value: 'work.name',
          },
          {
            text: 'Dia',
            sortable: true,
            value: 'missing_day',
          },   
          {
            text: 'Opções',
            value: 'actions',
          },          
        ],
        options: {},
        delayedDiaries: [],
        totalDelayed: 0,
        workFinishedPreviewTimeKey:0,
        workPreviewTimeKey: 0,        
        dashboardStatistics: null,
        worksPerformanceGraphKeyR1: 0,
        worksFinishedPerformanceGraphKeyR1: 0,
        worksPerformanceGraphKeyR2: 0,
        worksPerformanceGraphOnWayKey: 0,
        humanResourceGraphKey: 0,
        worksPerformanceGraphFinishedKey: 0,
        commissioners: []
      }
    },
    beforeMount(){
        this.generateStats()
        this.searchDelayedDiaries()
    },
    mounted(){
      this.fillBaseData();
    },
    computed:{       
        tableFooter(){
          let t = this.$t("globals.lines_datatable")
          let a = [5, 10, 15, 20];
          return {
            'items-per-page-options': a,
            'items-per-page-text': t
          }
        }, 
        calculateHeightL2(){
          let dt = this.getFinishedWorkPerformanceData()

          if(dt.length > 0 && dt.length <= 5)
            return "150"
          if(dt.length <= 20)
            return "350";
            
          let value = (350 * dt.length) / 5
          
          console.log("2: " + value)
          return "" + value
        },
        calculateHeightL1(){
          let dt = this.getWorkPerformanceData()
          if(dt.length > 0 && dt.length <= 5)
            return "150"
          if(dt.length <= 20)
            return "350";

          let value = (350 * dt.length) / 5
          console.log(value)
          return "" + value
        },
        workFinishedPreviewTimeSeries(){
          return [{
                  data: this.getWorkFinishedPreviewSeries()
              }]
        }  ,
        workPreviewTimeSeries(){
          return [{
                  data: this.getWorkPreviewSeries()
              }]
        },
        finishedSeries(){
          if(this.dashboardStatistics == null)
            return []
          if(this.dashboardStatistics.average_works_finished.length == 0)
            return []
            
          return [
            {
              data: [this.dashboardStatistics.average_works_finished]
            }
          ]
        },
        onWaySeries(){
          if(this.dashboardStatistics == null)
            return []
          if(this.dashboardStatistics.average_works_course.length == 0)
            return []
          return [
            {
              data: [this.dashboardStatistics.average_works_course]
            }
          ]
        },
        // FINISHED
        worksFinihedPerformanceGraphSeriesR1(){
          return [{
                  data: this.getFinishedWorkPerformanceData()
              }]
          },
        worksFinishedPerformanceGraphSeriesR1(){
          return [{
                  data: this.getFinishedWorkPerformanceData()
              }]
        },
        worksFinishedPerformanceGraphSeriesR2(){
          return [{
                  data: this.getFinishedWorkTime()
              },
              {
                  data: this.getFinishedWorkTimeWorked()
              }]
          },
        humanResourceGraphSeries(){
          if(this.dashboardStatistics == null)
            return []

          let extra = []
          let production = []
          let horse = []
          let faults = []
          let holidays = []
          this.dashboardStatistics.final_months.forEach(element => {
            extra.push(element.data.extra)
            production.push(element.data.production)
            horse.push(element.data.horse)
            faults.push(element.data.faults)
            holidays.push(element.data.holidays)
          });

          let returner = [{
            name: 'Horas Extra / Externos',
            data: extra
          }, 
          {
            name: 'Faltas / Folgas / Casa',
            data: faults
          }, 
          {
            name: 'Férias',
            data: holidays
          }, 
          {
            name: 'Estaleiro',
            data: horse
          }, 
          {
            name: 'Produção',
            data: production
          }]

          return returner
        },
        humanResourceGraphOptions(){
          return {
              colors: this.colors,
              chart: {
              type: 'bar',
              stacked: true,
              height: 350
              },  
              plotOptions: {
                  bar: {
                      borderRadius: 4,
                      horizontal: false,
                  }
                  },
                  dataLabels: {
                  enabled: false
                  },
                  xaxis: {
              type: 'text',
              categories: this.$t('globals.years'),
            },
            legend: {
              position: 'right',
              offsetY: 40
            },
            fill: {
              opacity: 1
            }
                  // Nomes das labels
          } 
        },
        worksFinishedPerformanceGraphoOptionsR2(){
          return {
              colors: this.colors,
              chart: {
              type: 'bar',
              height: 350
              },  
              plotOptions: {
                  bar: {
                      borderRadius: 4,
                      horizontal: true,
                  }
                  },
                  dataLabels: {
                  enabled: false
                  },
                  // Nomes das labels
                  xaxis: {
                  categories: ['Médias'],
                  },
                  legend: {
                    show: true,
                    showForSingleSeries: true,
                    customLegendItems: ['Prazo percorrido'],
                    markers: {
                      fillColors: ['#8bc34a', '#775DD0']
                    }
                  }
          }
        },
        worksFinishedPerformanceGraphoOptionsR1(){
            return {
                colors: this.colors,
                chart: {
                  type: 'bar',
                  height: 350,
                },  
                legend: {
                  show: true,
                  showForSingleSeries: true,
                  customLegendItems: ['Performance'],
                  markers: {
                    fillColors: ['#00E396']
                  }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        distributed: true,
                        dataLabels: {
                        position: 'bottom'
                      },
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                      colors: ['#fff']
                    },
                    formatter: (val, opt) => {
                      if(val == 0)
                        return ''

                        
                      return this.getFinishedWorksArrayPerformance(opt.dataPointIndex)
                        //return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
                    },
                    offsetX: 0,
                    dropShadow: {
                      enabled: true
                    }
                  },                  
                  // Nomes das labels
                  xaxis: {
                    categories: this.getFinishedWorkPerformanceCategories(),
                    labels:{
                      show: false
                    }
                  },
                  noData: {
                    text: 'Sem informação',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      color: undefined,
                      fontSize: '14px',
                      fontFamily: undefined
                    }
                  },  
                  tooltip: {
                    theme: 'dark',
                    x: {
                      show: false
                    },
                    y: {
                      formatter: (val, opt) => {
                        if(val == 0)
                          return ''

                          
                        return this.getFinishedWorksArrayPerformance(opt.dataPointIndex)
                      },
                      title: {
                        formatter: function () {
                          return 'Performance: '
                        }
                      }
                    }
                }
            }
        },
        // --
        worksPerformanceGraphSeriesR1(){
          return [{
                  data: this.getWorkPerformanceData()
              }]
          },
        worksPerformanceGraphSeriesR2(){
          return [{
                  data: this.getWorkTime()
              },
              {
                  data: this.getWorkTimeWorked()
              }]
          },
        worksPerformanceGraphoOptionsR2(){
          return {
              colors: this.colors,
              chart: {
              type: 'bar',
              height: 350
              },  
              plotOptions: {
                  bar: {
                      borderRadius: 4,
                      horizontal: true,
                  }
                  },
                  dataLabels: {
                  enabled: false
                  },
                  // Nomes das labels
                  xaxis: {
                  categories: this.getWorkPerformanceCategories(),
                  },
                  legend: {
                    show: true,
                    showForSingleSeries: true,
                    customLegendItems: ['Prazo percorrido'],
                    markers: {
                      fillColors: ['#8bc34a', '#775DD0']
                    }
                  }
          }
        },        
        workPreviewFinishedTimeOptions(){
          return {
            chart: {
                height: 350,
                type: 'bar'
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                }
              },
              colors: ['#00E396'],
              legend: {
                show: true,
                showForSingleSeries: true,
                customLegendItems: ['Prazo decorrido', 'previsto'],
                markers: {
                  fillColors: ['#00E396', '#775DD0']
                }
              },
              dataLabels: {
                  enabled: true,
                  textAnchor: 'start',
                  style: {
                    colors: ['#fff']
                  },
                  formatter: (val, opt) => {
                    if(val == 0)
                      return ''
                      

                      
                    return this.getPreviewFinishedTime(opt.dataPointIndex)
                      //return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
                  },
                  offsetX: 0,
                  dropShadow: {
                    enabled: true
                  }
                }, 
              noData: {
                text: 'Sem informação',
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                  color: undefined,
                  fontSize: '14px',
                  fontFamily: undefined
                }
              },
              xaxis:{
                labels:{
                  show: false
                }
              },
              tooltip: {
                    theme: 'dark',
                    x: {
                      show: true
                    },
                    y: {
                      formatter: (val, opt) => {
                        const goals =
                          opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                            .goals

                          if (goals && goals.length) {
                            return this.getPreviewFinishedTime(opt.dataPointIndex)
                          }
                          return val
                      },
                      title: {
                        formatter: function () {
                          return ''
                        }
                      }
                    }
                }
          }
        },
        workPreviewTimeOptions(){
          return {
            chart: {
                height: 350,
                type: 'bar'
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                }
              },
              colors: ['#00E396'],
              legend: {
                show: true,
                showForSingleSeries: true,
                customLegendItems: ['Prazo decorrido', 'previsto'],
                markers: {
                  fillColors: ['#00E396', '#775DD0']
                }
              },
              dataLabels: {
                  enabled: true,
                  textAnchor: 'start',
                  style: {
                    colors: ['#fff']
                  },
                  formatter: (val, opt) => {
                    if(val == 0)
                      return ''
                      

                      
                    return this.getPreviewTime(opt.dataPointIndex)
                      //return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
                  },
                  offsetX: 0,
                  dropShadow: {
                    enabled: true
                  }
                }, 
              noData: {
                text: 'Sem informação',
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                  color: undefined,
                  fontSize: '14px',
                  fontFamily: undefined
                }
              },
              xaxis:{
                labels:{
                  show: false
                }
              },
              tooltip: {
                    theme: 'dark',
                    x: {
                      show: true
                    },
                    y: {
                      formatter: (val, opt) => {
                        const goals =
                          opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                            .goals

                          if (goals && goals.length) {
                            return this.getPreviewTime(opt.dataPointIndex)
                          }
                          return val
                      },
                      title: {
                        formatter: function () {
                          return ''
                        }
                      }
                    }
                }
          }
        },
        worksPerformanceGraphoOptionsR1(){
            return {
                colors: this.colors,
                chart: {
                  type: 'bar',
                  height: 350,
                },  
                legend: {
                  show: true,
                  showForSingleSeries: true,
                  customLegendItems: ['Performance'],
                  markers: {
                    fillColors: ['#00E396']
                  }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        distributed: true,
                        dataLabels: {
                        position: 'bottom'
                      },
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                      colors: ['#fff']
                    },
                    formatter: (val, opt) => {
                      if(val == 0)
                        return ''

                        
                      return this.getArrayPerformance(opt.dataPointIndex)
                        //return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
                    },
                    offsetX: 0,
                    dropShadow: {
                      enabled: true
                    }
                  },                  
                  // Nomes das labels
                  xaxis: {
                    categories: this.getWorkPerformanceCategories(),
                    labels:{
                      show: false
                    }
                  },
                  noData: {
                    text: 'Sem informação',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      color: undefined,
                      fontSize: '14px',
                      fontFamily: undefined
                    }
                  },  
                  tooltip: {
                    theme: 'dark',
                    x: {
                      show: false
                    },
                    y: {
                      formatter: (val, opt) => {
                        if(val == 0)
                          return ''

                          
                        return this.getArrayPerformance(opt.dataPointIndex)
                      },
                      title: {
                        formatter: function () {
                          return 'Performance: '
                        }
                      }
                    }
                }
            }
        },
    },
    methods:{    
      getTooltipPreview(index){
        return this.dashboardStatistics.work_stats[index].tempo_previsto.toFixed(0)
      },
      getPreviewFinishedTime(index){
        return this.dashboardStatistics.finished_work_stats[index].prazo_decorrido.toFixed(0) + ' / ' + this.dashboardStatistics.finished_work_stats[index].tempo_previsto.toFixed(0)
      },
      getPreviewTime(index){        
        return this.dashboardStatistics.work_stats[index].prazo_decorrido.toFixed(0) + ' / ' + this.dashboardStatistics.work_stats[index].tempo_previsto.toFixed(0)
      },
      getFinishedWorksArrayPerformance(index){
        return this.dashboardStatistics.finished_work_stats[index].performance + '%';
      },
      getArrayPerformance(index){
        return this.dashboardStatistics.work_stats[index].performance + '%';
      },
      fillBaseData(){
        User.list().then(({data}) => {
          this.commissioners = data.filter(item => item.roles.some(role => role.name == "encarregado"));
        });
      },
      searchDelayedDiaries(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }

        this.loading = true;

        let filter = {...this.filter};

        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };
      
        Object.assign(request, filter);

        localStorage["delayed-diaries-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });

        DelayedDiaries.search(request).then(response => {

          let data = response.data;

          this.totalDelayed = data.total;


          this.delayedDiaries = data.data.map((item) => {

            return item;
          });

          this.loading = false;

        });
      },
      getWorkFinishedPreviewSeries(){
          if(this.dashboardStatistics == null)
            return []
          if(this.dashboardStatistics.finished_work_stats == null) 
            return[]

          let data_array = []
          this.dashboardStatistics.finished_work_stats.forEach(element => {

            let y_value = (element.prazo_decorrido * 100) / element.tempo_previsto
              
              if(y_value > 100)
                y_value = (y_value / 100) + 100

              let greater_value = 100 //y_value > 100 ? y_value - 100 : 100;
              
                data_array.push({
                  x:  element.name,
                  y:  y_value.toFixed(2), //value,
                  goals: [
                    {
                      name: 'Decorrido / Previsto: ',
                      value: greater_value.toFixed(2),
                      strokeWidth: 2,
                      strokeDashArray: 2,
                      strokeColor: '#775DD0'
                    }  
                  ]
                })
              
          });
          return data_array
        },        
        generateStats(){
            let parent = this
            Dashboard.getStatistics().then(({data}) => {
                parent.dashboardStatistics = data
            });
        },            
        getWorkTime(){
            let ar = [];
            if(this.dashboardStatistics == null)
                return ar;

            this.dashboardStatistics.work_stats.forEach(element => {
                ar.push(element.prazo_obra)
            });

            return ar
        },
        getWorkTimeWorked(){
            let ar = [];
            if(this.dashboardStatistics == null)
                return ar;
            this.dashboardStatistics.work_stats.forEach(element => {
                ar.push(element.prazo_decorrido)
            });

            return ar
        },
        getWorkPerformanceData(){
            let ar = [];
            if(this.dashboardStatistics == null)
                return ar;

            this.dashboardStatistics.work_stats.forEach(element => {
                ar.push(element.percentage.toFixed(2))
                
            });

            
            return ar
        },
        getWorkPreviewSeries(){
          if(this.dashboardStatistics == null)
            return []
          if(this.dashboardStatistics.work_stats == null) 
            return[]

            
          let data_array = []
          this.dashboardStatistics.work_stats.forEach(element => {
           

              let y_value = (element.prazo_decorrido * 100) / element.tempo_previsto
              
              if(y_value > 100)
                y_value = (y_value / 100) + 100

              let greater_value = 100 //y_value > 100 ? y_value - 100 : 100;

              data_array.push({
                x:  element.name,
                y:  y_value.toFixed(2), //value,
                goals: [
                  {
                    name: 'Decorrido / Previsto: ',
                    value: greater_value.toFixed(2), //element.prazo_decorrido > element.tempo_previsto ? element.prazo_decorrido : element.tempo_previsto,
                    strokeWidth: 2,
                    strokeHeight: 5,
                    strokeDashArray: 2,
                    strokeColor: '#775DD0'
                  }  
                ]
              })
          });
          
          return data_array
        },
        getWorkPerformanceCategories(){
            let ar = [];
            if(this.dashboardStatistics == null)
                return ar;

            this.dashboardStatistics.work_stats.forEach(element => {
                ar.push(element.code)
            });

            return ar
        },
        getFinishedWorkPerformanceCategories(){
            let ar = [];
            if(this.dashboardStatistics == null)
                return ar;

            this.dashboardStatistics.finished_work_stats.forEach(element => {
                ar.push(element.code)
            });

            return ar
        },
        getFinishedWorkPerformanceData(){
            let ar = [];
            if(this.dashboardStatistics == null)
                return ar;

            this.dashboardStatistics.finished_work_stats.forEach(element => {                  
                  ar.push(element.performance)
            });

            return ar
        },
        getFinishedWorkTime(){
            let ar = [];
            if(this.dashboardStatistics == null)
                return ar;

            this.dashboardStatistics.finished_work_stats.forEach(element => {
                ar.push(element.prazo_obra)
            });
            

            return ar
        },
        getFinishedWorkTimeWorked(){
            let ar = [];
            if(this.dashboardStatistics == null)
                return ar;

            this.dashboardStatistics.finished_work_stats.forEach(element => {
                ar.push(element.prazo_decorrido)
            });
            

            return ar
        },
    }
}
</script>