<template>
    <section class="mt-16">
      <v-breadcrumbs
          v-if="this.$root.$vuetify.theme.isDark"
          large
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
        <v-breadcrumbs
          v-else
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <rp-icon icon="rp-obras-blue-filled" class="mr-2"/> <span>{{$t('subcontractors.crumb_subcontractors')}}</span>
        </h1>
  
        <v-row>
          <v-col cols="12" md="auto">
            <rp-icon
                icon="rp-eye-blue-filled"
                class="mb-n2"
              /><v-icon class="ml-2" color="secondary" small>mdi-forward</v-icon>
              <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.visualize')}}</span>
          </v-col>
          <v-col cols="12" md="auto">
            <v-icon color="red" class="ml-4">
              mdi-delete
            </v-icon><v-icon class="ml-2" small color="secondary">mdi-forward</v-icon>
            <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.remove')}}</span>
          </v-col>
          <v-col cols="12" md="auto">
            <v-icon color="success" class="ml-4">
              mdi-file-export
            </v-icon><v-icon class="ml-2" small color="secondary">mdi-forward</v-icon>
            <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.export')}}</span>
          </v-col>
        </v-row>
  
        <div style="position:relative">
          <v-container>
            <v-speed-dial
              absolute
              v-model="speedDial"
              top
              right
              direction="bottom"
              :open-on-hover="true"
            >
              <template v-slot:activator>
                <v-tooltip right color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" v-on="on"
                      v-model="speedDial"
                      color="primary"
                      dark
                      x-large
                      fab
                    >
                      <v-icon v-if="speedDial">
                        mdi-close
                      </v-icon>
                      <v-icon v-else>
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('globals.options')}}</span>
                </v-tooltip>
              </template>
              <v-tooltip right color="info">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="info"
                    large
                    @click="searching = true"
                  >
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('globals.search')}}</span>
              </v-tooltip>
              <v-tooltip right color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="secondary"
                    large
                    :to="'/' + $session.get('domain') + '/admin/works-settings/subempreiteiros/create'"
                    v-if="$root.session.hasPermission(['super', 'subempreiteiro.create'])"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('globals.register')}}</span>
              </v-tooltip>
            </v-speed-dial>
          </v-container>
  
          <v-data-table 
            :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'"
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
            locale="pt-pt"
          >
  
          
  
            <template v-slot:item.actions="{item}">
              <v-row>
                <v-col cols="12" md="auto"
                    v-if='$root.session.hasPermission(["super", "subempreiteiro.update"])'>
                  <IconView
                    small
                    @click="viewSubempreiteiro(item)"
                  />
                  <IconRemove
                    v-if="!item.has_diaries && $root.session.hasPermission(['super', 'subempreiteiro.delete'])"
                    @on-submit="deleteSubempreiteiro(item)"
                  />
                </v-col>
              </v-row>
            </template>
  
            
          </v-data-table>
        </div>
        <v-navigation-drawer
            fixed
            right
            v-model="searching"
            hide-overlay
            width="520"
            class="pt-6"
          >
          <v-list>
            <v-list-item>
              <v-btn fab small color="secondary" @click="searching = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
  
              <v-btn absolute right small color="error" @click="resetSubempreiteiros">
                {{$t('globals.clean_fields')}}
              </v-btn>
            </v-list-item>
          </v-list>
          <v-list class="pt-10">
            <v-form @submit.prevent="searchSubempreiteiros">
              
             
  
              
              <v-row
                class="mt-10"
                align="center"
                justify="space-around"
              >
              <v-btn
                color="primary"
                type="submit"
              >
                {{$t('globals.search')}}
              </v-btn>
              </v-row>
            </v-form>
          </v-list>
        </v-navigation-drawer>  
        
        <ErrorSnackbar v-model="error.show" :message="error.message"/>
    </section>
  </template>
  <script>
  import IconRemove from '@/components/ui/IconRemove.vue';
  import IconView from '@/components/ui/IconView.vue';
  import Subempreiteiro from "@/api/Subempreiteiro.js";
  import dayjs from 'dayjs';
  import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
  
  export default {
    components: {
      IconRemove,
      IconView,
      ErrorSnackbar,
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchSubempreiteiros()}, 10);
        },
        deep: true,
      },
    },
    beforeCreate(){
      document.title = "Report Lake - " + this.$t('subcontractors.crumb_subcontractors');
    },
    data: () => ({
      modalPicker1: false,
      modalPicker2: false,
      datePicker1: null,
      datePicker2: null,
      error: {
        title: '',
        message: '',
        show:false,
      },
      items: [],
      total: 0,
      loading: false,
      searching: false,
      options: {},
      speedDial:false,
      filter: {
        code:null,
        name: null,
        commissioner_id:null,
        overseer_id:null,
        engineer_id:null,
        status: null,
      },
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "subempreiteiros.read"])) {
        this.$router.push('/admin');
      }
  
  
      this.fillBaseData();
    },
    methods:{
      getTableDate(date) {
        return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
      },
      fillBaseData() {
  
        if(localStorage["subempreiteiros-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["subempreiteiros-" + this.$root.session.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
      },
      searchSubempreiteiros(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
  
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };
      
        Object.assign(request, filter);
  
        localStorage["subempreiteiros-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        Subempreiteiro.search(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });
  
          this.loading = false;
  
        });
      },
      resetSubempreiteiros()
      {
        this.filter = {
          is_active: 1
        };
  
        this.searchSubempreiteiros();
      },
      viewSubempreiteiro(item){
        let domain = '/' + this.$session.get('domain')
        let path = domain + '/admin/works-settings/subempreiteiros/' + item.id
  
  
        this.$router.push(path);
      },
      deleteSubempreiteiro(item){
        Subempreiteiro.delete(item.id)
          .then(() => {
            this.searchSubempreiteiros();
          });
      },
    },
    computed: {
        headers(){
          let he = [
            {
            text: this.$t('globals.name'),
            sortable: true,
            value: 'name'
            },
            {
              text: this.$t('globals.email'),
              sortable: true,
              value: 'email'
            },
            {
              text: this.$t('globals.contact'),
              sortable: true,
              value: 'telephone'
            },
            {
              text: this.$t('globals.last_work'),
              sortable: true,
              value: 'last_work'
            },
            { 
              text: this.$t('globals.options'), 
              value: 'actions', 
              width: '200px',
              sortable: false 
            },
          ]
          return he
        },
        tableFooter(){
          let t = this.$t("globals.lines_datatable")
          let a = [5, 10, 15, 20];
          return {
            'items-per-page-options': a,
            'items-per-page-text': t
          }
        }, 
        crumbs: function() {
            return [
            {
                text: this.$t('menu.dashboard'),
                disabled: false,
                to: '/admin',
                exact: true,
            },
            {
                text: this.$t('subcontractors.crumb_subcontractors'),
                disabled: true,
                to: window.location.href,
                exact: true,
            },
            ];
        }
    }
  };
  </script>
  