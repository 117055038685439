import BaseApi from "./BaseApi";

export default class Chapter extends BaseApi{
    construct() {}
    
 
    static async search(params) {
        return await Chapter.getAxiosInstance().get(Chapter.getFullApiUrl(),{params: params});
    }

    static async create(params) {
        return await Chapter.getAxiosInstance().post(Chapter.getFullApiUrl(),params);
    }

    static async find(id) {
        return await Chapter.getAxiosInstance().get(Chapter.getFullApiUrl() + id);
    }

    static async update(params, id) {
        return await Chapter.getAxiosInstance().put(Chapter.getFullApiUrl() + id,params);
    }

    static async delete(id) {
        return await Chapter.getAxiosInstance().delete(Chapter.getFullApiUrl() + id);
    }
    
    static async list(params) {
        let url = Chapter.getFullApiUrl() + "list";

        return Chapter.getAxiosInstance().get(url,{params: params});
    }

    
    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/chapter/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/chapter/";
    }
}
