<template>
    <v-container fluid>
        <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
        <v-card>
            <v-card-title class="primary white--text">Adicionar comentário</v-card-title>
            <v-card-text>
                <v-textarea class="mt-4" outlined label="Inserir comentário" v-model="fields.comment_line">

                </v-textarea>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary'" @click="createComment">Comentar</v-btn>
            </v-card-actions>
        </v-card>
        <v-list three-line>
            <template v-for="(item, index) in comments">
                <v-container fluid
                    v-bind:key="item.id">
                <v-divider></v-divider>
                <v-list-item
                >
                
                    <v-list-item-avatar>
                        <v-icon>mdi-account</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title v-html="item.user"></v-list-item-title>
                        <v-list-item-subtitle v-html="item.updated_at"></v-list-item-subtitle>
                        <v-list-item-title v-if="!item.visible" v-html="item.comment_line"></v-list-item-title>
                        <v-textarea label="Comentário" v-if="item.visible" v-model="item.comment_line"></v-textarea>
                    </v-list-item-content>
                    <v-list-item-action>
                        <div v-if="verifyEditable(item, index)">
                            <v-btn icon>
                                <IconRemove
                                    v-if="item.user_id == $root.session.id"
                                    @on-submit="deleteComment(item)"
                                />
                            </v-btn>
                        </div>
                        <div v-if="verifyEditable(item, index)">
                            <v-btn icon v-if="!item.visible">
                                <v-icon @click="editComment(item)">mdi-pencil</v-icon>
                            </v-btn>
                        </div>
                        <div v-if="verifyEditable(item, index)">
                            <v-btn icon v-if="item.visible" color="success">
                                <v-icon @click="saveEdition(item)">mdi-content-save-all</v-icon>
                            </v-btn>
                        </div>
                    </v-list-item-action>
                </v-list-item>
            </v-container>
            </template>
            </v-list>
          <SuccessSnackBar v-model="success" message="Comentário inserido" />
    </v-container>
</template>
<script>
import Comments from '@/api/Comments.js'
import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';
import IconRemove from '@/components/ui/IconRemove.vue';

export default{
    components: {
        SuccessSnackBar,
        IconRemove
    },
    props:{
        place: String,
        method: {
            typeof: String,
            default: 'create'
        }
    },
    data(){
        return{
            toEdit: false,
            success:false,
            loading: true,
            fields:{
                id: null,
                place: null,
                comment_line: null,
                user_id: null,
                main_id: null
            },
            comments:[]
        }
    },
    mounted(){
        this.fields.place = this.place
        this.fields.user_id = this.$root.session.id
        this.fields.main_id = this.$route.params.id
        this.fillBaseData()
    },
    methods:{
        verifyEditable(item, index){
            if(index+1 == this.comments.length && item.user_id == this.$root.session.id)
                return true
            

            return false
        },
        async saveEdition(item){
            this.loading = false

            this.fields.comment_line = item.comment_line

            let data = {...this.fields};

            data.comment_line = item.comment_line
            data.id = item.id

            await Comments.update(data, item.id).then(() => {
  
                this.success = true;

                this.fields.comment_line = ''

                this.fillBaseData()

                this.loading = false;
                
                }).catch(err => {
                this.loading = false;

                    if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                    }

                    this.error.title = this.$t('globals.error') + " " + err.response.status;

                    this.error.message = err.response.data.message;

                });
        },
        editComment(item){
            item.visible = !item.visible
        },
        deleteComment(item){
            Comments.delete(item.id)
                .then(() => {
                    this.fillBaseData()
                });
        },
        async fillBaseData(){
            let filter = {
                place: this.place,
                main_id: this.$route.params.id
            }
            await Comments.list(filter).then(({data}) => {
                this.comments = data.map((item) => {

                    item.visible = false

                    return item;
                });
            });

            this.loading = false
        },
        async createComment(){           
            let data = {...this.fields};
            this.loading = true

            await Comments.create(data, this.fields.id).then(() => {
  
                this.success = true;

                this.fields.comment_line = ''

                this.fillBaseData()

                this.loading = false;
                
                }).catch(err => {
                this.loading = false;

                    if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                    }

                    this.error.title = this.$t('globals.error') + " " + err.response.status;

                    this.error.message = err.response.data.message;

                });
        }
    }
}
</script>