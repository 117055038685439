<template>
  <section class="mt-16">
    <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-notebook</v-icon> <span>{{$t('diaries.crumb_edit')}}</span>
      </h1>

      <div class="mt-16">
        <v-progress-linear v-if="loading"
          indeterminate
          class="global-loader"
        ></v-progress-linear>

        <template v-if="loading">
          <v-row>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row class="mt-6" align="center" justify="space-around">
            <v-skeleton-loader
                type="button"
              ></v-skeleton-loader>
          </v-row>
        </template>
        <diary v-else method="update" :diary="diary" @reloadAll="reloadAll" @reload="reload" :key="reloadAllComponent"></diary>
      </div>
  </section>
</template>
<script>
import Diary from '@/components/diaries/Diary'
import DiaryApi from "@/api/Diary.js";

export default {
  components: {
    Diary
  },
  beforeCreate(){
    document.title = "Report Lake - " + this.$t('diaries.crumb_edit');
  },
  data: () => ({
      diary: {},
      loading:true,
      reloadAllComponent: 0
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "diaries.update"])) {
      this.$router.push('/admin');
    }

    DiaryApi.find(this.$route.params.id).then(({data}) => { this.diary = data; this.loading = false;});
  },
  methods:{
    reload(fields){
      DiaryApi.find(this.$route.params.id).then(({data}) => { 
        this.diary.machine_work_units = data.machine_work_units
        this.diary.worker_work_units = data.worker_work_units
        //this.diary.work_units = data.work_units

        fields.machine_work_units = data.machine_work_units
        fields.worker_work_units = data.worker_work_units
        //fields.work_units = data.work_units
        
        this.loading = false;
      });
    },
    reloadAll(){
      location.reload()
    },
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: this.$t('menu.dashboard'),
          disabled: false,
          to: '/' + this.$session.get('domain') + '/admin',
          exact: true,
        },
        {
          text: this.$t('menu.daily_parts_management'),
          disabled: false,
          to: '/' + this.$session.get('domain') + '/admin/diaries',
          exact: true,
        },
        {
          text: this.$t('diaries.crumb_edit'),
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
