<template>
  <section class="mt-16">
    <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <rp-icon icon="rp-barcode-blue-filled" class="mr-2"/> <span>{{$t('menu.work_units_management')}}</span>
      </h1>

      <v-row>
        <v-col cols="12" md="auto">
          <rp-icon
            icon="rp-eye-blue-filled"
            class="mb-n2"
          /><v-icon class="ml-2" small color="secondary">mdi-forward</v-icon>
          <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.visualize')}}</span>
        </v-col>
        <v-col cols="12" md="auto">
          <v-icon color="red" class="ml-4">
            mdi-delete
          </v-icon><v-icon class="ml-2" small color="secondary">mdi-forward</v-icon>
          <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.remove')}}</span>
        </v-col>
        <v-col cols="12" md="auto">
          <v-icon color="success" class="ml-4">
            mdi-file-export
          </v-icon><v-icon class="ml-2" small color="secondary">mdi-forward</v-icon>
          <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.export')}}</span>
        </v-col>
      </v-row>

      <div style="position:relative">
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t('globals.options')}}</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="info"
                  large
                  @click="searching = true"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>{{$t('globals.search')}}</span>
            </v-tooltip>            
            <v-tooltip right color="success">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="success"
                    large
                    @click="exportUnitWorkList"
                  >
                    <v-icon>mdi-paperclip</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('globals.export')}}</span>
              </v-tooltip>
            <v-tooltip right color="warning">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="warning"
                  large
                  @click="exportAllUO"
                >
                  <v-icon>{{$currency_icon()}}</v-icon>
                </v-btn>
              </template>
              <span>{{$t('globals.work_unit_income')}}</span>
            </v-tooltip>
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  :to="'/' + $session.get('domain') + '/admin/elements/units/create'"
                  v-if="$root.session.hasPermission(['super', 'units.create'])"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{$t('globals.register')}}</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
          locale="pt-pt"
        >
          <template v-slot:item.value="{item}">
            {{ $currency(item.value) }}
          </template>
          <template v-slot:item.actions="{item}">
            <v-row>
              <v-col cols="12" md="auto">
                <IconView
                  class="mr-2"
                  @click="viewUnit(item)"
                />
                <IconExport
                  class="mr-2"
                  :text="$t('work_units.report')"
                  v-if="$root.session.hasPermission(['super', 'export.units'])"
                  @click="exportUO(item)"                />
         
                <IconRemove
                  v-if="!item.has_actions && $root.session.hasPermission(['super', 'units.delete'])"
                  class="mr-2"
                  @on-submit="deleteUnit(item)"
                />
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </div>
      <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetUnits">
              {{$t('globals.clean_fields')}}
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchUnits">
            
            <v-list-item>
              <v-text-field
                v-model="filter.code"
                prepend-inner-icon="mdi-barcode"
                :label="$t('globals.code')"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-text-field
                v-model="filter.name"
                prepend-inner-icon="mdi-form-textbox"
                :label="$t('globals.name')"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              {{$t('globals.search')}}
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
      </v-navigation-drawer>
      <v-dialog
            v-model="modalChooseData"
            persistent
            
            width="350px"
        >
        <ChooseData 
          @close="modalChooseData = false"
          @confirm="confirmExport"/>
      </v-dialog>
      <Loader v-if="exportingDocument"></Loader>  
      <ErrorSnackbar v-model="error.show" :message="error.message"/>
  </section>
</template>
<script>
import Loader from '@/components/ui/Loader.vue'
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import Unit from "@/api/Unit.js";
import dayjs from 'dayjs';
import Export from "@/api/Export.js";
import IconExport from '@/components/ui/IconExport.vue';
import ChooseData from '@/components/general/dialogs/ChooseDate.vue';
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';

export default {
  components: {
    IconRemove,
    IconView,
    IconExport,
    ChooseData,
    Loader,
    ErrorSnackbar
  },
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchUnits()}, 10);
      },
      deep: true,
    },
  },
  beforeCreate(){
    document.title = "Report Lake - " + this.$t('menu.work_units_management');
  },
  data: () => ({
    exportingDocument: false, 
    modalChooseData: false,
    error: {
        title: '',
        message: '',
        show:false,
    },
    to_export: {
      ob: null,
      start: null,
      end: null,
      all: false,
    },
    items: [],
    total: 0,
    loading: false,
    searching: false,
    options: {},
    speedDial:false,
    filter: {
      code:null,
      name: null,
    },
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "units.read"])) {
      this.$router.push('/admin');
    }

    this.fillBaseData();
  },
  methods:{
    exportUnitWorkList(){
        let data = 'unit_work';
        Export.generalExport(data)
          .then((resp) => {            
              window.open(resp.data.url, '_blank')
              Export.delete( resp.data.path)
              .then(() => {
              });
          });
      },
    exportAllUO(){
      this.to_export.all = true
      this.modalChooseData = true
    },
    exportUO(item){
      this.to_export.ob = item
      this.modalChooseData = true
      this.to_export.all = false
    },
    async confirmExport(d1, d2, type){
      this.exportingDocument = true
      let data ={
        dataInicial: d1,
        dataFinal: d2,
        type: type
      }

      // Se não for para exportar todos é só uma
      if(!this.to_export.all){
        await Export.exportRendimentoPorUO(this.to_export.ob.id, data)
            .then((resp) => {
              this.modalChooseData = false
              if(resp.data.url == null){
                this.error.message = this.$t('works.no_data')
                this.error.show = true
                this.exportingDocument = false
                return
              }
              window.open(resp.data.url, '_blank')
              Export.delete(resp.data.path)
                .then(() => {
                  this.exportingDocument = false                  
                });
            });
        }else{
          await Export.exportRendimentoUOs(data)
            .then((resp) => {
              this.modalChooseData = false
              if(resp.data.url == null){
                this.error.message = this.$t('works.no_data')
                this.error.show = true
                this.exportingDocument = false
                return
              }
              window.open(resp.data.url, '_blank')
              Export.delete(resp.data.path)
                .then(() => {
                  this.exportingDocument = false     
                });
            });
        }
        
        this.modalChooseData = false
    },
    getTableDate(date) {
      return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
    },
    fillBaseData() {

      if(localStorage["units-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["units-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }
    },
    searchUnits(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        page: this.options.page,
        limit: this.options.itemsPerPage,
      };
    
      Object.assign(request, filter);

      localStorage["units-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      Unit.search(request).then(response => {

        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });

        this.loading = false;

      });
    },
    resetUnits()
    {
      this.filter = {
      };

      this.searchUnits();
    },
    viewUnit(item){
      let domain = '/' + this.$session.get('domain')
      this.$router.push(domain + '/admin/elements/units/' + item.id);
    },
    deleteUnit(item){
      Unit.delete(item.id)
        .then(() => {
          this.searchUnits();
        });
    },
  },
  computed: {
    headers(){
      let he = [
        {
          text: this.$t('globals.code'),
          sortable: true,
          value: 'code',
        },
        {
          text: this.$t('globals.name'),
          sortable: true,
          value: 'name',
        },
        {
          text: this.$t('globals.unit'),
          sortable: true,
          value: 'unit',
        },
        {
          text: this.$t('globals.cost'),
          sortable: true,
          value: 'value',
        },
        { 
          text: this.$t('globals.options'), 
          value: 'actions', 
          width: '200px',
          sortable: false 
        },
      ]
      return he
    },
    tableFooter(){
      let t = this.$t("globals.lines_datatable")
      let a = [5, 10, 15, 20];
      return {
        'items-per-page-options': a,
        'items-per-page-text': t
      }
    }, 
    crumbs: function() {
      return [
        {
          text: this.$t('menu.dashboard'),
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: this.$t('menu.work_units_management'),
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
