<template>
    <v-row>
        <v-col cols="12">
        <div class="d-flex flex-row align-center">
            <h2 class="secondary--text subtitle-1">
            Mão de Obra
            </h2>
            <v-spacer />
        </div>
        <v-simple-table dense class="d-none d-lg-block">
            <template v-slot:default>
            <thead>
                <tr>
                <th class="text-center rounded-pill rounded-r-0">
                    Nome
                </th>
                <th class="text-center">
                    Horário
                </th>
                <th class="text-center">
                    Pernoita
                </th>
                <th class="text-center">
                    Trabalho Noturno
                </th>
                <th class="text-center rounded-pill rounded-l-0">
                    Opções
                </th>
                </tr>
            </thead>
            <tbody :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                <tr v-for="worker in template_fields.workers" v-bind:key="worker.id">
                <td class="text-center">
                    <validation-provider v-slot="{ errors }" vid="laborer" :name="$t('globals.name')" rules="required">
                    <v-combobox
                        v-model="worker.object"
                        :label="$t('globals.name') + ' *'"                  
                        :items="workersAvailable"
                        :item-text="item => item.name"
                        autocomplete="off"
                        item-value="id"
                        hide-selected
                        :error-messages="errors"
                        clearable
                        @input="worker.name = $event.name; worker.id = $event.id"
                        @change="changedWorker(worker)"
                    >
                    </v-combobox>
                    </validation-provider>
                </td>
                <td class="text-center">
                    <v-row>
                    <v-col cols="12" md="6">
                        <v-row>
                        <v-col cols="12" md="6">
                            <validation-provider v-slot="{ errors }" vid="hour" :name="$t('globals.entry_hour')" rules="required">
                            <v-text-field
                                v-model="worker.started_hour"
                                :label="$t('globals.entry_hour')  + ' *'"
                                :error-messages="errors"
                            ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="6">
                            <validation-provider v-slot="{ errors }" vid="minute" :name="$t('globals.entry_minute')" rules="required">
                            <v-text-field
                                v-model="worker.started_minute"
                                :label="$t('globals.entry_minute') + ' *'"
                                :error-messages="errors"
                            ></v-text-field>
                            </validation-provider>
                        </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-row>
                        <v-col cols="12" md="6">
                            <validation-provider v-slot="{ errors }" vid="hour" :name="$t('globals.exit_hour')" rules="required">
                            <v-text-field
                                v-model="worker.finished_hour"
                                :label="$t('globals.exit_hour') + ' *'"
                                :error-messages="errors"
                            ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="6">
                            <validation-provider v-slot="{ errors }" vid="minute" :name="$t('globals.exit_minute')" rules="required">
                            <v-text-field
                                v-model="worker.finished_minute"
                                :label="$t('globals.exit_minute') + ' *'"
                                :error-messages="errors"
                            ></v-text-field>
                            </validation-provider>
                        </v-col>
                        </v-row>
                    </v-col>
                    </v-row>
                </td>
                <td class="text-center">
                    <v-checkbox                   
                        v-model="worker.slept_night"
                    />
                </td>
                <td class="text-center">
                    <v-checkbox                    
                        v-model="worker.night_work"
                    />
                </td>
                <td class="text-center">
                    <v-col cols="12" md="6">
                        <v-btn
                        dark
                        small
                        color="secondary"
                        @click="error.show = true"
                        >
                        <small>Duplicar</small>
                        <v-icon small>mdi-plus-circle-multiple-outline</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="6" class="mt-n2">
                        <v-btn
                        dark
                        small
                        color="error"
                        @click="removeWorker(worker)"
                        >
                        <small>Eliminar</small>
                        <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                    </v-col>
                </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
        <v-data-iterator
            :items="template_fields.workers"
            item-key="id"
            :items-per-page="-1"
            hide-default-footer
            class="d-block d-lg-none"
        >
            <template v-slot:default="{ items }">
            <v-row>
                <v-col
                v-for="worker in items"
                :key="worker.id"
                cols="12"
                sm="6"
                md="4"
                lg="3"
                >
                <v-card>
                    <v-list
                    dense
                    >
                    <v-list-item>
                        <v-list-item-content>Nome:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <validation-provider v-slot="{ errors }" vid="laborer" :name="$t('globals.name')" rules="required">
                            <v-combobox
                            v-model="worker.object"
                            :label="$t('globals.name') + ' *'"                              
                            :items="workers"
                            :item-text="item => item.name"
                            item-value="id"
                            hide-selected
                            :error-messages="errors"
                            clearable
                            @input="worker.name = $event.name; worker.id = $event.id"
                            >
                            </v-combobox>
                        </validation-provider>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Horário:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <v-row>
                            <v-col cols="12" lg="6">
                            <v-row>
                                <v-col cols="12" lg="6">
                                <validation-provider v-slot="{ errors }" vid="hour" :name="$t('globals.entry_hour')" rules="required">
                                    <v-text-field
                                    v-model="worker.started_hour"
                                    :label="$t('globals.entry_hour') + ' *'"
                                    :error-messages="errors"
                                    ></v-text-field>
                                </validation-provider>
                                </v-col>
                                <v-col cols="12" lg="6">
                                <validation-provider v-slot="{ errors }" vid="minute" :name="$t('globals.entry_minute')" rules="required">
                                    <v-text-field
                                    v-model="worker.started_minute"
                                    :label="$t('globals.entry_minute') + ' *'"
                                    :error-messages="errors"
                                    ></v-text-field>
                                </validation-provider>
                                </v-col>
                            </v-row>
                            </v-col>
                            <v-col cols="12" lg="6">
                            <v-row>
                                <v-col cols="12" lg="6">
                                <validation-provider v-slot="{ errors }" vid="hour" :name="$t('globals.exit_hour')" rules="required">
                                    <v-text-field
                                    v-model="worker.finished_hour"
                                    :label="$t('globals.exit_hour') + ' *'"
                                    :error-messages="errors"
                                    ></v-text-field>
                                </validation-provider>
                                </v-col>
                                <v-col cols="12" lg="6">
                                <validation-provider v-slot="{ errors }" vid="minute" :name="$t('globals.exit_minute')" rules="required">
                                    <v-text-field
                                    v-model="worker.finished_minute"
                                    :label="$t('globals.exit_minute') + ' *'"
                                    :error-messages="errors"
                                    ></v-text-field>
                                </validation-provider>
                                </v-col>
                            </v-row>
                            </v-col>
                        </v-row>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Pernoita:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <v-checkbox
                        :disabled="true"
                            v-model="worker.slept_night"
                        />
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Trabalho Noturno:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <v-checkbox
                        :disabled="true"
                            v-model="worker.night_work"
                        />
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="true">
                        <v-list-item-content>Opções:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <v-row>
                            <v-col cols="12" md="6">
                            <v-btn
                                dark
                                small
                                block
                                color="secondary"
                                @click="error.show = true"
                            >
                                <v-icon small>mdi-plus-circle-multiple-outline</v-icon>
                            </v-btn>
                            </v-col>
                            <v-col cols="12" md="6">
                            <v-btn
                                dark
                                small
                                block
                                color="error"
                                @click="removeWorker(worker)"
                            >
                                <v-icon small>mdi-delete</v-icon>
                            </v-btn>
                            </v-col>
                        </v-row>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list>
                </v-card>
                </v-col>
            </v-row>
            </template>
        </v-data-iterator>
        <v-row justify="center" v-if="true">
            <v-btn
            fab
            dark
            small
            color="primary"
            class="mt-7"
            @click="addWorker"
            >
            <v-icon small>mdi-plus</v-icon>
            </v-btn>
        </v-row>
    </v-col>
        <ErrorSnackbar v-model="error.show" :message="error.message"/>
        <DialogConfirmation 
            v-if="openConfirmationDialog" 
            title="Remover Item" 
            type="warning" 
            :opened="openConfirmationDialog" 
            @on-submit="submitRemove" 
            @on-cancel="openConfirmationDialog = false">
                Tem a certeza que pretende remover o item?
        </DialogConfirmation>
    </v-row>
</template>
<script>

import dayjs from 'dayjs'
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
import DialogConfirmation from '@/components/ui/DialogConfirmation.vue';

export default {
    components:{
        DialogConfirmation,
        ErrorSnackbar
    },
    props: {
        template_fields: Object,
        workers: Array,
    },
    data: () => ({
        openConfirmationDialog: false,
        removedWorkers: [],
        workersAvailable: [],
        error:{
            show: false,
            message: 'Ação indisponível em template'
        },
        toSend:{
            id: null
        }
    }),
    mounted(){
        this.workersAvailable = this.workers
        let auxiliarWorkersAvailable = []


        this.workersAvailable.forEach(worker => {
            let found = false
            this.template_fields.workers.forEach(used => {
                if(worker.id == used.worker_id)
                    found = true

                if(worker.id == used.id)
                    found = true
            });
            if(found == false)
                auxiliarWorkersAvailable.push(worker)
            else this.removedWorkers.push(worker)
        });

        this.workersAvailable = auxiliarWorkersAvailable

    },
    methods:{
        changedWorker(worker){
            let index = 0; let position = -1;
            this.workersAvailable.forEach(workersArray => {        
                if(workersArray.id == worker.id){
                    position = index
                    this.removedWorkers.push(workersArray)
                }  
                index++
            });
            if(position != -1)
                this.workersAvailable.splice(position, 1)

                // Percorrer o array de removidos
                // Para verificar se podemos retirar algum deste array
                index = 0; position = -1;
                let auxiliarRemoved = [];
                this.removedWorkers.forEach(rWorker => {
                    let found = false
                    this.template_fields.workers.forEach(w1 => {
                        if(rWorker.id == w1.id)
                            found = true
                        if(w1.worker_id != undefined)
                            if(rWorker.id == w1.worker_id)
                                found = true
                    });

                    if(!found){
                        this.workersAvailable.push(rWorker)
                    }else{
                        auxiliarRemoved.push(rWorker)
                    }
                    index++
                });

                this.removedWorkers = auxiliarRemoved
        },
        addWorker() {
            this.template_fields.workers.push({
                id: dayjs().unix(),
                name: "",
                started_hour: 8,
                started_minute: 0,
                finished_hour: 16,
                finished_minute: 0,
                slept_night: false,
                night_work: false,
                worked_hours: []
            });
        },
        removeWorker(workerObject) {            
            this.toSend.id = workerObject.id
            this.openConfirmationDialog = true
        },
        async submitRemove(){
            await this.$emit('remove', this.toSend.id)
            

            let index = 0; let position = -1;
            this.removedWorkers.forEach(element => {
                if(element.id == this.toSend.id)
                    position = index

                index++
            });


            this.removedWorkers.splice(position, 1)
           


            let availableWorkersLocal = []

            this.workersAvailable.forEach(element => {
                let found = false
                this.removedWorkers.forEach(rWorker => {
                    if(element.id == rWorker.id)
                        found = true

                    if(rWorker.worker_id != undefined)
                        if(rWorker.worker_id == element.id)
                            found = true

                    if(element.worker_id != undefined)
                        if(element.worker_id == rWorker.id)
                            found = true
                });

                if(!found){
                    availableWorkersLocal.push(element)
                }
            });

            this.workersAvailable = availableWorkersLocal

            
            this.openConfirmationDialog = false
        }
    }
}
</script>