import BaseApi from "./BaseApi";

export default class Performance extends BaseApi{
    construct() {}

    static async getDiaries(id, params) {
        let url = Performance.getFullApiUrl() + id;

        return Performance.getAxiosInstance().get(url, {params: params});
    }

    static async getPerformance(id) {
        let url = Performance.getFullApiUrl() + 'work_performance/' + id;

        return Performance.getAxiosInstance().get(url);
    }

    static async exportWorkPerformance(work_id, params) {
        let url = Performance.getFullApiUrl() + 'export_work_performance/' + work_id;

        return Performance.getAxiosInstance().get(url, {params: params});
    }

    static async exportWorkUnitPerformance(work_id, params) {
        let url = Performance.getFullApiUrl() + 'unit_performance/' + work_id;

        return Performance.getAxiosInstance().get(url, {params: params});
    }

    static async delete(id) {
        return await Performance.getAxiosInstance().delete(Performance.getFullApiUrl() + 'export_work_performance/' + id);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/performance/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/performance/";
    }

}