<template>
    <v-card elevation="12" class="mt-16" :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'" :class="$root.$vuetify.theme.isDark ? 'secondary elevation-0' : ''">
        <v-row>
            <v-col cols="12" md="6">
                <v-card-title>{{$t('settings.schedule.work_hours')}}:</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="12">
                            <validation-provider v-slot="{ errors }" vid="work_hours" :name="$t('globals.work_hours')" rules="required">
                            <v-text-field
                                readonly
                                v-model="fields.work_hours"
                                prepend-inner-icon="mdi-clock"
                                :label="$t('globals.work_hours') + ' *'"
                                :error-messages="errors"
                            ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
                <v-card-title>{{$t('settings.schedule.start_month')}}:</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="12">
                            <validation-provider v-slot="{ errors }" vid="days" :name="$t('globals.days')" rules="required"> 
                            <v-select
                                v-model="fields.starting_month"
                                readonly
                                :items="days_starting"
                                menu-props="auto"
                                :label="$t('globals.days')"
                                prepend-inner-icon="mdi-view-day-outline"
                                :error-messages="errors"
                            ></v-select>
                            </validation-provider>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-col>
        </v-row>                    
        <v-divider color="#6E7A90" class="mt-8"></v-divider>
        <v-divider color="#6E7A90"></v-divider>
        <v-divider color="#6E7A90" class="mb-8"></v-divider>
        <v-card-title>{{$t('settings.schedule.extra_hours_classify')}}:</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="4">
                    <validation-provider v-slot="{ errors }" vid="50" name="50" rules="required">
                    <v-text-field
                        readonly
                        v-model="fields.extra_50"
                        prepend-inner-icon="mdi-clock"
                        label="50%"
                        :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                </v-col>
                <v-col cols="12" md="4">
                    <validation-provider v-slot="{ errors }" vid="75" name="75" rules="required">
                    <v-text-field
                        readonly
                        v-model="fields.extra_75"
                        prepend-inner-icon="mdi-clock"
                        label="75%"
                        :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                </v-col>
                <v-col cols="12" md="4">
                    <validation-provider v-slot="{ errors }" vid="100" name="100" rules="required">
                    <v-text-field
                        readonly
                        v-model="fields.extra_100"
                        prepend-inner-icon="mdi-clock"
                        label="100%"
                        :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                </v-col>
                <v-col cols="12">
                    <small>({{$t('settings.schedule.example')}})</small>
                </v-col>
            </v-row>
        </v-card-text>
    <DialogSuccess :opened="success" @on-ok="onSuccess" >
        {{$t('settings.schedule.success')}}
    </DialogSuccess>
    </v-card>
</template>
<script>
import Configurations from "@/api/Configurations.js";
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default{
    components:{
        DialogSuccess
    },
    data(){
        return{
            loading: false,
            success: false,
            fields:{},   
            days_starting: [
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28   
            ],
        }
    },
    async mounted(){
        this.loading = true
        await Configurations.get().then(({data}) => {
        this.fields = data.data
        
       
        this.loading = false
        });
    },
    methods:{
        onSuccess(){
            this.success = false
        },
        async submit(){
            await Configurations.update(this.fields, this.fields.id).then(() => {

                this.success = true;

                this.loading = false;
                
                }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        }     
    },
    computed: {
        crumbs: function() {
            return [
                {
                text: 'Dashboard',
                disabled: false,
                to: '/admin',
                exact: true,
                },
                {
                text: 'Horários',
                disabled: true,
                to: window.location.href,
                exact: true,
                },
            ];
        }
    }
}
</script>