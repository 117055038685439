<template>
    <section class="mt-16">
      <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      
        <h1 class="primary--text">
          <rp-icon icon="rp-alert-blue-filled" class="mr-2"/> <span>{{$t('faults.crumb_add')}}</span>
        </h1>
  
        <div class="mt-16">
          <Fault method="create"></Fault>
        </div>
    </section>
  </template>
  <script>
  import Fault from '@/components/faults/Fault'
  
  export default {
    components: {
        Fault
    },
    beforeCreate(){
      document.title = "Report Lake - " + this.$t('faults.crumb_add');
    },
    data: () => ({
      
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "faults.create"])) {
        this.$router.push('/');
      }
  
    },
    methods:{
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: this.$t('menu.dashboard'),
            disabled: false,
            to: '/' + this.$session.get('domain') + '/admin',
            exact: true,
          },
          {
            text: this.$t('menu.faults_management'),
            disabled: false,
            to: '/' + this.$session.get('domain') + '/admin/workers-settings/faults',
            exact: true,
          },
          {
            text: this.$t('faults.crumb_add'),
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  