import { extend } from 'vee-validate';
import { required, email, numeric, min_value, max_value } from 'vee-validate/dist/rules'
import dayjs from 'dayjs'
import i18n from '../i18n';

extend('required', {
    ...required,
    message: (field) => field + ' ' + i18n.t('validations.mandatory'),
})

extend('between', {
  validate(value) {
    return value >= 0 && value <= 100
  },
  message: (field) => field + ' ' + i18n.t('validations.between_0_100'),
})

extend('between_hour', {
  validate(value) {
    return value >= 0 && value <= 23
  },
  message: (field) => field + ' ' + i18n.t('validations.between_0_23'),
})

extend('between_minute', {
  validate(value) {
    return value >= 0 && value <= 59
  },
  message: (field) => field + ' ' + i18n.t('validations.between_0_59'),
})

extend('email', {
    ...email,
    message: (field) => field + ' ' + i18n.t('validations.valid_email'),
})

extend('numeric', {
    ...numeric,
    message: (field) => field + ' ' + i18n.t('validations.numeric'),
})

extend('min_value', {
    ...min_value,
    message: (field, attr) => field + ' ' + i18n.t('validations.greater_or_equal') + ' ' + attr.min,
})

extend('max_value', {
    ...max_value,
    message: (field, attr) => field + ' ' + i18n.t('validations.minor_or_equal') + ' ' + attr.max,
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: () => i18n.t('validations.password_different')
});

extend("date_min", {
  params: ['target'],
    validate(value, { target }) {

        if (value.length == 0) {
            return true;
        }

        value = value.split("/");

        target = target.split("/");

        value = value[2] + "-" + value[1] + "-" + value[0];

        target = target[2] + "-" + target[1] + "-" + target[0];

        return dayjs(value).isSame(dayjs(target)) || dayjs(value).isAfter(dayjs(target));
  },
  message: (target) => i18n.t('validations.data_greater_or_equal') + " " + target + "!"
});