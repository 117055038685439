<template>
    <section class="mt-16">
      <v-breadcrumbs
          v-if="this.$root.$vuetify.theme.isDark"
          large
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
        <v-breadcrumbs
          v-else
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <rp-icon icon="rp-truck-blue-filled" class="mr-2"/> <span>{{$t('menu.material_request')}}</span>
        </h1>
  
        <v-row>
          <v-col cols="12" md="auto">
            <rp-icon
              icon="rp-eye-blue-filled"
              class="mb-n2"
            />
            <v-icon class="ml-2" color="secondary" small>mdi-forward</v-icon>
            <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.visualize')}}</span>
          </v-col>
          <v-col cols="12" md="auto">
            <v-icon color="red" class="ml-4">
              mdi-delete
            </v-icon><v-icon class="ml-2" color="secondary" small>mdi-forward</v-icon>
            <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.remove')}}</span>
          </v-col>
        </v-row>     
  
        <div style="position:relative">          
          <v-container>
            <v-speed-dial
              absolute
              v-model="speedDial"
              top
              right
              direction="bottom"
              :open-on-hover="true"
            >
              <template v-slot:activator>
                <v-tooltip right color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" v-on="on"
                      v-model="speedDial"
                      color="primary"
                      dark
                      x-large
                      fab
                    >
                      <v-icon v-if="speedDial">
                        mdi-close
                      </v-icon>
                      <v-icon v-else>
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('globals.options')}}</span>
                </v-tooltip>
              </template>
              <v-tooltip right color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="secondary"
                    large
                    :to="'/' + $session.get('domain') + '/admin/shopping-request/create'"
                    v-if="$root.session.hasPermission(['super', 'shopping_request.create'])"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('globals.register')}}</span>
              </v-tooltip>
            </v-speed-dial>
          </v-container>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
            locale="pt-pt"
          >

          <template v-slot:item.invoices="{ item }">
            {{ item.invoices_total == 0 ? '0 ' + this.$store.state.coin : $currency(item.invoices_total) }}
          </template>
  
          <template v-slot:item.actions="{ item }">
            <v-row>
              <v-col cols="12" md="3">
                <IconView
                  @click="$router.push('/' + $session.get('domain') + '/admin/shopping-request/' + item.id)"
                  />
              </v-col>
            </v-row>
          </template>
  
            
          </v-data-table>
          
        </div>
        <v-navigation-drawer
            fixed
            right
            v-model="searching"
            hide-overlay
            width="520"
            class="pt-6"
          >
          <v-list>
            <v-list-item>
              <v-btn fab small color="secondary" @click="searching = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
  
              <v-btn absolute right small color="error" @click="resetShoppingRequest">
                {{$t('globals.clean_Fields')}}
              </v-btn>
            </v-list-item>
          </v-list>
  
        </v-navigation-drawer>
    </section>
  </template>
  <script>
  import dayjs from 'dayjs';
  import ShoppingRequest from "@/api/ShoppingRequest.js";
  import IconView from '@/components/ui/IconView.vue';
  
  export default {
    components: {
      IconView
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchShoppingRequest()}, 10);
        },
        deep: true,
      },
    },
    data: () => ({
      company_selected: null,
      items: [],
      total: 0,
      loading: false,
      searching: false,
      options: {},
      speedDial:false,
      filter: {
        name:null,
        deleted_company: false
      },
      status: [],
      addCompany: false
    }),
    mounted(){
  
      /*if(!this.$root.session.hasPermission(["super", "diaries.read"])) {
        this.$router.push('/admin');
      }*/
  
      //this.searchCompanies();
    },
    methods:{
      getTableDate(date) {
        return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
      },
      fillBaseData() {
        this.addCompany = false
        if(localStorage["shopping_request-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["shopping_request-" + this.$root.session.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
      },
      searchShoppingRequest(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
        
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };
        
      
        Object.assign(request, filter);
  
        localStorage["shopping_request-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        ShoppingRequest.search(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });
  
          this.loading = false;
  
        });
      },
      resetShoppingRequest()
      {
        this.searchShoppingRequest();
      },
      deleteShoppingRequest(item){  
        console.log(item)
      },
      viewShoppingRequest(item){
        this.shopping_request_selected = item
        this.addCompany = true
      }
    },
    computed: {
      headers(){
        let he = [
          {
            text: this.$t('globals.description'),
            sortable: true,
            value: 'description',
          },
          {
            text: this.$t('shopping_request.work_delivery_term'),
            sortable: true,
            value: 'work_delivery_term',
          },
          {
            text: this.$t('globals.supplier'),
            sortable: true,
            value: 'supplier',
          },
          {
            text: this.$t('globals.state'),
            sortable: true,
            value: 'status',
          },
          {
            text: this.$t('globals.created_at'),
            sortable: true,
            value: 'created_at',
          },
          {
            text: this.$t('globals.options'),
            sortable: false,
            value: 'actions',
          }
        ]
        return he
      },
      tableFooter(){
        let t = this.$t("globals.lines_datatable")
        let a = [5, 10, 15, 20];
        return {
          'items-per-page-options': a,
          'items-per-page-text': t
        }
      }, 
      crumbs: function() {
        return [
          {
            text: this.$t('menu.dashboard'),
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: this.$t('menu.material_request'),
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  