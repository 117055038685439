<template>
    <section>
        <validation-observer ref="form" v-slot="{ invalid }">
            <v-form @submit.prevent="submit" class="mb-16">
                <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                    <v-progress-linear v-if="loadPage"
                    indeterminate
                    class="global-loader"
                ></v-progress-linear>
                    <v-card-text>
                        <v-row v-if="!loadPage">
                            <v-col cols="12" md="6">
                                <validation-provider v-slot="{ errors }" vid="Ficheiro" :name="$t('globals.file')" rules="required">
                                    <v-select
                                        v-model="fields.fileType"
                                        :items="files"
                                        menu-props="auto"
                                        :label="$t('globals.file')"
                                        item-text="name"
                                        item-value="id"
                                        :error-messages="errors"
                                        persistent-hint
                                        :hint="$t('globals.select_file_type')"
                                        prepend-icon="mdi-file-delimited"
                                        single-line
                                        @change="changedDocument"
                                    ></v-select>
                                </validation-provider>
                            </v-col>                            
                            <v-col cols="12" md="6">
                                <validation-provider v-if="workSelect" v-slot="{ errors }" vid="Obra" :name="$t('globals.work')" rules="required">
                                    <v-autocomplete                                         
                                        v-model="fields.workId"
                                        :items="works"
                                        menu-props="auto"
                                        :error-messages="errors"
                                        :label="$t('globals.work')"
                                        item-text="name"
                                        item-value="id"
                                        persistent-hint
                                        :hint="$t('globals.select_work')"
                                        prepend-icon="mdi-hard-hat"
                                        single-line
                                    ></v-autocomplete>
                                </validation-provider>
                                <validation-provider v-if="machineSelect" v-slot="{ errors }" vid="Máquinas" :name="$t('globals.machine')" rules="required">
                                    <v-autocomplete                                        
                                        v-model="fields.machine_id"
                                        :items="machines"
                                        menu-props="auto"
                                        :error-messages="errors"
                                        :label="$t('globals.machine')"
                                        item-text="name"
                                        item-value="id"
                                        persistent-hint
                                        :hint="$t('globals.select_machine')"
                                        prepend-icon="mdi-hard-hat"
                                        single-line
                                    ></v-autocomplete>
                                </validation-provider>
                                <validation-provider v-if="uoSelect" v-slot="{ errors }" vid="Unidade Obra" :name="$t('globals.work_unit')" rules="required">
                                    <v-autocomplete                                        
                                        v-model="fields.unit_id"
                                        :items="units"
                                        menu-props="auto"
                                        :error-messages="errors"
                                        :label="$t('globals.work_unit')"
                                        item-text="name"
                                        item-value="id"
                                        persistent-hint
                                        :hint="$t('globals.select_work_unit')"
                                        prepend-icon="mdi-hard-hat"
                                        single-line
                                    ></v-autocomplete>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="3">
                                <validation-provider v-if="computedChoosed" v-slot="{ errors }" vid="Formato" :name="$t('globals.format')" rules="required">
                                    <v-select
                                        :items="itemTypes"
                                        v-model="selectedType"
                                        prepend-icon="mdi-paperclip"
                                        :error-messages="errors"
                                        :label="$t('globals.format')"
                                    ></v-select>
                                </validation-provider>
                                    <v-text-field
                                        v-else
                                        v-model="selectedType"
                                        :label="$t('globals.format')"
                                        prepend-icon="mdi-paperclip"
                                        readonly
                                        :disabled="true"
                                    ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3" v-if="hability_month == false">
                                <v-menu
                                    v-model="pickerInicio"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <validation-provider v-slot="{ errors }" vid="Inicio" :name="$t('globals.start_date')" rules="required">
                                        <v-text-field
                                            v-model="fields.start"
                                            :disabled="dateDisabled"
                                            :label="$t('globals.start_date')"
                                            :error-messages="errors"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </validation-provider>
                                    </template>
                                    <v-date-picker
                                    v-model="fields.start"
                                    :allowed-dates="!all_days_available ? (date) => date <= new Date(new Date()+1).toISOString().substr(0, 10) : null"
                                    @input="pickerInicio = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="3" v-if="hability_month == false">
                                <v-menu
                                    v-model="pickerFim"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <validation-provider v-slot="{ errors }" vid="Fim" :name="$t('globals.end_date')" rules="required">
                                        <v-text-field
                                            v-model="fields.end"
                                            :disabled="dateDisabled"
                                            :label="$t('globals.end_date')"
                                            :error-messages="errors"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </validation-provider>
                                    </template>
                                    <v-date-picker
                                    v-model="fields.end"
                                    :allowed-dates="!all_days_available ? (date) => date <= new Date(new Date()+1).toISOString().substr(0, 10) : null"
                                    @input="pickerFim= false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="3" v-if="hability_month == true">
                                <validation-provider v-slot="{ errors }" vid="Meses" :name="$t('globals.month')" rules="required">
                                    <v-select
                                        v-model="month_selected"
                                        :items="$t('globals.months_array')"
                                        :error-messages="errors"
                                        item-text="text"
                                        item-value="id"
                                        :label="$t('globals.Month_Capital') + ' *'"
                                    />
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row class="mt-6" align="center" justify="space-around">
                            <v-col align="center" justify="space-around">
                            <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                                {{$t('globals.save')}}
                            </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-form>
        </validation-observer>
        <Loader v-if="loading"></Loader>  
        <ErrorSnackbar v-model="error.show" :message="error.message"/>
    </section>
</template>
<script>
import Work from "@/api/Work.js";
import Worker from "@/api/Worker.js";
import Unit from "@/api/Unit.js";
import Machine from "@/api/Machine.js";
import Export from "@/api/Export.js";
import Loader from '@/components/ui/Loader.vue'
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';

export default{
    components:{
        Loader,
        ErrorSnackbar
    },
    data(){
        return{
            dateDisabled: false,
            month_selected: null,
            hability_month: false,
            units: [],
            machines: [],
            error: {
                title: '',
                message: '',
                show:false,
            },
            chooseDocument: false,
            selectedType: 'Excel',
            loadPage: false, 
            loading: false,
            itemTypes: ['Excel', 'PDF'],
            fields:{
                fileType: null,
                workId: null,
                start: null,
                end: null,
                designation: '',
                machine_id: null,
                unit_id: null
            },
            all_days_available: false,
            workSelect: false,
            uoSelect: false,
            machineSelect: false,
            pickerInicio: false, 
            pickerFim: false, 
            works: [],
            files: [
                {
                    id: -1,
                    name: 'Relatório de todos equipamentos e viaturas',
                    type: '',
                    chooseDocument: true,
                    designation: '',
                    all_days_available: false,
                    only_month: false
                },
                {
                    id: 0,
                    name: 'Relatório de equipamento e viatura',
                    type: 'machine',
                    chooseDocument: true,
                    designation: '',
                    all_days_available: false,
                    only_month: false
                },
                {
                    id: 1,
                    name: 'Relatório de equipamentos e viaturas por obra',
                    type: 'works',
                    chooseDocument: true,
                    designation: '',
                    all_days_available: false,
                    only_month: false
                },
                {
                    id: 2,
                    name: 'Relatório de rendimentos de mão de obra',
                    type: 'works',
                    chooseDocument: true,
                    designation: '',
                    all_days_available: false,
                    only_month: false
                },
                {
                    id: 3,
                    name: 'Colaboradores - Horas extra',
                    type: 'workers',
                    chooseDocument: true,
                    designation: 'extra_hours',
                    all_days_available: false,
                    only_month: false
                },
                {
                    id: 4,
                    name: 'Colaboradores - Ponto de mão de obra',
                    type: 'workers',
                    chooseDocument: true,
                    designation: 'work_entries',
                    all_days_available: false,
                    only_month: false
                },
                {
                    id: 5,
                    name: 'Colaboradores - Refeições',
                    type: 'workers',
                    chooseDocument: true,
                    designation: 'meals',
                    all_days_available: false,
                    only_month: false
                },
                {
                    id: 6,
                    name: 'Colaboradores - Trabalho noturno',
                    type: 'workers',
                    chooseDocument: true,
                    designation: 'night_work',
                    all_days_available: false,
                    only_month: false
                },
                {
                    id: 7,
                    name: 'Colaboradores - Performance',
                    type: 'workers',
                    chooseDocument: true,
                    designation: 'performance',
                    all_days_available: false,
                    only_month: false
                },                
                {
                    id: 8,
                    name: 'Colaboradores - Férias',
                    type: 'workers',
                    chooseDocument: true,
                    designation: 'holidays',
                    all_days_available: true,
                    only_month: false
                },
                {
                    id: 9,
                    name: 'Partes diárias',
                    type: 'works',
                    chooseDocument: true,
                    designation: '',
                    all_days_available: false,
                    only_month: false
                },
                {
                    id: 10,
                    name: 'Relatório de rendimentos médios por UO',
                    type: 'uo',
                    chooseDocument: true,
                    designation: '',
                    all_days_available: false,
                    only_month: false
                },
                {
                    id: 11,
                    name: 'Relatório de rendimentos médios das UO',
                    type: '',
                    chooseDocument: true,
                    designation: '',
                    all_days_available: false,
                    only_month: false
                },
                {
                    id: 12,
                    name: 'Listagem de materiais',
                    type: 'materials',
                    chooseDocument: false,
                    designation: '',
                    all_days_available: false,
                    only_month: false
                },
                {
                    id: 13,
                    name: 'Listagem de fornecedores',
                    type: 'suppliers',
                    chooseDocument: false,
                    designation: '',
                    all_days_available: false,
                    only_month: false
                },
                {
                    id: 14,
                    name: 'Listagem de unidades de medida',
                    type: 'units_management',
                    chooseDocument: false,
                    designation: '',
                    all_days_available: false,
                    only_month: false
                },
                {
                    id: 15,
                    name: 'Listagem de unidades de obra',
                    type: 'unit_work',
                    chooseDocument: false,
                    designation: '',
                    all_days_available: false,
                    only_month: false
                },
                {
                    id: 16,
                    name: 'Listagem de mão de obra indireta',
                    type: 'indirect',
                    chooseDocument: false,
                    designation: '',
                    all_days_available: false,
                    only_month: false
                },
                {
                    id: 17,
                    name: 'Mão de Obra - Por obra',
                    type: 'works',
                    chooseDocument: false,
                    designation: '',
                    all_days_available: false,
                    only_month: true
                }
            ]
        }
    },
    async mounted(){
        this.loadPage = true
        await this.fillBaseData()
        this.loadPage = false
    },
    methods:{
        changedDocument(e){
            this.workSelect = false
            this.machineSelect = false
            this.uoSelect = false

            this.files.forEach(element => {
                if(e == element.id){
                    if(element.type == 'works'){
                        this.workSelect = true
                    }
                    if(element.type == 'machine'){
                        this.machineSelect = true
                    }
                    if(element.type == 'uo'){
                        this.uoSelect = true
                    }
                    if(element.only_month){
                        this.hability_month = false
                    }else this.hability_month = false

                    this.chooseDocument = element.chooseDocument
                    if(this.chooseDocument == false)
                        this.selectedType = this.itemTypes[0]
                        
                    this.fields.report = element.designation

                    this.all_days_available = element.all_days_available

                    if(element.id >= 12 && element.id <=16){
                        this.dateDisabled = true
                    }else this.dateDisabled = false
                }
            });
        },
        async submit(){
            this.loading = true
            let data = {
                type:           this.selectedType,
                started_at:     this.fields.start,
                finished_at:    this.fields.end,
                report:         this.fields.report,
                machine_id:     this.fields.machine_id,
                unit_id:        this.fields.unit_id,
                work_id:        this.fields.workId,
                language:       this.$store.state.language
            }

            if(this.fields.fileType <= 0){
                
                await Machine.exportEquipments(data)
                    .then((resp) => {
                        
                    if(resp.data.success){
                        this.fields.machine_id = null
                        if(resp.data.object == null)
                        {
                            this.error.message = "Não há dados para mostrar"
                            this.error.show = true
                            this.exportingDocument = false
                            return
                        }else 
                            window.open(resp.data.object)
                    }

                    });
            }


            if(this.fields.fileType == 1){
                await Work.exportEquipments(this.fields.workId, data)
                    .then((resp) => {
                    this.exportingWorkEquipments = false;

                    this.exportingDocument = false

                    if(resp.data.success){
                        
                        if(resp.data.object == null)
                        {
                            this.error.message = "Não há dados para mostrar"
                            this.error.show = true
                            this.exportingDocument = false
                            return
                        }else 
                            window.open(resp.data.object)

                    }
                });
            }

            if(this.fields.fileType == 2){                
                if(data.type == 'PDF'){
                    data = {
                        type:           this.selectedType,
                        started_at:     this.fields.start,
                        finished_at:    this.fields.end,
                        report:         'performance',
                        machine_id:     this.fields.machine_id,
                        unit_id:        this.fields.unit_id,
                        work_id:        this.fields.workId,
                        language:       this.$store.state.language
                    }
                    await Worker.exportReport(data)
                    .then((resp) => {

                        if(resp.data.success)
                            window.open(resp.data.object)

                });
                }else{         
                    data = {
                        type:           data.type,
                        data1:          data.started_at,
                        data2:          data.finished_at,
                        work_id:        this.fields.workId,
                        language:       this.$store.state.language
                    }       
                    
                    await Export.exportMORendimentos(this.fields.workId, data)
                        .then((resp) => {
                            if(resp.data.success){
                                if(resp.data.object == null){
                                    this.error.message = "Não há dados para mostrar"
                                    this.error.show = true
                                    this.exportingDocument = false
                                    return                                
                                }else{
                                    window.open(resp.data.object.url, '_blank')
                                    Export.delete(resp.data.object.path)
                                    .then(() => {
                                        
                                    });
                                }
                            }                
                        
                    });
                }
            }

            if(this.fields.fileType >= 3 && this.fields.fileType <= 8){
                await Worker.exportReport(data)
                    .then((resp) => {

                        if(resp.data.success){
                            if(resp.data.object == null){
                                this.error.message = "Não há dados para mostrar"
                                this.error.show = true
                                this.exportingDocument = false
                                return  
                            }else{
                                window.open(resp.data.object)
                            }
                        }

                });
            }


            if(this.fields.fileType == 9){
                data ={
                    dataInicial:    data.started_at,
                    dataFinal:      data.finished_at,
                    type:           this.selectedType,
                    work:           this.fields.workId,
                    language:       this.$store.state.language
                }

                await Export.exportDiaries(data)
                    .then((resp) => {
                        if(resp.data.url == null){
                            this.error.message = "Sem Partes Diárias para apresentar"
                            this.error.show = true
                            this.exportingDocument = false
                            return;
                        }
                        window.open(resp.data.url, '_blank')
                        Export.delete( resp.data.path)
                        .then(() => {
                            this.exportingDocument = false
                        });
                    });
            }

            if(this.fields.fileType == 10){
                data ={
                    dataInicial:    data.started_at,
                    dataFinal:      data.finished_at,
                    type:           this.selectedType,
                    unit_id:        this.fields.unit_id,
                    language:       this.$store.state.language
                }

                await Export.exportRendimentoPorUO(this.fields.unit_id, data)
                    .then((resp) => {
                        if(resp.data.url == null){
                            this.error.message = "Sem dados para apresentar"
                            this.error.show = true
                            this.exportingDocument = false
                            return;
                        }
                        window.open(resp.data.url, '_blank')
                        Export.delete( resp.data.path)
                        .then(() => {
                            this.exportingDocument = false
                        });
                    });
            }

            if(this.fields.fileType == 11){
                data ={
                    dataInicial:    data.started_at,
                    dataFinal:      data.finished_at,
                    type:           this.selectedType,
                    language:       this.$store.state.language
                }

                await Export.exportRendimentoUOs(data)
                    .then((resp) => {
                        window.open(resp.data.url, '_blank')
                        Export.delete( resp.data.path)
                        .then(() => {
                            this.exportingDocument = false
                        });
                    });
            }

            if(this.fields.fileType >= 12 && this.fields.fileType <= 16){
                let data = ''
                this.files.forEach(element => {
                    if(element.id == this.fields.fileType)
                        data = element.type
                });
                
                Export.generalExport(data, {language: this.$store.state.language})
                .then((resp) => {            
                    window.open(resp.data.url, '_blank')
                    Export.delete( resp.data.path)
                    .then(() => {
                    });
                });
            }

            if(this.fields.fileType == 17){
                data.report = 'mo_works';
                //data.month = this.month_selected
                await Worker.exportReport(data)
                    .then((resp) => {

                        if(resp.data.success)
                            window.open(resp.data.object)

                });
            }

            
            this.loading = false
        },
        async fillBaseData(){
            await Work.list().then(({data}) => {
                this.works = data;
            });

            await Machine.list().then(({data}) => {
                let final = []
                data.forEach(element => {
                    if(element.has_actions == true)
                        final.push(element)
                });
                this.machines = final
            });

            await Unit.list().then(({data}) => {
                let final = []
                data.forEach(element => {
                    if(element.has_actions == true)
                        final.push(element)
                });
                this.units = final
            });

            // Exportações. Fim é o dia de hoje o Inicio são 30 dias antes, por defeito
            let dt = new Date(new Date().toISOString().substr(0, 10))
            dt.setDate(dt.getDate() - 30);
            this.fields.end = new Date().toISOString().substr(0, 10);
            this.fields.start = dt.toISOString().substr(0, 10);
        }
    },
    computed:{
        computedChoosed(){
            return this.chooseDocument
        }
    }
}
</script>