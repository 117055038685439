<template>
    <v-container fluid class="mb-8">
        <v-row>
            <v-col cols="12">
                <v-dialog
                    v-model="addImages"
                    max-width="800px"
                    >
                    <template v-slot:activator="{ props }">
                        <div class="text-left mt-4">
                        <Tooltip
                            color="warning"
                            :text="$t('work.add_image')"
                            bottom
                        >
                            <v-btn
                                class="mx-2"
                                dark
                                small
                                color="warning"
                                @click="addImages = true;"
                                v-bind="props"
                                >
                                <v-icon dark>
                                    mdi-plus
                                </v-icon>{{$t('work.add_image')}}
                            </v-btn>
                        </Tooltip>
                        </div>
                    </template>

                    <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                        <v-toolbar :class="$root.$vuetify.theme.isDark ? 'secondary' : 'primary white--text'">
                            {{$t('globals.indirect_work_hand')}}
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <v-menu
                                        v-model="image_object.pickerDatafotos"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <validation-provider v-slot="{ errors }" vid="Inicio" :name="$t('globals.start_date')" rules="required">
                                            <v-text-field
                                                v-model="image_object.associated_day"
                                                :label="$t('globals.start_date')"
                                                :error-messages="errors"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </validation-provider>
                                        </template>
                                        <v-date-picker
                                        v-model="image_object.associated_day"
                                        @input="image_object.pickerDatafotos = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea v-model="image_object.comment" 
                                        label="Adicione o seu comentário"
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <div class="d-flex justify-center mb-8">
                                        <VueUploadMultipleImage
                                            ref="imageUploader"
                                            class="image-uploader"
                                            :dragText="$t('diaries.insert_images')"
                                            :browseText="$t('diaries.click_here')"
                                            :showPrimary="false"
                                            @upload-success="uploadImageSuccess"
                                            @before-remove="beforeRemove"
                                            :data-images="images"
                                            idUpload="myIdUpload"
                                            editUpload="myIdEdit"
                                            :maxImage="200"
                                        />
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                
            </v-col>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="items"
                    item-key="id"
                    sort-by="associated_day"
                    group-by="associated_day"
                    class="elevation-1"
                    show-group-by
                >
                    <template v-slot:group.header="{items, isOpen, toggle}">
                        <v-container fluid :class="$root.$vuetify.theme.isDark ? '' : 'primary white--text'">
                            <v-row>
                                <v-col cols="12">
                                    <v-icon @click="toggle" class="white--text"
                                        >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                                    </v-icon>
                                    <span class="text-h6">{{$t('globals.day')}}: 
                                        <small>{{ formatDate(items[0].associated_day) }}</small>
                                    </span>  
                                </v-col>
                                <v-col cols="12" md="auto" class="mt-n4" v-if="items[0].diary_id != null">
                                    <span @click="viewDiary(items[0])"><small>{{$t('globals.options')}}: </small>
                                        <v-icon color="white" class="ml-2" small style="cursor: pointer;">mdi-eye</v-icon>
                                    </span>
                                </v-col>
                                <v-col cols="12" md="auto" class="mt-n4">
                                    <span @click="downloadImages(items[0])">
                                        <v-icon color="white" small style="cursor: pointer;">mdi-download</v-icon>
                                    </span>
                                </v-col>
                            </v-row> 
                        </v-container>         
                    </template>
            
                    <template v-slot:item.files="{item}">
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-card class="mt-4 mb-4">                                    
                                    <v-img
                                        aspect-ratio="1.7"
                                        contain
                                        :src="item.path"
                                    ></v-img>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" md="auto">
                                                <strong>{{$t('globals.comment')}}:</strong>
                                            </v-col>
                                            <v-col cols="12" md="auto">
                                                {{ item.comment }}
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Work from "@/api/Work.js";
import Downloads from "@/api/Downloads.js";
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import Tooltip from '@/components/ui/Tooltip.vue';

export default{
    name: "WorkImages",
    components:{
        VueUploadMultipleImage,
        Tooltip
    },
    data(){
        return{
            addImages: false,
            loading: true,
            options: {},
            filter:{

            },
            total: 0,
            items: [],
            images: [],
            image_object:{
                pickerDatafotos: false,
                associated_day: null,
                comment: null
            }
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        beforeRemove (index) {
            console.log(index)
            /*
            if(this.locked_edition){
                return;
            }

            this.openConfirmationRemoveImage = true
            this.fileIndex = index*/
        },
        uploadImageSuccess(formData) {
            formData.append('associated_day', this.image_object.associated_day)
            formData.append('comment', this.image_object.comment)

            Work.createImage(formData, this.$route.params.id).then(({data}) => {
                this.images.push(data)
            }).catch(err => {

                if(err.response.status == 422) {

                    this.error.title = this.$t('globals.error') + " " + err.response.status;

                    this.error.message = err.response.data.errors.file[0];
                    

                    this.error.show = true;  
                    return;
                }

                });
        },
        async downloadImages(item){
            let i = item.id
            await Downloads.get(i)
                .then((response) => {
                    window.open(response.data.url, '_blank')

                    Downloads.delete(response.data.path)
                    .then(() => {
                    
                    });
                    
                });

            
        },
        viewDiary(item){
            let domain = '/' + this.$session.get('domain')
            this.$router.push(domain + '/admin/diaries/' + item.diary_id);
        },
        formatDate(d){
            return new Date(d).toLocaleString('pt-PT').split(',')[0]
        },
        async fillBaseData(){
            await this.searchWorkImages()
        },
        searchWorkImages(event){
            if(!!event && event.type == 'submit'){
                this.options.page = 1;
            }
    
            this.loading = true;
    
            let filter = {...this.filter};
    
            let request = {
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                page: this.options.page,
                limit: this.options.itemsPerPage,
                work_id: this.$route.params.id
            };
        
            Object.assign(request, filter);
    
            localStorage["works-images-" + this.$root.session.id] = JSON.stringify({
                filter: this.filter,
                options: this.options,
            });
    
            Work.searchImages(request).then(response => {
    
                let data = response.data;
        
                this.total = data.total;
        
                this.items = data.data.map((item) => {
        
                    this.searching = false;
        
                    return item;
                });
        
                this.loading = false;
    
            });
        },
    },
    computed:{
        headers(){
            let he = [
                {
                    text: this.$t('globals.images'),
                    align: 'start',
                    value: 'files',
                    groupable: false,
                },
            ]
            return he
        }
    }
}
</script>