<template>
    <DialogV2 
      v-bind="$attrs"
      v-on="$listeners">
      <template #content>
        <v-row>
          <v-col cols="12" class="text-center">
            <!-- DESKTOP -->
            <div class="d-none d-lg-block">
              <v-icon color="warning" size="125">mdi-alert</v-icon>
            </div>
            <!-- MOBILE -->
            <div class="d-md-none">
              <v-icon color="warning" size="50">mdi-alert</v-icon>
            </div>
          </v-col>
          <v-col cols="12" class="text-center">
            <!-- DESKTOP -->
            <div class="d-none d-lg-block">
              <span class="warning--text text-h4 font-weight-black text-uppercase">{{$t('global_messages.attention')}}!</span>
            </div>
            <!-- MOBILE -->
            <div class="d-md-none">
              <span class="warning--text text-h6 font-weight-black text-uppercase">{{$t('global_messages.attention')}}!</span>
            </div>
          </v-col>
          <v-col cols="12" class="text-center text-body-1 font-weight-bold">
            <slot></slot>
          </v-col>
        </v-row>
      </template>
      <template #footer>
        <v-row no-gutters justify="center">
          <v-col cols="12" md="6">
            <v-btn
              color="error"
              block
              x-large
              @click="$emit('on-cancel')"
            >
              {{$t('globals.no')}}
            </v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn
              color="success"
              block
              x-large
              @click="OnSubmit"
              :disabled="disableButton"
            >
              {{$t('globals.yes')}}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </DialogV2>
</template>

<script>
  import DialogV2 from '@/components/ui/DialogV2.vue';
  export default {
    name: 'uiDialogConfirmation',
    props: {
      type: {
        type: String,
        default: 'info'
      },
    },
    data(){
      return{
        disableButton: false,
      }
    },
    components: {
      DialogV2
    },
    methods:{
      OnSubmit(){
        this.disableButton = true
        this.$emit('on-submit')
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>