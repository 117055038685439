var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:_vm.$root.$vuetify.theme.isDark ? 'secondary' : ''},[_c('v-container',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.loading)?_c('v-progress-linear',{staticClass:"global-loader",attrs:{"indeterminate":""}}):_vm._e(),_c('fieldset',{attrs:{"disabled":_vm.loading}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"code","name":_vm.$t('globals.worker'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.workers,"item-value":"id","item-text":function (item) { return item.name; },"menu-props":"auto","error-messages":errors,"label":_vm.$t('globals.worker') + ' *',"prepend-inner-icon":"mdi-account"},model:{value:(_vm.fields.worker_id),callback:function ($$v) {_vm.$set(_vm.fields, "worker_id", $$v)},expression:"fields.worker_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"date","name":_vm.$t('globals.data'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('globals.data'),"error-messages":errors,"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fields.fault_date),callback:function ($$v) {_vm.$set(_vm.fields, "fault_date", $$v)},expression:"fields.fault_date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.fields.fault_date),callback:function ($$v) {_vm.$set(_vm.fields, "fault_date", $$v)},expression:"fields.fault_date"}})],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-checkbox',{attrs:{"color":_vm.$root.$vuetify.theme.isDark ? 'white' : 'primary',"label":_vm.$t('faults.all_day')},on:{"change":_vm.changedAllDay},model:{value:(_vm.allDay),callback:function ($$v) {_vm.allDay=$$v},expression:"allDay"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"total_hours","name":_vm.$t('globals.hours'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.allDay,"type":"number","prepend-inner-icon":"mdi-form-textbox","label":_vm.$t('globals.hours') + '*',"clearable":"","error-messages":errors},model:{value:(_vm.fields.total_hours),callback:function ($$v) {_vm.$set(_vm.fields, "total_hours", $$v)},expression:"fields.total_hours"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"justified","name":_vm.$t('faults.justify'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"color":_vm.$root.$vuetify.theme.isDark ? 'white' : 'primary',"label":_vm.$t('faults.justify'),"error-messages":errors},model:{value:(_vm.fields.justified),callback:function ($$v) {_vm.$set(_vm.fields, "justified", $$v)},expression:"fields.justified"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{attrs:{"outlined":"","label":_vm.$t('faults.reason'),"prepend-inner-icon":"mdi-card-text","clearable":""},model:{value:(_vm.fields.obs),callback:function ($$v) {_vm.$set(_vm.fields, "obs", $$v)},expression:"fields.obs"}})],1)],1),_c('v-row',{staticClass:"mt-6",attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{class:(_vm.$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary') + ' mr-5 ml-5 mb-5',attrs:{"disabled":invalid,"depressed":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('globals.save'))+" ")])],1)],1)],1)],1),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":_vm.onSuccess}},[_vm._v(" "+_vm._s(_vm.$t('faults.success_add'))+" ")]),_c('ErrorSnackbar',{attrs:{"message":_vm.error.message},model:{value:(_vm.error.show),callback:function ($$v) {_vm.$set(_vm.error, "show", $$v)},expression:"error.show"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }