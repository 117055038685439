<template>
  <section class="mt-16">
    <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <rp-icon icon="rp-obras-blue-filled" class="mr-2"/> <span>{{$t('works.management_title')}}</span>
      </h1>

      <v-row>
        <v-col cols="12" md="auto">
          <rp-icon
              icon="rp-eye-blue-filled"
              class="mb-n2"
            /><v-icon class="ml-2" color="secondary" small>mdi-forward</v-icon>
            <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.visualize')}}</span>
        </v-col>
        <v-col cols="12" md="auto">
          <v-icon color="red" class="ml-4">
            mdi-delete
          </v-icon><v-icon class="ml-2" small color="secondary">mdi-forward</v-icon>
          <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.remove')}}</span>
        </v-col>
        <v-col cols="12" md="auto">
          <v-icon color="success" class="ml-4">
            mdi-file-export
          </v-icon><v-icon class="ml-2" small color="secondary">mdi-forward</v-icon>
          <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.export')}}</span>
        </v-col>
      </v-row>

      <div style="position:relative">
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t('globals.options')}}</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="info"
                  large
                  @click="searching = true"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>{{$t('globals.search')}}</span>
            </v-tooltip>
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  :to="'/' + $session.get('domain') + '/admin/works-settings/works/create'"
                  v-if="$root.session.hasPermission(['super', 'works.create'])"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{$t('globals.register')}}</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>

        <v-data-table 
          :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'"
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :expanded.sync="expanded"
          :single-expand="true"
          show-expand
          :options.sync="options"
          :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
          locale="pt-pt"
        >

        <template v-slot:item.client="{ item }">
            {{item.client != null ? item.client.name : ''}}
          </template>

          <template v-slot:item.status="{ item }">
            {{status != null ? status.find(i => i.id == item.status).name : "-"}}
          </template>

          <template v-slot:item.started_at="{ item }">
            {{getTableDate(item.started_at)}}
          </template>

          <template v-slot:item.finished_at="{ item }">
            {{getTableDate(item.finished_at)}}
          </template>

          <template v-slot:item.actions="{item}">
            <v-row>
              <v-col cols="12" md="auto"
                  v-if='$root.session.hasPermission(["super", "works.update"])'>
                <IconView
                  small
                  @click="viewWork(item)"
                />

                <IconExport
                  text="Relatório Equipamentos e Viaturas por Obra"
                  v-if="$root.session.hasPermission(['super', 'export.equipments'])"
                  @click="workToExport = item; exportingWorkEquipments = true"
                />
                
                <IconRemove
                  v-if="!item.has_diaries && $root.session.hasPermission(['super', 'works.delete']) && item.canDelete"
                  @on-submit="deleteWork(item)"
                />
              </v-col>
            </v-row>
          </template>

          <!-- EXPANDABLE ROW -->
          <template v-slot:expanded-item="{ item }">
           
            <!-- EXPORTAR REND MAO OBRA-->
            <td :colspan="headers.length">
              <v-row>
                <v-col cols="12" md="3">
                  <v-card-subtitle>{{$t('works.export_rend_mo')}}</v-card-subtitle>           
                </v-col>    
                <v-col cols="12" md="3">
                  <v-menu
                    ref="menu1"
                    v-model="modalPicker1"
                    :close-on-content-click="false"
                    :return-value.sync="datePicker1"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="datePicker1"
                        :label="$t('works.initial_date')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="datePicker1"
                      :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modalPicker1 = false"
                      >
                        {{$t('globals.cancel')}}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu1.save(datePicker1)"
                      >
                      {{$t('globals.ok')}}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>   
                <v-col cols="12" md="3">
                
                <v-menu
                  ref="menu"
                  v-model="modalPicker2"
                  :close-on-content-click="false"
                  :return-value.sync="datePicker2"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="datePicker2"
                      :label="$t('works.finished_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="datePicker2"
                    :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="modalPicker2 = false"
                    >
                      {{$t('globals.cancel')}}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(datePicker2)"
                    >
                      {{$t('globals.ok')}}
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3">
                
                
                <Tooltip
                  class="my mr-4"
                  color="success"
                  :text="$t('works.export_rend_mo_full')"
                  top
                >
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="mt-4 mb-4 md:mb-0"
                      style="float:right"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                    <v-icon
                      right
                      dark
                      class="mr-2"
                    >
                      mdi-download
                    </v-icon>
                    
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(t, index) in exportItems"
                      :key="index"
                      @click="exportMORendimento(item, t.title)"
                    >
                      <v-list-item-title>{{ t.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                    </v-menu>
                  </Tooltip>
                </v-col>
              </v-row>
              </td>
        </template>

        <!-- END EXPANDABLE ROW -->
        </v-data-table>
      </div>
      <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetWorks">
              {{$t('globals.clean_fields')}}
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchWorks">
            
            <v-list-item>
              <v-text-field
                v-model="filter.code"
                prepend-inner-icon="mdi-barcode"
                :label="$t('globals.code')"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-text-field
                v-model="filter.name"
                prepend-inner-icon="mdi-form-textbox"
                :label="$t('globals.name')"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
               <v-select
                  v-model="filter.status"
                  :items="status"
                  item-text="name"
                  item-value="id"
                  menu-props="auto"
                  :label="$t('globals.state')"
                  prepend-inner-icon="mdi-list-status"
                  clearable
                ></v-select>
            </v-list-item>

            <v-list-item v-if="!$root.session.hasRole(['encarregado'])">
               <v-select
                  v-model="filter.commissioner_id"
                  :items="commissioners"
                  :item-text="item => $t('globals.name') + ': ' + item.name + ' - ' + $t('globals.username') + ': ' + item.username"
                  item-value="id"
                  menu-props="auto"
                  :label="$t('globals.in_charge')"
                  prepend-inner-icon="mdi-account-hard-hat"
                  clearable
                ></v-select>
            </v-list-item>

            <v-list-item v-if="false">
               <v-select
                  v-model="filter.overseer_id"
                  :items="overseers"
                  :item-text="item => $t('globals.name') + ': ' + item.name + ' - ' + $t('globals.username') + ': '+ item.username"
                  item-value="id"
                  menu-props="auto"
                  :label="$t('globals.equipment_responsible')"
                  prepend-inner-icon="mdi-account-hard-hat"
                  clearable
                ></v-select>
            </v-list-item>

            <v-list-item v-if="!$root.session.hasRole(['dto'])">
               <v-select
                  v-model="filter.engineer_id"
                  :items="engineers"
                  :item-text="item => $t('globals.name') + ': ' + item.name + ' - ' + $t('globals.username') + ': '+ item.username"
                  item-value="id"
                  menu-props="auto"
                  :label="$t('globals.engineer')"
                  prepend-inner-icon="mdi-account-tie"
                  clearable
                ></v-select>
            </v-list-item>

            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              {{$t('globals.search')}}
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
      </v-navigation-drawer>
      <DialogSuccess :opened="started" @on-ok="started = false" >
        {{$t('works.started_export')}}
      </DialogSuccess>

      <Dialog 
        :opened="exportingWorkEquipments">
        <template #content>
          <v-container fluid>
            <v-card-title class="mt-n8">{{$t('works.choose_interval')}}</v-card-title>
            <v-row>
              <v-col cols="12" md="6">
                <v-menu
                  v-model="equipment_export_started_at"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :label="$t('globals.start_date')"
                        v-model="equipment.export.started_at"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="equipment.export.started_at = null"
                      ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="equipment.export.started_at"
                    :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                    locale="pt-pt"
                    @input="equipment_export_started_at = false;"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6">
                <v-menu
                  v-model="equipment_export_finished_at"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :label="$t('globals.end_date')"
                        v-model="equipment.export.finished_at"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="equipment.export.finished_at = null"
                      ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="equipment.export.finished_at"
                    :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                    locale="pt-pt"
                    @input="equipment_export_finished_at = false;"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template #footer>
            <v-btn
              color="error"
              depressed
              @click="exportingWorkEquipments = false"
            >
              {{$t('globals.cancel')}}
            </v-btn>
            <v-btn
              color="primary"
              depressed
              @click="exportEquipmentWork()"
            >
              {{$t('globals.export')}}
            </v-btn>
        </template>
      </Dialog>
      <ErrorSnackbar v-model="error.show" :message="error.message"/>
      <Loader v-if="exportingDocument"></Loader>  
  </section>
</template>
<script>
import Loader from '@/components/ui/Loader.vue'
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import IconExport from '@/components/ui/IconExport.vue';
import Dialog from '@/components/ui/Dialog.vue';
import Work from "@/api/Work.js";
import ClientUsers from "@/api/ClientUsers.js";
import Export from "@/api/Export.js";
import dayjs from 'dayjs';
import Tooltip from '@/components/ui/Tooltip.vue';
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';

export default {
  components: {
    IconRemove,
    IconView,
    DialogSuccess,
    IconExport,
    Tooltip,
    Dialog,
    ErrorSnackbar,
    Loader
  },
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchWorks()}, 10);
      },
      deep: true,
    },
  },
  beforeCreate(){
    document.title = "Report Lake - " + this.$t('works.management_title');
  },
  data: () => ({
    exportingDocument: false, 
    exportItems:[
      {
        'title': 'PDF'
      },
      {        
        'title': 'Excel'
      }
    ],
    expanded: [],
    modalPicker1: false,
    modalPicker2: false,
    datePicker1: null,
    datePicker2: null,
    error: {
      title: '',
      message: '',
      show:false,
    },
    workToExport: null,
    exportingWorkEquipments: false,
    equipment_export_started_at: false,
    equipment_export_finished_at: false,
    equipment: {
      export: {
        started_at: null,
        finished_at: null,
        language: null
      }
    },
    items: [],
    total: 0,
    loading: false,
    searching: false,
    options: {},
    speedDial:false,
    filter: {
      code:null,
      name: null,
      commissioner_id:null,
      overseer_id:null,
      engineer_id:null,
      status: null,
    },
    status: [],
    commissioners:[],
    overseers:[],
    engineers:[],
    started:false,
    finished:false,
    exporting: false,
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "works.read"])) {
      this.$router.push('/admin');
    }


    this.fillBaseData();
  },
  methods:{
    firstDateChangedExpandable(e){
        let dt = new Date(e)
        dt.setDate(dt.getDate() + 30);

        let month = new Date(e).getMonth() + 1
        let currentMonth = new Date().getMonth() + 1

        if(month == currentMonth)
            dt = new Date()

        this.datePicker2 = dt.toISOString().substr(0, 10)
    },
    firstDateChanged(e){
        let dt = new Date(e)
        dt.setDate(dt.getDate() + 30);

        let month = new Date(e).getMonth() + 1
        let currentMonth = new Date().getMonth() + 1

        if(month == currentMonth)
            dt = new Date()

        this.equipment.export.finished_at = dt.toISOString().substr(0, 10)
    },
    async exportMORendimento(i, type){
      this.exportingDocument = true
      let data = {
        type: type,
        data1: this.datePicker1,
        data2: this.datePicker2
      }
      if(this.datePicker1 == null || this.datePicker2 == null){
        this.error.message = this.$t('works.please_interval')
        this.error.show = true
        return
      }
      await Export.exportMORendimentos(i.id, data)
        .then((resp) => {
          window.open(resp.data.url, '_blank')
            Export.delete(resp.data.path)
              .then(() => {
                this.exportingDocument = false
              });
          
        });
      return ''
    },
    getTableDate(date) {
      return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
    },
    fillBaseData() {

      if(localStorage["works-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["works-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }


      // Exportações. Fim é o dia de hoje o Inicio são 30 dias antes, por defeito
      let dt = new Date(new Date().toISOString().substr(0, 10))
      dt.setDate(dt.getDate() - 30);
      this.equipment.export.finished_at = new Date().toISOString().substr(0, 10);
      this.equipment.export.started_at = dt.toISOString().substr(0, 10);
      this.equipment.export = this.$store.state.language

      Work.listStatus().then(({data}) => {
        this.status = Object.keys(data).map(item => {return {id: item, name: data[item]}});
      });

      ClientUsers.list().then(({data}) => {
        this.commissioners = data.filter(item => item.roles.some(role => role.name == "encarregado"));

        this.overseers = data.filter(item => item.roles.some(role => role.name == "maquinista"));

        this.engineers = data.filter(item => item.roles.some(role => role.name == "dto"));
      });
    },
    searchWorks(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        page: this.options.page,
        limit: this.options.itemsPerPage,
      };
    
      Object.assign(request, filter);

      localStorage["works-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      Work.search(request).then(response => {

        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });

        this.loading = false;

      });
    },
    resetWorks()
    {
      this.filter = {
        is_active: 1
      };

      this.searchWorks();
    },
    viewWork(item){
      let domain = '/' + this.$session.get('domain')
      let path = domain + '/admin/works-settings/works/' + item.id


      this.$router.push(path);
    },
    deleteWork(item){
      Work.delete(item.id)
        .then(() => {
          this.searchWorks();
        });
    },
    async exportEquipmentWork() {
      this.exportingDocument = true
      await Work.exportEquipments(this.workToExport.id, this.equipment.export)
        .then((resp) => {
          this.exportingWorkEquipments = false;

          this.exportingDocument = false

          if(resp.data.success)
            if(resp.data.object != null)
              window.open(resp.data.object)
            else{
              this.error.message = this.$t('works.no_data')
              this.error.show = true
            }
        });
    }
  },
  computed: {
    headers(){
      let h = [
        {
          text: this.$t('globals.code'),
          sortable: true,
          value: 'code'
        },
        {
          text: this.$t('globals.name'),
          sortable: true,
          value: 'name',
        },
        {
          text: this.$t('globals.client'),
          sortable: false,
          value: 'client',
        },
        {
          text: this.$t('globals.state'),
          sortable: true,
          value: 'status',
        },
        {
          text: this.$t('globals.started_at'),
          sortable: true,
          value: 'started_at',
        },
        {
          text: this.$t('globals.ended_at'),
          sortable: true,
          value: 'finished_at',
        },
        { 
          text: this.$t('globals.options'),
          value: 'actions', 
          width: '200px',
          sortable: false 
        },
      ]

      return h
    },
    tableFooter(){
      let t = this.$t("globals.lines_datatable")
      let a = [5, 10, 15, 20];
      return {
        'items-per-page-options': a,
        'items-per-page-text': t
      }
    }, 
    crumbs: function() {
      return [
        {
          text: this.$t('menu.dashboard'),
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: this.$t('menu.works_management'),
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
