<template>
    <section class="mt-16">
    
        <div style="position:relative">
          
  
          <v-data-table 
            :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'"
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
            locale="pt-pt"
          >
  
            <template v-slot:item.start="{item}">
              {{fixHour(item.worked_hours.started_hour)}}:{{fixHour(item.worked_hours.started_minute)}}h
            </template>

            <template v-slot:item.end="{item}">
              {{fixHour(item.worked_hours.finished_hour)}}:{{fixHour(item.worked_hours.finished_minute)}}h
            </template>
  
            <template v-slot:item.actions="{item}">
              <v-row>
                <v-col cols="12" md="auto"
                    v-if='$root.session.hasPermission(["super", "diaries.update"])'>
                  <IconView
                    small
                    @click="viewDiary(item)"
                  />
                </v-col>
              </v-row>
            </template>
  
            
          </v-data-table>
        </div>
        <v-navigation-drawer
            fixed
            right
            v-model="searching"
            hide-overlay
            width="520"
            class="pt-6"
          >
          <v-list>
            <v-list-item>
              <v-btn fab small color="secondary" @click="searching = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
  
              <v-btn absolute right small color="error" @click="resetDiaries">
                {{$t('globals.clean_fields')}}
              </v-btn>
            </v-list-item>
          </v-list>
          <v-list class="pt-10">
            <v-form @submit.prevent="searchDiaries">
              
             
  
              
              <v-row
                class="mt-10"
                align="center"
                justify="space-around"
              >
              <v-btn
                color="primary"
                type="submit"
              >
                {{$t('globals.search')}}
              </v-btn>
              </v-row>
            </v-form>
          </v-list>
        </v-navigation-drawer>  
        
        <ErrorSnackbar v-model="error.show" :message="error.message"/>
    </section>
  </template>
  <script>
  import IconView from '@/components/ui/IconView.vue';
  import Worker from "@/api/Worker.js";
  import dayjs from 'dayjs';
  import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
  
  export default {
    components: {
      IconView,
      ErrorSnackbar,
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchDiaries()}, 10);
        },
        deep: true,
      },
    },
    beforeCreate(){
      document.title = "Report Lake - " + this.$t('globals.diaries');
    },
    data: () => ({
      modalPicker1: false,
      modalPicker2: false,
      datePicker1: null,
      datePicker2: null,
      error: {
        title: '',
        message: '',
        show:false,
      },
      items: [],
      total: 0,
      loading: false,
      searching: false,
      options: {},
      speedDial:false,
      filter: {
        code:null,
        name: null,
        commissioner_id:null,
        overseer_id:null,
        engineer_id:null,
        status: null,
      },
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "diaries.read"])) {
        this.$router.push('/admin');
      }
  
  
      this.fillBaseData();
    },
    methods:{
      fixHour(h){
        if(h < 10)
          return "0" + h

        return h
      },
      getTableDate(date) {
        return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
      },
      fillBaseData() {
  
        if(localStorage["workers-diaries-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["workers-diaries-" + this.$root.session.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
      },
      searchDiaries(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
  
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          worker_id: this.$route.params.id
        };
      
        Object.assign(request, filter);
  
        localStorage["workers-diaries-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        Worker.searchDiaries(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });
  
          this.loading = false;
  
        });
      },
      resetDiaries()
      {
        this.filter = {
          
        };
  
        this.searchDiaries();
      },
      viewDiary(item){
        let domain = '/' + this.$session.get('domain')
        let path = domain + '/admin/diaries/' + item.id
  
  
        this.$router.push(path);
      },
    },
    computed: {
        headers(){
          let he = [
            {
            text: this.$t('globals.code'),
            sortable: true,
            value: 'code'
            },
            {
              text: this.$t('globals.data'),
              sortable: true,
              value: 'date'
            },
            {
              text: this.$t('globals.work'),
              sortable: true,
              value: 'work_object.name'
            },
            {
              text: this.$t('globals.start') + '(h)',
              sortable: true,
              value: 'start'
            },
            {
              text: this.$t('globals.end') + '(h)',
              sortable: true,
              value: 'end'
            },
            { 
              text: this.$t('globals.options'), 
              value: 'actions', 
              width: '200px',
              sortable: false 
            },
          ]
          return he
        },
        tableFooter(){
          let t = this.$t("globals.lines_datatable")
          let a = [5, 10, 15, 20];
          return {
            'items-per-page-options': a,
            'items-per-page-text': t
          }
        }, 
        crumbs: function() {
            return [
            {
                text: this.$t('menu.dashboard'),
                disabled: false,
                to: '/admin',
                exact: true,
            },
            {
                text: this.$t('subcontractors.crumb_subcontractors'),
                disabled: true,
                to: window.location.href,
                exact: true,
            },
            ];
        }
    }
  };
  </script>
  