<template>
    <v-card>
        <v-card-title class="primary white--text">
            Gestão de anexos de auto
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12">                    
                    <v-file-input
                        chips
                        multiple
                        v-model="files"
                        label="Uploads"
                    ></v-file-input>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="3" v-for="item in fields.attachments" :key="item.id">
                    <v-card height="175px">
                        <v-card-text class="text-center">
                            <v-icon>{{$getIconDocument(item.path)}}</v-icon>{{$getFileExtension(item.path)}}
                        </v-card-text>
                        <v-card-text class="text-caption text-center">
                            <v-row>
                                <v-col cols="12">
                                    {{$getFilename(item.path)}}
                                </v-col>
                                <v-col cols="12">                                    
                                    <v-btn @click="downloadFile(item)" text style="display: block; margin: auto;">
                                        Download
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="text-center">
            <v-btn class="success" style="display: block; margin: auto;" @click="saveAttachments">{{$t('globals.save')}}</v-btn>
        </v-card-actions>
        
    <DialogSuccess :opened="success" @on-ok="success = false" >
      {{$t('self_measuring.success')}}
    </DialogSuccess>
    </v-card>
</template>
<script>
import SelfMeasuring from "@/api/SelfMeasuring.js";
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default{
    name:"Attachments",
    components:{
        DialogSuccess
    },
    props:{
        fields: Object,
        method: {
            typeof: String,
            default: 'update'
        }
    },
    data(){
        return{
            files: [],
            success: false,
            creation: false,
        }
    },
    methods:{
        async saveAttachmentsCreate(files, id){
            this.files = files
            this.creation = true
            await this.saveAttachments(id)
        },
        downloadFile(item){
            window.open(item.download_path, '_blank')
        },
        async saveAttachments(id = null){
            if(this.creation == false){
                this.$emit('close', this.files)
                return;
            }

            const formData = new FormData()
            formData.append('id', id == null ? this.$route.params.id : id)

            
            if(this.files != null){
                for (var i = 0; i < this.files.length; i++ ){
                    let file = this.files[i];
                    formData.append('files[]', file);
                } 
            }
            
            
            await SelfMeasuring.saveAttachments(formData, id == null ? this.$route.params.id : id).then(() => {
                this.success = true
                this.$emit('reload')
                this.files = null
            })
        }
    }
}
</script>