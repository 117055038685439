<template>
    <v-row>
        <v-col cols="12">
        <div class="d-flex flex-row align-center">
            <h2 class="secondary--text subtitle-1">
            Unidade de Obra
            </h2>
            <v-spacer />
        </div>
        <v-simple-table dense class="d-none d-lg-block">
            <template v-slot:default>
            <thead>
                <tr>
                <th class="text-center rounded-pill rounded-r-0" style="width:40%;">
                    Nome
                </th>
                <th class="text-center">
                    Código
                </th>
                <th class="text-center">
                    Medição
                </th>
                <th class="text-center">
                    Unidade
                </th>
                <th class="text-center rounded-pill rounded-l-0">
                    Opções
                </th>
                </tr>
            </thead>
            <tbody :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                <tr v-for="work_unit in template_fields.units" v-bind:key="work_unit.id">
                <td class="text-center">
                    <validation-provider v-slot="{ errors }" vid="action_name" :name="$t('globals.work_unit')" rules="required">
                        <v-combobox
                            v-model="work_unit.object"
                            :label="$t('globals.work_unit') + ' *'"
                            :items="units"
                            @input="work_unit.id = $event.id; work_unit.code = $event.code; work_unit.unit = $event.unit; work_unit.unit_id = $event.id"
                            item-value="id"
                            item-text="name"
                            hide-selected
                            :error-messages="errors"
                        >
                        </v-combobox>
                    </validation-provider>
                </td>
                <td class="text-center">
                    <v-combobox
                        v-model="work_unit.object"
                        :label="$t('globals.code') + ' *'"
                        :items="units"
                        @input="work_unit.id = $event.id; work_unit.code = $event.code; work_unit.unit = $event.unit; work_unit.unit_id = $event.id"
                        item-value="id"
                        item-text="code"
                        hide-selected
                    >
                    </v-combobox>
                </td>
                <td class="text-center">
                    <validation-provider v-slot="{ errors }" vid="measure" :name="$t('globals.measure')" rules="required">
                    <v-text-field
                        v-model="work_unit.measure"
                        :label="$t('globals.measure') + ' *'"
                        :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                </td>
                <td class="text-center">
                    {{work_unit.object == null ? '-' : work_unit.object.unit}}
                </td>
                <td class="text-center">
                    <v-col cols="12">
                        <v-btn
                        width="100px"
                        dark
                        small
                        color="secondary"
                        @click="error.show = true"
                        >
                        <small>{{$t('globals.work_hand')}}</small>  
                        <v-icon small>mdi-account-hard-hat</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" class="mt-n2">                    
                        <v-btn
                        width="100px"
                        dark
                        small
                        color="secondary"
                        @click="error.show = true"
                        >
                        <small>{{$t('globals.machines')}}</small>
                        <v-icon small>mdi-bulldozer</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" class="mt-n2">                       
                        <v-btn
                        width="100px"
                        dark
                        small
                        color="error"
                        @click="removeWorkUnit(work_unit)"
                        >
                        <small>{{$t('globals.remove')}}</small>
                        <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                    </v-col>
                </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
        <v-data-iterator
            :items="template_fields.units"
            item-key="id"
            :items-per-page="-1"
            hide-default-footer
            class="d-block d-lg-none"
        >
            <template v-slot:default="{ items }">
            <v-row>
                <v-col
                v-for="work_unit in items"
                :key="work_unit.id"
                cols="12"
                sm="6"
                md="4"
                lg="3"
                >
                <v-card>
                    <v-list
                    dense
                    >
                    <v-list-item>
                        <v-list-item-content>{{$t('globals.name')}}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <validation-provider v-slot="{ errors }" vid="action_name" :name="$t('globals.measure')" rules="required">
                            <v-combobox
                            v-model="work_unit.object"
                            :label="$t('globals.measure')  + ' *'"
                            :items="units"
                            @input="work_unit.id = $event.id; work_unit.code = $event.code; work_unit.unit = $event.unit; work_unit.unit_id = $event.id"
                            item-value="id"
                            item-text="name"
                            hide-selected
                            :error-messages="errors"
                            >
                            </v-combobox>
                        </validation-provider>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>{{$t('globals.code')}}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                            <v-combobox
                                v-model="work_unit.object"
                                :label="$t('globals.code')  + ' *'"
                                :items="units"
                                @input="work_unit.id = $event.id; work_unit.code = $event.code; work_unit.unit = $event.unit; work_unit.unit_id = $event.id"
                                item-value="id"
                                item-text="code"
                                hide-selected
                            >
                            </v-combobox>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>{{$t('globals.measure')}}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <validation-provider v-slot="{ errors }" vid="measure" :name="$t('globals.measure')" rules="required">
                            <v-text-field
                            v-model="work_unit.measure"
                            :label="$t('globals.measure')  + ' *'"
                            :error-messages="errors"
                            ></v-text-field>
                        </validation-provider>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>{{$t('globals.unit')}}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        {{work_unit.unit}}
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>{{$t('globals.work_hand')}}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <v-btn
                            dark
                            small
                            block
                            color="secondary"
                            @click="error.show = true"
                        >
                            <v-icon small>mdi-account-hard-hat</v-icon>
                        </v-btn>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Máquinas:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <v-btn
                            dark
                            small
                            block
                            color="secondary"
                            @click="error.show = true"
                        >
                            <v-icon small>mdi-bulldozer</v-icon>
                        </v-btn>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>{{$t('globals.options')}}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <v-btn
                            dark
                            small
                            color="error"
                            @click="removeWorkUnit(work_unit)"
                        >
                            <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list>
                </v-card>
                </v-col>
            </v-row>
            </template>
        </v-data-iterator>
        <v-row justify="center">
            <v-btn
            fab
            dark
            small
            color="primary"
            class="mt-7"
            @click="addWorkUnit"
            >
            <v-icon small>mdi-plus</v-icon>
            </v-btn>
        </v-row>
        </v-col>
        <ErrorSnackbar v-model="error.show" :message="error.message"/>
        <DialogConfirmation 
            v-if="openConfirmationDialog" 
            title="Remover Item" 
            type="warning" 
            :opened="openConfirmationDialog" 
            @on-submit="submitRemove" 
            @on-cancel="openConfirmationDialog = false">
                {{$t('global_messages.are_you_sure_remove')}}
        </DialogConfirmation>
    </v-row>
</template>
<script>
import dayjs from 'dayjs'
import DialogConfirmation from '@/components/ui/DialogConfirmation.vue';
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';

export default {
    components:{
        DialogConfirmation,
        ErrorSnackbar
    },
    props: {
        template_fields: Object,
        units: Array
    },
    data: () => ({
        openConfirmationDialog: false,
        error:{
            show: false,
            message: 'Ação indisponível em template'
        },
        toSend:{
            id: null
        }
    }),
    mounted(){
    },
    methods:{
        addWorkUnit() {
            this.template_fields.units.push({
                id: dayjs().unix(),
                name: "",
                code: "-",
                object: null,
                measure: 0,
                unit: "-"
            });
        },
        getWorkUnitIndex(id) {
            return this.template_fields.units.findIndex(item => item.id == id);
        },
        removeWorkUnit(workUnitObject) {
            this.toSend.id = workUnitObject.id
            this.openConfirmationDialog = true
        },
        async submitRemove(){
            await this.$emit('remove', this.toSend.id)
            this.openConfirmationDialog = false
        }
    }
}
</script>
