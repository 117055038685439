<template>
    <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
        <v-container>
            <validation-observer ref="form" v-slot="{ invalid }">
            <v-form @submit.prevent="submit"  class="mb-16">
                <v-progress-linear v-if="loading"
                    indeterminate
                    class="global-loader"
                ></v-progress-linear>
                <fieldset :disabled="loading">
                    <v-row>
                        <v-col cols="12" md="3">
                            <validation-provider v-slot="{ errors }" vid="reference" :name="$t('globals.reference')" rules="required">
                                <v-text-field
                                v-model="fields.reference"
                                prepend-inner-icon="mdi-account-details"
                                :label="$t('globals.reference') + '*'"
                                clearable
                                :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="12">
                            <validation-provider v-slot="{ errors }" vid="name" :name="$t('globals.name')" rules="required">
                                <v-text-field
                                v-model="fields.name"
                                prepend-inner-icon="mdi-account-details"
                                :label="$t('globals.name') + '*'"
                                clearable
                                :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="12">
                            <validation-provider v-slot="{ errors }" vid="description" :name="$t('globals.description')" rules="required">
                                <v-text-field
                                v-model="fields.description"
                                prepend-inner-icon="mdi-account-details"
                                :label="$t('globals.description') + '*'"
                                clearable
                                :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="3">
                            <validation-provider v-slot="{ errors }" vid="unit" :name="$t('globals.unit')" rules="required">
                              <v-select
                                    v-model="fields.unit_management_id"
                                    :items="units"
                                    item-value="id"
                                    :item-text="item => item.abbreviation"
                                    menu-props="auto"
                                    :error-messages="errors"
                                    :label="$t('globals.unit') + ' *'"
                                    prepend-inner-icon="mdi-account"
                                ></v-select>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="1">
                          <Tooltip
                              color="primary"
                              :text="$t('shoppings.add_new_unit')"
                              bottom
                          >
                          <v-btn :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary'" fab small @click="addUnit = true">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                          </Tooltip>
                        </v-col>
                        <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" vid="cost" :name="$t('globals.cost')" rules="|">
                              <v-text-field
                                :disabled="true"
                                v-model="fields.cost"
                                prepend-inner-icon="mdi-account-details"
                                :label="$t('globals.cost')"
                                clearable
                                :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" vid="stock" :name="$t('globals.stock')" rules="|">
                              <v-text-field
                                :disabled="true"
                                v-model="fields.stock"
                                prepend-inner-icon="mdi-account-details"
                                :label="$t('globals.stock')"
                                clearable
                                :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                
                <v-row class="mt-6" align="center" justify="space-around">
                    <v-btn :disabled="invalid" depressed color="primary" type="submit">
                    {{$t('globals.save')}}
                    </v-btn>
                </v-row>
                </fieldset>
            </v-form>
            <DialogSuccess :opened="success" @on-ok="onSuccess" >
                {{$t('shoppings.material_saved')}}
            </DialogSuccess>
            </validation-observer>
        </v-container>
        <v-dialog
          v-model="addUnit"
          max-width="800px"
        >
          <AddUnit 
            v-if="addUnit"
            @close="addUnit = false"
            @close-update="fillBaseData()"
          />
        </v-dialog>
    </v-card>
  </template>
  <script>
  import Materials from "@/api/Materials.js";
  import Company from "@/api/Company.js";
  import AddUnit from '@/components/units_management/Add.vue';
  import UnitsManagement from "@/api/UnitsManagement.js"
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  import Tooltip from '@/components/ui/Tooltip.vue';
  
  export default {
    name: 'MaterialComponent',
    components: {
      AddUnit,
      DialogSuccess,
      Tooltip,
    },
   watch: { 
    material: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;

          this.total = this.fields.invoices.length
  
          this.fields.id = data.id;
        }
      },
    },
    beforeCreate(){
    },
    data: () => ({
        addUnit: false,
        pickerInicio: false, 
        addInvoice: false,
      options: {},
      total: 0,
      panel: 0,
      fields: {
        id: null,
        descritpion: null,
        name: null,
        unit_management_id:null,
        cost: null,
        reference: null
      },
      loading:false,
      error: {
        title: '',
        message: '',
      },
      success: false,
      units:[],
      companies: [],
    }),
    mounted(){
      this.fillBaseData();
    },
    methods:{
      calculateCost(place){
        if(place == 'cost')
          this.newInvoice.total_price = (this.newInvoice.cost * this.newInvoice.qty).toFixed(2)
        else
          this.newInvoice.cost = (this.newInvoice.total_price / this.newInvoice.qty).toFixed(2)
        
      },
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        
          this.$emit('reload')
      },
      fillBaseData(){
        UnitsManagement.list().then(({data}) => {
            this.units = data
        });

        Company.list().then(({data}) => {
            this.companies = data
        });
      },
      submit() {
  
        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }
  
          let data = {...this.fields};
  
         
  
          Materials['create'](data, this.fields.id).then(() => {
  
            this.success = true;
  
            this.loading = false;

            
          }).catch(err => {
            this.loading = false;
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = this.$t('globals.error') + " " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })
      }
    },
    computed: {
      headers(){
        let he = [
          {
            text: this.$t('invoices.invoice_date'),
            sortable: true,
            value: 'shopping_date',
          },
          {
            text: this.$t('globals.quantity'),
            sortable: true,
            value: 'qty',
          },
          {
            text: this.$t('globals.unit_price'),
            sortable: true,
            value: 'cost',
          },
          {
            text: this.$t('globals.total_cost'),
            sortable: true,
            value: 'total_price',
          },
          {
            text: this.$t('globals.supplier'),
            sortable: true,
            value: 'supplier'
          },
          { 
            text: this.$t('globals.options'), 
            value: 'actions', 
            sortable: false 
          },
        ]
        return he
      },
      tableFooter(){
        let t = this.$t("globals.lines_datatable")
        let a = [5, 10, 15, 20];
        return {
          'items-per-page-options': a,
          'items-per-page-text': t
        }
      },       
    }
  };
  </script>
  