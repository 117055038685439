import BaseApi from "./BaseApi";

export default class LunchHour extends BaseApi{
    construct() {}
    
    static async list(tenant = null) {
        let url = LunchHour.getFullApiUrl(tenant) + "list";

        return LunchHour.getAxiosInstance().get(url);
    }

    static async search(params) {
        return await LunchHour.getAxiosInstance().get(LunchHour.getFullApiUrl(),{params: params});
    }

    static async create(params) {
        return await LunchHour.getAxiosInstance().post(LunchHour.getFullApiUrl(),params);
    }

    static async update(params, id) {
        return await LunchHour.getAxiosInstance().put(LunchHour.getFullApiUrl() + id,params);
    }

    static async delete(id) {
        return await LunchHour.getAxiosInstance().delete(LunchHour.getFullApiUrl() + id);
    }

    static async find(id) {
        return await LunchHour.getAxiosInstance().get(LunchHour.getFullApiUrl() + id);
    }

    static async deleteHourFromUser(id){
        let url = LunchHour.getFullApiUrl() + "from-user/";

        return await LunchHour.getAxiosInstance().delete(url + id);
    }

    static async deleteHourFromWorker(id){
        let url = LunchHour.getFullApiUrl() + "from-worker/";

        return await LunchHour.getAxiosInstance().delete(url + id);
    }

    static async addHourToUser(params){
        let url = LunchHour.getFullApiUrl() + "to-user";

        return await LunchHour.getAxiosInstance().post(url, params);
    }

    static async addHourToWorker(params){
        let url = LunchHour.getFullApiUrl() + "to-worker";

        return await LunchHour.getAxiosInstance().post(url, params);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/lunch-hour/";
    }

    static getFullApiUrl(d = null){
        let domain = location.pathname.split('/')[1]
        if(d != null)
            domain = d
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/lunch-hour/";
    }
}