<template>
  <section class="mt-16">
    <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <rp-icon icon="rp-diagram-blue-filled" class="mr-2"/> <span>{{$t('menu.roles_management')}}</span>
      </h1>

      <v-row>
        <v-col cols="12" md="auto">
          <rp-icon
              icon="rp-eye-blue-filled"
              class="mb-n2"
          /><v-icon class="ml-2" color="secondary" small>mdi-forward</v-icon>
          <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.visualize')}}</span>
        </v-col>
        <v-col cols="12" md="auto">
          <v-icon color="error">
            mdi-delete
          </v-icon><v-icon class="ml-2" color="secondary" small>mdi-forward</v-icon>
          <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.remove')}}</span>
        </v-col>
      </v-row>
      

     

      <div style="position:relative">
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t('globals.options')}}</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="info"
                  large
                  @click="searching = true"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>{{$t('globals.search')}}</span>
            </v-tooltip>
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  :to="'/' + $session.get('domain') + '/admin/accesses/roles/create'"
                  v-if="$root.session.hasPermission(['super', 'roles.create'])"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{$t('globals.register')}}</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
          locale="pt-pt"
        >

          <template v-slot:item.actions="{item}">
            <IconView
              class="mr-2"
              @click="viewRole(item)"
            />
            <IconRemove
              v-if="item.canDelete"
              class="mr-2"
              @on-submit="deleteRole(item)"
            />
          </template>
        </v-data-table>
      </div>
      <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetRoles">
              {{$t('globals.clean_fields')}}
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchRoles">
            
            <v-list-item>
              <v-text-field
                v-model="filter.name"
                prepend-inner-icon="mdi-account-details"
                :label="$t('globals.code')"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-text-field
                v-model="filter.display_name"
                prepend-inner-icon="mdi-account-details"
                :label="$t('globals.name')"
                clearable
              ></v-text-field>
            </v-list-item>
            <v-list-item>
              <v-select
                v-model="filter.user_id"
                :items="users"
                :item-text="item => $t('globals.name') + ': ' + item.name + ' - ' + $t('globals.username') + ': '+ item.username"
                item-value="id"
                menu-props="auto"
                :label="$t('globals.user')"
                prepend-inner-icon="mdi-account"
                clearable
              ></v-select>  
            </v-list-item>
            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              {{$t('globals.search')}}
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
      </v-navigation-drawer>
  </section>
</template>
<script>
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import Role from "@/api/Role.js";
import User from "@/api/User.js";

export default {
  components: {
    IconRemove,
    IconView
  },
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchRoles()}, 10);
      },
      deep: true,
    },
  },
  beforeCreate(){
    document.title = "Report Lake - " + this.$t('menu.roles_management');
  },
  data: () => ({
    items: [],
    total: 0,
    loading: false,
    searching: false,
    options: {},
    speedDial:false,
    filter: {
      name: null,
      display_name:null,
      user_id:null,
    },
    users:[]
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "roles.read"])) {
      this.$router.push('/admin');
    }

    User.list({is_active: 1}).then(({data}) => {
      this.users = data;
    });

    this.fillBaseData();
  },
  methods:{
    fillBaseData() {

      if(localStorage["roles-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["roles-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }
    },
    searchRoles(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        page: this.options.page,
        limit: this.options.itemsPerPage,
      };
    
      Object.assign(request, filter);

      localStorage["roles-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      Role.search(request).then(response => {
        
        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });

        this.loading = false;

      });
    },
    resetRoles()
    {
      this.filter = {};

      this.searchRoles();
    },
    viewRole(item){
      let domain = '/' + this.$session.get('domain')
      this.$router.push(domain + '/admin/accesses/roles/' + item.id);
    },
    deleteRole(item){
      Role.delete(item.id)
        .then(() => {
          this.searchRoles();
        });
    },
  },
  computed: {
    headers(){
      let he = [
        {
          text: this.$t('globals.code'),
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('globals.name'),
          sortable: false,
          value: 'display_name',
        },
        { 
          text: this.$t('globals.options'), 
          value: 'actions', 
          sortable: false 
        },
      ]
      return he
    },
    tableFooter(){
      let t = this.$t("globals.lines_datatable")
      let a = [5, 10, 15, 20];
      return {
        'items-per-page-options': a,
        'items-per-page-text': t
      }
    }, 
    crumbs: function() {
      return [
        {
          text: this.$t('globals.definitions'),
          disabled: false,
          to: '/' + this.$session.get('domain') + '/admin/settings/general',
          exact: true,
        },
        {
          text: this.$t('menu.roles_management'),
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
