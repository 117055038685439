<template>
    <v-container fluid>
        <div v-if="viewOnly == false">
            <div class="mb-8">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="6">
                            <h3 class="primary--text">Lista de Subempreitadas</h3>
                        </v-col>
                        <v-col cols="12" md="6" class="mt-n8">
                            <v-btn
                                fab
                                dark
                                small
                                style="float: right;"
                                color="primary"
                                class="mt-7"
                                id="workHands"
                                @click="newSubempreitada"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="12">
                            <!--<v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Subempreiteiro
                                            </th>
                                            <th class="text-left">
                                                Descrição
                                            </th>
                                            <th class="text-left">
                                                Valor
                                            </th>
                                            <th class="text-left">
                                                Data
                                            </th>
                                            <th class="text-left">
                                                Proposta
                                            </th>
                                            <th class="text-left">
                                                Fatura
                                            </th>
                                            <th class="text-left">
                                                Opções
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(element, index) in subempreitadas" :key="index">
                                        <tr>
                                            <td>
                                                {{ element.subempreiteiro }}
                                            </td>
                                            <td>
                                                {{ element.description }}
                                            </td>
                                            <td>
                                                {{ element.value }}
                                            </td>
                                            <td>
                                                {{ element.starting_date }}
                                            </td>
                                            <td>
                                                {{ element.proposal }}
                                            </td>
                                            <td>
                                                DOING
                                            </td>
                                            <td>
                                                <v-row>
                                                    <v-col cols="12" md="1">
                                                        <IconView
                                                            class="mr-2"
                                                            @click="openSubEmpreitada(element)"                         
                                                        />
                                                    </v-col>
                                                    <v-col cols="12" md="1">
                                                        <IconRemove class="mr-2" @on-submit="removeSubEmpreitada(element)"/>
                                                    </v-col>
                                                </v-row>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>-->
                            <v-data-table 
                                :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'"
                                :headers="headers"
                                :items="items"
                                :server-items-length="total"
                                :loading="loading"
                                :footer-props="tableFooter"
                                :options.sync="options"
                                :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
                                locale="pt-pt"
                            >
                                <template v-slot:item.proposal="{item}">                                
                                    <v-btn v-if="item.proposal!=null" rounded text small class="success" @click="downloadProposal(item)">
                                        <v-icon small color="white">mdi-download-box</v-icon>
                                    </v-btn>
                                    <span v-else>-</span>
                                </template>

                                <template v-slot:item.invoice_path="{item}">                               
                                    <v-btn v-if="item.invoice_path!=null" rounded text small class="success" @click="downloadInvoice(item)">
                                        <v-icon small color="white">mdi-download-box</v-icon>
                                    </v-btn>   
                                    <span v-else>-</span>                             
                                </template>

                                <template v-slot:item.actions="{item}">
                                    <v-row>
                                        <v-col cols="12" md="1">
                                            <IconView
                                                class="mr-2"
                                                @click="openSubEmpreitada(item)"                         
                                            />
                                        </v-col>
                                        <v-col cols="12" md="1">
                                            <IconRemove class="mr-2" @on-submit="removeSubEmpreitada(item)"/>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
            <v-dialog
                v-model="dialog"
                width="800px"
            >
            <v-toolbar class="primary white--text">Subempreitada</v-toolbar>
                <v-card style="width: 100%; height: 100%;">
                    <v-card-text>
                        <validation-observer ref="form" v-slot="{ invalid }">
                            <v-form @submit.prevent="submit" >
                                <fieldset :disabled="loading">
                                    <v-row class="mt-8">
                                        <v-col cols="12" md="12">
                                            <validation-provider v-slot="{ errors }" vid="subempreiteiro" :name="$t('globals.subcontractors_person')" rules="required">
                                                <v-autocomplete
                                                    v-model="newSubempreiteiro.subempreiteiro_id"
                                                    :items="subempreiteiros"
                                                    :item-text="item => item.name"
                                                    :error-messages="errors"
                                                    item-value="id"
                                                    menu-props="auto"
                                                    :label="$t('globals.subcontractors_person')"
                                                    prepend-inner-icon="mdi-account"
                                                ></v-autocomplete>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <validation-provider v-slot="{ errors }" vid="name" :name="$t('globals.name')" rules="required">
                                                <v-text-field
                                                    v-model="newSubempreiteiro.name"
                                                    :error-messages="errors"
                                                    :label="$t('globals.name') + ' *'"
                                                    prepend-inner-icon="mdi-account-details"
                                                ></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <v-textarea
                                                label="Descrição" 
                                                v-model="newSubempreiteiro.description">
                                            </v-textarea>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <validation-provider v-slot="{ errors }" vid="valor" :name="$t('globals.value')" rules="required">
                                                <v-text-field
                                                    v-model="newSubempreiteiro.value"
                                                    :error-messages="errors"
                                                    :label="$t('globals.value') + ' *'"
                                                    type="number"
                                                    :prepend-inner-icon="$currency_icon()"
                                                ></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-menu
                                                v-model="dataPicker"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="newSubempreiteiro.starting_date"
                                                    label="Data trabalho"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                v-model="newSubempreiteiro.starting_date"
                                                @input="dataPicker = false"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <v-file-input 
                                                v-if="newSubempreiteiro.proposal == null"
                                                v-model="newSubempreiteiro.proposal"
                                                label="Proposta"
                                            ></v-file-input>
                                            <div v-else>
                                                <v-card>
                                                    <v-toolbar class="primary white--text">
                                                        <h2>Proposta</h2>
                                                    </v-toolbar>
                                                    <v-card-text>
                                                        <v-row>
                                                            <v-col cols="12">
                                                                <h4>Download: </h4>
                                                            </v-col>
                                                            <v-col cols="12">                                                   
                                                                {{getFilename(newSubempreiteiro.proposal)}} 
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <v-btn class="success" @click="downloadProposal(newSubempreiteiro)">
                                                                    <v-icon color="white">mdi-download-box</v-icon>
                                                                </v-btn>
                                                                <v-btn style="float: right;" class="error" @click="newSubempreiteiro.proposal = null; newSubempreiteiro.new_proposal = true;">
                                                                    <v-icon color="white">mdi-file-document-remove</v-icon>
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <v-file-input
                                                v-if="newSubempreiteiro.invoice_path == null"
                                                v-model="newSubempreiteiro.invoice_path"
                                                label="Fatura"
                                            ></v-file-input>
                                            <div v-else>
                                                <v-card>
                                                    <v-toolbar class="primary white--text">
                                                        <h2>Fatura</h2>
                                                    </v-toolbar>
                                                    <v-card-text>
                                                        <v-row>
                                                            <v-col cols="12">
                                                                <h4>Download: </h4>
                                                            </v-col>
                                                            <v-col cols="12">                                                   
                                                                {{getFilename(newSubempreiteiro.invoice_path)}} 
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <v-btn class="success" @click="downloadInvoice(newSubempreiteiro)">
                                                                    <v-icon color="white">mdi-download-box</v-icon>
                                                                </v-btn>
                                                                <v-btn style="float: right;" class="error" @click="newSubempreiteiro.invoice_path = null; newSubempreiteiro.new_invoice = true;">
                                                                    <v-icon color="white">mdi-file-document-remove</v-icon>
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-6" align="center" justify="space-around">
                                        <v-col align="center" justify="space-around">
                                            <v-btn :disabled="invalid" depressed :class="($root.$vuetify.theme.isDark ? 'white primary--text' : 'primary') + ' mr-5 ml-5'" type="submit">
                                            Gravar
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </fieldset>
                            </v-form>
                        </validation-observer>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <DialogSuccess :opened="success" @on-ok="onSuccess" >
                Subempreitada gravada com sucesso!
            </DialogSuccess>
        </div>
        <div class="mb-8" v-else>
            <span class="text-caption mt-2">* Lista de subempreitadas orçamentadas. Para as editar crie primeiro a obra</span>
            <v-simple-table dense class="d-none d-lg-block mt-4">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-center">
                        Subempreiteiro
                        </th>
                        <th class="text-center">
                        Nome
                        </th>
                        <th class="text-center">
                        Descrição
                        </th>
                        <th class="text-center">
                        Custo
                        </th>
                        <th class="text-center">
                        Data do trabalho
                        </th>
                        <th class="text-center">
                        Proposta
                        </th>
                    </tr>
                    </thead>
                    <tbody :class="$root.$vuetify.theme.isDark ?  'secondary' : ''">
                    <tr v-for="(element, index) in subempreitadasReader" v-bind:key="index + 32000">
                        <td class="text-center">
                            <label>{{element.subempreiteiro_name}}</label>
                        </td>
                        <td class="text-center">
                            <label>{{element.name}}</label>
                        </td>
                        <td class="text-center">
                            <label>{{element.description}}</label>
                        </td>
                        <td class="text-center">
                            <label>{{ $currency(element.value) }}</label>
                        </td>
                        <td class="text-center">
                            <label>{{ element.starting_date }}</label>
                        </td>
                        <td class="text-center">
                            <label>{{ $currency(element.proposal) }}</label>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
    </v-container>
</template>
<script>
import Subempreiteiro from "@/api/Subempreiteiro.js"
import SubempreitadaAPI from "@/api/Subempreitada.js"
import IconView from '@/components/ui/IconView.vue';
import IconRemove from '@/components/ui/IconRemove.vue';
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default{
    name: 'Subempreitadas',
    components:{
        IconView,
        IconRemove,
        DialogSuccess
    },
    props:{
        viewOnly:{
            typeof: Boolean,
            default: false
        },
        subempreitadasReader:{
            typeof: Array,
            default: []
        }
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchSubempreitadas()}, 10);
        },
        deep: true,
      },
    },
    data(){
        return{
            items:[],
            success: false,
            dataPicker: false,
            dialog: false,
            loading: true,
            subempreiteiros: [],
            newSubempreiteiro:{
                id: null,
                name: null,
                subempreiteiro_id: null,
                description: null,
                starting_date: null,
                proposal: null,
                value: null,
                invoice: null,
                invoice_path: null,
                new_invoice: false,
                new_proposal: false
            },
            options: {},
            total: 0,             
        }
    },
    mounted(){
        this.fillBaseData();    
    },
    methods:{
        newSubempreitada(){
            this.newSubempreiteiro = {
                id: null,
                name: null,
                subempreiteiro_id: null,
                description: null,
                starting_date: null,
                proposal: null,
                value: null,
                invoice: null,
                invoice_path: null,
                new_invoice: false,
                new_proposal: false
            }
            this.dialog = true
        },
        downloadProposal(object){
            SubempreitadaAPI['proposal_download'](object.id).then((response) => {
                let documentName =  this.getFilename(object.proposal)

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", documentName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        downloadInvoice(object){
            SubempreitadaAPI['invoice_download'](object.id).then((response) => {
                let documentName =  this.getFilename(object.invoice_path)

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", documentName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        getFilename(file){
            if(file == null)
                return ''
            return file.substring(file.lastIndexOf('/')+1)
        },
        async fillBaseData(){
            await Subempreiteiro.list().then(({data})   =>  {
                this.subempreiteiros = data
            })
            this.loading = false
        },
        searchSubempreitadas(event){
            if(!!event && event.type == 'submit'){
                this.options.page = 1;
            }
    
            this.loading = true;
    
            let filter = {...this.filter};
    
            let request = {
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                page: this.options.page,
                limit: this.options.itemsPerPage,
                work_id: this.$route.params.id
            };
        
            Object.assign(request, filter);
    
            localStorage["subempreitadas-" + this.$root.session.id] = JSON.stringify({
                filter: this.filter,
                options: this.options,
            });
    
            SubempreitadaAPI.search(request).then(response => {
    
                let data = response.data;
        
                this.total = data.total;
        
                this.items = data.data.map((item) => {
        
                    this.searching = false;
        
                    return item;
                });
        
                this.loading = false;
    
            });
        },
        onSuccess() {
            this.success = false
            this.$emit('reload')
        },
        removeSubEmpreitada(s){
            SubempreitadaAPI.delete(s.id)
            .then(() => {
                this.searchSubempreitadas();
            });
        },
        openSubEmpreitada(s){
            this.newSubempreiteiro = s
            if(this.newSubempreiteiro.new_invoice == undefined)
                this.newSubempreiteiro.new_invoice = true

            if(this.newSubempreiteiro.new_proposal == undefined)
                this.newSubempreiteiro.new_proposal = true

            this.dialog = true
        },
        submit(){
            let method = 'create'
            
            if(this.newSubempreiteiro.id != null) 
                method = 'update';

            const formData = new FormData()
            formData.append('id', this.newSubempreiteiro.id)
            formData.append('name', this.newSubempreiteiro.name)
            formData.append('subempreiteiro_id', this.newSubempreiteiro.subempreiteiro_id)
            formData.append('description', this.newSubempreiteiro.description)
            formData.append('starting_date', this.newSubempreiteiro.starting_date)
            formData.append('proposal', this.newSubempreiteiro.proposal)
            formData.append('value', this.newSubempreiteiro.value)
            formData.append('invoice_path', this.newSubempreiteiro.invoice_path)
            formData.append('work_id', this.$route.params.id)
            formData.append('new_invoice', this.newSubempreiteiro.new_invoice)
            formData.append('new_proposal', this.newSubempreiteiro.new_proposal)


            SubempreitadaAPI[method](formData, this.newSubempreiteiro.id).then(() => {
  
                this.success = true;

                this.loading = false;

                this.dialog = false;

                this.searchSubempreitadas()

                this.$emit('reloaded')
                
                }).catch(err => {
                this.loading = false;

                    if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                    }

                    this.error.title = this.$t('globals.error') + " " + err.response.status;

                    this.error.message = err.response.data.message;

            });
        }
    },
    computed:{
        tableFooter(){
          let t = this.$t("globals.lines_datatable")
          let a = [5, 10, 15, 20];
          return {
            'items-per-page-options': a,
            'items-per-page-text': t
          }
        }, 
        headers(){
            const h = [
                {
                    text: 'Nome',
                    value: 'name',
                },
                {
                    text: 'Descrição',
                    value: 'description',
                },
                {
                    text: 'Valor (' + this.$store.state.coin + ')',
                    value: 'value',
                },
                {
                    text: 'Data trabalho',
                    value: 'starting_date',
                },
                {
                    text: 'Proposta',
                    value: 'proposal',
                },
                {
                    text: 'Fatura',
                    value: 'invoice_path',
                },
                {
                    text: 'Opções',
                    value: 'actions',
                }
            ];

            return h;
        }
    }
}
</script>