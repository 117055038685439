import BaseApi from "./BaseApi";

export default class UnitsHistory extends BaseApi{
    construct() {}
    

    static async search(params) {
        return await UnitsHistory.getAxiosInstance().get(UnitsHistory.getFullApiUrl(),{params: params});
    }


    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/units-history/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/units-history/";
    }
    
}