<template>
    <section class="mt-16">
        <div style="position:relative" v-if="!loading">
            <v-container>
                <v-form @submit.prevent="submit">
                    <v-card elevation="10" :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'" :class="$root.$vuetify.theme.isDark ? 'secondary elevation-0' : ''">
                        <v-card-title>{{$t('settings.branding_tab.logo')}}</v-card-title>
                        <v-row class="mt-4">
                            <v-col cols="12">
                                <v-card-text class="mt-n6">
                                    <v-file-input
                                        v-model="fields.branding"
                                        :rules="rules"
                                        color="primary"
                                        accept="image/png, image/jpeg, image/bmp"
                                        :placeholder="$t('settings.branding_tab.pick')"
                                        prepend-icon="mdi-camera"
                                        :label="$t('settings.branding_tab.submit')"
                                        @change="onFileChange"
                                    ></v-file-input>
                                </v-card-text>
                            </v-col>
                            <v-col cols="12">
                                <v-card-title class="mt-n10 text-body-1 font-weight-bold">{{$t('settings.branding_tab.preview')}}:</v-card-title>
                                <v-card-subtitle><small>({{$t('settings.branding_tab.images_size')}})</small></v-card-subtitle>
                                <v-divider></v-divider>
                                <v-container class="fill-height" fluid>
                                    <v-img v-if="img_url" 
                                        :src="img_url" 
                                        ref="logoImage"
                                        @load="loadedImage"
                                        contain
                                        :aspect-ratio="16/9"
                                        class="center mt-2"         
                                        max-width="250px"
                                        max-height="250px"/>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-card>
                    
                    <v-card-actions class="text-center justify-center">
                        <v-btn elevation="10" class="mt-4" depressed color="warning" type="submit">
                            {{$t('globals.save')}}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-container>
        </div>
        <ErrorSnackbar v-model="error.show" :message="error.message"/>
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            {{$t('settings.branding_tab.success')}}
        </DialogSuccess>
    </section>
</template>
<script>
import Configurations from "@/api/Configurations.js";
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
import Branding from "@/api/Branding.js";

export default{
    components:{
        DialogSuccess,
        ErrorSnackbar
    },
    watch:{
        imageObject:{
            immediate: false,
            handler(){
                /*if(i.width > 200 || i.height > 200){
                    this.error.message = "Imagem muito grande. Tamanho máximo: 200x200"
                    this.error.show = true
                }*/
            }
        }
    },
    data(){
        return{
            loading: false,
            img_url: null,
            success: false,
            imageObject: null,
            fields:{},    
            error: {
                title: '',
                message: '',
                show:false,
                showExtensive: false
            },            
            rules: [
                value => !value || value.size < 2000000 || this.$t('settings.image_to_big'),
            ],   
        }
    },
    async mounted(){
        this.loading = true
        await Configurations.get().then(({data}) => {
            this.fields = data.data
            
            if(data.data.logo != null)
                this.img_url = data.data.logo.replace(/([^:])(\/\/+)/g, '$1/'); //process.env.VUE_APP_API_URL + data.logo.replace(/([^:])(\/\/+)/g, '$1/');
            
            
            this.loading = false
        });
    },
    methods:{
        loadedImage(){
            const img = new Image();
            img.src = this.$refs.logoImage.src;
            img.onload = () => {
                this.imageObject = img
                this.imageObject.width = img.width
                this.imageObject.height = img.height
            };
        },
        onFileChange(file){
            if(file == null)
                return

            let tamanho = 2000000; // 2 MB

            if(file.size >= tamanho){
                this.fields.branding = null
                this.error.message = this.$t('settings.image_less_2')
                this.error.show = true
                return
            }

            this.logo_changed = true;

            const reader = new FileReader();

            reader.onload = e => {
                this.img_url = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        onSuccess(){
            this.success = false
        },
        async submit(){
            if(this.fields.branding == null)
                    return
            const formData = new FormData();
            formData.append('file', this.fields.branding)
            await Branding.create(formData).then(() => {

                this.success = true;

                this.loading = false;
                
                }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        }
    },
    computed: {
        crumbs: function() {
            return [
                {
                text: this.$t('menu.dashboard'),
                disabled: false,
                to: '/admin',
                exact: true,
                },
                {
                text: this.$t('settings.branding'),
                disabled: true,
                to: window.location.href,
                exact: true,
                },
            ];
        }
    }
}
</script>