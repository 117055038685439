import BaseApi from "./BaseApi";

export default class Warehouse extends BaseApi{
    construct() {}
 
    static async list(params) {
        let url = Warehouse.getFullApiUrl() + "list";

        return Warehouse.getAxiosInstance().get(url,{params: params});
    }

    static async setPrincipal(params) {
        return await Warehouse.getAxiosInstance().post(Warehouse.getFullApiUrl() + 'set-principal',params);
    }

    static async search(params) {
        return await Warehouse.getAxiosInstance().get(Warehouse.getFullApiUrl(),{params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/warehouses/";
    }
       
    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/warehouses/";
    }

}