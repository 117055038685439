import BaseApi from "./BaseApi";

export default class Diary extends BaseApi{
    construct() {}
    //admin.diaries.read.update

    static async markRead(diary_id, user_id){
        return await Diary.getAxiosInstance().post(Diary.getFullApiUrl() + 'read/' + diary_id + '/' + user_id);
    }
    
    static async list(params) {
        let url = Diary.getFullApiUrl() + "list";

        return Diary.getAxiosInstance().get(url,{params: params});
    }

    static async reject(id, params){
        let url = Diary.getFullApiUrl() + "reject/" + id;

        return Diary.getAxiosInstance().post(url,params);
    }

    static async search(params) {
        return await Diary.getAxiosInstance().get(Diary.getFullApiUrl(),{params: params});
    }

    static async create(params) {
        return await Diary.getAxiosInstance().post(Diary.getFullApiUrl(),params);
    }

    static async createImage(params, id) {
        return await Diary.getAxiosInstance().post(Diary.getFullApiUrl()+ id + "/files",params);
    }

    static async deleteImage(fileId, id) {
        return await Diary.getAxiosInstance().delete(Diary.getFullApiUrl()+ id + "/files/" + fileId);
    }

    static async update(params, id) {
        return await Diary.getAxiosInstance().post(Diary.getFullApiUrl() + id,params);
    }

    static async approve(id, approval) {
        let url = Diary.getFullApiUrl() + id + "/approve/";

        let params = { 
            approval: approval
        };

        return Diary.getAxiosInstance().patch(url, params);
    }

    static async delete(id) {
        return await Diary.getAxiosInstance().delete(Diary.getFullApiUrl() + id);
    }

    static async find(id) {
        return await Diary.getAxiosInstance().get(Diary.getFullApiUrl() + id);
    }

    static async listStatus() {
        let url = Diary.getFullApiUrl() + "status";

        return Diary.getAxiosInstance().get(url);
    }

    static async getLast(id) {
        return await Diary.getAxiosInstance().get(Diary.getFullApiUrl() + 'last/' + id);
    }

    static async checkWorkCreationValidation(params){
        return await Diary.getAxiosInstance().get(Diary.getFullApiUrl() + 'validation/create', {params: params})
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/diaries/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/diaries/";
    }
}