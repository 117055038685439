import BaseApi from "./BaseApi";

export default class Subempreitada extends BaseApi{
    construct() {}

    static async search(params) {
        return await Subempreitada.getAxiosInstance().get(Subempreitada.getFullApiUrl(),{params: params});
    }

    static async create(params) {
        return await Subempreitada.getAxiosInstance().post(Subempreitada.getFullApiUrl(),params);
    }

    static async update(params, id) {
        return await Subempreitada.getAxiosInstance().post(Subempreitada.getFullApiUrl() + 'update/' + id ,params);
    }

    static async delete(id) {
        return await Subempreitada.getAxiosInstance().delete(Subempreitada.getFullApiUrl() + id);
    }

    static async find(id) {
        return await Subempreitada.getAxiosInstance().get(Subempreitada.getFullApiUrl() + id);
    }

    static async list(params, tenant = null) {
        let url = Subempreitada.getFullApiUrl(tenant) + "list";

        return Subempreitada.getAxiosInstance().get(url,{params: params});
    }

    static async invoice_download(id) {
        let url = Subempreitada.getFullApiUrl() + 'invoice_download/' + id
        
        return await Subempreitada.getAxiosInstanceDownload(url)
    }

    static async proposal_download(id) {
        let url = Subempreitada.getFullApiUrl() + 'proposal_download/' + id
        
        return await Subempreitada.getAxiosInstanceDownload(url)
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/subempreitadas/";
    }

    static getFullApiUrl(tenant = null){
        let domain = location.pathname.split('/')[1]
        if(tenant != null)
            domain = tenant
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/subempreitadas/";
    }
}