<template>
  <div :key="generalKey">
    <v-card elevation="10" :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
      <v-toolbar class="primary" dark>
        <v-tabs id="global-tab-diary" active-class="white--text" :class="!diary ? 'd-none' : ''" v-model="tab">
          <v-tabs-slider color="warning"></v-tabs-slider>
          <v-tab>{{$t('diaries.tabs.detail')}}</v-tab>
          <v-tab>{{$t('diaries.tabs.images')}}</v-tab>
        </v-tabs>
      </v-toolbar>  
      <v-tabs-items v-if="!loading" v-model="tab" touchless :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
        
        <v-container class="mb-n12">
          
          <v-alert v-if="locked_edition && $root.session.hasPermission(['super', 'edit.approved'])"
            prominent
            type="error"
          >
            <v-row align="center">
              <v-col class="grow">
                {{$t('diaries.approved_diary')}}
              </v-col>
              <v-col class="shrink">
                <v-btn @click="locked_edition = false" color="warning">
                  <v-icon small class="mr-2">mdi-lock-open-alert</v-icon>  
                  {{$t('diaries.unlock')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
          <!-- DESKTOP -->
          <v-btn
            v-if="tab==0 && $route.name == 'create'"
            class="d-none d-lg-block mr-2"
            style="float:right;"
            dark
            small
            color="primary"
            @click="replicarPD"
          >
            <v-icon small class="mr-2">mdi-plus-circle-multiple-outline</v-icon>
            <div v-if="!replicar">{{$t('diaries.copy_last')}}</div>
            <div v-else>{{$t('diaries.remove_copy')}}</div>
          </v-btn>
          <!-- MOBILE -->
          <v-btn
            v-if="tab==0 && $route.name == 'create'"
            class="d-sm-none mr-2"
            style="float:right;"
            dark
            small
            block
            color="primary"
            @click="replicarPD"
          >
            <v-icon small class="mr-2">mdi-plus-circle-multiple-outline</v-icon>
            <div v-if="!replicar">{{$t('diaries.copy_last')}}</div>
            <div v-else>{{$t('diaries.remove_copy')}}</div>
          </v-btn>
          <!-- DESKTOP -->
          <div v-if="$route.name == 'create' && readyToUseTemplate()">
          <v-btn
            :disabled="blockTemplate"
            class="d-none d-lg-block mr-2"
            dark
            small
            color="warning"
            style="float:right;"
            @click="defineTemplate"
          >
            <v-icon small class="mr-2">mdi-plus-circle-multiple-outline</v-icon>
            {{ useTemplate ? $t('diaries.remove_template') : $t('diaries.import_template') }}
          </v-btn>
          </div>
          <!-- MOBILE -->
          <div v-if="$route.name == 'create' && readyToUseTemplate()">
          <v-btn
            :disabled="blockTemplate"
            class="d-sm-none mr-2 mt-2"
            dark
            small
            block
            color="warning"
            style="float:right;"
            @click="defineTemplate"
          >
            <v-icon small class="mr-2">mdi-plus-circle-multiple-outline</v-icon>
            {{ useTemplate ? $t('diaries.remove_template') : $t('diaries.import_template') }}
          </v-btn>
          </div>
        </v-container>
        <v-tab-item class="mt-6" :key="1">
            <v-container>
              <validation-observer ref="form" v-slot="{ invalid }">
              <v-form @submit.prevent="submit"  class="mb-16" :readonly="loading || locked_edition">
                <v-progress-linear v-if="loading"
                    indeterminate
                    class="global-loader"
                  ></v-progress-linear>
                  <v-row>
                    <v-col cols="12" md="6">
                      <validation-provider v-slot="{ errors }" vid="work" :name="$t('globals.work')" rules="required">
                        <Select
                            v-if="$route.name == 'create'"
                            v-model="fields.work_id"
                            :items="works"
                            :item-text="item => newDiary ? ('Nome: ' + item.name + ' - Código: '+ item.code) : item.name"
                            item-value="id"
                            menu-props="auto"
                            :label="$t('globals.work') + '*'"
                            prepend-inner-icon="mdi-hard-hat"
                            @input="setDiaryCode"
                            @change="changedWork"
                            :error-messages="errors"
                          ></Select>
                          <v-text-field
                            v-else
                            prepend-inner-icon="mdi-barcode"
                            :value="fields.work_object.code + ':' + fields.work_object.name"
                            disabled
                            :label="$t('globals.work') + '*'"
                            clearable
                            :error-messages="errors"
                          ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="2" v-if="false">
                      <v-checkbox
                        :disabled="blockReplicar"
                        v-model="replicar"
                        :label="$t('diaries.copy_last')"
                        @change="replicarPD"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="4">
                      <validation-provider v-slot="{ errors }" vid="code" :name="$t('globals.code')" rules="required">
                        <v-text-field
                          v-model="fields.code"
                          prepend-inner-icon="mdi-barcode"
                          :label="$t('globals.code') + '*'"
                          :disabled="$route.name == 'create' ? false : true"
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-menu
                        v-model="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider v-slot="{ errors }" vid="date_buy" :name="$t('globals.data')" rules="required">
                            <v-text-field
                              :label="$t('globals.data')"
                              v-model="fields.date"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              @click:clear="fields.date = null"
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </template>
                        <v-date-picker
                          v-model="fields.date"
                          :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                          locale="pt-pt"
                          @change="changedData"
                          @input="date = false;"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row v-if="false">
                    <v-col cols="12" md="2" class="mt-n8">
                      <v-checkbox
                        :disabled="blockTemplate"
                        v-model="useTemplate"
                        :label="$t('diaries.use_template')"
                        @change="defineTemplate"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row v-if="!diaryCreationPossible">
                    <v-alert
                      border="left"                    
                      prominent  
                      outlined
                      colored-border
                      type="warning"
                      elevation="2"
                      style="width: 100%"
                    >
                      {{$t('diaries.select_the')}} <strong>{{$t('globals.work')}}</strong> {{$t('diaries.and_the')}} <strong>{{$t('globals.data')}}</strong> {{$t('diaries.go_forward')}}!
                    </v-alert>
                  </v-row>                  
                  <!--          -->
                  <!--          -->
                  <!--          -->
                  <!-- WORKERS  -->
                  <!--          -->
                  <!--          -->
                  <!--          -->
                  <v-row v-show="diaryCreationPossible">
                    <v-col cols="12">
                      <v-expansion-panels>
                        <v-expansion-panel
                        >
                          <v-expansion-panel-header :class="($root.$vuetify.theme.isDark ? 'warning' : 'primary') + ' white--text font-weight-bold rounded-lg'">
                            <template v-slot:actions>
                              <v-icon class="icon white--text">$expand</v-icon>
                          </template>
                            {{$t('diaries.tasks_in')}} {{ getWeekNumber }}
                          </v-expansion-panel-header>
                          <v-expansion-panel-content :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                            <GanttTime :edit="false" :key="ganttTimeReloader" v-if="ganttWork != undefined" :defaultTodayDate="fields.date" :openFullScreen="false" :work_days="ganttWork.work_days" :data="ganttWork.work_unit_list"/>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                    <v-col cols="12" class="mt-4">
                      <div class="d-flex flex-row align-center">
                        <h2 class="text-h6 font-weight-bold">
                          {{$t('globals.work_hand')}}
                        </h2>
                      </div>
                      <v-divider></v-divider>
                      <v-simple-table dense class="d-none d-lg-block mt-4">
                        <template v-slot:default>
                          <thead class="primary">
                            <tr>
                              <th class="text-center white--text rounded-pill rounded-r-0">
                                {{$t('globals.name')}}
                              </th>
                              <th class="text-center white--text">
                                {{$t('globals.free_text')}}
                              </th>
                              <th class="text-center white--text">
                                {{$t('globals.schedule')}}
                              </th>
                              <th class="text-center white--text">
                                {{$t('globals.sleep_over')}}
                              </th>
                              <th class="text-center white--text">
                                {{$t('globals.night_work')}}
                              </th>
                              <th class="text-center white--text rounded-pill rounded-l-0">
                                {{$t('globals.options')}}
                              </th>
                            </tr>
                          </thead>
                          <tbody :class="($root.$vuetify.theme.isDark ? 'secondary' : '') + ' font-weight-light text-caption'">
                            <tr v-for="worker in fields.workers" v-bind:key="worker.id">
                              <td class="text-center">
                                <validation-provider v-slot="{ errors }" vid="laborer" :name="$t('globals.name')" rules="required">
                                  <v-combobox
                                    v-model="worker.object"
                                    :label="$t('globals.name') + '*'"
                                    :filter="filterAutocomplete"
                                    :items="workers"
                                    :item-text="item => item.name"
                                    item-value="id"
                                    hide-selected
                                    :error-messages="errors"
                                    @input="worker.name = $event.name; worker.id = $event.id"
                                    @change="changedWorker(worker)"
                                  >
                                    <template v-slot:prepend>
                                      <TooltipErrors
                                          v-if="!validWorkHand(worker).result"
                                          color="error"
                                          :messages="validWorkHand(worker).errors"
                                          bottom
                                      >
                                        <v-icon color="error">mdi-alpha-x-circle</v-icon>
                                      </TooltipErrors>
                                      <v-icon color="success" v-else>mdi-check-bold</v-icon>
                                    </template>
                                  </v-combobox>
                                </validation-provider>
                              </td>
                              <td class="text-center">
                                <v-text-field
                                  v-model="worker.reason"
                                  :label="$t('globals.free_text')"
                                  :key="keyReason"
                                  @click="openEditorModal(worker)"
                                ></v-text-field>
                              </td>
                              <td class="text-center">
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <v-row>
                                      <v-col cols="12" md="6">
                                        <validation-provider v-slot="{ errors }" vid="hour" :name="$t('globals.entry_hour')" rules="required|between_hour">
                                          <v-text-field
                                            v-model="worker.started_hour"
                                            :label="$t('globals.entry_hour') + '*'"
                                            type="number"
                                            :error-messages="errors"
                                          ></v-text-field>
                                        </validation-provider>
                                      </v-col>
                                      <v-col cols="12" md="6">
                                        <validation-provider v-slot="{ errors }" vid="minute" :name="$t('globals.entry_minute')" rules="required|between_minute">
                                          <v-text-field
                                            v-model="worker.started_minute"
                                            :label="$t('globals.entry_minute') + '*'"
                                            type="number"
                                            :error-messages="errors"
                                          ></v-text-field>
                                        </validation-provider>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-row>
                                      <v-col cols="12" md="6">
                                        <validation-provider v-slot="{ errors }" vid="hour" :name="$t('globals.exit_hour')" rules="required|between_hour">
                                          <v-text-field
                                            v-model="worker.finished_hour"
                                            :label="$t('globals.exit_hour') + '*'"
                                            type="number"
                                            :error-messages="errors"
                                          ></v-text-field>
                                        </validation-provider>
                                      </v-col>
                                      <v-col cols="12" md="6">
                                        <validation-provider v-slot="{ errors }" vid="minute" :name="$t('globals.exit_minute')" rules="required|between_minute">
                                          <v-text-field
                                            v-model="worker.finished_minute"
                                            :label="$t('globals.exit_minute') + '*'"
                                            type="number"
                                            :error-messages="errors"
                                          ></v-text-field>
                                        </validation-provider>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </td>
                              <td class="text-center">
                                <v-checkbox 
                                  :disabled="locked_edition"
                                  class="d-inline-flex"
                                  v-model="worker.slept_night"
                                />
                              </td>
                              <td class="text-center">
                                <v-checkbox 
                                  :disabled="locked_edition"
                                  class="d-inline-flex"
                                  v-model="worker.night_work"
                                />
                              </td>
                              <td class="text-center" v-if="!locked_edition">
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <v-btn
                                      class="mt-2"
                                      dark
                                      small
                                      color="secondary"
                                      @click="duplicateWorker(worker)"
                                    >
                                      <v-icon small>mdi-plus-circle-multiple-outline</v-icon>
                                    </v-btn>
                                    
                                    <v-btn
                                      class="mt-2"
                                      dark
                                      small
                                      color="error"
                                      @click="removeWorker(worker.id)"
                                    >
                                      <v-icon small>mdi-delete</v-icon>
                                    </v-btn>
                                  </v-col>
                                </v-row>
                                  <p></p>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <v-data-iterator
                        :items="fields.workers"
                        item-key="id"
                        :items-per-page="-1"
                        hide-default-footer
                        class="d-block d-lg-none"
                      >
                        <template v-slot:default="{ items }">
                          <v-row>
                            <v-col
                              v-for="worker in items"
                              :key="worker.id"
                              cols="12"
                              sm="6"
                              md="4"
                              lg="3"
                            >
                              <v-card>
                                <v-list
                                  dense
                                >
                                  <v-list-item>
                                    <v-list-item-content class="align-end">
                                      <validation-provider v-slot="{ errors }" vid="laborer" :name="$t('globals.name')" rules="required">
                                        <v-combobox
                                          v-model="worker.object"
                                          :label="$t('globals.name') + '*'"
                                          :filter="filterAutocomplete"
                                          :items="workers"
                                          :item-text="item => item.name"
                                          item-value="id"
                                          hide-selected
                                          :error-messages="errors"
                                          clearable
                                          @input="worker.name = $event.name; worker.id = $event.id"
                                        >
                                        <template v-slot:prepend>
                                          <TooltipErrors
                                              v-if="!validWorkHand(worker).result"
                                              color="error"
                                              :messages="validWorkHand(worker).errors"
                                              bottom
                                          >
                                            <v-icon color="error">mdi-alpha-x-circle</v-icon>
                                          </TooltipErrors>
                                          <v-icon color="success" v-else>mdi-check-bold</v-icon>
                                        </template>
                                        
                                        </v-combobox>
                                      </validation-provider>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item>
                                    <v-list-item-content class="align-end">
                                      <v-row>
                                        <v-col cols="12" lg="6">
                                          <v-row>
                                            <v-col cols="12" lg="6">
                                              <validation-provider v-slot="{ errors }" vid="hour" :name="$t('globals.entry_hour')" rules="required">
                                                <v-text-field
                                                  v-model="worker.started_hour"
                                                  :label="$t('globals.entry_hour') + '*'"
                                                  :error-messages="errors"
                                                ></v-text-field>
                                              </validation-provider>
                                            </v-col>
                                            <v-col cols="12" lg="6">
                                              <validation-provider v-slot="{ errors }" vid="minute" :name="$t('globals.entry_minute')" rules="required">
                                                <v-text-field
                                                  v-model="worker.started_minute"
                                                  :label="$t('globals.entry_minute') + '*'"
                                                  :error-messages="errors"
                                                ></v-text-field>
                                              </validation-provider>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                        <v-col cols="12" lg="6">
                                          <v-row>
                                            <v-col cols="12" lg="6">
                                              <validation-provider v-slot="{ errors }" vid="hour" :name="$t('globals.exit_hour')" rules="required">
                                                <v-text-field
                                                  v-model="worker.finished_hour"
                                                  :label="$t('globals.exit_hour') + '*'"
                                                  :error-messages="errors"
                                                ></v-text-field>
                                              </validation-provider>
                                            </v-col>
                                            <v-col cols="12" lg="6">
                                              <validation-provider v-slot="{ errors }" vid="minute" :name="$t('globals.exit_minute')" rules="required">
                                                <v-text-field
                                                  v-model="worker.finished_minute"
                                                  :label="$t('globals.exit_minute') + '*'"
                                                  :error-messages="errors"
                                                ></v-text-field>
                                              </validation-provider>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                      </v-row>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item>
                                    <v-list-item-content>{{$t('globals.sleep_over')}}:</v-list-item-content>
                                    <v-list-item-content class="align-end">
                                      <v-checkbox 
                                        :disabled="locked_edition"
                                        class="d-inline-flex"
                                        v-model="worker.slept_night"
                                      />
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item>
                                    <v-list-item-content>{{$t('globals.night_work')}}:</v-list-item-content>
                                    <v-list-item-content class="align-end">
                                      <v-checkbox 
                                        :disabled="locked_edition"
                                        class="d-inline-flex"
                                        v-model="worker.night_work"
                                      />
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item v-if="!locked_edition">
                                    <v-list-item-content class="align-end">
                                      <v-row>
                                        <v-col cols="12" md="6">
                                          <v-btn
                                            dark
                                            small
                                            block
                                            color="secondary"
                                            @click="duplicateWorker(worker)"
                                          >
                                            {{$t('globals.duplicate')}}<v-icon class="ml-2" small>mdi-plus-circle-multiple-outline</v-icon>
                                          </v-btn>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                          <v-btn
                                            dark
                                            small
                                            block
                                            color="error"
                                            @click="removeWorker(worker.id)"
                                          >
                                            {{$t('globals.remove')}}<v-icon class="ml-2" small>mdi-delete</v-icon>
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-card>
                            </v-col>
                          </v-row>
                        </template>
                      </v-data-iterator>
                      <v-row justify="center" v-if="!locked_edition" id="machines">
                        <v-btn
                          fab
                          dark
                          small
                          color="primary"
                          class="mt-7"
                          @click="addWorker"
                        >
                          <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                  <!--          -->
                  <!--          -->
                  <!--          -->
                  <!-- MACHINES -->
                  <!--          -->
                  <!--          -->
                  <!--          -->
                  <v-row v-show="diaryCreationPossible">
                    <v-col cols="12">
                      <div class="d-flex flex-row align-center">
                        <h2 class="text-h6 font-weight-bold">
                          {{$t('globals.machines')}}
                        </h2>
                      </div>
                      <v-divider></v-divider>
                      <v-simple-table dense class="d-none d-lg-block mt-4">
                        <template v-slot:default>
                          <thead class="primary">
                            <tr>
                              <th class="text-center white--text rounded-pill rounded-r-0" style="width: 35%;">
                                {{$t('globals.name')}}
                              </th>
                              <th class="text-center white--text">
                                {{$t('globals.code')}}
                              </th>
                              <th class="text-center white--text">
                                {{$t('globals.diesel')}}
                              </th>
                              <th class="text-center white--text">
                                {{$t('globals.adblue')}}
                              </th>
                              <th class="text-center white--text">
                                {{$t('diaries.hours_km_start')}}
                              </th>
                              <th class="text-center white--text">
                                {{$t('diaries.hours_km_end')}}
                              </th>
                              <th class="text-center white--text">
                                {{$t('diaries.stopped_in_order')}}
                              </th>
                              <th class="text-center white--text rounded-pill rounded-l-0">
                                {{$t('globals.options')}}
                              </th>
                            </tr>
                          </thead>
                          <tbody :class="($root.$vuetify.theme.isDark ? 'secondary' : '') + ' font-weight-light text-caption'">
                            <tr v-for="machine in fields.machines" v-bind:key="machine.id">
                              <td class="text-center">
                                <validation-provider v-slot="{ errors }" vid="machine" :name="$t('globals.machine')" rules="required">
                                  <v-combobox
                                    v-model="machine.object"
                                    :label="$t('globals.machine') + '*'"
                                    :filter="filterAutocomplete"
                                    :items="machines"
                                    :item-text="item => item.code + ' - ' + item.name"
                                    item-value="id"
                                    hide-selected
                                    :error-messages="errors"
                                    @input="machine.code = $event.code; machine.id = $event.id; machine.name = $event.name; machine.started_hours = $event.next_start; machine.control_diesel = $event.control_diesel;"
                                  >
                                    <template v-slot:prepend>
                                      <TooltipErrors
                                          v-if="!validMachine(machine).result"
                                          color="error"
                                          :messages="validMachine(machine).errors"
                                          bottom
                                      >
                                        <v-icon color="error">mdi-alpha-x-circle</v-icon>
                                      </TooltipErrors>
                                      <v-icon color="success" v-else>mdi-check-bold</v-icon>
                                    </template>
                                  </v-combobox>
                                </validation-provider>
                              </td>
                              <td class="text-center">
                                {{ machine.code }}
                              </td>
                              <td class="text-center" style="width: 10% !important;" v-if="machine.control_diesel">
                                <validation-provider v-slot="{ errors }" vid="diesel" :name="$t('globals.diesel')" rules="required">
                                  <v-text-field
                                    v-model="machine.diesel"
                                    :label="$t('globals.diesel') + '*'"
                                    :error-messages="errors"
                                    @blur="checkDifference(machine)"
                                  ></v-text-field>
                                </validation-provider>
                              </td>
                              <td v-else>
                                -
                              </td>
                              <td class="text-center" style="width: 10% !important;" v-if="machine.control_adblue">
                                <validation-provider v-slot="{ errors }" vid="adblue" :name="$t('globals.adblue')" rules="required">
                                  <v-text-field
                                    v-model="machine.adblue"
                                    :label="$t('globals.adblue') + '*'"
                                    :error-messages="errors"
                                    @blur="checkDifference(machine)"
                                  ></v-text-field>
                                </validation-provider>
                              </td>
                              <td v-else>
                                -
                              </td>
                              <td class="text-center">
                                <validation-provider v-slot="{ errors }" vid="km_start" :name="$t('diaries.hours_km_start')" rules="required">
                                  <v-text-field
                                    v-model="machine.started_hours"
                                    @blur="checkDifference(machine)"
                                    :label="$t('diaries.hours_km_start') + '*'"
                                    :error-messages="errors"
                                  ></v-text-field>
                                </validation-provider>
                              </td>
                              <td class="text-center">
                                <validation-provider v-slot="{ errors }" vid="km_end" :name="$t('diaries.hours_km_end')" rules="required">
                                  <v-text-field
                                    v-model="machine.finished_hours"
                                    @blur="checkDifference(machine)"
                                    :label="$t('diaries.hours_km_end') + '*'"
                                    :error-messages="errors"
                                  ></v-text-field>
                                </validation-provider>
                              </td>
                              <td class="text-center">
                                <v-text-field
                                  v-model="machine.stopped_reason"
                                  :label="$t('globals.reason')"
                                ></v-text-field>
                              </td>
                              <td class="text-center" v-if="!locked_edition"> 
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <v-btn
                                      class="mt-2"
                                      dark
                                      small
                                      color="secondary"
                                      @click="duplicateMachine(machine)"
                                    >
                                      <v-icon small>mdi-plus-circle-multiple-outline</v-icon>
                                    </v-btn>
                                    <v-btn
                                      class="mt-2"
                                      dark
                                      small
                                      color="error"
                                      @click="removeMachine(machine.id)"
                                    >
                                      <v-icon small>mdi-delete</v-icon>
                                    </v-btn>
                                    <p></p>
                                  </v-col>
                                </v-row>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <v-data-iterator
                        :items="fields.machines"
                        item-key="id"
                        :items-per-page="-1"
                        hide-default-footer
                        class="d-block d-lg-none"
                      >
                        <template v-slot:default="{ items }">
                          <v-row>
                            <v-col
                              v-for="machine in items"
                              :key="machine.id"
                              cols="12"
                              sm="6"
                              md="4"
                              lg="3"
                            >
                              <v-card>
                                <v-list
                                  dense
                                >
                                  <v-list-item>
                                    <v-list-item-content class="align-end">
                                      <validation-provider v-slot="{ errors }" vid="machine" :name="$t('globals.machine')" rules="required">
                                        <v-combobox
                                          v-model="machine.object"
                                          :label="$t('globals.machine') + '*'"
                                          :filter="filterAutocomplete"
                                          :items="machines"
                                          :item-text="item => item.name"
                                          item-value="id"
                                          hide-selected
                                          :error-messages="errors"
                                          @input="machine.code = $event.code; machine.id = $event.id; machine.name = $event.name; machine.started_hours = $event.next_start; machine.control_diesel = $event.control_diesel;"
                                        >
                                        <template v-slot:prepend>
                                          <TooltipErrors
                                              v-if="!validMachine(machine).result"
                                              color="error"
                                              :messages="validMachine(machine).errors"
                                              bottom
                                          >
                                            <v-icon color="error">mdi-alpha-x-circle</v-icon>
                                          </TooltipErrors>
                                          <v-icon color="success" v-else>mdi-check-bold</v-icon>
                                        </template>
                                        
                                        </v-combobox>
                                      </validation-provider>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item>
                                    <v-list-item-content class="align-end">
                                      <v-text-field
                                          :disabled="true"
                                          v-model="machine.code"
                                          :label="$t('globals.code') + '*'"
                                        ></v-text-field>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item v-if="machine.control_diesel">
                                    <v-list-item-content class="align-end">
                                      <validation-provider v-slot="{ errors }" vid="diesel" :name="$t('globals.diesel')" rules="required">
                                        <v-text-field
                                          v-model="machine.diesel"
                                          :label="$t('globals.diesel') + '*'"
                                          :error-messages="errors"
                                        ></v-text-field>
                                      </validation-provider>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item v-if="machine.control_adblue">
                                    <v-list-item-content class="align-end">
                                      <validation-provider v-slot="{ errors }" vid="adblue" :name="$t('globals.adblue')" rules="required">
                                        <v-text-field
                                          v-model="machine.adblue"
                                          :label="$t('globals.adblue') + '*'"
                                          :error-messages="errors"
                                        ></v-text-field>
                                      </validation-provider>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item>
                                    <v-list-item-content class="align-end">
                                      <validation-provider v-slot="{ errors }" vid="km_start" :name="$t('diaries.hours_km_start')" rules="required">
                                        <v-text-field
                                          v-model="machine.started_hours"
                                          :label="$t('diaries.hours_km_start') + '*'"
                                          :error-messages="errors"
                                        ></v-text-field>
                                      </validation-provider>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item>
                                    <v-list-item-content class="align-end">
                                      <validation-provider v-slot="{ errors }" vid="km_end" :name="$t('diaries.hours_km_end')" rules="required">
                                        <v-text-field
                                          v-model="machine.finished_hours"
                                          :label="$t('diaries.hours_km_end') + '*'"
                                          :error-messages="errors"
                                        ></v-text-field>
                                      </validation-provider>
                                    </v-list-item-content>
                                  </v-list-item>                                
                                  <v-list-item>
                                    <v-list-item-content class="align-end">
                                      <v-text-field
                                        v-model="machine.stopped_reason"
                                        :label="$t('globals.reason')"
                                      ></v-text-field>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item v-if="!locked_edition">
                                    <v-list-item-content class="align-end">
                                      <v-row>
                                        <v-col cols="12" md="6">
                                          <v-btn
                                            dark
                                            small
                                            block
                                            color="secondary"
                                            @click="duplicateMachine(machine)"
                                          >
                                            {{$t('globals.duplicate')}}<v-icon class="ml-2" small>mdi-plus-circle-multiple-outline</v-icon>
                                          </v-btn>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                          <v-btn
                                            dark
                                            small
                                            block
                                            color="error"
                                            @click="removeMachine(machine.id)"
                                          >
                                            {{$t('globals.remove')}}<v-icon class="ml-2" small>mdi-delete</v-icon>
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-card>
                            </v-col>
                          </v-row>
                        </template>
                      </v-data-iterator>
                      <v-row justify="center" v-if="!locked_edition">
                        <v-btn
                          fab
                          dark
                          small
                          color="primary"
                          class="mt-7"
                          @click="addMachine"
                          id="workHands"
                        >
                          <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>  
                  <!--            -->
                  <!--            -->
                  <!--            -->
                  <!-- MATERIALS  -->
                  <!--            -->
                  <!--            -->
                  <!--            -->
                  <v-row v-show="diaryCreationPossible" v-if="addMaterials">
                    <v-col cols="12">
                      <div class="d-flex flex-row align-center">
                        <h2 class="text-h6 font-weight-bold">
                          {{$t('globals.materials')}}
                        </h2>
                      </div>
                      <v-divider></v-divider>
                      <v-simple-table dense class="d-none d-lg-block mt-4">
                        <template v-slot:default>
                          <thead class="primary">
                            <tr>
                              <th class="text-center white--text rounded-pill rounded-r-0" style="width: 35%;">
                                {{$t('globals.name')}}
                              </th>
                              <th class="text-center white--text">
                                {{$t('globals.reference')}}
                              </th>
                              <th class="text-center white--text" v-if="method=='update'">
                                {{$t('globals.change')}}
                              </th>
                              <th class="text-center white--text">
                                {{$t('globals.quantity')}}
                              </th>
                              <th class="text-center white--text">
                                {{$t('globals.free_text')}}
                              </th>
                              <th class="text-center white--text rounded-pill rounded-l-0">
                                {{$t('globals.options')}}
                              </th>
                            </tr>
                          </thead>
                          <tbody :class="($root.$vuetify.theme.isDark ? 'secondary' : '') + ' font-weight-light text-caption'">
                            <tr v-for="material in fields.materials" v-bind:key="material.id">
                              <td class="text-center">
                                <validation-provider v-slot="{ errors }" vid="machine" :name="$t('globals.material')" rules="required">
                                  <v-combobox
                                    v-model="material.object"
                                    :label="$t('globals.material') + ' *'"
                                    :filter="filterAutocomplete"
                                    :items="materials"
                                    :item-text="item => item.reference + ' - ' + item.name"
                                    item-value="id"
                                    hide-selected
                                    :error-messages="errors"
                                    @input="material.reference = $event.reference; material.id = $event.id; material.name = $event.name; material.stock = $event.stock; material.reserved = $event.reserved;"
                                  >
                                    <template v-slot:prepend>
                                      <TooltipErrors
                                          v-if="!validMaterial(material).result"
                                          :color="validMaterial(material).warning ? 'warning' : 'error'"
                                          :messages="validMaterial(material).errors"
                                          bottom
                                      >
                                        <v-icon :color="validMaterial(material).warning ? 'warning' : 'error'" >{{ validMaterial(material).warning ? 'mdi-alert-box' : 'mdi-alpha-x-circle'}}</v-icon>
                                      </TooltipErrors>
                                      <v-icon color="success" v-else>mdi-check-bold</v-icon>
                                    </template>
                                  </v-combobox>
                                </validation-provider>
                              </td>
                              <td class="text-center">
                                {{ material.reference }}
                              </td>
                              <td class="text-center" v-if="method=='update'">
                                <Tooltip
                                    color="warning"
                                    :text="$t('diaries.change_material_warning')"
                                    top
                                >
                                  <v-checkbox
                                    v-model="material.changed"
                                    label="?"
                                    class="text-caption"
                                  />
                                </Tooltip>
                              </td>
                              <td class="text-center">
                                <validation-provider v-slot="{ errors }" vid="quantity" :name="$t('globals.quantity')" rules="required">
                                  <v-text-field
                                    :disabled="material.changed == false"
                                    v-model="material.quantity"
                                    :label="$t('globals.quantity') + ' *'"
                                    :error-messages="errors"
                                  ></v-text-field>
                                </validation-provider>
                              </td>
                              <td class="text-center">
                                <v-text-field
                                  v-model="material.reason"
                                  :label="$t('globals.free_text')"
                                ></v-text-field>
                              </td>
                              <td class="text-center" v-if="!locked_edition"> 
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <v-btn
                                      class="mt-2"
                                      dark
                                      small
                                      color="secondary"
                                      @click="duplicateMaterial(material)"
                                    >
                                      <v-icon small>mdi-plus-circle-multiple-outline</v-icon>
                                    </v-btn>
                                    <v-btn
                                      class="mt-2"
                                      dark
                                      small
                                      color="error"
                                      @click="removeMaterial(material.id)"
                                    >
                                      <v-icon small>mdi-delete</v-icon>
                                    </v-btn>
                                    <p></p>
                                  </v-col>
                                </v-row>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <v-data-iterator
                        :items="fields.materials"
                        item-key="id"
                        :items-per-page="-1"
                        hide-default-footer
                        class="d-block d-lg-none"
                      >
                        <template v-slot:default="{ items }">
                          <v-row>
                            <v-col
                              v-for="material in items"
                              :key="material.id"
                              cols="12"
                              sm="6"
                              md="4"
                              lg="3"
                            >
                              <v-card>
                                <v-list
                                  dense
                                >
                                  <v-list-item>
                                    <v-list-item-content class="align-end">
                                      <validation-provider v-slot="{ errors }" vid="material" :name="$t('globals.material')" rules="required">
                                        <v-combobox
                                          v-model="material.object"
                                          :label="$t('globals.material') + '*'"
                                          :filter="filterAutocomplete"
                                          :items="materials"
                                          :item-text="item => item.name"
                                          item-value="id"
                                          hide-selected
                                          :error-messages="errors"
                                          @input="material.reference = $event.reference; material.id = $event.id; material.name = $event.name; material.stock = $event.stock; material.reserved = $event.reserved;"
                                        >
                                        
                                        <template v-slot:prepend>
                                          <TooltipErrors
                                              v-if="!validMaterial(material).result"
                                              :color="validMaterial(material).warning ? 'warning' : 'error'"
                                              :messages="validMaterial(material).errors"
                                              bottom
                                          >
                                            <v-icon :color="validMaterial(material).warning ? 'warning' : 'error'" >{{ validMaterial(material).warning ? 'mdi-alert-box' : 'mdi-alpha-x-circle'}}</v-icon>
                                          </TooltipErrors>
                                          <v-icon color="success" v-else>mdi-check-bold</v-icon>
                                        </template>
                                        
                                        </v-combobox>
                                      </validation-provider>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item>
                                    <v-list-item-content class="align-end">
                                      <v-text-field
                                          :disabled="true"
                                          v-model="material.reference"
                                          :label="$t('globals.reference') + '*'"
                                        ></v-text-field>
                                    </v-list-item-content>
                                  </v-list-item>                            
                                  <v-list-item>
                                    <v-list-item-content class="align-end">
                                      <validation-provider v-slot="{ errors }" vid="quantity" :name="$t('globals.quantity')" rules="required">
                                        <v-text-field
                                          v-model="material.quantity"
                                          :label="$t('globals.quantity') + ' *'"
                                          :error-messages="errors"
                                        ></v-text-field>
                                      </validation-provider>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item v-if="!locked_edition">
                                    <v-list-item-content class="align-end">
                                      <v-list-item-content class="align-end">
                                        <v-row>
                                          <v-col cols="12" md="6">
                                            <v-btn
                                              class="mt-2"
                                              dark
                                              small
                                              color="secondary"
                                              @click="duplicateMaterial(material)"
                                            >
                                              <v-icon small>mdi-plus-circle-multiple-outline</v-icon>
                                            </v-btn>
                                            <v-btn
                                              class="mt-2"
                                              dark
                                              small
                                              color="error"
                                              @click="removeMaterial(material.id)"
                                            >
                                              <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                            <p></p>
                                          </v-col>
                                        </v-row>
                                    </v-list-item-content>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-card>
                            </v-col>
                          </v-row>
                        </template>
                      </v-data-iterator>
                      <v-row justify="center" v-if="!locked_edition">
                        <v-btn
                          fab
                          dark
                          small
                          color="primary"
                          class="mt-7"
                          @click="addMaterial"
                          id="materialsAdd"
                        >
                          <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                  
                  <!--            -->
                  <!--            -->
                  <!--            -->
                  <!-- WORK UNITS -->
                  <!--            -->
                  <!--            -->
                  <!--            -->
                  <v-row v-show="diaryCreationPossible">
                    <v-col cols="12">
                      <div class="d-flex flex-row align-center">
                        <h2 class="text-h6 font-weight-bold">
                          {{$t('globals.work_unit')}}
                        </h2>
                      </div>
                    <v-divider></v-divider>
                      <v-row>
                        <v-col cols="12" md="auto">
                          <div class="text-body-1 d-flex flex-row align-center">   
                            <small>{{$t('diaries.divide_hours_in')}}:</small>
                          </div>
                        </v-col>
                        <v-col cols="12" md="auto">
                          <div class="text-caption d-flex flex-row align-center mt-n4">   
                            <v-checkbox v-model="fields.divideAllWorkHand" @change="changeCheckDivider('maodeobra')"></v-checkbox>{{$t('globals.work_hand')}} 
                          </div>
                        </v-col>
                        <v-col cols="12" md="auto">
                          <div class="text-caption d-flex flex-row align-center mt-n4">        
                            <v-checkbox v-model="fields.divideAllMachines" @change="changeCheckDivider('maquinas')"></v-checkbox>{{$t('globals.machine')}}  
                          </div>
                        </v-col>
                        <v-col cols="12" md="auto" v-if="addMaterials">
                          <div class="text-caption d-flex flex-row align-center mt-n4">        
                            <v-checkbox v-model="fields.divideAllMaterials" @change="changeCheckDivider('materials')"></v-checkbox>{{$t('globals.materials')}}  
                          </div>
                        </v-col>
                      </v-row>
                      <v-simple-table dense class="d-none d-lg-block">
                        <template v-slot:default>
                          <thead class="primary">
                            <tr>
                              <th class="text-center white--text rounded-pill rounded-r-0" style="width:40%;">
                                {{$t('globals.name')}}
                              </th>
                              <th class="text-center white--text">
                                {{$t('globals.code')}}
                              </th>
                              <th class="text-center white--text">
                                {{$t('globals.free_text')}}
                              </th>
                              <th class="text-center white--text">
                                {{$t('globals.measure')}}
                              </th>
                              <th class="text-center white--text">
                                {{$t('globals.unit')}}
                              </th>
                              <th class="text-center white--text rounded-pill rounded-l-0">
                                {{$t('globals.options')}}
                              </th>
                            </tr>
                          </thead>
                          <tbody :key="reloadUnidadeObra" :class="($root.$vuetify.theme.isDark ? 'secondary' : '') + ' font-weight-light text-caption'">
                            <tr v-for="work_unit in fields.work_units" v-bind:key="work_unit.id">
                              <td class="text-center">
                                <validation-provider v-slot="{ errors }" vid="action_name" :name="$t('globals.work_unit')" rules="required">
                                  <v-autocomplete
                                    v-model="work_unit.unit_id"
                                    :label="$t('globals.work_unit') + '*'"
                                    :filter="filterAutocomplete"
                                    :items="units"
                                    item-value="id"
                                    :item-text="item => item.code + ' - ' + item.name"
                                    hide-selected
                                    :error-messages="errors"
                                  >
                                    <template v-slot:prepend>
                                      <TooltipErrors
                                          v-if="!validWorkUnit(work_unit).result"
                                          color="error"
                                          :messages="validWorkUnit(work_unit).errors"
                                          bottom
                                      >
                                        <v-icon color="error">mdi-alpha-x-circle</v-icon>
                                      </TooltipErrors>
                                      <v-icon color="success" v-else>mdi-check-bold</v-icon>
                                    </template>

                                  </v-autocomplete>
                                </validation-provider>
                              </td>
                              <td class="text-center" style="width:10% !important;">
                                <v-autocomplete
                                    v-model="work_unit.unit_id"
                                    :label="$t('globals.code') + '*'"
                                    :filter="filterAutocomplete"
                                    :items="units"
                                    item-value="id"
                                    :item-text="item => item.code"
                                    hide-selected
                                  >
                                  </v-autocomplete>
                                <!--{{work_unit.code}}-->
                              </td>
                              <td class="text-center">
                                <v-text-field
                                  v-model="work_unit.reason"
                                  :label="$t('globals.free_text')" class="align-end"
                                  @click="openEditorModal(work_unit)"
                                ></v-text-field>
                              </td>
                              <td class="text-center" style="width: 10% !important;">
                                <validation-provider v-slot="{ errors }" vid="measure" :name="$t('globals.measure')" rules="required">
                                  <v-text-field
                                    v-model="work_unit.measure"
                                    @change="replaceComma(work_unit)"
                                    :label="$t('globals.measure') + '*'"
                                    :error-messages="errors"
                                  ></v-text-field>
                                </validation-provider>
                              </td>
                              <td class="text-center" style="width: 5% !important;">
                                {{work_unit.unit}}
                              </td>
                              <td class="text-center">
                                <v-col cols="12" v-if="fields.workers.length > 0">
                                  <div :class="{ shake: shakeWorkHandButtons }">
                                  <v-btn
                                    width="100px"
                                    dark
                                    small
                                    color="secondary"
                                    @click="showWorkersWorkUnitsDialog(work_unit)"
                                  >
                                    <small>{{$t('globals.work_hand')}}</small>  
                                    <v-icon small>mdi-account-hard-hat</v-icon>
                                  </v-btn>
                                  </div>
                                </v-col>
                                <v-col cols="12" class="mt-n2" v-if="fields.machines.length > 0">
                                  <div :class="{shake: shakeMachineButtons }">
                                  <v-btn
                                    width="100px"
                                    dark
                                    small
                                    color="secondary"
                                    @click="showMachinesWorkDialog(work_unit)"
                                  >
                                    <small>{{$t('globals.machines')}}</small>
                                    <v-icon small>mdi-bulldozer</v-icon>
                                  </v-btn>
                                </div>
                                </v-col>
                                <v-col cols="12" class="mt-n2"  v-if="addMaterials && fields.materials.length > 0">
                                  <div :class="{shake: shakeMaterialButtons }">
                                  <v-btn
                                    width="100px"
                                    dark
                                    small
                                    color="secondary"
                                    @click="showMaterialsWorkDialog(work_unit)"
                                  >
                                    <small>{{$t('globals.materials')}}</small>
                                    <v-icon small>mdi-shipping-pallet</v-icon>
                                  </v-btn>
                                </div>
                                </v-col>
                                <v-col cols="12" class="mt-n2" v-if="!locked_edition">
                                  <v-btn
                                    width="100px"
                                    dark
                                    small
                                    color="error"
                                    @click="removeWorkUnit(work_unit.id)"
                                  >
                                  <small>{{$t('globals.delete')}}</small>
                                  <v-icon small>mdi-delete</v-icon>
                                </v-btn>
                                </v-col>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <v-data-iterator
                        :items="fields.work_units"
                        item-key="id"
                        :items-per-page="-1"
                        hide-default-footer
                        class="d-block d-lg-none"
                      >
                        <template v-slot:default="{ items }">
                          <v-row>
                            <v-col
                              v-for="work_unit in items"
                              :key="work_unit.id"
                              cols="12"
                              sm="6"
                              md="4"
                              lg="3"
                            >
                              <v-card>
                                <v-list
                                  dense
                                >
                                  <v-list-item>
                                    <v-list-item-content class="align-end">
                                      <validation-provider v-slot="{ errors }" vid="action_name" :name="$t('globals.work_unit')" rules="required">
                                        <v-autocomplete
                                          v-model="work_unit.unit_id"
                                          :label="$t('globals.work_unit') + '*'"
                                          :filter="filterAutocomplete"
                                          :items="units"
                                          item-value="id"
                                          :item-text="item => item.code + ' - ' + item.name"
                                          hide-selected
                                          :error-messages="errors"
                                        >
                                          <template v-slot:prepend>
                                            <TooltipErrors
                                                v-if="!validWorkUnit(work_unit).result"
                                                color="error"
                                                :messages="validWorkUnit(work_unit).errors"
                                                bottom
                                            >
                                              <v-icon color="error">mdi-alpha-x-circle</v-icon>
                                            </TooltipErrors>
                                            <v-icon color="success" v-else>mdi-check-bold</v-icon>
                                          </template>

                                        </v-autocomplete>
                                      </validation-provider>
                                    </v-list-item-content>
                                  </v-list-item>
                                  
                                  <v-list-item>
                                    <v-list-item-content class="align-end">                                    
                                      <v-combobox
                                          v-model="work_unit.code"
                                          :label="$t('globals.code') + '*'"
                                          :items="units"
                                          @input="work_unit.code = $event.code;work_unit.name = $event.name; work_unit.unit = $event.unit; work_unit.unit_id = $event.id"
                                          item-value="id"
                                          item-text="code"
                                          hide-selected
                                        />
                                    </v-list-item-content>
                                  </v-list-item>
                                  
                                  <v-list-item>
                                    <v-list-item-content class="align-end">
                                      <validation-provider v-slot="{ errors }" vid="reason" :name="$t('globals.free_text')">                                     
                                        <v-text-field
                                          v-model="work_unit.reason"
                                          :label="$t('globals.free_text')" class="align-end"
                                          @click="openEditorModal(work_unit)"
                                          :error-messages="errors"
                                        ></v-text-field>
                                      </validation-provider>
                                    </v-list-item-content>
                                  </v-list-item>

                                  <v-list-item>
                                    <v-list-item-content class="align-end">
                                      <validation-provider v-slot="{ errors }" vid="measure" :name="$t('globals.measure')" rules="required">
                                        <v-text-field
                                          v-model="work_unit.measure"
                                          :label="$t('globals.measure') + '*'"
                                          :error-messages="errors"
                                        ></v-text-field>
                                      </validation-provider>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item>
                                    <v-list-item-content class="align-end">
                                      <v-text-field
                                          :disabled="true"
                                          v-model="work_unit.unit"
                                          :label="$t('globals.unit') + '*'"
                                        ></v-text-field>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item v-if="fields.workers.length > 0">
                                    <v-list-item-content class="align-end">
                                      <v-btn
                                        dark
                                        small
                                        block
                                        color="secondary"
                                        @click="showWorkersWorkUnitsDialog(work_unit)"
                                      >
                                    <small>{{$t('globals.work_hand')}}</small><v-icon class="ml-2" small>mdi-account-hard-hat</v-icon>
                                      </v-btn>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item v-if="fields.machines.length > 0">
                                    <v-list-item-content class="align-end">
                                      <v-btn
                                        dark
                                        small
                                        block
                                        color="secondary"
                                        @click="showMachinesWorkDialog(work_unit)"
                                      >
                                      <small>{{$t('globals.machines')}}</small><v-icon class="ml-2" small>mdi-bulldozer</v-icon>
                                      </v-btn>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item v-if="addMaterials && fields.materials.length > 0">
                                    <v-list-item-content class="align-end">
                                      <v-btn
                                        dark
                                        small
                                        color="secondary"
                                        @click="showMaterialsWorkDialog(work_unit)"
                                      >
                                        <small>{{$t('globals.materials')}}</small>
                                        <v-icon small>mdi-shipping-pallet</v-icon>
                                      </v-btn>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item v-if="!locked_edition">
                                    <v-list-item-content class="align-end">
                                      <v-btn
                                        dark
                                        small
                                        color="error"
                                        @click="removeWorkUnit(work_unit.id)"
                                      >
                                      <small>{{$t('globals.remove')}}</small><v-icon class="ml-2" small>mdi-delete</v-icon>
                                      </v-btn>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-card>
                            </v-col>
                          </v-row>
                        </template>
                      </v-data-iterator>
                      <v-row justify="center" v-if="!locked_edition">
                        <v-btn
                          fab
                          dark
                          small
                          color="primary"
                          class="mt-7"
                          @click="addWorkUnit"
                        >
                          <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-show="diaryCreationPossible">
                    <v-col
                      cols="12"
                    >
                      <v-textarea
                        outlined
                        v-model="fields.description"
                        :label="$t('globals.free_text')"
                        prepend-inner-icon="mdi-card-text"
                        counter="900"
                        maxlength="900"
                        clearable
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <div class=" d-none d-lg-block" v-if="diaryCreationPossible">
                    <v-row class="mt-6" justify="space-around">
                      <v-btn v-if="$route.name != 'create'" :disabled="invalid || locked_edition" depressed color="warning" @click="tab=1">
                        {{$t('diaries.insert_images')}}
                      </v-btn>
                      <v-btn v-if="$route.name != 'create'" :disabled="invalid || locked_edition" depressed color="success" @click="checkApproval = true">
                        {{$t('globals.approve')}}
                      </v-btn>
                      <v-btn :disabled="invalid || locked_edition" depressed :color="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary'" type="submit">
                        {{$t('globals.save')}}
                      </v-btn>
                      
                      <Reject v-if="method == 'update'" :opened="rejectDiary" :label="$t('diaries.rejection_motive_message')" vid="reject_modal" :title="$t('diaries.rejection_reason_insert')"/>

                      <v-menu offset-y v-if="$route.name != 'create'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :disabled="invalid" depressed
                            outlined
                            color="danger"
                            v-bind="attrs"
                            v-on="on"
                          >
                          {{$t('globals.print')}}
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="exportDiary('Excel')">
                            <v-list-item-title>Excel</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="exportDiary('PDF')">
                            <v-list-item-title>PDF</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-row>
                  </div>
                  <!-- BTNS MOBILE-->
                  <div class="d-block d-lg-none" v-if="diaryCreationPossible">
                    <v-row class="mt-4" align="center" justify="space-around">
                      <v-btn v-if="$route.name != 'create'" :disabled="invalid || locked_edition" depressed color="warning" @click="tab=1">
                        {{$t('diaries.insert_images')}}
                      </v-btn>
                    </v-row>
                    <v-row  class="mt-8" align="center" justify="space-around">
                      <v-btn v-if="$route.name != 'create'" :disabled="invalid || locked_edition" depressed color="success" @click="checkApproval = true">
                        {{$t('globals.approve')}}
                      </v-btn>
                    
                    </v-row>
                    <v-row  class="mt-8" align="center" justify="space-around">
                      <v-btn :disabled="invalid || locked_edition" depressed color="primary" type="submit">
                        {{$t('globals.save')}}
                      </v-btn>
                      
                    </v-row>
                    <v-row  class="mt-8" align="center" justify="space-around">
                      <v-btn v-if="$route.name != 'create'" :disabled="$root.session.hasRole(['encarregado']) || invalid || locked_edition" depressed color="red" @click="rejectDiary = true">
                        {{$t('globals.reject')}}
                      </v-btn>
                      </v-row>
                    <v-row  class="mt-8" align="center" justify="space-around">
                      <v-menu offset-y v-if="$route.name != 'create'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :disabled="invalid" depressed
                            outlined
                            color="danger"
                            v-bind="attrs"
                            v-on="on"
                          >
                          {{$t('globals.print')}}
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="exportDiary('Excel')">
                            <v-list-item-title>Excel</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="exportDiary('PDF')">
                            <v-list-item-title>PDF</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-row>
                  </div>
                  <!-- BTNS MOBILE-->
              </v-form>
          </validation-observer>
            </v-container>
          <DialogSuccess :opened="success" @on-ok="onSuccess" >
            {{$t('diaries.success_diary_saved')}}
          </DialogSuccess>
        </v-tab-item>
        <v-tab-item class="mt-6" :key="2">
          <v-row>
            <v-col cols="12">
              <v-btn
                :class="($root.$vuetify.theme.isDark ? 'white primary--text' : '') + ' d-none d-lg-block mb-4 mt-4 mr-2'"
                style="float:right;"
                dark
                @click="downloadImages()"
              >
                {{$t('diaries.download_all')}}
                <v-icon class="ml-2">mdi-briefcase-download</v-icon>
              </v-btn>
              <v-btn
                :class="($root.$vuetify.theme.isDark ? 'white primary--text' : '') + ' d-sm-none mb-4 mt-4 mr-2'"
                block
                dark
                @click="downloadImages()"
              >
              {{$t('diaries.download_all')}}
                <v-icon class="ml-2">mdi-briefcase-download</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <div class="d-flex justify-center mb-8">
                <VueUploadMultipleImage
                  ref="imageUploader"
                  class="image-uploader"
                  :dragText="$t('diaries.insert_images')"
                  :browseText="$t('diaries.click_here')"
                  :showPrimary="false"
                  @upload-success="uploadImageSuccess"
                  @before-remove="beforeRemove"
                  :data-images="images"
                  idUpload="myIdUpload"
                  editUpload="myIdEdit"
                  :maxImage="200"
                  :disabled="locked_edition"
                />
              </div>
            </v-col>
          </v-row>
          <DialogConfirmation 
            :title="$t('diaries.remove_image')" 
            type="warning" 
            :opened="openConfirmationRemoveImage" 
            @on-submit="submitRemoveImage"
            :key="removeImageDialog"
            @on-cancel="openConfirmationRemoveImage = false">
              {{$t('diaries.are_you_sure_remove_image')}}
          </DialogConfirmation>
        </v-tab-item>
        
      </v-tabs-items>
    </v-card>
    <v-row justify="center">
      <v-dialog
        v-model="showingWorkers"
        max-width="800px"
      >
      <!--
        <MaoObra 
          v-if="showingWorkers" 
          v-show="showAutoWorkers"
          ref="MaoObraModal"
          :diary_status="diary_status"
          :locked_edition="locked_edition"
          :fields="fields"
          :workers_auto="workers_auto"
          :work_unit_id="work_unit_id+''"
          :is_error="alerts.workHandAlert"
          :divideAll="fields.divideAllWorkHand"
          @close="showingWorkers = false"
          @close-with-work="closeWithWork"
          @reload="reload"
          @submit="workHandSubmission"
          return-object
        />

      -->
      <WorkHandDivision v-if="showingWorkers"
           :fields="fields" 
           :locked_edition="locked_edition" 
           :work_unit_id="selectedWorkUnit.id"
           :auto_divider="fields.divideAllWorkHand"
           @submit="workHandSubmission"
      />
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="showingMachines"
        max-width="800px"
      >
      <!--  
      <Machines
          v-if="showingMachines"
          v-show="showAutoMachines"
          :diary_status="diary_status" 
          :locked_edition="locked_edition"
          :fields="fields"
          :machines_auto="machines_auto"
          :work_unit_id="work_unit_id+''"
          :is_error="alerts.machineAlert"
          :divideAll="fields.divideAllMachines"
          @close="showingMachines = false"
          @close-with-work="closeWithMachineWork"
          @reload="reload"
          return-object
        />
      -->
      <MachineWorkDivision v-if="showingMachines"
           :fields="fields" 
           :locked_edition="locked_edition" 
           :work_unit_id="selectedWorkUnit.id"
           :auto_divider="fields.divideAllMachines"
           @submit="machineHandSubmission"
      />
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="showingMaterials"
        max-width="800px"
      >
        <!--<Materials
          v-if="showingMaterials"
          v-show="showAutoMaterials"
          :diary_status="diary_status"
          :locked_edition="locked_edition"
          :fields="fields"
          :materials_auto="materials_auto"
          :work_unit_id="work_unit_id+''"
          :is_error="alerts.materialAlert"
          :divideAll="fields.divideAllMaterials"
          @close="showingMaterials = false"
          @close-with-work="closeWithMaterialWork"
          @reload="reload"
          return-object
        />
        -->        
        <MaterialWorkDivision v-if="showingMaterials"
           :fields="fields" 
           :locked_edition="locked_edition" 
           :work_unit_id="selectedWorkUnit.id"
           :auto_divider="fields.divideAllMaterials"
           @submit="materialHandSubmission"
      />
      </v-dialog>
    </v-row>
    <ErrorSnackbar v-model="error.show" :message="error.message"/>
    <WarningSnackbar v-model="warning.show" :message="warning.message"/>
    <ExtensiveErrorSnackbar v-model="error.showExtensive" :message="error.message"/>
    <v-dialog
            v-if="editorModal"
            v-model="editorModal"
            max-width="600px"
            scrollable
        >
      <ModalTextArea :locked="locked_edition" @change-value="changeModalTextArea" vid="editor_local" :title="$t('globals.free_text')" :label="$t('globals.free_text')" v-model="inputEditorModalData.itemModel"/>
    </v-dialog>
    <DialogConfirmation v-if="checkApproval"    
      :title="$t('diaries.confirm_action')" 
      type="info" 
      :opened="checkApproval"
      @on-submit="approve" 
      @on-cancel="checkApproval = false" >
      {{$t('diaries.are_you_sure')}}
    </DialogConfirmation>
    <DialogConfirmation 
          v-if="dieselObject.emptyDiesel"
          :title="$t('diaries.diesel_fields')" 
          type="warning" 
          :opened="dieselObject.emptyDiesel" 
          @on-submit="passDiesel" 
          @on-cancel="loading = false; dieselObject.emptyDiesel = false">
            {{$t('diaries.diesel_warning')}}
    </DialogConfirmation>    
    <DialogConfirmation 
          v-if="divideHours.show"
          :title="divideHours.title" 
          type="warning" 
          :opened="divideHours.show" 
          @on-submit="dividirHorasTodos" 
          @on-cancel="loading = false; divideHours.show = false">
            {{ divideHours.msg }}
    </DialogConfirmation> 
    <Reject v-if="rejectDiary" @on-submit="rejeitarDiaria" @on-cancel="rejectDiary = false" :opened="rejectDiary" vid="reject_modal" :title="$t('diaries.rejection_reason_insert')"/>
    <Loader v-if="exportingDocument"></Loader>  
    <Loader v-if="loading"></Loader>  
    <DialogSuccess :opened="successDivided" @on-ok="successDivided = false" >
      {{$t('diaries.divided_hours')}}
    </DialogSuccess>
  </div>
</template>
<script>
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import DialogConfirmation from '@/components/ui/DialogConfirmation.vue';
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
import WarningSnackbar from '@/components/ui/WarningSnackbar.vue';
import ExtensiveErrorSnackbar from '@/components/ui/ExtensiveErrorSnackbar.vue';
import Work from "@/api/Work.js";
import Diary from "@/api/Diary.js";
import Worker from "@/api/Worker.js";
import Export from "@/api/Export.js";
import Machine from "@/api/Machine.js";
import Material from "@/api/Materials.js";
import Unit from "@/api/Unit.js";
import dayjs from 'dayjs'
import Select from '@/components/inputs/Select'
import ModalTextArea from '@/components/ui/ModalTextArea.vue';
import Downloads from "@/api/Downloads.js";
import Reject from '@/components/diaries/dialogs/Reject'
import Loader from '@/components/ui/Loader.vue'
import GanttTime from '@/components/works/gantt/GanttTime.vue'
import TooltipErrors from '@/components/ui/TooltipErrors.vue';
import WorkHandDivision from '@/components/diaries/dialogs/WorkHandDivision.vue'
import MachineWorkDivision from '@/components/diaries/dialogs/MachineWorkDivision.vue'
import MaterialWorkDivision from '@/components/diaries/dialogs/MaterialWorkDivision.vue'
import Tooltip from '@/components/ui/Tooltip.vue';



export default {
  components: {
    TooltipErrors,
    Tooltip,
    DialogSuccess,
    VueUploadMultipleImage,
    DialogConfirmation,
    ErrorSnackbar,
    Select,
    WarningSnackbar,
    ModalTextArea,
    Reject,
    ExtensiveErrorSnackbar,
    Loader,
    GanttTime,
    WorkHandDivision,
    MachineWorkDivision,
    MaterialWorkDivision
  },
  props: {
   diary: Object,
   method: String,
   disabled: Boolean,
 },
 watch: { 
    diary: {
      immediate: true, 
      handler (val) {
        if(!val) {
          return;
        }

        let data = {...val};

        this.fields = data;


        this.fields.date = dayjs(this.fields.date).format("YYYY-MM-DD");

        this.fields.id = data.id;

        this.fixWorkUnitLineNumber()

        this.diary_status = data.status;

        this.buildImages(data)

        this.fields.machines.map(item => {

          let obj = {...item};

          this.machinesBackup.push(
            {
              id: item.id,
              started_hours: item.started_hours,
              finished_hours: item.finished_hours
            }
          )

          return item.object = obj;
        })
        

        this.fields.materials.map(item => {

            let obj = {...item};

            this.materialsBackup.push(
              {
                id: item.id,
                quantity: item.quantity,
                reason: item.reason
              }
            )

            return item.object = obj;
          })


        this.fields.workers.map(item => {

          let obj = {...item};

          
          return item.object = obj;
        })

        this.fields.divideAllMachines = false
        this.fields.divideAllWorkHand = false
        this.fields.divideAllMaterials = false

        if(this.diary.status == 'FINISHED_APPROVAL')
          this.locked_edition = true

      }
    },
  },
  data: () => ({
    reloadUnidadeObra: 9999,
    ganttTimeReloader: 500,
    workers_auto: false,
    machines_auto: false,
    materials_auto: false,
    divideHours:{
      show: false,
      title: '',
      type: '', 
      machines: false,
      workHand: false
    },
    removeImageDialog: 0,
    exportingDocument: false,
    shakeWorkHandButtons: false,
    shakeMachineButtons: false, 
    shakeMaterialButtons: false,
    diaryCreationPossible: false,
    alerts:{
      machineAlert: false,
      workHandAlert: false,
      materialAlert: false
    },
    keyReason:0,
    refreshComponent: false,
    locked_edition:false,
    dieselObject:{
      ignoreDiesel: false,
      emptyDiesel: false
    },
    rejectDiary: false,
    generalKey:0,
    editorModal:false,
    inputEditorModalData:{
        itemModel:null,
        id: null,
        object: null
    },
    templateField: null,
    addMaterials: false,
    useTemplate: false,
    blockTemplate: false,
    blockReplicar:true,
    replicar: false,
    newDiary: false,
    renderWorkedHours: 0,
    renderMachineWorkedHours: 0,
    tab: 0,
    workers: [],
    removedWorkers: [],
    work_units: [],
    work_unit_id: null,
    showingWorkers: false,
    showAutoWorkers: false,
    showAutoMachines: false,
    showAutoMaterials: false,
    showingMachines: false,
    showingMaterials: false,
    diary_status: null,
    fields: {
      id: null,
      work_id: null,
      code: null,
      date: null,
      workers: [],
      work_units: [],
      machines: [],
      materials: [],
      name: null,
      status:null,
      description:null,
      started_at:null,
      finished_at:null,
      commissioner_id: null,
      overseer_id: null,
      engineer_id: null,
      files: null,
      newWorkUnit: null,
      machinesWorkUnit: null,
      materialsWorkUnit: null,
      divideAllWorkHand: false,
      divideAllMachines: false,
      divideAllMaterials: false,
      machine_work_units: [],
      material_work_units: [],
      worker_work_units: []
    },
    filesFormData: [],
    loading:false,
    loadingAction:false,
    error: {
      title: '',
      message: '',
      show:false,
      showExtensive: false
    },
    warning:{
      show: false,
      title: '',
      message: ''
    },
    status: [],
    works: [],
    machines: [],
    materials: [],
    images: [],
    success: false,
    successDivided: false,
    date:false,
    units: [],
    openConfirmationRemoveImage: false,
    fileIndex: [],
    checkApproval: false,
    ganttWork: null,
    machinesBackup: [],
    materialsBackup: [],
    // NEW VERSION
    selectedWorkUnit: null
  }),
  async mounted(){
    this.loading = true
    await this.fillBaseData();


    let work_id = this.$route.params.work_id
    let start_date = this.$route.params.start_date
    
    if(this.$route.name == 'create' && work_id != undefined && start_date != undefined){
      this.fields.work_id = work_id
      this.setDiaryCode(work_id)
      this.fields.date = start_date
      this.checkDiaryCreation()
    }

    await this.getWork()    

    if(this.$route.params.id != undefined)
      this.diaryCreationPossible = true

    this.loading = false
  },
  computed:{      
    getWeekNumber(){
            if(this.fields.date == null)
                return ''

            let currentDate = new Date(this.fields.date)
            let year = new Date(currentDate.getFullYear(), 0, 1);
            let days = Math.floor((currentDate - year) / (24 * 60 * 60 * 1000));

            return this.$t('diaries.week') + ': ' + Math.ceil(( currentDate.getDay() + 1 + days) / 7)
        },
  },
  methods:{
    showMaterialsWorkDialog(selectedWorkUnit){
      this.selectedWorkUnit = selectedWorkUnit

      this.showingMaterials = true
    },
    materialHandSubmission(material_hands){
      material_hands.forEach(element_materials => {
        let pos = -1
        let i = 0

        this.fields.material_work_units.forEach(mwu => {
          if(mwu.work_unit_id == element_materials.work_unit_id && mwu.material_id == element_materials.material_id)
            pos = i

          i++
        });

        // Se pos == -1 então não encontrou nada, temos de criar um registo novo
        // se diferente de -1, é porque existe e temos de atualizar o registo atual
        if(pos == -1){
          this.fields.material_work_units.push({
            id: 'new_' + element_materials.material_id,
            line_number: this.fields.material_work_units.length,
            unit_id: this.selectedWorkUnit.unit_id,
            work_unit_id: this.selectedWorkUnit.id,
            material_id: element_materials.material_id,
            quantity: element_materials.quantity
          })
        }else{
          this.fields.material_work_units[pos].quantity = element_materials.quantity
        }

      });
      this.showingMaterials = false
    },
    showMachinesWorkDialog(selectedWorkUnit){
      this.selectedWorkUnit = selectedWorkUnit

      this.showingMachines = true
    },
    machineHandSubmission(machine_hands){
      machine_hands.forEach(element_machines => {
        let pos = -1
        let i = 0

        this.fields.machine_work_units.forEach(mwu => {
          if(mwu.work_unit_id == element_machines.work_unit_id && mwu.machine_id == element_machines.machine_id)
            pos = i

          i++
        });

        // Se pos == -1 então não encontrou nada, temos de criar um registo novo
        // se diferente de -1, é porque existe e temos de atualizar o registo atual
        if(pos == -1){
          this.fields.machine_work_units.push({
            id: 'new_' + element_machines.machine_id,
            line_number: this.fields.machine_work_units.length,
            unit_id: this.selectedWorkUnit.unit_id,
            work_unit_id: this.selectedWorkUnit.id,
            machine_id: element_machines.machine_id,
            worked_hours: element_machines.worked_hours
          })
        }else{
          this.fields.machine_work_units[pos].worked_hours = element_machines.worked_hours
        }

      });
      this.showingMachines = false
    },
    showWorkersWorkUnitsDialog(selectedWorkUnit){
      this.selectedWorkUnit = selectedWorkUnit
      
      this.showingWorkers = true
    },
    workHandSubmission(work_hands){  
      work_hands.forEach(element_hands => {
        let pos = -1
        let i = 0

        this.fields.worker_work_units.forEach(wwu => {
          if(wwu.work_unit_id == element_hands.work_unit_id && wwu.worker_id == element_hands.worker_id)
            pos = i

          i++
        });

        // Se pos == -1 então não encontrou nada, temos de criar um registo novo
        // se diferente de -1, é porque existe e temos de atualizar o registo atual
        if(pos == -1){
          this.fields.worker_work_units.push({
            id: 'new_' + element_hands.worker_id,
            line_number: this.fields.worker_work_units.length,
            unit_id: this.selectedWorkUnit.unit_id,
            work_unit_id: this.selectedWorkUnit.id,
            worker_id: element_hands.worker_id,
            worked_hours: element_hands.worked_hours
          })
        }else{
          this.fields.worker_work_units[pos].worked_hours = element_hands.worked_hours
        }

      });

      this.showingWorkers = false
      this.reloadUnidadeObra++
    },
    areHoursValid(){
      let flag = true;          
      
      this.error.message = this.$t('diaries.divide_hours_unit_superior')
      this.fields.workers.forEach(element => {
        let dt1 = new Date("October 13, 2023 " + element.started_hour + ":" + element.started_minute + ":00");
        let dt2 = new Date("October 13, 2023 " + element.finished_hour + ":" + element.finished_minute + ":00");

        var diff =(dt2.getTime() - dt1.getTime()) / 1000;
        diff /= (60 * 60);
        diff = Math.abs(Math.round(diff));

        if(element.lunch_hour != null)
          diff = diff - parseInt(element.lunch_hour);
        else diff = diff - 1;
        

        let workerSumHours = 0
        if(this.fields.worker_work_units != undefined){
          this.fields.worker_work_units.forEach(auxiliar => {
            if(auxiliar.worker_id == element.id)
              workerSumHours = parseInt(workerSumHours) + parseInt(auxiliar.worked_hours)
          });
        }

        if(this.fields.newWorkUnit != undefined || this.fields.newWorkUnit != null)
          this.fields.newWorkUnit.forEach(auxiliar => {
            if(auxiliar.worker_id == element.id)
              workerSumHours = parseInt(workerSumHours) + parseInt(auxiliar.worked_hours)
          })

        if(workerSumHours > diff){
          this.error.message = this.error.message + "<br/><br/>" + element.name;
          flag = false
        }

        
      });

      if(flag == false)
        this.error.showExtensive = true
        
      return flag;
    },
    areMachineHoursValid(){
      let flag = true;  
    
      
      this.error.message = this.$t('diaries.divide_hours_unit_superior_machines')
      this.fields.machines.forEach(element => {
        let diff = element.finished_hours - element.started_hours

        let machineSumHours = 0
        if(this.fields.machine_work_units != undefined){
          this.fields.machine_work_units.forEach(auxiliar => {
            if(auxiliar.machine_id == element.id)
              machineSumHours = parseInt(machineSumHours) + parseInt(auxiliar.worked_hours)
          });
        }

        if(machineSumHours > diff){
          this.error.message = this.error.message + "<br/><br/>" + element.name;
          flag = false
        }

        
      });

      if(flag == false)
        this.error.showExtensive = true
        
      return flag;
    },
    areMaterialQuantityValid(){
      let flag = true;  
    
      
        this.error.message = this.$t('diaries.divide_quantity_unit_superior_materials')
        this.fields.materials.forEach(element => {
          let diff = element.quantity

          let materialSumQty = 0
          if(this.fields.material_work_units != undefined){
            this.fields.material_work_units.forEach(auxiliar => {
              if(auxiliar.material_id == element.id)
                materialSumQty = parseInt(materialSumQty) + parseInt(auxiliar.quantity)
            });
          }


          if(materialSumQty > diff){
            this.error.message = this.error.message + "<br/><br/>" + element.name;
            flag = false
          }

          
        });

        if(flag == false)
          this.error.showExtensive = true
          
        return flag;
    },
    validWorkUnit(wu){
      let returner = {
        result: true,
        errors:[]
      };

/*
      // Validar unidade obra
      if(wu.name == '' || wu.name == null || wu.name == undefined){
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.work_unit_fault')))
          returner.errors.push(this.$t('diaries.missing_fields_errors.work_unit_fault'))
        returner.result = false
      }

      // Validar unidade obra
      if(wu.code == '' || wu.code == null || wu.code == undefined){
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.work_unit_fault')))
          returner.errors.push(this.$t('diaries.missing_fields_errors.work_unit_fault'))
        returner.result = false
      }*/

      if(wu.unit_id == null || wu.unit_id+'' == '' || wu.unit_id == undefined){
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.work_unit_fault')))
          returner.errors.push(this.$t('diaries.missing_fields_errors.work_unit_fault'))
        returner.result = false
      }

      // Validar medição
      if(wu.measure+'' == '' || wu.measure == null || wu.measure == undefined){ 
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.work_unit_measure_fault')))
          returner.errors.push(this.$t('diaries.missing_fields_errors.work_unit_measure_fault'))
        returner.result = false
      }

      // Validar Mão de Obra
      if(this.fields.divideAllWorkHand == false){
        // Só faz sentido validar se houver mão de obra inserida
        if(this.fields.workers.length > 0){
          if(this.fields.worker_work_units.length == 0){
            // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
            if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.work_hand_missing')))
              returner.errors.push(this.$t('diaries.missing_fields_errors.work_hand_missing'))
            returner.result = false
          }else{
            // caso não seja de tamanho zero, significa que tem dados
            // então vamos percorrer os colaboradores
            // e verificar se estes têm as horas inseridas
            // se não encontrou nenhum é porque está em falta
            let found = false
            this.fields.worker_work_units.forEach(element => {
              if(element.unit_id == wu.unit_id){
                // Encontrei um registo, não há problemas
                found = true;
                if(element.worked_hours+'' == '' || element.worked_hours == null || element.worked_hours == undefined){
                  // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
                  if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.work_hand_missing')))
                    returner.errors.push(this.$t('diaries.missing_fields_errors.work_hand_missing'))
                  returner.result = false
                }
              }
            });
            // caso não tenha encontrado nenhum registo, está em falta. Temos de lançar a mensagem
            if(found == false){
              // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
              if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.work_hand_missing')))
                returner.errors.push(this.$t('diaries.missing_fields_errors.work_hand_missing'))
              returner.result = false
            }
          }
        }
      }



      // Validar Máquinas
      if(this.fields.divideAllMachines == false){
        if(this.fields.machines.length > 0){
          if(this.fields.machine_work_units.length == 0){
            // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
            if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.machine_missing')))
              returner.errors.push(this.$t('diaries.missing_fields_errors.machine_missing'))
            returner.result = false
          }else{
            // caso não seja de tamanho zero, significa que tem dados
            // então vamos percorrer os colaboradores
            // e verificar se estes têm as horas inseridas
            // se não encontrou nenhum é porque está em falta
            let found = false
            this.fields.machine_work_units.forEach(element => {
              if(element.unit_id == wu.unit_id){
                // Encontrei um registo, não há problemas
                found = true;
                if(element.worked_hours+'' == '' || element.worked_hours == null || element.worked_hours == undefined){
                  // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
                  if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.machine_missing')))
                    returner.errors.push(this.$t('diaries.missing_fields_errors.machine_missing'))
                  returner.result = false
                }
              }
            });
            // caso não tenha encontrado nenhum registo, está em falta. Temos de lançar a mensagem
            if(found == false){
              // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
              if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.machine_missing')))
                returner.errors.push(this.$t('diaries.missing_fields_errors.machine_missing'))
              returner.result = false
            }
          }
        }
      }



      // Validar Materiais
      if(this.addMaterials){
        if(this.fields.divideAllMaterials == false){
          if(this.fields.materials.length > 0){
            if(this.fields.material_work_units.length == 0){
              // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
              if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.material_missing')))
                returner.errors.push(this.$t('diaries.missing_fields_errors.material_missing'))
              returner.result = false
            }else{
              // caso não seja de tamanho zero, significa que tem dados
              // então vamos percorrer os colaboradores
              // e verificar se estes têm as horas inseridas
              // se não encontrou nenhum é porque está em falta
              let found = false
              this.fields.material_work_units.forEach(element => {
                if(element.unit_id == wu.unit_id){
                  // Encontrei um registo, não há problemas
                  found = true;
                  if(element.quantity+'' == '' || element.quantity == null || element.quantity == undefined){
                    // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
                    if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.material_missing')))
                      returner.errors.push(this.$t('diaries.missing_fields_errors.material_missing'))
                    returner.result = false
                  }
                }
              });
              // caso não tenha encontrado nenhum registo, está em falta. Temos de lançar a mensagem
              if(found == false){
                // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
                if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.material_missing')))
                  returner.errors.push(this.$t('diaries.missing_fields_errors.material_missing'))
                returner.result = false
              }
            }
          }
        }
      }

      
      // Se tiver máquinas, então tem de ter registo das máquinas também
      if(this.fields.machines.length > 0){
        if(this.fields.divideAllMachines == false){
          if(this.fields.machine_work_units.length == 0){
            // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
            if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.machine_missing')))
              returner.errors.push(this.$t('diaries.missing_fields_errors.machine_missing'))
            returner.result = false
            
          }
        }
      }


      // Se for para adicionar materiais
      // Então temos de verificar se existem no array de materiais
      // Se existirem temos de verificar se tem registo de materiais nas uo também
      if(this.addMaterials){
        if(this.fields.divideAllMaterials == false){
          if(this.fields.materials.length > 0){
            if(this.fields.material_work_units.length == 0){
            // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
            if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.material_missing')))
              returner.errors.push(this.$t('diaries.missing_fields_errors.material_missing'))
            returner.result = false            
            }
          }
        }
      }

      return returner
    },
    validWorkHand(hand){
      let returner = {
        result: true,
        errors:[]
      };

      if(hand.started_hour == null || hand.started_hour == undefined){
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.entry_hour_error')))
          returner.errors.push(this.$t('diaries.missing_fields_errors.entry_hour_error'))
        returner.result = false
      }else{
        if(typeof hand.started_hour == "string"){
          if(hand.started_hour == ''){
            // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
            if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.entry_hour_error')))
              returner.errors.push(this.$t('diaries.missing_fields_errors.entry_hour_error'))
            returner.result = false
          }
        }
      }

      if(hand.started_minute == null || hand.started_minute == undefined){
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.entry_hour_error')))
          returner.errors.push(this.$t('diaries.missing_fields_errors.entry_hour_error'))
        returner.result = false
      }else{        
        if(typeof hand.started_minute == "string"){
          if(hand.started_minute == ''){
            returner.errors.push(this.$t('diaries.missing_fields_errors.entry_hour_error'))
            returner.result = false
          }
        }
      }

      if(hand.finished_hour == null || hand.finished_hour == undefined){
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.exit_hour_error')))
          returner.errors.push(this.$t('diaries.missing_fields_errors.exit_hour_error'))
        returner.result = false
      }else{
        if(typeof hand.finished_hour == "string"){
          if(hand.finished_hour == ''){
            // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
            if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.exit_hour_error')))
              returner.errors.push(this.$t('diaries.missing_fields_errors.exit_hour_error'))
            returner.result = false
          }
        }
      }

      if(hand.finished_minute == null || hand.finished_minute == undefined){
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.exit_hour_error')))
          returner.errors.push(this.$t('diaries.missing_fields_errors.exit_hour_error'))
        returner.result = false
      }else{
        if(typeof hand.finished_minute == "string"){
          if(hand.finished_minute == ''){
            // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
            if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.exit_hour_error')))
              returner.errors.push(this.$t('diaries.missing_fields_errors.exit_hour_error'))
            returner.result = false
          }
        }
      }    

      if(hand.object == null || hand.object == undefined){
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.work_hand_missing')))
          returner.errors.push(this.$t('diaries.missing_fields_errors.work_hand_missing'))
        returner.result = false
      }

      return returner;
    },
    validMachine(machine){
      let returner = {
        result: true,
        errors:[]
      };

      if(machine.name == '' || machine.name == null || machine.name == undefined){
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.missing_machine_error')))
          returner.errors.push(this.$t('diaries.missing_fields_errors.missing_machine_error'))
          
        returner.result = false
      }

      if(machine.control_diesel){
        if(machine.diesel == null || machine.diesel+'' == '' || machine.diesel == undefined){
          // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
          if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.diesel_missing')))
            returner.errors.push(this.$t('diaries.missing_fields_errors.diesel_missing'))
          returner.result = false
        }
      }

      if(machine.control_adblue){
        if(machine.adblue == null || machine.adblue+'' == '' || machine.adblue == undefined){
          // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
          if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.adblue_missing')))
            returner.errors.push(this.$t('diaries.missing_fields_errors.adblue_missing'))
          returner.result = false
        }
      }

      if(machine.started_hours == '' || machine.started_hours == null || machine.started_hours == undefined){
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.machine_missing_hours')))
          returner.errors.push(this.$t('diaries.missing_fields_errors.machine_missing_hours'))
        returner.result = false
      }

      if(machine.finished_hours == '' || machine.finished_hours == null || machine.finished_hours == undefined){
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.machine_missing_hours_end')))
          returner.errors.push(this.$t('diaries.missing_fields_errors.machine_missing_hours_end'))
        returner.result = false
      }


      if(machine.started_hours > machine.finished_hours){
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.entry_hours_bigger')))
          returner.errors.push(this.$t('diaries.missing_fields_errors.entry_hours_bigger'))
        returner.result = false
      }


      return returner;
    },
    validMaterial(material){
      let returner = {
        result: true,
        errors:[],
        warning: false
      };

      if(material.reference == null || material.reference == '' || material.reference == undefined
        || material.name == null || material.name == '' || material.name == undefined){
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.missing_material')))
          returner.errors.push(this.$t('diaries.missing_fields_errors.missing_material'))
        returner.result = false   
      }

      if(material.quantity == null || material.quantity+'' == '' || material.quantity == undefined){
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.material_quantity_missing')))
          returner.errors.push(this.$t('diaries.missing_fields_errors.material_quantity_missing'))
        returner.result = false   
      }

      let available = parseFloat(material.stock) - parseFloat(material.reserved)
       
      if(parseFloat(material.quantity) > parseFloat(available)){
        if(returner.errors.length == 0)
          returner.warning = true
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if(!returner.errors.includes(this.$t('diaries.missing_fields_errors.material_qty_warning')))
          returner.errors.push(this.$t('diaries.missing_fields_errors.material_qty_warning'))
        returner.result = false
      }

      return returner;
    },
    checkDifference(machine){
      if(machine.started_hours == null || machine.finished_hours == null)
        return

      let found = false;
      if(parseInt(machine.started_hours) > parseInt(machine.finished_hours)){
        if(this.machinesBackup.length > 0){
          this.machinesBackup.forEach(element => {
            if(element.id == machine.id){
              machine.started_hours = element.started_hours
              machine.finished_hours = element.finished_hours
              found = true
            }
            
          });
        }else{
          this.machines.forEach(element => {
            if(element.id == machine.id){
              machine.started_hours = element.next_start
              machine.finished_hours = null
              found = true
            }
          });
        }

        // Pode ser um registo novo, logo não terá informação no backup de máquinas
        if(found == false){
          machine.started_hours = null
          machine.finished_hours = null
        }
        
        this.error.message = '[' + machine.name + '] - ' + this.$t('diaries.machines_superior_msg')
        this.error.showExtensive = true
      }
    },
    async getWork(){
      if(this.fields.work_id == null)
        return
      if(this.fields.work_id == undefined)
        return
        
      let self = this
      await Work.find(this.fields.work_id)
        .then(function({data}) {

          self.ganttWork = data

          // Verificar se tem materiais
          self.addMaterials = data.add_materials_diary
           
        });
    },
    changedWorker(worker){
      let index = 0; let position = -1;
      this.workers.forEach(workersArray => {        
        if(workersArray.id == worker.id){
          position = index
          this.removedWorkers.push(workersArray)
        }  
        index++
        
      });
      if(position != -1)
        this.workers.splice(position, 1)

        // Percorrer o array de removidos
        // Para verificar se podemos retirar algum deste array
        index = 0; position = -1;
        let auxiliarRemoved = [];
        this.removedWorkers.forEach(rWorker => {
          let found = false
          this.fields.workers.forEach(w1 => {
            if(rWorker.id == w1.id)
              found = true
          });

          if(!found){
            this.workers.push(rWorker)
          }else auxiliarRemoved.push(rWorker)
          index++
        });

        this.removedWorkers = auxiliarRemoved
    },
    changeCheckDivider(e){
      if(e=='maquinas')
        if(this.fields.divideAllMachines)
          this.warning.message = this.$t('diaries.machines_auto_divide')
        else 
          this.warning.message = this.$t('diaries.machines_manual_divide')
      else if(e=='maodeobra')
        if(this.fields.divideAllWorkHand)
          this.warning.message = this.$t('diaries.work_hand_auto_divide')
        else
          this.warning.message = this.$t('diaries.work_hand_manual_divide')
      else
        if(this.fields.divideAllMaterials)
          this.warning.message = this.$t('diaries.work_hand_auto_divide')
        else
          this.warning.message = this.$t('diaries.work_hand_manual_divide')

      this.reloadUnidadeObra++

      this.warning.show = true
    },
    dividirHorasModal(type){
      if(type=='maodeobra'){
        this.divideHours.title = this.$t('diaries.divide_hours_title_wh')
        this.divideHours.msg = this.$t('diaries.divide_hours_confirm_wh')
      }else{
        this.divideHours.title = this.$t('diaries.divide_hours_title_machine')
        this.divideHours.msg = this.$t('diaries.divide_hours_confirm_machine')
      }

        this.divideHours.type = type
        this.divideHours.show = true
    },
    dividirHorasTodos(){
      // maquinas
      // maodeobra
      if(this.divideHours.type == 'maodeobra'){
        this.fields.work_units.forEach(element => {
          this.showWorkers(element.id, true)
        })
      }else{
        this.fields.work_units.forEach(element => {
          this.showMachines(element.id, true)
        });
      }

      
      this.successDivided = true
    },
    changedData(){
      this.checkDiaryCreation()
    },
    changedWork(){
      this.readyToUseTemplate();
      this.checkDiaryCreation();
    },
    shakeWorkHandButtonsFunction(){
      this.shakeWorkHandButtons = true
      setTimeout(() => {
        this.shakeWorkHandButtons = false
      }, 3500)
    },
    shakeMachineButtonsFunction() {
      this.shakeMachineButtons = true
      setTimeout(() => {
        this.shakeMachineButtons = false
      }, 3500)
    },
    shakeMaterialButtonsFunction(){
      this.shakeMaterialButtons = true
      setTimeout(() => {
        this.shakeMaterialButtons = false
      }, 3500)
    },
    // Função para compor os números de linha a NULL
    // Vão ficar exatamente como estão no ecrã
    fixWorkUnitLineNumber(){
      let line_number = 0
      this.fields.work_units.forEach(element => {
        element.line_number = line_number
        line_number++
      });
    },
    rejeitarDiaria(reason){
      let data = {
        reason: reason
      }
      Diary.reject(this.$route.params.id, data)
        .then(() => {
          this.success = true
          this.rejectDiary = false
        })
    },
    async exportDiary(type){
      this.exportingDocument = true
      let data = {
        type: type,
        language: this.$store.state.language
      }
      await Export.exportDiary(this.$route.params.id, data)
          .then((resp) => {
            window.open(resp.data.url, '_blank')
            Export.delete(resp.data.path)
              .then(() => {
                this.exportingDocument = false
              });
          });
    },
    async downloadImages(){
      let i = this.$route.params.id
      await Downloads.get(i)
        .then((response) => {
          window.open(response.data.url, '_blank')

        Downloads.delete(response.data.path)
          .then(() => {
            
          });
        });
    },
    changeModalTextArea(t){
      this.inputEditorModalData.object.reason = t
      this.keyReason++
    },
    openEditorModal(item){
      this.inputEditorModalData.itemModel = item.reason
      this.inputEditorModalData.id = item.id
      this.inputEditorModalData.object = item
      this.editorModal=true
    },
    async defineTemplate(){
      this.loading = true
      this.useTemplate = !this.useTemplate
      let parent = this
      let workers_template = null

      if(this.useTemplate == false){
        this.fields.workers = []
        this.fields.machines = []
        this.fields.materials = []
        this.fields.work_units = []
        this.fields.newWorkUnit = null
        this.loading = false
        return;
      }

      await Work.find(this.fields.work_id)
        .then(function({data}) {

          parent.templateField = data.template
           
        });
        
        
      
      // Template Mao Obra
      this.templateField.workers.forEach(a1 => {
        let findObject = null
        parent.workers.forEach(a2 => {
          if(a1.worker_id == a2.id)
            findObject = a2
        });
        
        let found = false
        this.fields.workers.forEach(element => {
          if(element.id == a1.worker_id)
            found = true
        });
        if(found == false){
          parent.fields.workers.push({
            id: a1.worker_id,
            name: a1.worker_object.name,
            started_hour: a1.started_hour,
            started_minute: a1.started_minute,
            finished_hour: a1.finished_hour,
            finished_minute: a1.finished_minute,
            slept_night: a1.slept_night,
            night_work: a1.night_work,
            worked_hours: a1.worked_hours,
            object: findObject
          });

          let index = 0; let position = -1;
          this.workers.forEach(element => {
            if(element.id == a1.worker_id){
              position = index
              this.removedWorkers.push(element)
            }
            
            index++
          });
          if(position != -1)
            this.workers.splice(position, 1)
        }
      });

      // Template Maquina
      this.templateField.machines.forEach(a1 => {
        let findObject = null
        parent.machines.forEach(a2 => {
          if(a1.machine_id == a2.id)
            findObject = a2
        });
        // maquinas ficam com a última hora
        parent.fields.machines.push({
          id: a1.machine_id,
          name: a1.name,
          code: a1.code,
          diesel: '',
          adblue: '',
          started_hours: findObject == null ? 0 : findObject.next_start,
          finished_hours: a1.finished_hours,
          stopped_reason: a1.stopped_reason,
          worked_hours: a1.worked_hours,
          object: findObject
        });
      });

      // Template Materiais
      this.templateField.materials.forEach(a1 => {
        let findObject = null
        parent.materials.forEach(a2 => {
          if(a1.material_id == a2.id)
            findObject = a2
        });
        
        parent.fields.materials.push({
          id: findObject.id,
          name: findObject.name,
          reference: findObject.reference,
          quantity: null,
          changed: true,
          reason: null,
          object: findObject,
          stock: findObject.stock,
          reserved: findObject.reserved
        });
      });
      
      // Template Unidades
      let line_number_work_unit = 0
      this.templateField.units.forEach(a1 => {
        let findObject = null
        parent.units.forEach(a2 => {
          if(a1.unit_id == a2.id)
            findObject = a2
        });
        
        parent.fields.work_units.push({
          id: findObject.id,
          name: findObject.name,
          code: findObject.code,
          measure: a1.measure,
          unit: findObject.unit,
          unit_id: findObject.id,
          line_number: line_number_work_unit
        });
        line_number_work_unit++
      });
      

      
      this.fields.newWorkUnit = workers_template
      
      this.loading = false
    },
    readyToUseTemplate(){
      if(this.fields.work_id==null)
        return false

      let parent = this
      let returner = false
      this.works.forEach(element => {
        if(element.id == parent.fields.work_id)
          if(element.template != null){
            //if(element.template.machines.length > 0 || element.template.units.length > 0 || element.template.workers.length > 0){
              returner = true
              parent.templateField = element.template
            //}
          }
      });
      
      //if(this.fields.date != null){
        //this.checkDiaryCreation()
      //}

      if(this.fields.date == null)
        return false;


      return returner
    },
    async checkDiaryCreation(){
      if(this.fields.date == null)
        return null;
      if(this.fields.work_id == null)
        return null;

      this.loading = true
      let data = {
        data: this.fields.date,
        work: this.fields.work_id
      }
      let parent = this


      await Diary.checkWorkCreationValidation(data)
        .then(function(resp){
         
            if(resp.data.diary != null){
              parent.error.message = parent.$t('diaries.diaries_already_exist')
              parent.error.showExtensive = true
              parent.diaryCreationPossible = false
            }else{
              parent.error.showExtensive = false
              parent.diaryCreationPossible = true
            }
            parent.loading = false
          });  
          
          this.ganttTimeReloader++
    },
    resetFields(){
      this.fields = {
          id: null,
          work_id: null,
          code: null,
          date: null,
          workers: [],
          work_units: [],
          machines: [],
          name: null,
          status:null,
          description:null,
          started_at:null,
          finished_at:null,
          commissioner_id: null,
          overseer_id: null,
          engineer_id: null,
          files: null,
          newWorkUnit: null,
          machinesWorkUnit: null,
          materialsWorkUnit: null
        }
    },
    replicarPD(){
      this.replicar = !this.replicar

      let parent = this
      if(this.replicar == false){
        let work_id = this.fields.work_id;
        let code = this.fields.code
        let date = this.fields.date
        this.resetFields();
        this.fields.work_id = work_id
        this.fields.code = code
        this.fields.date = date
        return
      }

      Diary.getLast(this.fields.work_id)
        .then(function({data}){
          if(data==''){
            parent.resetFields()
            parent.error.message=this.$t('diaries.no_old_records')
            parent.error.show = true
            parent.replicar = false
            return
          }
          

          
          parent.fields.work_id = data.work_id 
          
          
          data.workers.forEach(element => {
            parent.fields.workers.push({
              id: element.id,
              name: element.name,
              started_hour: element.started_hour,
              started_minute: element.started_minute,
              finished_hour: element.finished_hour,
              finished_minute: element.finished_minute,
              slept_night: false,
              night_work: false,
              worked_hours: [],
              object: element
            });            
          });

          data.machines.forEach(element => {
            // Obter últimas horas para cada máquina
            parent.fields.machines.push({
              id: element.id,
              name: element.name,
              code: element.code,
              diesel: '',
              adblue: '',
              started_hours: element.finished_hours,
              finished_hours: 0,
              stopped_reason: "",
              worked_hours: [],
              object: element
            });     
          });

          let line_number_work_unit = 0
          data.work_units.forEach(element => {
            parent.fields.work_units.push({
              id: element.id,
              name: element.name,
              code: element.code,
              measure: 0,
              unit: element.unit,
              object: element,
              unit_id: element.unit_id,
              reason: element.reason,
              line_number: line_number_work_unit
            });           
            line_number_work_unit++
          });
        })
      
    },
    closeWithWork(mo){
      this.showingWorkers = false
      this.fields.newWorkUnit = mo
      this.reloadUnidadeObra++
      //this.fillBaseData()
      //this.generalKey++
    },
    closeWithMachineWork(m){
      this.showingMachines = false
      this.fields.machinesWorkUnit = m
      this.reloadUnidadeObra++
    },
    closeWithMaterialWork(m){
      this.showingMaterials = false
      this.fields.materialsWorkUnit = m
      this.reloadUnidadeObra++
    },
    replaceComma(place){
      place.measure = place.measure.replace(',','.')
      this.reloadUnidadeObra++
    },
    setDiaryCode(id){
      if(this.fields.id != null) {
        return;
      }

      let self = this;
      
      Work.find(id)
        .then(function({data}) {

          let date = dayjs().toDate();

          // Verificar se tem materiais
          self.addMaterials = data.add_materials_diary

          self.ganttWork = data

          self.fields.code = data.code + date.getFullYear() + date.getMonth() + date.getDay() + date.getHours() + date.getMinutes() + date.getSeconds();

          self.blockReplicar = false
        });
    },
    fillRestMachines(machineId) {

      let workUnitIndex = this.getWorkUnitIndex(this.work_unit_id);

      let machineIndex = this.getMachineIndex(machineId);

      let machine = this.fields.machines[machineIndex];

      let workedHours = machine.worked_hours[workUnitIndex];

      for(var i = machineIndex; i < this.fields.machines.length; i++) {
        this.fields.machines[i].worked_hours[workUnitIndex] = workedHours;
      }

      this.renderMachineWorkedHours++;
    },
    fillRestWorkers(workerId) {

      let workUnitIndex = this.getWorkUnitIndex(this.work_unit_id);

      let workerIndex = this.getWorkerIndex(workerId);

      let worker = this.fields.workers[workerIndex];

      let workedHours = worker.worked_hours[workUnitIndex];

      for(var i = workerIndex; i < this.fields.workers.length; i++) {
        this.fields.workers[i].worked_hours[workUnitIndex] = workedHours;
      }

      this.renderWorkedHours++;
    },
    addWorkUnit() {
      let size = this.fields.work_units.length
      this.fields.work_units.push({
        id: 'new_' + new Date().getTime(),
        name: "",
        code: "-",
        measure: null,
        unit: "-",
        reason: '',
        line_number: size
      });
    },
    removeWorkUnit(id) {
      let index = this.getWorkUnitIndex(id);

      this.fields.work_units.splice(index, 1);
    },
    addWorker() {
      this.fields.workers.push({
        id: 'new',
        name: "",
        started_hour: 8,
        started_minute: 0,
        finished_hour: 16,
        finished_minute: 0,
        slept_night: false,
        night_work: false,
        worked_hours: [],
        reason: ''
      });
    },
    removeWorker(id) {
      let index = this.getWorkerIndex(id);

      let auxiliarRemoved = []
      this.removedWorkers.forEach(element => {
        if(element.id != id)
          auxiliarRemoved.push(element)
        else
          this.workers.push(element)
      });

      let i = 0
      let auxiliar_array = this.fields.worker_work_units
      auxiliar_array.forEach(element => {
        if(element.worker_id == id)
          this.fields.worker_work_units.splice(i, 1)

        i++
      });

      this.removedWorkers = auxiliarRemoved

      this.fields.workers.splice(index, 1);
    },
    getWorkerIndex(id) {
      return this.fields.workers.findIndex(item => item.id == id);
    },
    getWorkUnitIndex(id) {
      return this.fields.work_units.findIndex(item => item.id == id);
    },
    getMachineIndex(id) {
      return this.fields.machines.findIndex(item => item.id == id);
    },
    getMaterialIndex(id){
      return this.fields.materials.findIndex(item => item.id == id);
    },
    duplicateWorker(worker){

      let id = dayjs().unix();

      this.fields.workers.push({
        id: id,
        name: "",
        started_hour: worker.started_hour,
        started_minute: worker.started_minute,
        finished_hour: worker.finished_hour,
        finished_minute: worker.finished_minute,
        slept_night: worker.slept_night,
        night_work: worker.night_work,
        worked_hours: []
      });
    },
    addMachine() {
      this.fields.machines.push({
        id: 'new',
        name: "",
        code: "-",
        diesel: null,
        adblue: null,
        started_hours: null,
        finished_hours: null,
        stopped_reason: "",
        worked_hours: []
      });
    },
    addMaterial() {
      this.fields.materials.push({
        id: 'new',
        name: "",
        reference: "-",
        stock: null,
        changed: true,
        reserved: null,
        quantity: null,
        reason: null
      });
    },
    removeMachine(id) {
      let index = this.getMachineIndex(id);

      this.fields.machines.splice(index, 1);
    },
    removeMaterial(id) {
      let index = this.getMaterialIndex(id);

      this.fields.materials.splice(index, 1);
    },
    duplicateMachine(machine){
      this.fields.machines.push({
        id: dayjs().unix(),
        name: "",
        code: "-",
        diesel: machine.diesel,
        adblue: machine.adblue,
        started_hours: machine.started_hours,
        finished_hours: machine.finished_hours,
        stopped_reason: machine.stopped_reason,
        worked_hours: []
      });
    },
    duplicateMaterial(material){
      this.fields.materials.push({
        id: dayjs().unix(),
        name: "",
        reference: "-",
        stock: material.stock,
        reserved: material.reserved,
        changed: true,
        quantity: material.quantity,
        reason: null
      });
    },
    showWorkers(id, auto = false) {
      this.work_unit_id = id;
      this.workers_auto = auto
      if(auto)
        this.showAutoWorkers = false
      else this.showAutoWorkers = true

      this.showingWorkers = true;
      
      this.divideHours.show = false
    },
    showMachines(id, auto = false) {
      this.work_unit_id = id;
      this.machines_auto = auto
      if(auto)
        this.showAutoMachines = false
      else this.showAutoMachines = true

      this.showingMachines = true;

      this.divideHours.show = false
    },
    showMaterials(id, auto = false){
      this.work_unit_id = id;
      this.materials_auto = auto;
      if(auto)
        this.showAutoMaterials = false
      else this.showAutoMaterials = true

      this.showingMaterials = true

      this.divideHours.show = false
    },
    uploadImageSuccess(formData) {
      Diary.createImage(formData, this.fields.id).then(({data}) => {
        this.images.push(data)
      }).catch(err => {

          if(err.response.status == 422) {

            this.error.title = this.$t('globals.error') + " " + err.response.status;

            this.error.message = err.response.data.errors.file[0];
              

            this.error.show = true;  
              return;
          }

        });
    },
    beforeRemove (index) {
      if(this.locked_edition){
        return;
      }

      this.openConfirmationRemoveImage = true
      this.fileIndex = index
    },
    submitRemoveImage() {

      this.openConfirmationRemoveImage = false

      let image = this.images[this.fileIndex].id;

      Diary.deleteImage(image, this.fields.id).then(() => {

        this.images.splice(this.fileIndex, 1);
        this.removeImageDialog++

      }).catch(err => {

          this.error.title = this.$t('globals.error') + " " + err.response.status;

          this.error.message = err.response.data.message;

        });
    },
    onSuccess() {
      if(this.fields.id != null) {
        this.success = false
        if(this.refreshComponent)
          this.$emit('reloadAll')
        return
      }
      

      let domain = '/' + this.$session.get('domain')
      this.$router.push(domain + '/admin/diaries');
    },
    buildImages(data) {
      this.images = []
      if(data.files.length) {
        data.files.forEach(file => {
          this.images.push({
            id:file.id,
            path: file.path,
            default: 0,
            highlight: 0,
          })
        })
      }
    },
    reload(){
      this.reloadUnidadeObra++      
      this.$emit('reload', this.fields)
      
    },
    fillBaseData(){      
      Work.listStatus().then(({data}) => {
        this.status = data;

        this.fields.status = this.fields.status == null ? data.WAITING : this.fields.status;
      });

      Unit.list().then(({data}) => {
        this.units = data;
      });

      Material.list().then(({data}) => {
        this.materials = data
      })

      Worker.list().then(({data}) => {
        this.workers = data;
        // Remover users já usados
        this.fields.workers.forEach(diaryWorker => {
          let index = 0; let position = -1;
          this.workers.forEach(worker => {
            if(diaryWorker.id == worker.id){
              position = index
              this.removedWorkers.push(worker)
            }
            index++
          });
          this.workers.splice(position, 1)
        });
      });

      Machine.list().then(({data}) => {
        this.machines = data;
      });

      Work.list({status: ["STARTED",'SUSPENDED']}).then(({data}) => {
        this.works = data;
  
        if(this.works.length == 1){

          this.fields.work_id = this.works[0].id;

          this.setDiaryCode(this.works[0].id)
        }
      });


      this.fields.date = this.$route.query.date != undefined ? this.$route.query.date : this.fields.date;

      // Só para ajudar a saber se estamos a editar ou a criar uma nova
      if(this.fields.id == null)
        this.newDiary = true
      else
        this.newDiary = false
    },
    formatDate(){

      let date = dayjs();

      if(this.fields.date != null){
        date = dayjs(this.fields.date);
      }

      return date.format('DD/MM/YYYY');
    },
    passDiesel(){
      this.dieselObject.ignoreDiesel = true
      this.dieselObject.emptyDiesel = false
      this.submit()
    },
    /*old_submit(){
      let a = false;
      if(a){
        return
      }
      this.$refs.form.validate().then((result) => {
        this.loading = true;


        
        if(!result){
          this.loading = false;
          return;
        }


        if(this.fields.workers.length == 0){
          this.error.message = this.$t('diaries.one_work_hand')
          this.error.show = true
          this.loading = false;
          return
        }

        
        if(this.fields.work_units.length > 0){  
          if(this.fields.newWorkUnit == null && this.fields.worker_work_units == null){   
            if(!this.fields.divideAllWorkHand){
              this.error.message = this.$t('diaries.empty_work_hand_hours')
              this.error.showExtensive = true
              this.loading = false;

              // Abertura do alerta dentro da modal da mão de obra e abertura da modal
              this.shakeWorkHandButtonsFunction()
              return
            }
          }

          if(this.fields.machines.length > 0){
            if(this.fields.machinesWorkUnit == null && this.fields.machine_work_units == null){
              if(!this.fields.divideAllMachines){
                this.error.message = this.$t('diaries.empty_machine_hours')
                this.error.showExtensive = true
                this.loading = false;

                // Abertura do alerta dentro da modal das maquinas e abertura da modal
                this.shakeMachineButtonsFunction()
                return
              }      
            }
          }  

          if(this.fields.materials.length > 0){
            if(this.fields.materialsWorkUnit == null && this.fields.material_work_units == null){
              if(!this.fields.divideAllMaterials){
                this.error.message = this.$t('diaries.empty_materials')
                this.error.showExtensive = true
                this.loading = false;

                // Abertura do alerta dentro da modal das maquinas e abertura da modal
                this.shakeMaterialButtonsFunction()
                return
              }      
            }
          }  


        }

        // Contar quantas unidades de Obra são novas
        let totalNewWorkUnits = 0;
        this.fields.work_units.forEach(element => {
          if(element.id.includes('new'))
            totalNewWorkUnits++
        });
        

        if(totalNewWorkUnits > 0){
          if(this.fields.divideAllWorkHand == false){
            if(this.fields.newWorkUnit == undefined || this.fields.newWorkUnit == null){
              this.error.message = this.$t('diaries.empty_work_hand_hours')
              this.error.showExtensive = true
              this.loading = false
              return
            }
          }
        }

        if(totalNewWorkUnits > 0){
          if(this.fields.divideAllMachines == false){
            if(this.fields.machinesWorkUnit == undefined || this.fields.machinesWorkUnit == null){
              if(this.fields.machines.length > 0){
                this.error.message = this.$t('diaries.empty_machine_hours')
                this.error.showExtensive = true
                this.loading = false
                return
              }
          } 
        }

          if(this.fields.divideAllMaterials == false){
            if(this.fields.materialsWorkUnit == undefined || this.fields.materialsWorkUnit == null){
              this.error.message = this.$t('diaries.empty_materials')
              this.error.showExtensive = true
              this.loading = false
              return
            } 
          }

          let returner = true
          if(this.fields.divideAllWorkHand == false){
            if(this.fields.newWorkUnit != undefined)
              this.fields.newWorkUnit.forEach(element => {              
                if(element.worked_hours == '')
                  returner = false
              });
          }

          if(this.fields.divideAllMachines == false){
            if(this.fields.machinesWorkUnit != undefined)
              this.fields.machinesWorkUnit.forEach(element => {
                if(element.worked_hours == '')
                  returner = false
              });
          }

          if(this.fields.divideAllMaterials == false){
            if(this.fields.materialsWorkUnit != undefined)
              this.fields.materialsWorkUnit.forEach(element => {
                if(element.quantity == '')
                  returner = false
              });
          }

          if(!returner){
            this.error.message = this.$t('diaries.divide_hours_error')
            this.error.showExtensive = true
            this.loading = false
            return
          }
        }

        let hoursValid = true
        if(this.fields.divideAllWorkHand == false)
          hoursValid = this.areHoursValid();

        if(hoursValid == false) {
          this.loading = false
          return
        }


        let teste = false
        if(teste){
          this.loading = false
          return
        }
        

        // Check machines fields
        let invalid = false
        let dieselIsEmpty = false
        this.fields.machines.forEach(element => {
          if(element.diesel == null || element.diesel === "")
            invalid = true
          if(element.diesel == '' || element.diesel == 0)
            dieselIsEmpty = true
          if(element.started_hours == null)
            invalid = true
          if(element.finished_hours == null)
            invalid = true
        });
        if(invalid){
          this.error.message = this.$t('diaries.empty_machine_fields')
          this.error.showExtensive = true
          this.loading = false

          document.getElementById('machines').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});

          return  
        }

        // Check UO fields
        invalid = false
        this.fields.work_units.forEach(element => {
          if(element.measure == null)
            invalid = true
        });
        
        if(invalid){
          this.error.message =this.$t('diaries.empty_machine_fields_2')
          this.error.showExtensive = true
          this.loading = false
          
          document.getElementById('workHands').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});

          return
        }

        if(dieselIsEmpty){
          if(this.dieselObject.ignoreDiesel == false){
            this.dieselObject.emptyDiesel = true
            return
          }
        }

        this.dieselObject = {
                              ignoreDiesel: false,
                              emptyDiesel: false
                            }

        
        Diary[this.method](this.fields, this.fields.id).then(() => {
          
          if(this.method == 'update')
            this.refreshComponent = true

          this.success = true;


          this.loading = false;
          
        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
            }

            this.error.title = this.$t('globals.error') + " " + err.response.status;

            this.error.message = err.response.data.message;

            this.error.show = true

          });
          
      })
    }, */
    validateSubmissionFields(){
      // **************************************
        // Pelo menos um colaborador é necessário
        // **************************************
        /*if(this.fields.workers.length == 0){
          this.error.message = this.$t('diaries.one_work_hand')
          this.error.show = true
          this.loading = false;
          return false
        }*/

        
        // Se existirem unidades de obra, então:

        // Então tem de existir horas divididas nas unidades de obra, dos colaboradores
        if(this.fields.work_units.length > 0){  
          if(this.fields.worker_work_units == null){   
            if(!this.fields.divideAllWorkHand){
              this.error.message = this.$t('diaries.empty_work_hand_hours')
              this.error.showExtensive = true
              this.loading = false;

              // Abertura do alerta dentro da modal da mão de obra e abertura da modal
              this.shakeWorkHandButtonsFunction()
              return false
            }
          }

          // Se existirem máquinas
          // Então tem de existir horas divididas nas unidades de obra, das máquinas
          if(this.fields.machines.length > 0){
            if(this.fields.machine_work_units == null){
              if(!this.fields.divideAllMachines){
                this.error.message = this.$t('diaries.empty_machine_hours')
                this.error.showExtensive = true
                this.loading = false;

                // Abertura do alerta dentro da modal das maquinas e abertura da modal
                this.shakeMachineButtonsFunction()
                return false
              }      
            }
          }  

          // Se existirem materiais
          // Então tem de existir quantidades dividadas nas unidades de obra, de materiais
          if(this.fields.materials.length > 0){
            if(this.fields.materialsWorkUnit == null && this.fields.material_work_units == null){
              if(!this.fields.divideAllMaterials){
                this.error.message = this.$t('diaries.empty_materials')
                this.error.showExtensive = true
                this.loading = false;

                // Abertura do alerta dentro da modal das maquinas e abertura da modal
                this.shakeMaterialButtonsFunction()
                return false
              }      
            }
          }  


        }

        

        // Validar se todas as horas das unidades de obra são válidas
        // Ou seja, se alguma ultrapassar as horas trabalhadas então terá de haver um erro
        let hoursValid = true
        if(this.fields.divideAllWorkHand == false)
          hoursValid = this.areHoursValid();

        if(hoursValid == false) {
          this.loading = false
          return false
        }

        // Verificar se o somatório das horas das máquinas nas UO não ultrapassa o máximo
        if(this.fields.divideAllMachines == false){
          let machinesValid = this.areMachineHoursValid()
          if(machinesValid == false){
            this.loading = false
            return false
          }
        }

        // Verificar se o somatório das quantidades de Material nas UO não ultrapassa o máximo
        if(this.fields.divideAllMaterials == false){
          let materialsValid = this.areMaterialQuantityValid()
          if(materialsValid == false){
            this.loading = false
            return false
          }
        }

        let allValid = true
        this.fields.work_units.forEach(element => {
          if(this.validWorkUnit(element).result == false)
            allValid = false
        })

        if(allValid == false){
          this.error.message = this.$t('diaries.missing_fields_errors.work_unit_problem')
          this.error.show = true;
          this.loading = false
          return false
        }

        return true;
    },
    async approve(){
      this.loading = true;
      let fieldsValid = this.validateSubmissionFields()

      if(fieldsValid == false){
        this.loading = false;
        return;
      }

      let approvals = [];

      for(let approval in this.fields.approvals){

        let auxApproval = this.fields.approvals[approval];

        if(this.$root.session.hasRole(["super", approval]) && auxApproval.approved_at == null){

          approvals.push(approval);
        }
      }

      await Diary[this.method](this.fields, this.fields.id).then(async () => {
        
          await Diary.approve(this.$route.params.id, approvals[0])
          .then(() => {

            this.checkApproval = false
            this.success = true
            this.$router.go(0)

            this.approved = true;
            this.loading = false;
          });          
        
          
        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
            }

            this.error.title = this.$t('globals.error') + " " + err.response.status;

            this.error.message = err.response.data.message;

            this.error.show = true

          });      
    },
    submit() {
      this.$refs.form.validate().then((result) => {
        this.loading = true;
   
        if(!result){
          this.loading = false;
          return;
        }


        let fieldsValid = this.validateSubmissionFields()

        if(fieldsValid == false){
          this.loading = false;
          return
        }

        let a = false
        if(a){
          this.loading = false
          return
        }

        Diary[this.method](this.fields, this.fields.id).then(() => {
          
          if(this.method == 'update')
            this.refreshComponent = true

          this.success = true;


          this.loading = false;
          
        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
            }

            this.error.title = this.$t('globals.error') + " " + err.response.status;

            this.error.message = err.response.data.message;

            this.error.show = true

          });
          
      })
    },
    fillUnit(item){
      this.action.unit = item;
    },
    filterAutocomplete(item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    },
    submitWorker(){
      this.showingWorkers = false;
    },
    submitMachine(){
      this.showingMachines = false;
    },
  },
};
</script>
<style scoped>

::v-deep .v-item-group {
  padding-right: 1rem;
}
::v-deep .image-container {
  width: 160px;
  height: 140px;
}
::v-deep .image-list-container.display-flex.flex-wrap{
  max-width: 100%;
  margin-bottom: 40px;
}
::v-deep .image-list-container .image-list-item {
  width: 100px;
  height:100px;
}
::v-deep .image-list-container .image-list-item .show-img {
  max-width: 90px;
  max-height:90px;
}
::v-deep .image-icon-delete {
  position: absolute;
  width: 3rem;
  height: 1.5rem;
  fill: red;
  margin-top: -7.5rem;
  margin-left: 1rem;
}
::v-deep .image-icon-edit {
  display: none;
  width: 2rem;
  height: 3rem;
  fill: #673ab7;
  margin-left: 2.5rem;
  margin-top: -1.3rem;
}
::v-deep .add-image-svg {
  width: 17px;
  height: 17px;
  fill: white
}
::v-deep .image-list-item.position-relative.cursor-pointer.display-flex.justify-content-center.align-items-center {
  background: var(--v-primary-base);
  border-radius: 34px;
  width: 3.7rem;
  height: 3.7rem;
  top: 1rem;
  left: 1rem;
  border: none;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)
}
::v-deep .v-item-group{
  padding-right: 0;
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

#global-tab-diary .v-tabs-bar{
        width: 100% !important;
    }

    #global-tab-diary .v-tabs-slider-wrapper{
        margin-bottom: 1vh !important;
    }

@media (min-width: 600px) {
    #global-tab-diary .v-tabs-bar{
        width: 75% !important;
        left: 50%;
        -webkit-transform: translate(-50%, 0%); 
        transform: translate(-50%, 0%);
    }

    #global-tab-diary .v-tabs-slider-wrapper{
        margin-bottom: 1vh !important;
    }
}

#global-tab-diary div.theme--light.v-tabs-items{
    background-color: transparent !important;
}

</style>
