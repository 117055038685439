<template>
    <v-card :class="color" elevation="10" shaped >
        <v-card-text class="text-center">
            <v-progress-linear
                :value="per"
                height="25"
                color="white"
                >
                <strong>{{ Math.ceil(per) }}%</strong>
                </v-progress-linear>
        </v-card-text>
    </v-card>
</template>
<script>
export default{
    props: {
        t: String,
        color: {
            typeof: String,
            default: 'accent'
        },
        per: Number
    },
    data(){
        return{

        }
    }
}
</script>