import BaseApi from "./BaseApi";

export default class ClientUsers extends BaseApi{
    construct() {}

    static async search(params, tenant) {
        return await ClientUsers.getAxiosInstance().get(ClientUsers.getFullApiUrl(tenant),{params: params});
    }

    static async create(params, tenant) {
        let url = ClientUsers.getFullApiUrl(tenant)
        
        return await ClientUsers.getAxiosInstance().post(url,params);
    }

    static async updateActivation(user) {
        let params = {
            name: user.name,
            email: user.email,
            username: user.username,
            phone_number: user.phone_number,
            is_active: user.is_active,
            roles: user.roles.map((item) => {return item.id}),
        };
        let url = ClientUsers.getFullApiUrl() + user.id + '/activate'

        return await ClientUsers.getAxiosInstance().put(url,params);
    }

    static async update(params, tenant) {
        let url = ClientUsers.getFullApiUrl(tenant) + params.id
        
        return await ClientUsers.getAxiosInstance().put(url,params);
    }

    static async find(id, tenant = null) {
        return await ClientUsers.getAxiosInstance().get(ClientUsers.getFullApiUrl(tenant) + id);
    }

    static async list(params) {
        let url = ClientUsers.getFullApiUrl() + "list";

        return ClientUsers.getAxiosInstance().get(url,{params: params});
    }

    static async resendActivationEmail(id) {
        return await ClientUsers.getAxiosInstance().post(ClientUsers.getFullApiUrl() + id + "/email-verification");
    }

    static async delete(params, tenant) {
        let url = ClientUsers.getFullApiUrl(tenant) + params.id
        
        return await ClientUsers.getAxiosInstance().delete(url,params);
    }

    static getFullApiUrl(tenant = null){
        let domain = location.pathname.split('/')[1]
        if(tenant != null)
            domain = tenant;
            
        return BaseApi.getApiUrl() + "api/" + domain + "/admin/clientusers/";
    }
    
    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/clientusers/";
    }

}