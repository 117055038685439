import BaseApi from "./BaseApi";

export default class Company extends BaseApi{
    construct() {}
    
 
    static async search(params) {
        return await Company.getAxiosInstance().get(Company.getFullApiUrl(),{params: params});
    }

    static async create(params) {
        return await Company.getAxiosInstance().post(Company.getFullApiUrl(),params);
    }

    static async find(id) {
        return await Company.getAxiosInstance().get(Company.getFullApiUrl() + id);
    }

    static async update(params, id) {
        return await Company.getAxiosInstance().put(Company.getFullApiUrl() + id,params);
    }

    static async list(params) {
        let url = Company.getFullApiUrl() + "list";

        return Company.getAxiosInstance().get(url,{params: params});
    }
    
    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/company/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/company/";
    }
}