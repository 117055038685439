<template>
    <v-card elevation="10" :style="this.$root.$vuetify.theme.isDark ? '' : (modalType ? '' : 'background-color: rgba(255, 255, 255, 0.75);')" :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
      <v-toolbar :class="$root.$vuetify.theme.isDark ? 'secondary' : 'primary white--text'">
            {{ method == 'create' ? $t('shopping_request.create_new') : $t('shopping_request.edit')}}
            <v-spacer></v-spacer>
            <v-btn
              class="mr-4"
              color="warning"
              outlined
              dark
              @click="externalRequestClick('create')"
              v-show="$root.session.hasRole(['super', 'compras'])"
              v-if="initialStatus == 'Em andamento' && fields.external_request == null"
            >
              {{$t('shopping_request.create_external_req')}}
            </v-btn>
            <v-btn
              class="mr-4"
              color="warning"
              outlined
              dark
              @click="externalRequestClick('update')"
              v-show="$root.session.hasRole(['super', 'compras'])"
              v-else-if="fields.external_request != null"
            >
            {{$t('shopping_request.open_external_req')}}
            </v-btn>
            
            <v-speed-dial
              v-if="method=='update'"
              v-model="speedDial"
              direction="top"
              :open-on-hover="true"
            >
              <template v-slot:activator>
                <v-tooltip right color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" v-on="on"
                      v-model="speedDial"
                      :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary'"
                      dark
                      x-large
                    >
                      <v-icon v-if="speedDial">
                        mdi-close
                      </v-icon>
                      <v-icon v-else>
                        mdi-dots-horizontal
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('globals.options')}}</span>
                </v-tooltip>
              </template>
              <v-tooltip right color="success" v-if="initialStatus == 'Em andamento' && fields.external_request == null && $root.session.hasRole(['super', 'compras'])">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="success"
                    large
                    @click="externalRequestClick('create')"
                  >
                    <v-icon>{{$currency_icon()}}</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('shopping_request.create_external_request')}}</span>
              </v-tooltip> 
              <v-tooltip right color="success" v-else-if="fields.external_request != null && $root.session.hasRole(['super', 'compras'])" >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="success"
                    large
                    @click="externalRequestClick('update')"
                  >
                    <v-icon>{{$currency_icon()}}</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('shopping_request.see_external_request')}}</span>
              </v-tooltip> 
                
                 
            </v-speed-dial>
        </v-toolbar>
        <v-container>
            <validation-observer ref="form" v-slot="{ invalid }">
            <v-form @submit.prevent="submit"  class="mb-16">
                <v-progress-linear v-if="loading"
                    indeterminate
                    class="global-loader"
                ></v-progress-linear>
                <fieldset :disabled="loading">
                    <v-row>
                      <v-col cols="12" md="9" v-if="fields.reference != null">
                        <validation-provider v-slot="{ errors }" vid="descrição" :name="$t('globals.reference')" rules="required">
                            <v-text-field
                              :disabled="true"
                              v-model="fields.reference"
                              prepend-inner-icon="mdi-account-details"
                              :label="$t('globals.reference') + ' *'"
                              clearable
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                      </v-col>
                      <v-col cols="12" md="9" v-else>
                        <validation-provider v-slot="{ errors }" vid="descrição" :name="$t('globals.reference')" rules="required">
                            <v-text-field
                              :disabled="true"
                              :value="$t('shopping_request.reference_msg')"
                              prepend-inner-icon="mdi-account-details"
                              :label="$t('globals.reference') + ' *'"
                              clearable
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                      </v-col>
                      <v-col cols="12" md="3">
                          <validation-provider v-slot="{ errors }" vid="descrição" :name="$t('globals.state')" rules="required">
                            <v-select
                                    :disabled="disableStatus"
                                    v-model="fields.status"
                                    :items="status"
                                    menu-props="auto"
                                    :error-messages="errors"
                                    :label="$t('globals.state') + ' *'"
                                    @change="openStatusChange=true"
                                    prepend-inner-icon="mdi-account"
                                ></v-select>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="12">
                          <validation-provider v-slot="{ errors }" vid="descrição" :name="$t('globals.description')" rules="required">
                              <v-text-field
                                :disabled="lockEdition"
                                v-model="fields.description"
                                prepend-inner-icon="mdi-account-details"
                                :label="$t('globals.description') + ' *'"
                                clearable
                                :error-messages="errors"
                              ></v-text-field>
                            </validation-provider>
                        </v-col>
                      <v-col cols="12" md="9">
                          <validation-provider v-slot="{ errors }" vid="obra" :name="$t('globals.work')" rules="required">
                            <v-autocomplete
                                    :disabled="lockEdition"
                                    v-model="fields.work_id"
                                    :items="works"
                                    menu-props="auto"
                                    :error-messages="errors"
                                    :label="$t('globals.work') + ' *'"
                                    @change="changedWork"
                                    item-value="id"
                                    :item-text="item => item.code + ' - ' + item.name"
                                    prepend-inner-icon="mdi-account"
                                ></v-autocomplete>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-menu
                            v-model="pickerDateChoose"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                              <template v-slot:activator="{ on, attrs }">
                              <validation-provider v-slot="{ errors }" vid="Prazo entrega na obra" :name="$t('shopping_request.time_delivery_to_work')" rules="required">
                                  <v-text-field
                                      v-model="fields.work_delivery_term"
                                      :label="$t('shopping_request.time_delivery_to_work') + ' *'"
                                      :error-messages="errors"
                                      prepend-icon="mdi-calendar"
                                      :disabled="lockEdition"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                  ></v-text-field>
                              </validation-provider>
                              </template>
                              <v-date-picker
                                :allowed-dates="(date) => date >= new Date(new Date()+1).toISOString().substr(0, 10)"
                                v-model="fields.work_delivery_term"
                                @input="pickerDateChoose = false"
                              ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" md="9">
                          <validation-provider v-slot="{ errors }" vid="supplier" :name="$t('globals.supplier')" rules="|">
                              <v-select
                                    v-model="fields.supplier_id"
                                    :disabled="lockEdition"
                                    :items="suppliers"
                                    item-value="id"
                                    :item-text="item => item.name"
                                    menu-props="auto"
                                    :error-messages="errors"
                                    @change="changedAddress"
                                    clearable
                                    :label="$t('globals.supplier')"
                                    prepend-inner-icon="mdi-account"
                                ></v-select>
                            </validation-provider>
                        </v-col>     
                        <v-col cols="12" md="12" v-if="false">
                          <v-menu
                            v-model="pickerSupplierDeliveryTerm"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                              <template v-slot:activator="{ on, attrs }">
                              <validation-provider v-slot="{ errors }" vid="Data" :name="$t('shopping_request.supplier_delivery')" rules="|">
                                  <v-text-field
                                      v-model="fields.supplier_delivery_term"
                                      :label="$t('shopping_request.supplier_delivery')"
                                      :error-messages="errors"
                                      :disabled="lockEdition"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                  ></v-text-field>
                              </validation-provider>
                              </template>
                              <v-date-picker
                                :allowed-dates="(date) => date >= new Date(new Date()+1).toISOString().substr(0, 10)"
                                v-model="fields.supplier_delivery_term"
                                @input="pickerSupplierDeliveryTerm = false"
                              ></v-date-picker>
                          </v-menu>
                        </v-col>       
                        <v-col cols="12" md="12">
                          <v-expansion-panels focusable>
                            <v-expansion-panel class="mt-4">
                              <v-expansion-panel-header  v-slot="{ open }"  :class="($root.$vuetify.theme.isDark ? 'secondary' : 'accent') + ' white--text'"> 
                                <v-row v-if="open">
                                  <v-col cols="12">
                                    <strong>Morada</strong>
                                  </v-col>
                                </v-row> 
                                <v-row v-if="!open">
                                  <v-col cols="12" md="auto">
                                    <strong>Morada:</strong>
                                  </v-col>
                                  <v-col cols="12" md="auto" v-if="fields.address != null">
                                    {{ fields.address }}
                                  </v-col>
                                  <v-col cols="12" md="auto" v-if="fields.postal_code1 != null">
                                    {{ fields.postal_code1 }} - {{ fields.postal_code2 }}
                                  </v-col>
                                  <v-col cols="12" md="auto" v-if="fields.city != null">
                                    {{ fields.city }}
                                  </v-col>
                                  <v-col cols="12" md="auto" v-if="fields.country != null">
                                    {{ fields.country }}
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content :class="($root.$vuetify.theme.isDark ? 'secondary' : '')">
                                <v-row>
                                  <v-col cols="12">
                                    <v-select
                                      :items="addressOptions"
                                      menu-props="auto"
                                      :disabled="lockEdition && !$root.session.hasRole(['super','compras'])"
                                      v-model="addressChooser"
                                      :label="$t('shopping_request.fill_address')"
                                      @change="changedAddress()"
                                      prepend-inner-icon="mdi-account"
                                  ></v-select>
                                  </v-col>                                  
                                  <v-col cols="12" md="12">
                                    <validation-provider v-slot="{ errors }" vid="morada" :name="$t('globals.address.address')" rules="required">
                                        <v-textarea
                                          v-model="fields.address"
                                          :disabled="lockEdition && !$root.session.hasRole(['super','compras'])"
                                          prepend-inner-icon="mdi-account-details"
                                          :label="$t('globals.address.address') + ' *'"
                                          clearable
                                          :error-messages="errors"
                                        ></v-textarea>
                                      </validation-provider>
                                  </v-col> 
                                  <v-col cols="12" md="auto">
                                        {{$t('globals.address.postal_code')}}
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-otp-input
                                            plain
                                            v-model="fields.postal_code1"
                                            :disabled="lockEdition && !$root.session.hasRole(['super','compras'])"
                                            :length="4"
                                        ></v-otp-input>
                                    </v-col>
                                    <v-col cols="12" md="auto">
                                        <h1>-</h1>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-otp-input
                                            plain
                                            v-model="fields.postal_code2"
                                            :disabled="lockEdition && !$root.session.hasRole(['super','compras'])"
                                            :length="3"
                                        ></v-otp-input>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            :label="$t('globals.address.city')"
                                            color="accent"
                                            v-model="fields.city"
                                            :disabled="lockEdition && !$root.session.hasRole(['super','compras'])"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            :label="$t('globals.address.country')"
                                            color="accent"
                                            v-model="fields.country"
                                            :disabled="lockEdition && !$root.session.hasRole(['super','compras'])"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>    
                        <v-col cols="12" md="12" v-if="addFaturacao">
                          <v-expansion-panels focusable>
                            <v-expansion-panel class="mt-4">
                              <v-expansion-panel-header  v-slot="{ open }" class="accent white--text"> 
                                <v-row v-if="open">
                                  <v-col cols="12">
                                    <strong>{{$t('shopping_request.invoiced')}}</strong>
                                  </v-col>
                                </v-row> 
                                <v-row v-if="!open">
                                  <v-col cols="12" md="auto">
                                    <strong>{{$t('shopping_request.invoiced')}}:</strong>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>    
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                          <v-dialog
                            v-model="addFaturacaoModal"
                            persistent
                            width="500"
                          >
                            <v-card>
                              <v-card-title class="text-h5 primary white--text">
                                {{$t('shopping_request.invoiced')}}
                              </v-card-title>

                              <v-card-text>
                                {{$t('shopping_request.external_request_exists')}}
                              </v-card-text>
                              <v-card-text>
                                <v-row>
                                  <v-col cols="12">                                    
                                    <v-menu
                                      v-model="pickerShoppingDate"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <validation-provider v-slot="{ errors }" vid="Data de compra" :name="$t('shopping_request.shopping_date')" rules="required">
                                            <v-text-field
                                                v-model="fields.shopping_date"
                                                :label="$t('shopping_request.shopping_date')"
                                                :error-messages="errors"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </validation-provider>
                                        </template>
                                        <v-date-picker
                                          :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                                          v-model="fields.shopping_date"
                                          @input="pickerShoppingDate = false"
                                        ></v-date-picker>
                                    </v-menu>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-file-input
                                        accept=".pdf"
                                        :label="$t('invoices.attach')"
                                        v-model="fields.shopping_invoice"
                                    ></v-file-input>
                                  </v-col>
                                </v-row>
                              </v-card-text>

                              <v-divider></v-divider>

                              <v-card-actions>
                                <v-btn
                                  color="error"
                                  text
                                  @click="status = ['Em andamento', 'Em armazém', 'Concluído'];fields.status = 'Em andamento'; addFaturacaoModal = false;"
                                >
                                  {{$t('globals.close')}}
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="primary"
                                  :disabled="fields.shopping_date == null"
                                  @click="submit()"
                                >
                                {{$t('globals.submit')}}
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-col>                  
                    </v-row>
                    <v-row>
                  <v-col cols="9" xs="9" sm="9" md="12">
                    <!-- DESKTOP VERSION -->
                    <v-simple-table dense class="d-none d-lg-block" :key="reloadTable">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center">
                              {{$t('globals.reference')}} {{$t('globals.material')}}
                            </th>
                            <th class="text-center" v-if="method != 'create'">
                              {{$t('globals.material')}}
                            </th>
                            <th class="text-center">
                              {{$t('globals.description')}}
                            </th>
                            <th class="text-center">
                              {{$t('globals.quantity')}}
                            </th>
                            <th class="text-center" v-if="method != 'create'">
                              {{$t('globals.stock')}}/{{$t('globals.reserve')}}
                            </th>
                            <th class="text-center">
                              {{$t('globals.unit')}}
                            </th>
                            <th class="text-center">
                              {{$t('globals.options')}}
                            </th>
                          </tr>
                        </thead>
                        <tbody :class="$root.$vuetify.theme.isDark ?  'secondary' : ''">
                          <tr v-for="(element, index) in fields.materials" v-bind:key="index + 10000"  :class="canReserv(element)">
                            <td class="text-center">
                                <label>{{element.material_ref}}</label>
                            </td>
                            <td class="text-center" v-if="method != 'create'">
                                <label>{{element.material == null ? '-' : element.material}}</label>
                            </td>
                            <td class="text-center">
                                <label>{{element.description}}</label>
                            </td>
                            <td class="text-center">
                                <label>{{element.qty}}</label>
                            </td>
                            <td class="text-center" v-if="method != 'create'">
                                <label>{{element.stock}}/{{ element.reserved }}</label>
                            </td>
                            <td class="text-center">
                                <label>{{element.unit }}</label>
                            </td>
                            <td class="text-center">
                              <v-row>
                                <v-col cols="12" md="3" v-if="fields.status == 'Em andamento' || fields.status == 'Pendente' || fields.external_request == null">                                
                                  <IconView
                                    v-show="materialActions"
                                    class="mr-2"
                                    @click="editLine(element)"                                      
                                  />
                                </v-col>
                                <v-col cols="12" md="3">    
                                  <IconRemove
                                      v-if="fields.status == 'Em andamento' || fields.status == 'Pendente' || fields.external_request == null"
                                      v-show="materialActions"
                                      @on-submit="deleteLine(element)"
                                    />
                                </v-col>
                              </v-row>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <!-- DESKTOP VERSION -->
                    <v-dialog
                      
                      v-model="addMaterial"
                      max-width="800px"
                      >
                      <template v-slot:activator="{ props }">
                          <div class="text-right mt-4">
                          <Tooltip
                              color="warning"
                              :text="$t('shoppings.add_material_invoice')"
                              bottom
                          >
                              <v-btn
                                  class="mx-2"
                                  fab
                                  dark
                                  large
                                  :disabled="lockEdition"
                                  color="warning"
                                  @click="addMaterial = true;"
                                  v-bind="props"
                                  >
                                  <v-icon dark>
                                      mdi-plus
                                  </v-icon>
                              </v-btn>
                          </Tooltip>
                          </div>
                      </template>

                      <v-card>
                        <v-card-title class="primary white--text">{{$t('shopping_request.material_definitions')}}</v-card-title>
                          <v-card-text class="mt-2">
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                    v-model="newMaterial.material_ref"
                                    prepend-inner-icon="mdi-account-details"
                                    :label="$t('shopping_request.material_reference') + ' *'"
                                    clearable
                                ></v-text-field>
                              </v-col>   
                              <v-col cols="12" v-if="method!='create'">
                                <v-autocomplete
                                  v-model="newMaterial.material_id"
                                  :items="materials"
                                  item-value="id"
                                  :item-text="item => item.reference + ' - ' + item.name"
                                  menu-props="auto"
                                  :label="$t('globals.material')"
                                  prepend-inner-icon="mdi-account"
                              ></v-autocomplete>
                              </v-col>                                 
                              <v-col cols="12" md="12">
                                <v-text-field
                                    v-model="newMaterial.description"
                                    prepend-inner-icon="mdi-account-details"
                                    :label="$t('globals.description') + ' *'"
                                    clearable
                                ></v-text-field>
                              </v-col>                                    
                              <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="newMaterial.qty"
                                    prepend-inner-icon="mdi-account-details"
                                    type="number"
                                    :label="$t('globals.quantity') + ' *'"
                                    clearable
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-select
                                    v-model="newMaterial.unit_management_id"
                                    :items="units"
                                    item-value="id"
                                    :item-text="item => item.abbreviation"
                                    menu-props="auto"
                                    :label="$t('globals.unit') + ' *'"
                                    prepend-inner-icon="mdi-account"
                                ></v-select>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions class="justify-center">
                          <v-btn :disabled="newMaterial.material_ref == null || newMaterial.description==null || newMaterial.qty==null || newMaterial.unit_management_id==null" color="success" @click="insertMaterial()">{{$t('globals.insert')}}</v-btn>
                          <v-btn color="error" right @click="addMaterial = false; editMode = false;">{{$t('globals.close')}}</v-btn>
                          </v-card-actions>
                      </v-card>
                  </v-dialog>
                  </v-col>
                </v-row>
                
                <v-row class="mt-6" align="center" justify="space-around">
                    <v-btn :disabled="invalid" depressed :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary'" type="submit" v-if="fields.status != 'Concluído'">
                    {{$t('globals.save')}}
                    </v-btn>
                </v-row>
                </fieldset>
            </v-form>
            <DialogSuccess :opened="success" @on-ok="onSuccess" >
                {{$t('shopping_request.success')}}
            </DialogSuccess>
            </validation-observer>
        </v-container>
        <Comments v-if="method!='create'" :place="this.$route.name"/>
        <ErrorSnackbar v-model="error.show" :message="error.message"/>
        <DialogConfirmation 
            :title="$t('shopping_request.change_state')" 
            type="warning" 
            :opened="openStatusChange" 
            @on-submit="changeStatusConfirmation"
            @on-cancel="openStatusChange = false; fields.status = initialStatus;">
              {{$t('shopping_request.change_status_to')}} <strong>"{{ initialStatus }}"</strong> {{$t('shopping_request.to')}} <strong>"{{ fields.status }}"</strong>
          </DialogConfirmation>
    </v-card>
  </template>
  <script>
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  import Materials from '@/api/Materials.js'
  import Configurations from '@/api/Configurations.js'
  import Work from '@/api/Work.js'
  import ShoppingRequestAPI from '@/api/ShoppingRequest.js'
  import UnitsManagement from '@/api/UnitsManagement.js'
  import Tooltip from '@/components/ui/Tooltip.vue';
  import IconRemove from '@/components/ui/IconRemove.vue';
  import Company from '@/api/Company.js'
  import Comments from '@/components/ui/Comments.vue'
  import IconView from '@/components/ui/IconView.vue';
  import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
  import DialogConfirmation from '@/components/ui/DialogConfirmation.vue';

  export default {
    components: {
      DialogConfirmation,
      DialogSuccess,
      Tooltip,
      IconRemove,
      Comments,
      IconView,
      ErrorSnackbar
    },
    props: {
      shopping_request: Object,
     method: String,
     disabled: Boolean,
     modalType: {
      typeof: Boolean,
      default: false
     }
   },
   watch: { 
    'fields.status':{
      immediate: true,
      handler(val){
        // Se for em modo criação, pode criar à vontade
        if(this.method == 'create'){
          this.lockEdition = false
          return
        }

        // Se for para atualizar e, se o role não for super ou compras, então não pode atualizar
        if(this.method == 'update'){
          if(!this.$root.session.hasRole['super', 'compras'])
            this.lockEdition = true
        }

        // Se já tiver ultrapasado os estados Em Andamento e Pendente, não há edições para ninguém
        if(val != 'Em andamento' || val != 'Pendente')
          this.lockEdition = true

        
      }
    },
    shopping_request: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;
          
          this.fields.id = data.id
          this.fields.work_delivery_term = data.work_delivery_term
          this.fields.supplier_id = data.supplier_id
          this.fields.supplier_delivery_term = data.supplier_delivery_term
          this.fields.work_id = data.work_id
          this.fields.status = data.status
          this.initialStatus = data.status
          this.fields.materials = data.materials

          
          if(data.postal_code != ''){
            if(data.postal_code != null){
              this.fields.postal_code1 = data.postal_code.split('-')[0]
              
              this.fields.postal_code2 = data.postal_code.split('-')[1]
            }
          }
        }
      },
    },
    beforeCreate(){
    },
    data: () => ({
      openStatusChange:false, 
      materialActions: false,
      speedDial: false,
      lockEdition : false,
      initialStatus: '',
      addFaturacaoModal: false,
      addFaturacao: false,
      addressChooser: '',
      reloadTable: 0,
      addMaterial: false,
      materials: [],
      editMode: false,
      newMaterial:{
        id: null,
        material_ref: null,
        qty: null,
        description: null,
        unit_management_id: null,
        material_id: null
      },
      pickerSupplierDeliveryTerm: false,
      pickerShoppingDate: false,
      pickerDateChoose: false, 
      fields: {
        external_request: null,
        description: null,
        work_delivery_term: null,
        supplier_id: null,
        supplier_delivery_term: null,
        work_id: null,
        status: null,
        materials: [],
        shopping_date: null,
        address: '',
        postal_code: null,
        postal_code1: null,
        postal_code2: null,
        city: null,
        country: null,
      },
      loading:false,
      error: {
        title: '',
        message: '',
        show: false
      },
      success: false,
      suppliers:[],
      units: [],
      status: ['Pendente', 'Em andamento', 'Em armazém', 'Em processamento', 'Em trânsito', 'Concluído'],
      works: [],
      configurations: null
    }),
    mounted(){
      this.fillBaseData();

      if(this.method == 'create'){
        this.fields.status = this.status[0]
      }
      this.statusOptions()
      this.activeMaterialActions()
    },
    methods:{
      changedWork(){
        this.works.forEach(element => {
          if(element.id == this.fields.work_id){
            if(element.address != null)
              this.addressOptions.push(this.$t('shopping_request.work_address'))
          
          }
        });
      },
      externalRequestClick(m){
        if(this.fields.address == null){
          this.error.message = this.$t('shopping_request.please_fill_address')
          this.error.show = true
          return
        }
        let domain = '/' + this.$session.get('domain') + '/admin/'

        if(m == 'create'){
          this.$router.push(domain + 'external-request/create/' + this.$route.params.id)
          return
        }

        this.$router.push(domain + 'external-request/' + this.fields.external_request.id)
      },
      activeMaterialActions(){
        if(this.$root.session.hasRole(['super', 'compras']))
          this.materialActions = true
      },
      async changedAddress(){
        if(this.addressChooser == this.$t('shopping_request.headquarters')){
          
          this.fields.address = this.configurations.data.address
            if(this.configurations.data.postal_code != '' && this.configurations.data.postal_code != null){
              this.fields.postal_code1 = this.configurations.data.postal_code.split('-')[0]
              this.fields.postal_code2 = this.configurations.data.postal_code.split('-')[1]
            }
            this.fields.country = this.configurations.data.country
            this.fields.city = this.configurations.data.city
          
        }
        if(this.addressChooser == this.$t('shopping_request.work_address')){
          this.works.forEach(element => {
            if(element.id == this.fields.work_id){
              this.fields.address = element.address

              if(element.postal_code != '' && element.postal_code != null){
                this.fields.postal_code1 = element.postal_code.split('-')[0]
                this.fields.postal_code2 = element.postal_code.split('-')[1]
              }
              this.fields.country = element.country
              this.fields.city = element.city
            }
          });
        }
        
      },
      changeStatusConfirmation(){
        if(this.method == 'update'){
          let problem = false
          this.fields.materials.forEach(element => {
            if(element.material_id == null)
              problem = true
          });

          if(problem == true){
            this.error.message = this.$t('shopping_request.material_validation')
            this.error.show = true
            return
          }

          
          if(this.fields.status != 'Em andamento'){
            // Validar se há requisição externa
            problem = false
            this.fields.materials.forEach(element => {
              let disponivel = element.stock - element.reserved
              if(disponivel - element.qty < 0){
                if(this.fields.external_request == null){
                  problem = true
                }
              }
            });
            if(problem){              
              this.error.message = this.$t('shopping_request.no_material_request')
              this.error.show = true
              this.status = ['Em andamento', 'Em armazém', 'Concluído']
              this.fields.status = 'Em andamento'
              return     
            }
          }
        
        }

        if(this.fields.status == 'Em armazém'){
          
          this.openStatusChange = false
          this.addFaturacao = true
          this.addFaturacaoModal = true
        }else this.submit()
      },
      statusOptions(){      
        switch(this.fields.status){
          case 'Pendente':
            this.status = ['Pendente', 'Em andamento', 'Em armazém']
            break;
          case 'Em andamento':
            this.status = ['Em andamento', 'Em armazém', 'Concluído']
            break;
          case 'Em armazém':
            /*if(this.fields.external_request != null){
              if(this.fields.external_request.stock_updated == false){
                this.addFaturacao = true
                this.addFaturacaoModal = true
              }
            }*/
            
            this.status = ['Em armazém', 'Em processamento', 'Concluído']
            break;
          case 'Em processamento':
            this.status = ['Em processamento', 'Em trânsito']
            break;
          case 'Em trânsito':
            this.status = ['Em trânsito', 'Concluído']
            break;
          case 'Concluído':
            this.status = ['Concluído']
            break
          default:
            this.status = ['Pendente']
        }
      },
      canReserv(element){
        if(element.material_id == null)
          return ''
        let disponivel = element.stock - element.reserved

        if(disponivel - element.qty < 0){
          
          return 'red-line'
        }
        

        return ''
      },
      editLine(item){
        this.newMaterial = item
        this.editMode = true
        this.addMaterial = true
      },
      deleteLine(item){
        let i = 0
        let pos = 0
        this.fields.materials.forEach(element => {
          if(element.id == item.id){
            pos = i
          }
          i++
        });
        this.fields.materials.splice(pos, 1)
      },
      insertMaterial(){
        let unit = ''
        let material = ''
        let stock = 0
        let reserved = 0

        this.units.forEach(element => {
          if(element.id == this.newMaterial.unit_management_id)
            unit = element.abbreviation
        });

        this.materials.forEach(element => {
          if(element.id == this.newMaterial.material_id){
            material = element.description
            stock = element.stock
            reserved = element.reserved
          }
        });



        let material_object = {
            id: this.newMaterial.id == undefined ? 'new_' + this.fields.materials.length : this.newMaterial.id,
            material_ref: this.newMaterial.material_ref,
            qty: this.newMaterial.qty,
            description: this.newMaterial.description,
            unit_management_id: this.newMaterial.unit_management_id,
            unit: unit,
            material_id: this.newMaterial.material_id,
            material: material
        }

        if(this.editMode == false){

          this.fields.materials.push(material_object)

          this.newMaterial.id = null
          this.newMaterial.material_ref = null
          this.newMaterial.qty = null
          this.newMaterial.description = null
          this.newMaterial.unit_management_id = null
        }

        if(this.editMode){
          this.fields.materials.forEach(element => {
            if(element.id == this.newMaterial.id){
              element.material = material
              element.material_id = this.newMaterial.material_id
              element.stock = stock
              element.reserved = reserved
            }
          });

          this.editMode = false
          this.addMaterial = false
        }
      },
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        let domain = '/' + this.$session.get('domain')
        this.$router.push(domain + '/admin/shopping-request');
      },
      fillBaseData(){       
        Work.list().then(({data}) => {
          this.works = data
          this.changedWork()
        }) 
        Company.list().then(({data}) => {
            this.suppliers = data
        });
        UnitsManagement.list().then(({data}) => {
            this.units = data
        });
        Materials.list().then(({data}) => {
          this.materials = data
        })
        Configurations.get().then(({data}) => {
          this.configurations = data

          if(this.configurations.data.address==null){
            let i = 0
            let pos = 0
            this.addressOptions.forEach(element => {
              if(element == this.$t('shopping_request.headquarters'))
                pos = i

              i++
            });
            this.addressOptions.splice(pos, 1)
          }
        })
      },
      submit() {

      

        if(this.method == 'update' && this.fields.status == 'Em andamento'){
          let problem = false
          this.fields.materials.forEach(element => {
            if(element.material_id == null)
              problem = true
          });

          if(problem == true){
            this.error.message = this.$t('shopping_request.material_validation')
            this.error.show = true
            return
          }
        }

        
        if(this.fields.status == 'Em armazém'){
          // Validar se há requisição externa
          var problem = false
          this.fields.materials.forEach(element => {
            let disponivel = element.stock - element.reserved
            if(disponivel - element.qty < 0){
              if(this.fields.external_request == null){
                problem = true
              }
            }
          });
          if(problem){              
            this.error.message = this.$t('shopping_request.no_material_request')
            this.error.show = true
            this.status = ['Em andamento', 'Em armazém', 'Concluído']
            this.fields.status = 'Em andamento'
            return     
          }
        }

  
        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }
          
          if(this.fields.postal_code1 != null)
            this.fields.postal_code = this.fields.postal_code1 + '-' + this.fields.postal_code2
 
  
          let data = {...this.fields};

          const formData = new FormData()
          formData.append('description',              data.description)
          formData.append('work_delivery_term',       data.work_delivery_term)
          formData.append('supplier_id',              data.supplier_id)
          formData.append('supplier_delivery_term',   data.supplier_delivery_term)
          formData.append('work_id',                  data.work_id)
          formData.append('status',                   data.status)
          formData.append('materials', JSON.stringify(data.materials))
          formData.append('address',                  data.address == null ? '' : data.address)
          formData.append('postal_code',              data.postal_code == null ? '' : data.postal_code)
          formData.append('city',                     data.city == null ? '' : data.city)
          formData.append('country',                  data.country == null ? '' : data.country)
          formData.append('shopping_date',            data.shopping_date)
          if(data.shopping_invoice != null)
            formData.append('shopping_invoice',         data.shopping_invoice)


          ShoppingRequestAPI[this.method](formData, this.fields.id).then(() => {
  
            this.success = true;
  
            this.loading = false;

            this.openStatusChange = false
            
            this.addFaturacaoModal = false

            //this.canDoExternalRequest()


            this.reloadTable++

            this.$emit('reload')
            
          }).catch(err => {
            this.loading = false;

            console.log(err)
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = this.$t('globals.error') + " " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })
      }
    },
    computed: {
      addressOptions(){
        return [this.$t('shopping_request.headquarters')]
      },
      disableStatus(){
        if(this.method == 'create')
          return true

        if(this.fields.status == 'Concluído')
          return true

        if(this.method == 'update'){
          if(!this.$root.session.hasRole(['super', 'compras']))
            return true
        }
        return false
      }
    }
  };
  </script>
<style>
.red-line{
  background-color: #F9A825;
}

.red-line:hover{
  background-color: red
}
</style>