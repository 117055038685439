<template>
    <section class="mt-16">
      <v-breadcrumbs
          v-if="this.$root.$vuetify.theme.isDark"
          large
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
        <v-breadcrumbs
          v-else
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <rp-icon icon="rp-barcode-blue-filled" class="mr-2"/> <span>{{$t('menu.shopping')}}</span>
        </h1>
  
        <v-row>
          <v-col cols="12" md="auto">
            <rp-icon
              icon="rp-eye-blue-filled"
              class="mb-n2"
            />
            <v-icon class="ml-2" color="secondary" small>mdi-forward</v-icon>
            <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.visualize')}}</span>
          </v-col>
          <v-col cols="12" md="auto">
            <v-icon color="red" class="ml-4">
              mdi-delete
            </v-icon><v-icon class="ml-2" color="secondary" small>mdi-forward</v-icon>
            <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.remove')}}</span>
          </v-col>
        </v-row>
      
        <div style="position:relative">
          <v-switch
              v-if="this.$root.$vuetify.theme.isDark"
              style="position:absolute"
              v-model="filter.deleted_company"
              color="success"
              light
              @change="searchCompanies"
              :label="$t('shoppings.show_deleted')"
            ></v-switch>
            <v-switch
              v-else
              style="position:absolute"
              v-model="filter.deleted_company"
              color="success"
              @change="searchCompanies"
              :label="$t('shoppings.show_deleted')"
            ></v-switch>
          
          <v-container>
            <v-speed-dial
              absolute
              v-model="speedDial"
              top
              right
              direction="bottom"
              :open-on-hover="true"
            >
              <template v-slot:activator>
                <v-tooltip right color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" v-on="on"
                      v-model="speedDial"
                      color="primary"
                      dark
                      x-large
                      fab
                    >
                      <v-icon v-if="speedDial">
                        mdi-close
                      </v-icon>
                      <v-icon v-else>
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('globals.options')}}</span>
                </v-tooltip>
              </template>
                           
              <v-tooltip right color="info">
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="info"
                    large
                    @click="searching = true"
                >
                    <v-icon>mdi-filter</v-icon>
                </v-btn>
                </template>
                <span>{{$t('globals.search')}}</span>
            </v-tooltip>
            <v-tooltip right color="secondary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" v-on="on"
                      fab
                      dark
                      small
                      color="secondary"
                      large
                      :to="'/' + $session.get('domain') + '/admin/shopping/create'"
                      v-if="$root.session.hasPermission(['super', 'shopping.create'])"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('globals.register')}}</span>
                </v-tooltip>
            </v-speed-dial>
          </v-container>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
            locale="pt-pt"
          >

          <template v-slot:item.invoices="{ item }">
            {{ item.invoices_total == 0 ? '0 ' + $store.state.coin : $currency(item.invoices_total) }}
          </template>
  
          <template v-slot:item.actions="{ item }">
            <v-row>
              <v-col cols="12" md="3">
                <IconView
                    @click="$router.push('/' + $session.get('domain') + '/admin/shopping/supplier/' + item.id)"
                  />
              </v-col>
            </v-row>
          </template>
  
            
          </v-data-table>
          
        </div>
        <DialogSuccess :opened="approved" @on-ok="approved = false" >
          {{$t('shoppings.company_update')}}
        </DialogSuccess>
        <v-dialog
          v-model="addCompany"
          max-width="800px"
        >
          <AddCompany 
            v-if="addCompany"
            :editCompany="company_selected"
            @close="addCompany = false"
            @close-update="fillBaseData()"
          />
        </v-dialog>
        
        <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="filter.name = null; filter.nif = null; filter.email = null; searchCompanies();">
              {{$t('globals.clean_fields')}}
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchCompanies">
            
            <v-list-item>
                <v-text-field
                    v-model="filter.name"
                    clearable
                    prepend-inner-icon="mdi-text-box"
                    :label="$t('globals.supplier')"
                ></v-text-field>
            </v-list-item>   
            
            <v-list-item>
                <v-text-field
                    v-model="filter.nif"
                    clearable
                    prepend-inner-icon="mdi-barcode"
                    :label="$t('globals.nif')"
                ></v-text-field>
            </v-list-item>   

            <v-list-item>
                <v-text-field
                    v-model="filter.email"
                    clearable
                    prepend-inner-icon="mdi-email-box"
                    :label="$t('globals.email')"
                ></v-text-field>
            </v-list-item>   

            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              {{$t('globals.search')}}
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
      </v-navigation-drawer>
    </section>
  </template>
  <script>
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  import dayjs from 'dayjs';
  import AddCompany from '@/components/companies/Add.vue';
  import Company from "@/api/Company.js";
  import IconView from '@/components/ui/IconView.vue';
  
  export default {
    components: {
      AddCompany,
      DialogSuccess,
      IconView
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchCompanies()}, 10);
        },
        deep: true,
      },
    },
    data: () => ({
      company_selected: null,
      items: [],
      total: 0,
      loading: false,
      searching: false,
      options: {},
      speedDial:false,
      filter: {
        name:null,
        email: null,
        nif: null,
        deleted_company: false
      },
      status: [],
      approved:false,
      addCompany: false
    }),
    mounted(){
  
      /*if(!this.$root.session.hasPermission(["super", "diaries.read"])) {
        this.$router.push('/admin');
      }*/
  
      //this.searchCompanies();
    },
    methods:{
      openModalNewCompany(){
        this.company_selected = null
        this.addCompany = true
      },
      getTableDate(date) {
        return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
      },
      fillBaseData() {
        this.addCompany = false
        if(localStorage["shoppings-companies-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["shoppings-companies-" + this.$root.session.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
      },
      searchCompanies(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
        
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };
        
      
        Object.assign(request, filter);
  
        localStorage["shoppings-companies-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        Company.search(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });
  
          this.loading = false;
  
        });
      },
      resetCompanies()
      {
        this.searchCompanies();
      },
      deleteCompany(item){
        if(this.filter.deleted_company)
          item.deleted_company = false;
        else
          item.deleted_company = true
  
        Company.update(item, item.id)
          .then(() => {
            this.searchCompanies();
          });
      },
      viewCompany(item){
        this.company_selected = item
        this.addCompany = true
      }
    },
    computed: {
      headers(){
        let he = [
          {
            text: this.$t('globals.name'),
            sortable: true,
            value: 'name',
          },
          {
            text: this.$t('globals.nif'),
            sortable: true,
            value: 'nif',
          },
          {
            text: this.$t('globals.email'),
            sortable: true,
            value: 'email',
          },
          {
            text: this.$t('globals.contact'),
            sortable: true,
            value: 'contact',
          },
          {
            text: this.$t('shoppings.total_invoiced'),
            sortable: true,
            value: 'invoices',
          },
          {
            text: this.$t('globals.options'),
            sortable: false,
            value: 'actions',
          }
        ]
        return he
      },
      tableFooter(){
        let t = this.$t("globals.lines_datatable")
        let a = [5, 10, 15, 20];
        return {
          'items-per-page-options': a,
          'items-per-page-text': t
        }
      }, 
      crumbs: function() {
        return [
          {
            text: this.$t('menu.dashboard'),
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: this.$t('menu.suppliers_management'),
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  