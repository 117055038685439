import BaseApi from "./BaseApi";

export default class Materials extends BaseApi{
    construct() {}

    static async searchLocal(params){
        return await Materials.getAxiosInstance().get(Materials.getFullApiUrl() + 'material-work' ,{params: params});
    }

    static async changeWarehouse(params){
        return await Materials.getAxiosInstance().post(Materials.getFullApiUrl() + 'material-work/change-warehouse' , params);
    }    

    static async removeFromWarehouse(params){
        return await Materials.getAxiosInstance().delete(Materials.getFullApiUrl() + 'material-work/change-warehouse', {params: params})
    }
 
    static async search(params) {
        return await Materials.getAxiosInstance().get(Materials.getFullApiUrl(),{params: params});
    }

    static async create(params) {
        return await Materials.getAxiosInstance().post(Materials.getFullApiUrl(),params);
    }

    static async find(id) {
        return await Materials.getAxiosInstance().get(Materials.getFullApiUrl() + id);
    }

    static async update(params, id) {
        return await Materials.getAxiosInstance().put(Materials.getFullApiUrl() + id,params);
    }

    static async delete(id) {
        return await Materials.getAxiosInstance().delete(Materials.getFullApiUrl() + id);
    }
    
    static async list(params) {
        let url = Materials.getFullApiUrl() + "list";

        return Materials.getAxiosInstance().get(url,{params: params});
    }

    static async addInvoice(params){
        return await Materials.getAxiosInstance().post(Materials.getFullApiUrl() + 'invoices',params);
    }

    static async deleteInvoice(id){
        return await Materials.getAxiosInstance().delete(Materials.getFullApiUrl() + 'invoices/' + id);
    }

    static async downloadInvoice(id, tenant) {
        let url = Materials.getFullApiUrl(tenant) + 'invoices/download/' + id
        
        return await Materials.getAxiosInstanceDownload(url)
    }
    
    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/materials/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/materials/";
    }
}
