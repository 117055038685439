<template>
    <v-card elevation="10" :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75);'" :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
        <v-container>
            <validation-observer ref="form" v-slot="{ invalid }">
            <v-form @submit.prevent="submit"  class="mb-16">
                <v-progress-linear v-if="loading"
                    indeterminate
                    class="global-loader"
                ></v-progress-linear>
                <fieldset :disabled="loading">                  
                    <v-card class="transparent elevation-0" v-if="fields != null">
                        <v-card-title :class="$root.$vuetify.theme.isDark ? 'primary' : 'primary white--text'">
                          {{$t('work.auto.title')}}
                        </v-card-title>
                        <v-card-text v-if="!loading">
                            <v-row class="mt-2">
                                <v-col cols="12" md="4">
                                    <v-text-field :label="$t('globals.code')"
                                        v-model="fields.code"
                                        outlined dense
                                        readonly
                                        prepend-inner-icon="mdi-account-details"
                                    />
                                </v-col>
                                <v-col cols="12" md="8">
                                    <v-text-field :label="$t('globals.work')"
                                        v-model="fields.name"
                                        outlined dense
                                        readonly
                                        prepend-inner-icon="mdi-hard-hat"
                                    />
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-menu
                                        v-model="menu2"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <validation-provider v-slot="{ errors }" vid="data" :name="$t('globals.data')" rules="required">
                                            <v-text-field
                                                outlined dense
                                                v-model="fields.data"
                                                :label="$t('globals.data') + '*'"
                                                prepend-inner-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                :error-messages="errors"
                                            ></v-text-field>
                                        </validation-provider>
                                        </template>
                                        <v-date-picker
                                            :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                                            v-model="fields.data"
                                            @input="menu2 = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field :label="$t('globals.total')"
                                        :value="getTotalCost"
                                        outlined dense
                                        readonly
                                        prepend-inner-icon="mdi-cash"
                                    />
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        :label="$t('budgeting.budget_k')"
                                        readonly
                                        :value="$currency(fields.work_value_k)"
                                        outlined dense
                                        prepend-inner-icon="mdi-hand-coin"
                                    />
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-checkbox                                      
                                        :disabled="method=='update'"   
                                        class="ma-0 pa-0"
                                        v-model="calculate_global"
                                        hide-details
                                        :label="$t('budgeting.budget_related_no_units')"
                                    />
                                </v-col>
                                <v-col cols="12" md="3" v-if="calculate_global">
                                    <v-text-field
                                        :disabled="!calculate_global || method=='update'"                                        
                                        :label="'% ' + $t('budgeting.invoice_from_budget')"
                                        v-model="fields.global_percent"
                                        outlined dense
                                    />
                                </v-col>
                                <v-col cols="12" md="12" justify="end" align="end">
                                    <v-btn @click="openAttachments = true" class="primary">
                                        <v-icon>mdi-paperclip</v-icon>
                                        {{$t('globals.attachments')}}
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="fields.budgeted != null && calculate_global == false">
                                <v-card style="width: 100%;" class="elevation-1 mt-4" v-for="(element, index) in fields.chapters" v-bind:key="index + 10000">
                                    <v-card-title class="primary white--text">
                                        <v-row>
                                            <v-col cols="12" md="auto">
                                                {{element.description}}     
                                            </v-col>
                                            <v-col cols="12" md="auto">
                                                <v-radio-group
                                                    :readonly="method=='update'"
                                                    dark
                                                    v-model="rowRadioButton"
                                                    row
                                                    class="mt-n1"
                                                >
                                                    <v-radio
                                                        label="Calcular margem por capítulo"
                                                        value="1"
                                                    ></v-radio>
                                                    <v-radio
                                                        label="Calcular margem por classe"
                                                        value="2"
                                                    ></v-radio>
                                                    <v-radio
                                                        label="Calcular margem por unidades"
                                                        value="3"
                                                    ></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="rowRadioButton == '1' || rowRadioButton == '2' || rowRadioButton == '3'"> 
                                            <v-col cols="12" md="4" align="end">
                                                <v-text-field
                                                    dark
                                                    :label="$t('globals.budgeted')"
                                                    :value="$currency(element.budgeted)"
                                                    readonly
                                                />
                                            </v-col>    
                                            <v-col cols="12" md="2" align="end">
                                                <v-text-field
                                                    dark
                                                    :readonly="rowRadioButton != '1'"
                                                    :label="$t('globals.percent_am')"
                                                    v-model="element.margin"
                                                    @keyup="changeMargin(element)"
                                                />
                                            </v-col>      
                                            <v-col cols="12" md="4" align="end">
                                                <v-text-field
                                                    dark
                                                    :label="$t('shoppings.total_invoiced')"
                                                    :value="$currency(element.total_charged)"
                                                    readonly
                                                />
                                            </v-col> 
                                        </v-row>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card v-for="(c, index_chapter) in element.classes" v-bind:key="index_chapter + 2000000" class="elevation-0">
                                        <v-card-subtitle>
                                            <v-row>
                                                <v-col cols="12" md="4">
                                                    <v-icon color="primary">mdi-arrow-right-bold-circle</v-icon><span class="ml-4 font-weight-bold">{{c.description}}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row v-if="rowRadioButton == '2' || rowRadioButton == '3'">
                                                <v-col cols="12" md="2" align="end" v-if="false">
                                                    <v-text-field
                                                        :label="$t('globals.margin') + ' (%)'"
                                                        :value="c.chapter_class_margin"
                                                        readonly
                                                    />
                                                </v-col>  
                                                <v-col cols="12" md="4" align="end">
                                                    <v-text-field
                                                        :label="$t('globals.budgeted')"
                                                        :value="$currency(c.budgeted)"
                                                        readonly
                                                    />
                                                </v-col>    
                                                <v-col cols="12" md="2" align="end">
                                                    <v-text-field
                                                        :label="$t('globals.percent_am')"
                                                        v-model="c.margin"
                                                        :readonly="rowRadioButton != '2'"
                                                        clearable
                                                        @keyup="changeClasseMargin(c)"
                                                    />
                                                </v-col>      
                                                <v-col cols="12" md="4" align="end">
                                                    <v-text-field
                                                        :label="$t('shoppings.total_invoiced')"
                                                        :value="$currency(c.total_charged)"
                                                        readonly
                                                    />
                                                </v-col>                     
                                            </v-row>
                                        </v-card-subtitle>
                                        <v-divider></v-divider>
                                        <v-card-text>
                                            <v-simple-table dense :class="($root.$vuetify.theme.isDark ? 'secondary' : '')">
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                        <th class="text-start">
                                                            {{$t('globals.work_units_tables.name')}}
                                                        </th>
                                                        <th class="text-center">
                                                            {{$t('globals.done')}}
                                                        </th>
                                                        <th class="text-center">
                                                            {{$t('globals.actual_price')}}
                                                        </th>
                                                        <th class="text-center">
                                                            {{$t('globals.budgeted')}}
                                                        </th>
                                                        <th class="text-center" v-if="rowRadioButton == '3'">
                                                            {{$t('globals.percent_am')}}
                                                        </th>
                                                        <th class="text-center">
                                                            {{$t('shoppings.total_invoiced')}}
                                                        </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(un, index_unit) in c.units" v-bind:key="index_unit + 3000000">
                                                        <td class="text-start">
                                                            <label>{{un.code}} - {{un.unit}}</label>
                                                        </td>
                                                        <td class="text-center">
                                                            <label>{{un.efetuada}}%</label>
                                                        </td>
                                                        <td class="text-center">
                                                            <label>{{$currency(un.actual_cost)}}</label>
                                                        </td>
                                                        <td class="text-center">
                                                            <label>{{$currency(un.budgeted)}}</label>
                                                        </td>
                                                        <td style="width: 10%;" v-if="rowRadioButton == '3'">
                                                            <v-text-field
                                                                :readonly="rowRadioButton != '3'"
                                                                v-model="un.margin"
                                                                @keyup="changeUnitMargin(un, c)"
                                                            />
                                                        </td>
                                                        <td class="text-center">
                                                            {{$currency(un.total_charged)}}
                                                        </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card-text>
                                    </v-card>
                                </v-card>
                                
                                
                                
                                <v-card style="width: 100%;" class="elevation-1 mt-4" v-if="fields.indirect_work_hand != null">
                                    <v-card-title class="primary white--text">
                                        <v-row>
                                            <v-col cols="12">
                                                {{$t('globals.work_hand')}}
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="4" align="end">
                                                <v-text-field
                                                    dark
                                                    :label="$t('globals.budgeted')"
                                                    :value="$currency(fields.indirect_work_hand.budgeted)"
                                                    readonly
                                                />
                                            </v-col>    
                                            <v-col cols="12" md="2" align="end">
                                                <v-text-field
                                                    dark
                                                    :label="$t('globals.percent_am')"
                                                    v-model="fields.indirect_work_hand.margin"
                                                    @keyup="changeMargin(fields.indirect_work_hand)"
                                                />
                                            </v-col>      
                                            <v-col cols="12" md="4" align="end" :key="updater">
                                                <v-text-field
                                                    dark
                                                    :label="$t('shoppings.total_invoiced')"
                                                    :value="fields.indirect_work_hand.margin == '' ? $currency(0) : $currency(fields.indirect_work_hand.total_charged)"
                                                    readonly
                                                />
                                            </v-col> 
                                        </v-row>
                                    </v-card-title>
                                </v-card>


                                <v-card style="width: 100%;" class="elevation-1 mt-4" v-if="fields.subcontractor != null">
                                    <v-card-title class="primary white--text">
                                        <v-row>
                                            <v-col cols="12" md="auto">
                                                {{$t('globals.subcontractors')}}
                                            </v-col>
                                            <v-col cols="12" md="auto" class="mt-n4">
                                                <v-switch
                                                    inset 
                                                    color="success"
                                                    v-model="subcontractorHeaderMargin" 
                                                    dark
                                                    persistent-hint
                                                    :hint="'(' + $t('work.auto.switch_hint') + ')'" 
                                                    :label="$t('work.auto.switch_calc')" />
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="4" align="end">
                                                <v-text-field
                                                    dark
                                                    :label="$t('globals.budgeted')"
                                                    :value="$currency(fields.subcontractor.budgeted)"
                                                    readonly
                                                />
                                            </v-col>    
                                            <v-col cols="12" md="2" align="end">
                                                <v-text-field
                                                    dark
                                                    :readonly="!subcontractorHeaderMargin"
                                                    :label="$t('globals.percent_am')"
                                                    v-model="fields.subcontractor.margin"
                                                    @keyup="changeMargin(fields.subcontractor);"
                                                />
                                            </v-col>      
                                            <v-col cols="12" md="4" align="end">
                                                <v-text-field
                                                    dark
                                                    :label="$t('shoppings.total_invoiced')"
                                                    :value="$currency(fields.subcontractor.total_charged)"
                                                    readonly
                                                />
                                            </v-col> 
                                        </v-row>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card class="elevation-0">
                                        <v-card-subtitle v-for="item in fields.subcontractor.data" :key="item.id">
                                            <v-row>
                                                <v-col cols="12" md="4">
                                                    <v-icon color="primary">mdi-arrow-right-bold-circle</v-icon><span class="ml-4 font-weight-bold">{{ item.object.name}}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row justify="end" v-if="!subcontractorHeaderMargin">
                                                <v-col cols="12" md="2" align="end">
                                                    <v-text-field
                                                        :label="$t('globals.budgeted')"
                                                        :value="$currency(item.budgeted)"
                                                        readonly
                                                    />
                                                </v-col>    
                                                <v-col cols="12" md="2" align="end">
                                                    <v-text-field
                                                        :label="$t('globals.percent_am')"
                                                        v-model="item.margin"
                                                        @keyup="changeSubcontractorIndividualMargin(item)"
                                                    />
                                                </v-col>      
                                                <v-col cols="12" md="2" align="end">
                                                    <v-text-field
                                                        :label="$t('shoppings.total_invoiced')"
                                                        :value="$currency(item.total_charged)"
                                                        readonly
                                                    />
                                                </v-col> 
                                            </v-row>
                                        </v-card-subtitle>
                                    </v-card>
                                </v-card>


                                <v-card style="width: 100%;" class="elevation-1 mt-4" v-if="fields.indirect_costs != null">
                                    <v-card-title class="primary white--text">
                                        <v-row>
                                            <v-col cols="12">
                                                {{$t('budgeting.indirect_costs')}}
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="false">
                                            <v-col cols="12" md="4" align="end">
                                                <v-text-field
                                                    dark
                                                    :label="$t('globals.budgeted')"
                                                    :value="$currency(fields.indirect_costs.budgeted)"
                                                    readonly
                                                />
                                            </v-col>    
                                            <v-col cols="12" md="2" align="end">
                                                <v-text-field
                                                    dark
                                                    :label="$t('globals.percent_am')"
                                                    v-model="fields.indirect_costs.margin"
                                                    @keyup="changeMargin(fields.indirect_costs)"
                                                />
                                            </v-col>      
                                            <v-col cols="12" md="4" align="end">
                                                <v-text-field
                                                    dark
                                                    :label="$t('shoppings.total_invoiced')"
                                                    :value="$currency(fields.indirect_costs.total_charged)"
                                                    readonly
                                                />
                                            </v-col> 
                                        </v-row>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card class="elevation-0">
                                        <v-card-subtitle>
                                            <v-row>
                                                <v-col cols="12" md="4">
                                                    <v-icon color="primary">mdi-arrow-right-bold-circle</v-icon><span class="ml-4 font-weight-bold">{{$t('globals.construction_site')}}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row justify="end">
                                                <v-col cols="12" md="4" align="end">
                                                    <v-text-field
                                                        :label="$t('globals.budgeted')"
                                                        :value="$currency(fields.indirect_costs.stable.budgeted)"
                                                        readonly
                                                    />
                                                </v-col>    
                                                <v-col cols="12" md="2" align="end">
                                                    <v-text-field
                                                        :label="$t('globals.percent_am')"
                                                        v-model="fields.indirect_costs.stable.margin"
                                                        clearable
                                                        @keyup="changeMargin(fields.indirect_costs.stable)"
                                                    />
                                                </v-col>      
                                                <v-col cols="12" md="4" align="end">
                                                    <v-text-field
                                                        :label="$t('shoppings.total_invoiced')"
                                                        :value="$currency(fields.indirect_costs.stable.total_charged)"
                                                        readonly
                                                    />
                                                </v-col>                     
                                            </v-row>
                                        </v-card-subtitle>
                                        <v-card-subtitle>
                                            <v-row>
                                                <v-col cols="12" md="4">
                                                    <v-icon color="primary">mdi-arrow-right-bold-circle</v-icon><span class="ml-4 font-weight-bold">{{$t('budgeting.general_expenses')}}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row justify="end">
                                                <v-col cols="12" md="4" align="end">
                                                    <v-text-field
                                                        :label="$t('globals.budgeted')"
                                                        :value="$currency(fields.indirect_costs.general_expenses.budgeted)"
                                                        readonly
                                                    />
                                                </v-col>    
                                                <v-col cols="12" md="2" align="end">
                                                    <v-text-field
                                                        :label="$t('globals.percent_am')"
                                                        v-model="fields.indirect_costs.general_expenses.margin"
                                                        clearable
                                                        @keyup="changeMargin(fields.indirect_costs.general_expenses)"
                                                    />
                                                </v-col>      
                                                <v-col cols="12" md="4" align="end">
                                                    <v-text-field
                                                        :label="$t('shoppings.total_invoiced')"
                                                        :value="$currency(fields.indirect_costs.general_expenses.total_charged)"
                                                        readonly
                                                    />
                                                </v-col>                     
                                            </v-row>
                                        </v-card-subtitle>
                                    </v-card>
                                </v-card>
                            </v-row>
                        </v-card-text>
                        <v-card-actions v-if="method == 'create'">
                            <v-row class="mt-6" align="center" justify="space-around">
                                <v-btn :disabled="invalid" depressed color="primary" type="submit">
                                {{$t('globals.save')}}
                                </v-btn>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </fieldset>
            </v-form>
            </validation-observer>
            <DialogSuccess :opened="success" @on-ok="onSuccess" >
                {{$t('work.auto.success')}}
            </DialogSuccess>
        </v-container>
        <v-dialog v-model="openAttachments" width="850px">
            <Attachments :method="method" @close="closeWithFiles" @reload="toReload" :fields="fields" />
        </v-dialog>

        <Attachments ref="AttachmentsDialog" v-if="!loading" v-show="false" :method="method" @close="closeWithFiles" @reload="toReload" :fields="fields" />
    </v-card>
</template>
<script>
import SelfMeasuring from "@/api/SelfMeasuring.js";
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Attachments from "@/components/self_measuring/dialog/Attachments.vue"

export default{
    name:"SelfMeasuring",
    props:{
        method: String,
        selfMeasure: Object,
    },
    watch: { 
    selfMeasure: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;

          this.rowRadioButton = "3"

          console.log(this.fields)  
          this.calculate_global = this.fields.calculate_global
          this.fields.budgeted = {}

          if(this.calculate_global == false){

            if(this.fields.chapters[0].margin != null)
                if(this.fields.chapters[0].margin != '')
                    this.rowRadioButton = "1"

            if(this.fields.chapters[0].classes[0].margin != null)
                if(this.fields.chapters[0].classes[0].margin != '')
                    this.rowRadioButton = "2"

          }

          this.loading = false
        }
      },
    },
    components:{
        DialogSuccess,
        Attachments
    },
    data(){
        return{
            openAttachments: false,
            subcontractorHeaderMargin: true,
            rowRadioButton: "1",
            menu2: false,
            success: false,
            loading: true,
            updater: 9000,
            calculate_global: false,
            fields: null,
            subContractor:{
                globalMargin: true,
                individualMargin: true
            },
            error:{
                title: null,
                message: null
            },
            response: null,
            fileAttachments: []
        }
    },
    mounted(){
        if(!this.$root.session.hasPermission(["super", "self_measuring.create"])) {
            this.$router.push('/admin');
        }
    
        if(this.method == 'create')
            SelfMeasuring.loadWork(this.$route.params.work_id).then(({data}) => { 
                this.fields = data; 
                this.loading = false;
            });

            
    },
    methods:{
        toReload(){
            if(this.method == 'update')
                this.$emit('refresh')
        },
        closeWithFiles(files){
            this.fileAttachments = files
            this.openAttachments = false
        },
        changeClasseMargin(ob){
            if(ob.margin != null){
                if(ob.margin != ''){
                    ob.total_charged = ((ob.budgeted) * (ob.margin / 100)).toFixed(2)
                }
            }

            
            this.fields.chapters.forEach(chapter => {
                let total = 0
                let average_percentage = 0
                let counter = 0;
                chapter.classes.forEach(classe => {
                    if(classe.margin != null){
                        if(classe.margin != ''){
                            total = total + parseFloat(classe.total_charged)
                            counter++
                            average_percentage = average_percentage + parseFloat(classe.margin)
                        }
                    }
                });
                let per = (average_percentage / counter).toFixed(2)
                chapter.margin = isNaN(per) ? null : per
                chapter.total_charged = isNaN(total) ? null : total
            });
        },
        changeSubcontractorIndividualMargin(item){
            let percentage = item.margin / 100
            let value = item.budgeted * percentage;
            item.total_charged = value


            let total = 0
            let average_percentage = 0
            let counter = 0;
            this.fields.subcontractor.data.forEach(element => {
                if(element.total_charged != null)
                    if(element.total_charged != '')
                        total = total + element.total_charged

                if(element.margin != null){
                    if(element.margin != ''){
                        average_percentage = average_percentage + parseFloat(element.margin)
                        counter++
                    }
                }
            });
            this.fields.subcontractor.margin = parseFloat(average_percentage/counter, 2);
            if(isNaN(this.fields.subcontractor.margin))
                this.fields.subcontractor.margin = null
                
            if(isNaN(total))
                total = null

            this.fields.subcontractor.total_charged = total
        },
        getTotalInvoiced(){
            let total = 0
            if(this.calculate_global){
                let valor = this.fields.work_value_k * (this.fields.global_percent / 100)

                total = valor
                if(isNaN(total))
                    total = 0
                return total
            }

            this.fields.chapters.forEach(chapter => {
                if(chapter.margin == null || chapter.margin == ''){
                    chapter.classes.forEach(classe => {
                        if(classe.margin == null || classe.margin == ''){
                            classe.units.forEach(element => {
                                element.total_charged = element.total_charged == null ? 0 : element.total_charged;
                                total = parseFloat(total) + parseFloat(element.total_charged == null ? 0 : element.total_charged)
                            });
                        }else{
                            classe.total_charged = classe.total_charged == null ? 0 : classe.total_charged;
                            total = parseFloat(total) + parseFloat(classe.total_charged == null ? 0 : classe.total_charged)
                        }
                    });
                }else{
                    chapter.total_charged = chapter.total_charged == null ? 0 : chapter.total_charged;
                    total = parseFloat(total) + parseFloat(chapter.total_charged == null ? 0 : chapter.total_charged)
                }
            });

            // Juntar custos de Mão de Obra, subempreitadas e Gerais
            if(this.fields.indirect_work_hand != null)
                if(this.fields.indirect_work_hand.total_charged != null)
                    total = total + parseFloat(this.fields.indirect_work_hand.total_charged)

            if(this.fields.subcontractor != null)
                if(this.fields.subcontractor.total_charged != null)
                    total = total + parseFloat(this.fields.subcontractor.total_charged)


            if(this.fields.subcontractor != null)
                if(this.fields.subcontractor.margin == null){
                    this.fields.subcontractor.data.forEach(element => {
                        
                        if(element.margin != null){
                            if(element.margin != ''){
                                total = total + parseFloat(element.total_charged)
                            }
                        }
                    });
                }
            

            if(this.fields.indirect_costs != null){
                if(this.fields.indirect_costs.margin != null && this.fields.indirect_costs.margin != '')
                    total = total + parseFloat(this.fields.indirect_costs.total_charged)
                else{
                    if(this.fields.indirect_costs.stable.total_charged != null)
                        total = total + parseFloat(this.fields.indirect_costs.stable.total_charged)

                    if(this.fields.indirect_costs.general_expenses.total_charged != null)
                        total = total + parseFloat(this.fields.indirect_costs.general_expenses.total_charged)
                }
            }


            return total
        },
        onSuccess(){
            // voltar para a obra talvez
            if(this.method == 'create'){
                let domain = '/' + this.$session.get('domain')                
                this.$router.push(domain + "/admin/invoiced/self-measuring/" + this.$route.params.work_id + '/' + this.response)
                return
            }

            if(this.fields != null)
                if(this.fields.id != null) {
                    this.success = false
                    return
                }
            let domain = '/' + this.$session.get('domain')
            this.$router.push(domain + '/admin/invoiced/self-measurings');
        },
        submit(){
            let total_faturado = 0
            let percentagem_total_faturada = 0
            this.fields.chapters.forEach(chapter => {
                total_faturado = total_faturado + parseFloat(chapter.total_charged)
                percentagem_total_faturada = percentagem_total_faturada + parseFloat(chapter.margin)

                chapter.percentagem = chapter.margin
                chapter.total_faturado = parseFloat(chapter.total_charged)
            });

            this.fields.total_faturado = total_faturado
            this.fields.percentagem_faturada = parseFloat(percentagem_total_faturada) / parseFloat(this.fields.chapters.length)

            
            if(isNaN(this.fields.percentagem_faturada)){
                total_faturado = 0
                percentagem_total_faturada = 0
                let total_classes = 0
                // Falhou os capitulos, devem ser as classes que tem de ser faturas
                this.fields.chapters.forEach(chapter => {
                    chapter.classes.forEach(classe => {
                        total_faturado = total_faturado + parseFloat(classe.total_charged)
                        percentagem_total_faturada = percentagem_total_faturada + parseFloat(classe.margin)

                        classe.percentagem = classe.margin
                        classe.total_faturado = parseFloat(classe.total_charged)
                        total_classes++
                    });
                });
                
                this.fields.total_faturado = total_faturado
                this.fields.percentagem_faturada = parseFloat(percentagem_total_faturada) / parseFloat(total_classes)
            }

            if(isNaN(this.fields.percentagem_faturada)){
                total_faturado = 0;
                percentagem_total_faturada = 0;
                let total_unidades = 0
                this.fields.chapters.forEach(chapter => {
                    chapter.classes.forEach(classe => {
                        classe.units.forEach(unit => {
                            total_faturado = total_faturado + parseFloat(unit.total_charged)
                            percentagem_total_faturada = percentagem_total_faturada + parseFloat(unit.margin)

                            unit.percentagem = unit.margin
                            unit.total_faturado = parseFloat(unit.total_charged)
                            total_unidades++
                        });
                    });
                });
                
                
                this.fields.total_faturado = total_faturado
                this.fields.percentagem_faturada = parseFloat(percentagem_total_faturada) / parseFloat(total_unidades)
            }
            
            this.fields.total_invoiced_value = this.getTotalInvoiced()

            if(this.calculate_global){
                this.fields.percentagem_faturada = this.fields.global_percent
            }

            this.fields.calculate_global = this.calculate_global


            SelfMeasuring[this.method](this.fields, this.fields.id).then(async(resp)  =>  {
                this.response = resp.data.id

                if(this.method == 'create'){
                    console.log(this.$refs)
                    await this.$refs.AttachmentsDialog.saveAttachmentsCreate(this.fileAttachments, this.response)
                }

                this.success = true;

                this.loading = false;

                //"/" + this.$session.get("domain") + "/admin/accesses/users",
                ///:tenant/admin/invoiced/self-measuring/:work_id/:id

                }).catch(err => {
                this.loading = false;

                console.log(err)

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },        
        changeMargin(ob){
            if(ob.margin != null){
                if(ob.margin != ''){
                    this.updater++
                    let percent = ob.margin / 100
                    ob.total_charged = (ob.budgeted) * percent                                       
                    return
                }
            }
            ob.total_charged = 0
        },
        changeUnitMargin(ob, classe){
            if(ob.margin != null){
                if(ob.margin != ''){
                    ob.total_charged = ((ob.budgeted) * (ob.margin / 100)).toFixed(2)
                    let counter = 0
                    let total = 0
                    let total_percentage = 0

                    classe.units.forEach(element => {
                        if(element.margin != null){
                            if(element.margin != ''){
                                counter ++
                                total = total + parseFloat(element.total_charged)
                                total_percentage = total_percentage + parseFloat(element.margin)
                            }
                        }
                    });
                    classe.total_charged = total;
                    classe.margin = parseFloat(total_percentage / counter).toFixed(2)

                    
                }
            }

            this.fields.chapters.forEach(chapter => {
                let total = 0
                let average_percentage = 0
                let counter = 0;
                chapter.classes.forEach(classe => {
                    if(classe.margin != null){
                        if(classe.margin != ''){
                            total = total + parseFloat(classe.total_charged)
                            counter++
                            average_percentage = average_percentage + parseFloat(classe.margin)
                        }
                    }
                });
                let per = (average_percentage / counter).toFixed(2)
                chapter.margin = isNaN(per) ? null : per
                chapter.total_charged = isNaN(total) ? null : total
            });
        },
        fillBaseData(){
            
        }
    },
    computed:{
        readonlySubcontractorMargin(){
            let flag = true

            this.fields.subcontractor.data.forEach(element => {
                if(element.margin == null)
                    flag = false
                if(element.margin == '')
                    flag = false
            });

            return flag
        },
        getTotalCost(){
            let total = 0

            if(this.calculate_global){
                let valor = this.fields.work_value_k * (this.fields.global_percent / 100)

                total = valor
                if(isNaN(total))
                    total = 0
                return this.$currency(total)
            }


            this.fields.chapters.forEach(chapter => {
                if(chapter.margin == null || chapter.margin == ''){
                    chapter.classes.forEach(classe => {
                        if(classe.margin == null || classe.margin == ''){
                            classe.units.forEach(element => {
                                element.total_charged = element.total_charged == null ? 0 : element.total_charged;
                                total = parseFloat(total) + parseFloat(element.total_charged == null ? 0 : element.total_charged)
                            });
                        }else{
                            classe.total_charged = classe.total_charged == null ? 0 : classe.total_charged;
                            total = parseFloat(total) + parseFloat(classe.total_charged == null ? 0 : classe.total_charged)
                        }
                    });
                }else{
                    chapter.total_charged = chapter.total_charged == null ? 0 : chapter.total_charged;
                    total = parseFloat(total) + parseFloat(chapter.total_charged == null ? 0 : chapter.total_charged)
                }
            });

            // Juntar custos de Mão de Obra, subempreitadas e Gerais
            if(this.fields.indirect_work_hand != null)
                if(this.fields.indirect_work_hand.total_charged != null)
                    total = total + parseFloat(this.fields.indirect_work_hand.total_charged)

            if(this.fields.subcontractor != null)
                if(this.fields.subcontractor.total_charged != null)
                    total = total + parseFloat(this.fields.subcontractor.total_charged)

            if(this.fields.subcontractor != null)
                if(this.fields.subcontractor.margin == null){
                    this.fields.subcontractor.data.forEach(element => {
                        
                        if(element.margin != null){
                            if(element.margin != ''){
                                total = total + parseFloat(element.total_charged)
                            }
                        }
                    });
                }
            

            if(this.fields.indirect_costs != null){
                if(this.fields.indirect_costs.margin != null && this.fields.indirect_costs.margin != '')
                    total = total + parseFloat(this.fields.indirect_costs.total_charged)
                else{
                    if(this.fields.indirect_costs.stable.total_charged != null)
                        total = total + parseFloat(this.fields.indirect_costs.stable.total_charged)

                    if(this.fields.indirect_costs.general_expenses.total_charged != null)
                        total = total + parseFloat(this.fields.indirect_costs.general_expenses.total_charged)
                }
            }


            return this.$currency(total)
        },
        showChapterMargins(){
            let show = true
            this.fields.chapters.forEach(chapter => {
                chapter.classes.forEach(classe => {
                    classe.units.forEach(unit => {
                        if(unit.margin != null)
                            if(unit.margin.length > 0)
                                show = false
                    });
                    if(classe.margin != null)
                        if(classe.margin.length > 0)
                            show = false
                });
            });
            return show
        },
        showClasseMargins(){
            /*let show = true
            this.fields.chapters.forEach(chapter => {
                if(chapter.margin != undefined)
                    if(chapter.margin != null)
                        if(chapter.margin.length > 0)
                            show = false
                chapter.classes.forEach(classe => {
                    classe.units.forEach(unit => {
                        if(unit.margin != undefined)
                            if(unit.margin != null)
                                if(unit.margin.length > 0)
                                    show = false
                    });
                });
            });
            
            return show*/
            return true
        },
        showUnitsMargins(){
            let show = true
            this.fields.chapters.forEach(chapter => {
                if(chapter.margin != undefined)
                    if(chapter.margin != null)
                        if(chapter.margin.length > 0)
                            show = false

                chapter.classes.forEach(classe => {
                    if(classe.margin != undefined)
                        if(classe.margin != null)
                            if(classe.margin.length > 0)
                                show = false
                });
            });
            
            return show
        }
    }
}
</script>