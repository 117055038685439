<template>
    <v-container fluid class="ma-0 pa-0">
        <v-row>
              <v-col cols="12">
                <v-card elevation="0"  :class="this.$root.$vuetify.theme.isDark ? 'secondary' : ''">
                  <v-card-title>{{$t('costs.subcontractors')}}</v-card-title>
                  <v-card-subtitle>{{$t('costs.subcontractors_cost')}}</v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                  <v-card
                    class="grow"
                    color="secondary"
                    outlined
                    height="125px"
                    dark
                    elevation="0"
                >
                  <v-card-subtitle>
                      <div class="text-center mt-n4 mb-3">
                          <span class="text-h6 font-weight-light">{{$t('globals.total')}}</span>
                      </div>
                  </v-card-subtitle>
                <!-- DESKTOP -->
                <v-card-text class="d-none d-lg-block ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="auto">                            
                        <v-icon
                            large
                            center
                        >
                        {{$currency_icon()}}
                        </v-icon>
                      </v-col>
                      <v-col cols="12" md="auto" class="text-h6 font-weight-bold white--text ml-4">
                        {{ parseValue(costs.subempreitadas.total_cost) }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
                  <!-- MOBILE -->
                  <v-card-text class="d-md-none ma-0 pa-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="12" md="12" class="font-weight-bold white--text text-center">
                        {{ parseValue(costs.subempreitadas.total_cost) }}
                      </v-col>
                    </v-row>                      
                  </v-card-text>
                </v-card>
              </v-col>


              <v-card width="100%" elevation="0"  :class="this.$root.$vuetify.theme.isDark ? 'secondary' : ''">
                <v-card-title>
                {{$t('costs.subcontractors_descriptive')}}
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('globals.filter')"
                    single-line
                    hide-details
                ></v-text-field>
                </v-card-title>
                <v-data-table
                    :footer-props="tableFooter"
                    :headers="headers"
                    :items="costs.subempreitadas.subempreitadas"
                    :class="this.$root.$vuetify.theme.isDark ? 'secondary' : ''"
                    :search="search"
                    style="white-space: nowrap;"
                >
            
                <template v-slot:item.value="{item}">
                    {{ $currency(item.value) }}
                </template>
                
            </v-data-table>
            </v-card>


            </v-row>
    </v-container>
</template>
<script>
export default{
    props: ['costs'],
    mounted(){
      
    },
    data(){
        return{            
            search: '',
        }
    },
    methods:{
        parseValue(v){
            return this.$currency(v)
          },
    },
    computed:{
      tableFooter(){
        let t = this.$t("globals.lines_datatable")
        let a = [5, 10, 15, 20];
        return {
          'items-per-page-options': a,
          'items-per-page-text': t
        }
      }, 
      headers(){
        let he = [          
          {
              text: this.$t('globals.work'),
              value: 'work_name'
          },
          {
              text: this.$t('globals.name'),
              value: 'name'
          },
          {
              text: this.$t('globals.description'),
              value: 'description'
          },
          {
              text: this.$t('costs.subcontractor_day'),
              value: 'starting_date'
          },  
          {
              text: this.$t('costs.total_cost'),
              value: 'value'
          },
        ]

        return he
      }
    }
}
</script>