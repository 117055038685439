<template>
    <v-container fluid class="mt-n16 mb-6">
        <v-data-table
            :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'"
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
            locale="pt-pt"
        >

        <template v-slot:item.invoice="{item}">

            <v-btn v-if="item.invoice_path!=null" rounded text small class="success" @click="downloadInvoice(item)">
                <v-icon small color="white">mdi-download-box</v-icon>
            </v-btn>   
            <span v-else>-</span>
        </template>

        <template v-slot:item.proposal="{item}">

            <v-btn v-if="item.proposal!=null" rounded text small class="success" @click="downloadProposal(item)">
                <v-icon small color="white">mdi-download-box</v-icon>
            </v-btn>   
            <span v-else>-</span>
        </template>

            
        </v-data-table>
    </v-container>
</template>
<script>
import Subempreiteiro from "@/api/Subempreiteiro.js";
import SubempreitadaAPI from "@/api/Subempreitada.js"

export default{
    name: "SubempreiteiroWorks",
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchWorksCarriedOut()}, 10);
        },
        deep: true,
      },
    },
    data(){
        return{
            items: [],
            total: 0,
            loading: true,
            options: {}
        }
    },
    mounted(){
        this.fillBaseData()
        this.loading = false
    },
    methods:{  
        getFilename(file){
            if(file == null)
                return ''
            return file.substring(file.lastIndexOf('/')+1)
        },
        downloadProposal(object){
            SubempreitadaAPI['proposal_download'](object.id).then((response) => {
                let documentName =  this.getFilename(object.proposal)

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", documentName + '.pdf');
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch(err => {
                this.loading = false;

                console.log(err)

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },    
        downloadInvoice(object){
            console.log(object)
            SubempreitadaAPI['invoice_download'](object.id).then((response) => {
                let documentName =  this.getFilename(object.invoice_path)

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", documentName + '.pdf');
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch(err => {
                this.loading = false;

                console.log(err)

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        }, 
        async fillBaseData(){
            await this.searchWorksCarriedOut()
        },
        searchWorksCarriedOut(event){
            if(!!event && event.type == 'submit'){
                this.options.page = 1;
            }
    
            this.loading = true;
    
            let filter = {...this.filter};
    
            let request = {
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                page: this.options.page,
                limit: this.options.itemsPerPage,
                subcontractor_id: this.$route.params.id
            };
        
            Object.assign(request, filter);
    
            localStorage["works-carried-out-" + this.$root.session.id] = JSON.stringify({
                filter: this.filter,
                options: this.options,
            });
    
            Subempreiteiro.searchWorksCarriedOut(request).then(response => {
    
                let data = response.data;
        
                this.total = data.total;
        
                this.items = data.data.map((item) => {
        
                    this.searching = false;
        
                    return item;
                });
        
                this.loading = false;
    
            });
        },
    },
    computed:{      
        headers(){
            let he = [          
                {
                    text: this.$t('globals.work'),
                    value: 'name'
                },
                {
                    text: this.$t('globals.name'),
                    value: 'description'
                },
                {
                    text: this.$t('globals.invoice'),
                    value: 'invoice'
                },
                {
                    text: this.$t('globals.proposal'),
                    value: 'proposal'
                },
                ]

            return he
        },            
        tableFooter(){
            let t = this.$t("globals.lines_datatable")
            let a = [5, 10, 15, 20];
            return {
                'items-per-page-options': a,
                'items-per-page-text': t
            }
        },
    }
}
</script>