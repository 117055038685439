<template>
    <section class="mt-16">
      <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon>mdi-help</v-icon> <span>{{$t('menu.how_to_do')}}</span>
      </h1>

      <v-data-table
          :headers="headers"
          :items="computedItems"
          :server-items-length="computedItems.length"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          :expanded.sync="expanded"
          :single-expand="true"
          show-expand
          :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
          locale="pt-pt"
        >
        

        <template v-slot:expanded-item="{ item }">
           
           <!-- EXPORTAR REND MAO OBRA-->
           <td :colspan="headers.length + 1">
                <iframe :src="item.url" frameborder="0" 
                    class="mt-6"
                    webkitallowfullscreen="true" 
                    mozallowfullscreen="true" 
                    allowfullscreen style="width: 100%; height: 70vh;"></iframe>
            </td>
        </template>

        </v-data-table>

    </section>

</template>
<script>
export default{
    data(){
        return{
            loading: true,
            expanded:[],
            myIframe: null,
            options: {},
        }
    },
    mounted(){
        this.loading = false
    },
    methods:{
        onLoad(frame) {
            this.myIframe = frame.contentWindow
        }
    },
    computed: {
        items(){
            let i = [
                {
                    id: '0',
                    how: this.$t('how_to_do.first_steps'),
                    description: this.$t('how_to_do.first_steps_msg'),
                    url: 'https://app.supademo.com/embed/E0zXHDEFOjQhsMHCZVDjH',
                    permission: true
                },
                {
                    id: '1',
                    how: this.$t('globals.suppliers'),
                    description: this.$t('how_to_do.suppliers_msg'),
                    url: 'https://app.supademo.com/embed/RSeKI3jNE-cH7_HDwv-Vf',
                    permission: this.$root.session.hasPermission(['super', 'companies.create', 'companies.read'])
                },
                {
                    id: '2',
                    how: this.$t('globals.exports'),
                    description: this.$t('how_to_do.exports_msg'),
                    url: 'https://app.supademo.com/embed/AXy0eGE1dFJBtkVo2YmaV',
                    permission: this.$root.session.hasPermission(['super', 'export.read'])
                },
                {
                    id: '3',
                    how: this.$t('how_to_do.create_work'),
                    description: this.$t('how_to_do.create_work_msg'),
                    url: 'https://app.supademo.com/embed/Vx_3qMxzWxsNWOdPm-X3j',
                    permission: this.$root.session.hasPermission(['super', 'works.create'])
                },
                {
                    id: '4',
                    how: this.$t('how_to_do.create_daily_part'),
                    description: this.$t('how_to_do.create_daily_part_msg'),
                    url: 'https://app.supademo.com/embed/gXNS2l3hS5JNzoro583Aj',
                    permission: this.$root.session.hasPermission(['super', 'diaries.create'])
                },
                {
                    id: '5',
                    how: this.$t('how_to_do.create_template'),
                    description: this.$t('how_to_do.create_template_msg'),
                    url: 'https://app.supademo.com/embed/H1D9Ta69PCt9S9XNdG-tp',
                    permission: this.$root.session.hasPermission(['super', 'template.create'])
                },
                {
                    id: '6',
                    how: this.$t('how_to_do.create_user'),
                    description: this.$t('how_to_do.create_user_msg'),
                    url: 'https://app.supademo.com/embed/qPgJAlRWsLECAM_jjgyAT',
                    permission: this.$root.session.hasPermission(['super', 'users.create'])
                },
                {
                    id: '7',
                    how: this.$t('how_to_do.create_performance'),
                    description: this.$t('how_to_do.create_performance_msg'),
                    url: 'https://app.supademo.com/embed/cfGGhqrKDSgCHeqYz7FHL',
                    permission: this.$root.session.hasPermission(['super', 'performance.calculate'])
                },
                {
                    id: '8',
                    how: this.$t('menu.budgeting'),
                    description: this.$t('how_to_do.budget_msg'),
                    url: 'https://app.supademo.com/embed/PEznK-4DTEBuyeWULsMVW',
                    permission: this.$root.session.hasPermission(['super', 'budget_work.create', 'budget_work.read'])
                },
                {
                    id: '9',
                    how: this.$t('how_to_do.articulados'),
                    description: this.$t('how_to_do.articulados_msg'),
                    url: 'https://app.supademo.com/embed/v6B0x-hEi2FgquiyQWa5S',
                    permission: true
                }
            ]
            return i
        },
        headers(){
            let he = [                
                {
                    text: this.$t('how_to_do.doubt'),
                    sortable: true,
                    value: 'how',
                },
                {
                    text: this.$t('globals.description'),
                    sortable: true,
                    value: 'description',
                },
            ]
            return he
        },
        tableFooter(){
          let t = this.$t("globals.lines_datatable")
          let a = [5, 10, 15, 20];
          return {
            'items-per-page-options': a,
            'items-per-page-text': t
          }
        }, 
        crumbs: function() {
            return [
                {
                    text: this.$t('menu.dashboard'),
                    disabled: false,
                    to: '/admin',
                    exact: true,
                },
                {
                    text: this.$t('menu.how_to_do'),
                    disabled: true,
                    to: window.location.href,
                    exact: true,
                },
            ];
        },
        computedItems(){
            let final = []
            this.items.forEach(element => {
                if(element.permission)
                    final.push(element)
            });
            return final
        }
    }
}
</script>