import BaseApi from "./BaseApi";

export default class Branding extends BaseApi{
    construct() {}

    static async create(params) {
        return await Branding.getAxiosInstance().post(Branding.getFullApiUrl(),params);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/branding/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/branding/";
    }
}