import BaseApi from "./BaseApi";

export default class Permission extends BaseApi{
    construct() {}

    static async list(params) {
        return await Permission.getAxiosInstance().get(Permission.getFullApiUrl() + "list",{params: params});
    }

    static async search(params) {
        return await Permission.getAxiosInstance().get(Permission.getFullApiUrl(),{params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/permissions/";
    }

    static getFullApiUrl(tenant = null){
        let domain = location.pathname.split('/')[1]
        if(tenant != null)
            domain = tenant;
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/permissions/";
    }
}