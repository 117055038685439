<template>
  <router-view></router-view>
</template>

<script>
export default {
  components: {
  },

  data: () => ({
  }),
};
</script>
<style>
@font-face {
      font-family: 'Montserrat';
      src: local('Montserrat'), url('./assets/fonts/Montserrat-Regular.ttf')  format('truetype'), 
    }
    .v-navigation-drawer__content::-webkit-scrollbar-track{
      -webkit-box-shadow: inset 0 0 6px #5d5d5d;
      background-color: #5d5d5d;
    }
    .v-navigation-drawer__content::-webkit-scrollbar{
      width: 0px;
    }
    .v-navigation-drawer__content::-webkit-scrollbar-thumb{
      -webkit-box-shadow: inset 0 0 6px #424242;
      background-color: #424242;
    }
    
</style>