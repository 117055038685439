<template>
    <section class="mt-16">
      <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <rp-icon icon="rp-calendar-blue-filled" class="mr-2"/> <span>{{$t('menu.holidays_management')}}</span>
        </h1>

        <v-row>
          <v-col cols="12" md="auto">
            <rp-icon
              icon="rp-eye-blue-filled"
              class="mb-n2"
            /><v-icon class="ml-2" small color="secondary">mdi-forward</v-icon>
            <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.visualize')}}</span>
          </v-col>
          <v-col cols="12" md="auto">
            <v-icon color="red" class="ml-4">
              mdi-delete
            </v-icon><v-icon class="ml-2" small color="secondary">mdi-forward</v-icon>
            <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.remove')}}</span>
          </v-col>
        </v-row>
        
        <div style="position:relative">
          <v-container>
            <v-speed-dial
              absolute
              v-model="speedDial"
              top
              right
              direction="bottom"
              :open-on-hover="true"
            >
              <template v-slot:activator>
                <v-tooltip right color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" v-on="on"
                      v-model="speedDial"
                      color="primary"
                      dark
                      x-large
                      fab
                    >
                      <v-icon v-if="speedDial">
                        mdi-close
                      </v-icon>
                      <v-icon v-else>
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('globals.options')}}</span>
                </v-tooltip>
              </template>
              <v-tooltip right color="info">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="info"
                    large
                    @click="searching = true"
                  >
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('globals.search')}}</span>
              </v-tooltip>
              <v-tooltip right color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="secondary"
                    large
                    :to="'/' + $session.get('domain') + '/admin/workers-settings/holidays/create'"
                    v-if="$root.session.hasPermission(['super', 'holidays.create'])"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('globals.register')}}</span>
              </v-tooltip>
            </v-speed-dial>
          </v-container>
  
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
            locale="pt-pt"
          >
            <template v-slot:item.days="{item}">
              {{ item.days.length }}
            </template>

            <template v-slot:item.next="{item}">
              {{ nextDay(item) }}
            </template>
            
            <template v-slot:item.updated_at="{ item }">
                {{ formatDate(item.updated_at)}}
            </template>
            <template v-slot:item.actions="{item}">
              <IconView
                class="mr-2"
                @click="viewHoliday(item)"
              />
              <IconRemove
                v-if="!item.has_workers && $root.session.hasPermission(['super', 'holidays.delete'])"
                class="mr-2"
                @on-submit="deleteHoliday(item)"
              />
            </template>
          </v-data-table>
        </div>
        <v-navigation-drawer
            fixed
            right
            v-model="searching"
            hide-overlay
            width="520"
            class="pt-6"
          >
          <v-list>
            <v-list-item>
              <v-btn fab small color="secondary" @click="searching = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
  
              <v-btn absolute right small color="error" @click="resetHolidays">
                {{$t('globals.clean_fields')}}
              </v-btn>
            </v-list-item>
          </v-list>
          <v-list class="pt-10">
            <v-form @submit.prevent="searchHolidays">
              
              <v-list-item>
                <v-text-field
                  v-model="filter.name"
                  prepend-inner-icon="mdi-form-textbox"
                  :label="$t('globals.name')"
                  clearable
                ></v-text-field>
              </v-list-item>
  
              <v-row
                class="mt-10"
                align="center"
                justify="space-around"
              >
              <v-btn
                color="primary"
                type="submit"
              >
                {{$t('globals.search')}}
              </v-btn>
              </v-row>
            </v-form>
          </v-list>
        </v-navigation-drawer>
    </section>
  </template>
  <script>
  import IconRemove from '@/components/ui/IconRemove.vue';
  import IconView from '@/components/ui/IconView.vue';
  import Holiday from "@/api/Holiday.js";
  import dayjs from 'dayjs';
  
  export default {
    components: {
      IconRemove,
      IconView
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchHolidays()}, 10);
        },
        deep: true,
      },
    },
    beforeCreate(){
      document.title = "Report Lake - " + this.$t('menu.holidays_management');
    },
    data: () => ({
      items: [],
      total: 0,
      loading: false,
      searching: false,
      options: {},
      speedDial:false,
      filter: {
        code:null,
        name: null,
      },
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "holidays.read"])) {
        this.$router.push('/admin');
      }
  
      this.fillBaseData();
    },
    methods:{
      formatDate(d){
          return new Date(d).toLocaleString('pt-PT')
      },
      nextDay(i){
        var dates = i.days
        //var temp = dates.map(d => Math.abs(new Date() - new Date(d).getTime()));
        //var idx = temp.indexOf(Math.min(...temp));

        let idx = 0
        if(dates.length >= 3)
          return dates[idx] + ', ' + dates[idx + 1] + ', ' + dates[idx + 2]
          
        if(dates.length >= 2)
          return dates[idx] + ', ' + dates[idx + 1]

        return dates[idx]
      },
      getTableDate(date) {
        return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
      },
      fillBaseData() {
  
        if(localStorage["holidays-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["holidays-" + this.$root.session.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
      },
      searchHolidays(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
  
        let request = {
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };
      
        Object.assign(request, filter);
  
        localStorage["holidays-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        Holiday.search(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });
  
          this.loading = false;
  
        });
      },
      resetHolidays()
      {
        this.filter = {
        };
  
        this.searchCategories();
      },
      viewHoliday(item){
      let domain = '/' + this.$session.get('domain')
        this.$router.push(domain + '/admin/workers-settings/holidays/' + item.id);
      },
      deleteHoliday(item){
        Holiday.delete(item.id)
          .then(() => {
            this.searchHolidays();
          });
      },
    },
    computed: {
      headers(){
        let he = [
          {
            text: this.$t('holidays.employee_name'),
            sortable: false,
            value: 'person.name',
          },
          {
            text: this.$t('globals.days'),
            sortable: false,
            value: 'days',
          },
          {
            text: this.$t('holidays.next_holidays'),
            sortable: false,
            value: 'next',
          },
          {
            text: this.$t('globals.created_by'),
            sortable: false,
            value: 'createdBy',
          },
          {
            text: this.$t('globals.updated_by'),
            sortable: false,
            value: 'updatedBy',
          },
          {
            text: this.$t('holidays.last_update'),
            sortable: false,
            value: 'updated_at',
          },
          { 
            text: this.$t('globals.options'),
            value: 'actions', 
            sortable: false 
          },
        ]
        return he
      },
      tableFooter(){
        let t = this.$t("globals.lines_datatable")
        let a = [5, 10, 15, 20];
        return {
          'items-per-page-options': a,
          'items-per-page-text': t
        }
      }, 
      crumbs: function() {
        return [
          {
            text: this.$t('menu.dashboard'),
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: this.$t('menu.holidays_management'),
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  