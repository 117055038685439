import BaseApi from "./BaseApi";

export default class Syncs extends BaseApi{
    construct() {}
 
    static async eticadata(params) {
        let url = Syncs.getApiUrl() + 'tenant-eticadata/populate'

        return await Syncs.getAxiosInstance().post(url, params);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/";
    }

}
