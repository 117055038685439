<template>
    <div>
        <v-tabs :class="!subempreiteiro ? 'elevation-10 d-none' : 'elevation-10'" v-model="tab" :background-color="$root.$vuetify.theme.isDark ? 'secondary' : 'primary'" dark fixed-tabs>
            <v-tab>{{$t('subcontractors.tabs.detail')}}</v-tab>
            <v-tab>{{$t('subcontractors.tabs.effected')}} {{getTotalDone}}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" touchless :class="($root.$vuetify.theme.isDark ? 'secondary' : '') + ' elevation-10'">
            <v-container class="mb-n12">
              <v-tab-item class="mt-6" :key="1">
                  <v-card :class="$root.$vuetify.theme.isDark ? 'secondary elevation-0' : 'elevation-0'">
                      <v-container>
                      <validation-observer ref="form" v-slot="{ invalid }">
                          <v-form @submit.prevent="submit"  class="mb-16">
                          <v-progress-linear v-if="loading"
                              indeterminate
                              class="global-loader"
                              ></v-progress-linear>
                          <fieldset :disabled="loading">
                              <v-row>
                                <v-col cols="12" md="12">
                                    <validation-provider v-slot="{ errors }" vid="nome" :name="$t('globals.name')" rules="required">
                                    <v-text-field
                                        v-model="fields.name"
                                        prepend-inner-icon="mdi-account-details"
                                        :label="$t('globals.name') + '*'"
                                        clearable
                                        :error-messages="errors"
                                    ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="6">
                                <validation-provider v-slot="{ errors }" vid="email" :name="$t('globals.email')" rules="email|required">
                                    <v-text-field
                                        v-model="fields.email"
                                        prepend-inner-icon="mdi-at"
                                        :label="$t('globals.email')+  '*'"
                                        clearable
                                        :error-messages="errors"
                                    ></v-text-field>
                                    </validation-provider>
                                </v-col>   
                                <v-col cols="6">       
                                <validation-provider v-slot="{ errors }" vid="telephone" :name="$t('globals.contact')" rules="">
                                    <v-text-field
                                        v-model="fields.telephone"
                                        prepend-inner-icon="mdi-phone"
                                        :label="$t('globals.contact')"
                                        clearable
                                        :error-messages="errors"
                                    ></v-text-field>
                                    </validation-provider>
                                </v-col>   
                                <v-col cols="12" md="6">
                                  <validation-provider v-slot="{ errors }" vid="nif" :name="$t('globals.nif')" rules="required">
                                    <v-text-field
                                        v-model="fields.nif"
                                        prepend-inner-icon="mdi-barcode"
                                        :label="$t('globals.nif') + '*'"
                                        clearable
                                        :error-messages="errors"
                                    ></v-text-field>
                                  </validation-provider>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <validation-provider v-slot="{ errors }" vid="iban" :name="$t('globals.iban')" rules="required">
                                    <v-text-field
                                        v-model="fields.iban"
                                        prepend-inner-icon="mdi-barcode"
                                        :label="$t('globals.iban') + '*'"
                                        clearable
                                        :error-messages="errors"
                                    ></v-text-field>
                                  </validation-provider>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea v-model="fields.notes" :label="$t('globals.obs')">
                                    </v-textarea>
                                </v-col>   
                                </v-row>
                                <v-row class="mt-6" align="center" justify="space-around">
                                <v-col align="center" justify="space-around">
                                    <v-btn :disabled="invalid" depressed :class="($root.$vuetify.theme.isDark ? 'white primary--text' : 'primary') + ' mr-5 ml-5 mb-5'" type="submit">
                                    {{$t('globals.save')}}
                                    </v-btn>
                                </v-col>
                            </v-row>
                          </fieldset>
                          </v-form>
                          <DialogSuccess :opened="success" @on-ok="onSuccess" >
                              {{$t('subcontractors.save_message')}}
                          </DialogSuccess>
                      </validation-observer>
                      </v-container>
                  </v-card>
              </v-tab-item>
              <v-tab-item class="mt-6" :key="2">
                <v-container fluid class="mb-14">
                  <v-row>
                    <v-col cols="12">
                      <h3 class="primary--text">{{$t('subcontractors.tabs.effected')}}</h3>
                    </v-col>
                    <v-col cols="12">
                        <WorksCarriedOut />
                      </v-col>
                    </v-row>
                  </v-container>
              </v-tab-item>
            </v-container>
        </v-tabs-items>
    </div>
  </template>
  <script>
  import Subempreiteiro from "@/api/Subempreiteiro.js";
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  import WorksCarriedOut from '@/components/works/subempreitadas/WorksCarriedOut.vue'
  
  export default {
    components: {
      DialogSuccess,
      WorksCarriedOut
    },
    props: {
     subempreiteiro: Object,
     method: String,
     disabled: Boolean,
   },
   watch: { 
      subempreiteiro: {
        immediate: true, 
        handler (val) {
          this.fields.theme = this.$themes[0];
  
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;
  
        }
      },
    },
    beforeCreate(){
    },
    data: () => ({
        tab:0,
        checkbox: false,
        fields: {
            name: null,
            email: null,
            telephone: null,
            notes: null,
            nif: null,
            iban: null
        },
        loading:false,
        error: {
            title: '',
            message: '',
        },
        success: false,
    }),
    mounted(){
        
    },
    methods:{
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        let domain = '/' + this.$session.get('domain')
        this.$router.push(domain + '/admin/works-settings/subempreiteiros');
      },
      submit() {
  
        if(this.checkbox == false)
          this.fields.id_company = null
  
        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }
  
          Subempreiteiro[this.method](this.fields, this.fields.id).then(() => {
  
            this.success = true;
  
            this.loading = false;
            
          }).catch(err => {
            this.loading = false;
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = this.$t('globals.error') + " " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })
      }
    },
    computed: {
      getTotalDone(){
        if(this.subempreiteiro == null)
          return '(0)'

        if(this.subempreiteiro == undefined)
          return '(0)'

        return '(' + this.subempreiteiro.all_works.length + ')'
      }
    }
  };
  </script>
  