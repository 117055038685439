import BaseApi from "./BaseApi";

export default class DelayedDiaries extends BaseApi{
    construct() {}
  

    static async search(params) {
        return await DelayedDiaries.getAxiosInstance().get(DelayedDiaries.getFullApiUrl(),{params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/delayed-diaries/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/delayed-diaries/";
    }
}