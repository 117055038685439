<template>
    <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
      <v-container>
        <validation-observer ref="form" v-slot="{ invalid }">
          <v-form @submit.prevent="submit"  class="mb-16" :disabled="method=='update'">
            <v-progress-linear v-if="loading"
                indeterminate
                class="global-loader"
              ></v-progress-linear>
            <fieldset :disabled="loading">
              <v-row>
                <v-col cols="12" md="9">
                    <validation-provider v-slot="{ errors }" vid="work" :name="$t('billings.plan')" rules="required">
                        <v-select
                            v-model="fields.subscriptionType"
                            :items="plans"
                            menu-props="auto"
                            item-text="type"
                            item-value="id"
                            :label="$t('billings.plan')"
                            prepend-inner-icon="mdi-account"
                            :error-messages="errors"
                            @change="changedPlan"
                        ></v-select>
                    </validation-provider>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field 
                        outlined
                        :disabled="true"
                        v-model="fields.price"
                        :label="$t('globals.price')"
                        prepend-inner-icon="mdi-currency-eur"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <validation-provider v-slot="{ errors }" vid="mb_payments" :name="$t('subscription.payment_method')" rules="required">
                        <v-select
                            v-model="fields.paymentMethod"
                            :items="$t('subscription.payments')"
                            menu-props="auto"
                            item-text="name"
                            item-value="id"
                            :label="$t('subscription.payment_method') + ' *'"
                            prepend-inner-icon="mdi-list-box"
                            :error-messages="errors"
                        ></v-select>
                    </validation-provider>
                </v-col>
              </v-row>
              <v-row v-if="method=='create'" class="mt-6" align="center" justify="space-around">
                <v-col align="center" justify="space-around">
                  <v-btn :disabled="invalid" depressed :class="($root.$vuetify.theme.isDark ? 'white primary--text' : 'primary') + ' mr-5 ml-5 mb-5'" type="submit">
                    {{$t('globals.save')}}
                  </v-btn>
                </v-col>
              </v-row>
            </fieldset>
          </v-form>
          <DialogSuccess :opened="success" @on-ok="onSuccess" >
              {{$t('billings.success')}}
          </DialogSuccess>
        </validation-observer>
      </v-container>
      
      <ErrorSnackbar v-model="error.show" :message="error.message"/>

    </v-card>
  </template>
  <script>
  import Billings from "@/api/Billings.js";
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
  
  export default {
    components: {
      DialogSuccess,
      ErrorSnackbar
    },
    props: {
     billing: Object,
     method: String,
     disabled: Boolean,
   },
   watch: { 
        billing: {
            immediate: true, 
            handler (val) {
            this.fields.theme = this.$themes[0];
    
            if(!val) {
                return;
            }
    
            let data = {...val};
    
            this.fields = data;

            this.fields.id = data.id
            this.fields.subscriptionType = data.payment_type
            this.fields.paymentMethod = data.payment_method
            this.fields.price = data.value
    
            if(data.company != null){
                this.checkbox = true
            }
            }
        },
    },
    beforeCreate(){
    },
    data: () => ({
      checkbox: false,
      plans: [],
      fields: {
        id: null,
        subscriptionType: null,
        price: 0
      },
      loading:false,
      error: {
        title: '',
        message: '',
        show: false
      },
      success: false,
    }),
    mounted(){
        Billings.getPlans().then(({data}) => {
            this.plans = data
        });
    },
    methods:{
        changedPlan(i){
            this.plans.forEach(element => {
                if(element.id == i)
                    this.fields.price = element.price
            });
        },
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        let domain = '/' + this.$session.get('domain')
        this.$router.push(domain + '/admin/billings');
      },
      submit() {
  
        this.$refs.form.validate().then(async (result) => {
            this.loading = true;
            
            if(!result){
                this.loading = false;
                return;
            }
    
            await Billings.requestPayment(this.fields).then((resp) => {

                if(this.fields.paymentMethod == 'MBW'){

                    let response_object = JSON.parse(resp.data.msg);
                    if(response_object.transactionStatus == 'Rejected'){
                        this.error.message = response_object.text
                        this.error.show = true
                        return;
                    }
                }

                this.paymentMessage = resp.data

                if(this.fields.paymentMethod == 'CC'){
                    if(resp.data.success){
                        let object = JSON.parse(resp.data.msg)

                        window.open(object.redirectUrl, '_blank')
                    }
                }

                this.success = true;
                })
            })
      }
    },
    computed: {
      
    }
  };
  </script>
  