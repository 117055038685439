<template>
  <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
    <v-container>
    <validation-observer ref="form" v-slot="{ invalid }">
      <v-form @submit.prevent="submit">
         <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
        <fieldset :disabled="loading">
          <v-row>
              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" vid="code" :name="$t('globals.worker')" rules="required">
                  <v-autocomplete
                      v-model="fields.worker_id"
                      :items="workers"
                      item-value="id"
                      :item-text="item => item.name"
                      menu-props="auto"
                      :error-messages="errors"
                      :label="$t('globals.worker') + ' *'"
                      prepend-inner-icon="mdi-account"
                  ></v-autocomplete>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" vid="date" :name="$t('globals.data')" rules="required">
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fields.fault_date"
                          :label="$t('globals.data')"
                          :error-messages="errors"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fields.fault_date"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="2">
                <v-checkbox
                  :color="$root.$vuetify.theme.isDark ? 'white' : 'primary'"
                  v-model="allDay"
                  @change="changedAllDay"
                  :label="$t('faults.all_day')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="4">
                <validation-provider v-slot="{ errors }" vid="total_hours" :name="$t('globals.hours')" rules="required">
                  <v-text-field
                    :disabled="allDay"
                    v-model="fields.total_hours"
                    type="number"
                    prepend-inner-icon="mdi-form-textbox"
                    :label="$t('globals.hours') + '*'"
                    clearable
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="4">
                <validation-provider v-slot="{ errors }" vid="justified" :name="$t('faults.justify')" rules="required">
                  <v-checkbox
                  :color="$root.$vuetify.theme.isDark ? 'white' : 'primary'"
                  v-model="fields.justified"
                  :label="$t('faults.justify')"
                  :error-messages="errors"
                ></v-checkbox>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="12">
                <v-textarea
                  outlined
                  v-model="fields.obs"
                  :label="$t('faults.reason')"
                  prepend-inner-icon="mdi-card-text"
                  clearable
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mt-6" align="center" justify="space-around">
              <v-col align="center" justify="space-around">
                <v-btn :disabled="invalid" depressed :class="($root.$vuetify.theme.isDark ? 'white primary--text' : 'primary') + ' mr-5 ml-5 mb-5'" type="submit">
                  {{$t('globals.save')}}
                </v-btn>
              </v-col>
            </v-row>

        </fieldset>
      </v-form>
      <DialogSuccess :opened="success" @on-ok="onSuccess" >
          {{$t('faults.success_add')}}
      </DialogSuccess>
    <ErrorSnackbar v-model="error.show" :message="error.message"/>
    </validation-observer>
    </v-container>
    </v-card>
  </template>
  <script>
    import Configurations from "@/api/Configurations.js";
    import Faults from "@/api/Faults.js";
    import DialogSuccess from '@/components/ui/DialogSuccess.vue';
    import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
    import Worker from "@/api/Worker.js";
  
  export default {
    components: {
      DialogSuccess,
      ErrorSnackbar
    },
    props: {
     fault: Object,
     method: String,
     disabled: Boolean,
   },
   watch: { 
    fault: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;

        }
      },
    },
    async mounted(){
      this.fillBaseData()
      let max_hours = 8
      await Configurations.get(this.$session.get('domain')).then((resp) => {
        let data = resp.data.data

        max_hours = Configurations.getInstance(data).work_hours
      })

      if(this.$route.name == 'create')
        this.fields.total_hours = max_hours
      else
        if(this.fields.total_hours == max_hours)
          this.allDay = true
        else this.allDay = false
    },
    data: () => ({
        allDay: true,
        menu: false,
        fields: {
          worker_id: null,
          fault_date: null,
          total_hours: null,
          justified: false,
          obs: ''
        },
        loading:false,
        error: {
            title: '',
            message: '',
            show: false,
        },
        success: false,
        workers: [],
    }),
    methods:{
        changedAllDay(value){
          if(value)
            this.fields.total_hours = this.$root.configurations.work_hours
        },
        async fillBaseData(){
            let parent = this
            this.loading = true
            await Worker.list().then(({data}) => {
                parent.workers = data;
            });
            this.loading = false
        },
        onSuccess() {
            if(this.fields.id != null) {
            this.success = false
            return
            }
            let domain = '/' + this.$session.get('domain')
            this.$router.push(domain + '/admin/workers-settings/faults');
        },
        submit() {
    
            this.$refs.form.validate().then((result) => {
            this.loading = true;
            
            if(!result){
                this.loading = false;
                return;
            }

    
            Faults[this.method](this.fields, this.fields.id).then(() => {
    
                this.success = true;
    
                this.loading = false;
                
            }).catch(err => {
                this.loading = false;
    
                if(err.response.status == 422) {
    
                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }
    
                this.error.title = this.$t('globals.error') + " " + err.response.status;
    
                this.error.message = err.response.data.message;
    
                });
            })
        }
        },
        computed: {
            
        }
  };
  </script>
  