<template>
    <v-container>
        <v-card>
            <v-card-title class="mb-12">
                {{$t('rendimento_mo.title')}}
            </v-card-title>
            <v-card-subtitle>
                <v-row>                    
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="search"
                            :label="$t('rendimento_mo.search')"
                            single-line
                            hide-details
                            clearable
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <v-autocomplete                                         
                            v-model="categoryFilter"
                            :items="categories"
                            menu-props="auto"
                            :label="$t('rendimento_mo.category')"
                            item-text="name"
                            item-value="id"
                            persistent-hint
                            :hint="$t('rendimento_mo.category_hint')"
                            prepend-icon="mdi-shape"
                            single-line
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <v-btn @click="searchField" color="success" small class="mt-4 mr-4"><v-icon>mdi-magnify</v-icon></v-btn>
                    </v-col>
                </v-row>                
            </v-card-subtitle>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          class="mt-12"
          locale="pt-pt"
        >
        <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
                {{$t('rendimento_uo.your_search')}} "{{ search }}" {{$t('rendimento_uo.no_found')}}.
            </v-alert>
        </template>
        </v-data-table>
    </v-card>
    </v-container>
  </template>
  
  <script>
import Dashboard from "@/api/Dashboard.js";
import Category from "@/api/Category.js";

  export default {
    data(){
        return{    
            categoryFilter: null,
            categories: [],
            search: '',
            items: [],
            options: {},
            total: 0,
            loading: false,            
        }
    },
    watch: {
        'options': {
            immediate: false,
            handler () {
                setTimeout(() => {this.searchWorkHand()}, 10);
            },
            deep: true,
            },
    },
    async beforeMount() {
        this.loading = true
        

        await this.searchWorkHand()

        this.fillBaseData()
        
        this.loading = false
    },
    computed:{
        headers(){
            return [
                {
                    text: this.$t('globals.code'),
                    value: 'workerObject.code',
                },
                {
                    text: this.$t('globals.worker'),
                    value: 'workerObject.name',
                },
                {
                    text: this.$t('rendimento_mo.category'),
                    value: 'category.name',
                },
                {
                    text: this.$t('rendimento_mo.atual_month'),
                    sortable: true,
                    value: 'performance',
                },
                { 
                    text: this.$t('rendimento_mo.anterior_month'), 
                    sortable: true,
                    value: 'lastMonth' 
                },
                { 
                    text: this.$t('rendimento_mo.atual_year'),  
                    sortable: true,
                    value: 'atualYear.atualYear' 
                },
                { 
                    text: this.$t('rendimento_mo.anterior_year'), 
                    sortable: true  ,
                    value: 'lastYear.lastYear' 
                },
            ]
        },
        tableFooter(){
          let t = this.$t("globals.lines_datatable")
          let a = [5, 10, 15, 20];
          return {
            'items-per-page-options': a,
            'items-per-page-text': t
          }
        }, 
    },
    methods:{
        fillBaseData(){
            Category.list().then(({data}) => {
                this.categories = data;
            });
        },
        async searchField(){
            await this.searchWorkHand()
        },
        async searchWorkHand(event){
            if(!!event && event.type == 'submit'){
                this.options.page = 1;
            }

            let filter = {...this.filter};

            let request = {
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                page: this.options.page,
                limit: this.options.itemsPerPage,
                search: this.search,
                categoryFilter: this.categoryFilter
            };
            Object.assign(request, filter);

            localStorage["rendimentosMO-" + this.$root.session.id] = JSON.stringify({
                filter: this.filter,
                options: this.options,
            });


            await Dashboard.workHand(request).then(({data}) => {
                this.items = data.data
                this.total = data.total
                console.log(this.items)
            });
        },
    },
    mounted(){
    }
  }
          
  
  </script>