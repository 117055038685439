import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Login2Fa from '../views/Login2Fa.vue'
import EmailConfirmation from '../views/EmailConfirmation.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Dashboard from '../views/Dashboard.vue'
import Notifications from '../views/Notifications.vue'
import NotFound from '../views/NotFound.vue'
import InvalidSubscription from '../views/InvalidSubscription.vue'
import Roles from '../views/settings/roles/Roles.vue'
import Works from '../views/works_settings/works/Works.vue'
import WorkCost from '../views/works_settings/costs/WorkCost.vue'
import CreateWork from '../views/works_settings/works/CreateWork.vue'
import UpdateWork from '../views/works_settings/works/UpdateWork.vue'
import Diaries from '../views/diaries/Diaries.vue'
import CreateDiary from '../views/diaries/CreateDiary.vue'
import UpdateDiary from '../views/diaries/UpdateDiary.vue'
import CreateRole from '../views/settings/roles/CreateRole.vue'
import UpdateRole from '../views/settings/roles/UpdateRole.vue'
import Users from '../views/settings/users/Users.vue'
import CreateUser from '../views/settings/users/CreateUser.vue'
import UpdateUser from '../views/settings/users/UpdateUser.vue'
import Machines from '../views/works_settings/machines/Machines.vue'
import CreateMachine from '../views/works_settings/machines/CreateMachine.vue'
import UpdateMachine from '../views/works_settings/machines/UpdateMachine.vue'
import Categories from '../views/workers_settings/categories/Categories.vue'
import CreateCategory from '../views/workers_settings/categories/CreateCategory.vue'
import UpdateCategory from '../views/workers_settings/categories/UpdateCategory.vue'
import LunchHourWorker from '../views/workers_settings/lunch_hour/LunchHours'
import CreateLunchHourWorker from '../views/workers_settings/lunch_hour/CreateLunchHour.vue'
import UpdateLunchHourWorker from '../views/workers_settings/lunch_hour/UpdateLunchHour.vue'

import GlobalConfigurations from '../views/settings/global_configurations/GlobalConfigurations.vue'
import General from '../views/settings/general/General.vue'
import FilesExport from '../views/files/FilesExport.vue'
import HowToDo from '../views/HowToDo.vue'

import Alerts from '../views/settings/Alerts.vue'
import Meals from '../views/settings/Meals.vue'
import Branding from '../views/settings/Branding.vue'
import Schedule from '../views/settings/Schedule.vue'

import Workers from '../views/workers_settings/workers/Workers.vue'
import CreateWorker from '../views/workers_settings/workers/CreateWorker.vue'
import UpdateWorker from '../views/workers_settings/workers/UpdateWorker.vue'
import Units from '../views/works_settings/units/Units.vue'
import CreateUnit from '../views/works_settings/units/CreateUnit.vue'
import UpdateUnit from '../views/works_settings/units/UpdateUnit.vue'
import Layout from '../Layout.vue'
import Logs from '../views/logs/Manage.vue'

import Companies from '../views/companies/Manage.vue'
import Clients from '../views/clients/Clients.vue'
import CreateClient from '../views/clients/CreateClient.vue'
import UpdateClient from '../views/clients/UpdateClient.vue'

import Holidays from '../views/workers_settings/holidays/Holidays.vue'
import CreateHoliday from '../views/workers_settings/holidays/CreateHoliday.vue'
import UpdateHoliday from '../views/workers_settings/holidays/UpdateHoliday.vue'

import Faults from '../views/workers_settings/faults/Faults.vue'
import CreateFault from '../views/workers_settings/faults/CreateFault.vue'
import UpdateFault from '../views/workers_settings/faults/UpdateFault.vue'

import UnitsManagement from '../views/units_management/Manage.vue'

import Materials from '../views/materials/Materials.vue'
import CreateMaterial from '../views/materials/CreateMaterial.vue'
import UpdateMaterial from '../views/materials/UpdateMaterial.vue'

import Indirect from '../views/indirect_work_hand/Indirect.vue'
import CreateIndirect from '../views/indirect_work_hand/CreateIndirect.vue'
import UpdateIndirect from '../views/indirect_work_hand/UpdateIndirect.vue'

import MaterialsComposite from '../views/materials_composite/MaterialsComposite.vue'
import MaterialsCompositeCreate from '../views/materials_composite/CreateMaterialComposite.vue'
import UpdateMaterialComposite from '../views/materials_composite/UpdateMaterialComposite.vue'

import BudgetWorks from '../views/budget/BudgetWorks.vue'
import CreateBudgetWork from '../views/budget/CreateBudgetWork.vue'
import UpdateBudgetWork from '../views/budget/UpdateBudgetWork.vue'

import Shopping from '../views/shopping/Shoppings.vue'
import CreateShopping from '../views/shopping/CreateShopping.vue'
import EditCompany from '../views/companies/EditCompany.vue'

import ShoppingRequest from '../views/shopping_request/ShoppingRequest.vue'
import CreateShoppingRequest from '../views/shopping_request/CreateShoppingRequest.vue'
import UpdateShoppingRequest from '../views/shopping_request/UpdateShoppingRequest.vue'

import ExternalRequest from '../views/external_request/ExternalRequest.vue'
import CreateExternalRequest from '../views/external_request/CreateExternalRequest.vue'
import UpdateExternalRequest from '../views/external_request/UpdateExternalRequest.vue'

import Import from '../views/settings/import/Import.vue'

import Permissions from '../views/settings/permissions/Permissions.vue'

import Subempreiteiros from '../views/subempreiteiros/Subempreiteiros.vue'
import CreateSubempreiteiro from '../views/subempreiteiros/CreateSubempreiteiro.vue'
import UpdateSubempreiteiro from '../views/subempreiteiros/UpdateSubempreiteiro.vue'

import Billings from '@/views/billings/Billings.vue'
import CreateBilling from '../views/billings/CreateBilling.vue'
import UpdateBilling from '../views/billings/UpdateBilling.vue'

import Chapters from '@/views/chapter/Chapters.vue'
import CreateChapter from '../views/chapter/CreateChapter.vue'
import UpdateChapter from '../views/chapter/UpdateChapter.vue'

import ChaptersClass from '@/views/chapter-class/ChaptersClass.vue'
import CreateChapterClass from '../views/chapter-class/CreateChapterClass.vue'
import UpdateChapterClass from '../views/chapter-class/UpdateChapterClass.vue'

import SelfMeasurings from '@/views/self_measuring/SelfMeasurings.vue'
import CreateSelfMeasuring from '@/views/self_measuring/CreateSelfMeasuring.vue'
import UpdateSelfMeasuring from '@/views/self_measuring/UpdateSelfMeasuring.vue'

import InvoicedMenu from '@/views/invoiced/InvoicedMenu.vue'

import TrialOver from '../views/TrialOver.vue'

import Error from '@/views/Error.vue'

import Sync from '@/views/settings/sync/Sync.vue'
import Warehouses from '@/views/warehouses/Warehouses.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Login
    },
    {
        path: '/2fa',
        component: Login2Fa
    },
    {
        path: '/verify-email/:id/:hash',
        component: EmailConfirmation
    },
    {
        path: '/reset/:id/:hash',
        component: ResetPassword
    },
    {
        path: '/:tenant/trial-over',
        component: TrialOver
    },
    {
        path: '/:tenant/error',
        component: Error
    },
    {
        path: '/admin',
        component: Layout,
        children: [
            {
                path: '/:tenant/admin',
                component: Dashboard
            },
            {
                path: '/:tenant/admin/billings',
                component: Billings
            },
            {
                path: '/:tenant/admin/billing/create',
                component: CreateBilling
            },
            {
                path: '/:tenant/admin/billing/:id',
                component: UpdateBilling
            },
            {
                path: '/:tenant/admin/notifications',
                component: Notifications,
            },
            {
                path: '/:tenant/admin/works-settings/subempreiteiros',
                component: Subempreiteiros
            },
            {
                path: '/:tenant/admin/works-settings/subempreiteiros/create',
                component: CreateSubempreiteiro
            },
            {
                path: '/:tenant/admin/works-settings/subempreiteiros/:id',
                component: UpdateSubempreiteiro
            },
            {
                path: '/:tenant/admin/works-settings/machines',
                component: Machines
            },
            {
                path: '/:tenant/admin/works-settings/machines/create',
                component: CreateMachine,
            },
            {
                path: '/:tenant/admin/works-settings/machines/:id',
                component: UpdateMachine,
            },
            {
                path: '/:tenant/admin/elements/units',
                component: Units
            },
            {
                path: '/:tenant/admin/elements/units/create',
                component: CreateUnit,
            },
            {
                path: '/:tenant/admin/elements/units/:id',
                component: UpdateUnit,
            },
            {
                path: '/:tenant/admin/works-settings/works',
                component: Works,
            },
            {
                path: '/:tenant/admin/invoiced/costs',
                component: WorkCost,
            },
            {
                path: '/:tenant/admin/works-settings/works/create',
                component: CreateWork,
            },
            {
                path: '/:tenant/admin/works-settings/works/:id',
                component: UpdateWork,
            },
            {
                path: '/:tenant/admin/exports',
                component: FilesExport
            },
            {
                path: '/:tenant/admin/diaries',
                component: Diaries,
            },
            {
                path: '/:tenant/admin/diaries/search/:code',
                component: Diaries,
            },
            {
                path: '/:tenant/admin/diaries/create',
                component: CreateDiary,
                name: 'create'
            },
            {
                path: '/:tenant/admin/diaries/create/:work_id/:start_date',
                component: CreateDiary,
                name: 'create'
            },
            {
                path: '/:tenant/admin/diaries/:id',
                component: UpdateDiary,
            },            
            {
                path: '/:tenant/admin/companies',
                component: Companies,
            },
            {
                path: '/:tenant/admin/clients',
                component: Clients,
            },
            {
                path: '/:tenant/admin/clients/create',
                component: CreateClient,
            },
            {
                path: '/:tenant/admin/clients/:id',
                component: UpdateClient,
            },
            {
                path: '/:tenant/admin/workers-settings/workers',
                component: Workers
            },
            {
                path: '/:tenant/admin/workers-settings/workers/create',
                component: CreateWorker,
            },
            {
                path: '/:tenant/admin/workers-settings/workers/create/:data',
                component: CreateWorker,
            },
            {
                path: '/:tenant/admin/workers-settings/workers/:id',
                component: UpdateWorker,
            },
            {
                path: '/:tenant/admin/workers-settings/categories',
                component: Categories
            },
            {
                path: '/:tenant/admin/workers-settings/categories/create',
                component: CreateCategory,
            },
            {
                path: '/:tenant/admin/workers-settings/categories/:id',
                component: UpdateCategory,
            },
            {
                path: '/:tenant/admin/workers-settings/lunch-hour',
                component: LunchHourWorker
            },
            {
                path: '/:tenant/admin/workers-settings/lunch-hour/create',
                component: CreateLunchHourWorker
            },
            {
                path: '/:tenant/admin/workers-settings/lunch-hour/:id',
                component: UpdateLunchHourWorker
            },
            {
                path: '/:tenant/admin/accesses/roles',
                component: Roles,
            },
            {
                path: '/:tenant/admin/accesses/roles/create',
                component: CreateRole,
            },
            {
                path: '/:tenant/admin/accesses/roles/:id',
                component: UpdateRole,
            },
            {
                path: '/:tenant/admin/accesses/users',
                component: Users
            },
            {
                path: '/admin/my-user/*',
                name: 'my-profile',
                component: UpdateUser
            },
            {
                path: '/:tenant/admin/logs',
                component: Logs
            },
            {
                path: '/:tenant/admin/how-to-do',
                component: HowToDo
            },
            {
                path:   '/:tenant/admin/settings/general',
                component: General
            },
            {
                path:   '/:tenant/admin/settings/global_configurations',
                component: GlobalConfigurations
            },
            {
                path :'/:tenant/admin/settings/Warehouses',
                component: Warehouses
            },
            {
                path: '/:tenant/admin/accesses/users/create',
                component: CreateUser,
            },
            {
                path: '/:tenant/admin/accesses/users/create/:data',
                component: CreateUser,
            },
            {
                path: '/:tenant/admin/accesses/users/:id',
                component: UpdateUser,
            },
            {
                path: '/:tenant/admin/workers-settings/holidays',
                component: Holidays
            },
            {
                path: '/:tenant/admin/workers-settings/holidays/create',
                component: CreateHoliday,
            },
            {
                path: '/:tenant/admin/workers-settings/holidays/:id',
                component: UpdateHoliday,
            },
            {
                path: '/:tenant/admin/workers-settings/faults',
                component: Faults,
            },
            {
                path: '/:tenant/admin/workers-settings/faults/create',
                name: 'create',
                component: CreateFault,
            },
            {
                path: '/:tenant/admin/workers-settings/faults/:id',
                name: 'update',
                component: UpdateFault,
            },
            {
                path: '/:tenant/admin/elements/chapters',
                component: Chapters
            },
            {
                path: '/:tenant/admin/elements/chapter/create',
                component: CreateChapter
            },
            {
                path: '/:tenant/admin/elements/chapter/:id',
                component: UpdateChapter
            },
            {
                path: '/:tenant/admin/elements/chapters-class',
                component: ChaptersClass
            },
            {
                path: '/:tenant/admin/elements/chapter-class/create',
                component: CreateChapterClass
            },
            {
                path: '/:tenant/admin/elements/chapter-class/:id',
                component: UpdateChapterClass
            },
            {
                path: '/:tenant/admin/settings/alerts',
                component: Alerts,
            },
            {
                path: '/:tenant/admin/settings/meals',
                component: Meals,
            },
            {
                path: '/:tenant/admin/settings/branding',
                component: Branding,
            },
            {
                path: '/:tenant/admin/settings/schedule',
                component: Schedule,
            },
            {
                path: '/:tenant/admin/settings/import',
                component: Import
            },
            {
                path: '/:tenant/admin/budgeting/units_management',
                component: UnitsManagement
            },
            {
                path: '/:tenant/admin/budgeting/materials',
                component: Materials
            },
            {
                path: '/:tenant/admin/budgeting/materials/create',
                component: CreateMaterial
            },
            {
                path: '/:tenant/admin/budgeting/materials/:id',
                component: UpdateMaterial
            },
            {
                path: '/:tenant/admin/budgeting/indirect_work_hand',
                component: Indirect
            },
            {
                path: '/:tenant/admin/budgeting/indirect_work_hand/create',
                component: CreateIndirect
            },
            {
                path: '/:tenant/admin/budgeting/indirect_work_hand/:id',
                component: UpdateIndirect
            },
            {
                path: '/:tenant/admin/budgeting/materials-composite',
                component: MaterialsComposite
            },
            {
                path: '/:tenant/admin/budgeting/materials-composite/create',
                component: MaterialsCompositeCreate
            },
            {
                path: '/:tenant/admin/budgeting/materials-composite/:id',
                component: UpdateMaterialComposite
            },
            {
                path: '/:tenant/admin/budgeting/works',
                component: BudgetWorks
            },
            {
                path: '/:tenant/admin/budgeting/budget/create',
                component: CreateBudgetWork
            },
            {
                path: '/:tenant/admin/budgeting/budget/:id',
                component: UpdateBudgetWork
            },
            {
                path: '/:tenant/admin/shopping',
                component: Shopping
            },
            {
                path: '/:tenant/admin/shopping/create',
                component: CreateShopping
            },
            {
                path: '/:tenant/admin/shopping/supplier/:id',
                component: EditCompany
            },
            {
                path: '/:tenant/admin/shopping-request',
                component: ShoppingRequest
            },
            {
                path: '/:tenant/admin/shopping-request/create',
                component: CreateShoppingRequest,
                name: 'shopping-request'
            },
            {
                path: '/:tenant/admin/shopping-request/:id',
                component: UpdateShoppingRequest,
                name: 'shopping-request'
            },
            {
                path: '/:tenant/admin/external-request',
                component: ExternalRequest
            },
            {
                path: '/:tenant/admin/external-request/create/:budget_work_id',
                component: CreateExternalRequest,
                name: 'external-request'
            },
            {
                path: '/:tenant/admin/external-request/:id',
                component: UpdateExternalRequest,
                name: 'external-request'
            },
            {
                path: '/:tenant/admin/permissions',
                component: Permissions,
                name: 'permissions-list'
            },
            {
                path: '/:tenant/admin/invoiced/self-measuring/:work_id',
                component: CreateSelfMeasuring,
                name: 'CreateSelfMeasuring'
            },
            {
                path: '/:tenant/admin/invoiced/self-measuring/:work_id/:id',
                component: UpdateSelfMeasuring,
                name: 'UpdateSelfMeasuring'
            },
            {
                path: '/:tenant/admin/invoiced/self-measurings',
                component: SelfMeasurings,
                name: 'SelfMeasurings'
            },
            {
                path: '/:tenant/admin/invoiced/self-measurings/client/:client_id',
                component: SelfMeasurings,
                name: 'SelfMeasurings'
            },
            {
                path: '/:tenant/admin/invoiced',
                component: InvoicedMenu,
                name: 'InvoicedMenu'                
            },
            {
                path: '/:tenant/admin/syncs',
                component: Sync,
                name: 'Sync'                
            }
        ]
    },
    { path: '*/invalid', component: InvalidSubscription},
    { path: '*', component: NotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
