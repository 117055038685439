var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mt-16"},[(this.$root.$vuetify.theme.isDark)?_c('v-breadcrumbs',{class:this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0',attrs:{"large":"","items":_vm.crumbs,"divider":"/","light":""}}):_c('v-breadcrumbs',{class:this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0',attrs:{"items":_vm.crumbs,"divider":"/","light":""}}),_c('h1',{staticClass:"primary--text"},[_c('rp-icon',{staticClass:"mr-2",attrs:{"icon":"rp-diagram-blue-filled"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('menu.material_composite_management')))])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('rp-icon',{staticClass:"mb-n2",attrs:{"icon":"rp-eye-blue-filled"}}),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"secondary","small":""}},[_vm._v("mdi-forward")]),_c('span',{class:this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'},[_vm._v(_vm._s(_vm.$t('globals.visualize')))])],1),_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-icon',{staticClass:"ml-4",attrs:{"color":"red"}},[_vm._v(" mdi-delete ")]),_c('v-icon',{staticClass:"ml-2",attrs:{"small":"","color":"secondary"}},[_vm._v("mdi-forward")]),_c('span',{class:this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'},[_vm._v(_vm._s(_vm.$t('globals.remove')))])],1)],1),_c('div',{staticStyle:{"position":"relative"}},[_c('v-container',[_c('v-speed-dial',{attrs:{"absolute":"","top":"","right":"","direction":"bottom","open-on-hover":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","x-large":"","fab":""},model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},'v-btn',attrs,false),on),[(_vm.speedDial)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('globals.options')))])])]},proxy:true}]),model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},[(false)?_c('v-tooltip',{attrs:{"right":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"info","large":""},on:{"click":function($event){_vm.searching = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}],null,false,1260705035)},[_c('span',[_vm._v(_vm._s(_vm.$t('globals.search')))])]):_vm._e(),_c('v-tooltip',{attrs:{"right":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$root.session.hasPermission(['super', 'materials_composite.create']))?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"secondary","large":"","to":'/' + _vm.$session.get('domain') + '/admin/budgeting/materials-composite/create'}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('globals.register')))])])],1)],1),_c('v-data-table',{class:this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16',style:(this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'),attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.tableFooter,"options":_vm.options,"locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.client != null ? item.client.name : '')+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.status != null ? _vm.status.find(function (i) { return i.id == item.status; }).name : "-")+" ")]}},{key:"item.started_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTableDate(item.started_at))+" ")]}},{key:"item.finished_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTableDate(item.finished_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[(_vm.$root.session.hasPermission(["super", "materials_composite.update"]))?_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('IconView',{attrs:{"small":""},on:{"click":function($event){return _vm.viewMaterialComposite(item)}}}),(!item.has_diaries && _vm.$root.session.hasPermission(['super', 'materials_composite.delete']))?_c('IconRemove',{on:{"on-submit":function($event){return _vm.deleteMaterialComposite(item)}}}):_vm._e()],1):_vm._e()],1)]}}])})],1),_c('v-navigation-drawer',{staticClass:"pt-6",attrs:{"fixed":"","right":"","hide-overlay":"","width":"520"},model:{value:(_vm.searching),callback:function ($$v) {_vm.searching=$$v},expression:"searching"}},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"fab":"","small":"","color":"secondary"},on:{"click":function($event){_vm.searching = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-btn',{attrs:{"absolute":"","right":"","small":"","color":"error"},on:{"click":_vm.resetMaterialsComposite}},[_vm._v(" "+_vm._s(_vm.$t('globals.clean_fields'))+" ")])],1)],1),_c('v-list',{staticClass:"pt-10"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchMaterials.apply(null, arguments)}}},[_c('v-row',{staticClass:"mt-10",attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('globals.search'))+" ")])],1)],1)],1)],1),_c('ErrorSnackbar',{attrs:{"message":_vm.error.message},model:{value:(_vm.error.show),callback:function ($$v) {_vm.$set(_vm.error, "show", $$v)},expression:"error.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }