<template>
    <section class="mt-16">
      <div v-if="page">
      <v-breadcrumbs
          v-if="this.$root.$vuetify.theme.isDark"
          large
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
        <v-breadcrumbs
          v-else
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <rp-icon icon="rp-shield-blue-filled" class="mr-2"/> <span>{{$t('menu.chapter_class')}}</span>
        </h1>
      </div>
        <v-row v-if="page">
          <v-col cols="12" md="auto">
            <rp-icon
                icon="rp-eye-blue-filled"
                class="mb-n2"
              /><v-icon class="ml-2" color="secondary" small>mdi-forward</v-icon>
              <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.visualize')}}</span>
          </v-col>
          <v-col cols="12" md="auto">
            <v-icon color="red" class="ml-4">
              mdi-delete
            </v-icon><v-icon class="ml-2" small color="secondary">mdi-forward</v-icon>
            <span :class="this.$root.$vuetify.theme.isDark ? 'primary--text text-caption' : 'primary--text text-caption'">{{$t('globals.remove')}}</span>
          </v-col>
        </v-row>
        
  
        
  
        <div style="position:relative">
          <v-container>
            <v-speed-dial
              absolute
              v-model="speedDial"
              top
              right
              direction="bottom"
              :open-on-hover="true"
            >
              <template v-slot:activator>
                <v-tooltip right color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" v-on="on"
                      v-model="speedDial"
                      color="primary"
                      dark
                      x-large
                      fab
                    >
                      <v-icon v-if="speedDial">
                        mdi-close
                      </v-icon>
                      <v-icon v-else>
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('globals.options')}}</span>
                </v-tooltip>
              </template>
              <v-tooltip right color="info">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="info"
                    large
                    @click="searching = true"
                  >
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('globals.search')}}</span>
              </v-tooltip>
              <v-tooltip right color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="secondary"
                    large
                    :to="'/' + $session.get('domain') + '/admin/elements/chapter-class/create'"
                    v-if="$root.session.hasPermission(['super', 'class.create'])"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('globals.register')}}</span>
              </v-tooltip>
            </v-speed-dial>
          </v-container>
  
          <v-data-table 
            :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'"
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
            locale="pt-pt"
          >
  
            <template v-slot:item.total="{ item }">
              {{ item.units == null ? 0 : item.units.length}}
            </template>

            <template v-slot:item.chapter_description="{ item }">
              {{ item.chapter == null ? '-' : item.chapter.description}}
            </template>

          
  
            <template v-slot:item.actions="{item}">
              <v-row>
                <v-col cols="12" md="auto"
                    v-if='$root.session.hasPermission(["super", "class.update"])'>
                  <IconView
                    small
                    @click="viewChapterClass(item)"
                  />
  
                  
                  <IconRemove
                    v-if="$root.session.hasPermission(['super', 'class.delete']) && item.units.length == 0"
                    @on-submit="deleteChapterClass(item)"
                  />
                </v-col>
              </v-row>
            </template>
  
           
          </v-data-table>
        </div>
        <v-navigation-drawer
            fixed
            right
            v-model="searching"
            hide-overlay
            width="520"
            class="pt-6"
          >
          <v-list>
            <v-list-item>
              <v-btn fab small color="secondary" @click="searching = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
  
              <v-btn absolute right small color="error" @click="resetChapterClass">
                {{$t('globals.clean_fields')}}
              </v-btn>
            </v-list-item>
          </v-list>
          <v-list class="pt-10">
            <v-form @submit.prevent="searchChapterClass">
              
              <v-list-item>
                <v-text-field
                  v-model="filter.code"
                  prepend-inner-icon="mdi-barcode"
                  :label="$t('globals.code')"
                  clearable
                ></v-text-field>
              </v-list-item>

              <v-list-item>
                <v-text-field
                  v-model="filter.description"
                  prepend-inner-icon="mdi-barcode"
                  :label="$t('globals.description')"
                  clearable
                ></v-text-field>
              </v-list-item>

              <v-list-item>
                <v-autocomplete
                  v-model="filter.chapter_id"
                  :items="chapters_array"
                  item-value="id"
                  item-text="description"
                  prepend-inner-icon="mdi-barcode"
                  :label="$t('globals.chapter')"
                  clearable
                ></v-autocomplete>
              </v-list-item>
  
              <v-row
                class="mt-10"
                align="center"
                justify="space-around"
              >
              <v-btn
                color="primary"
                type="submit"
              >
              {{$t('globals.search')}}
              </v-btn>
              </v-row>
            </v-form>
          </v-list>
        </v-navigation-drawer>
  
        
        <ErrorSnackbar v-model="error.show" :message="error.message"/>
    </section>
  </template>
  <script>
  import IconRemove from '@/components/ui/IconRemove.vue';
  import IconView from '@/components/ui/IconView.vue';
  import ChapterClassAPI from "@/api/ChapterClass.js";
  import dayjs from 'dayjs';
  import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
  import Chapter from '@/api/Chapter.js'
  
  export default {
    components: {
      IconRemove,
      IconView,
      ErrorSnackbar,
    },
    props:{
      page: {
        typeof: Boolean,
        default: true
      },
      chapter_id:{
        typeof: String,
        default: null
      }
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchChapterClass()}, 10);
        },
        deep: true,
      },
    },
    beforeCreate(){
      document.title = "Report Lake - " + this.$t('menu.chapter_class');
    },
    data: () => ({
      error: {
        title: '',
        message: '',
        show:false,
      },
      chapters_array: [],
      items: [],
      total: 0,
      loading: false,
      searching: false,
      options: {},
      speedDial:false,
      filter: {
        chapter_id: null,
        code: null,
        description: null
      },
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "class.read"])) {
        this.$router.push('/admin');
      }
  
  
      this.fillBaseData();
    },
    methods:{
      getTableDate(date) {
        return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
      },
      fillBaseData() {
  
        if(localStorage["chapters-class-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["chapters-class-" + this.$root.session.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }

        Chapter.list().then(({data}) => {
            this.chapters_array = data 
        });
  
        
      },
      searchChapterClass(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;

        if(this.chapter_id != null){
          this.filter.chapter_id = this.chapter_id
        }
  
        let filter = {...this.filter};
  
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };
      
        Object.assign(request, filter);
  
        localStorage["chapters-class-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        ChapterClassAPI.search(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });
  
          this.loading = false;
  
        });
      },
      resetChapterClass()
      {
        this.filter = {
          is_active: 1
        };
  
        this.searchChapterClass();
      },
      viewChapterClass(item){
        let domain = '/' + this.$session.get('domain')
        let path = domain + '/admin/elements/chapter-class/' + item.id
  
  
        this.$router.push(path);
      },
      deleteChapterClass(item){
        
        ChapterClassAPI.delete(item.id)
          .then(() => {
            this.searchChapterClass();
          });
      },
    },
    computed: {
      headers(){
        let he = [              
          {
            text: this.$t('globals.code'),
            sortable: true,
            value: 'code'
          },     
          {
            text: this.$t('globals.description'),
            sortable: true,
            value: 'description'
          },        
          {
            text: this.$t('globals.chapter'),
            sortable: true,
            value: 'chapter_description'
          },      
          {
            text: this.$t('chapter_class.unit_total'),
            sortable: true,
            value: 'total'
          },
          {
            text: this.$t('globals.created_at'),
            sortable: true,
            value: 'created_at',
          },
          { 
            text: this.$t('globals.options'),
            value: 'actions', 
            width: '200px',
            sortable: false 
          },
        ]
        return he
      },
      tableFooter(){
        let t = this.$t("globals.lines_datatable")
        let a = [5, 10, 15, 20];
        return {
          'items-per-page-options': a,
          'items-per-page-text': t
        }
      }, 
      crumbs: function() {
        return [
          {
            text: this.$t('menu.dashboard'),
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: this.$t('menu.chapter_class'),
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  