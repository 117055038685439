<template>
    <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
      <v-container>
    <validation-observer ref="form" v-slot="{ invalid }">
      <v-form @submit.prevent="submit"  class="mb-16">
         <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
        <fieldset :disabled="loading">
          <v-row>
            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" vid="code" :name="$t('globals.code')" rules="required">
                <v-text-field
                  v-model="fields.code"
                  outlined
                  dense
                  prepend-inner-icon="mdi-code-array"
                  :label="$t('globals.code') + '*'"
                  clearable
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6" v-if="!quick">
              <v-autocomplete
                  v-model="fields.chapter_id"
                  dense
                  outlined
                  :items="chapters"
                  :item-text="item => item.code == null ? item.description : item.code + ' - ' + item.description"
                  item-value="id"
                  menu-props="auto"
                  :label="$t('globals.chapter')"
                  prepend-inner-icon="mdi-account"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" :md="quick ? '12' : '12'">
              <validation-provider v-slot="{ errors }" vid="description" :name="$t('globals.description')" rules="required">
                <v-textarea
                  dense
                  outlined
                  v-model="fields.description"
                  prepend-inner-icon="mdi-form-textbox"
                  :label="$t('globals.description') + '*'"
                  clearable
                  :error-messages="errors"
                ></v-textarea>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="auto" v-if="false">
              <v-btn small class="primary" @click="createUnit = true;">
                {{$t('chapter_class.add_unit')}}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                      <tr>
                          <th class="text-center">
                          {{$t('chapter_class.unit_work_description')}}
                          </th>
                          <th class="text-center">
                          {{$t('globals.price')}}
                          </th>
                          <th class="text-center">
                          {{$t('globals.unit')}}
                          </th>
                          <th class="text-center">
                          {{$t('globals.options')}}
                          </th>
                      </tr>
                    </thead>
                    <tbody :class="$root.$vuetify.theme.isDark ?  'secondary' : ''">
                      <tr v-for="(element, index) in fields.units" v-bind:key="index + 40000">
                          <td class="text-center">
                              <label v-if="!element.id.includes('new')">{{element.code}} - {{element.name}}</label>
                              <v-autocomplete
                                v-else
                                v-model="element.unit_id"
                                :items="units"
                                :item-text="item => item.code + ' - ' + item.name"
                                class="mt-4 mb-n4"
                                item-value="id"
                                menu-props="auto"
                                :label="$t('globals.description')"
                                prepend-inner-icon="mdi-account"
                                @input="changeValue(element)"
                            ></v-autocomplete>
                          </td>
                          <td class="text-center">
                            <label>{{$currency(element.value)}}</label>
                          </td>
                          <td class="text-center">
                            <label>{{element.unit}}</label>
                          </td>
                          <td class="text-center">
                            <IconRemove
                                @on-submit="deleteUnit(element)"
                            />
                          </td>
                      </tr>
                    </tbody>
                </template>
                </v-simple-table>
                <!-- DESKTOP VERSION -->
            </v-col>
            <v-col cols="12" align="center">
              <v-btn fab small class="success" @click="addLine">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-6" align="center" justify="space-around">
            <v-col align="center" justify="space-around">
              <v-btn :disabled="invalid" depressed :class="($root.$vuetify.theme.isDark ? 'white primary--text' : 'primary') + ' mr-5 ml-5 mb-5'" type="submit">
                {{$t('globals.save')}}
              </v-btn>
            </v-col>
          </v-row>
        </fieldset>
      </v-form>
      <DialogSuccess :opened="success" @on-ok="onSuccess" >
          {{$t('chapter.success_save')}}
      </DialogSuccess>
    </validation-observer>
    </v-container>

    <v-dialog v-model="createUnit"
      max-width="850">

      <v-card class="elevation-0">
        <v-card-title class="primary white--text">
          {{$t('chapter_class.create_new')}}
        </v-card-title>
        <v-card-text>
          <AddUnit :reload="false" @close-update="createUnit=false; fillBaseData()" :quick="true" class="elevation-0" method="create" />
        </v-card-text>
      </v-card>

    </v-dialog>

    </v-card>
  </template>
  <script>
  import ChapterClassAPI from "@/api/ChapterClass.js";
  import Chapter from "@/api/Chapter.js"
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  import Unit from '@/api/Unit.js'
  import IconRemove from '@/components/ui/IconRemove.vue';
  import AddUnit from '@/components/units/Unit.vue'
  
  export default {
    components: {
      DialogSuccess,
      IconRemove,
      AddUnit
    },
    props: {
     chapter_class: Object,
     method: String,
     disabled: Boolean,
     quick:{
      typeof:Boolean,
      default: false
     }
   },
   watch: { 
      chapter_class: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;
        }
      },
    },
    beforeCreate(){
    },
    mounted(){
        this.fillBaseData()
    },
    data: () => ({
      chapters: [],
      createUnit: false,
      units: [],
      fields: {
        id: null,
        code: null,
        description: null,
        unit_id: null,
        units: []
      },
      loading:false,
      error: {
        title: '',
        message: '',
      },
      success: false,
    }),
    methods:{
      changeValue(event){
        this.units.forEach(element => {
          if(element.id == event.unit_id){
            event.value = element.value
            event.unit = element.unit
          }
        });
      },
      deleteUnit(item){
        let pos = -1
        let i = 0;

        this.fields.units.forEach(element => {
          if(element.id == item.id)
            pos = i

            i++
        });

        if(pos != -1){
          this.fields.units.splice(pos, 1)
        }
      },
      addLine(){
        this.fields.units.push({
          id: 'new-' + new Date().getTime(),
          description: null,
          newLine: true
        })
      },
      fillBaseData(){
          Chapter.list().then(({data}) => {
              this.chapters = data
          });

          Unit.list().then(({data}) =>  {
            this.units = data
          })
      },
      onSuccess() {
          if(this.fields.id != null) {
            this.success = false
            return
          }
          if(this.quick){
            this.$emit('close')
            return;
          }
          let domain = '/' + this.$session.get('domain')
          this.$router.push(domain + '/admin/elements/chapters-class');
      },
      submit() {
  
          this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
              this.loading = false;
              return;
          }
  
          ChapterClassAPI[this.method](this.fields, this.fields.id).then(() => {
  
              this.success = true;
  
              this.loading = false;

              this.$emit('refresh')
              
          }).catch(err => {
              this.loading = false;
  
              if(err.response.status == 422) {
  
                  this.$refs.form.setErrors(err.response.data.errors);
                  
                  return;
              }
  
              this.error.title = this.$t('globals.error') + " " + err.response.status;
  
              this.error.message = err.response.data.message;
  
              });
          })
      }
    },
    computed: {
    
    }
  };
  </script>
  