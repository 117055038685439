<template>
    <v-card class="elevation-0">
        <v-card-title :class="($root.$vuetify.theme.isDark ? 'warning' : 'primary') + ' white--text elevation-0'">
            {{$t('chapter.chapter')}}
            <v-spacer></v-spacer>
            {{allChapterCost()}}
        </v-card-title>
        <v-card-text>
            <v-expansion-panels flat>
                <v-expansion-panel class="mt-4" v-for="(item, index) in fields.chapters" :key="index">
                    <v-expansion-panel-header :class="$root.$vuetify.theme.isDark ? 'primary' : ''">
                        <template v-slot:actions>
                            <span class="mt-1">{{chapterCost(item)}}</span>
                            <v-btn fab class="primary mt-n1 ml-5" x-small>
                                <v-icon class="icon" color="white">$expand</v-icon>
                            </v-btn>
                        </template>
                        <v-row no-gutters @click.stop=''>
                            <v-col
                                cols="12" md="12"                                
                            >
                                <v-fade-transition leave-absolute>
                                <v-row
                                    no-gutters
                                    style="width: 100%"
                                >
                                    <v-col cols="12" md="12" class="font-weight-bold">
                                        <span class="font-weight-bold text-h6">{{index+1}}. {{ item.description}}</span>
                                        <span v-if="!lock_budget">
                                            <IconRemove
                                                v-if="$root.session.hasPermission(['super', 'budget_work.delete'])"
                                                @on-submit="deleteChapter(item)"
                                            />
                                        </span>
                                    </v-col>
                                </v-row>
                                </v-fade-transition>
                            </v-col>        
                            <v-col cols="12" md="auto" v-if="item.canHaveMargin && !lock_k_fields">
                                <v-checkbox
                                    v-model="item.add_chapter_margin"
                                    :label="$t('global_messages.add_margin')"
                                    @change="changeChapterMargin(item)"
                                ></v-checkbox>
                            </v-col>                            
                            <v-col cols="12" md="2" class="ml-4" v-if="item.canHaveMargin && !lock_k_fields">
                                <v-text-field
                                    v-if="item.add_chapter_margin"
                                    :disabled="lock_budget"
                                    v-model="item.chapter_margin"
                                    prepend-inner-icon="mdi-percent"
                                    :label="$t('globals.margin')"
                                    @change="calculateChapterMargin()"
                                    clearable
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="auto" v-if="!item.canHaveMargin">
                                <v-icon :color="$root.$vuetify.theme.isDark ? 'white' : 'primary'">mdi-sigma</v-icon><span class="ml-4 font-weight-bold">{{$t('globals.average')}} {{$t('globals.margin')}}:</span><span> {{marginAverage(item)}}</span>
                            </v-col>
                        </v-row>                                                     
                    </v-expansion-panel-header>
                    <v-expansion-panel-content style="width: 100% !important;" :class="$root.$vuetify.theme.isDark ? 'secondary mt-n4 ma-0 pa-0' : 'mt-n4 ma-0 pa-0'">
                           <Classes class="ma-0 pa-0" :chapter_index="(index+1)+''" :lock_k_fields="lock_k_fields" :chapter_margin="item.chapter_margin == null ? null : item.chapter_margin+''" @with-margin="withMargin(item)" :canHaveMargin="item.add_chapter_margin" :chapter="item" :fields="fields" :lock_budget="lock_budget" return-object/>    
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-row class="mt-4">
                <v-col cols="12" align="right">
                    <v-btn
                        v-if="!lock_budget"
                        color="warning"
                        @click="chapter_item.chapter_id = null; chapter_item.classe_id = null; addChapter = true;"
                        large
                        fab
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>

        <v-dialog
            v-model="addChapter"
            persistent
            max-width="80%"
            >          
            <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">                                        
                <v-toolbar :class="$root.$vuetify.theme.isDark ? 'secondary' : 'primary white--text'">
                    {{$t('budgeting.add_new_lines.title')}}
                </v-toolbar>
                <v-card-text>
                    <v-progress-linear v-if="loading_units"
                        indeterminate
                    ></v-progress-linear>
                    <v-row class="mt-2">
                        <v-col cols="12" md="6">
                            <v-autocomplete
                                v-model="chapter_item.chapter_id"
                                :items="computeChapters"
                                :item-text="item => item.code == null ? item.description : item.code + ' - ' + item.description"
                                item-value="id"
                                menu-props="auto"
                                :label="$t('globals.chapter')"
                                prepend-inner-icon="mdi-account"
                                @change="changeChapter"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-autocomplete
                                :disabled="chapter_item.chapter_id == null"
                                v-model="chapter_item.classe_id"
                                :items="classes"
                                :item-text="item => item.code == null ? item.description : item.code + ' - ' + item.description"
                                item-value="id"
                                menu-props="auto"
                                :label="$t('globals.chapter_class')"
                                prepend-inner-icon="mdi-account"
                                @change="changeClasse"
                            ></v-autocomplete>
                        </v-col>                                
                        <v-dialog
                                v-if="editorModal"
                                v-model="editorModal"
                                max-width="600px"
                                scrollable
                            >
                        <ModalTextArea 
                                        return-object
                                        vid="editor_local" 
                                        :title="$t('globals.free_text')" 
                                        :label="$t('globals.free_text')" 
                                        v-model="localToEdit.local"/>
                        </v-dialog>
                        <v-col cols="12" v-if="chapter_item.chapter_id != null && chapter_item.classe_id != null">
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-center" width="15%">
                                                {{$t('globals.unit')}}
                                            </th>
                                            <th class="text-center" width="35%">
                                                {{$t('globals.description')}}
                                            </th>
                                            <th class="text-center" width="10%">
                                                {{$t('globals.unit')}}
                                            </th>
                                            <th class="text-center" width="10%">
                                                {{$t('globals.quantity')}}
                                            </th>
                                            <th class="text-center" width="10%">
                                                {{$t('globals.cost')}} ({{$store.state.coin}})
                                            </th>
                                            <th class="text-center" width="10%">
                                                {{$t('budgeting.add_new_lines.local')}}
                                            </th>
                                            <th class="text-center" width="10%">
                                                {{$t('budgeting.add_new_lines.select')}}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="item in computeUnits"
                                            :key="item.id"
                                        >
                                        <td class="text-center" width="15%">{{ item.name }}</td>
                                        <td class="text-center" width="35%">{{ item.description }}</td>
                                        <td class="text-center" width="10%">{{ item.unit }}</td>
                                        <td class="text-center" width="10%">
                                            <v-text-field 
                                                class="mt-4"
                                                dense
                                                outlined
                                                v-model="item.qty"
                                                @keyup="changedItemQuantity(item)"
                                            />
                                        </td>
                                        <td class="text-center" width="10%">{{ item.value.toFixed(2) }}{{$store.state.coin}}</td>
                                        <td class="text-center" width="10%">
                                            <v-text-field 
                                                class="mt-4"
                                                dense
                                                outlined
                                                v-model="item.local"
                                                @click="localToEdit = item; editorModal = true;"
                                            />
                                        </td>
                                        <td align="center" width="10%" class="text-center justify-center text-center">
                                            <v-row align="center" justify="center">
                                                <v-col cols="auto">
                                                    <span>
                                                        <v-switch
                                                            color="success"
                                                            v-model="item.selected"
                                                        ></v-switch>
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>                                            
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn :disabled="chapter_item.chapter_id == null" color="success" @click="addNewItem">{{$t('globals.insert')}}</v-btn>
                    <v-btn color="error" right @click="addChapter = false">{{$t('globals.close')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <SuccessSnackBar v-model="modalSuccess.show" :message="modalSuccess.message" />
        <ErrorSnackbar v-model="error.show" :message="error.message"/>

    </v-card>
</template>
<script>
import Classes from "@/components/budget/components/Classes.vue"
import ChapterAPI from "@/api/Chapter.js"
import IconRemove from "@/components/ui/IconRemove"
import UnitAPI from "@/api/Unit.js"
import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
import ModalTextArea from '@/components/ui/ModalTextArea.vue';

export default{
    name:"Chapters",
    components:{
        Classes,
        IconRemove,
        SuccessSnackBar,
        ErrorSnackbar,
        ModalTextArea
    },
    props:{
        fields: Object,
        lock_budget: Boolean,
        lock_k_fields: Boolean
    },
    data(){
        return{
            localToEdit: null,
            editorModal: false,
            more_detailed: false,
            modalSuccess:{
                show: false,
                message: ''
            },
            loading_units: false,
            canHaveMargin: true,
            total_chapter_cost: 0,
            chapter_item: {
                chapter_id: null,
                classe_id: null,
                units_ids: []
            },
            addChapter: false,
            chapters: [],
            classes: [],
            units: [],
            error:{
                message: '',
                show: false
            }
        }
    },
    mounted(){  
        this.fields.chapters.forEach(chapter => {
            let flag = 0;
            chapter.classes.forEach(cl => {
                if(cl.add_chapter_class_margin == true)
                    flag = 1
            });

            if(flag == 1)
                chapter.canHaveMargin = false
            else chapter.canHaveMargin = true
        });

        this.fillBaseData()
    },
    methods:{
        changedItemQuantity(item){
            if(item.qty == null){
                item.selected = false
                return
            }

            if(item.qty == ''){
                item.selected = false
                return
            }
            
            item.selected = true
        },
        addNewItem(){
            let hasSelected = false
            this.units.forEach(element => {
                if(element.selected)
                    hasSelected = true
            });

            if(hasSelected == false){
                this.error.message = this.$t('budgeting.no_selected')
                this.error.show = true
                return
            }

            let i = 0
            let chapter_index = null
            this.fields.chapters.forEach(element => {
                if(element.id == this.chapter_item.chapter_id)
                    chapter_index = i

                i++
            });


            // Capitulo novo e inexistente
            if(chapter_index == null){
                let description = ''
                let chapter_classes = []
                this.chapters.forEach(element => {
                    if(element.id == this.chapter_item.chapter_id){
                        description = element.description
                        chapter_classes = element.chapter_classes
                    }
                });

                this.fields.chapters.push({
                    id: this.chapter_item.chapter_id,
                    description: description,
                    chapter_classes: chapter_classes,
                    classes: [],
                    chapter_margin: null,
                    add_chapter_margin: false,
                    canHaveMargin: true
                })

                chapter_index = this.fields.chapters.length-1
            }


            let classe_index = null
            i = 0;
            this.fields.chapters[chapter_index].classes.forEach(element => {
                if(element.id == this.chapter_item.classe_id)
                    classe_index = i

                i++
            });

            // Classe nova
            if(classe_index == null){
                let description = ''
                this.classes.forEach(element => {
                    if(element.id == this.chapter_item.classe_id){
                        description = element.description
                    }
                });


                this.fields.chapters[chapter_index].classes.push({
                    id: this.chapter_item.classe_id,
                    description: description,
                    units: [],
                    chapter_class_margin: null,
                    add_chapter_class_margin: false
                })

                classe_index = this.fields.chapters[chapter_index].classes.length-1
            }


            this.units.forEach(unit => {

                if(unit.selected){
                    this.fields.chapters[chapter_index].classes[classe_index].units.push({

                        id: unit.id,
                        cost: unit.value,
                        unit_cost: unit.value,
                        qty: unit.qty == null ? 0 : unit.qty,
                        local: unit.local,
                        unit_measure: unit.unit,
                        code: unit.code,
                        name: unit.name,
                        long_description: unit.description,
                        unit: unit.name,
                        manual: false,
                        budget_materials: [],
                        budget_materials_composite: [],
                        budget_machines: [],
                        budget_work_hand: []

                    })
                }

            });            

            this.modalSuccess.message = this.$t('budgeting.add_new_lines.success');
            this.modalSuccess.show = true

            
        },
        changeClasse(){
            this.loading_units = true
            let params = {
                class_id: this.chapter_item.classe_id
            }
            UnitAPI.list(params).then(({data}) => {
                this.units = data.map((item) => {
  
                    let ob = item
                    ob.selected = false
                    ob.local = null
                    ob.qty = null

                    return ob;
                });
                this.loading_units = false
            });
        },
        changeChapter(){
            this.chapter_item.classe_id = null

            this.chapters.forEach(element => {
                if(element.id == this.chapter_item.chapter_id)
                    this.classes = element.chapter_classes
            });
        },
        calculateChapterMargin(){
            
        },
        changeChapterMargin(item){
            if(item.add_chapter_margin == false)
                item.chapter_margin = null
                
            this.$emit('with-margin', item.add_chapter_margin)
        },
        marginAverage(item){
            let i = 0
            let sum = 0
            item.classes.forEach(element => {
                if(element.add_chapter_class_margin == true){
                    if(element.chapter_class_margin != null){
                        sum = sum + parseInt(element.chapter_class_margin)
                        i++
                    }
                }
            });

            if(i <= 0)
                return null;

            return sum / i
        },
        withMargin(item){
            let flag = 0
            item.classes.forEach(element => {
                if(element.add_chapter_class_margin == true)
                    flag = 1
            });

            if(flag == 1)
                item.canHaveMargin = false
            else item.canHaveMargin = true
        },
        deleteChapter(item){
            let pos = -1
            let i = 0;
            this.fields.chapters.forEach(element => {
                if(element.id == item.id){
                    pos = i
                }
                i++
            });

            this.fields.chapters.splice(pos, 1)
        },
        allChapterCost(){
            let total = 0;
            
            this.fields.chapters.forEach(element => {
                total += this.chapterCost(element, true)
            });

            this.$emit('update-budget', total)

            return this.$currency(total);
        },
        chapterCost(item, no_cur = false){
            let total = 0;

            item.classes.forEach(chapter_father => {

                
                let total_aux = 0
                chapter_father.units.forEach(element => {
                    if(element.manual == false){
                        total_aux = total_aux + (parseFloat(element.qty) * parseFloat(element.cost))
                    }else{                        
                        let totalCost = 0
                        
                        element.budget_materials.forEach(ob => {
                            totalCost += (parseFloat(ob.cost) * parseFloat(ob.qty))
                        });

                        element.budget_materials_composite.forEach(ob => {
                            totalCost += (parseFloat(ob.cost) * parseFloat(ob.qty))
                        });

                        element.budget_machines.forEach(ob => {
                            totalCost += (parseFloat(ob.cost) * parseFloat(ob.hours))
                        });

                        element.budget_work_hand.forEach(ob => {
                            totalCost += (parseFloat(ob.cost) * parseFloat(ob.hours))
                        });
                        total_aux = totalCost * parseFloat(element.qty)
                        if(isNaN(total_aux))
                            total_aux = 0    

                        //total_aux = total
                    }
                });


                let margin = 1
                if(chapter_father.chapter_class_margin != null && chapter_father.add_chapter_class_margin == true){
                    margin = parseFloat(chapter_father.chapter_class_margin) / 100
               
                    let increment = total_aux * margin;
                    total_aux = total_aux + increment
                }
                
                total = total + total_aux

                
            });


            let margin = 1
            if(item.chapter_margin != null && item.add_chapter_margin == true){
                margin = item.chapter_margin / 100
                let increment = total * margin;
                total = total + increment
            }
            
            if(no_cur)
                return total;

            if(isNaN(total))
                return '-'

            return this.$currency(total)
        },
        fillBaseData(){
            ChapterAPI.list().then(({data}) => {
                this.chapters = data
            });
        },
        addNewChapter(){
            let description = ''
            let chapter_classes = []
            this.chapters.forEach(element => {
                if(element.id == this.chapter.id){
                    description = element.description
                    chapter_classes = element.chapter_classes
                }
            });
            this.fields.chapters.push({
                id: this.chapter.id,
                description: description,
                chapter_classes: chapter_classes,
                classes: [],
                chapter_margin: null,
                add_chapter_margin: false,
                canHaveMargin: true
            })
        },
    },
    computed:{
        computeChapters(){
            let used_chapters = []
            this.fields.chapters.forEach(element => {
                used_chapters.push(element.id)
            });
            
            let to_use = []
            this.chapters.forEach(element => {
                //if(!used_chapters.includes(element.id))
                    to_use.push(element)
            });
            

            return to_use
        },
        computeUnits(){
            let used_units = []
            this.fields.chapters.forEach(chapter => {
                if(chapter.id == this.chapter_item.chapter_id){
                    chapter.classes.forEach(classe => {
                        if(classe.id == this.chapter_item.classe_id){
                            classe.units.forEach(u => {
                                used_units.push(u.id)
                            });
                        }
                    });
                }          
            });

            let to_use = []
            this.units.forEach(element => {
                if(!used_units.includes(element.id))
                    to_use.push(element)
            });
            return to_use
        }
    }
}
</script>