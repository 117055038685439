var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:_vm.$root.$vuetify.theme.isDark ? 'secondary' : ''},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('fieldset',[_c('v-form',{attrs:{"disabled":_vm.loading},on:{"submit":function($event){$event.preventDefault();_vm.editCompany == null ? _vm.addCompany() : _vm.updateCompany()}}},[_c('v-card-title',{class:_vm.$root.$vuetify.theme.isDark ? 'white--text subtitle-1 primary' : 'white--text subtitle-1 primary'},[_vm._v(" "+_vm._s(_vm.$t('supplier.add_company'))+" ")]),_c('v-card-text',[_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":function($event){_vm.success = false; _vm.$emit('close');}}},[_vm._v(" "+_vm._s(_vm.$t('supplier.company_added'))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"name","name":_vm.$t('globals.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":_vm.$t('globals.name') + ' *',"clearable":"","error-messages":errors},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"nif","name":_vm.$t('globals.nif'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-finance","label":_vm.$t('globals.nif') + ' *',"clearable":"","error-messages":errors},model:{value:(_vm.company.nif),callback:function ($$v) {_vm.$set(_vm.company, "nif", $$v)},expression:"company.nif"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-provider',{attrs:{"vid":"email","name":_vm.$t('globals.email'),"rules":"|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-at","label":_vm.$t('globals.email') + ' *',"clearable":"","error-messages":errors},model:{value:(_vm.company.email),callback:function ($$v) {_vm.$set(_vm.company, "email", $$v)},expression:"company.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"contact","name":_vm.$t('globals.contact'),"rules":"|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-card-account-phone","label":_vm.$t('globals.contact') + ' *',"clearable":"","error-messages":errors},model:{value:(_vm.company.contact),callback:function ($$v) {_vm.$set(_vm.company, "contact", $$v)},expression:"company.contact"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('small',[_vm._v(_vm._s(_vm.$t('supplier.defect_window')))])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","depressed":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('globals.cancel'))+" ")]),_c('v-btn',{class:_vm.$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary',attrs:{"disabled":invalid,"depressed":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('globals.save'))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }