import BaseApi from "./BaseApi";

export default class Shopping extends BaseApi{
    construct() {}
    
 
    static async search(params) {
        return await Shopping.getAxiosInstance().get(Shopping.getFullApiUrl(),{params: params});
    }

    static async create(params) {
        return await Shopping.getAxiosInstance().post(Shopping.getFullApiUrl(),params);
    }

    static async find(id) {
        return await Shopping.getAxiosInstance().get(Shopping.getFullApiUrl() + id);
    }

    static async update(params, id) {
        return await Shopping.getAxiosInstance().post(Shopping.getFullApiUrl() + id,params);
    }

    static async delete(id) {
        return await Shopping.getAxiosInstance().delete(Shopping.getFullApiUrl() + id);
    }
    
    static async list(params) {
        let url = Shopping.getFullApiUrl() + "list";

        return Shopping.getAxiosInstance().get(url,{params: params});
    }

    static async downloadInvoice(id, tenant) {
        let url = Shopping.getFullApiUrl(tenant) + 'download/' + id
        
        return await Shopping.getAxiosInstanceDownload(url)
    }
    
    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/shopping/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/shopping/";
    }
}
