<template>
    <v-card elevation="10" :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75);'" :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
        <v-container>
            <validation-observer ref="form" v-slot="{ invalid }">
            <v-form @submit.prevent="submit"  class="mb-16">
                <v-progress-linear v-if="loading"
                    indeterminate
                    class="global-loader"
                ></v-progress-linear>
                <fieldset :disabled="loading">                              
                  <v-card class="transparent elevation-0">
                    <v-card-title :class="$root.$vuetify.theme.isDark ? 'primary' : 'primary white--text'">
                      {{$t('globals.indirect_work_hand')}}
                    </v-card-title>
                    <v-row class="mt-2">
                        <v-col cols="12" md="12">
                            <validation-provider v-slot="{ errors }" vid="description" :name="$t('globals.description')" rules="required">
                                <v-text-field
                                v-model="fields.description"
                                prepend-inner-icon="mdi-account-details"
                                :label="$t('globals.description') + ' *'"
                                clearable
                                :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="3">
                            <validation-provider v-slot="{ errors }" vid="cost" :name="$t('globals.cost')" rules="required">
                              <v-text-field
                                v-model="fields.cost"
                                prepend-inner-icon="mdi-account-details"
                                :label="$t('globals.cost') + ' *'"
                                clearable
                                :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="5">
                            <validation-provider v-slot="{ errors }" vid="company" :name="$t('globals.supplier')" rules="|">
                              <v-select
                                    v-model="fields.company_id"
                                    :items="companies"
                                    item-value="id"
                                    :item-text="item => item.name"
                                    menu-props="auto"
                                    :error-messages="errors"
                                    :label="$t('globals.supplier')"
                                    prepend-inner-icon="mdi-account"
                                ></v-select>
                            </validation-provider>
                        </v-col>                        
                        <v-col cols="12" md="1">
                          <Tooltip
                              color="primary"
                              :text="$t('indirect_work_hand.add_new_supplier')"
                              bottom
                          >
                            <v-btn :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary'" fab small @click="addCompany = true">
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </Tooltip>
                        </v-col>                        
                        <v-col cols="12" md="3">
                            <validation-provider v-slot="{ errors }" vid="company" :name="$t('globals.internal')" rules="|">
                              <v-checkbox
                                :color="$root.$vuetify.theme.isDark ? 'white' : 'primary'"
                                v-model="fields.internal"
                                :error-messages="errors"
                                :label="$t('globals.internal')"
                              ></v-checkbox>
                            </validation-provider>
                        </v-col>   
                    </v-row>
                  </v-card>
                
                <v-row class="mt-6" align="center" justify="space-around">
                    <v-btn :disabled="invalid" depressed :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary'" type="submit">
                    {{$t('globals.save')}}
                    </v-btn>
                </v-row>
                </fieldset>
            </v-form>
            <DialogSuccess :opened="success" @on-ok="onSuccess" >
                {{$t('indirect_work_hand.success_saved')}}
            </DialogSuccess>
            </validation-observer>
        </v-container>
        <v-dialog
          v-model="addCompany"
          max-width="800px"
        >
          <AddCompany 
            v-if="addCompany"
            @close="addCompany = false"
            @close-update="fillBaseData();"
          />
        </v-dialog>
    </v-card>
  </template>
  <script>
  import Indirect from "@/api/Indirect.js";
  import Company from "@/api/Company.js";
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  import AddCompany from '@/components/companies/Add.vue';
  import Tooltip from '@/components/ui/Tooltip.vue';
  
  export default {
    components: {
      DialogSuccess,
      AddCompany,
      Tooltip
    },
    props: {
     indirect: Object,
     method: String,
     disabled: Boolean,
   },
   watch: { 
    indirect: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;
  
          this.fields.id = data.id;
        }
      },
    },
    beforeCreate(){
    },
    data: () => ({
      addCompany: false,
      fields: {
        id: null,
        description: null,
        cost: null,
        company_id: null
      },
      loading:false,
      error: {
        title: '',
        message: '',
      },
      success: false,
      units:[],
      companies: [],
    }),
    mounted(){
      this.fillBaseData();
    },
    methods:{
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        let domain = '/' + this.$session.get('domain')
        this.$router.push(domain + '/admin/budgeting/indirect_work_hand');
      },
      fillBaseData(){

        Company.list().then(({data}) => {
            this.companies = data
        });
      },
      submit() {
  
        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }
  
          let data = {...this.fields};

          if(this.fields.company_id == null)
            this.fields.internal = true
         
  
          Indirect[this.method](data, this.fields.id).then(() => {
  
            this.success = true;
  
            this.loading = false;
            
          }).catch(err => {
            this.loading = false;
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = this.$t('globals.error') + " " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })
      }
    },
    computed: {
      
    }
  };
  </script>
  