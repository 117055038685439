<template>
    <DialogV2 
      v-bind="$attrs"
      v-on="$listeners"
      class="elevation-16"
      title="Sucesso">
      <template #content>
        <v-row>
          <v-col cols="12" class="text-center">
            <!-- DESKTOP -->
            <div class="d-none d-lg-block">
              <v-icon color="success" size="125">mdi-check-circle-outline</v-icon>
            </div>
            <!-- MOBILE -->
            <div class="d-md-none">
              <v-icon color="success" size="50">mdi-check-circle-outline</v-icon>
            </div>
          </v-col>
          <v-col cols="12" class="text-center">
            <!-- DESKTOP -->
            <div class="d-none d-lg-block">
              <span class="success--text text-h4 font-weight-black text-uppercase">{{$t('globals.success')}}!</span>
            </div>
            <!-- MOBILE -->
            <div class="d-md-none">
              <span class="success--text text-h6 font-weight-black text-uppercase">{{$t('globals.success')}}!</span>
            </div>
          </v-col>
          <v-col cols="12" class="text-center text-body-1 font-weight-bold">
            <slot></slot>
          </v-col>
        </v-row>
      </template>
      <template #footer>
        <v-btn
            block
            :color="'success'"            
            class="white--text"
            x-large
            @click="$emit('on-ok')"
          >OK</v-btn>
      </template>
    </DialogV2>
</template>

<script>
  import DialogV2 from '@/components/ui/DialogV2.vue';
  export default {
    name: 'uiDialogSuccess',
    components: {
      DialogV2
    }
  }
</script>

<style lang="scss" scoped>
</style>