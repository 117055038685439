<template>
  <section class="mt-16">
    <v-breadcrumbs
        v-if="this.$root.$vuetify.theme.isDark"
        large
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>
      <v-breadcrumbs
        v-else
        :items="crumbs"
        divider="/"
        light
        :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-bulldozer</v-icon> <span>{{$t('machines.crumb_edit')}}</span>
      </h1>

      <div class="mt-16">
        <v-progress-linear v-if="loading"
          indeterminate
          class="global-loader"
        ></v-progress-linear>

        <template v-if="loading">
          <v-row>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row class="mt-6" align="center" justify="space-around">
            <v-skeleton-loader
                type="button"
              ></v-skeleton-loader>
          </v-row>
        </template>
        <machine v-else method="update" :machine="machine"></machine>
      </div>
  </section>
</template>
<script>
import Machine from '@/components/machines/Machine'
import MachineApi from "@/api/Machine.js";

export default {
  components: {
    Machine
  },
  beforeCreate(){
    document.title = "Report Lake - " + this.$t('machines.crumb_edit');
  },
  data: () => ({
      machine: {},
      loading:true
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "machines.update"])) {
      this.$router.push('/admin');
    }

    MachineApi.find(this.$route.params.id).then(({data}) => { this.machine = data; this.loading = false;});
  },
  methods:{
    
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: this.$t('menu.dashboard'),
          disabled: false,
          to: '/' + this.$session.get('domain') + '/admin',
          exact: true,
        },
        {
          text: this.$t('machines.crumb_management'),
          disabled: false,
          to: '/' + this.$session.get('domain') + '/admin/works-settings/machines',
          exact: true,
        },
        {
          text: this.$t('machines.crumb_edit'),
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
